import { ConversationSortOptionsController } from './conversation-sort-options.controller';
var template = "<div layout=\"row\" layout-align=\"space-between\" flex=\"none\" class=\"sort-options-banner\">\n  <div class=\"qual-title\">\n    <strong><span md-colors=\"{ color: 'primary-500', borderColor: 'primary-500' }\" ng-bind-html=\"vm.postTitle\"></span></strong>\n    <span ng-if=\"(vm.isMobileAppUser || vm.isMobileView()) && vm.isLounge\" class=\"room-name\">{{ '/' + vm.postRoom}}</span>\n  </div>\n  <div layout=\"row\" layout-align=\"center center\" class=\"qual-banner-dropdown\">\n    <isc-checkbox class=\"sort-label group-by-member\"\n          ng-if=\"vm.isGroupByMemberEnabled\"\n          label=\"{{vm.groupByMemberLabel}}\"\n          aria-label=\"Group by member\"\n          aria-checked=\"false\"\n          model=\"vm.isGroupedByMember\"\n          ng-click=\"vm.groupedByMemberChecked();\"\n         >\n    </isc-checkbox>\n    <span class=\"sort-label\" isc-translate=\"(LabelConversationSort)\"></span>\n    <isc-dropdown isc-translate=\"[ariaLabel](LabelConversationSort)\" class=\"medium-qual-size bordered\" model=\"vm.model\" options=\"::vm.sortDropdownList\"\n      value-property=\"value\" label-property=\"label\" on-change=\"vm.onDropdownChange(value)\">\n    </isc-dropdown>\n  </div>\n</div>";
export var ConversationSortOptionsComponent = {
    controller: ConversationSortOptionsController,
    controllerAs: 'vm',
    template: template,
    bindings: {
        model: '=',
        isGroupedByMember: '=',
        onChange: '&',
        postTitle: '@',
        postRoom: '@',
        isMobileAppUser: '<',
        isLounge: '<',
        sortOptions: '<',
        isGroupByMemberEnabled: '<',
    },
};
