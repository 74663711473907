export class ConfirmPasswordController {
    constructor($state, participantService, _, imagesFactory, $stateParams) {
        this.$state = $state;
        this.participantService = participantService;
        this._ = _;
        this.imagesFactory = imagesFactory;
        this.$stateParams = $stateParams;
        this.images = this.imagesFactory.images;
        this.emailChanged = false;
        this.changeEmailError = false;
        this.invalidLink = false;
        this.dataLoading = true;
    }
    $onInit() {
        this.imagesFactory.applyImages();
        if (this.$stateParams.changeEmailGuid) {
            this.participantService.checkChangeEmailUrlValidity(this.$stateParams.changeEmailGuid).then((response) => {
                this.dataLoading = false;
                if (response && response.data && response.data.IsUrlValid) {
                    this.participantService.changeParticipantEmail(this.$stateParams.changeEmailGuid).then((res) => {
                        if (res && res.data && res.data.IsChanged) {
                            this.emailChanged = true;
                        }
                        else {
                            this.changeEmailError = true;
                        }
                    });
                }
                else {
                    this.invalidLink = true;
                }
            });
        }
    }
    goToLogin() {
        this.$state.go('container.login');
    }
}
ConfirmPasswordController.$inject = ['$state', 'participantService', '_', 'imagesFactory', '$stateParams'];
