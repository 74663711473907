var IscModalPresetController = (function () {
    function IscModalPresetController($mdDialog) {
        this.$mdDialog = $mdDialog;
    }
    IscModalPresetController.prototype.close = function () {
        this.$mdDialog.hide(this.title);
    };
    IscModalPresetController.prototype.cancel = function () {
        this.$mdDialog.cancel(false);
    };
    IscModalPresetController.prototype.stay = function () {
        this.$mdDialog.cancel(true);
    };
    IscModalPresetController.prototype.toInt = function (value) {
        return +value;
    };
    IscModalPresetController.$inject = ['$mdDialog'];
    return IscModalPresetController;
}());
export { IscModalPresetController };
