import RemoveBadStyles from './RemoveBadStyles';
import RemoveEmptyElement from './RemoveEmptyElement';
import TransformMsoListsToListElements from './TransformMsoListsToListElements';
import ReplaceEmojis from './ReplaceEmojis';
import RemoveIgnoredMsoListItemElement from './RemoveIgnoredMsoListItemElement';
import ReplacePreWithDiv from './ReplacePreWithDiv';
export function createPasteElementHelpers($injector) {
    var helpers = [
        $injector.instantiate(RemoveIgnoredMsoListItemElement),
        $injector.instantiate(TransformMsoListsToListElements),
        $injector.instantiate(RemoveBadStyles),
        $injector.instantiate(RemoveEmptyElement),
        $injector.instantiate(ReplaceEmojis),
        $injector.instantiate(ReplacePreWithDiv),
    ];
    return helpers;
}
