import router from '@/router';

export default new Proxy({}, {
  get: (_, key: string) => {
    const value = router.currentRoute.value.params[key] || router.currentRoute.value.query[key];
    if(value === 'false'){
      return false;
    }
    if(value === 'true'){
      return true;
    }
    return value;
  },
});
