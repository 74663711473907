// Import icon libraries
import iconSet from 'quasar/icon-set/fontawesome-v6-pro.js';
// Import Quasar css
import 'quasar/src/css/index.sass';
// Import plugins
import { Notify, Dialog, Loading, QuasarPluginOptions } from 'quasar';

// To be used on app.use(Quasar, { ... }
const quasarUserOptions: Partial<QuasarPluginOptions> = {
  config: {
    notify: {
      position: 'bottom-right',
      iconSize: '1.25rem',
    },
    loading: {
      spinnerColor: 'primary',
    },
  },
  plugins: {
    Dialog,
    Notify,
    Loading,
  },
  iconSet,
};

export default quasarUserOptions;
