export class MediaService {
    constructor(userService, _) {
        this.userService = userService;
        this._ = _;
        this.mobileBreakpoint = 600;
        this.tabletBreakpoint = 960;
        this._isMobileApp = false;
        this.videoScriptsLoaded = false;
        this.isDesktop = () => !this.isMobile
            && !this.isTablet;
    }
    get isMobile() {
        return window.innerWidth < this.mobileBreakpoint;
    }
    get isTablet() {
        return !this.isMobile
            && window.innerWidth < this.tabletBreakpoint;
    }
    get isMobileApp() {
        return this.isMobileAppUser() || this._isMobileApp;
    }
    set isMobileApp(value) {
        this._isMobileApp = value;
    }
    get isIOSDeviceType() {
        return (this.isMobileAppUser() || this._isMobileApp) && this.isIOSDevice();
    }
    appendMobileAppParam(url) {
        let returnUrl = url;
        if (this.shouldAppendMobileAppParamTo(returnUrl)) {
            returnUrl = `${returnUrl}?${MediaService.MobileAppParam}`;
        }
        return returnUrl;
    }
    hasAppVersion() {
        return !!this.userService.mobileAppVersion();
    }
    loadVideoScripts() {
        if (!this.videoScriptsLoaded) {
            this.loadScript('/youtube.iframe_api.js');
            this.loadScript('/azuremediaplayer.min.js');
            this.videoScriptsLoaded = true;
        }
    }
    isMobileAppUser() {
        return this.userService.isMobileAppUser();
    }
    isIOSDevice() {
        return this.userService.mobileDeviceType() === MediaService.IOSDeviceTypeParam;
    }
    shouldAppendMobileAppParamTo(url) {
        return this.isMobileApp &&
            !this.isExternalUrl(url) &&
            url.indexOf(MediaService.MobileAppParam) < 0;
    }
    isExternalUrl(url) {
        return this._.startsWith(url, 'http');
    }
    loadScript(scriptUrl) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(script);
        script.src = scriptUrl;
    }
}
MediaService.$inject = ['userService', '_'];
MediaService.MobileAppParam = 'mobileapp';
MediaService.MobileAppReturnToNativeHomeParam = 'autologin';
MediaService.IOSDeviceTypeParam = 'ios';
