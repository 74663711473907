import { UiModule } from '../../../index';
var likeButtonTemplate = "<md-button ng-disabled=\"vm.isDisabled\" class=\"md-primary\" ng-click=\"vm.onLike()\" isc-translate=\"[aria-label]{{vm.isLiked ? '(AriaLabelRemoveLike)' : '(AriaLabelAddLike)'}}\">\n  <i ng-if=\"!vm.hideIcon\" class=\"fa-heart\" ng-class=\"{'fal':!vm.isLiked, 'fas':vm.isLiked}\"></i>\n  <ng-transclude></ng-transclude>\n</md-button>";
UiModule.component('likeButton', {
    template: likeButtonTemplate,
    controllerAs: 'vm',
    transclude: true,
    bindings: {
        onLike: '&',
        isLiked: '<',
        hideIcon: '<',
        isDisabled: '<',
    },
});
