import { __awaiter } from "tslib";
import { iframeResizer } from 'iframe-resizer';
export class DecipherEmbedController {
    constructor($q, __env) {
        this.$q = $q;
        this.__env = __env;
        this.receiveMessage = (event) => {
            if (event.origin === this.__env.decipherUrl && event.data === 'completed') {
                this.onSurveyComplete();
            }
        };
    }
    $onInit() {
        this.initIframe().then((iframe) => {
            iframeResizer({}, iframe);
            window.addEventListener('message', this.receiveMessage, false);
        });
    }
    initIframe() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$q((resolve) => {
                const iframe = document.querySelector('iframe');
                if (iframe) {
                    iframe.addEventListener('load', () => {
                        resolve(iframe);
                    });
                    iframe.src = this.surveyUrl;
                }
            });
        });
    }
}
DecipherEmbedController.$inject = ['$q', '__env'];
