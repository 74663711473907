import { __awaiter } from "tslib";
export class DownloadMobileAppService {
    constructor(participantService, $q) {
        this.participantService = participantService;
        this.$q = $q;
        this._hasUserAlreadyDownloadedTheMobileApp = null;
        this.iosDownloadUrl = 'https://itunes.apple.com/us/app/insites-square/id1232226979';
        this.androidDownloadUrl = 'https://play.google.com/store/apps/details?id=com.squareapp';
    }
    shouldSuggestMobileAppDownload() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.isSupportedMobileDeviceOS() &&
                !(yield this.hasUserAlreadyDownloadedTheMobileApp());
        });
    }
    getMobileAppDownloadUrl() {
        if (this.isAndroidOS()) {
            return this.androidDownloadUrl;
        }
        if (this.isIOS()) {
            return this.iosDownloadUrl;
        }
        return null;
    }
    hasUserAlreadyDownloadedTheMobileApp() {
        if (this._hasUserAlreadyDownloadedTheMobileApp !== null) {
            return this.$q.resolve(this._hasUserAlreadyDownloadedTheMobileApp);
        }
        return this.participantService.hasUserAlreadyDownloadedTheMobileApp();
    }
    isSupportedMobileDeviceOS() {
        return this.isAndroidOS() || this.isIOS();
    }
    isAndroidOS() {
        const userAgent = this.getUserAgent();
        return /android/i.test(userAgent) && !(/windows phone/i.test(userAgent));
    }
    isIOS() {
        const userAgent = this.getUserAgent();
        return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    }
    getUserAgent() {
        return navigator.userAgent || navigator.vendor || window.opera;
    }
}
DownloadMobileAppService.$inject = ['participantService', '$q'];
