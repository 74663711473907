import { UiModule } from './../index';
UiModule.constant('emojis', [{
        name: 'HEAVY BLACK HEART',
        unified: '2764',
        variations: ['2764-FE0F'],
        docomo: 'E6EC',
        au: 'E595',
        softbank: 'E022',
        google: 'FEB0C',
        image: '2764.png',
        sheet_x: 4,
        sheet_y: 10,
        short_name: 'heart',
        short_names: ['heart'],
        text: '<3',
        texts: ['<3'],
        category: 'Symbols',
        sort_order: 1,
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_emojione: true,
    }, {
        name: 'GRINNING FACE',
        unified: '1F600',
        variations: [],
        docomo: null,
        au: null,
        softbank: null,
        google: null,
        image: '1f600.png',
        sheet_x: 26,
        sheet_y: 15,
        short_name: 'grinning',
        short_names: ['grinning'],
        text: ':D',
        texts: null,
        category: 'People',
        sort_order: 1,
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_emojione: true,
    }, {
        name: 'WINKING FACE',
        unified: '1F609',
        variations: [],
        docomo: 'E729',
        au: 'E5C3',
        softbank: 'E405',
        google: 'FE347',
        image: '1f609.png',
        sheet_x: 26,
        sheet_y: 24,
        short_name: 'wink',
        short_names: ['wink'],
        text: ';)',
        texts: [';)', ';-)'],
        category: 'People',
        sort_order: 10,
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_emojione: true,
    }, {
        name: 'SMILING FACE WITH SMILING EYES',
        unified: '1F60A',
        variations: [],
        docomo: 'E6F0',
        au: 'EACD',
        softbank: 'E056',
        google: 'FE335',
        image: '1f60a.png',
        sheet_x: 26,
        sheet_y: 25,
        short_name: 'blush',
        short_names: ['blush'],
        text: ':)',
        texts: null,
        category: 'People',
        sort_order: 11,
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_emojione: true,
    }, {
        name: 'FACE WITH STUCK-OUT TONGUE',
        unified: '1F61B',
        variations: [],
        docomo: null,
        au: null,
        softbank: null,
        google: null,
        image: '1f61b.png',
        sheet_x: 27,
        sheet_y: 1,
        short_name: 'stuck_out_tongue',
        short_names: ['stuck_out_tongue'],
        text: ':p',
        texts: [':p', ':-p', ':P', ':-P', ':b', ':-b'],
        category: 'People',
        sort_order: 24,
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_emojione: true,
    }, {
        name: 'FACE WITH STUCK-OUT TONGUE AND WINKING EYE',
        unified: '1F61C',
        variations: [],
        docomo: 'E728',
        au: 'E4E7',
        softbank: 'E105',
        google: 'FE329',
        image: '1f61c.png',
        sheet_x: 27,
        sheet_y: 2,
        short_name: 'stuck_out_tongue_winking_eye',
        short_names: ['stuck_out_tongue_winking_eye'],
        text: ';p',
        texts: [';p', ';-p', ';b', ';-b', ';P', ';-P'],
        category: 'People',
        sort_order: 22,
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_emojione: true,
    }, {
        name: 'DISAPPOINTED FACE',
        unified: '1F61E',
        variations: [],
        docomo: 'E6F2',
        au: 'EAC0',
        softbank: 'E058',
        google: 'FE323',
        image: '1f61e.png',
        sheet_x: 27,
        sheet_y: 4,
        short_name: 'disappointed',
        short_names: ['disappointed'],
        text: ':(',
        texts: ['):', ':(', ':-('],
        category: 'People',
        sort_order: 37,
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_emojione: true,
    }, {
        name: 'CRYING FACE',
        unified: '1F622',
        variations: [],
        docomo: 'E72E',
        au: 'EB69',
        softbank: 'E413',
        google: 'FE339',
        image: '1f622.png',
        sheet_x: 27,
        sheet_y: 8,
        short_name: 'cry',
        short_names: ['cry'],
        text: ":'(",
        texts: [":'("],
        category: 'People',
        sort_order: 57,
        has_img_apple: true,
        has_img_google: true,
        has_img_twitter: true,
        has_img_emojione: true,
    }]);
