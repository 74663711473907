<template>
  <q-card
    square
    class="isc-base-filter-menu"
  >
    <isc-search-filter
      v-if="showSearch"
      class="isc-base-filter-menu__search"
      :search-input="searchInput"
      @search-input="emit('search-input', $event)"
    />
    <q-separator v-if="showSearch" />
    <isc-selection-radio
      v-if="showSelectionOperation"
      class="isc-base-filter-menu__selection-radio"
      :selection-operation="props.selectionOperation"
      @selection-operation-change="emit('selection-operation-change', $event)"
    />
    <q-separator v-if="showSelectionOperation" />
    <isc-checkbox-selection
      v-if="showAnySelectionOption"
      class="isc-base-filter-menu__any-selection"
      :selection-option="props.anySelectionOption"
      :label="'Any ' + props.filterType"
      @selection-change="emit('any-selection-change', $event)"
    />
    <slot name="option" />
    <q-option-group
      v-if="!$slots.option"
      :model-value="selectedOptions"
      :options="cleanedUpOptions"
      color="secondary"
      type="checkbox"
      class="isc-base-filter__option-group"
      @update:model-value="emit('selected-options-change', $event)"
    >
      <template #label="opt">
        <!-- eslint-disable vue/no-v-html -->
        <label v-html="opt.label" />
        <!-- eslint-enable vue/no-v-html -->
      </template>
    </q-option-group>
    <isc-checkbox-selection
      v-if="props.noSelectionOption && props.noSelectionOption.show"
      class="isc-base-filter-menu__no-selection"
      :selection-option="props.noSelectionOption.selected"
      :label="props.noSelectionOption.label"
      @selection-change="emit('no-selection-change', $event)"
    />
  </q-card>
</template>

<script lang="ts" setup>
import { IscSearchFilter, IscSelectionRadio, IscCheckboxSelection } from '@ui/components/filter';
import { FilterNoSelection, FilterOption, FilterSelectionOperation, FilterType } from '../isc-filter.types';
import { computed } from 'vue';
import { removeHtmlFromLabel } from '../filter-helpers';

export interface Props {
  searchInput?: string;
  showSearch?: boolean;
  options?: Array<FilterOption<number>> | Array<FilterOption<string>>;
  filterType?: FilterType;
  selectedOptions?: number[] | string[];
  anySelectionOption?: boolean | null;
  noSelectionOption?: FilterNoSelection;
  showAnySelectionOption?: boolean;
  selectionOperation?: FilterSelectionOperation;
  showSelectionOperation?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  searchInput: '',
  showSearch: false,
  options: () => [],
  filterType: undefined,
  selectedOptions: () => [],
  anySelectionOption: false,
  noSelectionOption: undefined,
  showAnySelectionOption: false,
  selectionOperation: FilterSelectionOperation.Any,
  showSelectionOperation: false,
});

const emit = defineEmits<{
  (e: 'selected-options-change', payload: number[] | string[]): void;
  (e: 'search-input', payload: string | undefined): void;
  (e: 'selection-operation-change', payload: FilterSelectionOperation): void;
  (e: 'any-selection-change', payload: boolean | null): void;
  (e: 'no-selection-change', payload: boolean | null): void;
}>();

const cleanedUpOptions = computed(() =>
  (props.options as Array<FilterOption<number>> | Array<FilterOption<string>>)
    .map((o) => ({
      value: o.value,
      label: removeHtmlFromLabel(o.label, true),
    })),
);
</script>

<style lang="scss">
.isc-base-filter-menu {
  color: $text-on-surface-high;
}
</style>
