var IscCheckboxController = (function () {
    function IscCheckboxController() {
    }
    IscCheckboxController.prototype.toggleCheckbox = function () {
        this.model = !this.model;
        if (this.onChange) {
            this.onChange();
        }
    };
    IscCheckboxController.$inject = [];
    return IscCheckboxController;
}());
export { IscCheckboxController };
