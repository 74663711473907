import { UserNotificationController } from './user-notification.controller';
const template = "<isc-user-notification notifications=\"vm.notifications\"\n notification-track=\"vm.trackResearchActivityNotificationsView()\"\n notification-click=\"vm.notificationClicked(notification)\"\n clear-individual-notification=\"false\"\n is-mobile=\"vm.isMobile\" bordered-icon=\"vm.borderedIcon\"\n menu-offset=\"vm.menuOffset\"\n notification-types=\"vm.notificationTypes\"\n clear-all-notifications=\"vm.clearAllNotifications()\"\n clear-all=\"false\">\n</isc-user-notification>\n";
export const UserNotificationComponent = {
    controller: UserNotificationController,
    template,
    controllerAs: 'vm',
    bindings: {
        isMobile: '<',
        borderedIcon: '<',
    },
};
