import { BaseDataService } from './base.data.service';
export class SquareService extends BaseDataService {
    constructor(httpService, squareFactory, $window) {
        super(httpService);
        this.squareFactory = squareFactory;
        this.$window = $window;
    }
    getSquareDetails(squareGuid) {
        return this.httpService.get({
            url: `/SquareService/SelectSquare/${squareGuid}`,
        });
    }
    getSquareConnectProject(squareId) {
        return this.httpService.get({
            url: '/SquareService/GetSquareConnectProject/',
            params: {
                SquareId: squareId,
            },
        }).then((response) => response.data);
    }
    selectSquareDesign() {
        return this.getOrCached({
            url: '/SquareService/SelectSquareDesign',
            params: {
                SquareGuid: this.squareFactory.Guid,
            },
        });
    }
    getSquareTimelineCards(newsPages, lastCardGuid) {
        return this.httpService.get({
            url: '/SquareService/ListSquareTimelineCards',
            params: {
                LastCardGuid: lastCardGuid,
                NewsPages: newsPages,
            },
        }).then((response) => response.data);
    }
    getChallengeSequencePageCards(includedGuid) {
        return this.httpService.get({
            url: '/SquareService/ListSquareChallengeSequences',
            params: {
                included: includedGuid || '',
            },
        }).then((response) => response.data);
    }
    getExternalLink(externalLinkGuid) {
        return this.httpService.get({
            url: '/SquareService/GetExternalLink',
            params: {
                guid: externalLinkGuid,
            },
        }).then((response) => response.data);
    }
    getSplashPageDetails() {
        return this.httpService.get({
            url: '/SquareService/GetSplashDataToShow',
        }).then((response) => response.data);
    }
    sendSplashAgreement(agree, versionNumber) {
        return this.httpService.post({
            url: '/SquareService/SaveSplashAgreement',
            data: {
                agreed: agree,
                version: versionNumber,
            },
        }).then((response) => response.data);
    }
    getThemes() {
        return this.httpService.get({
            url: '/SquareService/GetThemes',
        }).then((response) => response.data);
    }
    getAppCustomUriDetails() {
        const url = this.$window.location.origin;
        const subdomainRegex = /:\/\/(.*?)\./;
        const squareUrl = url.match(subdomainRegex)[1];
        return this.httpService.get({
            url: `/SquareService/GetAppCustomUriDetails?squareUrl=${squareUrl}`,
        }).then((response) => response.data);
    }
}
SquareService.$inject = ['httpService', 'squareFactory', '$window'];
