(function (introJs) {
    'use strict';
    angular
        .module('insitesApp.core')
        .factory('tourService', TourServiceFactory);
    TourServiceFactory.$inject = ['$timeout', '$rootScope'];
    function TourServiceFactory($timeout, $rootScope) {
        let platformTourEnabled = false;
        let startTourRequested = false;
        return {
            startTour: (enabled) => {
                platformTourEnabled = enabled;
                startTourRequested = true;
                startTourIfEnabledAndRequested();
            },
            isTourStarted: false,
            alreadyStarted: false,
        };
        function startTourIfEnabledAndRequested() {
            if (platformTourEnabled && startTourRequested) {
                $timeout($rootScope.iscStartTour, 1000, false);
                startTourRequested = false;
            }
        }
    }
    angular
        .module('insitesApp.core')
        .directive('iscTour', IscTourDirective);
    IscTourDirective.$inject = ['$timeout', 'mediaservice', 'constantsfactory', '$q', '$state', 'tourService', 'squareFactory'];
    function IscTourDirective($timeout, mediaservice, constantsfactory, $q, $state, tourService, squareFactory) {
        return {
            restrict: 'A',
            link: (scope) => {
                let intro;
                function onIntroFinished() {
                    $timeout(() => {
                        tourService.isTourStarted = false;
                        tourService.alreadyStarted = true;
                    });
                    const backdrop = document.getElementsByClassName('md-menu-backdrop');
                    if (backdrop && backdrop.length > 0) {
                        angular.element(backdrop[0]).triggerHandler('click');
                    }
                    angular.element(document.body).removeClass('disable-scroll');
                }
                scope.$root.iscStartTour = (step) => {
                    if (tourService.isTourStarted) {
                        intro.exit();
                        intro.start();
                        return;
                    }
                    tourService.isTourStarted = true;
                    if (typeof (introJs) !== 'function') {
                        throw new Error('Intro.js not available!');
                    }
                    if (typeof (step) === 'string') {
                        intro = introJs(step);
                    }
                    else {
                        intro = introJs();
                    }
                    const optionspromise = $q.all([
                        constantsfactory.getLabelValue('LabelPlatformTourNext'),
                        constantsfactory.getLabelValue('LabelPlatformTourPrevious'),
                        constantsfactory.getLabelValue('LabelPlatformTourDialogOkButton'),
                    ])
                        .then((labels) => {
                        intro.setOptions({
                            showStepNumbers: false,
                            showBullets: true,
                            scrollToElement: false,
                            tooltipPosition: 'bottom-middle-aligned',
                            nextLabel: labels[0],
                            prevLabel: labels[1],
                            hidePrev: true,
                            hideNext: true,
                            skipLabel: '',
                            doneLabel: !squareFactory.SsoEnabled ? labels[2] : '',
                        });
                    });
                    intro.onafterchange((element) => {
                        if (element.getAttribute('data-tooltipclass') === 'last-step') {
                            const tooltip = document.querySelector('.introjs-tooltip');
                            tooltip.classList.add('last-step');
                        }
                        else {
                            const tooltipWithLastStepClass = document.querySelector('.introjs-tooltip.last-step');
                            if (tooltipWithLastStepClass) {
                                tooltipWithLastStepClass.classList.remove('last-step');
                            }
                        }
                    });
                    intro.onexit(() => {
                        onIntroFinished();
                    });
                    intro.oncomplete(() => {
                        onIntroFinished();
                        if (!squareFactory.SsoEnabled) {
                            $state.go('container.main.profile');
                        }
                    });
                    if (typeof (step) === 'number') {
                        optionspromise.then(() => {
                            intro.goToStep(step).start();
                        });
                    }
                    else {
                        if (mediaservice.isDesktop()) {
                            $timeout(() => {
                                const el = document.getElementById('moreDesktop');
                                if (el.clientWidth > 0) {
                                    angular.element(el).triggerHandler('click');
                                }
                            });
                        }
                        optionspromise.then(() => {
                            intro.start();
                        });
                        angular.element(document.body).addClass('disable-scroll');
                    }
                };
            },
        };
    }
}(typeof window.introJs === 'object' ? window.introJs.introJs : window.introJs));
