import { loginerrorModule } from './loginerror.module';
const appRunLoginerror = (routerHelper) => {
    routerHelper.configureStates([{
            state: 'container.loginerror',
            config: {
                url: '/loginerror',
                component: 'loginerror',
                data: {
                    title: '(LoginErrorTitle)',
                },
                requireLogin: false,
            },
        }]);
};
appRunLoginerror.$inject = ['routerHelper'];
loginerrorModule.run(appRunLoginerror);
