var IscStimuliController = (function () {
    function IscStimuliController(iscSocialStimuli, $q, iscStimuliTypeService, _, iscStimuliDialogService, $scope) {
        this.iscSocialStimuli = iscSocialStimuli;
        this.$q = $q;
        this.iscStimuliTypeService = iscStimuliTypeService;
        this._ = _;
        this.iscStimuliDialogService = iscStimuliDialogService;
        this.$scope = $scope;
        this.inview = false;
        this.backgroundUrl = '';
        this.cursor = 'default';
        this.loaded = false;
        this.url = '/images/bg-video.png';
    }
    IscStimuliController.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch('vm.stimuli.ThumbnailUrl', function () {
            if (_this.stimuli.ThumbnailUrl !== 'images/bg-video.png' && _this.stimuli.ThumbnailUrl) {
                _this.newThumb = _this.stimuli.ThumbnailUrl;
                _this.backgroundUrl = _this.createBackgroundUrl();
            }
            else {
                _this.setThumbnail();
            }
            _this.isVideoFormat = _this.isVideo();
        });
        this.$scope.$watch('vm.inview', function () {
            _this.backgroundUrl = _this.createBackgroundUrl();
        });
        this.conversationStimuliType = this.iscStimuliTypeService.getTypes();
        this.stringStimuliType = this._.invert(this.conversationStimuliType);
        this.isVideoFormat = this.isVideo();
        this.setThumbnail();
        this.backgroundUrl = this.createBackgroundUrl();
    };
    IscStimuliController.prototype.getAriaLabel = function () {
        if (!this.isClickable()) {
            return '';
        }
        if (this.isImage()) {
            return '[aria-label](AriaLabelOpenImage)';
        }
        if (this.isVideo()) {
            return '[aria-label](AriaLabelOpenVideo)';
        }
    };
    IscStimuliController.prototype.setThumbnail = function () {
        var _this = this;
        if (this.stimuli.Type === this.conversationStimuliType.youtube || this.stimuli.Type === this.conversationStimuliType.vimeo) {
            this.iscSocialStimuli.getThumbnail(this.stringStimuliType[this.stimuli.Type], this.stimuli.Value).then(function (thumbnail) {
                _this.stimuli.ThumbnailUrl = thumbnail;
                _this.loaded = true;
            });
        }
        else {
            this.loaded = true;
        }
    };
    IscStimuliController.prototype.isVideoLoaded = function () {
        return (this.newThumb && this.loaded && this.stimuli.ThumbnailUrl !== 'images/bg-video.png');
    };
    IscStimuliController.prototype.isClickable = function () {
        return this.isVideo()
            ? this.stimuli.ThumbnailUrl !== 'images/bg-video.png'
            : this.clickable !== undefined
                ? this.clickable && this.stimuli.ThumbnailUrl !== '/images/default-stimuli.png'
                : this.stimuli.ThumbnailUrl !== '/images/default-stimuli.png';
    };
    IscStimuliController.prototype.openStimuli = function (event) {
        this.iscStimuliDialogService.openStimuli(this.stimuli, this.allowDownload, event.target);
    };
    IscStimuliController.prototype.isImage = function () {
        return this.stimuli && this.stimuli.Type === this.conversationStimuliType.image;
    };
    IscStimuliController.prototype.isVideo = function () {
        if (this.stimuli && this.stimuli.Type) {
            if (this.stimuli.Type === this.conversationStimuliType.image ||
                this.stimuli.Type.toString().indexOf('image/') !== -1 ||
                (this.stimuli.TypeName && this.stimuli.TypeName.toString().indexOf('image/') !== -1)) {
                return false;
            }
            else if (this.stimuli.Type === this.conversationStimuliType.vimeo ||
                this.stimuli.Type === this.conversationStimuliType.video ||
                this.stimuli.Type === this.conversationStimuliType.youtube ||
                this.stimuli.Type.toString().indexOf('video/') !== -1 ||
                (this.stimuli.TypeName && this.stimuli.TypeName.toString().indexOf('video/') !== -1)) {
                return true;
            }
            else {
                throw new Error('Unknown stimuli type');
            }
        }
    };
    IscStimuliController.prototype.createBackgroundUrl = function () {
        if (this.disableLazyLoad || (this.inview && this.stimuli.ThumbnailUrl)) {
            var url = "url('".concat(this.stimuli.ThumbnailUrl, "')");
            return url;
        }
        return 'url(\'' + this.url + '\')';
    };
    IscStimuliController.prototype.showPreparingForPlayback = function () {
        return !this.isVideoLoaded() && this.isVideo() && (this.uploadProgress === 100 || this.uploadProgress == null);
    };
    IscStimuliController.$inject = ['iscSocialStimuli', '$q', 'iscStimuliTypeService', '_', 'iscStimuliDialogService', '$scope'];
    return IscStimuliController;
}());
export { IscStimuliController };
