import { __extends } from "tslib";
import { IscTaggingMenuBaseController } from '../common-tagging/tagging-menu-base.controller';
var IscMentionMenuController = (function (_super) {
    __extends(IscMentionMenuController, _super);
    function IscMentionMenuController($scope, iscMentionUtil, $window, $element) {
        return _super.call(this, $scope, iscMentionUtil, $window, $element) || this;
    }
    IscMentionMenuController.prototype.tagTypeName = function () {
        return 'mention';
    };
    IscMentionMenuController.$inject = ['$scope', 'iscMentionUtil', '$window', '$element'];
    return IscMentionMenuController;
}(IscTaggingMenuBaseController));
export { IscMentionMenuController };
