import { __awaiter } from "tslib";
export class SplashPageContentController {
    constructor($state, squareService, participantService, imagesFactory, logger, $stateParams, redirectService, squareFactory, serverConstants, authService, featureToggleService, _) {
        this.$state = $state;
        this.squareService = squareService;
        this.participantService = participantService;
        this.imagesFactory = imagesFactory;
        this.logger = logger;
        this.$stateParams = $stateParams;
        this.redirectService = redirectService;
        this.squareFactory = squareFactory;
        this.serverConstants = serverConstants;
        this.authService = authService;
        this.featureToggleService = featureToggleService;
        this._ = _;
        this.shouldShowFooter = this.featureToggleService.shouldShowFooter;
        this.splashMessage = {};
        this.squareInfo = this.squareFactory;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.init();
            }
            catch (e) {
                this.handleInitError(e);
            }
        });
    }
    handleInitError(e) {
        this.logger.error('Failed to load splash message', e);
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.imagesFactory.applyImages();
            this.images = this.imagesFactory.images;
            this.isAuthorized = yield this.authService.isAuthorized();
            if (this.isAuthorized) {
                yield this.authService.getSquareParticipantDetailsAndUpdate();
            }
            const shouldGetSplash = yield this.participantService.shouldRedirectToSplashPage();
            this.splashMessage = shouldGetSplash
                ? yield this.squareService.getSplashPageDetails()
                : null;
            if (this.splashMessage === null ||
                (this.splashMessage &&
                    this.splashMessage.IsEnabled !== undefined &&
                    !this.splashMessage.IsEnabled)) {
                this.handleRedirect(true);
            }
        });
    }
    agree() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.saveSplashAgreement(true);
        });
    }
    disagree() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.saveSplashAgreement(false);
        });
    }
    shouldShowActionButtons() {
        return this.splashMessage !== null
            ? this.splashMessage.Type !== this.serverConstants.splashMessageTypeConstants.squareClosure
            : false;
    }
    saveSplashAgreement(agreed) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.squareService.sendSplashAgreement(agreed, this.splashMessage.Guid);
                this.handleRedirect(agreed);
            }
            catch (error) {
                this.handleSendError(error);
            }
        });
    }
    handleRedirect(agreed) {
        if (!agreed) {
            this.authService.signOut(true);
        }
        else {
            if (this.$stateParams.redirectUrl && this.$stateParams.redirectUrl !== '/') {
                this.redirectService.redirectToLink(this.$stateParams.redirectUrl);
            }
            else {
                this.$state.go('container.main.home');
            }
        }
    }
    handleSendError(e) {
        this.logger.error('Failed to send splash info', e);
    }
}
SplashPageContentController.$inject = ['$state', 'squareService', 'participantService', 'imagesFactory', 'logger', '$stateParams',
    'redirectService', 'squareFactory', 'serverConstants', 'authService', 'featureToggleService', '_'];
