import { IscMentionController } from './mention.controller';
var IscMentionDirective = (function () {
    function IscMentionDirective(iscMentionUtil, $document, $compile, $log, $timeout, $parse) {
        this.iscMentionUtil = iscMentionUtil;
        this.$document = $document;
        this.$compile = $compile;
        this.$log = $log;
        this.$timeout = $timeout;
        this.$parse = $parse;
        this.restrict = 'A';
        this.require = 'ngModel';
        this.controller = IscMentionController;
    }
    IscMentionDirective.prototype.link = function (scope, element, attrs, ctrl) {
        var _this = this;
        if (!scope.mention.disabled) {
            scope.mention.triggerCharMap = {};
            scope.mention.targetElement = element;
            attrs.$set('autocomplete', 'off');
            scope.mention.defaultTriggerChar = '@';
            var html = "<isc-mention-menu\n          isc-mention-search=\"mention.bridgeSearch(term)\"\n          isc-mention-select=\"mention.bridgeSelect(item)\"\n          isc-mention-items=\"mention.items\"\n          isc-mention-trigger-char=\"'".concat(scope.mention.defaultTriggerChar, "'\"\n          isc-mention-replace-text=\"mention.replaceText(text)\"\n          isc-mention-add-menu=\"mention.addMenu(menuScope)\"/>");
            var linkFn = this.$compile(html);
            var el_1 = linkFn(scope);
            element.parent().append(el_1);
            scope.$on('$destroy', function () {
                el_1.remove();
            });
            ctrl.$viewChangeListeners.push(function () {
                var newValue = ctrl.$viewValue;
                if ((!newValue || newValue === '') && !scope.mention.isActive()) {
                    return;
                }
                if (scope.mention.triggerCharSet === undefined) {
                    _this.$log.error('Error, no isc-mention-items attribute was provided, ' +
                        'and no separate isc-mention-menus were specified.  Nothing to do.');
                    return;
                }
                if (scope.mention.contentEditableMenuPasted) {
                    scope.mention.contentEditableMenuPasted = false;
                    return;
                }
                var isActive = scope.mention.isActive();
                var mentionInfo = _this.iscMentionUtil.getTriggerInfo(scope.mention.triggerCharSet, isActive);
                if (mentionInfo !== undefined &&
                    (!isActive ||
                        (isActive &&
                            mentionInfo.taggingTriggerChar === scope.mention.currentMentionTriggerChar))) {
                    if (mentionInfo.taggingSelectedElement) {
                        scope.mention.targetElement = mentionInfo.taggingSelectedElement;
                        scope.mention.targetElementPath = mentionInfo.taggingSelectedPath;
                        scope.mention.targetElementSelectedOffset = mentionInfo.taggingSelectedOffset;
                    }
                    scope.mention.setTriggerText(mentionInfo.taggingText);
                    scope.mention.currentMentionPosition = mentionInfo.taggingPosition;
                    scope.mention.currentMentionTriggerChar = mentionInfo.taggingTriggerChar;
                    scope.mention.query(mentionInfo.taggingTriggerChar, mentionInfo.taggingText);
                }
                else {
                    scope.mention.setTriggerText('');
                    scope.mention.hideAll();
                }
            });
        }
    };
    IscMentionDirective.factory = function () {
        var directive = function (iscMentionUtil, $document, $compile, $log, $timeout, $parse) {
            return new IscMentionDirective(iscMentionUtil, $document, $compile, $log, $timeout, $parse);
        };
        directive.$inject = ['iscMentionUtil', '$document', '$compile', '$log', '$timeout', '$parse'];
        return directive;
    };
    IscMentionDirective.$inject = ['iscMentionUtil', '$document', '$compile', '$log', '$timeout', '$parse'];
    return IscMentionDirective;
}());
export { IscMentionDirective };
