export class EqualsToDirective {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {
            otherModelValue: '=equalsTo',
        };
    }
    link(scope, element, attrs, ngModel) {
        ngModel.$validators.equalsTo = (modelValue) => modelValue === scope.otherModelValue;
        scope.$watch('otherModelValue', () => {
            ngModel.$validate();
        });
    }
}
