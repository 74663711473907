import { __awaiter } from "tslib";
import { DateTime } from 'luxon';
export class NewsDetailController {
    constructor($stateParams, newsService, $scope, $document) {
        this.$stateParams = $stateParams;
        this.newsService = newsService;
        this.$scope = $scope;
        this.$document = $document;
        this.$scope.$watch(() => this.$stateParams.cardGuid, (newVal) => __awaiter(this, void 0, void 0, function* () {
            if (newVal != null) {
                yield this.$onInit();
            }
        }));
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            let card;
            if (this.$stateParams.card && this.$stateParams.card.Guid === this.$stateParams.cardGuid) {
                card = this.$stateParams.card;
            }
            else {
                card = yield this.newsService.getNewsCardDetail(this.$stateParams.cardGuid);
            }
            card.Stimuli = {
                ThumbnailUrl: (card.FileType && card.FileType.indexOf('image/') !== -1) ? card.StimuliUrl : card.StimuliThumbnailUrl,
                Value: card.StimuliUrl,
                Url: card.StimuliUrl,
                Type: card.FileType,
            };
            card.DateDiff = DateTime.fromISO(card.StartDateTime).toRelative({ base: DateTime.utc() });
            this.card = card;
        });
    }
    likeClick() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.card) {
                yield this.newsService.likeClick(this.card);
            }
        });
    }
}
NewsDetailController.$inject = ['$stateParams', 'newsService', '$scope', '$document'];
