import { __awaiter } from "tslib";
import { ForumNewConversationControllerBase } from '../../common/forum-new-conversation-controller-base';
export class NewDiscussionTopicController extends ForumNewConversationControllerBase {
    constructor($stateParams, $state, forumService, taskList, pageThemes, emojiservice, serverConstants, mappingService, conversationService, $timeout, discussionService, discussionLoungeService) {
        super($stateParams, $state, forumService, taskList, pageThemes, emojiservice, serverConstants, mappingService, conversationService, $timeout);
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.forumService = forumService;
        this.taskList = taskList;
        this.pageThemes = pageThemes;
        this.emojiservice = emojiservice;
        this.serverConstants = serverConstants;
        this.mappingService = mappingService;
        this.conversationService = conversationService;
        this.$timeout = $timeout;
        this.discussionService = discussionService;
        this.discussionLoungeService = discussionLoungeService;
    }
    $onInit() {
        super.$onInit();
    }
    saveConversation() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isSaving) {
                return;
            }
            this.isSaving = true;
            const pageGuid = this.$stateParams.pageGuid;
            const roomGuid = this.$stateParams.roomGuid;
            const theme = this.pageThemes.getPageTheme(pageGuid);
            const publishingTask = this.taskList.createTask({
                title: '(LabelPublishingForumConversationTitle)',
                text: '(LabelPublishingConversationText)',
                theme,
            });
            const publishedTask = this.taskList.createTask({
                title: '(LabelPublishedConversationTitle)',
                text: '(LabelPublishedConversationText)',
                showCloseButton: true,
                theme,
                onClick: () => {
                    this.$state.go('container.main.forum.room', { pageGuid, roomGuid });
                    publishedTask.hide();
                },
            });
            publishingTask.show();
            try {
                const createOrUpdateTopicRequest = {
                    roomGuid,
                    title: this.title,
                };
                const topicResponse = yield this.discussionLoungeService.createOrUpdateTopic(createOrUpdateTopicRequest);
                const createOrUpdatePostRequest = {
                    discussionGuid: topicResponse.discussionGuid,
                };
                const postResponse = yield this.discussionService.createOrUpdatePost(createOrUpdatePostRequest);
                const createOrUpdateMessageRequest = {
                    postGuid: postResponse.guid,
                    text: this.emojiservice.htmlToShort(this.message),
                };
                yield this.discussionService.createOrUpdateMessage(createOrUpdateMessageRequest);
                publishingTask.hide();
                publishedTask.show();
                this.$timeout(() => publishedTask.hide(), 5000);
            }
            catch (error) {
                publishedTask.title = '(LabelPublishConversationError)';
                publishedTask.text = '(LabelPublishConversationErrorText)';
                publishingTask.hide();
                publishedTask.show();
            }
            finally {
                this.isSaving = false;
            }
            this.goToRoom();
        });
    }
}
NewDiscussionTopicController.$inject = [
    '$stateParams',
    '$state',
    'forumService',
    'taskList',
    'pageThemes',
    'emojiservice',
    'serverConstants',
    'mappingService',
    'conversationService',
    '$timeout',
    'discussionService',
    'discussionLoungeService',
];
