import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { guidRegex } from '@/router/router.constants';

const confirmEmail = () => import('@/pages/confirm-email.vue');

export const confirmEmailRoute: RouteRecordRaw = {
  name: RouteNames.ConfirmEmail,
  path: `/confirmemail/:changeEmailGuid(${guidRegex})`,
  component: confirmEmail,
  meta: {
    requireLogin: false,
    title: '(LabelWindowTitleConfirmEmail)',
  },
};
