export class HeaderImagesController {
    constructor(imagesFactory, mediaservice, $state) {
        this.imagesFactory = imagesFactory;
        this.mediaservice = mediaservice;
        this.$state = $state;
        this.images = this.imagesFactory.images;
        this.state = this.$state;
    }
    isDesktop() {
        return this.mediaservice.isDesktop();
    }
}
HeaderImagesController.$inject = ['imagesFactory', 'mediaservice', '$state'];
