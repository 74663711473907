import { IscHashtagMenuController } from './hashtag-menu.controller';
export var IscHashtagMenuComponent = {
    template: "\n    <ul class=\"dropdown-menu scrollable-menu\" style=\"display:block\">\n      <isc-hashtag-menu-item\n        typed-term=\"vm.typedTerm\"\n        is-active=\"vm.isActive(item)\"\n        activate=\"vm.activate(item)\"\n        select-item=\"vm.selectItem(item)\"\n        ng-repeat=\"item in vm.items track by $index\" item=\"item\"></isc-hashtag-menu-item>\n    </ul>\n  ",
    controllerAs: 'vm',
    controller: IscHashtagMenuController,
    bindings: {
        search: '&iscHashtagSearch',
        select: '&iscHashtagSelect',
        items: '<iscHashtagItems',
        triggerChar: '=iscHashtagTriggerChar',
        addMenu: '&iscHashtagAddMenu',
        replaceText: '&iscHashtagReplaceText',
    },
};
