import { __awaiter } from "tslib";
import * as angular from 'angular';
import { Utils } from '../../core/utils';
export class MyIncentiveController {
    constructor(pageService, incentiveService, $filter, constantsfactory, $state, iscPages, _, serverConstants) {
        this.pageService = pageService;
        this.incentiveService = incentiveService;
        this.$filter = $filter;
        this.constantsfactory = constantsfactory;
        this.$state = $state;
        this.iscPages = iscPages;
        this._ = _;
        this.serverConstants = serverConstants;
        this.FIRST_IN_BATCH = 1;
        this.LAST_IN_BATCH = 2;
        this.NOT_IN_BATCH = 3;
        this.questionsInfoText = '';
        this.isLoaded = false;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.channelTypes = this.serverConstants.channelTypeConstants;
            const incentiveDetails = yield this.incentiveService.getProfileIncentiveDetails();
            this.model = incentiveDetails.data;
            this._.forEach(this.model.Items, (item) => __awaiter(this, void 0, void 0, function* () {
                if (item.RewardPartnerType && item.RewardWinnerStatus) {
                    const rewardPartnerType = Utils.getEnumValueName(this.serverConstants.rewardPartnerTypeConstants, item.RewardPartnerType);
                    if (item.RewardPartnerType !== this.serverConstants.rewardPartnerTypeConstants.other) {
                        item.IncentiveLabel = `(LabelRewardPartner${rewardPartnerType})`;
                    }
                }
            }));
            if (this.model.CurrentRaffleStartDate) {
                this.model.Items.push({
                    DateTime: this.model.CurrentRaffleStartDate,
                    ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                    PositionInBatch: this.LAST_IN_BATCH,
                });
            }
            this.model.Items = this.$filter('orderBy')(this.model.Items, 'DateTime', true);
            this.numberOfRewards = this.getNumberOfRewards();
            this.findOutdatedCards(this.model.Items, this.model.CurrentRaffleStartDate);
            this.updatePositionsInBatch(this.model.Items, this.model.CurrentRaffleStartDate !== undefined);
            yield this.checkIncentivesFaqCategoryExists();
            this.isLoaded = true;
        });
    }
    updatePositionsInBatch(items, hasRaffleStartDate) {
        if (hasRaffleStartDate && items.length > 0) {
            if (!items[0].PositionInBatch) {
                items[0].PositionInBatch = this.FIRST_IN_BATCH;
            }
        }
        let offset = 0;
        if (hasRaffleStartDate) {
            offset = this._.findIndex(items, (item) => item.PositionInBatch === this.LAST_IN_BATCH);
        }
        for (let i = offset + 1; i < items.length; i++) {
            items[i].PositionInBatch = this.NOT_IN_BATCH;
        }
    }
    getNumberOfRewards() {
        let rewards = 0;
        angular.forEach(this.model.Items, (value) => {
            if (value.Currency) {
                rewards++;
            }
        });
        return rewards;
    }
    setQuestionsInfoTextValue() {
        const faqPage = this.iscPages.helpCenterPages.filter((page) => page.PageType === this.iscPages.PageType.faq);
        const faqIncentivesUrl = this.$state.href('container.main.helpcenter.faq', { helpcenterPage: faqPage[0].Guid }, { absolute: true });
        this.constantsfactory.getLabelValueReplaceUrl('LabelQuestionsMyIncentive', faqIncentivesUrl)
            .then((value) => {
            this.questionsInfoText = value;
        });
    }
    checkIncentivesFaqCategoryExists() {
        this.iscPages.init().then(() => {
            const page = this._.find(this.iscPages.helpCenterPages, (item) => item.PageType === this.iscPages.PageType.faq);
            if (!page) {
                return;
            }
            this.pageService.getFaqPageDetails(page.Guid).then((result) => {
                if (this._.some(result.data.Categories, { IsLinkedToIncentives: true, IsVisible: true })) {
                    this.setQuestionsInfoTextValue();
                }
            });
        });
    }
    findOutdatedCards(items, refDate) {
        angular.forEach(items, (value) => {
            if (!refDate || value.DateTime < refDate) {
                value.isOutdated = true;
            }
        });
    }
}
MyIncentiveController.$inject = ['pageService', 'incentiveService', '$filter', 'constantsfactory', '$state', 'iscPages', '_', 'serverConstants'];
