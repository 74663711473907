export class FooterController {
    constructor(iscPages, __env, serverConstants) {
        this.iscPages = iscPages;
        this.__env = __env;
        this.serverConstants = serverConstants;
        this.showMyCookieSettings = this.__env.cookieYesSiteId !== '';
        this.showIcpLicense = this.__env.showICPLicense;
        this.ICPRedirectUrl = this.__env.ICPRedirectUrl;
        this.ICPLicenseNumber = this.__env.ICPLicenseNumber;
        this.pageTypeConstants = serverConstants.pageTypeConstants;
    }
    $onInit() {
        this.iscPages.init().then(() => {
            this.listPage = this.iscPages.footerPages.filter((page) => page.PageType !== this.pageTypeConstants.myCookieSettings);
            this.myCookieSettingsPage = this.iscPages.footerPages.filter((page) => page.PageType === this.pageTypeConstants.myCookieSettings)[0];
        });
    }
}
FooterController.$inject = ['iscPages', '__env', 'serverConstants'];
