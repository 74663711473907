import { DateTime } from 'luxon';
export class Utils {
    static getEnumValueName(enumObject, value) {
        for (const item in enumObject) {
            if (enumObject[item] === value) {
                const label = item[0].toUpperCase() + item.slice(1);
                return label;
            }
        }
        return null;
    }
    static buildUrl(baseUrl, paramObj) {
        const paramsPrefix = baseUrl.indexOf('?') >= 0 ? '&' : '?';
        const params = [];
        for (const param in paramObj) {
            if (param) {
                params.push(`${encodeURIComponent(param)}=${encodeURIComponent(paramObj[param])}`);
            }
        }
        return baseUrl + paramsPrefix + params.join('&');
    }
    static getRoleLabel(roleConstants, roleType) {
        if (roleType === roleConstants.participant) {
            return '';
        }
        let roleName = Utils.getEnumValueName(roleConstants, roleType);
        if (roleType === roleConstants.Human8) {
            roleName += ' Admin';
        }
        return roleName;
    }
    static getStatusLabel(registrationStatusConstants, statusType, suspended) {
        if (suspended) {
            return 'Suspended';
        }
        return Utils.getEnumValueName(registrationStatusConstants, statusType);
    }
    static runAtDate(date, fn) {
        let timeout;
        const runLater = () => {
            const now = DateTime.utc().toMillis();
            const then = date.toMillis();
            const diff = Math.max((then - now), 0);
            if (diff > 0x7FFFFFFF) {
                timeout = setTimeout(runLater, 0x7FFFFFFF);
            }
            else {
                timeout = setTimeout(fn, diff);
            }
        };
        runLater();
        return () => {
            clearTimeout(timeout);
        };
    }
    static anchorScrollWithWait(id, waitTime = 5000) {
        if (Utils.findElementAndScroll(id)) {
            return;
        }
        const interval = setInterval(() => {
            if (Utils.findElementAndScroll(id)) {
                clearInterval(interval);
            }
        }, 200);
        setTimeout(() => {
            clearInterval(interval);
        }, waitTime);
    }
    static findElementAndScroll(id) {
        const element = document.getElementById(id);
        if (element) {
            try {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
            catch (e) {
                element.scrollIntoView();
            }
            return true;
        }
        return false;
    }
    static getExtensionFromFilename(filename) {
        if (!filename) {
            return '';
        }
        const i = filename.lastIndexOf('.');
        return i > 0
            ? filename.substr(i + 1)
            : '';
    }
}
