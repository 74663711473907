import { UiModule } from './../../../../index';
var INSERT_HTML_COMMAND_NAME = 'inserthtml';
UiModule.config(function () {
    if (!supportsInsertHtmlCommand()) {
        applyInsertHtmlPolyfill();
    }
});
function supportsInsertHtmlCommand() {
    return document.queryCommandSupported && document.queryCommandSupported(INSERT_HTML_COMMAND_NAME);
}
function applyInsertHtmlPolyfill() {
    polyfillInsertHtmlQueryCommandSupported();
    polyfillInsertHtmlExecCommand();
}
function polyfillInsertHtmlQueryCommandSupported() {
    var oldQueryCommandSupported = document.queryCommandSupported.bind(document);
    document.queryCommandSupported = function (command) {
        if (command.toLowerCase() === INSERT_HTML_COMMAND_NAME.toLowerCase()) {
            return true;
        }
        else {
            return oldQueryCommandSupported(command);
        }
    };
}
function polyfillInsertHtmlExecCommand() {
    var oldExecCommand = document.execCommand.bind(document);
    document.execCommand = function (commandName, useUi, options) {
        if (commandName.toLowerCase() === INSERT_HTML_COMMAND_NAME.toLowerCase()) {
            insertHtml(options);
            return true;
        }
        else {
            return oldExecCommand(commandName, useUi, options);
        }
    };
}
function insertHtml(html) {
    var selection = document.getSelection();
    var range = selection.getRangeAt(0);
    var el = document.createElement('div');
    el.innerHTML = html;
    var node;
    var lastNode = el.lastChild;
    while ((node = el.lastChild)) {
        range.insertNode(node);
    }
    if (lastNode) {
        var newRange = document.createRange();
        newRange.setStartAfter(lastNode);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
    }
}
