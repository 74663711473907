import { PriorityNavigationController } from './priority-navigation.controller';
export const PriorityNavigationComponent = {
    controller: PriorityNavigationController,
    template: '<div class="js-priority-menu priority-menu"><ng-transclude layout="row" layout-align="center center"></ng-transclude></div>',
    controllerAs: 'vm',
    bindings: {
        listPage: '=',
    },
    transclude: true,
};
