import { __awaiter, __generator } from "tslib";
import { StimuliPreviewDisplayType } from './isc-stimuli-preview.model';
var IscStimuliPreviewController = (function () {
    function IscStimuliPreviewController(iscStimuliTypeService, serverConstants, _, $scope, iscSocialStimuliService, iscStimuliDialogService) {
        this.iscStimuliTypeService = iscStimuliTypeService;
        this.serverConstants = serverConstants;
        this._ = _;
        this.$scope = $scope;
        this.iscSocialStimuliService = iscSocialStimuliService;
        this.iscStimuliDialogService = iscStimuliDialogService;
        this.carouselStimuli = [];
    }
    IscStimuliPreviewController.prototype.$onInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var list, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.stimuliTypes = this.iscStimuliTypeService.getTypes();
                        this.stimuliDisplayTypes = StimuliPreviewDisplayType;
                        if (this.config.displayType === this.stimuliDisplayTypes.carousel) {
                            this.setCarouselStimuli();
                            this.$scope.$watchCollection(function () { return _this.stimuli; }, function (newVal, oldVal) {
                                if (newVal !== oldVal) {
                                    _this.setCarouselStimuli();
                                }
                            });
                            list = this.carouselStimuli;
                        }
                        if (!(this.config.displayType === StimuliPreviewDisplayType.list)) return [3, 2];
                        _a = this;
                        return [4, this.parseStimuli(this.stimuli)];
                    case 1:
                        _a.stimuli = _b.sent();
                        list = this.stimuli;
                        _b.label = 2;
                    case 2:
                        if (list) {
                            list.forEach(function (stimulus) {
                                if (stimulus.IsBroken === true && stimulus.guid) {
                                    _this.$scope.$on("broken_stimulus_".concat(stimulus.guid), function (event, args) {
                                        _this.updateStimulus(stimulus.guid, args.url, args.thumbnail);
                                    });
                                }
                            });
                        }
                        return [2];
                }
            });
        });
    };
    IscStimuliPreviewController.prototype.updateStimulus = function (guid, url, thumbnail) {
        var list = [];
        if (this.config.displayType === StimuliPreviewDisplayType.carousel) {
            list = this.carouselStimuli;
        }
        else if (this.config.displayType === StimuliPreviewDisplayType.list) {
            list = this.stimuli;
        }
        if (list && list.length && list.length > 0) {
            list.forEach(function (stimulus) {
                if (stimulus.guid === guid) {
                    stimulus.url = url;
                    stimulus.thumbnailUrl = thumbnail;
                    return false;
                }
            });
        }
    };
    IscStimuliPreviewController.prototype.onStimulusRemoved = function (stimulus) {
        this.removeStimulus(stimulus);
    };
    IscStimuliPreviewController.prototype.removeStimulus = function (stimulus) {
        var stimulusIndex = this.stimuli.indexOf(stimulus);
        if (stimulusIndex !== -1) {
            if (stimulus.status === this.serverConstants.conversationStimuliStatusConstants.new) {
                if (!stimulus.guid) {
                    var hasFile = stimulus.file !== undefined && stimulus.file !== null;
                    if (hasFile) {
                        this._.pull(this.newStimulusFiles, stimulus.file);
                    }
                    this._.pull(this.stimuli, stimulus);
                    if (hasFile) {
                        for (var index = stimulusIndex; index < this.stimuli.length; index++) {
                            var element = this.stimuli[index];
                            if (element.id && element.file) {
                                var id = parseInt(element.id, 10);
                                if (!isNaN(id)) {
                                    this.stimuli[index].id = (id - 1).toString();
                                }
                            }
                        }
                    }
                }
            }
            else {
                stimulus.status = this.serverConstants.conversationStimuliStatusConstants.removed;
                this.stimuli[stimulusIndex] = stimulus;
            }
            this.setCarouselStimuli();
        }
        if (this.onFileRemoved) {
            var data = {
                removedItem: stimulus,
                isStimulus: true,
            };
            this.onFileRemoved({ data: data });
        }
    };
    IscStimuliPreviewController.prototype.removeAttachment = function (attachment) {
        var attachmentIndex = this.attachments.indexOf(attachment);
        if (attachmentIndex !== -1) {
            if (attachment.status === this.serverConstants.conversationStimuliStatusConstants.new) {
                this._.pull(this.newAttachmentFiles, attachment.file);
                this._.pull(this.attachments, attachment);
                for (var index = attachmentIndex; index < this.attachments.length; index++) {
                    var element = this.attachments[index];
                    if (element.id && element.file) {
                        var id = parseInt(element.id, 10);
                        if (!isNaN(id)) {
                            this.attachments[index].id = (id - 1).toString();
                        }
                    }
                }
            }
            else {
                attachment.status = this.serverConstants.conversationStimuliStatusConstants.removed;
                this.attachments[attachmentIndex] = attachment;
            }
        }
        if (this.onFileRemoved) {
            var data = {
                removedItem: attachment,
                isStimulus: false,
            };
            this.onFileRemoved({ data: data });
        }
    };
    IscStimuliPreviewController.prototype.parseStimuli = function (stimuli) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._.forEach(stimuli, function (stimulus) { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!!stimulus.thumbnailUrl) return [3, 2];
                                _a = stimulus;
                                return [4, this.iscSocialStimuliService.getStimulusThumbnail(stimulus)];
                            case 1:
                                _a.thumbnailUrl = _b.sent();
                                _b.label = 2;
                            case 2: return [2];
                        }
                    });
                }); });
                return [2, stimuli];
            });
        });
    };
    IscStimuliPreviewController.prototype.isImagePreview = function (stimulus) {
        return stimulus.status !== this.serverConstants.conversationStimuliStatusConstants.removed &&
            stimulus.type === this.stimuliTypes.image &&
            stimulus.isPreview === true;
    };
    IscStimuliPreviewController.prototype.isImage = function (stimulus) {
        return stimulus.status !== this.serverConstants.conversationStimuliStatusConstants.removed &&
            stimulus.type === this.stimuliTypes.image &&
            stimulus.isPreview === false;
    };
    IscStimuliPreviewController.prototype.isVideoPreview = function (stimulus) {
        return stimulus.status !== this.serverConstants.conversationStimuliStatusConstants.removed &&
            stimulus.type === this.stimuliTypes.video &&
            stimulus.isPreview === true;
    };
    IscStimuliPreviewController.prototype.isVideo = function (stimulus) {
        return stimulus.status !== this.serverConstants.conversationStimuliStatusConstants.removed &&
            stimulus.type === this.stimuliTypes.video &&
            stimulus.isPreview === false;
    };
    IscStimuliPreviewController.prototype.isYoutube = function (stimulus) {
        return stimulus.status !== this.serverConstants.conversationStimuliStatusConstants.removed &&
            stimulus.type === this.stimuliTypes.youtube;
    };
    IscStimuliPreviewController.prototype.isVimeo = function (stimulus) {
        return stimulus.status !== this.serverConstants.conversationStimuliStatusConstants.removed &&
            stimulus.type === this.stimuliTypes.vimeo;
    };
    IscStimuliPreviewController.prototype.hasRemoveButton = function (stimulus) {
        return stimulus.status !== this.serverConstants.conversationStimuliStatusConstants.removed &&
            !this.config.isDisabled;
    };
    IscStimuliPreviewController.prototype.displayAttachment = function (attachment) {
        return attachment.status !== this.serverConstants.conversationStimuliStatusConstants.removed;
    };
    IscStimuliPreviewController.prototype.setCarouselStimuli = function () {
        var _this = this;
        if (this.config.displayType === this.stimuliDisplayTypes.carousel) {
            this.carouselStimuli = this._.filter(this.stimuli, function (stimulus) {
                return stimulus.status !== _this.serverConstants.conversationStimuliStatusConstants.removed;
            });
        }
    };
    IscStimuliPreviewController.prototype.getUploadProgressForStimulus = function (stimulus) {
        if (this.uploadProgress) {
            return this.uploadProgress({ stimulus: stimulus });
        }
        return null;
    };
    IscStimuliPreviewController.prototype.isUploading = function (stimulus) {
        var isUploadProgress = this.getUploadProgress(stimulus) != null;
        if (isUploadProgress && stimulus.IsBroken) {
            stimulus.IsBroken = false;
        }
        return isUploadProgress;
    };
    IscStimuliPreviewController.prototype.getUploadProgress = function (stimulus) {
        var result = null;
        if (this.uploadProgress) {
            result = this.uploadProgress({ stimulus: stimulus });
            if (result === null && stimulus.file === undefined && this.stimulusThumbnail) {
                var thumbnailData = this.stimulusThumbnail({ stimulus: stimulus });
                if (thumbnailData) {
                    var found = this._.find(this.carouselStimuli, function (s) { return s.guid === stimulus.guid; });
                    if (found) {
                        found.thumbnailUrl = thumbnailData.thumbnailUrl;
                        found.url = thumbnailData.url;
                    }
                }
            }
        }
        return result;
    };
    IscStimuliPreviewController.prototype.getUploadAgain = function (stimulus) {
        if (this.uploadAgain) {
            return this.uploadAgain({ stimulus: stimulus });
        }
    };
    IscStimuliPreviewController.prototype.openStimuli = function (stimulus, event) {
        if (stimulus.thumbnailUrl == null) {
            return;
        }
        var newStimuliWithBigLatters = {
            Guid: stimulus.guid,
            Type: stimulus.type,
            Url: stimulus.url,
            Value: stimulus.value,
        };
        this.iscStimuliDialogService.openStimuli(newStimuliWithBigLatters, false, event.target);
    };
    IscStimuliPreviewController.prototype.isVideoPreviewClickable = function (stimulus) {
        return this.isVideoPreview(stimulus)
            ? stimulus.thumbnailUrl !== 'images/bg-video.png'
            : this.clickable !== undefined
                ? this.clickable && stimulus.thumbnailUrl !== '/images/default-stimuli.png'
                : stimulus.thumbnailUrl !== '/images/default-stimuli.png';
    };
    IscStimuliPreviewController.prototype.isStimulusThumbnailLoaded = function (stimulus) {
        if (stimulus.thumbnailUrl == null) {
            return false;
        }
        return true;
    };
    IscStimuliPreviewController.prototype.isBrokenStimulus = function (stimulus) {
        return stimulus.IsBroken && !this.isUploading(stimulus);
    };
    IscStimuliPreviewController.$inject = [
        'iscStimuliTypeService',
        'serverConstants',
        '_',
        '$scope',
        'iscSocialStimuli',
        'iscStimuliDialogService',
    ];
    return IscStimuliPreviewController;
}());
export { IscStimuliPreviewController };
