import { __extends } from "tslib";
import { SequencedCardControlllerBase } from '../../../card/sequencedCardControlllerBase';
import * as angular from 'angular';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
var ChallengeCardController = (function (_super) {
    __extends(ChallengeCardController, _super);
    function ChallengeCardController($element, iscStimuliService, serverConstants) {
        var _this = _super.call(this) || this;
        _this.$element = $element;
        _this.iscStimuliService = iscStimuliService;
        _this.serverConstants = serverConstants;
        _this.isCardViewed = false;
        return _this;
    }
    ChallengeCardController.prototype.$onInit = function () {
        var _this = this;
        this.index = this.getNextIndexFromSequence();
        var sequence = this.getSequence().cards;
        this.card = sequence[this.index];
        _.forEach(sequence, function (card) {
            _this.labelUpdaterResult = _this.createCardDateLabelUpdater({ card: card });
            card.StartDateTime = DateTime.fromISO(card.StartDateTime).toLocaleString(DateTime.DATETIME_MED);
        });
    };
    ChallengeCardController.prototype.$postLink = function () {
        this.unregisterCard = this.timeline.registerCard(this.card.ParentGuid || this.card.Guid, this.$element);
    };
    ChallengeCardController.prototype.$onDestroy = function () {
        this.unregisterCard();
        this.labelUpdaterResult.then(function (destroy) {
            destroy();
        });
    };
    ChallengeCardController.prototype.ctaClick = function () {
        this.onCtaClick({ card: this.card });
    };
    ChallengeCardController.prototype.trackViewed = function () {
        if (!this.isCardViewed) {
            this.onTrackViewed({ card: this.card });
            this.isCardViewed = true;
        }
    };
    ChallengeCardController.prototype.getSequence = function () {
        var result = { cards: [] };
        if (angular.isArray(this.sequence)) {
            result.cards = this.sequence;
            result.startIndex = this.sequence.startIndex;
        }
        else {
            result.cards = [this.sequence];
        }
        return result;
    };
    ChallengeCardController.prototype.getNextIndexFromSequence = function () {
        var result = 0;
        var sequence = this.getSequence();
        if (sequence.startIndex) {
            result = sequence.startIndex;
        }
        return result;
    };
    ChallengeCardController.prototype.hasStimuliOrDefault = function () {
        var result = this.defaultStimuli;
        if (this.card.Stimuli && this.card.Stimuli.Type) {
            result = this.card.Stimuli;
        }
        return result;
    };
    ChallengeCardController.prototype.isSticky = function () {
        return !this.card.Completed && this.card.StickyDate;
    };
    ChallengeCardController.prototype.showCTAButton = function () {
        return this.card.CallToActionText && this.card.CallToActionUrl;
    };
    ChallengeCardController.prototype.cardInStack = function () {
        var result = '';
        if (this.showTwoMoreCardsInStack()) {
            result = 'two-cards';
        }
        else if (this.showOneMoreCardInStack()) {
            result = 'one-card';
        }
        return result;
    };
    ChallengeCardController.prototype.isDiscussionActivity = function () {
        return !this.card.Completed || this.card.isDiscussionActivity;
    };
    ChallengeCardController.prototype.getCompletedLabel = function () {
        if (!this.card.Completed) {
            return '';
        }
        if (this.card.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.screened ||
            this.card.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.quotaFull) {
            return '(LabelChallengesHomePageChallengeNotQualified)';
        }
        return '(LabelChallengesHomePageChallengeCompleted)';
    };
    ChallengeCardController.prototype.isVideoStimuli = function () {
        return this.iscStimuliService.isVideoMimeType(this.card.Stimuli.Type);
    };
    ChallengeCardController.$inject = ['$element', 'iscStimuliService', 'serverConstants'];
    return ChallengeCardController;
}(SequencedCardControlllerBase));
export { ChallengeCardController };
