import { __awaiter } from "tslib";
import { CacheService } from '../core/services/cache.service';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { activityStartedKey } from '../core/dataservices/activity.service';
export class ChallengesService {
    constructor(squareService, $interval, constantsfactory, $q, $state, serverConstants, iscTranslate, notifications, authService) {
        this.squareService = squareService;
        this.$interval = $interval;
        this.constantsfactory = constantsfactory;
        this.$q = $q;
        this.$state = $state;
        this.serverConstants = serverConstants;
        this.iscTranslate = iscTranslate;
        this.notifications = notifications;
        this.authService = authService;
        this.cleanupFunctions = [];
        notifications.todoActivities
            .subscribe(() => __awaiter(this, void 0, void 0, function* () {
            this.listPromise = undefined;
        }));
    }
    listChallengeSequenceCards(includedGuid) {
        if (this.listPromise) {
            return this.listPromise.then((cards) => {
                cards = cards.map((card) => {
                    if (!angular.isArray(card)) {
                        return [card];
                    }
                    return card;
                });
                cards.forEach((sequence) => {
                    const dateLabelUpdaterPromises = sequence.map((card) => this.createCardDateLabelUpdater(card));
                    this.$q.all(dateLabelUpdaterPromises).then((dateLabelDisposers) => {
                        this.cleanupFunctions = this.cleanupFunctions.concat(dateLabelDisposers);
                    });
                });
                return cards;
            });
        }
        this.listPromise = this.squareService
            .getChallengeSequencePageCards(includedGuid)
            .then((cards) => {
            cards = cards.map((card) => {
                if (!angular.isArray(card)) {
                    return [card];
                }
                return card;
            });
            cards.forEach((sequence) => {
                sequence.forEach((card) => {
                    card.Stimuli = {
                        ThumbnailUrl: card.StimuliThumbnailUrl || card.StimuliUrl,
                        Value: card.StimuliUrl,
                        Url: card.StimuliUrl,
                        Type: card.FileType,
                    };
                    card.showLength = card.MessageType !== this.serverConstants.messageTypeConstants.qualActivity;
                    card.isDiscussionActivity = card.MessageType === this.serverConstants.messageTypeConstants.qualActivity;
                    card.route =
                        card.MessageType === this.serverConstants.messageTypeConstants.qualActivity
                            ? 'container.main.challenges.qualactivity'
                            : 'container.activity';
                    if (card.CompletionTarget != null) {
                        card.spotsLeft = Math.max(card.CompletionTarget - card.ParticipantsCompleted, 0);
                    }
                });
                const dateLabelUpdaterPromises = sequence.map((card) => this.createCardDateLabelUpdater(card));
                this.$q.all(dateLabelUpdaterPromises).then((dateLabelDisposers) => {
                    this.cleanupFunctions = this.cleanupFunctions.concat(dateLabelDisposers);
                });
            });
            return cards;
        });
        return this.listPromise;
    }
    createCardDateLabelUpdater(card) {
        return __awaiter(this, void 0, void 0, function* () {
            let destroy = _.noop;
            const [year, yearpl, month, monthpl, day, daypl, hours, minutes, seconds] = yield this.$q.all([
                this.constantsfactory.getLabelValue('LabelChallengeYear'),
                this.constantsfactory.getLabelValue('LabelChallengeYearPlural'),
                this.constantsfactory.getLabelValue('LabelChallengeMonth'),
                this.constantsfactory.getLabelValue('LabelChallengeMonthPlural'),
                this.constantsfactory.getLabelValue('LabelChallengeDay'),
                this.constantsfactory.getLabelValue('LabelChallengeDayPlural'),
                this.constantsfactory.getLabelValue('LabelChallengesHoursAbbr'),
                this.constantsfactory.getLabelValue('LabelChallengeMinutesAbbr'),
                this.constantsfactory.getLabelValue('LabelChallengeSecondsAbbr'),
            ]);
            const now = DateTime.utc();
            const end = DateTime.fromISO(card.EndDateTime);
            const diff = end.diff(now, ['years', 'months', 'days']);
            if (diff.as('milliseconds') <= 0) {
                card.DateLabel = '0';
            }
            else {
                if (diff.as('days') < 1) {
                    destroy = this.setCounter(card, hours, minutes, seconds);
                }
                else {
                    card.DateLabel =
                        (diff.years !== 0 ? (diff.years === 1 ? `1 ${year} ` : `${Math.floor(diff.years)} ${yearpl} `) :
                            (diff.months !== 0 ? (diff.months === 1 ? `1 ${month} ` : `${Math.floor(diff.months)} ${monthpl} `) :
                                (diff.days === 1 ? `1 ${day}` : `${Math.floor(diff.days)} ${daypl}`)));
                }
            }
            return destroy;
        });
    }
    setCounter(card, hoursLabel, minutesLabel, secondsLabel) {
        const updater = this.updateCounter(card, hoursLabel, minutesLabel, secondsLabel);
        const interval = this.$interval(updater, 1000);
        updater();
        return () => {
            this.$interval.cancel(interval);
        };
    }
    updateCounter(card, hoursLabel, minutesLabel, secondsLabel) {
        return () => {
            const now = DateTime.utc();
            const end = DateTime.fromISO(card.EndDateTime);
            const diff = end.diff(now, ['hours', 'minutes', 'seconds']).toObject();
            const dateDiff = {
                hours: Math.floor(diff.hours),
                minutes: Math.floor(diff.minutes),
                seconds: Math.floor(diff.seconds),
            };
            card.DateLabel = (dateDiff.hours !== 0 ? `${this.iscTranslate.replaceArguments(hoursLabel, dateDiff)} ` : '')
                + (dateDiff.minutes !== 0 ? `${this.iscTranslate.replaceArguments(minutesLabel, dateDiff)} ` : '')
                + this.iscTranslate.replaceArguments(secondsLabel, dateDiff);
        };
    }
    filterSequences(sequences, filter) {
        const challengesSequences = [];
        _.forEach(sequences, (sequence) => {
            let matchIndex = _.findIndex(sequence, (card) => filter(card));
            if (matchIndex >= 0) {
                if (sequence[matchIndex].Completed) {
                    matchIndex = this.getStartIndexOfCompletedSequence(sequence);
                }
                const challengesSequence = {
                    cards: sequence,
                    startIndex: matchIndex,
                };
                challengesSequences.push(challengesSequence);
            }
        });
        return challengesSequences;
    }
    getSequenceStartIndex(sequence) {
        return this.makeSequences([sequence])[0].startIndex;
    }
    makeSequences(sequences) {
        const challengesSequences = [];
        _.forEach(sequences, (sequence) => {
            let matchIndex = _.findIndex(sequence, this.hasTodoStatus);
            if (matchIndex < 0) {
                matchIndex = _.findIndex(sequence, this.hasInProgressStatus);
            }
            if (matchIndex < 0) {
                matchIndex = this.getStartIndexOfCompletedSequence(sequence);
            }
            if (matchIndex < 0) {
                matchIndex = 0;
            }
            const challengesSequence = {
                cards: sequence,
                startIndex: matchIndex,
            };
            challengesSequences.push(challengesSequence);
        });
        return challengesSequences;
    }
    hasTodoStatus(card) {
        return !card.Started && !card.Completed;
    }
    hasInProgressStatus(card) {
        return card.Started && !card.Completed;
    }
    hasCompletedStatus(card) {
        return card.Completed;
    }
    listTodoChallenges() {
        return __awaiter(this, void 0, void 0, function* () {
            const cards = yield this.listChallengeSequenceCards(null);
            let sequences = this.filterSequences(cards, (card) => !this.hasCompletedStatus(card));
            sequences = this.sortSequences(sequences);
            return {
                themeCount: this.getNumberOfThemes(cards),
                groups: this.groupByTheme(sequences),
            };
        });
    }
    listCompletedChallenges() {
        return __awaiter(this, void 0, void 0, function* () {
            const activityStartedSampleGuid = CacheService.getCacheValue(activityStartedKey);
            CacheService.removeCacheValue(activityStartedKey);
            const cards = yield this.listChallengeSequenceCards(activityStartedSampleGuid);
            let sequences = this.filterSequences(cards, this.hasCompletedStatus);
            sequences = this.sortSequences(sequences);
            return {
                themeCount: this.getNumberOfThemes(cards),
                groups: this.groupByTheme(this.sortSequences(sequences)),
            };
        });
    }
    listChallenges() {
        return __awaiter(this, void 0, void 0, function* () {
            const cards = yield this.listChallengeSequenceCards();
            let sequences = this.makeSequences(cards);
            sequences = this.sortSequences(sequences);
            return {
                themeCount: this.getNumberOfThemes(cards),
                groups: this.groupByTheme(sequences),
            };
        });
    }
    getNumberOfThemes(sequencesList) {
        return _.chain(sequencesList).map((sequence) => sequence[0].ThemeName).uniq().value().length;
    }
    groupByTheme(sequencesList) {
        return _.chain(sequencesList)
            .groupBy((sequence) => sequence.cards[0].ThemeName)
            .map((sequencesInTheme) => ({
            sequences: sequencesInTheme,
            themeName: sequencesInTheme[0].cards[0].ThemeName,
            themeOrder: sequencesInTheme[0].cards[0].ThemeSortOrder || -100,
        }))
            .orderBy((sequencesInTheme) => sequencesInTheme.themeOrder)
            .value();
    }
    getStartIndexOfCompletedSequence(cards) {
        let sortedCards = cards.slice();
        sortedCards = sortedCards.sort((a, b) => this.relevanceComparer(a, b, false));
        return _.findIndex(cards, sortedCards[0]);
    }
    sortSequences(sequences) {
        return sequences.sort((a, b) => {
            const cardA = a.cards[a.startIndex];
            const cardB = b.cards[b.startIndex];
            return this.relevanceComparer(cardA, cardB, true);
        });
    }
    relevanceComparer(cardA, cardB, showLatestFirst) {
        if (!(cardA.StickyDate && cardB.StickyDate) && (cardA.StickyDate || cardB.StickyDate)) {
            return cardA.StickyDate ? -1 : 1;
        }
        if (cardA.CardStatus !== cardB.CardStatus) {
            let importanceA;
            let importanceB;
            switch (cardA.CardStatus) {
                case this.serverConstants.cardStatusConstants.none:
                    importanceA = 0;
                    break;
                case this.serverConstants.cardStatusConstants.new:
                    importanceA = 2;
                    break;
                case this.serverConstants.cardStatusConstants.update:
                    importanceA = 1;
                    break;
            }
            switch (cardB.CardStatus) {
                case this.serverConstants.cardStatusConstants.none:
                    importanceB = 0;
                    break;
                case this.serverConstants.cardStatusConstants.new:
                    importanceB = 2;
                    break;
                case this.serverConstants.cardStatusConstants.update:
                    importanceB = 1;
                    break;
            }
            return importanceB - importanceA;
        }
        if (cardA.NotificationSortOrder !== cardB.NotificationSortOrder) {
            if (!cardA.NotificationSortOrder) {
                return 1;
            }
            if (!cardB.NotificationSortOrder) {
                return -1;
            }
            return cardA.NotificationSortOrder - cardB.NotificationSortOrder;
        }
        return showLatestFirst ? (cardA.StartDateTime < cardB.StartDateTime ? 1 : -1) : (cardA.StartDateTime < cardB.StartDateTime ? -1 : 1);
    }
    destroy() {
        this.cleanupFunctions.forEach((cleanup) => cleanup());
        this.cleanupFunctions = [];
        this.listPromise = undefined;
    }
}
ChallengesService.$inject = [
    'squareService',
    '$interval',
    'constantsfactory',
    '$q',
    '$state',
    'serverConstants',
    'iscTranslate',
    'notifications',
    'authService',
];
