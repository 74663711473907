import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { guidRegex } from '@/router/router.constants';
import { checkAzureAdB2CLogin } from '@/pages/__helpers__/form-related-pages';

const ChangePasswordPage = () => import('@/pages/change-password-page.vue');

export const changePasswordRoute: RouteRecordRaw = {
  name: RouteNames.ChangePassword,
  path: `/changepassword/:forgotPassGuid(${guidRegex})`,
  component: ChangePasswordPage,
  meta: {
    requireLogin: false,
    title: '(LabelWindowTitleChangePassword)',
  },
  beforeEnter: async () => await checkAzureAdB2CLogin(),
};
