import { NotificationsServiceBase } from './notificationservicebase';
import { SplashPageCheckDispatcher } from '../../splashPage/splashPageCheckDispatcher';
export class NotificationsService extends NotificationsServiceBase {
    constructor(signalr, logger, $rootScope, $q, __env, authService, userService, squareFactory) {
        super(signalr, logger, $rootScope, $q, __env, authService);
        this.userService = userService;
        this.squareFactory = squareFactory;
        this.authService.on('login', () => {
            this.connect();
        });
        this.authService.on('logout', () => {
            this.onLogout();
            this.removeUserFromGroup(this.squareFactory.Guid);
        });
        this.profileActivities = this.createReplayEvent('Notifications', 'profileActivitiesChanged');
        this.progress = this.createReplayEvent('Notifications', 'progressChanged');
        this.totalCredits = this.createReplayEvent('Notifications', 'totalCreditsChanged');
        this.ideaStreamLoginFinished = this.createReplayEvent('IdeaStream', 'ideaStreamLoginFinished');
        this.pHealthDummyEvent = this.createReplayEvent('PlatformHealth', 'pHealthDummyEvent');
        this.userNotification = this.createReplayEvent('Notifications', 'UserNotification');
        this.timeLineChanged = this.createEvent('Notifications', 'TimeLineChanged');
        this.pagesChanged = this.createReplayEvent('Notifications', 'PagesChanged');
        this.todoActivities = this.createReplayEvent('Notifications', 'todoActivities');
        this.suspendedNotification = this.createReplayEvent('Notifications', 'suspendedNotification');
        this.showVideoThumbnail = this.createReplayEvent('Notifications', 'ShowVideoThumbnail');
        this.showPhotoThumbnail = this.createReplayEvent('Notifications', 'ShowPhotoThumbnail');
        this.updateAttachmentUrl = this.createEvent('Notifications', 'UpdateAttachmentUrl');
        this.discussionActivityComplete = this.createReplayEvent('Notifications', 'discussionActivityComplete');
        this.forumConversationChanged = this.createReplayEvent('Notifications', 'forumConversationChanged');
        this.discussionChanged = this.createReplayEvent('Notifications', 'discussionChanged');
        this.userLoggedOut = this.createReplayEvent('Notifications', 'userLoggedOut');
        this.squareStatusChanged = this.createEvent('Notifications', 'squareStatusChanged');
        this.showEngagementCardVideoThumbnail = this.createReplayEvent('Notifications', 'ShowEngagementCardVideoThumbnail');
        this.squareStatusChanged.subscribe((data) => {
            this.squareFactory.Status = data.Status;
            SplashPageCheckDispatcher.requiresRecheck();
        });
    }
    init() {
        this.userService.squareParticipantGuidChange.subscribe(() => {
            this.reConnect();
        });
        this.userLoggedOut.subscribe(() => {
            this.authService.signOut(true);
        });
    }
    reConnect() {
        this.disconnect();
        this.connect();
    }
    getSignalRToken() {
        return this.authService.getOrRenewSignalRToken();
    }
    ideaStreamSsoLoginFinished(connectionId, redirectUrl) {
        return this.forwardCallToHub('IdeaStream', 'ideaStreamSsoLoginFinished', connectionId, redirectUrl);
    }
    pageVisited(path) {
        return this.forwardCallToHub('PlatformHealth', 'pageVisited', path);
    }
    elementClicked(event) {
        return this.forwardCallToHub('PlatformHealth', 'elementClicked', event.Identifier, event.ChannelType, event.TargetGuid);
    }
    elementViewed(event) {
        return this.forwardCallToHub('PlatformHealth', 'elementViewed', event.Identifier, event.ChannelType, event.TargetGuid);
    }
}
NotificationsService.$inject = ['signalr', 'logger', '$rootScope', '$q', '__env', 'authService', 'userService', 'squareFactory'];
angular
    .module('insitesApp.core')
    .service('notifications', NotificationsService);
