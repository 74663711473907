// We need to make sure that our Vuex store can be used in our AngularJS controllers
// without having to change the code there

import { checkFeatureAccessibility,
  isFeatureEnabledForSquare,
  isForceMobileAppEnabledForSquare,
  isMasterFeatureEnabledForSquare } from '@/services/feature-service';

interface FeatureService {
  isFeatureEnabledForSquare: (feature: number) => Promise<boolean>;
  checkFeatureAccessibility: (feature: number) => Promise<boolean>;
  isForceMobileAppEnabledForSquare: () => Promise<boolean>;
  isMasterFeatureEnabledForSquare: (feature: number) => Promise<boolean>;
}

export default {
  isFeatureEnabledForSquare,
  checkFeatureAccessibility,
  isForceMobileAppEnabledForSquare,
  isMasterFeatureEnabledForSquare,
} as FeatureService;
