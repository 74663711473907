import { __awaiter } from "tslib";
export class ChangePasswordController {
    constructor($state, participantService, imagesFactory, $stateParams, _, serverConstants, loginFlowService, featureToggleService, captchaService, featureService) {
        this.$state = $state;
        this.participantService = participantService;
        this.imagesFactory = imagesFactory;
        this.$stateParams = $stateParams;
        this._ = _;
        this.serverConstants = serverConstants;
        this.loginFlowService = loginFlowService;
        this.featureToggleService = featureToggleService;
        this.captchaService = captchaService;
        this.featureService = featureService;
        this.shouldShowFooter = this.featureToggleService.shouldShowFooter;
        this.images = this.imagesFactory.images;
        this.ShowError = false;
        this.changeRequest = {
            Password: '',
            ConfirmPassword: '',
            SquareParticipantChangeRequestGuid: this.$stateParams.forgotPassGuid,
        };
        this.validationConstants = this.serverConstants.validationConstants;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const isAzureADFeatureEnabled = yield this.featureService.isMasterFeatureEnabledForSquare(this.serverConstants.featureConstants.azureAdB2CLogin);
            if (isAzureADFeatureEnabled) {
                this.$state.go('container.main.home');
            }
            this.imagesFactory.applyImages();
            this.participantService.checkForgotPassUrlValidity(this.changeRequest.SquareParticipantChangeRequestGuid).then((response) => {
                if (response) {
                    this.isUrlValid = response.data.IsUrlValid;
                }
            });
        });
    }
    changePassword() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.participantService.changePasswordForSquareParticipant(this.changeRequest);
                if (response != null && response.status === 200) {
                    const email = response.data.Email;
                    const password = this.changeRequest.Password;
                    const captchaResponse = yield this.captchaService.getCaptchaResponse('changePassword');
                    this.loginFlowService.login(email, password, captchaResponse);
                }
                else {
                    this.ShowError = true;
                }
            }
            catch (error) {
                this.handleChangePasswordError(error);
            }
        });
    }
    goToForgotPass() {
        this.$state.go('container.resetPassword');
    }
    handleChangePasswordError(error) {
        if (error.status === 400) {
            const data = error.data;
            const grouped = this._.groupBy(data.ValidationErrors, 'PropertyName');
            this._.forEach(grouped, (item, key) => {
                let message = '';
                this._.forEach(item, (errorMessage) => {
                    message += `${errorMessage.ErrorMessage} `;
                });
                this.changePasswordForm[key].$setValidity('serverErrors', false);
                this.changePasswordForm[key].errorMessage = message;
            });
        }
    }
}
ChangePasswordController.$inject = ['$state', 'participantService', 'imagesFactory', '$stateParams', '_',
    'serverConstants', 'loginFlowService', 'featureToggleService', 'captchaService', 'featureService'];
