<script setup lang="ts">
import HeaderConsumerLogo from './header-consumer-logo.vue';
import { useImagesStore, emptyImage } from '@/store/images-store';
import { computed } from 'vue';

const imagesStore = useImagesStore();
const images = computed(() => imagesStore.$state);

</script>

<template>
  <div class="navigation-bar-logos__header row justify-center">
    <div
      v-if="images.logoTopBar && images.logoTopBar !== emptyImage"
      ref="logosDiv"
      class="row navigation-bar-logos__header-consumer-logo items-center"
    >
      <header-consumer-logo :show-logo-centered="true" />
    </div>
  </div>
</template>

<style lang="scss">
.navigation-bar-logos {
  &__header {
    background: linear-gradient(to right, var(--navigation-bar-start) 0%, var(--navigation-bar-end) 100%);
  }

  &__header-consumer-logo {
    flex-grow: 1;
  }
}
</style>
