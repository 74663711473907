import { isFunction, noop, once } from 'lodash-es';
import { Emitter } from './events.types';
import { convertPascalCasedObjectToCamelCasedObject } from '@ui/common/utils/convert-case';
import { convertDateTimesForObject } from '@ui/common/utils/convert-luxon';

export class EventPubSub<T> {
  constructor(
    public eventName: string,
    private shouldResolveInitialValueOnConnect: boolean = true) {
  }

  private lastValue?: T;
  private initialValue?: T | Promise<T> | (() => Promise<T>);
  private emitter: Emitter<T> | undefined;
  private callback: (data: T) => void = noop;

  // Sets the initial value
  // Wil be used for resetSubscriptions
  // Will also be used on the first subscribe if the shouldResolveInitialValueOnConnect property is true
  public startWith(promiseFactoryOrValue: T | Promise<T> | (() => Promise<T>)) {
    this.initialValue = promiseFactoryOrValue;
    return this;
  }

  // Receives a emitter param which is actually a proxy object from SignalR
  // Subscribe to changes for the event, whenever changes come in on that subscription,
  // we call the callback function.
  // If the shouldResolveInitialValueOnConnect property is set to true,
  // the initial value will be set if this is the first subscription.
  // Returns a function to unsubscribe from the proxy changes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public useEmitter(emitter: Emitter<T>): () => void {
    this.lastValue = undefined;
    this.emitter = emitter;
    const handler = (data: T) => {
      this.resolve(data);
    };
    return emitter.off.bind(emitter, this.eventName, handler);
  }

  public subscribe(callback: (data: T) => void, doNotConvert = false) {
    const adaptedCallback = doNotConvert ? callback : (data: T) => {
      data = convertPascalCasedObjectToCamelCasedObject(data) as T;
      data = convertDateTimesForObject(data) as T;
      callback(data);
    };
    this.callback = adaptedCallback;
    this.emitter?.on(this.eventName, adaptedCallback);
    return () => this.emitter?.off(this.eventName, adaptedCallback);
  }

  // Will reset the data to the initial state
  // This method is called on every connect (so the first one, and every reconnect) and resolves the initial value if needed
  public resetSubscriptions() {
    this.lastValue = undefined;
    if (this.shouldResolveInitialValueOnConnect) {
      this.resolveInitialValue();
    }
  }

  private resolve(data: T) {
    if (this.lastValue !== data) {
      this.lastValue = data;
      this.callback(data);
    }
  }

  private resolveInitialValue() {
    // If last value differs from undefined,
    // It means that this isn't the initial resolve so we emit the data that we already had.
    // This can happen if we subscribe multiple times to the same event.
    // The first subscription should set the initial value, but we don't want the second subscription to do that again.
    if (this.lastValue !== undefined) {
      this.resolve(this.lastValue);
    } else if (this.initialValue !== undefined) {
      let init = this.initialValue;
      if (isFunction(init)) {
        init = init();
      }
      Promise.resolve(init).then((data) => {
        this.resolve(data);
      });
    }
  }

  next(data: T) {
    if (this.lastValue !== data || (this.lastValue === undefined && data === undefined)) {
      this.lastValue = data;
    }
  }

  // not used yet, but will be when the Angular Component where it is used will be transfered to Vue
  public toPromise(): Promise<T> {
    if (this.lastValue !== undefined) {
      return Promise.resolve(this.lastValue);
    }
    if (this.initialValue !== undefined) {
      let init = this.initialValue;
      if (isFunction(init)) {
        init = init();
      }
      return Promise.resolve(init);
    }

    return new Promise((resolve) => {
      this.subscribe(once((data: T) => {
        Promise.resolve(data).then((d) => {
          resolve(d);
        });
      }));
    });
  }
}
