import { DateTime } from 'luxon';
var CommunicationCardController = (function () {
    function CommunicationCardController($element, iscStimuliService) {
        this.$element = $element;
        this.iscStimuliService = iscStimuliService;
        this.isCardViewed = false;
    }
    CommunicationCardController.prototype.$onInit = function () {
        this.card.DateDiff = DateTime.fromISO(this.card.StartDateTime).toRelative({ base: DateTime.utc() });
    };
    CommunicationCardController.prototype.$postLink = function () {
        this.unregisterCard = this.timeline.registerCard(this.card.Guid, this.$element);
    };
    CommunicationCardController.prototype.$onDestroy = function () {
        this.unregisterCard();
    };
    CommunicationCardController.prototype.ctaClick = function () {
        this.onCtaClick({ card: this.card });
    };
    CommunicationCardController.prototype.trackViewed = function () {
        if (!this.isCardViewed) {
            this.isCardViewed = true;
            this.onTrackViewed({ card: this.card });
        }
    };
    CommunicationCardController.prototype.hasStimuli = function () {
        return this.card.Stimuli && this.card.Stimuli.Type;
    };
    CommunicationCardController.prototype.isSticky = function () {
        return !this.card.Completed && this.card.StickyDate;
    };
    CommunicationCardController.prototype.showCTAButton = function () {
        return this.card.CallToActionText && this.card.CallToActionUrl;
    };
    CommunicationCardController.prototype.openCTAUrl = function () {
        var target = this.card.OpenInNewTab ? '_blank' : undefined;
        window.open(this.card.CallToActionUrl, target);
    };
    CommunicationCardController.prototype.isVideoStimuli = function () {
        return this.iscStimuliService.isVideoMimeType(this.card.Stimuli.Type);
    };
    CommunicationCardController.$inject = ['$element', 'iscStimuliService'];
    return CommunicationCardController;
}());
export { CommunicationCardController };
