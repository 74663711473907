var ReplayEventPubSub = (function () {
    function ReplayEventPubSub(scope, eventName, $q, _) {
        this.scope = scope;
        this.eventName = eventName;
        this.$q = $q;
        this._ = _;
        this.subscriptionsCount = 0;
        this.shouldResolveInitialValueOnSubscribe = true;
        this.shouldMapOnInitialResolve = true;
    }
    ReplayEventPubSub.prototype.startWith = function (promiseFactoryOrValue) {
        this.initialValue = promiseFactoryOrValue;
        return this;
    };
    ReplayEventPubSub.prototype.useEmitter = function (emitter) {
        var _this = this;
        this.lastValue = undefined;
        var handler = function (data) {
            _this.scope.$apply(function () {
                _this.next(data);
            });
        };
        emitter.on(this.eventName, handler);
        return emitter.off.bind(emitter, this.eventName, handler);
    };
    ReplayEventPubSub.prototype.subscribe = function (callback) {
        var _this = this;
        var unsubscribeFromScope = this.scope.$on(this.eventName, function (e, data) {
            callback(data);
        });
        this.subscriptionsCount++;
        if (this.shouldResolveInitialValueOnSubscribe) {
            this.resolveInitialValue();
        }
        return function () {
            _this.subscriptionsCount--;
            unsubscribeFromScope();
        };
    };
    ReplayEventPubSub.prototype.resetSubscriptions = function () {
        this.lastValue = undefined;
        if (this.subscriptionsCount > 0 && this.shouldResolveInitialValueOnSubscribe) {
            this.resolveInitialValue();
        }
    };
    ReplayEventPubSub.prototype.next = function (data, isInitialResolve) {
        if (isInitialResolve === void 0) { isInitialResolve = false; }
        if (this.lastValue !== data || (this.lastValue === undefined && data === undefined)) {
            if (this.mapping && (!isInitialResolve || this.shouldMapOnInitialResolve)) {
                data = data.map(this.mapping);
            }
            this.lastValue = data;
            this.scope.$emit(this.eventName, data);
        }
    };
    ReplayEventPubSub.prototype.resolveInitialValue = function () {
        var _this = this;
        if (this.lastValue !== void 0) {
            this.next(this.lastValue, true);
        }
        else if (this.initialValue !== void 0) {
            var init = this.initialValue;
            if (this._.isFunction(init)) {
                init = init();
            }
            this.$q.resolve(init).then(function (data) {
                _this.next(data, true);
            });
        }
    };
    ReplayEventPubSub.prototype.toPromise = function () {
        var _this = this;
        if (this.lastValue !== void 0 || this.initialValue !== void 0) {
            return this.$q.resolve((this.lastValue || this.initialValue));
        }
        return this.$q(function (resolve) {
            var unsubscribe = _this.subscribe(_this._.once(function (data) {
                _this.$q.resolve(data).then(function (d) {
                    unsubscribe();
                    resolve(d);
                });
            }));
        });
    };
    ReplayEventPubSub.prototype.withMapping = function (mapping, shouldMapOnInitialResolve) {
        this.mapping = mapping;
        this.shouldMapOnInitialResolve = shouldMapOnInitialResolve;
        return this;
    };
    return ReplayEventPubSub;
}());
export { ReplayEventPubSub };
