import { __awaiter } from "tslib";
import * as _ from 'lodash';
export class IscPagesService {
    constructor(pageService, featureService, $state, serverConstants, authService, $transitions) {
        this.pageService = pageService;
        this.featureService = featureService;
        this.$state = $state;
        this.serverConstants = serverConstants;
        this.authService = authService;
        this.$transitions = $transitions;
        this.allPages = [];
        this.mainPages = [];
        this.helpCenterPages = [];
        this.incentives = [];
        this.footerPages = [];
        this.PageType = this.serverConstants.pageTypeConstants;
        this.Area = this.serverConstants.areaConstants;
        this.init = _.memoize(() => this.pageService.listPageConsumer().then((response) => {
            this.syncPageResponse(response.data.List);
            const pagesByType = _.groupBy(this.allPages, (page) => page.Area);
            this.mainPages = pagesByType[this.Area.mainPages] || [];
            this.helpCenterPages = pagesByType[this.Area.helpCenter] || [];
            this.footerPages = pagesByType[this.Area.footer] || [];
            this.incentives = pagesByType[this.Area.incentives] || [];
            this.initPageRoutes();
        }));
        this.authService.on('logout', () => {
            this.init.cache = new _.memoize.Cache();
        });
        this.$transitions.onSuccess({}, () => {
            for (const page of this.mainPages) {
                this.updateActive(page);
            }
            for (const page of this.incentives) {
                this.updateActive(page);
            }
        });
        this.$transitions.onBefore({ to: 'container.main.incentives.myincentive' }, () => __awaiter(this, void 0, void 0, function* () {
            const isSavingUpEnabled = yield this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards);
            if (isSavingUpEnabled) {
                return this.$state.target('container.main.incentives.mysavinguprewards');
            }
        }));
        this.$transitions.onBefore({ to: 'container.main.incentives.mysavinguprewards' }, () => __awaiter(this, void 0, void 0, function* () {
            const isSavingUpEnabled = yield this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards);
            if (!isSavingUpEnabled) {
                return this.$state.target('container.main.incentives.myincentive');
            }
        }));
    }
    resetPagesCache() {
        this.init.cache = new _.memoize.Cache();
    }
    goToPage(page, replace = false) {
        this.$state.go(page.Route, page.RouteParams, replace ? { location: 'replace' } : undefined);
    }
    syncPageResponse(pagesResponse) {
        if (this.allPages.length === 0) {
            this.allPages = pagesResponse;
            return;
        }
        const pagesByGuid = _.groupBy(pagesResponse, (page) => page.Guid);
        for (let index = 0; index < this.allPages.length; index++) {
            if (!pagesByGuid[this.allPages[index].Guid]) {
                this.allPages.splice(index, 1);
            }
        }
        const arrTemp = new Array(pagesResponse.length);
        pagesResponse.forEach((page, index) => {
            const existingPage = _.find(this.allPages, (s) => s.Guid === page.Guid);
            arrTemp[index] = existingPage ? existingPage : page;
        });
        this.allPages = arrTemp;
    }
    initPageRoutes() {
        this.initMainPageRoutes();
        this.initHelpCenterPageRoutes();
        this.initIncentivesPagesRoutes();
        this.initFooterPagesRoutes();
        this.updateLinkAndTarget(IscPagesService.profilePage);
    }
    initMainPageRoutes() {
        _.each(this.mainPages, (item) => {
            switch (item.PageType) {
                case this.PageType.home: {
                    item.Route = 'container.main.home';
                    break;
                }
                case this.PageType.activities: {
                    item.Route = 'container.main.challenges';
                    item.RouteParams = { pageGuid: item.Guid };
                    break;
                }
                case this.PageType.forum: {
                    item.Route = 'container.main.forum';
                    item.RouteParams = { pageGuid: item.Guid };
                    break;
                }
                case this.PageType.ideabox: {
                    item.Route = 'container.main.ideabox';
                    item.RouteParams = { pageGuid: item.Guid };
                    break;
                }
                case this.PageType.news: {
                    item.Route = 'container.main.news';
                    item.RouteParams = { pageGuid: item.Guid };
                    break;
                }
                case this.PageType.mainPageCustom: {
                    item.Route = 'container.main.custom';
                    item.RouteParams = { pageGuid: item.Guid };
                    break;
                }
                default:
                    item.Route = item.Route || 'container.main.home';
                    break;
            }
            this.updateLinkAndTarget(item);
            this.updateActive(item);
        });
    }
    initHelpCenterPageRoutes() {
        _.each(this.helpCenterPages, (item) => {
            switch (item.PageType) {
                case this.PageType.faq: {
                    item.Route = 'container.main.helpcenter.faq';
                    item.RouteParams = { helpcenterPage: item.Guid };
                    break;
                }
                case this.PageType.platformTour: {
                    item.Route = 'container.main.helpcenter.platformtour';
                    item.RouteParams = { helpcenterPage: item.Guid };
                    break;
                }
                case this.PageType.contact: {
                    item.Route = 'container.main.helpcenter.contact';
                    item.RouteParams = { helpcenterPage: item.Guid };
                    break;
                }
                case this.PageType.helpCenterCustom: {
                    item.Route = 'container.main.helpcenter.custom';
                    item.RouteParams = { helpcenterPage: item.Guid };
                    break;
                }
                default:
                    item.Route = 'container.main.home';
                    break;
            }
        });
    }
    initIncentivesPagesRoutes() {
        return __awaiter(this, void 0, void 0, function* () {
            const isAuthorized = yield this.authService.isAuthorized();
            const isSavingUpEnabled = isAuthorized ? yield this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards) : false;
            _.each(this.incentives, (item) => {
                switch (item.PageType) {
                    case this.PageType.incentivesCustom: {
                        item.Route = 'container.main.incentives.custom';
                        item.RouteParams = { customPage: item.Guid };
                        break;
                    }
                    case this.PageType.myIncentive:
                        item.Route = isSavingUpEnabled ? 'container.main.incentives.mysavinguprewards' : 'container.main.incentives.myincentive';
                        break;
                }
                this.updateLinkAndTarget(item);
                this.updateActive(item);
            });
        });
    }
    initFooterPagesRoutes() {
        _.each(this.footerPages, (item) => {
            item.Route = 'container.main.footer';
            item.RouteParams = { pageGuid: item.Guid };
            this.updateLinkAndTarget(item);
            this.updateActive(item);
        });
    }
    updateActive(page) {
        page.Active = this.$state.current.name.indexOf(page.Route) === 0 &&
            (!page.RouteParams || Object.getOwnPropertyNames(page.RouteParams).length === 0 ||
                _.every(page.RouteParams, (value, key) => this.$state.params[key] === value));
    }
    updateLinkAndTarget(page) {
        page.Link = page.CustomUrl ? page.CustomUrl : this.$state.href(page.Route, page.RouteParams);
        page.Target = page.CustomUrl ? (page.OpenInNewTab ? '_blank' : '_self') : null;
    }
}
IscPagesService.profilePage = {
    Icon: 'profile',
    Route: 'container.main.profile',
    Name: '(LabelNavigationProfile)',
    Added: false,
};
IscPagesService.$inject = ['pageService', 'featureService', '$state', 'serverConstants', 'authService', '$transitions'];
