import { __awaiter } from "tslib";
import { NotificationsServiceBase } from './notificationservicebase';
import { Utils } from '../utils';
import * as _ from 'lodash';
export class CardService {
    constructor(iscPages, squareService, serverConstants, constants, notifications, userService, $window, $state, pageThemes, mediaservice, challengesService) {
        this.iscPages = iscPages;
        this.squareService = squareService;
        this.serverConstants = serverConstants;
        this.constants = constants;
        this.notifications = notifications;
        this.userService = userService;
        this.$window = $window;
        this.$state = $state;
        this.pageThemes = pageThemes;
        this.mediaservice = mediaservice;
        this.challengesService = challengesService;
    }
    getTimelineCards(lastCardGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.iscPages.init();
            const newsPages = this.iscPages.mainPages.filter((element) => element.PageType === 5)
                .map((element) => element.Guid);
            const data = yield this.squareService.getSquareTimelineCards(newsPages, lastCardGuid);
            data.List = yield this.adjustTimelineCardsManager(data.List);
            return data;
        });
    }
    activityCardClickedOnHomePage(card) {
        this.activityCardClicked(card, this.serverConstants.channelTypeConstants.ResearchActivityChallengeHome);
    }
    activityCardClickedOnChallengesPage(card) {
        this.activityCardClicked(card, this.serverConstants.channelTypeConstants.ResearchActivityChallenge);
    }
    activityCardClicked(card, channel) {
        const event = {
            Identifier: 'challenge-card-click',
            ChannelType: channel,
            TargetGuid: card.CommunicationSampleGuid,
        };
        if (this.mediaservice.isMobileApp) {
            event.ChannelType = this.serverConstants.channelTypeConstants.researchActivityMobileApp;
        }
        this.notifications.elementClicked(event);
    }
    adjustTimelineCardsManager(cards) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = [];
            yield this.iscPages.init();
            for (const card of cards) {
                if (angular.isArray(card)) {
                    const items = yield this.adjustCards(card);
                    const parentGuid = items[0].Guid;
                    items.Guid = parentGuid;
                    items.ChannelType = items[0].ChannelType;
                    items.forEach((child) => {
                        child.ParentGuid = parentGuid;
                    });
                    items.startIndex = this.challengesService.getSequenceStartIndex(items);
                    result.push(items);
                }
                else {
                    if (yield this.adjustCard(card)) {
                        result.push(card);
                    }
                }
            }
            return result;
        });
    }
    adjustCards(cards) {
        return __awaiter(this, void 0, void 0, function* () {
            cards = _.filter(cards, (card) => this.adjustCard(card));
            return cards;
        });
    }
    adjustCard(card) {
        card.Message = this.replaceMessageParameters(card.Message);
        card.Stimuli = {
            Guid: card.StimuliGuid,
            ThumbnailUrl: card.StimuliThumbnailUrl || card.StimuliUrl,
            Value: card.StimuliUrl,
            Url: card.StimuliUrl,
            Type: card.FileType,
        };
        switch (card.ChannelType) {
            case this.serverConstants.channelTypeConstants.researchActivityChallenge:
                this.adjustChallengeCard(card);
                break;
            case this.serverConstants.channelTypeConstants.researchActivityChallengeHome:
                this.adjustChallengeCard(card);
                break;
            case this.serverConstants.channelTypeConstants.communicationCard:
                if (!this.adjustCommunicationCard(card)) {
                    return false;
                }
                break;
            case this.serverConstants.channelTypeConstants.communicationNews:
                this.adjustNewsCard(card);
                break;
        }
        if (this.constants.isInIframe && !card.OpenInNewTab && card.CallToActionUrl && card.CallToActionUrl.indexOf('ideastreamsso') >= 0) {
            card.OpenInNewTab = true;
            card.CallToActionUrl = Utils.buildUrl(card.CallToActionUrl, {
                connectionId: NotificationsServiceBase.ConnectionId,
                token: this.userService.getToken(),
            });
        }
        return true;
    }
    adjustChallengeCard(card) {
        this.adjustCardForPageType(card, this.serverConstants.pageTypeConstants.activities);
        if (card.MessageType === this.serverConstants.messageTypeConstants.qualActivity) {
            card.route = {
                sref: 'container.main.challenges.qualactivity',
            };
        }
        else {
            card.route = {
                sref: 'container.activity',
            };
        }
    }
    adjustNewsCard(card) {
        const page = _.find(this.iscPages.mainPages, (x) => x.Guid === card.NewsPageGuid);
        if (page) {
            card.Icon = page.Icon;
            card.PageGuid = page.Guid;
            card.Theme = this.pageThemes.getPageTheme(page.Guid);
        }
    }
    adjustCommunicationCard(card) {
        const homePage = _.find(this.iscPages.mainPages, (x) => x.PageType === this.serverConstants.pageTypeConstants.home);
        const squareUrl = this.$window.location.origin;
        const ctaUrl = card.CallToActionUrl;
        let page = homePage;
        if (ctaUrl != null && ctaUrl.indexOf(squareUrl) === 0) {
            const pageUrl = ctaUrl.replace(squareUrl, '');
            const pageGuid = this.getPageGuid(pageUrl);
            if (pageGuid) {
                const mainPage = _.find(this.iscPages.mainPages, (x) => x.Guid === pageGuid);
                if (mainPage) {
                    page = mainPage;
                }
                else {
                    const otherPage = _.find(this.iscPages.allPages, (x) => x.Guid === pageGuid);
                    if (!otherPage) {
                        return false;
                    }
                }
            }
            else {
                const routeIncentiveProfile = _.find(this.$state.get(), (state) => state.$$state() && state.$$state().url && state.$$state().url.exec(pageUrl));
                if (routeIncentiveProfile) {
                    if (routeIncentiveProfile.name.indexOf('container.main.profile') === 0) {
                        card.Icon = 'default-profile';
                    }
                    else if (routeIncentiveProfile.name.indexOf('container.main.incentives') === 0) {
                        card.Icon = 'database';
                    }
                    else if (routeIncentiveProfile.name.indexOf('container.main.helpcenter') === 0) {
                        card.Icon = 'info-large';
                    }
                    if (card.Icon) {
                        page = null;
                    }
                }
            }
        }
        if (page) {
            this.adjustCardForPage(card, page);
        }
        else {
            card.Theme = this.pageThemes.defaultGreyTheme;
        }
        this.adjustIdeaStreamCardUrl(card);
        return true;
    }
    getPageGuid(pageUrl) {
        const pageRoute = this.$state.get()
            .map((state) => {
            const url = state.$$state() && state.$$state().url;
            if (url) {
                return state.$$state().url.exec(pageUrl);
            }
        })
            .filter((x) => x != null);
        if (pageRoute.length) {
            return pageRoute[0].pageGuid;
        }
    }
    adjustCardForPageType(card, pageType) {
        const page = _.find(this.iscPages.mainPages, (x) => x.PageType === pageType);
        if (page) {
            this.adjustCardForPage(card, page);
        }
    }
    adjustCardForPage(card, page) {
        card.Icon = page.Icon;
        card.PageGuid = page.Guid;
        card.Theme = this.pageThemes.getPageTheme(page.Guid);
    }
    adjustIdeaStreamCardUrl(card) {
        if (card.CallToActionUrl && card.CallToActionUrl.indexOf('ideastreamsso') >= 0) {
            card.CallToActionUrl = Utils.buildUrl(card.CallToActionUrl, {
                sourceGuid: card.CommunicationSampleGuid,
                source: this.serverConstants.ideaStreamProjectTargetConstants.engagementMessage,
            });
        }
    }
    replaceMessageParameters(message) {
        const faqPage = _.find(this.iscPages.allPages, (item) => item.PageType === this.serverConstants.pageTypeConstants.faq);
        const contactPage = _.find(this.iscPages.allPages, (item) => item.PageType === this.serverConstants.pageTypeConstants.contact);
        const aboutThisProjectPage = _.find(this.iscPages.mainPages, (item) => item.IsAboutThisProjectPage);
        if (faqPage) {
            const faqUrl = this.$state.href('container.main.helpcenter.faq', { helpcenterPage: faqPage.Guid }, { absolute: true });
            message = message.replaceAll('{faqpage}', faqUrl);
        }
        if (contactPage) {
            const contactUrl = this.$state.href('container.main.helpcenter.contact', { helpcenterPage: contactPage.Guid }, { absolute: true });
            message = message.replaceAll('{supportpage}', contactUrl);
        }
        if (aboutThisProjectPage) {
            const aboutThisProjectUrl = this.$state.href('container.main.news', { pageGuid: aboutThisProjectPage.Guid }, { absolute: true });
            message = message.replaceAll('{aboutthisprojectpage}', aboutThisProjectUrl);
        }
        return message;
    }
}
CardService.$inject = ['iscPages', 'squareService', 'serverConstants', 'constants', 'notifications',
    'userService', '$window', '$state', 'pageThemes', 'mediaservice', 'challengesService'];
