import * as angular from 'angular';
var IscDropDownController = (function () {
    function IscDropDownController($element, $scope, _) {
        var _this = this;
        this.$element = $element;
        this.$scope = $scope;
        this._ = _;
        this.searchTerm = '';
        $scope.$watch('vm.model', function () { _this.ensureModelController(); });
    }
    IscDropDownController.prototype.getValue = function (option) {
        if (!this.valueProperty || option[this.valueProperty] === undefined) {
            return option;
        }
        return option[this.valueProperty];
    };
    IscDropDownController.prototype.getLabel = function (option) {
        if (!this.labelProperty || option[this.labelProperty] === undefined) {
            return option;
        }
        return option[this.labelProperty];
    };
    IscDropDownController.prototype.getTitle = function () {
        var _a;
        return (_a = this.label) !== null && _a !== void 0 ? _a : this.ariaLabel;
    };
    IscDropDownController.prototype.clearSearchTerm = function () {
        this.searchTerm = '';
    };
    IscDropDownController.prototype.onClicked = function () {
        this.ensureModelController();
    };
    IscDropDownController.prototype.ensureModelController = function () {
        var element = this.$element.find('md-select');
        this.ngModelController = element.controller('ngModel');
    };
    IscDropDownController.prototype.customSelectedText = function () {
        var _this = this;
        var customSelectedText = this.selectedText(this.model);
        if (customSelectedText !== undefined) {
            return customSelectedText;
        }
        if (!angular.isArray(this.model) && !this._.isUndefined(this.model)) {
            return this.getValueFromModel(this.model);
        }
        if (angular.isArray(this.model) && this.model.length) {
            return this.model.map(function (item) { return _this.getValueFromModel(item); }).join(', ');
        }
        return (this.placeholder || this.label) || '';
    };
    IscDropDownController.prototype.toggleSelectAll = function () {
        var _this = this;
        var selectAllState = false;
        if (this._.some(this.options, function (option) { return option.Selected === true; })) {
            selectAllState = false;
        }
        else {
            selectAllState = true;
        }
        this._.forEach(this.options, function (option) {
            option.Selected = selectAllState;
            if (selectAllState) {
                _this.model.push(option.Guid);
            }
            else {
                _this.model = [_this.selectAllOption.Text];
            }
        });
    };
    IscDropDownController.prototype.getValueFromModel = function (model) {
        var _this = this;
        if (this.valueProperty && this.labelProperty && this.options !== undefined && this.options.length > 0) {
            var modelItem = this._.filter(this.options, function (item) { return item[_this.valueProperty] === model; });
            if (modelItem !== undefined && modelItem.length > 0) {
                return modelItem[0][this.labelProperty];
            }
        }
        if (!this.valueProperty && this.labelProperty) {
            return model[this.labelProperty];
        }
        return model;
    };
    IscDropDownController.$inject = ['$element', '$scope', '_'];
    return IscDropDownController;
}());
export { IscDropDownController };
