import { __awaiter } from "tslib";
import { Utils } from './../../core/utils';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
export class ForumConversationController {
    constructor($stateParams, $state, $scope, forumService, conversationService, serverConstants, constants, notifications, conversationEventsService, userNotificationService, muxService, fileStorageService, userService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$scope = $scope;
        this.forumService = forumService;
        this.conversationService = conversationService;
        this.serverConstants = serverConstants;
        this.constants = constants;
        this.notifications = notifications;
        this.conversationEventsService = conversationEventsService;
        this.userNotificationService = userNotificationService;
        this.muxService = muxService;
        this.fileStorageService = fileStorageService;
        this.userService = userService;
        this.unsubscribeDiscussionChange = _.noop;
        this.unsubscribeForum = _.noop;
        this.currentPage = 0;
        this.totalPages = 1;
        this.conversationElements = [];
        this.stimuli = [];
        this.inDraftRepliesAdded = false;
    }
    $onInit() {
        this.initPromise = this.init();
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.conversationGuid = this.$stateParams.conversationGuid;
            const defaultSortOption = this.serverConstants.conversationSortOptionConstants.chronological;
            this.sortOptions = this.serverConstants.conversationSortOptionConstants;
            this.isMobileAppUser = this.userService.isMobileAppUser();
            this.sortBy = this.$stateParams.sort === undefined ? defaultSortOption : parseInt(this.$stateParams.sort, 10);
            this.currentPage = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
            const loadParentConversation = () => __awaiter(this, void 0, void 0, function* () {
                const parentConversation = yield this.forumService.getConversation(this.$stateParams.conversationGuid);
                if (!parentConversation.IsRoomActive) {
                    this.$state.go('container.main.home');
                }
                parentConversation.IsLiked = parentConversation.Likes.IsLiked;
                parentConversation.LikesCount = parentConversation.Likes.LikeCount;
                parentConversation.Level = 0;
                this.conversation = parentConversation;
            });
            yield loadParentConversation();
            yield this.loadConversationElements();
            this.scrollReply(this.$stateParams.replyGuid);
            this.unsubscribeShowVideoThumbnail = this.notifications.showVideoThumbnail.subscribe((stimuli) => this.updateStimuliUrlAndThumbnailUrl(this.serverConstants.conversationStimuliTypeConstants.video, stimuli));
            this.unsubscribeShowPhotoThumbnail = this.notifications.showPhotoThumbnail.subscribe((stimuli) => this.updateStimuliUrlAndThumbnailUrl(this.serverConstants.conversationStimuliTypeConstants.image, stimuli));
            this.unsubscribeUpdateAttachmentUrl = this.notifications.updateAttachmentUrl.subscribe((attachment) => this.updateAttachmentUrl(attachment));
            this.unsubscribeDiscussionChange = this.conversationEventsService.forumConversationChange.subscribe(this.$stateParams.conversationGuid, () => __awaiter(this, void 0, void 0, function* () {
                yield loadParentConversation();
                yield this.loadConversationElements();
            }));
            this.unsubscribeForum = this.forumService.onConversationChanged.subscribe(() => __awaiter(this, void 0, void 0, function* () {
                yield this.loadConversationElements();
                if (this.conversationElements[0]) {
                    this.scrollReply(this.conversationElements[0].Guid);
                }
            }));
            yield this.userNotificationService.markTopicNotificationsRead(this.$stateParams.conversationGuid);
            this.watchStateParams();
        });
    }
    updateStimuliUrlAndThumbnailUrl(stimuliType, stimuliUploaded) {
        _.forEach(this.conversationElements, (item) => {
            _.forEach(item.Stimuli, (stimulus) => {
                if (stimulus.Guid === stimuliUploaded.Guid) {
                    stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
                    if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                        stimulus.Value = stimuliUploaded.Url;
                        this.fileStorageService.removeStimulusFromUploadingList(stimulus.Guid);
                    }
                    else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                        stimulus.Url = stimuliUploaded.Url;
                        this.muxService.removeStimulusFromUploadingList(stimulus.Value);
                    }
                    return false;
                }
            });
            if (item.ProbeQuestions) {
                _.forEach(item.ProbeQuestions, (probequestion) => {
                    if (probequestion.Stimuli) {
                        _.forEach(probequestion.Stimuli, (stimulus) => {
                            if (stimulus.Guid === stimuliUploaded.Guid) {
                                stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
                                if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                                    stimulus.Value = stimuliUploaded.Url;
                                    this.fileStorageService.removeStimulusFromUploadingList(stimulus.Guid);
                                }
                                else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                                    stimulus.Url = stimuliUploaded.Url;
                                    this.muxService.removeStimulusFromUploadingList(stimulus.Value);
                                }
                                return false;
                            }
                        });
                    }
                });
            }
        });
        if (this.conversation && this.conversation.Stimuli && this.conversation.Stimuli.length > 0) {
            _.forEach(this.conversation.Stimuli, (stimulus) => {
                if (stimulus.Guid === stimuliUploaded.Guid) {
                    stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
                    return false;
                }
            });
        }
    }
    updateAttachmentUrl(attachmentUploaded) {
        _.forEach(this.conversationElements, (item) => {
            _.forEach(item.Attachments, (attachment) => {
                if (attachment.Guid === attachmentUploaded.Guid) {
                    attachment.Url = attachmentUploaded.Url;
                }
                return false;
            });
            if (item.ProbeQuestions) {
                _.forEach(item.ProbeQuestions, (probeQuestion) => {
                    if (probeQuestion.Attachments && probeQuestion.Attachments.length) {
                        _.forEach(probeQuestion.Attachments, (attachment) => {
                            if (attachment.Guid === attachmentUploaded.Guid) {
                                attachment.Url = attachmentUploaded.Url;
                                return false;
                            }
                        });
                    }
                    if (probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
                        _.forEach(probeQuestion.Answer.Attachments, (attachment) => {
                            if (attachment.Guid === attachmentUploaded.Guid) {
                                attachment.Url = attachmentUploaded.Url;
                                return false;
                            }
                        });
                    }
                });
            }
            if (item.ProbeQuestionsForEditor) {
                _.forEach(item.ProbeQuestionsForEditor, (probeQuestion) => {
                    if (probeQuestion.answer && probeQuestion.answer.attachments && probeQuestion.answer.attachments.length) {
                        _.forEach(probeQuestion.answer.attachments, (attachment) => {
                            if (attachment.guid === attachmentUploaded.Guid) {
                                attachment.url = attachmentUploaded.Url;
                                return false;
                            }
                        });
                    }
                });
            }
        });
    }
    $onDestroy() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.initPromise;
            }
            finally {
                this.unsubscribeShowVideoThumbnail();
                this.unsubscribeShowPhotoThumbnail();
                this.unsubscribeUpdateAttachmentUrl();
                this.unsubscribeDiscussionChange();
                this.unsubscribeForum();
                if (this.watchParams) {
                    this.watchParams();
                }
            }
        });
    }
    loadConversationElements() {
        return __awaiter(this, void 0, void 0, function* () {
            this.totalPages = 1;
            const conversationElements = yield this.conversationService.loadForumConversationElements(this.$stateParams.conversationGuid, this.currentPage, this.constants.itemsPerPage, this.sortBy);
            this.totalPages = conversationElements.TotalPages;
            this.conversationElements =
                this.conversationService.addInDraftRepliesToPost(conversationElements.Items.concat(this.conversation))
                    .filter(((conv) => conv !== this.conversation));
            const userGuid = this.userService.userGuid();
            this.conversationElements.forEach((item) => {
                if (item.Stimuli && item.Stimuli.length) {
                    const mom = DateTime.fromISO(item.DateCreated).plus({ hours: 4 });
                    if (mom < DateTime.utc()) {
                        item.Stimuli.forEach((stimulus) => {
                            stimulus.IsBroken =
                                (!stimulus.Url && stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.video)
                                    ||
                                        (!stimulus.Value && stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image);
                            if (stimulus.IsBroken === true) {
                                stimulus.IsBrokenAndBelongsToCurrentUser = item.SquareParticipantGuid === userGuid;
                            }
                        });
                    }
                }
            });
            this.inDraftRepliesAdded = true;
        });
    }
    navigate(pageNumber) {
        this.totalPages = 1;
        this.currentPage = pageNumber;
        this.conversationService.navigate(pageNumber, this.sortBy, []);
        this.forumService.pageChangedInForumConversation();
    }
    onSortChange(value) {
        this.sortBy = value;
        this.navigate(1);
    }
    scrollReply(replyGuid) {
        if (replyGuid) {
            Utils.anchorScrollWithWait(replyGuid);
        }
    }
    watchStateParams() {
        if (!this.watchParams) {
            this.watchParams = this.$scope.$watch(() => this.$stateParams.conversationGuid, (conversationGuid) => __awaiter(this, void 0, void 0, function* () {
                if (conversationGuid && conversationGuid !== this.conversationGuid) {
                    yield this.init();
                }
            }));
        }
    }
}
ForumConversationController.$inject = ['$stateParams', '$state', '$scope',
    'forumService', 'conversationService', 'serverConstants', 'constants',
    'notifications', 'conversationEventsService', 'userNotificationService',
    'muxService', 'fileStorageService', 'userService'];
