import * as _ from 'lodash';
import { isArray } from 'angular';
import * as angular from 'angular';
import { IscDraftService } from '../../services/activity/draft.service';
var ProbeQuestionsAnswerEditorController = (function () {
    function ProbeQuestionsAnswerEditorController(serverConstants, $scope, iscDraftService, iscTranslate) {
        this.serverConstants = serverConstants;
        this.$scope = $scope;
        this.iscDraftService = iscDraftService;
        this.iscTranslate = iscTranslate;
        this.buttonClicked = false;
        this.previousSavedAnswers = [];
        this.displaySkipQuestionButton = false;
        this.allAnswersEmpty = false;
        this.showSaveAsDraft = false;
        this.savedAsDraftMessage = '';
    }
    ProbeQuestionsAnswerEditorController.prototype.$onInit = function () {
        var _this = this;
        this.previousSavedAnswers = this.probeQuestions.map(function (question) { return angular.copy(question.answer); });
        var allTopicQuestionsAnswered = this.probeQuestions
            && isArray(this.probeQuestions)
            && this.probeQuestions.every(function (q) { return q.hasBeenAnswered; });
        this.allAnswersEmpty = allTopicQuestionsAnswered
            && this.probeQuestions.filter(function (q) { return !q.answerRequired && (!q.answer || !q.answer.isValidAnswer); }).length === this.probeQuestions.length;
        this.shouldHideAnswers = this.isReadonly;
        this.setSkipQuestionButtonVisibility();
        this.$scope.$watchCollection('vm.probeQuestions', function () {
            _this.setSkipQuestionButtonVisibility();
            _this.buttonClicked = false;
        });
        var uid = this.draftUid ? this.draftUid() : undefined;
        if (uid && uid !== '') {
            this.iscDraftService.setOnDraftSaving(uid, function () {
                _this.showSaveAsDraft = true;
                _this.iscTranslate.replaceLabels('(QualConversationSavingAsDraftMessage)')
                    .then(function (label) {
                    _this.savedAsDraftMessage = label;
                    setTimeout(function () {
                        _this.savedAsDraftMessage = null;
                        _this.showSaveAsDraft = false;
                    }, 2000);
                });
                return true;
            });
            this.iscDraftService.setOnDraftSaved(uid, function () {
                _this.showSaveAsDraft = true;
                var date = new Date();
                _this.iscTranslate.replaceLabels('(QualConversationSavedAsDraftMessage)')
                    .then(function (label) {
                    _this.savedAsDraftMessage =
                        "".concat(label, " ").concat(IscDraftService.formatSavedDate(date));
                    setTimeout(function () {
                        _this.savedAsDraftMessage = null;
                        _this.showSaveAsDraft = false;
                    }, 2000);
                });
                return true;
            });
        }
    };
    ProbeQuestionsAnswerEditorController.prototype.$onChanges = function () {
        var _this = this;
        this.allQuestionsAnswered = this.probeQuestions
            && isArray(this.probeQuestions)
            && this.probeQuestions.every(function (q) { return q.hasBeenAnswered && !_this.isReadonly; });
        this.shouldHideAnswers = this.isReadonly &&
            this.allQuestionsAnswered &&
            !this.hasBeenAnswered;
        this.setSkipQuestionButtonVisibility();
    };
    ProbeQuestionsAnswerEditorController.prototype.isCurrentAnswerValid = function () {
        var currentQuestion = this.probeQuestions[this.probeQuestions.length - 1];
        if (this.isOneByOne) {
            if (currentQuestion.answerRequired || !this.isEmptyAnswer(currentQuestion.answer)) {
                return this.isValidAnswerForQuestion(currentQuestion);
            }
            else {
                return false;
            }
        }
        return true;
    };
    ProbeQuestionsAnswerEditorController.prototype.areAllProbeAnswersValid = function () {
        var _this = this;
        var isValid = true;
        var oneQuestionAnswered = false;
        _.forEach(this.probeQuestions, function (question) {
            if (!question.answer && question.answerRequired) {
                isValid = false;
                return false;
            }
            if (question.answerRequired || !_this.isEmptyAnswer(question.answer)) {
                var isValidAnswer = _this.isValidAnswerForQuestion(question);
                isValid = isValid && isValidAnswer;
                oneQuestionAnswered = isValidAnswer;
            }
        });
        return isValid && oneQuestionAnswered;
    };
    ProbeQuestionsAnswerEditorController.prototype.getSkipButtonText = function () {
        if (this.remainingQuestions <= 1) {
            return '(LabelSkipQuestionAndSubmitAnswers)';
        }
        return '(LabelSkipQuestion)';
    };
    ProbeQuestionsAnswerEditorController.prototype.isEmptyAnswer = function (answer) {
        return (answer.message === null || answer.message === undefined || !answer.message.trim()) &&
            (answer.stimuli === null || answer.stimuli === undefined || answer.stimuli.length === 0) &&
            (answer.attachments === null || answer.attachments === undefined || answer.attachments.length === 0);
    };
    ProbeQuestionsAnswerEditorController.prototype.isValidAnswerForQuestion = function (question) {
        var _this = this;
        var availableStimuli = _.filter(question.answer.stimuli, function (stimuli) {
            return stimuli.status !== _this.serverConstants.conversationStimuliStatusConstants.removed;
        });
        var availableAttachments = _.filter(question.answer.attachments, function (attachment) {
            return attachment.status !== _this.serverConstants.conversationStimuliStatusConstants.removed;
        });
        var isValid = true;
        switch (question.questionType) {
            case this.serverConstants.conversationQuestionTypeConstants.text:
                if (!question.answer.message || question.answer.message.trim().length === 0) {
                    isValid = false;
                }
                break;
            case this.serverConstants.conversationQuestionTypeConstants.image:
                if (!question.answer.stimuli || question.answer.stimuli.length === 0) {
                    isValid = false;
                }
                var imageStimuli = _.filter(question.answer.stimuli, function (stimuli) {
                    return stimuli.type === _this.serverConstants.conversationStimuliTypeConstants.image &&
                        stimuli.status !== _this.serverConstants.conversationStimuliStatusConstants.removed;
                });
                if (!imageStimuli || imageStimuli.length === 0) {
                    isValid = false;
                }
                break;
            case this.serverConstants.conversationQuestionTypeConstants.video:
                if (!question.answer.stimuli || question.answer.stimuli.length === 0) {
                    isValid = false;
                }
                var videoStimuli = _.filter(question.answer.stimuli, function (stimuli) {
                    return (stimuli.type === _this.serverConstants.conversationStimuliTypeConstants.video
                        || stimuli.type === _this.serverConstants.conversationStimuliTypeConstants.youtube
                        || stimuli.type === _this.serverConstants.conversationStimuliTypeConstants.vimeo)
                        && stimuli.status !== _this.serverConstants.conversationStimuliStatusConstants.removed;
                });
                if (!videoStimuli || videoStimuli.length === 0) {
                    isValid = false;
                }
                break;
            case this.serverConstants.conversationQuestionTypeConstants.imageAndVideo:
                if (!availableStimuli || availableStimuli.length === 0) {
                    isValid = false;
                }
                break;
            case this.serverConstants.conversationQuestionTypeConstants.attachment:
                if (!availableAttachments || availableAttachments.length === 0) {
                    isValid = false;
                }
                break;
            case this.serverConstants.conversationQuestionTypeConstants.freestyle:
                if ((!question.answer.message || question.answer.message.trim().length === 0) &&
                    (!availableStimuli || availableStimuli.length === 0) &&
                    (!availableAttachments || availableAttachments.length === 0)) {
                    isValid = false;
                }
                break;
            default: isValid = false;
        }
        return isValid;
    };
    ProbeQuestionsAnswerEditorController.prototype.saveAnswers = function () {
        this.buttonClicked = true;
        this.onSave();
    };
    ProbeQuestionsAnswerEditorController.prototype.skipQuestion = function () {
        this.buttonClicked = true;
        var currentQuestion = this.probeQuestions[this.probeQuestions.length - 1];
        if (!currentQuestion.answerRequired) {
            currentQuestion.answer.message = '';
            currentQuestion.answer.caption = '';
            currentQuestion.answer.stimuli = [];
            currentQuestion.answer.attachments = [];
            currentQuestion.answer.newStimulusFiles = [];
            currentQuestion.answer.newAttachmentFiles = [];
            this.onSave();
        }
    };
    ProbeQuestionsAnswerEditorController.prototype.isProbingQuestionEditorDisabled = function (question) {
        return (this.isReadonly && !this.isEditMode) ||
            this.buttonClicked;
    };
    ProbeQuestionsAnswerEditorController.prototype.iscMentionSearchFn = function (term) {
        if (this.iscMentionSearch) {
            return this.iscMentionSearch({ term: term });
        }
        return null;
    };
    ProbeQuestionsAnswerEditorController.prototype.iscMentionSelectFn = function (item) {
        if (this.iscMentionSelect) {
            return this.iscMentionSelect({ item: item });
        }
        return null;
    };
    ProbeQuestionsAnswerEditorController.prototype.iscHashtagSearchFn = function (term) {
        if (this.iscHashtagSearch) {
            return this.iscHashtagSearch({ term: term });
        }
        return null;
    };
    ProbeQuestionsAnswerEditorController.prototype.iscHashtagSelectFn = function (item) {
        if (this.iscHashtagSelect) {
            return this.iscHashtagSelect({ item: item });
        }
        return null;
    };
    ProbeQuestionsAnswerEditorController.prototype.setSkipQuestionButtonVisibility = function () {
        var currentQuestion = this.probeQuestions[this.probeQuestions.length - 1];
        if (this.isOneByOne && currentQuestion && !currentQuestion.answerRequired) {
            this.displaySkipQuestionButton = true;
        }
        else {
            this.displaySkipQuestionButton = false;
        }
    };
    ProbeQuestionsAnswerEditorController.prototype.getUploadProgress = function (stimulus) {
        if (this.uploadProgress) {
            return this.uploadProgress({ stimulus: stimulus });
        }
        return null;
    };
    ProbeQuestionsAnswerEditorController.prototype.getStimulusThumbnail = function (stimulus) {
        return this.stimulusThumbnail && stimulus
            ? this.stimulusThumbnail({ stimulus: stimulus })
            : null;
    };
    ProbeQuestionsAnswerEditorController.prototype.getUploadAgain = function (stimulus) {
        if (this.uploadAgain) {
            return this.uploadAgain({ stimulus: stimulus });
        }
    };
    ProbeQuestionsAnswerEditorController.$inject = [
        'serverConstants',
        '$scope',
        'iscDraftService',
        'iscTranslate',
    ];
    return ProbeQuestionsAnswerEditorController;
}());
export { ProbeQuestionsAnswerEditorController };
