import { __awaiter } from "tslib";
export class SplashPageCheckDispatcher {
    constructor($transitions, splashCheckService, authService, $state, squareFactory, serverConstants) {
        authService.on('logout', () => {
            SplashPageCheckDispatcher.requiresRecheck();
        });
        authService.on('login', () => {
            SplashPageCheckDispatcher.registerTransition($transitions, splashCheckService, authService, $state, squareFactory, serverConstants);
        });
    }
    static registerTransition($transitions, splashCheckService, authService, $state, squareFactory, serverConstants) {
        const allowedPagesInClosedSquare = ['container.main.incentives.mysavinguprewards', 'container.main.incentives.myincentive',
            'container.main.profile', 'container.main.helpcenter', 'container.main.helpcenter.faq',
            'container.main.helpcenter.platformtour', 'container.main.helpcenter.contact', 'container.main.helpcenter.custom',
            'container.main.profile.edit', 'container.main.more', 'container.main.incentive-landingpage-form',
            'container.main.profile.activity'];
        const deregisterTermsTransition = $transitions.onBefore({}, (transition) => __awaiter(this, void 0, void 0, function* () {
            let canActivate = true;
            const targetState = transition.to().$$state();
            const weAreAlreadyOnTheSplashPage = targetState.name === 'container.splashPage';
            const isLogoutPage = targetState.name === 'container.logout';
            const isTargetStateRequiringLogin = targetState.requireLogin !== false;
            if (!weAreAlreadyOnTheSplashPage && isTargetStateRequiringLogin && !isLogoutPage) {
                const isAuthorized = yield authService.isAuthorized();
                if (!SplashPageCheckDispatcher.isAlreadyCheckedInTheCurrentUserSession && isAuthorized) {
                    if (squareFactory.Status === serverConstants.squareStatusConstants.closed
                        && allowedPagesInClosedSquare.indexOf(targetState.name) !== -1) {
                        return canActivate;
                    }
                    const redirectUrl = $state.href(targetState.name, transition.targetState()._params);
                    canActivate = yield splashCheckService.resolveSplashPageFlow(redirectUrl);
                    SplashPageCheckDispatcher.isAlreadyCheckedInTheCurrentUserSession = canActivate;
                    if (canActivate) {
                        deregisterTermsTransition();
                    }
                }
            }
            return canActivate;
        }));
    }
    static requiresRecheck() {
        SplashPageCheckDispatcher.isAlreadyCheckedInTheCurrentUserSession = false;
    }
}
SplashPageCheckDispatcher.$inject = ['$transitions', 'splashCheckService', 'authService', '$state', 'squareFactory', 'serverConstants'];
SplashPageCheckDispatcher.isAlreadyCheckedInTheCurrentUserSession = false;
