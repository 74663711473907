import { __awaiter } from "tslib";
import { Subject } from 'rxjs';
export class ConversationEventsService {
    constructor(notifications) {
        this.notifications = notifications;
        this._discussionChangeDone = {};
        this._conversationChangeDone = {};
        this.conversationListChanged = new Subject();
    }
    conversationChangeStarted(topicGuid) {
        if (!this._conversationChangeDone[topicGuid]) {
            this._conversationChangeDone[topicGuid] = {
                openedEditorsCount: 1,
                subject: new Subject(),
            };
        }
        else {
            this._conversationChangeDone[topicGuid].openedEditorsCount++;
        }
    }
    discussionChangeStarted(activityGuid) {
        if (!this._discussionChangeDone[activityGuid]) {
            this._discussionChangeDone[activityGuid] = {
                openedEditorsCount: 1,
                subject: new Subject(),
            };
        }
        else {
            this._discussionChangeDone[activityGuid].openedEditorsCount++;
        }
    }
    conversationChangeEnded(topicGuid) {
        if (this._conversationChangeDone[topicGuid]) {
            if (this._conversationChangeDone[topicGuid].openedEditorsCount === 1) {
                this._conversationChangeDone[topicGuid].subject.next(topicGuid);
                this._conversationChangeDone[topicGuid].subject.complete();
                delete this._conversationChangeDone[topicGuid];
            }
            else {
                this._conversationChangeDone[topicGuid].openedEditorsCount--;
            }
        }
    }
    discussionChangeEnded(activityGuid) {
        if (this._discussionChangeDone[activityGuid]) {
            if (this._discussionChangeDone[activityGuid].openedEditorsCount === 1) {
                this._discussionChangeDone[activityGuid].subject.next(activityGuid);
                this._discussionChangeDone[activityGuid].subject.complete();
                delete this._discussionChangeDone[activityGuid];
            }
            else {
                this._discussionChangeDone[activityGuid].openedEditorsCount--;
            }
        }
    }
    get forumConversationChange() {
        return {
            subscribe: (topicGuid, callback) => {
                this.notifications.addUserToGroup(topicGuid);
                const subscription = this.notifications.forumConversationChanged.subscribe((topicGuidGroup) => {
                    this.notifications.forumConversationChanged.resetSubscriptions();
                    this.conversationListChanged.next(true);
                    if (this._conversationChangeDone[topicGuidGroup]) {
                        this._conversationChangeDone[topicGuidGroup].subject.subscribe((changedtopicGuid) => callback(changedtopicGuid));
                    }
                    else {
                        callback(topicGuid);
                    }
                });
                return () => __awaiter(this, void 0, void 0, function* () {
                    this.notifications.removeUserFromGroup(topicGuid);
                    delete this._conversationChangeDone[topicGuid];
                    subscription();
                });
            },
        };
    }
    get discussionChange() {
        return {
            subscribe: (activityGuid, callback) => {
                this.notifications.addUserToGroup(activityGuid, true);
                const subscription = this.notifications.discussionChanged.subscribe(() => {
                    this.notifications.discussionChanged.resetSubscriptions();
                    if (this._discussionChangeDone[activityGuid]) {
                        this._discussionChangeDone[activityGuid].subject.subscribe((changedActivityGuid) => callback(changedActivityGuid));
                    }
                    else {
                        callback(activityGuid);
                    }
                });
                return () => {
                    this.notifications.removeUserFromGroup(activityGuid, true);
                    delete this._discussionChangeDone[activityGuid];
                    subscription();
                };
            },
        };
    }
}
ConversationEventsService.$inject = ['notifications'];
