import { CaptionEditorController } from './caption-editor.controller';
var captionEditorTemplate = "\n<div layout=\"column\" flex ng-form=\"caption\" ng-submit=\"vm.validCaption()\" ng-if=\"!vm.isDisabled\">\n  <isc-rich-input\n    model=\"vm.message\"\n    isc-mention-search=\"vm.iscMentionSearchFn(term)\"\n    isc-mention-select=\"vm.iscMentionSelectFn(item)\"\n    isc-hashtag-search=\"vm.iscHashtagSearchFn(term)\"\n    isc-hashtag-select=\"vm.iscHashtagSelectFn(item)\"\n    maxlength=\"::vm.messageMaxLength\"\n    buttons=\"{{vm.textEditorButtons}}\"\n    placeholder=\"{{vm.placeholder}}\"\n    remove-formatting=\"vm.removeFormatting\"\n    on-blur=\"vm.onBlur()\"\n    autosave=\"vm.autosave()\"\n    character-count-enabled=\"false\">\n    <isc-rich-input-errors>\n      <div ng-message=\"maxlength\" isc-translate=\"(LabelForumConversationEditorMessageMaxLengthError)\"\n        isc-translate-count=\"{{vm.messageMaxLength}}\" class=\"message-error\"></div>\n    </isc-rich-input-errors>\n  </isc-rich-input>\n</div>\n\n<div ng-if=\"vm.isDisabled && vm.message !== ''\"\nclass=\"caption-disabled\"\nng-bind-html=\"vm.message | highlightText: vm.keyword:vm.highlight:vm.isFiltered\">\n</div>";
export var CaptionEditorComponent = {
    controller: CaptionEditorController,
    controllerAs: 'vm',
    template: captionEditorTemplate,
    bindings: {
        message: '=messageModel',
        messageMaxLength: '<',
        isDisabled: '<',
        removeFormatting: '<',
        iscMentionSearch: '&?',
        iscMentionSelect: '&?',
        iscHashtagSearch: '&',
        iscHashtagSelect: '&',
        textEditorButtons: '@',
        onBlur: '&',
        autosave: '&',
    },
};
