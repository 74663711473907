import { __awaiter } from "tslib";
export class SsoService {
    constructor($state, httpService, $window, $q, _, ssocheckService, authenticationUrl, squareFactory, $mdDialog) {
        this.$state = $state;
        this.httpService = httpService;
        this.$window = $window;
        this.$q = $q;
        this._ = _;
        this.ssocheckService = ssocheckService;
        this.authenticationUrl = authenticationUrl;
        this.squareFactory = squareFactory;
        this.$mdDialog = $mdDialog;
        this.ssoLogin = this._.memoize(() => this.httpService.post({
            url: '/AuthorizationService/SquareParticipantSso',
            params: {
                token: this.ssocheckService.getSsoToken(),
            },
        }).then((response) => {
            if (!response.data.RegisteredUser) {
                return this.httpService.post({
                    url: '/ActivityService/GetScreenerSurveyLink',
                    data: {
                        ActivityGuid: response.data.ScreenerGuid,
                        PartnerId: response.data.PartnerId,
                        BId: response.data.Bid,
                        FirstName: response.data.FirstName,
                        LastName: response.data.LastName,
                        Email: response.data.Email,
                        Culture: response.data.Culture,
                        BackgroundVariables: [],
                    },
                }).then((screenerResponse) => {
                    this.$window.open(screenerResponse.data.Link, '_self');
                    return this.$q.defer().promise;
                });
            }
            return response.data.Token;
        }).catch(() => this.$q.reject(this.$state.target('container.landingpage', {
            landingTitle: '(SsoLoginFailedTitle)',
            landingMessage: '(SsoLoginFailedMessage)',
            timeout: -1,
        }))));
    }
    startAdminSsoLogin() {
        window.location.href = `${this.authenticationUrl}${this.$state.href('container.sso', {}, { absolute: true })}&squareguid=${this.squareFactory.Guid}`;
    }
    saveRedirectUrl(redirectUrl) {
        try {
            this.$window.sessionStorage.setItem(SsoService.ssoRedirectUrlStorageKey, redirectUrl);
        }
        catch (e) {
        }
    }
    getRedirectToSavedUrl() {
        let savedUrl = this.$window.sessionStorage.getItem(SsoService.ssoRedirectUrlStorageKey);
        if (!savedUrl) {
            savedUrl = '/';
        }
        this.saveRedirectUrl('');
        return savedUrl;
    }
    isSsoDomain(mail) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!mail) {
                return false;
            }
            const result = yield this.httpService.post({
                url: '/AuthorizationService/IsSsoDomain',
                params: {
                    email: mail,
                },
            });
            return result && result.data && result.data === true;
        });
    }
    showLimitedAccessDialog(isSquare = true) {
        let firstText = 'Client and its Square(s)';
        let secondText = 'Client';
        if (isSquare) {
            firstText = 'Square';
            secondText = 'Square';
        }
        const dialog = this.$mdDialog.iscConfirm()
            .text(`The access to this ${firstText} is limited.\n\nPlease contact the project owner - this can be either ` +
            `the CST lead or the PST lead - giving a clear description WHY you need access to WHICH Square(s) and WHO referred you to this ${secondText}.\n\n` +
            'This will make it easier for the project owner to assess your request.\n\nAlternatively, a ticket can be added through the Service Desk.')
            .cancel('Ok');
        return this.$mdDialog.show(dialog);
    }
}
SsoService.$inject = ['$state', 'httpService', '$window', '$q', '_', 'ssocheckService', 'authenticationUrl', 'squareFactory', '$mdDialog'];
SsoService.ssoRedirectUrlStorageKey = 'ssoRedirectUrl';
