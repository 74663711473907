export class RedirectService {
    constructor($window, $location) {
        this.$window = $window;
        this.$location = $location;
    }
    redirectToLink(link) {
        if (link.indexOf('http') === 0) {
            this.$window.location.replace(link);
        }
        else {
            this.$location.url(link).replace();
        }
    }
}
RedirectService.$inject = ['$window', '$location'];
