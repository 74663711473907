import {
  StoreLinksResponse,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const verifyAppVersion = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/AppService/VerifyAppVersion', undefined, config);

export const getStoreLinksForApp = (squareId: number, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<StoreLinksResponse>('/AppService/GetStoreLinksForApp', { squareId }, config);
