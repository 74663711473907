import { Dialog } from 'quasar';
import SequencedActivityDialog from '@ui/components/sequenced-activity-dialog/sequenced-activity-dialog.vue';
import { NextSequencedActivityRedirectInfoResponse } from '@api/models/query';
import { ChannelType, PageType } from '@/common/constants/enums';
import { RouteNames } from '@/router/routes/route-names.enum';
import router from '@/router';
import { usePagesStore } from '@/store/pages-store';
import { useSquareStore } from '@/store/square-store';

export const showSequencedActivityModal = async (info: NextSequencedActivityRedirectInfoResponse, dismissToDoActivities = false) => {
  const homeLabel = usePagesStore().pageByType(PageType.Home)[0]?.name ?? 'Home';
  const color = router.currentRoute.value.params.pageGuid ?
    useSquareStore().design.pageDesign?.find((design) => design.pageGuid === router.currentRoute.value.params.pageGuid)?.color :
    undefined;

  Dialog.create({
    component: SequencedActivityDialog,
    componentProps: {
      info,
      homeLabel,
      color,
    },
  })
    .onOk(() => {
      if (info.nextActivityGuid) {
        router.push({ name: RouteNames.ActivityResearch, params: { activityGuid: info.nextActivityGuid, channel: ChannelType.ResearchActivityChallenge } });
      } else {
        router.push({ name: RouteNames.Home });
      }
    })
    .onCancel(() => {
      // When coming from a survey, dismissing the modal goes to the ToDo tab instead
      if (dismissToDoActivities) {
        const activityPageGuid = usePagesStore().pageByType(PageType.Activities)[0].guid;
        router.push({ name: RouteNames.ChallengesTodo, params: { pageGuid: activityPageGuid } });
      }
    })
  ;
};
