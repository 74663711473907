import * as angular from 'angular';
var TimelineService = (function () {
    function TimelineService() {
    }
    TimelineService.prototype.calculateCard = function ($element, isNewCardOnPageAdded) {
        var timeline = document.getElementsByClassName('timeline')[0];
        if (isNewCardOnPageAdded) {
            Array.from(timeline.querySelectorAll('.timeline-left')).map(function (x) { return x.classList.remove('timeline-left'); });
            Array.from(timeline.querySelectorAll('.timeline-inverted .first-inverted')).map(function (x) { return x.classList.remove('timeline-inverted first-inverted'); });
            Array.from(timeline.querySelectorAll('.timeline-inverted')).map(function (x) { return x.classList.remove('timeline-inverted'); });
        }
        var leftItems = timeline.getElementsByClassName('timeline-left');
        var rightItems = timeline.getElementsByClassName('timeline-inverted');
        var currentElement = $element[0].getElementsByClassName('listItem')[0];
        if (leftItems.length === 0) {
            angular.element(currentElement).addClass('timeline-left');
            $element.removeClass('invisible');
        }
        else {
            var sumLeft_1 = 0;
            var sumRight_1 = 0;
            angular.forEach(leftItems, function (card) {
                sumLeft_1 += card.clientHeight;
            });
            angular.forEach(rightItems, function (card) {
                sumRight_1 += card.clientHeight;
            });
            if (sumLeft_1 > sumRight_1) {
                if (rightItems.length === 0) {
                    angular.element(currentElement).addClass('timeline-inverted first-inverted');
                }
                angular.element(currentElement).addClass('timeline-inverted');
                this.calculateBadges(leftItems, currentElement);
                $element.removeClass('invisible');
            }
            else {
                angular.element(currentElement).addClass('timeline-left');
                this.calculateBadges(rightItems, currentElement);
                $element.removeClass('invisible');
            }
        }
    };
    TimelineService.prototype.calculateBadges = function (cards, currentElement) {
        var previousCard;
        var badgePreviousCard;
        var badgePreviousCardTop;
        var currentCardBefore;
        var currentCardAfter;
        var badgeCurrentCard;
        var badgeCurrentCardTop;
        var differenceCards;
        var offsetBetweenBadges = 30;
        previousCard = cards[cards.length - 1];
        badgePreviousCard = previousCard.getElementsByClassName('timeline-badge')[0];
        badgePreviousCardTop = badgePreviousCard.getBoundingClientRect().top + badgePreviousCard.clientHeight;
        badgeCurrentCard = currentElement.getElementsByClassName('timeline-badge')[0];
        badgeCurrentCardTop = badgeCurrentCard.getBoundingClientRect().top;
        differenceCards = badgeCurrentCardTop - badgePreviousCardTop;
        if (differenceCards < offsetBetweenBadges) {
            angular.element(badgeCurrentCard).css('top', Math.abs(differenceCards) + offsetBetweenBadges + badgeCurrentCard.offsetTop + 'px');
            currentCardBefore = currentElement.getElementsByClassName('timeline-panel')[0].getElementsByClassName('before')[0];
            currentCardAfter = currentElement.getElementsByClassName('timeline-panel')[0].getElementsByClassName('after')[0];
            angular.element(currentCardBefore).css('top', Math.abs(differenceCards) + offsetBetweenBadges + currentCardBefore.offsetTop + 'px');
            angular.element(currentCardAfter).css('top', Math.abs(differenceCards) + offsetBetweenBadges + currentCardAfter.offsetTop + 'px');
        }
    };
    return TimelineService;
}());
export { TimelineService };
