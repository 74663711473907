import { UiModule } from './../../index';
import { IscHashtagDirective } from './hashtag.directive';
import { IscHashtagMenuComponent } from './hashtag-menu.component';
import { IscHashtagHighlightFilter } from './hashtag-highlight.filter';
import { IscHashtagMenuItemComponent } from './hashtag-menu-item.component';
import { IscHashtagUtil } from './hashtag.service';
UiModule
    .directive('iscHashtag', IscHashtagDirective.factory())
    .component('iscHashtagMenu', IscHashtagMenuComponent)
    .component('iscHashtagMenuItem', IscHashtagMenuItemComponent)
    .service('iscHashtagUtil', IscHashtagUtil)
    .filter('iscHashtagHighlight', IscHashtagHighlightFilter);
