import * as angular from 'angular';
export class NavigationMenuService {
    constructor($state, mediaservice, _) {
        this.$state = $state;
        this.mediaservice = mediaservice;
        this._ = _;
        this.lastScrollTop = 0;
        this.delta = 1;
        this.scroll = () => {
            if (this._.every(document.getElementsByTagName('md-menu-content'), (item) => item.offsetParent == null)) {
                if (!this.mediaservice.isDesktop()) {
                    this.toggleFooter();
                    this.toggleMobileHeader();
                    this.setLastScrollTop();
                }
            }
        };
    }
    closeBottomSheet() {
        const sheetBackdrop = document.getElementsByClassName('md-bottom-sheet-backdrop');
        if (sheetBackdrop && sheetBackdrop.length > 0) {
            angular.element(sheetBackdrop[0]).triggerHandler('click');
            return true;
        }
        return false;
    }
    gotoPrev(shouldGoToMorePage = false) {
        if (shouldGoToMorePage) {
            this.$state.go('container.main.more');
            return;
        }
        const prevState = this.$state.current.prevState;
        if (typeof prevState === 'string') {
            this.$state.go(prevState);
        }
        else if (typeof prevState === 'object') {
            const mappedStates = this._.mapValues(prevState.params, (param) => this.$state.params[param]);
            this.$state.go(prevState.state, mappedStates);
        }
    }
    toggleFooter() {
        const scrollTop = document.scrollingElement.scrollTop;
        const scrollHeight = document.scrollingElement.scrollHeight;
        const scrollBottom = scrollHeight - scrollTop - document.scrollingElement.clientHeight;
        const scrolledDistance = Math.abs(this.lastScrollTop - scrollTop);
        const isScrolledOutOfView = scrollTop < 0 || scrollBottom < 0;
        if (!isScrolledOutOfView && scrolledDistance > this.delta) {
            const navMenu = document.getElementsByTagName('isc-navigation-menu')[0].getElementsByClassName('menu')[0];
            if (navMenu) {
                if (scrollTop > this.lastScrollTop) {
                    navMenu.classList.remove('nav-show');
                    navMenu.classList.add('nav-hide');
                }
                else {
                    navMenu.classList.add('nav-show');
                    navMenu.classList.remove('nav-hide');
                }
            }
        }
    }
    toggleMobileHeader() {
        const scrollTop = document.scrollingElement.scrollTop;
        if (!(Math.abs(this.lastScrollTop - scrollTop) <= this.delta)) {
            const mobileHeader = document.getElementsByTagName('isc-navigation-menu')[0].getElementsByClassName('mobile-header')[0];
            const headerBackground = document.getElementsByTagName('isc-header-images')[0].getElementsByClassName('header-background')[0];
            if (mobileHeader) {
                if (!mobileHeader.classList.contains('home') || !headerBackground) {
                    mobileHeader.classList.add('show');
                    mobileHeader.classList.remove('hide');
                    return;
                }
                if (headerBackground && headerBackground.offsetHeight - 10 < scrollTop) {
                    mobileHeader.classList.add('show');
                    mobileHeader.classList.remove('hide');
                }
                else {
                    mobileHeader.classList.add('hide');
                    mobileHeader.classList.remove('show');
                }
            }
        }
    }
    setLastScrollTop() {
        const scrollTop = document.scrollingElement.scrollTop;
        this.lastScrollTop = scrollTop;
    }
}
NavigationMenuService.$inject = ['$state', 'mediaservice', '_'];
