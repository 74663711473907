import { __extends } from "tslib";
import { IscTaggingMenuItemController } from '../common-tagging/tagging-menu-item-base.controller';
var IscMentionMenuItemController = (function (_super) {
    __extends(IscMentionMenuItemController, _super);
    function IscMentionMenuItemController() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return IscMentionMenuItemController;
}(IscTaggingMenuItemController));
export { IscMentionMenuItemController };
