var IscTaggingMenuItemController = (function () {
    function IscTaggingMenuItemController() {
    }
    IscTaggingMenuItemController.prototype.mouseEnter = function () {
        this.activate();
    };
    IscTaggingMenuItemController.prototype.click = function () {
        this.selectItem();
        return false;
    };
    IscTaggingMenuItemController.prototype.active = function () {
        return this.isActive();
    };
    return IscTaggingMenuItemController;
}());
export { IscTaggingMenuItemController };
