import { UiModule } from './../../index';
var ClipboardService = (function () {
    function ClipboardService() {
    }
    ClipboardService.prototype.copyToClipboard = function (text) {
        var anyWindow = window;
        if (anyWindow.clipboardData && anyWindow.clipboardData.setData) {
            return anyWindow.clipboardData.setData('Text', text);
        }
        else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            var textarea = document.createElement('textarea');
            textarea.textContent = text;
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand('copy');
            }
            catch (ex) {
                return false;
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    };
    return ClipboardService;
}());
export { ClipboardService };
UiModule.service('iscClipboardService', ClipboardService);
