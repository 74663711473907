import * as angular from 'angular';
var ShowMoreDirective = (function () {
    function ShowMoreDirective() {
        this.template = '<div class="show-more-container"><ng-transclude></ng-transclude></div>' +
            '<a href="#" class="show-more-expand"></a>' +
            '<a href="#" class="show-more-collapse"></a>';
        this.transclude = true;
    }
    ShowMoreDirective.prototype.link = function (scope, element, attrs, ngModel) {
        var maxHeight = 22;
        var initialized = null;
        var containerDom = element.children()[0];
        var $showMore = angular.element(element.children()[1]);
        var $showLess = angular.element(element.children()[2]);
        scope.$watch(function () {
            return containerDom.innerHTML;
        }, function () {
            if (null !== initialized) {
                return;
            }
            if (containerDom.clientHeight <= maxHeight) {
                return;
            }
            if (!attrs.showMoreText || !attrs.showLessText) {
                return;
            }
            $showMore.on('click', function () {
                element.removeClass('show-more-collapsed');
                element.addClass('show-more-expanded');
                containerDom.style.height = null;
            });
            $showLess.on('click', function () {
                element.removeClass('show-more-expanded');
                element.addClass('show-more-collapsed');
                containerDom.style.height = maxHeight + 'px';
            });
            $showMore.text(attrs.showMoreText);
            $showLess.text(attrs.showLessText);
            initialized = true;
            $showLess.triggerHandler('click');
        });
    };
    return ShowMoreDirective;
}());
export { ShowMoreDirective };
