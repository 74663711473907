import { UiModule } from './../../index';
var SpinnerService = (function () {
    function SpinnerService() {
    }
    SpinnerService.prototype.show = function (guid) {
        throw new Error('Please override this function with a function that gets the stimuli streaming URL from the backend');
    };
    SpinnerService.prototype.hide = function (guid) {
        throw new Error('Please override this function with a function that gets the stimuli streaming URL from the backend');
    };
    return SpinnerService;
}());
export { SpinnerService };
UiModule.service('iscSpinnerService', SpinnerService);
