import { Subject } from 'rxjs';
import { Utils } from '../../core/utils';
import { CacheService } from '../services/cache.service';
export class UserService {
    constructor(_, serverConstants) {
        this._ = _;
        this.serverConstants = serverConstants;
        this.JWT_KEY = 'SW5TaXRlc1NxdWFyZV9KV1Q=';
        this.uniqueNameAttrName = 'unique_name';
        this.squareParticipantGuidChange = new Subject();
    }
    getToken() {
        return CacheService.getCacheValue(this.JWT_KEY);
    }
    getClaims() {
        if (this.token) {
            return {
                role: this.token.Role,
                id: this.token.SquareParticipantId,
                guid: this.token.SquareParticipantGuid,
                mobileAppVersion: this.token.MobileAppVersion,
                isMobileAppUser: this.token.IsMobileAppUser,
                mobileDeviceType: this.token.MobileDeviceType,
                squareGuid: this.token.SquareGuid,
                uniqueName: this.token.UniqueName,
            };
        }
        return {};
    }
    userRole() {
        return this.getClaims().role;
    }
    userRoleLabel() {
        return Utils.getEnumValueName(this.serverConstants.roleConstants, this.getClaims().role);
    }
    userGuid() {
        return this.getClaims().guid;
    }
    userId() {
        return this.getClaims().id;
    }
    email() {
        return this.getClaims().uniqueName;
    }
    squareGuid() {
        return this.getClaims().squareGuid;
    }
    hasInsitesRole() {
        return this.userRole() === this.serverConstants.roleConstants.human8;
    }
    hasObserverRole() {
        return this.userRole() === this.serverConstants.roleConstants.observer;
    }
    hasParticipantRole() {
        return this.userRole() === this.serverConstants.roleConstants.participant;
    }
    hasModeratorRole() {
        return this.userRole() === this.serverConstants.roleConstants.professionalAdmin || this.userRole() === this.serverConstants.roleConstants.clientAdmin;
    }
    mobileAppVersion() {
        return this.getClaims().mobileAppVersion;
    }
    isMobileAppUser() {
        return this.getClaims().isMobileAppUser === true;
    }
    mobileDeviceType() {
        return this.getClaims().mobileDeviceType;
    }
    setTokenInfo(token) {
        this.token = token;
        this.squareParticipantGuidChange.next(token.SquareParticipantGuid);
    }
}
UserService.$inject = ['_', 'serverConstants'];
