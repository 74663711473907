export class SpinnerService {
    constructor(_, $q) {
        this._ = _;
        this.$q = $q;
        this.spinners = {};
    }
    _register(data) {
        if (!Object.prototype.hasOwnProperty.call(data, 'name')) {
            throw new Error('Spinner must specify a name when registering with the spinner service.');
        }
        data.countShown = 0;
        this.spinners[data.name] = data;
    }
    _unregister(name) {
        if (Object.prototype.hasOwnProperty.call(this.spinners, name)) {
            delete this.spinners[name];
        }
    }
    _unregisterGroup(group) {
        this._.each(this.spinners, (name) => {
            if (this.spinners[name].group === group) {
                delete this.spinners[name];
            }
        });
    }
    _unregisterAll() {
        this._.each(this.spinners, (name) => {
            delete this.spinners[name];
        });
    }
    show(name) {
        const spinner = this.spinners[name];
        if (!spinner) {
            throw new Error(`No spinner named ${name} is registered.`);
        }
        ++spinner.countShown;
        spinner.show();
    }
    showFor(name, promises) {
        if (!Array.isArray(promises)) {
            promises = [promises];
        }
        this.show(name);
        this.$q.all(promises).finally(() => {
            this.hide(name);
        });
    }
    hide(name) {
        const spinner = this.spinners[name];
        if (!spinner) {
            throw new Error(`No spinner named ${name} is registered.`);
        }
        if (--spinner.countShown === 0) {
            spinner.hide();
        }
    }
    showGroup(group) {
        let groupExists = false;
        this._.each(this.spinners, (name) => {
            const spinner = this.spinners[name];
            if (spinner.group === group) {
                spinner.show();
                groupExists = true;
            }
        });
        if (!groupExists) {
            throw new Error(`No spinners found with group ${group}.`);
        }
    }
    hideGroup(group) {
        let groupExists = false;
        this._.each(this.spinners, (name) => {
            const spinner = this.spinners[name];
            if (spinner.group === group) {
                spinner.hide();
                groupExists = true;
            }
        });
        if (!groupExists) {
            throw new Error(`No spinners found with group ${group}.`);
        }
    }
    showAll() {
        this._.each(this.spinners, (name) => {
            this.spinners[name].show();
        });
    }
    hideAll() {
        this._.each(this.spinners, (spinner) => {
            spinner.hide();
        });
    }
}
SpinnerService.$inject = ['_', '$q'];
