export class NgPasswordMeter {
    constructor() {
        this.restrict = 'AE';
        this.template = `<div class="pass-meter {{masterClass}}">
                 <div class="{{colClass}} pass-meter-col {{first}}"><div class="indicator"></div></div>
                 <div class="{{colClass}} pass-meter-col {{second}}"><div class="indicator"></div></div>
                 <div class="{{colClass}} pass-meter-col {{third}}"><div class="indicator"></div></div>
                 <div class="{{colClass}} pass-meter-col {{fourth}}"><div class="indicator"></div></div>
               </div>`;
        this.scope = {
            password: '<',
            strength: '<?',
        };
    }
    link($scope) {
        const measureStrength = (p) => {
            const matches = {
                pos: {
                    lower: '',
                    upper: '',
                    numbers: '',
                    symbols: '',
                    middleNumber: '',
                    middleSymbol: '',
                },
                neg: {
                    consecLower: '',
                    consecUpper: '',
                    consecNumbers: '',
                    onlyNumbers: '',
                    onlyLetters: '',
                },
            };
            const counts = {
                pos: {
                    lower: 0,
                    upper: 0,
                    numbers: 0,
                    symbols: 0,
                    numChars: 0,
                    requirements: 0,
                    middleNumber: 0,
                    middleSymbol: 0,
                },
                neg: {
                    seqLetter: 0,
                    seqNumber: 0,
                    seqSymbol: 0,
                    consecLower: 0,
                    consecUpper: 0,
                    consecNumbers: 0,
                    repeated: 0,
                },
            };
            let tmp;
            let strength = 0;
            const letters = 'abcdefghijklmnopqrstuvwxyz';
            const numbers = '01234567890';
            const symbols = '\\!@#$%&/()=?¿';
            let back;
            let forth;
            let i;
            if (p) {
                let sameCharacter = true;
                for (i = 1; i < p.length; i++) {
                    if (p[0] !== p[i]) {
                        sameCharacter = false;
                    }
                }
                if (sameCharacter) {
                    p = p.substring(0, 1);
                }
                matches.pos.lower = p.match(/[a-z]/g);
                matches.pos.upper = p.match(/[A-Z]/g);
                matches.pos.numbers = p.match(/\d/g);
                matches.pos.symbols = p.match(/[$-/:-?{-~!^_`[\]]/g);
                matches.pos.middleNumber = p.slice(1, -1).match(/\d/g);
                matches.pos.middleSymbol = p.slice(1, -1).match(/[$-/:-?{-~!^_`[\]]/g);
                counts.pos.lower = matches.pos.lower ? matches.pos.lower.length : 0;
                counts.pos.upper = matches.pos.upper ? matches.pos.upper.length : 0;
                counts.pos.numbers = matches.pos.numbers ? matches.pos.numbers.length : 0;
                counts.pos.symbols = matches.pos.symbols ? matches.pos.symbols.length : 0;
                tmp = Object.keys(counts.pos).reduce((previous, key) => previous + Math.min(1, counts.pos[key]), 0);
                counts.pos.numChars = p.length;
                tmp += (counts.pos.numChars >= 8) ? 1 : 0;
                counts.pos.requirements = (tmp >= 3) ? tmp : 0;
                counts.pos.middleNumber = matches.pos.middleNumber ? matches.pos.middleNumber.length : 0;
                counts.pos.middleSymbol = matches.pos.middleSymbol ? matches.pos.middleSymbol.length : 0;
                matches.neg.consecLower = p.match(/(?=([a-z]{2}))/g);
                matches.neg.consecUpper = p.match(/(?=([A-Z]{2}))/g);
                matches.neg.consecNumbers = p.match(/(?=(\d{2}))/g);
                matches.neg.onlyNumbers = p.match(/^[0-9]*$/g);
                matches.neg.onlyLetters = p.match(/^([a-z]|[A-Z])*$/g);
                counts.neg.consecLower = matches.neg.consecLower ? matches.neg.consecLower.length : 0;
                counts.neg.consecUpper = matches.neg.consecUpper ? matches.neg.consecUpper.length : 0;
                counts.neg.consecNumbers = matches.neg.consecNumbers ? matches.neg.consecNumbers.length : 0;
                for (i = 0; i < letters.length - 2; i++) {
                    const p2 = p.toLowerCase();
                    forth = letters.substring(i, parseInt(i + 3, 10));
                    back = this.stringReverse(forth);
                    if (p2.indexOf(forth) !== -1 || p2.indexOf(back) !== -1) {
                        counts.neg.seqLetter++;
                    }
                }
                for (i = 0; i < numbers.length - 2; i++) {
                    forth = numbers.substring(i, parseInt(i + 3, 10));
                    back = this.stringReverse(forth);
                    if (p.indexOf(forth) !== -1 || p.toLowerCase().indexOf(back) !== -1) {
                        counts.neg.seqNumber++;
                    }
                }
                for (i = 0; i < symbols.length - 2; i++) {
                    forth = symbols.substring(i, parseInt(i + 3, 10));
                    back = this.stringReverse(forth);
                    if (p.indexOf(forth) !== -1 || p.toLowerCase().indexOf(back) !== -1) {
                        counts.neg.seqSymbol++;
                    }
                }
                const repeats = {};
                const plower = p.toLowerCase();
                const arr = plower.split('');
                counts.neg.repeated = 0;
                for (i = 0; i < arr.length; i++) {
                    let cnt = 0;
                    let idx = 0;
                    while (idx !== -1) {
                        idx = plower.indexOf(arr[i], idx);
                        if (idx === -1) {
                            break;
                        }
                        idx++;
                        cnt++;
                    }
                    if (cnt > 1 && !repeats[plower[i]]) {
                        repeats[plower[i]] = cnt;
                        counts.neg.repeated += cnt;
                    }
                }
                strength += counts.pos.numChars * 4;
                if (counts.pos.upper) {
                    strength += (counts.pos.numChars - counts.pos.upper) * 2;
                }
                if (counts.pos.lower) {
                    strength += (counts.pos.numChars - counts.pos.lower) * 2;
                }
                if (counts.pos.upper || counts.pos.lower) {
                    strength += counts.pos.numbers * 4;
                }
                strength += counts.pos.symbols * 6;
                strength += (counts.pos.middleSymbol + counts.pos.middleNumber) * 2;
                strength += counts.pos.requirements * 2;
                strength -= counts.neg.consecLower * 2;
                strength -= counts.neg.consecUpper * 2;
                strength -= counts.neg.consecNumbers * 2;
                strength -= counts.neg.seqNumber * 3;
                strength -= counts.neg.seqLetter * 3;
                strength -= counts.neg.seqSymbol * 3;
                if (matches.neg.onlyNumbers) {
                    strength -= counts.pos.numChars;
                }
                if (matches.neg.onlyLetters) {
                    strength -= counts.pos.numChars;
                }
                if (counts.neg.repeated) {
                    strength -= (counts.neg.repeated / counts.pos.numChars) * 10;
                }
            }
            return Math.max(0, Math.min(100, Math.round(strength)));
        };
        $scope.colClass = '';
        $scope.masterClass = '';
        $scope.$watch('password', () => {
            $scope.first = '';
            $scope.second = '';
            $scope.third = '';
            $scope.fourth = '';
            $scope.message = '';
            if (!$scope.password) {
                $scope.masterClass = 'hidden';
                return;
            }
            $scope.strength = measureStrength($scope.password);
            $scope.masterClass = '';
            if ($scope.strength < 25) {
                $scope.first = 'poor';
                $scope.message = 'Poor';
            }
            else if ($scope.strength < 50) {
                $scope.first = 'weak';
                $scope.second = 'weak';
                $scope.message = 'weak';
            }
            else if ($scope.strength < 75) {
                $scope.first = 'good';
                $scope.second = 'good';
                $scope.third = 'good';
                $scope.message = 'good';
            }
            else if ($scope.strength <= 100) {
                $scope.first = 'strong';
                $scope.second = 'strong';
                $scope.third = 'strong';
                $scope.fourth = 'strong';
                $scope.message = 'strong';
            }
        });
    }
    stringReverse(str) {
        let out = '';
        for (let i = str.length - 1; i >= 0;) {
            out += str[i--];
        }
        return out;
    }
}
