var RemoveIgnoredMsoListItemElement = (function () {
    function RemoveIgnoredMsoListItemElement() {
    }
    RemoveIgnoredMsoListItemElement.prototype.processElement = function (element) {
        var styleAttribute = element.attributes.getNamedItem('style');
        var styleValue = (styleAttribute && styleAttribute.value) || '';
        var isIgnoredListItem = /mso-list:\s*Ignore/i.test(styleValue)
            || styleValue.indexOf('mso-special-format') !== -1;
        return isIgnoredListItem ? null : element;
    };
    return RemoveIgnoredMsoListItemElement;
}());
export default RemoveIgnoredMsoListItemElement;
