import { IncentiveController } from './isc-incentive.controller';
import { UiModule } from '../../index';
var iscIncentiveTemplate = "<div class=\"incentives-summary\" ng-class=\"{'has-rewards': vm.numberOfRewards > 1}\" layout=\"row\">\n  <div class=\"header-spacer\"></div>\n  <div class=\"summary-item\">\n    <md-icon class=\"fa fa-database\"></md-icon>\n    <div class=\"item-details\">\n      <div layout=\"column\">\n        <span class=\"number-of-credits\"> {{vm.totalCredits}} </span>\n        <span ng-if=\"vm.totalCredits == 1\" isc-translate=\"(LabelIncentiveCredit)\"></span>\n        <span ng-if=\"vm.totalCredits != 1\" isc-translate=\"(LabelIncentiveCredits)\"></span>\n      </div>\n    </div>\n  </div>\n  <div class=\"summary-item\" ng-if=\"vm.currentRaffleEndDate\">\n    <md-icon class=\"fa fa-calendar\"></md-icon>\n    <div class=\"item-details\">\n      <div layout=\"column\">\n        <span> {{vm.currentRaffleEndDate | iscDateFormat:'MMM d'}} </span>\n        <span isc-translate=\"(LabelIncentiveEndDate)\"></span>\n      </div>\n    </div>\n  </div>\n  <div class=\"summary-item\" ng-if=\"vm.numberOfRewards > 0\">\n    <md-icon class=\"fa fa-gift\"></md-icon>\n    <div class=\"item-details\">\n      <div layout=\"column\">\n        <span> {{vm.numberOfRewards}} </span>\n        <span ng-if=\"vm.numberOfRewards == 1\" isc-translate=\"(LabelIncentiveReward)\"></span>\n        <span ng-if=\"vm.numberOfRewards != 1\" isc-translate=\"(LabelIncentiveRewards)\"></span>\n      </div>\n    </div>\n  </div>\n</div>\n";
UiModule
    .component('iscIncentive', {
    controller: IncentiveController,
    template: iscIncentiveTemplate,
    controllerAs: 'vm',
    bindings: {
        numberOfRewards: '<',
        currentRaffleEndDate: '<',
        totalCredits: '<',
    },
});
