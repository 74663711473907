import { __extends } from "tslib";
import { IscTaggingBaseController } from '../common-tagging/tagging-base.controller';
var IscMentionController = (function (_super) {
    __extends(IscMentionController, _super);
    function IscMentionController($scope, $timeout, $attrs, $document, iscMentionUtil, $element, $parse) {
        return _super.call(this, $scope, $timeout, $attrs, $document, iscMentionUtil, $element, $parse) || this;
    }
    IscMentionController.prototype.init = function () {
        this.$scope.mention = this;
        this.search = this.$parse(this.$attrs.iscMentionSearch);
        this.select = this.$parse(this.$attrs.iscMentionSelect);
    };
    IscMentionController.$inject = ['$scope', '$timeout', '$attrs', '$document', 'iscMentionUtil', '$element', '$parse'];
    return IscMentionController;
}(IscTaggingBaseController));
export { IscMentionController };
