export class ReactivateController {
    constructor($stateParams, participantService, $state, imagesFactory, featureToggleService) {
        this.$stateParams = $stateParams;
        this.participantService = participantService;
        this.$state = $state;
        this.imagesFactory = imagesFactory;
        this.featureToggleService = featureToggleService;
        this.shouldShowFooter = this.featureToggleService.shouldShowFooter;
        this.images = this.imagesFactory.images;
        this.loading = true;
        this.isUrlValid = true;
    }
    $onInit() {
        this.imagesFactory.applyImages();
        this.participantService.checkSubscribeUrlValidity(this.$stateParams.subscribeGuid)
            .then((isValid) => this.isUrlValid = isValid)
            .finally(() => this.loading = false);
    }
    reactivate() {
        this.loading = true;
        this.participantService.reactivateParticipant(this.$stateParams.subscribeGuid)
            .then(() => this.$state.go('container.login'))
            .finally(() => this.loading = false);
    }
}
ReactivateController.$inject = ['$stateParams', 'participantService', '$state', 'imagesFactory', 'featureToggleService'];
