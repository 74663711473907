import { __awaiter } from "tslib";
import { MobileTourController } from './mobile-tour.controller';
const MobileTourTemplate = "<isc-modal-dialog class=\"mobile-tour\">\n  <isc-modal-content class=\"tooltiptext\">\n    <div class=\"title\" isc-translate=\"(LabelMobileTourTitle)\"></div>\n    <span isc-translate=\"(LabelMobileTourBody)\"></span>\n  </isc-modal-content>\n  <isc-modal-actions>\n    <isc-button class=\"primary\" ng-click=\"vm.goToProfile()\">\n        <isc-button-label isc-translate=\"(LabelMobileTourButton)\"></isc-button-label>\n    </isc-button>\n  </isc-modal-actions>\n</isc-modal-dialog>";
export class MobileTourService {
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }
    showMobileTourDialog() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.$mdDialog.show({
                controller: MobileTourController,
                controllerAs: 'vm',
                template: MobileTourTemplate,
                parent: angular.element(document.body),
                clickOutsideToClose: false,
            });
        });
    }
}
MobileTourService.$inject = ['$mdDialog'];
