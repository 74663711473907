import { CustomController } from './reusablesCustom.controller';
const template = "<div md-theme=\"{{vm.page.Guid | getPageTheme}}\" ng-if=\"vm.page\">\n  <div ng-switch=\"vm.hasCustomUrl\">\n    <div ng-switch-when=\"false\">\n      <h3 ng-if=\"vm.page.Name\" md-colors=\"{color:'primary-500'}\" ng-bind-html=\"vm.page.Name\"></h3>\n      <p ng-bind-html=\"vm.page.Intro\"></p>\n    </div>\n    <div ng-switch-when=\"true\">\n      <h3 ng-if=\"vm.page.Name\" md-colors=\"{color: 'primary-500'}\" ng-bind-html=\"vm.page.Name\"></h3>\n      <p class=\"redirect-text\" md-colors=\"{color: 'primary-500'}\" ng-bind-html=\"vm.customPageRedirextText\">\n      </p>\n    </div>\n  </div>\n</div>";
export const ReusablesCustomComponent = {
    controller: CustomController,
    template,
    controllerAs: 'vm',
    bindings: {
        page: '<',
    },
};
