import { MediaService } from '../core/services/media.service';
export class SquareLandingPagesController {
    constructor($state, $timeout, imagesFactory, mediaservice, $window, $stateParams) {
        this.$state = $state;
        this.$timeout = $timeout;
        this.imagesFactory = imagesFactory;
        this.mediaservice = mediaservice;
        this.$window = $window;
        this.$stateParams = $stateParams;
    }
    $onInit() {
        this.landingTitle = this.landingTitle || this.$stateParams.landingTitle;
        this.landingMessage = this.landingMessage || this.$stateParams.landingMessage;
        this.redirectState = this.redirectState || this.$stateParams.redirectState;
        this.redirectToMobileAppHome = this.$stateParams.redirectToMobileAppHome;
        this.activityGuid = this.activityGuid || this.$stateParams.activityGuid;
        this.channelType = this.channelType || this.$stateParams.channelType;
        this.redirectParams = {
            activityGuid: this.activityGuid,
            channel: this.channelType,
        };
        this.timeout = this.timeout || this.$stateParams.timeout;
        this.isExternalLink = this.isExternalLink || this.$stateParams.isExternalLink;
        this.imagesFactory.applyImages();
        this.images = this.imagesFactory.images;
        if (this.redirectToMobileAppHome) {
            this.redirectState = 'container.main.home';
            this.redirectParams[MediaService.MobileAppReturnToNativeHomeParam] = true;
        }
        const timeout = parseInt(this.timeout, 10);
        if (timeout >= 0) {
            this.$timeout(() => {
                if (!this.isExternalLink) {
                    this.$state.go(this.redirectState, this.redirectParams);
                }
                else {
                    this.$window.location.href = this.redirectState;
                }
            }, timeout);
        }
    }
    get hidePageHeader() {
        return this.mediaservice.isMobileApp && this.mediaservice.hasAppVersion();
    }
}
SquareLandingPagesController.$inject = ['$state', '$timeout', 'imagesFactory', 'mediaservice', '$window', '$stateParams'];
