import { __awaiter } from "tslib";
export class CaptchaService {
    constructor(__env, initGeeTest, $rootScope, squareFactory, authService, participantService, $q, featureService, serverConstants) {
        this.__env = __env;
        this.initGeeTest = initGeeTest;
        this.$rootScope = $rootScope;
        this.squareFactory = squareFactory;
        this.authService = authService;
        this.participantService = participantService;
        this.$q = $q;
        this.featureService = featureService;
        this.serverConstants = serverConstants;
        this.captchaMethod = this.__env.CaptchaMethod;
        this.RECAPTCHA = 'reCaptcha';
        this.reCaptchaDomain = this.__env.ReCaptchaFallbackMode ? 'recaptcha.net' : 'google.com';
        this.RECAPTCHA_SITEID = this.__env.ReCaptchaSiteId;
        this.GEETESTCAPTCHA = 'gtCaptcha';
        this.showGtCaptcha = false;
        this.GEETESTCAPTCHA_SITEID = this.__env.GtCaptchaSiteId;
        this.gtCaptchaValid = false;
    }
    isCaptchaEnabled() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.featureService.isMasterFeatureEnabledForSquare(this.serverConstants.featureConstants.captcha);
        });
    }
    loadCaptchaIfNecessary(action) {
        return __awaiter(this, void 0, void 0, function* () {
            const isCaptchaEnabled = yield this.isCaptchaEnabled();
            if (!isCaptchaEnabled) {
                return '';
            }
            if (action === 'login' || action === 'changePassword') {
                return this.authService.shouldDisplayCaptcha().then((displayCaptcha) => {
                    if (displayCaptcha) {
                        this.loadCaptcha();
                    }
                });
            }
            else if (action === 'resetPassword') {
                return this.participantService.shouldDisplayCaptcha().then((displayCaptcha) => {
                    if (displayCaptcha) {
                        this.loadCaptcha();
                    }
                });
            }
            return this.loadCaptcha();
        });
    }
    loadCaptcha() {
        let lang = this.squareFactory.Language !== null ? this.squareFactory.Language : 'en';
        if (this.captchaMethod === this.RECAPTCHA) {
            if (lang.startsWith('zh')) {
                lang = 'zh-CN';
            }
            this.initReCaptcha(lang);
        }
        else if (this.captchaMethod === this.GEETESTCAPTCHA) {
            if (lang.startsWith('zh')) {
                lang = 'zho';
            }
            if (!this.geeTestObject) {
                this.showGtCaptcha = true;
                this.initGeeTest({
                    captchaId: this.GEETESTCAPTCHA_SITEID,
                    product: 'float',
                    btnWidth: '100%',
                    language: lang,
                    protocol: 'https://',
                }, (x) => {
                    this.geeTestHandler(x);
                });
            }
            else {
                this.geeTestObject.reset();
            }
        }
    }
    initReCaptcha(lang) {
        this.reCaptchaPromise = this.$q((resolve) => {
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.id = 'reCaptchaScript';
            script.type = 'text/javascript';
            script.src = `https://www.${this.reCaptchaDomain}/recaptcha/api.js?render=${this.RECAPTCHA_SITEID}&hl=${lang}`;
            script.onload = () => resolve();
            head.appendChild(script);
        });
    }
    hideCaptcha() {
        return __awaiter(this, void 0, void 0, function* () {
            const isCaptchaEnabled = yield this.isCaptchaEnabled();
            if (isCaptchaEnabled) {
                if (this.__env.CaptchaMethod === this.RECAPTCHA) {
                    const reCaptchaScript = document.getElementById('reCaptchaScript');
                    if (reCaptchaScript) {
                        reCaptchaScript.remove();
                    }
                    document.querySelectorAll('.grecaptcha-badge').forEach((el) => {
                        el.remove();
                    });
                }
                else {
                    this.showGtCaptcha = false;
                }
            }
        });
    }
    geeTestHandler(geeTestObject) {
        geeTestObject.onReady(() => {
            this.showGtCaptcha = true;
        });
        geeTestObject.onSuccess(() => {
            this.gtCaptchaValid = true;
            this.gtCaptchaResponse = geeTestObject.getValidate();
            this.$rootScope.$digest();
        });
        geeTestObject.appendTo('#captcha');
        this.geeTestObject = geeTestObject;
    }
    getCaptchaResponse(act) {
        return __awaiter(this, void 0, void 0, function* () {
            const isCaptchaEnabled = yield this.isCaptchaEnabled();
            if (!isCaptchaEnabled) {
                return '';
            }
            if (this.captchaMethod === this.GEETESTCAPTCHA) {
                return angular.toJson(this.gtCaptchaResponse);
            }
            if (this.reCaptchaPromise) {
                return new Promise((resolve) => {
                    this.reCaptchaPromise.then(() => {
                        window.grecaptcha.ready(() => {
                            window.grecaptcha
                                .execute(this.RECAPTCHA_SITEID, { action: act })
                                .then((token) => {
                                resolve(token);
                            });
                        });
                    });
                });
            }
        });
    }
}
CaptchaService.$inject = ['__env', 'initGeeTest', '$rootScope', 'squareFactory', 'authService', 'participantService', '$q', 'featureService', 'serverConstants'];
