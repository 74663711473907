import { DateTime } from 'luxon';

type ConvertLuxonToString<O> =
  O extends readonly unknown[] ?
    {
      [K in keyof O]: ConvertLuxonToString<O[K]>;
    } :
    O extends DateTime ? string :
      O extends object ? {[K in keyof O as Extract<K, string>]: ConvertLuxonToString<O[K]> } : O

type ConvertStringToLuxon<O> =
  O extends readonly unknown[] ? {
    [K in keyof O]: ConvertStringToLuxon<O[K]>;
  } :
    O extends string ? string | DateTime :
      O extends object ? {[K in keyof O as Extract<K, string>]: ConvertStringToLuxon<O[K]> } : O

export const convertDateTimesForObject = <T>(data: T): ConvertStringToLuxon<T> => {
  if (!data) {
    return data as ConvertStringToLuxon<T>;
  }

  // Regex for iso dates e.g. 2021-08-23T08:30:50.453Z. Milliseconds are optional
  const isoDateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d{1,3})?Z$/;
  const t = Object.prototype.toString.apply(data);

  if (t === '[object String]' && (data as unknown as string).match(isoDateRegex)) {
    const date = DateTime.fromISO(data as unknown as string);
    if (date.isValid) {
      return date as ConvertStringToLuxon<T>;
    }
  }

  if (t === '[object Object]') {
    return Object.keys(data).reduce((result, key) => ({
      ...result,
      [key]: convertDateTimesForObject(data[key as keyof typeof data]),
    }), {}) as ConvertStringToLuxon<T>;
  }
  if (t === '[object Array]') {
    return (data as unknown as unknown[]).map((v) =>
      convertDateTimesForObject(v)) as unknown as ConvertStringToLuxon<T>;
  }

  return data as ConvertStringToLuxon<T>;
};

export const convertDateTimesToIsoStringForObject = <T>(data: T): ConvertLuxonToString<T> => {
  if (!data) {
    return data as ConvertLuxonToString<T>;
  }

  const t = Object.prototype.toString.apply(data);

  if (t === '[object Object]') {
    if (DateTime.isDateTime(data)) {
      return (data as unknown as DateTime).toUTC().toISO() as ConvertLuxonToString<T>;
    }

    return Object.keys(data).reduce((result, key) => ({
      ...result,
      [key]: (() => convertDateTimesToIsoStringForObject(data[key as keyof typeof data]))(),
    }), {}) as ConvertLuxonToString<T>;
  }

  if (t === '[object Array]') {
    return (data as unknown as unknown[]).map((v) =>
      convertDateTimesToIsoStringForObject(v)) as unknown as ConvertLuxonToString<T>;
  }

  return data as ConvertLuxonToString<T>;
};
