import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';
import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { guidRegex } from '@/router/router.constants';

export const externalLinkRoute: RouteRecordRaw = {
  name: RouteNames.ExternalLink,
  path: `/to/:externallinkguid(${guidRegex})`,
  component: getWrappedAngularComponent('externalLinkComponent'),
  meta: {
    requireLogin: false,
    tile: '(LabelWindowTitleHome)',
  },
};
