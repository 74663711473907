// @ts-expect-error types on window are not set
const envVars = window.__env ?? window;

/** Absolute URL */
export const AUTHENTICATION_BASE = (envVars.AUTHENTICATION_BASE || envVars.authenticationBase) as string;
/** Absolute URL formatted as: `${AUTHENTICATION_BASE}?return_uri=` */
export const AUTHENTICATION_URL = (envVars.AUTHENTICATION_URL || `${AUTHENTICATION_BASE}?return_uri=`) as string;
/** Absolute URL */
export const AZURE_BASE_URL = (envVars.AZURE_BASE_URL || envVars.azureBaseUrl) as string;
/** Relative URL */
export const BASE_URL = envVars.BASE_URL as string;
export const BUILD_VERSION = (envVars.BUILD_VERSION || envVars.buildVersion) as string;
/** Relative URL (Absolute URL on localhost) */
export const COMMAND_API_URL = (envVars.COMMAND_API_URL || envVars.commandApiUrl) as string;
/** Absolute URL */
export const DECIPHER_URL = (envVars.DECIPHER_URL || envVars.decipherUrl) as string;
/** Absolute URL */
export const HEALTHDASHBOARD_URL = (envVars.HEALTHDASHBOARD_URL || envVars.healthDashboardUrl) as string;
export const ENABLE_DEBUG = (envVars.ENABLE_DEBUG || envVars.enableDebug) as boolean;
export const ENVIRONMENT = (envVars.ENVIRONMENT || envVars.environment) as string;
export const GOOGLE_TAG_MANAGER_CONTAINER_ID = envVars.GOOGLE_TAG_MANAGER_CONTAINER_ID as string;
/** Absolute URL */
export const INSITES_URL = envVars.INSITES_URL as string;
export const INSTRUMENTATION_KEY = (envVars.INSTRUMENTATION_KEY || envVars.instrumentationKey) as string;
export const IS_ENVIRONMENT_AS_PREFIX = (envVars.IS_ENVIRONMENT_AS_PREFIX || envVars.isEnvironmentAsPrefix) as boolean;
export const JWT_KEY = envVars.JWT_KEY || 'SW5TaXRlc1NxdWFyZV9KV1Q=';
export const JWT_KEY_SQUARE = 'MkPB3ZVMUzEnncFXRbM5bBGG';
/** Relative URL (Absolute URL on localhost) */
export const QUERY_API_URL = (envVars.QUERY_API_URL || envVars.queryApiUrl) as string;
/** Relative URL (Absolute URL on localhost) */
export const SIGNALR_URL = (envVars.SIGNALR_URL || envVars.signalrUrl) as string;
/** Absolute URL without protocol (e.g. `dev-insiteseco.com`) */
export const SQUARE_URL_BASE = (envVars.SQUARE_URL_BASE || envVars.squareUrlBase) as string;
export const ICP_REDIRECT_URL = (envVars.ICP_REDIRECT_URL || envVars.ICPRedirectUrl) as string;
export const SHOW_ICP_LICENSE = (envVars.SHOW_ICP_LICENSE || envVars.showICPLicense) as boolean;
export const ICP_LICENSE_NUMBER = (envVars.ICP_LICENSE_NUMBER || envVars.ICPLicenseNumber) as string;
