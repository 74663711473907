import { IscMentionMenuController } from './mention-menu.controller';
export var IscMentionMenuComponent = {
    template: "\n    <ul class=\"dropdown-menu scrollable-menu\" style=\"display:block\">\n      <isc-mention-menu-item\n        typed-term=\"vm.typedTerm\"\n        is-active=\"vm.isActive(item)\"\n        activate=\"vm.activate(item)\"\n        select-item=\"vm.selectItem(item)\"\n        ng-repeat=\"item in vm.items track by $index\" item=\"item\"></isc-mention-menu-item>\n    </ul>\n  ",
    controllerAs: 'vm',
    controller: IscMentionMenuController,
    bindings: {
        search: '&iscMentionSearch',
        select: '&iscMentionSelect',
        items: '<iscMentionItems',
        triggerChar: '=iscMentionTriggerChar',
        addMenu: '&iscMentionAddMenu',
        replaceText: '&iscMentionReplaceText',
    },
};
