export class DownloadService {
    downloadResponse(response) {
        const contentDisposition = response.headers('Content-Disposition');
        const contentType = response.headers('Content-Type');
        const fileName = /filename="?(.+)"/.exec(contentDisposition)[1];
        this.download(response.data, contentType, fileName);
    }
    download(data, contentType, fileName) {
        const blob = new Blob([data], { type: contentType });
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.cssText = 'display:none';
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
