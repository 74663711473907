import RecordRTC from 'recordrtc';
import { DateTime } from 'luxon';
var IscMediaRecorderController = (function () {
    function IscMediaRecorderController($document, $timeout, $interval, $q) {
        this.$document = $document;
        this.$timeout = $timeout;
        this.$interval = $interval;
        this.$q = $q;
        this.showVideoOverlay = false;
        this.videoCountdown = 0;
        this.isRecording = false;
        this.previewCapture = false;
        this.picturePreview = false;
        this.timer = {
            minutes: '',
            seconds: '',
            interval: null,
            startTime: null,
        };
    }
    IscMediaRecorderController.prototype.$onInit = function () {
        this.initializeRecordingDevice();
        this.setTimer(0, 0);
    };
    IscMediaRecorderController.prototype.$onDestroy = function () {
        this.stopCameraStreaming();
    };
    IscMediaRecorderController.prototype.stopCameraStreaming = function () {
        if (this.cameraStream) {
            this.cameraStream.stop();
        }
    };
    IscMediaRecorderController.prototype.initializeRecordingDevice = function () {
        var _this = this;
        var constraints = {
            audio: true,
            video: true,
        };
        this.isRecording = false;
        this.previewCapture = false;
        this.picturePreview = false;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            this.getUserMediaStatus = 'pending';
            this.$q.when(navigator.mediaDevices.getUserMedia(constraints))
                .then(function (camera) {
                _this.getUserMediaStatus = 'ok';
                _this.video = _this.$document[0].querySelector('#videoInput');
                _this.cameraStream = camera;
                _this.video.removeAttribute('controls');
                _this.video.srcObject = _this.cameraStream;
                _this.video.play();
            }).catch(function (error) {
                _this.getUserMediaStatus = 'error';
            });
        }
    };
    IscMediaRecorderController.prototype.startRecording = function () {
        var _this = this;
        if (!this.showVideo) {
            return;
        }
        this.videoCountdown = 3;
        this.showVideoOverlay = true;
        this.isRecording = true;
        this.$interval(function () {
            _this.videoCountdown--;
        }, 1000, 3).then(function () {
            _this.showVideoOverlay = false;
            _this.recorder = RecordRTC(_this.cameraStream, {
                type: 'video',
                mimeType: 'video/webm',
            });
            _this.recorder.startRecording();
            _this.recorder.camera = _this.cameraStream;
            _this.startTimer();
        }).finally(function () {
            _this.showVideoOverlay = false;
        });
    };
    IscMediaRecorderController.prototype.stopRecording = function () {
        var _this = this;
        this.stopTimer();
        this.recorder.stopRecording(function () {
            _this.$timeout(function () {
                _this.video.src = _this.video.srcObject = null;
                var recorderResult = _this.recorder.getBlob();
                _this.mediaCapture = new Blob([recorderResult], { type: 'video/webm' });
                _this.mediaCapture.name = "webcammedia".concat(new Date().getTime(), ".mp4");
                _this.video.src = URL.createObjectURL(_this.mediaCapture);
                _this.video.setAttribute('controls', true);
                _this.recorder.camera.stop();
                _this.recorder.destroy();
                _this.recorder = null;
                _this.stopCameraStreaming();
                _this.isRecording = false;
                _this.previewCapture = true;
            }, 0);
        });
    };
    IscMediaRecorderController.prototype.recordAgain = function () {
        this.initializeRecordingDevice();
        this.mediaCapture = null;
    };
    IscMediaRecorderController.prototype.takePicture = function () {
        var _this = this;
        if (!this.showImage) {
            return;
        }
        var canvas = this.$document[0].querySelector('#picturePreview');
        canvas.width = 640;
        canvas.height = 480;
        var canvasContext = canvas.getContext('2d');
        canvasContext.drawImage(this.video, 0, 0, 640, 480);
        canvas.toBlob(function (blob) {
            _this.$timeout(function () {
                if (blob) {
                    _this.mediaCapture = blob;
                    _this.mediaCapture.name = "webcammedia".concat(new Date().getTime(), ".png");
                }
                _this.isRecording = false;
                _this.previewCapture = true;
                _this.picturePreview = true;
                _this.stopCameraStreaming();
            }, 0);
        });
    };
    IscMediaRecorderController.prototype.shouldShowTimer = function () {
        return this.showVideoOverlay && this.getUserMediaStatus === 'ok';
    };
    IscMediaRecorderController.prototype.shouldShowVideo = function () {
        return !this.picturePreview && this.getUserMediaStatus === 'ok';
    };
    IscMediaRecorderController.prototype.shouldShowPicturePreview = function () {
        return this.picturePreview && this.getUserMediaStatus === 'ok';
    };
    IscMediaRecorderController.prototype.startTimer = function () {
        var _this = this;
        this.stopTimer();
        this.setTimer(0, 0);
        this.timer.startTime = DateTime.now();
        this.timer.interval = this.$interval(function () {
            var currentTime = DateTime.now();
            var diff = currentTime.diff(_this.timer.startTime, ['minutes', 'seconds']);
            _this.setTimer(Math.floor(diff.minutes), Math.floor(diff.seconds));
        }, 1000);
    };
    IscMediaRecorderController.prototype.setTimer = function (minutes, seconds) {
        this.timer.minutes = minutes;
        this.timer.seconds = seconds < 10 ? '0' + seconds : seconds;
    };
    IscMediaRecorderController.prototype.stopTimer = function () {
        if (this.timer.interval) {
            this.$interval.cancel(this.timer.interval);
            this.timer.interval = null;
        }
    };
    IscMediaRecorderController.$inject = ['$document', '$timeout', '$interval', '$q'];
    return IscMediaRecorderController;
}());
export { IscMediaRecorderController };
