import { IscRedemptionThresholdCardController } from './isc-redemption-threshold-card.controller';
var iscRedemptionThresholdCardTemplate = "<md-card>\n  <md-card-title>\n      <md-card-title-text isc-translate=\"({{vm.titleText}})\" isc-translate-unitlabel=\"{{vm.unitLabel}}\"></md-card-title-text>\n  </md-card-title>\n\n  <md-card-content layout=\"column\" class=\"web\">\n    <div layout=\"row\" class=\"icons\">\n      <md-icon flex=\"40\" md-font-icon=\"fa fa-database\"></md-icon>\n      <md-icon flex=\"20\" ng-if=\"vm.value && vm.currency\" md-font-icon=\"far fa-arrow-right\"></md-icon>\n      <md-icon flex=\"40\" ng-if=\"vm.value && vm.currency\" md-font-icon=\"fas fa-gift\"></md-icon>\n    </div>\n    <div layout=\"row\">\n        <span flex=\"{{vm.value && vm.currency ? 40 : 100}}\">{{vm.points}} {{vm.unitLabel}}</span>\n        <span flex=\"20\" ng-if=\"vm.value && vm.currency\"></span>\n        <span flex=\"40\" ng-if=\"vm.value && vm.currency\">{{vm.value}} {{vm.currency}}</span>\n    </div>\n  </md-card-content>\n\n  <md-card-content layout=\"column\" class=\"mobile\">\n    <div layout=\"row\">\n      <div layout=\"row\" flex=\"{{vm.value && vm.currency ? 40 : 100}}\">\n        <span flex=\"50\" class=\"icons\">\n          <md-icon md-font-icon=\"fa fa-database\"></md-icon>\n        </span>\n        <span class=\"points\" flex=\"50\" layout=\"column\">\n          <span>{{vm.points}}</span>\n          <span>{{vm.unitLabel}}</span>\n        </span>\n      </div>\n      <div flex=\"20\" ng-if=\"vm.value && vm.currency\">\n          <md-icon md-font-icon=\"far fa-arrow-right\"></md-icon>\n      </div>\n      <div layout=\"row\" flex=\"40\" ng-if=\"vm.value && vm.currency\">\n        <span flex=\"50\" class=\"icons\">\n          <md-icon md-font-icon=\"fas fa-gift\"></md-icon>\n        </span>\n        <span class=\"points\" flex=\"50\" layout=\"column\">\n          <span>{{vm.value}}</span>\n          <span>{{vm.currency}}</span>\n        </span>\n      </div>\n    </div>\n  </md-card-content>\n</md-card>";
export var IscRedemptionThresholdCardComponent = {
    template: iscRedemptionThresholdCardTemplate,
    controller: IscRedemptionThresholdCardController,
    controllerAs: 'vm',
    bindings: {
        titleText: '@',
        points: '<',
        value: '<',
        currency: '@',
        unitLabel: '@',
    },
};
