import { __awaiter } from "tslib";
export class ContactController {
    constructor($stateParams, pageService, _, contactService, serverConstants, constantsfactory) {
        this.$stateParams = $stateParams;
        this.pageService = pageService;
        this._ = _;
        this.contactService = contactService;
        this.serverConstants = serverConstants;
        this.constantsfactory = constantsfactory;
        this.ticketPostFailed = false;
        this.messageSent = false;
        this.dataSending = false;
        this.messageTypes = [];
        this.ticketDetails = {
            Type: undefined,
            Subject: '',
            Message: '',
        };
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const contactMessageTypes = [this.serverConstants.serviceDeskTypeConstants.myAccount, this.serverConstants.serviceDeskTypeConstants.myIncentives,
                this.serverConstants.serviceDeskTypeConstants.technical, this.serverConstants.serviceDeskTypeConstants.other];
            for (const item of contactMessageTypes) {
                this.messageTypes.push({
                    Label: yield this.constantsfactory.getLabelValue(`LabelServiceDeskType${item}`),
                    MessageType: item,
                });
            }
            this.pageService.getPageDetails(this.$stateParams.helpcenterPage).then((pageDetails) => {
                this.page = pageDetails;
            });
        });
    }
    sendMessage() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataSending = true;
            try {
                yield this.contactService.sendTicket(this.ticketDetails);
                this.ticketDetails = {
                    Type: undefined,
                    Subject: '',
                    Message: '',
                };
                this.helpdeskForm.Type.$setUntouched();
                this.helpdeskForm.Subject.$setUntouched();
                this.helpdeskForm.Message.$setUntouched();
                this.helpdeskForm.Message.$dirty = false;
            }
            catch (ex) {
                this.ticketPostFailed = true;
            }
            finally {
                this.dataSending = false;
                this.messageSent = true;
                window.scrollTo(0, 0);
            }
        });
    }
    showSuccessMessage() {
        return !this.ticketPostFailed && this.messageSent && !this.dataSending;
    }
    showFailMessage() {
        return this.ticketPostFailed && this.messageSent && !this.dataSending;
    }
}
ContactController.$inject = ['$stateParams', 'pageService', '_', 'contactService', 'serverConstants', 'constantsfactory'];
