import * as angular from 'angular';
export class TaskListFactory {
    constructor() {
        this.tasks = [];
        window.addEventListener('beforeunload', (e) => {
            if (this.tasks.length > 0) {
                (e || window.event).returnValue = ' ';
                return ' ';
            }
        });
    }
    createTask(options) {
        const task = new Task(this);
        angular.extend(task, options);
        return task;
    }
    getTaskById(id) {
        return this.tasks.find((t) => t.id === id);
    }
}
export class Task {
    constructor(factory) {
        this.factory = factory;
        this.id = '';
        this.title = '';
        this.text = '';
        this.showCloseButton = false;
        this.onClick = null;
        this.theme = 'default';
        this.hide = () => {
            const index = this.factory.tasks.indexOf(this);
            if (index !== -1) {
                this.factory.tasks.splice(index, 1);
            }
        };
    }
    show() {
        this.factory.tasks.push(this);
    }
    updateProgress(progress) {
        this.progress = progress;
    }
}
