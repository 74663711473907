export var IncentiveCardType;
(function (IncentiveCardType) {
    IncentiveCardType[IncentiveCardType["credits"] = 0] = "credits";
    IncentiveCardType[IncentiveCardType["rewards"] = 1] = "rewards";
    IncentiveCardType[IncentiveCardType["periodstart"] = 2] = "periodstart";
    IncentiveCardType[IncentiveCardType["redemptionAction"] = 3] = "redemptionAction";
    IncentiveCardType[IncentiveCardType["redemptionHistory"] = 4] = "redemptionHistory";
    IncentiveCardType[IncentiveCardType["rewardEarned"] = 5] = "rewardEarned";
    IncentiveCardType[IncentiveCardType["rewardNotEarned"] = 6] = "rewardNotEarned";
    IncentiveCardType[IncentiveCardType["emptyContainer"] = 7] = "emptyContainer";
    IncentiveCardType[IncentiveCardType["manualRedemptionHistory"] = 8] = "manualRedemptionHistory";
    IncentiveCardType[IncentiveCardType["manualIntervention"] = 9] = "manualIntervention";
    IncentiveCardType[IncentiveCardType["leftover"] = 10] = "leftover";
    IncentiveCardType[IncentiveCardType["invalidation"] = 11] = "invalidation";
    IncentiveCardType[IncentiveCardType["info"] = 12] = "info";
})(IncentiveCardType || (IncentiveCardType = {}));
export var IncentiveCardSampleRemovedReason;
(function (IncentiveCardSampleRemovedReason) {
    IncentiveCardSampleRemovedReason[IncentiveCardSampleRemovedReason["none"] = 0] = "none";
    IncentiveCardSampleRemovedReason[IncentiveCardSampleRemovedReason["suspended"] = 1] = "suspended";
    IncentiveCardSampleRemovedReason[IncentiveCardSampleRemovedReason["unsubscribed"] = 2] = "unsubscribed";
    IncentiveCardSampleRemovedReason[IncentiveCardSampleRemovedReason["notInTarget"] = 4] = "notInTarget";
    IncentiveCardSampleRemovedReason[IncentiveCardSampleRemovedReason["activitySegmentTargetFull"] = 8] = "activitySegmentTargetFull";
})(IncentiveCardSampleRemovedReason || (IncentiveCardSampleRemovedReason = {}));
