import { UiModule } from './../../index';
var LabelService = (function () {
    function LabelService() {
    }
    LabelService.prototype.getLabels = function () {
        throw new Error('Please override this function with a function that gets the labels from the backend');
    };
    LabelService.prototype.getLabelValue = function (name) {
        return this.getLabels().then(function (dict) { return dict[name] || ''; });
    };
    return LabelService;
}());
export { LabelService };
UiModule.service('iscLabelService', LabelService);
