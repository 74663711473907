import { __extends } from "tslib";
import { HotkeySubscription } from '../HotKeySubscription';
var RedoHotkeyMac = (function (_super) {
    __extends(RedoHotkeyMac, _super);
    function RedoHotkeyMac($document) {
        var _this = _super.call(this) || this;
        _this.$document = $document;
        _this.keyCode = 90;
        _this.metaKey = true;
        _this.shiftKey = true;
        return _this;
    }
    RedoHotkeyMac.prototype.handler = function (event) {
        this.redo(event);
    };
    RedoHotkeyMac.prototype.redo = function (event) {
        this.$document[0].execCommand('redo', false);
        event.preventDefault();
    };
    return RedoHotkeyMac;
}(HotkeySubscription));
export { RedoHotkeyMac };
