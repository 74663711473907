import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useSquareStore } from '@/store/square-store';

const AdActivationPage = () => import('@/pages/activation-page.vue');

export const adActivationRoute: RouteRecordRaw = {
  name: RouteNames.ADActivation,
  path: '/activation',
  component: AdActivationPage,
  meta: {
    title: '',
    requireLogin: false,
  },
  beforeEnter: (_to, from: RouteLocationNormalized) => {
    // If already logged in and navigating to verify its you page, redirect to home page
    if (useAuthenticationStore().isAuthorized || !useSquareStore().info.adLogin) {
      // if we are coming directly from email migration and we have token, we allow this route
      if (from.name === RouteNames.ADEmailMigration) {
        return true;
      }
      return { name: RouteNames.Home };
    }
  },
};
