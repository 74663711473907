export const removeHtmlFromLabel = (label: string, replaceBlockElementsWithBreakLines = false) => {
  const labelHtml = new DOMParser()
    .parseFromString(label, 'text/html')
    .body;
  const blockElements = [
    'address',
    'blockquote',
    'body',
    'center',
    'dir',
    'div',
    'dl',
    'fieldset',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'isindex',
    'menu',
    'noframes',
    'noscript',
    'ol',
    'p',
    'pre',
    'table',
    'ul',
    'dd',
    'dt',
    'frameset',
    'li',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'html',
  ];
    // Remove all HTML tags except break lines.
    // Ensure all block elements are being put on a separate line if replaceBlockElementsWithBreakLines is true
    // Otherwise add a spacing between block elements to keep it clear that they are separate items.
  Array.from(labelHtml.querySelectorAll('*'))
    .reverse()
    .forEach((e) => {
      if (e.nodeName.toLowerCase() === 'br') {
        if (!replaceBlockElementsWithBreakLines) {
          e.outerHTML = '';
        }
        return;
      }
      if (blockElements.includes(e.nodeName.toLowerCase())) {
        e.outerHTML = replaceBlockElementsWithBreakLines ? `<br>${e.innerHTML}<br>` : ` ${e.innerHTML} `;
        return;
      }

      e.outerHTML = e.innerHTML;
    });

  return labelHtml.innerHTML
    .trim() // Remove leading and trailing white spaces
    .replaceAll(/ {2,}/g, ' ') // Replace all repeating spaces with only 1 space
    .replaceAll(/(?:<br>){2,}/g, '<br>') // Replace all repeating break lines with only 1 break line
    .replace(/<br>( \(\d+\))*$/, '$1') // Remove the trailing break line if present. Make sure to keep the match number into account.
    .replace(/^<br>/, ''); // Remove leading break line.
};
