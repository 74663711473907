import { camelCase } from 'lodash-es';
import { DateTime } from 'luxon';

type AlphaNumeric =
  'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x' | 'y' | 'z' |
  'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z' |
  '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
type OnlyAlphaNumeric<T extends string, A extends string = ''> =
  T extends `${infer F}${infer R}`
    ? OnlyAlphaNumeric<R, `${A}${F extends AlphaNumeric ? F : ''}`>
    : A;

type FirstLetterOfString<T extends string> = T extends `${infer A}${string}` ? A : '';

type PascalCasedStringToCamelCased<
  StillToCheck extends string,
  PreviouslyCheckedString extends string = '',
  OriginalPreviousChar extends string = ''
> = StillToCheck extends OnlyAlphaNumeric<StillToCheck>
  ? StillToCheck extends `${infer CurrentChar}${infer StillToCheckMinusCurrent}`
    ? PreviouslyCheckedString extends ''
      ? PascalCasedStringToCamelCased<
        StillToCheckMinusCurrent,
        Lowercase<CurrentChar>,
        CurrentChar
      > // CurrentChar is the first character of the string so should be lowerCased. The rest will be converted.
      : CurrentChar extends Uppercase<CurrentChar>
        ? OriginalPreviousChar extends Uppercase<OriginalPreviousChar>
          ? StillToCheckMinusCurrent extends ''
            ? `${PreviouslyCheckedString}${Lowercase<CurrentChar>}`
            // The OriginalPreviousChar was uppercased and the currentChar is the last in the string. Will always be lowercased.
            : FirstLetterOfString<StillToCheckMinusCurrent> extends Uppercase<
              FirstLetterOfString<StillToCheckMinusCurrent>
            >
              ? PascalCasedStringToCamelCased<
                StillToCheckMinusCurrent,
              `${PreviouslyCheckedString}${Lowercase<CurrentChar>}`,
              CurrentChar
              > // The OriginalPreviousChar was uppercased and the next character is uppercased. Will always be lowercased
              : PascalCasedStringToCamelCased<
                StillToCheckMinusCurrent,
              `${PreviouslyCheckedString}${CurrentChar}`,
              CurrentChar
              > // The OriginalPreviousChar was uppercased but the next one is lowercased. Keep current form.
          : PascalCasedStringToCamelCased<
            StillToCheckMinusCurrent,
            `${PreviouslyCheckedString}${CurrentChar}`,
            CurrentChar
          > // OriginalPreviousChar was lowercased, so the current should be keep its current form
        : PascalCasedStringToCamelCased<
          StillToCheckMinusCurrent,
          `${PreviouslyCheckedString}${CurrentChar}`,
          CurrentChar
        > // CurrentChar is not uppercased (and not the first in the string)
    : PreviouslyCheckedString // Only true if string is empty to begin with
  : StillToCheck;

export type CamelCase<T> =
  T extends readonly unknown[]
    ? { [K in keyof T]: CamelCase<T[K]> }
    : T extends DateTime
      ? T // We don't want to convert Luxon DateTime objects
      : T extends object
        ? { [K in keyof T as PascalCasedStringToCamelCased<Extract<K, string>>]: CamelCase<T[K]>; }
        : T;

type CamelCasedStringToPascalCased<T extends string> =
  T extends OnlyAlphaNumeric<T> // Only convert strings with no special characters
    ? Capitalize<T>
    : T;

export type PascalCase<T> = T extends readonly unknown[]
  ? { [K in keyof T]: PascalCase<T[K]> }
  : T extends DateTime
    ? T // We don't want to convert Luxon DateTime objects
    : T extends object
      ? { [K in keyof T as CamelCasedStringToPascalCased<Extract<K, string>>]: PascalCase<T[K]>; }
      : T;

const upperFirst = (string?: string) => {
  if(!string) {
    return '';
  }
  return `${string[0].toUpperCase()}${string.slice(1)}`;
};


/**
 * Deeply converts all PascalCased property names to camelCased property names.
 * This included properties that are entirely uppercased
 *
 * The following types will NOT be converted: snake_case, kebab-case, SCREAMING_SNAKE_CASE, strings with spaces in them.
 * @param obj The object to deeply convert.
 */
export const convertPascalCasedObjectToCamelCasedObject = <T>(obj: T): CamelCase<T> => {
  if (DateTime.isDateTime(obj)) {
    return obj as CamelCase<T>;
  }
  const t = Object.prototype.toString.apply(obj);
  if (t === '[object Object]') {
    return Reflect.ownKeys(obj as unknown as object).reduce(
      (result, key) => {
        const shouldConvert = typeof key !== 'symbol' && key.match(/^[a-z0-9]+$/i);
        const objKey = shouldConvert ? camelCase(key) : key;

        return {
          ...result,
          [objKey]: convertPascalCasedObjectToCamelCasedObject(obj[key as keyof typeof obj]),
        };
      },
      {},
    ) as CamelCase<T>;
  } else if (t === '[object Array]') {
    return (obj as unknown as unknown[]).map((v) => convertPascalCasedObjectToCamelCasedObject(v)) as unknown as CamelCase<T>;
  }
  return obj as CamelCase<T>;
};

/**
 * Deeply converts all camelCased property names to PascalCased property names.
 * This included properties that are entirely lowercased
 *
 * The following types will NOT be converted: snake_case, kebab-case, SCREAMING_SNAKE_CASE, UPPERCASE, strings with spaces in them.
 * @param obj The object to deeply convert.
 */
export const convertCamelCasedObjectToPascalCasedObject = <T>(obj: T): PascalCase<T> => {
  if (DateTime.isDateTime(obj)) {
    return obj as PascalCase<T>;
  }
  const t = Object.prototype.toString.apply(obj);
  if (t === '[object Object]') {
    return Reflect.ownKeys(obj as unknown as object).reduce(
      (result, key) => {
        const shouldConvert = typeof key !== 'symbol' && key.match(/^[a-z0-9]+$/i);
        const objKey = shouldConvert ? upperFirst(key) : key;

        return {
          ...result,
          [objKey]: convertCamelCasedObjectToPascalCasedObject(obj[key as keyof typeof obj]),
        };
      },
      {},
    ) as PascalCase<T>;
  } else if (t === '[object Array]') {
    return (obj as unknown as unknown[]).map((v) => convertCamelCasedObjectToPascalCasedObject(v)) as unknown as PascalCase<T>;
  }
  return obj as PascalCase<T>;
};
