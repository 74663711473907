import {
  AutoLoginResponse,
  TokenForSquareResponse,
  GetTokenInfoResponse,
  ShouldShowCaptchaResponse,
  StatusForAuthorizationResponse,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const autoLogin = (sampleGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<AutoLoginResponse>('/AuthorizationService/AutoLogin', { sampleGuid }, config);

export const getTokenForSquare = (clientGuid: string, squareGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<TokenForSquareResponse>('/AuthorizationService/GetTokenForSquare', { clientGuid, squareGuid }, config);

export const getTokenForClient = (clientGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<void>('/AuthorizationService/GetTokenForClient', { clientGuid }, config);

export const getTokenForSignalR = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/AuthorizationService/GetTokenForSignalR', undefined, config);

export const getTokenInfo = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetTokenInfoResponse>('/AuthorizationService/GetTokenInfo', undefined, config);

export const shouldShowCaptcha = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ShouldShowCaptchaResponse>('/AuthorizationService/ShouldShowCaptcha', undefined, config);

export const hasInSitesUserAccess = (clientGuid: string, squareGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/AuthorizationService/HasInSitesUserAccess', { clientGuid, squareGuid }, config);

export const getStatusForAuthorization = (username?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<StatusForAuthorizationResponse>('/AuthorizationService/GetStatusForAuthorization', { username }, config);

export const getTokenFor = (url?: string, clientCode?: string, squareGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/AuthorizationService/GetTokenFor', { url, clientCode, squareGuid }, config);

export const getTokenForUser = (email?: string, clientCode?: string, squareGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/AuthorizationService/GetTokenForUser', { email, clientCode, squareGuid }, config);
