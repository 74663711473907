import { __awaiter } from "tslib";
export class BeaconService {
    constructor($window, __env) {
        this.$window = $window;
        this.__env = __env;
    }
    sendBeacon(relativeUrl, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const beaconFormData = new FormData();
            for (const prop in data) {
                beaconFormData.append(prop, data[prop]);
            }
            return this.$window.navigator.sendBeacon(`${this.__env.commandApiUrl}${relativeUrl}`, beaconFormData);
        });
    }
}
BeaconService.$inject = [
    '$window',
    '__env',
];
