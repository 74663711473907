var HtmlUtils = (function () {
    function HtmlUtils() {
    }
    HtmlUtils.sanitizeRichEditorContent = function (value, $sanitize, $sce) {
        var styles = this.getElementsStyleAttributes(value);
        value = this.escapeStyles(value, styles);
        var sanititizedHtml = $sanitize($sce.getTrustedHtml($sce.trustAsHtml(value))) || '';
        sanititizedHtml = this.applyRemoveStyles(sanititizedHtml, styles);
        return sanititizedHtml;
    };
    HtmlUtils.removeStyles = function (value) {
        var styles = this.getElementsStyleAttributes(value);
        styles.styleAttributes.forEach(function (style) {
            value = value.replace(style, '');
        });
        return value;
    };
    HtmlUtils.getElementsStyleAttributes = function (htmlContent) {
        var result = {
            styleTags: [],
            styleAttributes: [],
        };
        var styleAttributesRegex = /(style="[\W\w]*?")|(style='[\W\w]*?')/g;
        var styleTagsRegex = /<style>[\n]*<!--[\n\W\w]*?<\/style>/m;
        var styleTagsMatch = styleTagsRegex.exec(htmlContent);
        if (styleTagsMatch != null) {
            result.styleTags.push(styleTagsMatch[0]);
        }
        var match = styleAttributesRegex.exec(htmlContent);
        var index = 0;
        while (match != null) {
            index++;
            result.styleAttributes.push({
                index: 'scope="styleNumber' + index + '"',
                style: match[0].toString(),
            });
            match = styleAttributesRegex.exec(htmlContent);
        }
        return result;
    };
    HtmlUtils.escapeStyles = function (htmlContent, stylesToRemove) {
        stylesToRemove.styleAttributes.forEach(function (item) {
            htmlContent = htmlContent.replace(item.style, item.index);
        });
        return htmlContent;
    };
    HtmlUtils.applyRemoveStyles = function (htmlContent, stylesRemoved) {
        stylesRemoved.styleAttributes.forEach(function (item) {
            htmlContent = htmlContent.replace(item.index, item.style);
        });
        stylesRemoved.styleTags.forEach(function (item) {
            htmlContent = item + ' ' + htmlContent;
        });
        return htmlContent;
    };
    return HtmlUtils;
}());
export { HtmlUtils };
