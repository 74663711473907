import { __awaiter } from "tslib";
export class ProfileActivityController {
    constructor(activityService, $stateParams, $state, _, serverConstants, $timeout) {
        this.activityService = activityService;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this._ = _;
        this.serverConstants = serverConstants;
        this.$timeout = $timeout;
        this.cleanupFunctions = [];
        this.activity = null;
        this.isCompleted = false;
        this.surveyWasCompletedBefore = false;
        this.isComponentDestroyed = false;
    }
    $onInit() {
        this.loadActivity();
    }
    $onDestroy() {
        this._.each(this.cleanupFunctions, (cleanup) => {
            cleanup();
        });
        this.isComponentDestroyed = true;
    }
    loadActivity() {
        const activitiesSubscription = this.activityService.profileActivities
            .subscribe((activities) => {
            this.setActivities(activities);
        });
        this.activityService.profileActivities.toPromise().then((activities) => {
            this.setActivities(activities);
        });
        this.activityService.getProfileSurveyLink(this.$stateParams.activityGuid).then((link) => {
            this.surveyUrl = link;
        });
        this.cleanupFunctions.push(activitiesSubscription);
    }
    setActivities(activities) {
        this.activities = activities;
        this.activity = this._.find(activities, { Guid: this.$stateParams.activityGuid });
        this.surveyWasCompletedBefore = this.activity && this.activity.SampleStatus === this.serverConstants.sampleStatusConstants.completed;
    }
    surveyComplete() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isCompleted = true;
            const activities = yield this.activityService.profileActivities.toPromise();
            const nextIncompleteActivity = this._.find(activities, (activity) => activity.Guid !== this.activity.Guid && activity.CompletedPercentage < 100);
            this.$timeout(() => {
                if (this.isComponentDestroyed) {
                    return;
                }
                if (nextIncompleteActivity) {
                    const currentStateParams = this.$state.params;
                    currentStateParams.activityGuid = nextIncompleteActivity.Guid;
                    this.$state.go('.', {
                        activityGuid: nextIncompleteActivity.Guid,
                    }, { location: 'replace' });
                }
            }, 5000);
        });
    }
}
ProfileActivityController.$inject = ['activityService', '$stateParams', '$state', '_', 'serverConstants', '$timeout'];
