import { __awaiter } from "tslib";
export class IncentiveLandingpageController {
    constructor(_, $state, $stateParams, projectService, incentiveService, serverConstants, iscPages) {
        this._ = _;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.projectService = projectService;
        this.incentiveService = incentiveService;
        this.serverConstants = serverConstants;
        this.iscPages = iscPages;
        this.model = {
            Guid: this.$stateParams.rewardWinnerGuid,
            FullName: '',
            AddressLine1: '',
            AddressLine2: '',
            ZIPPostalCode: '',
            City: '',
            Country: '',
            StateProvince: '',
        };
        this.winnerCanConfirm = false;
        this.showMessageEnabled = false;
        this.validationConstants = this.serverConstants.validationConstants;
        this.rewardSuccess = false;
        this.rewardExpired = false;
        this.rewardConfirmed = false;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataLoading = true;
            const userDetails = yield this.incentiveService.getPotentialWinnerUserDetails(this.$stateParams.rewardWinnerGuid);
            if (userDetails.IsRewardWinner === true) {
                this.isRewardpartnerSurvMan = userDetails.RewardPartnerType === this.serverConstants.rewardPartnerTypeConstants.paypal ||
                    userDetails.RewardPartnerType === this.serverConstants.rewardPartnerTypeConstants.emag;
                const linkResponse = yield this.incentiveService.checkGetRewardLinkValidity(this.$stateParams.rewardWinnerGuid);
                if (linkResponse.DetailsConfirmed === true) {
                    this.showMessage('rewardConfirmed');
                }
                else {
                    if (linkResponse.IsLinkValid === true) {
                        this.termslink = yield this.projectService.getTermsAndConditionsUrl();
                        this.winnerCanConfirm = true;
                    }
                    else {
                        this.showMessage('rewardExpired');
                    }
                }
            }
            else {
                this.$state.go('container.login', {
                    redirectState: this.$state.current.name,
                    redirectParams: {
                        rewardWinnerGuid: this.$state.params.rewardWinnerGuid,
                    },
                });
            }
            this.dataLoading = false;
        });
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataLoading = true;
            this.incentiveService.confirmRewardWinnerDetails(this.model).then(() => {
                this.winnerCanConfirm = false;
                this.showMessage('rewardSuccess');
            }).finally(() => {
                this.dataLoading = false;
            });
        });
    }
    showMessage(messageType) {
        switch (messageType) {
            case 'rewardSuccess':
                this.rewardSuccess = true;
                break;
            case 'rewardExpired':
                this.rewardExpired = true;
                break;
            case 'rewardConfirmed':
                this.rewardConfirmed = true;
                break;
            default:
                this.rewardConfirmed = true;
                break;
        }
        this.iscPages.init();
        this.showMessageEnabled = true;
    }
    get isEnabledMyIncentivePage() {
        if (!this.iscPages.incentives) {
            return false;
        }
        return this._.some(this.iscPages.incentives, ['PageType', this.serverConstants.pageTypeConstants.myIncentive]);
    }
    goToIncentive() {
        if (this.isEnabledMyIncentivePage) {
            this.$state.go('container.main.incentives');
        }
        else {
            this.$state.go('container.main.home');
        }
    }
}
IncentiveLandingpageController.$inject = ['_', '$state', '$stateParams', 'projectService', 'incentiveService', 'serverConstants', 'iscPages'];
