<template>
  <div class="navigation-menu">
    <navigation-bar-desktop v-if="!isSmallScreen" />
    <mobile-header v-if="isSmallScreen" />
    <navigation-bar-mobile v-if="isSmallScreen" />
  </div>
</template>

<script setup lang="ts">
import { useQuasar } from 'quasar';
import { computed } from 'vue';
import MobileHeader from './mobile-header.vue';
import NavigationBarDesktop from './navigation-bar-desktop.vue';
import NavigationBarMobile from './navigation-bar-mobile.vue';

const $q = useQuasar();
const isSmallScreen = computed(() => $q.screen.lt.md);

</script>
