import { __awaiter } from "tslib";
export class LoginFlowService {
    constructor(userService, authService, $state, squareFactory, serverConstants, iscPages, captchaService) {
        this.userService = userService;
        this.authService = authService;
        this.$state = $state;
        this.squareFactory = squareFactory;
        this.serverConstants = serverConstants;
        this.iscPages = iscPages;
        this.captchaService = captchaService;
        this.REGISTRATION_URL_PARAM = '?fromRegistration=true';
        this.DEFAULT_REDIRECT_STATE = 'container.main.home';
    }
    login(email_1, password_1) {
        return __awaiter(this, arguments, void 0, function* (email, password, captchaResponse = null) {
            const authResult = yield this.authService.authenticate({ username: email, password, captchaResponse });
            const result = authResult.success;
            if (result) {
                yield this.postLoginAction(authResult.activateWarning);
            }
            return result;
        });
    }
    loginAfterRegistration(token) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authService.setToken(token);
            yield this.postLoginAction(false);
        });
    }
    postLoginAction(activateWarning) {
        return __awaiter(this, void 0, void 0, function* () {
            const redirectState = this.$state.params.redirectState || this.DEFAULT_REDIRECT_STATE;
            this.captchaService.hideCaptcha();
            if (this.squareFactory.Status !== this.serverConstants.squareStatusConstants.archived &&
                (this.userService.hasModeratorRole() || this.userService.hasObserverRole())) {
                const redirectUrl = this.authService.getSquareAdminUrlForModeratorAndObserver(false, activateWarning);
                window.location.href = redirectUrl;
            }
            else {
                this.iscPages.resetPagesCache();
                yield this.iscPages.init();
                this.$state.go(redirectState, this.$state.params.redirectParams);
            }
        });
    }
}
LoginFlowService.$inject = ['userService', 'authService', '$state', 'squareFactory', 'serverConstants',
    'iscPages', 'captchaService'];
