import { imagesConstants } from '@/common/constants/constants';
import { AZURE_BASE_URL, ENVIRONMENT, IS_ENVIRONMENT_AS_PREFIX } from '@/common/env.config';
import { camelCase } from 'lodash-es';
import { defineStore } from 'pinia';
import { useSquareStore } from './square-store';

export const emptyImage = '/images/empty.png';
const envPrefix = IS_ENVIRONMENT_AS_PREFIX ? `${ENVIRONMENT.toLowerCase()}/` : '';

export const useImagesStore = defineStore('images', {
  state: () => ({
    logoTopBar: emptyImage,
    headerLogo: emptyImage,
    headerDesktop: emptyImage,
    headerMobile: emptyImage,
    background: emptyImage,
    logoTopBarLanding: emptyImage,
    backgroundLanding: emptyImage,
  }),

  actions: {
    async applyImages(images: string[]) {
      images.forEach((image) => {
        this.$state[camelCase(image) as keyof typeof this.$state] = this.baseUrl + image + this.imagesTParam;
      });

      // Make sure that the provided images are correct
      // and set fallbacks if not
      type StateKey = keyof typeof this.$state;
      await Promise.all(Object.entries(this.$state).map(async ([key, imageSrc]) => {
        const isImageCorrect = await checkImage(imageSrc);
        if (!isImageCorrect) {
          this.$state[key as StateKey] = emptyImage;
        }

        // We don't want to show the empty image for the headers so we replace it with an empty string
        const isMobileOrDesktopOrLogoHeader = key === camelCase(imagesConstants.headerDesktop) || key === camelCase(imagesConstants.headerMobile)
        || key === camelCase(imagesConstants.headerLogo);
        if (isMobileOrDesktopOrLogoHeader && this.$state[key as StateKey] === emptyImage) {
          this.$state[key as StateKey] = '';
        }
      }));
    },
  },

  getters: {
    baseUrl: () => {
      const squareInfo = useSquareStore().info;
      return `${AZURE_BASE_URL + envPrefix + squareInfo.clientGuid}/${squareInfo.guid}/design-images/`;
    },
    imagesTParam: () => {
      const squareInfo = useSquareStore().info;
      return squareInfo.dateLastImageChange ? `?t=${squareInfo.dateLastImageChange.toMillis()}` : '';
    },
  },
});

// Helper functions
const checkImage = (imgSrc: string) => new Promise<boolean>((resolve) => {
  const img = new Image();
  img.onerror = () => {
    resolve(false);
  };
  img.onload = () => {
    resolve(true);
  };
  img.src = imgSrc;
});
