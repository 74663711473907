import { __awaiter, __generator } from "tslib";
var CaptionEditorController = (function () {
    function CaptionEditorController($q, iscLabelService) {
        this.$q = $q;
        this.iscLabelService = iscLabelService;
        this.placeholder = 'Add a caption and/or #hashtags';
    }
    CaptionEditorController.prototype.$onInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4, this.iscLabelService.getLabelValue('CaptionPlaceholder')];
                    case 1:
                        _a.placeholder = _b.sent();
                        return [2];
                }
            });
        });
    };
    CaptionEditorController.prototype.validPost = function () {
        return (this.message && this.message.length > 0);
    };
    CaptionEditorController.prototype.iscMentionSearchFn = function (term) {
        if (this.iscMentionSearch) {
            return this.iscMentionSearch({ term: term });
        }
    };
    CaptionEditorController.prototype.iscMentionSelectFn = function (item) {
        if (this.iscMentionSelect) {
            return this.iscMentionSelect({ item: item });
        }
    };
    CaptionEditorController.prototype.iscHashtagSearchFn = function (term) {
        return this.iscHashtagSearch({ term: term });
    };
    CaptionEditorController.prototype.iscHashtagSelectFn = function (item) {
        return this.iscHashtagSelect({ item: item });
    };
    CaptionEditorController.$inject = ['$q', 'iscLabelService'];
    return CaptionEditorController;
}());
export { CaptionEditorController };
