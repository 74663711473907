var IscTaggingBaseUtil = (function () {
    function IscTaggingBaseUtil($window, $location, $anchorScroll, $timeout) {
        this.$window = $window;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$timeout = $timeout;
    }
    IscTaggingBaseUtil.prototype.popUnderTagging = function (triggerCharSet, selectionEl) {
        var _this = this;
        var coordinates;
        var taggingInfo = this.getTriggerInfo(triggerCharSet, true, false);
        var parent = selectionEl.parent()[0];
        var parentBoundingRect = parent.getBoundingClientRect();
        if (taggingInfo !== undefined) {
            coordinates = this.getContentEditableCaretPosition(taggingInfo.taggingPosition);
            var menuTopOffset = parent.style.fontSize ? (parent.style.fontSize + 2) : 16;
            selectionEl.css({
                top: coordinates.top - parentBoundingRect.top + menuTopOffset + 'px',
                left: coordinates.left - parentBoundingRect.left + 'px',
                position: 'absolute',
                zIndex: 3,
                display: 'block',
            });
            this.$timeout(function () {
                _this.scrollIntoView(selectionEl);
            }, 0);
        }
        else {
            selectionEl.css({
                display: 'none',
            });
        }
    };
    IscTaggingBaseUtil.prototype.deleteIfTagging = function (event) {
        var sel;
        sel = window.getSelection();
        if (sel && sel.anchorNode && sel.anchorNode.parentElement) {
            var parentEl = sel.anchorNode.parentElement;
            if (parentEl) {
                var isMentionEl = parentEl.classList.contains(this.tagCssClassName());
                if (isMentionEl) {
                    parentEl.remove();
                    event.preventDefault();
                }
            }
        }
    };
    IscTaggingBaseUtil.prototype.replaceTriggerText = function (targetElement, path, offset, triggerCharSet, text, hasTrailingSpace) {
        this.resetSelection(targetElement, path, offset);
        var mentionInfo = this.getTriggerInfo(triggerCharSet, true, hasTrailingSpace);
        if (mentionInfo !== undefined) {
            this.pasteHtml(text, mentionInfo.taggingPosition, mentionInfo.taggingPosition + mentionInfo.taggingText.length + 1);
        }
    };
    IscTaggingBaseUtil.prototype.getTriggerInfo = function (triggerCharSet, menuAlreadyActive, hasTrailingSpace) {
        var selected;
        var path;
        var offset;
        var selectionInfo = this.getContentEditableSelectedPath();
        if (selectionInfo) {
            selected = selectionInfo.selected;
            path = selectionInfo.path;
            offset = selectionInfo.offset;
        }
        var effectiveRange = this.getTextPrecedingCurrentSelection();
        if (effectiveRange !== undefined && effectiveRange !== null) {
            var mostRecentTriggerCharPos_1 = -1;
            var triggerChar_1;
            triggerCharSet.forEach(function (c) {
                var idx = effectiveRange.lastIndexOf(c);
                if (idx > mostRecentTriggerCharPos_1) {
                    mostRecentTriggerCharPos_1 = idx;
                    triggerChar_1 = c;
                }
            });
            if (mostRecentTriggerCharPos_1 >= 0 &&
                (mostRecentTriggerCharPos_1 === 0 ||
                    /[\xA0\s]/g.test(effectiveRange.substring(mostRecentTriggerCharPos_1 - 1, mostRecentTriggerCharPos_1)))) {
                var currentTriggerSnippet = effectiveRange.substring(mostRecentTriggerCharPos_1 + 1, effectiveRange.length);
                triggerChar_1 = effectiveRange.substring(mostRecentTriggerCharPos_1, mostRecentTriggerCharPos_1 + 1);
                var firstSnippetChar = currentTriggerSnippet.substring(0, 1);
                var leadingSpace = currentTriggerSnippet.length > 0 &&
                    (firstSnippetChar === ' ' ||
                        firstSnippetChar === '\xA0');
                if (hasTrailingSpace) {
                    currentTriggerSnippet = currentTriggerSnippet.trim();
                }
                if (!leadingSpace && (menuAlreadyActive || !(/[\xA0\s]/g.test(currentTriggerSnippet)))) {
                    return {
                        taggingPosition: mostRecentTriggerCharPos_1,
                        taggingText: currentTriggerSnippet,
                        taggingSelectedElement: selected,
                        taggingSelectedPath: path,
                        taggingSelectedOffset: offset,
                        taggingTriggerChar: triggerChar_1,
                    };
                }
            }
        }
    };
    IscTaggingBaseUtil.prototype.scrollIntoView = function (elem) {
        var reasonableBuffer = 20;
        var maxScrollDisplacement = 100;
        var clientRect;
        var e = elem[0];
        while (clientRect === undefined || clientRect.height === 0) {
            clientRect = e.getBoundingClientRect();
            if (clientRect.height === 0) {
                e = e.childNodes[0];
                if (e === undefined || !e.getBoundingClientRect) {
                    return;
                }
            }
        }
        var elemTop = clientRect.top;
        var elemBottom = elemTop + clientRect.height;
        if (elemTop < 0) {
            this.$window.scrollTo(0, this.$window.pageYOffset + clientRect.top - reasonableBuffer);
        }
        else if (elemBottom > this.$window.innerHeight) {
            var maxY = this.$window.pageYOffset + clientRect.top - reasonableBuffer;
            if (maxY - this.$window.pageYOffset > maxScrollDisplacement) {
                maxY = this.$window.pageYOffset + maxScrollDisplacement;
            }
            var targetY = this.$window.pageYOffset - (this.$window.innerHeight - elemBottom);
            if (targetY > maxY) {
                targetY = maxY;
            }
            this.$window.scrollTo(0, targetY);
        }
    };
    IscTaggingBaseUtil.prototype.selectElement = function (targetElement, path, offset) {
        var range;
        var elem = targetElement;
        if (path) {
            for (var i = 0; i < path.length; i++) {
                elem = elem.childNodes[path[i]];
                if (elem === undefined) {
                    return;
                }
                while (elem.length < offset) {
                    offset -= elem.length;
                    elem = elem.nextSibling;
                }
                if (elem.childNodes.length === 0 && !elem.length) {
                    elem = elem.previousSibling;
                }
            }
        }
        var sel = window.getSelection();
        range = document.createRange();
        range.setStart(elem, offset);
        range.setEnd(elem, offset);
        range.collapse(true);
        try {
            sel.removeAllRanges();
        }
        catch (error) { }
        sel.addRange(range);
        targetElement.focus();
    };
    IscTaggingBaseUtil.prototype.pasteHtml = function (html, startPos, endPos) {
        var range;
        var sel;
        sel = window.getSelection();
        range = document.createRange();
        range.setStart(sel.anchorNode, startPos);
        range.setEnd(sel.anchorNode, endPos);
        range.deleteContents();
        var parentNode = range.startContainer.parentNode;
        if (parentNode.matches && parentNode.matches(".".concat(this.tagCssClassName()))) {
            parentNode.remove();
        }
        else if (parentNode.msMatchesSelector && parentNode.msMatchesSelector(".".concat(this.tagCssClassName()))) {
            parentNode.remove();
        }
        var el = document.createElement('div');
        el.innerHTML = html;
        el.querySelector('span').classList.add(this.tagCssClassName());
        var frag = document.createDocumentFragment();
        var node;
        var lastNode;
        while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        if (lastNode) {
            range = range.cloneRange();
            range.setStartAfter(lastNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    };
    IscTaggingBaseUtil.prototype.resetSelection = function (targetElement, path, offset) {
        this.selectElement(targetElement, path, offset);
    };
    IscTaggingBaseUtil.prototype.getNodePositionInParent = function (elem) {
        if (elem.parentNode === null) {
            return 0;
        }
        for (var i = 0; i < elem.parentNode.childNodes.length; i++) {
            var node = elem.parentNode.childNodes[i];
            if (node === elem) {
                return i;
            }
        }
    };
    IscTaggingBaseUtil.prototype.getContentEditableSelectedPath = function () {
        var sel = window.getSelection();
        var selected = sel.anchorNode;
        var path = [];
        var offset;
        if (selected != null) {
            var i = void 0;
            var ce = selected.contentEditable;
            while (selected !== null && ce !== 'true') {
                i = this.getNodePositionInParent(selected);
                path.push(i);
                selected = selected.parentNode;
                if (selected !== null) {
                    ce = selected.contentEditable;
                }
            }
            path.reverse();
            offset = sel.getRangeAt(0).startOffset;
            return {
                selected: selected,
                path: path,
                offset: offset,
            };
        }
    };
    IscTaggingBaseUtil.prototype.getTextPrecedingCurrentSelection = function () {
        var text;
        var selectedElem = window.getSelection().anchorNode;
        if (selectedElem != null && selectedElem.nodeType && selectedElem.nodeType === 3) {
            var workingNodeContent = selectedElem.textContent;
            var selectStartOffset = window.getSelection().getRangeAt(0).startOffset;
            if (selectStartOffset >= 0) {
                text = workingNodeContent.substring(0, selectStartOffset);
            }
        }
        return text;
    };
    IscTaggingBaseUtil.prototype.getContentEditableCaretPosition = function (selectedNodePosition) {
        var markerTextChar = '\ufeff';
        var markerEl;
        var markerId = 'sel_' + new Date().getTime() + '_' + Math.random().toString().substr(2);
        var range;
        var sel = window.getSelection();
        var prevRange = sel.getRangeAt(0);
        range = document.createRange();
        range.setStart(sel.anchorNode, selectedNodePosition);
        range.setEnd(sel.anchorNode, selectedNodePosition);
        range.collapse(false);
        markerEl = document.createElement('span');
        markerEl.id = markerId;
        markerEl.appendChild(document.createTextNode(markerTextChar));
        range.insertNode(markerEl);
        var markerBoundingRect = markerEl.getBoundingClientRect();
        sel.removeAllRanges();
        sel.addRange(prevRange);
        var coordinates = {
            left: markerBoundingRect.left,
            top: markerBoundingRect.top,
        };
        return coordinates;
    };
    return IscTaggingBaseUtil;
}());
export { IscTaggingBaseUtil };
