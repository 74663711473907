import { UiModule } from './../index';
import '../components/modal/isc-modal-preset';
UiModule.config(configure);
configure.$inject = ['$mdDialogProvider'];
function configure($mdDialogProvider) {
    $mdDialogProvider.addPreset('iscAlert', createSimpleDialogPreset(['ok', 'text', 'title']));
    $mdDialogProvider.addPreset('iscConfirm', createSimpleDialogPreset(['ok', 'cancel', 'stay', 'text', 'title']));
    $mdDialogProvider.addPreset('iscConfirmationWarning', createSimpleDialogPreset(['ok', 'cancel', 'text', 'title', 'question']));
    $mdDialogProvider.addPreset('iscPrompt', createSimpleDialogPreset(['ok', 'cancel', 'title', 'text', 'placeholder', 'requiredText', 'maxLength', 'maxLengthMessage']));
}
function createSimpleDialogPreset(methods) {
    return {
        methods: methods,
        options: function () {
            return {
                template: "<isc-modal-preset title-text=\"{{vm.title}}\" stay-text=\"{{vm.stay}}\" cancel-text=\"{{vm.cancel}}\"\n        ok-text=\"{{vm.ok}}\"  text = \"{{vm.text}}\" placeholder=\"{{vm.placeholder}}\" required-text=\"{{vm.requiredText}}\"\n        max-length=\"{{vm.maxLength}}\" max-length-message=\"{{vm.maxLengthMessage}}\"> </isc-modal-preset>",
                bindToController: true,
                controllerAs: 'vm',
                controller: function () {
                    'use strict';
                },
                multiple: true,
            };
        },
    };
}
