import * as angular from 'angular';
var IscTaggingBaseController = (function () {
    function IscTaggingBaseController($scope, $timeout, $attrs, $document, iscTaggingUtil, $element, $parse) {
        var _this = this;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$attrs = $attrs;
        this.$document = $document;
        this.iscTaggingUtil = iscTaggingUtil;
        this.$element = $element;
        this.$parse = $parse;
        this.items = [];
        this.query = function (triggerChar, triggerText) {
            var remoteScope = _this.triggerCharMap[triggerChar];
            triggerText = triggerText.trim();
            remoteScope.showMenu();
            remoteScope.search({
                term: triggerText,
            });
            remoteScope.typedTerm = triggerText;
        };
        this.bridgeSearch = function (termString) {
            _this.search(_this.$scope, { term: termString }).then(function (res) {
                _this.items = res;
            });
        };
        this.bridgeSelect = function (itemVar) {
            return _this.select(_this.$scope, {
                item: itemVar,
            });
        };
        this.setTriggerText = function (text) {
            if (_this.syncTriggerText) {
                _this.typedTerm = text.trim();
            }
        };
        this.replaceText = function (text, hasTrailingSpace) {
            _this.iscTaggingUtil.replaceTriggerText(_this.targetElement, _this.targetElementPath, _this.targetElementSelectedOffset, _this.triggerCharSet, text, hasTrailingSpace);
            if (!hasTrailingSpace) {
                _this.setTriggerText('');
                angular.element(_this.targetElement).triggerHandler('change');
                _this.contentEditableMenuPasted = true;
                var timer_1 = _this.$timeout(function () {
                    _this.contentEditableMenuPasted = false;
                }, 200);
                _this.$scope.$on('$destroy', function () {
                    _this.$timeout.cancel(timer_1);
                });
            }
            _this.hideAll();
        };
        this.deleteIfTagged = function (event) {
            _this.iscTaggingUtil.deleteIfTagging(event);
        };
        this.hideAll = function () {
            for (var key in _this.triggerCharMap) {
                if (_this.triggerCharMap.hasOwnProperty(key)) {
                    _this.triggerCharMap[key].hideMenu();
                }
            }
        };
        this.getActiveMenuScope = function () {
            for (var key in _this.triggerCharMap) {
                if (_this.triggerCharMap.hasOwnProperty(key)) {
                    if (_this.triggerCharMap[key].visible) {
                        return _this.triggerCharMap[key];
                    }
                }
            }
            return null;
        };
        this.selectActive = function () {
            for (var key in _this.triggerCharMap) {
                if (_this.triggerCharMap.hasOwnProperty(key)) {
                    if (_this.triggerCharMap[key].visible) {
                        _this.triggerCharMap[key].selectActive();
                    }
                }
            }
        };
        this.isActive = function () {
            for (var key in _this.triggerCharMap) {
                if (_this.triggerCharMap.hasOwnProperty(key)) {
                    if (_this.triggerCharMap[key].visible) {
                        return true;
                    }
                }
            }
            return false;
        };
        this.addMenu = function (menuScope) {
            if (_this.triggerCharMap.hasOwnProperty(menuScope.triggerChar)) {
                return;
            }
            _this.triggerCharMap[menuScope.triggerChar] = menuScope;
            if (_this.triggerCharSet === undefined) {
                _this.triggerCharSet = [];
            }
            _this.triggerCharSet.push(menuScope.triggerChar);
        };
        this.init();
        var searchRes = this.search($scope);
        var selectRes = this.select($scope);
        if (searchRes === undefined || selectRes === undefined) {
            this.disabled = true;
        }
        else {
            var clickListener_1 = function () {
                if (_this.isActive()) {
                    $scope.$apply(function () {
                        _this.hideAll();
                    });
                }
            };
            $scope.$on('$destroy', function () {
                _this.$document.off('click', clickListener_1);
            });
            this.$document.on('click', clickListener_1);
            this.$element.on('keydown keypress paste', function (event) {
                var activeMenuScope = _this.getActiveMenuScope();
                if (activeMenuScope) {
                    if (event.which === 9 || event.which === 13) {
                        event.preventDefault();
                        activeMenuScope.selectActive();
                    }
                    if (event.which === 27) {
                        event.preventDefault();
                        activeMenuScope.hideMenu();
                    }
                    if (event.which === 40) {
                        event.preventDefault();
                        activeMenuScope.activateNextItem();
                        activeMenuScope.adjustScroll(1);
                    }
                    if (event.which === 38) {
                        event.preventDefault();
                        activeMenuScope.activatePreviousItem();
                        activeMenuScope.adjustScroll(-1);
                    }
                    if (event.which === 37 || event.which === 39) {
                        event.preventDefault();
                    }
                }
                if (event.which === 8 || event.which === 46) {
                    _this.deleteIfTagged(event);
                    _this.hideAll();
                }
            });
        }
    }
    return IscTaggingBaseController;
}());
export { IscTaggingBaseController };
