import { UiModule } from '../../index';
import { IscStimuliController } from './isc-stimuli.controller';
var template = "<div ng-if=\"vm.loaded\">\n    <button ng-click=\"vm.isClickable() && vm.openStimuli($event)\" class=\"stimuli-container\" ng-class=\"{'clickable': vm.isClickable()}\"\n            ng-disabled=\"!vm.isVideoLoaded() || vm.disabled\" in-view=\"vm.inview=vm.inview||$inview\"\n            ng-style=\"{'background-image':vm.backgroundUrl}\" ng-attr-aria-haspopup=\"{{vm.isClickable()}}\"\n            isc-translate=\"{{vm.getAriaLabel()}}\">\n        <span class=\"video-overlay\" ng-if=\"!vm.isVideoLoaded()\">\n          <ng-include ng-if=\"vm.isImage()\" ng-attr-src=\"'/images/isc-camera-icon.svg'\"></ng-include>\n          <ng-include ng-if=\"!vm.isImage()\" ng-attr-src=\"'/images/isc-video-icon.svg'\"></ng-include>\n            <md-progress-circular class=\"white\" value=\"{{vm.uploadProgress}}\"></md-progress-circular>\n        </span>\n        <span class=\"video-overlay\" ng-if=\"vm.isVideoFormat && vm.isVideoLoaded()\" ng-include=\"'/images/ico-play.svg'\">\n        </span>\n        <div class=\"label-red\" ng-if=\"vm.label === 'LabelCardUpdate'\">\n            <span isc-translate=\"(LabelCardUpdate)\"></span>\n        </div>\n        <div class=\"label-red\" ng-if=\"vm.label === 'LabelCardNew'\">\n          <span isc-translate=\"(LabelCardNew)\"></span>\n        </div>\n        <div class=\"label-preparing\" ng-if=\"vm.showPreparingForPlayback()\">\n          <span isc-translate=\"(LabelPreparingForPlayback)\"></span>\n        </div>\n    </button>\n</div>";
UiModule.component('iscStimuli', {
    template: template,
    controllerAs: 'vm',
    controller: IscStimuliController,
    bindings: {
        stimuli: '<',
        label: '<',
        disabled: '<',
        allowDownload: '<?',
        disableLazyLoad: '<?',
        clickable: '<?',
        uploadProgress: '<?',
    },
});
