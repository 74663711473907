export class ActivitySequenceDataService {
    constructor(httpService) {
        this.httpService = httpService;
    }
    isSequencedActivityUnlocked(activityGuid) {
        return this.httpService.get({
            url: '/ActivityService/IsSequencedActivityUnlocked',
            params: {
                activityGuid,
            },
        }).then((response) => response.data);
    }
    isSequencedActivitySample(sampleGuid) {
        return this.httpService.get({
            url: '/ActivityService/IsSequencedActivitySample',
            params: {
                sampleGuid,
            },
        }).then((response) => response.data);
    }
    getNextSequencedActivityRedirectInfo(request) {
        return this.httpService.get({
            url: '/ActivityService/GetNextSequencedActivityRedirectInfo',
            params: {
                finishedActivityGuid: request.finishedActivityGuid,
                finishedSampleGuid: request.finishedSampleGuid,
            },
        }).then((response) => response.data);
    }
    getFirstIncompleteActivityGuidInSequence(activityGuid, sampleGuid) {
        return this.httpService.get({
            url: '/ActivityService/GetFirstIncompleteActivityGuidInSequence',
            params: {
                activityGuid,
                sampleGuid,
            },
        }).then((response) => response.data);
    }
}
ActivitySequenceDataService.$inject = ['httpService'];
