import MainContainer from '@/components/main/main-container.vue';
import { RouteRecordRaw } from 'vue-router';
import { challengesRoutes } from './main/challenges.route';
import { footerpageRoute } from './main/footerpage.route';
import { forumRoute } from './main/forum.route';
import { gotoNotificationRoute } from './main/goto-notification.route';
import { helpcenterRoute } from './main/helpcenter.route';
import { homeRoute } from './main/home.route';
import { incentiveLandingpageFormRoute } from './main/incentive-landingpage-form.route';
import { incentivesRoute } from './main/incentives.route';
import { mainpageCustomRoute } from './main/mainpage-custom.route';
import { moreRoute } from './main/more.route';
import { newsRoute } from './main/news.route';
import { profileRoute } from './main/profile.route';
import { publicProfileRoute } from './main/public-profile.route';
import { RouteNames } from './route-names.enum';

export const mainRoute: RouteRecordRaw = {
  name: RouteNames.Main,
  path: '',
  component: MainContainer,
  meta: {
    title: 'Login',
    requireLogin: false,
  },
  redirect: () => ({ name: RouteNames.Home }),
  children: [
    homeRoute,
    challengesRoutes,
    footerpageRoute,
    forumRoute,
    gotoNotificationRoute,
    helpcenterRoute,
    incentiveLandingpageFormRoute,
    incentivesRoute,
    mainpageCustomRoute,
    moreRoute,
    newsRoute,
    profileRoute,
    publicProfileRoute,
  ],
};
