import { __extends } from "tslib";
import { IscTaggingBaseUtil } from '../common-tagging/tagging-base.service';
var IscHashtagUtil = (function (_super) {
    __extends(IscHashtagUtil, _super);
    function IscHashtagUtil($window, $location, $anchorScroll, $timeout) {
        return _super.call(this, $window, $location, $anchorScroll, $timeout) || this;
    }
    IscHashtagUtil.prototype.tagCssClassName = function () {
        return 'hashtag';
    };
    IscHashtagUtil.$inject = ['$window', '$location', '$anchorScroll', '$timeout'];
    return IscHashtagUtil;
}(IscTaggingBaseUtil));
export { IscHashtagUtil };
