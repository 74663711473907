import { __awaiter, __generator } from "tslib";
import { IscUIUtils } from '../../utils/sharedutils';
var ProbeQuestionEditorController = (function () {
    function ProbeQuestionEditorController(serverConstants, $scope, iscLabelService) {
        this.serverConstants = serverConstants;
        this.$scope = $scope;
        this.iscLabelService = iscLabelService;
    }
    ProbeQuestionEditorController.prototype.$onInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var visualFocusedConversationUploadConfig, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.questionTypeConstants = this.serverConstants.conversationQuestionTypeConstants;
                        this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);
                        this.conversationStimuliTypes = this.serverConstants.conversationStimuliTypeConstants;
                        this.questionTypeText = this.model.questionType === this.questionTypeConstants.text;
                        this.questionTypeImage = this.model.questionType === this.questionTypeConstants.image;
                        this.questionTypeVideo = this.model.questionType === this.questionTypeConstants.video;
                        this.questionTypeAttachment = this.model.questionType === this.questionTypeConstants.attachment;
                        this.questionTypeImageAndVideo = this.model.questionType === this.questionTypeConstants.imageAndVideo;
                        this.questionTypeFreestyle = this.model.questionType === this.questionTypeConstants.freestyle;
                        this.isTextOrAttachmentQuestionType = this.questionTypeText || this.questionTypeAttachment;
                        this.isVisualFocusedConversation = this.isActivityVisualFocusedConversation(this.conversationFocusType) ||
                            this.isScoutDiscussionType;
                        visualFocusedConversationUploadConfig = {
                            showImageUpload: this.questionTypeImage || this.questionTypeImageAndVideo || this.questionTypeFreestyle,
                            showVideoUpload: this.questionTypeVideo || this.questionTypeImageAndVideo || this.questionTypeFreestyle,
                            showVideoLink: this.questionTypeVideo || this.questionTypeImageAndVideo || this.questionTypeFreestyle,
                            showAttachment: this.questionTypeFreestyle,
                            maxStimuli: this.maxStimuli,
                            maxAttachments: this.maxAttachments,
                        };
                        if (this.isScoutDiscussionType) {
                            this.visualFocusedConversationConfig = {
                                isColumnLayout: true,
                                showTextEditor: this.questionTypeFreestyle,
                                messagePlaceholder: this.placeholder,
                                textEditorButtons: this.textEditorButtons(),
                                uploadConfig: visualFocusedConversationUploadConfig,
                            };
                        }
                        else if (this.isActivityVisualFocusedConversation(this.conversationFocusType)) {
                            this.visualFocusedConversationConfig = {
                                isColumnLayout: false,
                                showTextEditor: this.questionTypeFreestyle,
                                uploadConfig: visualFocusedConversationUploadConfig,
                                messagePlaceholder: this.placeholder,
                                textEditorButtons: this.textEditorButtons(),
                            };
                        }
                        this.conversationEditorConfig = {
                            isQuestion: true,
                            showTextEditor: this.questionTypeText || this.questionTypeFreestyle,
                            showCaptionEditor: this.questionTypeImage || this.questionTypeVideo || this.questionTypeImageAndVideo || this.questionTypeFreestyle,
                            textEditorButtons: this.textEditorButtons(),
                            actionButtonsHidden: true,
                            showTitle: false,
                            messagePlaceholder: this.placeholder,
                            isDisabled: this.isDisabled,
                            showDisabledAsGreyedOut: this.showDisabledAsGreyedOut,
                            uploadConfig: {
                                showImageUpload: this.questionTypeImage || this.questionTypeImageAndVideo || this.questionTypeFreestyle,
                                showVideoUpload: this.questionTypeVideo || this.questionTypeImageAndVideo || this.questionTypeFreestyle,
                                showVideoLink: this.questionTypeVideo || this.questionTypeImageAndVideo || this.questionTypeFreestyle,
                                showAttachment: this.questionTypeAttachment || this.questionTypeFreestyle,
                                maxStimuli: this.maxStimuli,
                                maxAttachments: this.maxAttachments,
                                isColumnLayout: this.isScoutDiscussionType,
                            },
                        };
                        this.$scope.$watch(function () { return _this.isDisabled; }, function (newValue) {
                            _this.conversationEditorConfig.isDisabled = newValue;
                        });
                        _a = this;
                        return [4, this.iscLabelService.getLabelValue('AriaLabelDownloadAttachment')];
                    case 1:
                        _a.downloadAriaLabel = _b.sent();
                        return [2];
                }
            });
        });
    };
    Object.defineProperty(ProbeQuestionEditorController.prototype, "draftIdentifier", {
        get: function () {
            return this.draftUid ? this.draftUid() : undefined;
        },
        enumerable: false,
        configurable: true
    });
    ProbeQuestionEditorController.prototype.isNullOrEmpty = function (checkedString) {
        return checkedString === null || checkedString === undefined || checkedString === '';
    };
    ProbeQuestionEditorController.prototype.textEditorButtons = function () {
        if (this.isScoutDiscussionType && !this.questionTypeText && !this.questionTypeFreestyle) {
            return 'none';
        }
        return 'emoji, bold, italic, underline, list1, list2, customlink, outdent, indent, removeFormat';
    };
    ProbeQuestionEditorController.prototype.isActivityVisualFocusedConversation = function (conversationFocusType) {
        return conversationFocusType !== null &&
            conversationFocusType !== undefined &&
            conversationFocusType === this.serverConstants.conversationFocusTypeConstants.visual;
    };
    ProbeQuestionEditorController.prototype.iscMentionSearchFn = function (term) {
        if (this.iscMentionSearch) {
            return this.iscMentionSearch({ term: term });
        }
        return null;
    };
    ProbeQuestionEditorController.prototype.iscMentionSelectFn = function (item) {
        if (this.iscMentionSelect) {
            return this.iscMentionSelect({ item: item });
        }
        return null;
    };
    ProbeQuestionEditorController.prototype.iscHashtagSearchFn = function (term) {
        if (this.iscHashtagSearch) {
            return this.iscHashtagSearch({ term: term });
        }
        return null;
    };
    ProbeQuestionEditorController.prototype.iscHashtagSelectFn = function (item) {
        if (this.iscHashtagSelect) {
            return this.iscHashtagSelect({ item: item });
        }
        return null;
    };
    ProbeQuestionEditorController.prototype.isStimuliDownloadSupported = function (stimulus) {
        return !stimulus.IsBroken &&
            (stimulus.type === this.conversationStimuliTypes.image ||
                stimulus.type === this.conversationStimuliTypes.video);
    };
    ProbeQuestionEditorController.prototype.getUploadProgress = function (stimulus) {
        if (this.uploadProgress) {
            return this.uploadProgress({ stimulus: stimulus });
        }
        return null;
    };
    ProbeQuestionEditorController.prototype.getStimulusThumbnail = function (stimulus) {
        return this.stimulusThumbnail && stimulus
            ? this.stimulusThumbnail({ stimulus: stimulus })
            : null;
    };
    ProbeQuestionEditorController.prototype.getUploadAgain = function (stimulus) {
        if (this.uploadAgain) {
            return this.uploadAgain({ stimulus: stimulus });
        }
    };
    ProbeQuestionEditorController.prototype.isVisualFocusedConversationVisible = function () {
        var visualFocusedConversationVisible = this.isScoutDiscussionType;
        visualFocusedConversationVisible = visualFocusedConversationVisible && (!(this.isTopic || this.isIndividualUpdatePost) || this.replyContentWhileSavingDisabled);
        visualFocusedConversationVisible = visualFocusedConversationVisible && this.isReadonly;
        return visualFocusedConversationVisible;
    };
    ProbeQuestionEditorController.prototype.isConversationEditorVisible = function () {
        var conversationEditorVisible = (this.isTopic || this.replyContentWhileSavingDisabled) || (!this.hideAnswer && !this.isDisabled);
        conversationEditorVisible = conversationEditorVisible && (!this.isScoutDiscussionType || this.isTextOrAttachmentQuestionType);
        return conversationEditorVisible;
    };
    ProbeQuestionEditorController.prototype.getAriaDownloadLabel = function (name) {
        return this.downloadAriaLabel ? this.downloadAriaLabel.replace('{filename}', name) : name;
    };
    ProbeQuestionEditorController.$inject = [
        'serverConstants',
        '$scope',
        'iscLabelService',
    ];
    return ProbeQuestionEditorController;
}());
export { ProbeQuestionEditorController };
