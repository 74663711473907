import './confirmit-embed-element-updater.service';
import './confirmit-embed-events.service';
import './confirmit-embed.component';
import * as angular from 'angular';
import { ConfirmitEmbedComponent } from './confirmit-embed.component';
import { ConfirmitEmbedCustomElementService } from './confirmit-embed-element-updater.service';
import { ConfirmitEmbedEventsService } from './confirmit-embed-events.service';
angular
    .module('insitesApp.profile')
    .component('confirmitEmbed', ConfirmitEmbedComponent)
    .service('confirmitEmbedCustomElementService', ConfirmitEmbedCustomElementService)
    .service('confirmitEmbedEventsService', ConfirmitEmbedEventsService);
