import { ChallengesListController } from './challenges-list.controller';
const template = "<div layout=\"column\">\n  <challenges-group ng-repeat=\"group in vm.groups\" card-group=\"::group\" wrap-cards=\"!group.themeName || vm.themeCount <= 1\">\n  </challenges-group>\n</div>";
export const ChallengesListComponent = {
    controller: ChallengesListController,
    template,
    controllerAs: 'vm',
    bindings: {
        type: '@',
    },
};
