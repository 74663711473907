export class TourController {
    constructor($state, mediaservice, mobiletourService) {
        this.$state = $state;
        this.mediaservice = mediaservice;
        this.mobiletourService = mobiletourService;
    }
    startTour() {
        if (!this.mediaservice.isDesktop()) {
            this.mobiletourService.showMobileTourDialog();
        }
        else {
            this.$state.go('container.main.home', { startTour: true });
        }
    }
}
TourController.$inject = ['$state', 'mediaservice', 'mobiletourService'];
