import { RouteRecordRaw } from 'vue-router';
import { useAuthenticationStore } from '@/store/authentication-store';
import { RouteNames } from './route-names.enum';
import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';

export const logoutRoute: RouteRecordRaw = {
  name: RouteNames.Logout,
  path: '/logout',
  component: getWrappedAngularComponent('logoutComponent'),
  meta: {
    title: 'Logout',
    requireLogin: false,
  },
  beforeEnter: async () => {
    await useAuthenticationStore().signout(false);
  },
};
