import { __awaiter } from "tslib";
import { MediaService } from '../../core/services/media.service';
export class ForceMobileAppDispatcher {
    constructor($transitions, forceMobileAppService, mediaservice, $location) {
        const allowedPagesOnMobileWebApp = ['container.unsubscribe', 'container.reactivate'];
        $transitions.onBefore({}, (transition) => __awaiter(this, void 0, void 0, function* () {
            const targetState = transition.to().$$state();
            if (targetState.name === 'container.forceMobileAppPage' ||
                ForceMobileAppDispatcher.isAlreadyCheckedInTheCurrentUserSession ||
                allowedPagesOnMobileWebApp.indexOf(targetState.name) !== -1 ||
                mediaservice.isMobileApp || $location.search()[MediaService.MobileAppParam]) {
                return true;
            }
            ForceMobileAppDispatcher.isAlreadyCheckedInTheCurrentUserSession = true;
            const userCanContinue = yield forceMobileAppService.forceMobileAppServiceFlow();
            ForceMobileAppDispatcher.isAlreadyCheckedInTheCurrentUserSession = userCanContinue;
            return userCanContinue;
        }));
    }
}
ForceMobileAppDispatcher.$inject = ['$transitions', 'forceMobileAppService', 'mediaservice', '$location'];
ForceMobileAppDispatcher.isAlreadyCheckedInTheCurrentUserSession = false;
