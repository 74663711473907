import { UiModule } from './../../index';
var IscTranslateService = (function () {
    function IscTranslateService(iscLabelService, $interpolate) {
        this.iscLabelService = iscLabelService;
        this.$interpolate = $interpolate;
    }
    IscTranslateService.prototype.replaceLabels = function (text, labelArgs) {
        var _this = this;
        if (labelArgs === void 0) { labelArgs = {}; }
        return this.iscLabelService.getLabels().then(function (labels) {
            return text.replace(IscTranslateService.replaceLabelsPattern, function (match, labelName) {
                if (labels.hasOwnProperty(labelName)) {
                    return _this.replaceArguments(labels[labelName], labelArgs);
                }
                return match;
            });
        });
    };
    IscTranslateService.prototype.replaceArguments = function (label, labelArgs) {
        var _this = this;
        if (labelArgs === void 0) { labelArgs = {}; }
        return label.replace(IscTranslateService.replaceArgumentsPattern, function (match, expression) { return _this.$interpolate("{{".concat(expression, "}}"))(labelArgs); });
    };
    IscTranslateService.$inject = ['iscLabelService', '$interpolate'];
    IscTranslateService.replaceLabelsPattern = /\((\w+)\)/g;
    IscTranslateService.replaceArgumentsPattern = /\{([^\{\}]+)\}/g;
    return IscTranslateService;
}());
export { IscTranslateService };
UiModule.service('iscTranslate', IscTranslateService);
