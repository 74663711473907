import * as _ from 'lodash';
export class FaqController {
    constructor($stateParams, pageService, $state, iscPages, $sce) {
        this.$stateParams = $stateParams;
        this.pageService = pageService;
        this.$state = $state;
        this.iscPages = iscPages;
        this.$sce = $sce;
        this.categories = [];
    }
    $onInit() {
        const pageGuid = this.$stateParams.helpcenterPage;
        this.pageService.getFaqPageDetails(pageGuid).then((result) => {
            const categories = result.data.Categories;
            const questions = result.data.Questions;
            _.forEach(questions, (question) => question.Answer = this.$sce.trustAsHtml(this.replaceAnswerParameters(question.Answer)));
            const categoriesWithQuestions = categories
                .filter((category) => category.IsVisible === true).map((category) => ({
                Name: category.Name,
                IsExpanded: this.$stateParams.incentives && category.IsLinkedToIncentives,
                Questions: _.filter(questions, {
                    IsVisible: true,
                    FaqCategoryGuid: category.Guid,
                }),
            }));
            this.categories = categoriesWithQuestions;
        });
    }
    replaceAnswerParameters(answer) {
        const aboutThisProjectPage = _.find(this.iscPages.mainPages, (item) => item.IsAboutThisProjectPage);
        const aboutThisProjectUrl = aboutThisProjectPage ? this.$state.href('container.main.news', { pageGuid: aboutThisProjectPage.Guid }, { absolute: true }) : '/';
        const myRewardsUrl = this.$state.href('container.main.incentives.myincentive', {}, { absolute: true });
        answer = answer.replaceAll('{aboutthisprojectpage}', aboutThisProjectUrl);
        answer = answer.replaceAll('{myrewardspage}', myRewardsUrl);
        return answer;
    }
}
FaqController.$inject = ['$stateParams', 'pageService', '$state', 'iscPages', '$sce'];
