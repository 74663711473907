import { __awaiter, __generator } from "tslib";
var NwAddImageController = (function () {
    function NwAddImageController($element, iscDomhelperservice, $timeout, iscStimuliService) {
        var _this = this;
        this.$element = $element;
        this.iscDomhelperservice = iscDomhelperservice;
        this.$timeout = $timeout;
        this.iscStimuliService = iscStimuliService;
        this.imageTemplateWithPlaceholderHTML = "<table id=\"imageContainer\" class=\"mcnImageBlock\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\">\n  <tbody class=\"mcnImageBlockOuter\"><tr><td valign=\"top\" class=\"mcnImageBlockInner\">\n  <table align=\"left\" width=\"100%\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" class=\"mcnImageContentContainer\">\n  <tbody> <tr> <td class=\"mcnImageContent\" valign=\"top\"> <img id=\"editorImage\" align=\"center\" width=\"100%\" alt=\"\" src=\"PLACEHOLDER\" class=\"mcnImage\">\n  </td></tr> </tbody></table></td></tr></tbody></table>";
        this.$postLink = function () {
            _this.$timeout(function () {
                var ngWig = _this.iscDomhelperservice.findAncestor(_this.$element[0], 'ng-wig');
                _this.ngWigEditor = ngWig.querySelector('#ng-wig-editable');
            });
        };
    }
    NwAddImageController.prototype.addImage = function (event) {
        var _this = this;
        var input = document.createElement('input');
        input.type = 'file';
        input.accept = '.gif,.jpg,.jpeg,.png';
        input.addEventListener('change', function (e) { return __awaiter(_this, void 0, void 0, function () {
            var imageUrl, imageInHtml;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.iscStimuliService.uploadEditorImage(input.files[0])];
                    case 1:
                        imageUrl = _a.sent();
                        imageInHtml = this.imageTemplateWithPlaceholderHTML.replace('PLACEHOLDER', imageUrl);
                        this.iscDomhelperservice.insertHtmlInNgWig(imageInHtml, this.ngWigEditor);
                        return [2];
                }
            });
        }); }, false);
        event.preventDefault();
        input.click();
    };
    NwAddImageController.$inject = ['$element', 'iscDomhelperservice', '$timeout', 'iscStimuliService'];
    return NwAddImageController;
}());
export { NwAddImageController };
