import { SquareLandingPagesController } from './landingpage.controller';
const template = "﻿<div class=\"top-bar\" layout=\"row\" layout-align=\"center center\" ng-if=\"!vm.hidePageHeader\">\n  <!-- The id in the image is used for the mobile app, don't remove it -->\n  <img id=\"logoTopBar\" ng-src=\"{{vm.images.logoTopBar}}\" alt=\"square-small-logo\">\n</div>\n<div layout=\"column\" layout-align=\"space-between center\">\n  <md-content class=\"message-box intro\">\n    <div class=\"message-text\">\n      <div isc-translate=\"{{vm.landingTitle}}\"></div>\n      <p isc-translate=\"{{vm.landingMessage}}\"></p>\n    </div>\n  </md-content>\n  <!-- NEW LOGO: The new logo should be included in the div below. Check the history for more details -->\n  <div class=\"isc-logo\"></div>\n</div>\n";
export const SquareLandingPagesComponent = {
    controller: SquareLandingPagesController,
    template,
    controllerAs: 'vm',
    bindings: {
        landingTitle: '<',
        landingMessage: '<',
        redirectState: '<',
        redirectParams: '<',
        timeout: '<',
        isExternalLink: '<',
    },
};
