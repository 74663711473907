import { __awaiter } from "tslib";
import { Tab } from '../challenges/challenges.controller';
import { DateTime } from 'luxon';
import { IscUIUtils } from 'isc-ui';
export class HomeController {
    constructor($scope, $stateParams, tourService, _, $q, cardService, notifications, trackingService, serverConstants, $state, challengesService, newsService, participantFactory, mediaservice) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.tourService = tourService;
        this._ = _;
        this.$q = $q;
        this.cardService = cardService;
        this.notifications = notifications;
        this.trackingService = trackingService;
        this.serverConstants = serverConstants;
        this.$state = $state;
        this.challengesService = challengesService;
        this.newsService = newsService;
        this.participantFactory = participantFactory;
        this.mediaservice = mediaservice;
        this.cards = [];
        this.todoActivitiesSubscription = this._.noop;
        this.timeLineChangedSubscription = this._.noop;
        this.unsubscribeShowVideoThumbnail = this._.noop;
        this.notificationsSubscriptions = this._.noop;
        this.reloadCardsInProgress = false;
        this.onTodoActivities = () => __awaiter(this, void 0, void 0, function* () {
            const response = yield this.cardService.getTimelineCards();
            let differentCards = this.cards.length !== response.List.length && this.cards.length > 0;
            if (!differentCards) {
                for (let index = 0; index < this.cards.length; index++) {
                    if (!response.List[index]
                        || this.cards[index].Guid !== response.List[index].Guid
                        || this.cards[index].HasNotification !== response.List[index].HasNotification
                        || (this.cards[index].CardStatus !== response.List[index].CardStatus)) {
                        differentCards = true;
                        break;
                    }
                }
            }
            this.newCardsAssignment(response);
        });
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            window.scrollTo(0, 0);
            this.timeLineChangedSubscription = this.notifications
                .timeLineChanged
                .subscribe(() => __awaiter(this, void 0, void 0, function* () {
                yield this.reloadCards();
            }));
            this.researchActivityCardOnHomePageMessageType = this.serverConstants.messageTypeConstants.qualActivity;
            this.channelTypes = this.serverConstants.channelTypeConstants;
            if (this.$stateParams.startTour) {
                this.tourService.startTour(true);
            }
            if (HomeController.isFirstLogin) {
                HomeController.isFirstLogin = yield this.participantFactory
                    .loadingPromise
                    .then(() => this.participantFactory.isFirstLogin());
            }
            this.handleFirstLogin();
            if (!HomeController.isFirstLogin) {
                if (this.mediaservice.isMobileApp && this.mediaservice.hasAppVersion()) {
                    this.notifications.todoActivities.startWith(0);
                }
                this.todoActivitiesSubscription = this.notifications
                    .todoActivities
                    .subscribe(() => __awaiter(this, void 0, void 0, function* () {
                    yield this.reloadCards();
                }));
            }
            HomeController.isFirstLogin = false;
            yield this.reloadCards();
            this.unsubscribeShowVideoThumbnail = this.notifications.showEngagementCardVideoThumbnail.subscribe((video) => {
                this._.forEach(this.cards, (item) => {
                    if (item.Stimuli.Guid === video.Guid) {
                        item.Stimuli.Url = video.Url;
                        item.Stimuli.ThumbnailUrl = video.ThumbnailUrl;
                        return false;
                    }
                });
            });
        });
    }
    handleFirstLogin() {
        if (HomeController.isFirstLogin) {
            this.notificationsSubscriptions = this.notifications
                .connected
                .subscribe((isConnected) => __awaiter(this, void 0, void 0, function* () {
                if (isConnected) {
                    yield this.reloadCards();
                }
            }));
        }
    }
    $onDestroy() {
        this.notificationsSubscriptions();
        this.cards = [];
        this.todoActivitiesSubscription();
        this.unsubscribeShowVideoThumbnail();
    }
    reloadCards() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.reloadCardsInProgress) {
                try {
                    this.reloadCardsInProgress = true;
                    yield this.onTodoActivities();
                }
                finally {
                    this.reloadCardsInProgress = false;
                }
            }
        });
    }
    newCardsAssignment(response) {
        const mapper = (item) => {
            item.isDiscussionActivity = item.MessageType === this.serverConstants.messageTypeConstants.qualActivity;
            item.DateDiff = DateTime.fromISO(item.StartDateTime).toRelative({ base: DateTime.utc() });
        };
        response.List.forEach((item) => {
            if (angular.isArray(item)) {
                item.forEach((child) => mapper(child));
            }
            else {
                mapper(item);
            }
        });
        this.cards = response.List;
        try {
            this.$scope.$evalAsync();
        }
        catch (_a) {
        }
    }
    loadMore() {
        if (this.cards && this.cards.length > 0 && !this.reloadCardsInProgress) {
            this.reloadCardsInProgress = true;
            return this.cardService
                .getTimelineCards(this.cards.slice(-1)[0].Guid)
                .then((data) => {
                const existingGuids = this._.map(this.cards, (c) => c.Guid);
                for (const newCard of data.List) {
                    if (!this._.find(existingGuids, newCard.Guid)) {
                        this.cards.push(newCard);
                    }
                }
                this.reloadCardsInProgress = false;
                return data;
            });
        }
        return this.$q.when({ HasMoreCards: false, List: [] });
    }
    gotoRoute(card) {
        card.route.params = {
            channel: this.serverConstants.channelTypeConstants.researchActivityChallengeHome,
            sampleGuid: card.CommunicationSampleGuid,
            activityGuid: card.ActivityGuid,
            pageGuid: card.PageGuid,
            selectedIndex: card.CommunicationSampleStatus === this.serverConstants.sampleStatusConstants.completed ? Tab.Completed : Tab.ToDo,
            activityType: card.ActivityType,
        };
        if (card.ActivityType === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey && !this.mediaservice.isMobileApp) {
            const url = this.$state.href(card.route.sref, card.route.params);
            window.open(url, '_blank');
        }
        else if (IscUIUtils.isActivityDiscussionNewType(card.ActivityType, this.serverConstants)) {
            const routeName = 'container.main.challenges.discussionNew';
            this.$state.go(routeName, card.route.params);
        }
        else {
            this.$state.go(card.route.sref, card.route.params);
        }
    }
    onResearchActivityCardOnHomePageCtaClick(card) {
        this.trackingService.researchActivityCardOnHomePageClick(card);
        this.gotoRoute(card);
    }
    onResearchActivityCardOnHomePageTrackViewed(card) {
        this.trackingService.researchActivityCardOnHomePageView(card);
    }
    createCardDateLabelUpdater(card) {
        return this.challengesService.createCardDateLabelUpdater(card);
    }
    onEngagementCardOnHomePageCtaClick(card) {
        this.trackingService.engagementCardOnHomePageClick(card.CommunicationSampleGuid);
    }
    onEngagementCardOnHomePageTrackViewed(card) {
        this.trackingService.engagementCardOnHomePageView(card.CommunicationSampleGuid);
    }
    onNewsCardOnHomePageLikeClick(card) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.newsService.likeClick(card);
        });
    }
    onNewsCardOnHomePageCtaClick(card) {
        this.trackingService.newsCardOnHomePageClick(card.CommunicationSampleGuid);
        this.$state.go('container.main.news.detail', { pageGuid: card.PageGuid, cardGuid: card.Guid });
    }
    onNewsCardOnHomePageTrackViewed(card) {
        this.trackingService.newsCardOnHomePageView(card.CommunicationSampleGuid);
    }
}
HomeController.$inject = ['$scope', '$stateParams', 'tourService', '_', '$q', 'cardService', 'notifications', 'trackingService',
    'serverConstants', '$state', 'challengesService', 'newsService', 'participantFactory', 'mediaservice'];
HomeController.isFirstLogin = true;
