import { __awaiter } from "tslib";
export class ForceMobileAppService {
    constructor($state, featureService) {
        this.$state = $state;
        this.featureService = featureService;
    }
    forceMobileAppServiceFlow() {
        return __awaiter(this, void 0, void 0, function* () {
            const isMobile = this.isSupportedMobileDeviceOS();
            if (!isMobile) {
                return true;
            }
            const isForceMobileAppEnabled = yield this.featureService.isForceMobileAppEnabledForSquare();
            if (isForceMobileAppEnabled) {
                yield this.$state.go('container.forceMobileAppPage', {}, { location: false });
                return false;
            }
            return true;
        });
    }
    isSupportedMobileDeviceOS() {
        return this.isAndroidOS() || this.isIOS();
    }
    isAndroidOS() {
        const userAgent = this.getUserAgent();
        return /android/i.test(userAgent) && !(/windows phone/i.test(userAgent));
    }
    isIOS() {
        const userAgent = this.getUserAgent();
        return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    }
    getUserAgent() {
        return navigator.userAgent || navigator.vendor || window.opera;
    }
}
ForceMobileAppService.$inject = ['$state', 'featureService'];
