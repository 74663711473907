export class IntakeFinishedController {
    constructor(activityService, $state, serverConstants, authService, $window, $stateParams) {
        this.activityService = activityService;
        this.$state = $state;
        this.serverConstants = serverConstants;
        this.authService = authService;
        this.$window = $window;
        this.$stateParams = $stateParams;
    }
    $onInit() {
        this.intakeGuid = this.$stateParams.intakeGuid;
        this.activityService
            .getIntakeCallback(this.intakeGuid)
            .then((callback) => {
            if (callback.SsoToken) {
                this.authService.setToken(callback.SsoToken);
                this.$state.go('container.main.home');
                return;
            }
            if (callback.RedirectUrl) {
                this.$window.location.href = callback.RedirectUrl;
            }
            else {
                const outComeCodeName = this.getEnumValueName(this.serverConstants.surveyOutcomeCodeConstants, callback.OutComeCode);
                const redirectParams = {
                    timeout: -1,
                    landingTitle: `(LabelConfigurationStatus${outComeCodeName}CallbackPixelTitle)`,
                    landingMessage: `(LabelConfigurationStatus${outComeCodeName}CallbackPixelMessage)`,
                };
                this.$state.go('container.landingpage', redirectParams);
            }
        });
    }
    getEnumValueName(enumObject, value) {
        for (const item in enumObject) {
            if (enumObject[item] === value) {
                const label = item[0].toUpperCase() + item.slice(1);
                return label;
            }
        }
        return null;
    }
}
IntakeFinishedController.$inject = ['activityService', '$state', 'serverConstants', 'authService', '$window', '$stateParams'];
