import { InjectionKey, ComputedRef, Ref } from 'vue';
import { FileConfigurationDto } from '@api/models/query';
import { ClientVideoStorage, StimuliUploadState } from '@ui/common/constants/enums';
import { PostChanged } from './components/user-notification-base/user-notification-base.types';

export interface StimulusTranscriptionStatusChanged {
  stimulusGuid: string;
  status: StimuliUploadState;
  url?: string;
  thumbnailUrl?: string;
  discussionGuid: string;
}

export const injectionKeys = {
  square: {
    videoStorageType: Symbol.for('videoStorageType') as InjectionKey<ComputedRef<ClientVideoStorage>>,
  },
  discussions: {
    postGuid: Symbol.for('postGuid') as InjectionKey<string>,
  },
  user: {
    getUserProfile: Symbol.for('getUserProfile') as InjectionKey<(userGuid: string, userRole: number, userName?: string) => string | undefined>,
  },
  configuration: {
    fileConfiguration: Symbol('fileConfiguration') as InjectionKey<Ref<FileConfigurationDto[] | undefined>>,
  },
  notification: {
    discussionNewChange: Symbol('discussionNewChange') as InjectionKey<{
      subscribe: (discussionGuid: string, callback: (message: PostChanged) => void) => Promise<() => Promise<void>>;
    }>,
    stimulusTranscriptionStatusChanged: Symbol('stimulusTranscriptionStatusChanged') as InjectionKey<{
      subscribe: (discussionGuid: string, callback: (message: StimulusTranscriptionStatusChanged) => void) => Promise<() => Promise<void>>;
    }>,
  },
  translation: {
    isTranslationEnabled: Symbol('isTranslationEnabled') as InjectionKey<() => boolean>,
  },
};
