import { NewsDetailController } from './newsdetail.controller';
const template = "<header>\n   <isc-stimuli ng-if=\"vm.card.Stimuli\" stimuli=\"vm.card.Stimuli\"></isc-stimuli>\n</header>\n<section class=\"content\">\n  <h1 md-colors=\"{color: 'primary-500'}\">{{vm.card.Title}}</h1>\n  <div class=\"text\" ng-bind-html=\"vm.card.Message\">\n  </div>\n  <footer layout=\"row\" layout-align=\"space-between center\">\n    <like-button on-like=\"vm.likeClick()\" is-disabled=\"!('Human8 ProfessionalAdmin ClientAdmin ClientEditor Participant'|userRole)\" is-liked=\"vm.card.IsLiked\">\n      <span>{{vm.card.LikesCount}}</span>\n    </like-button>\n    <div class=\"timestamp\" isc-translate=\"(LabelCardPostedAgo)\" isc-translate-ago=\"{{vm.card.DateDiff}}\"></div>\n  </footer>\n</section>\n";
export const NewsDetailComponent = {
    controller: NewsDetailController,
    template,
    controllerAs: 'vm',
    bindings: {
        cardGuid: '@',
    },
};
