import { __awaiter } from "tslib";
export class HelpcenterCustomController {
    constructor($stateParams, pageService, $scope) {
        this.$stateParams = $stateParams;
        this.pageService = pageService;
        this.$scope = $scope;
    }
    $onInit() {
        this.$scope.$watch(() => this.$stateParams.helpcenterPage, () => __awaiter(this, void 0, void 0, function* () {
            this.loadPage();
        }));
    }
    loadPage() {
        this.pageService.getPageDetails(this.$stateParams.helpcenterPage).then((pageDetails) => {
            delete pageDetails.Name;
            this.pageDetails = pageDetails;
        });
    }
}
HelpcenterCustomController.$inject = ['$stateParams', 'pageService', '$scope'];
