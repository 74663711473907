import { __awaiter } from "tslib";
import * as _ from 'lodash';
export class ChallengesController {
    constructor($scope, $stateParams, pageService, challengesService, $state) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.pageService = pageService;
        this.challengesService = challengesService;
        this.$state = $state;
        this.selectedIndex = -1;
        this.tabs = [{
                label: '(LabelChallengesFilterAll)',
                sref: 'container.main.challenges.all',
                index: Tab.All,
            }, {
                label: '(LabelChallengesFilterTodo)',
                sref: 'container.main.challenges.todo',
                index: Tab.ToDo,
            }, {
                label: '(LabelChallengesFilterCompleted)',
                sref: 'container.main.challenges.completed',
                index: Tab.Completed,
            }];
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.pageDetails = yield this.pageService.getPageDetails(this.$stateParams.pageGuid);
            if (this.$state.current.name === 'container.main.challenges.qualactivity') {
                if (this.$stateParams.selectedIndex) {
                    this.selectedIndex = this.$stateParams.selectedIndex;
                }
                else {
                    this.selectedIndex = Tab.All;
                }
            }
            else {
                this.selectedIndex = this.findIndex();
            }
        });
    }
    goToTab(tab) {
        this.$state.go(tab.sref);
    }
    $onDestroy() {
        this.challengesService.destroy();
    }
    get type() {
        switch (this.selectedIndex) {
            case Tab.Completed:
                return 'completed';
            case Tab.ToDo:
                return 'todo';
            case Tab.All:
            default:
                return 'all';
        }
    }
    findIndex() {
        return _.find(this.tabs, (tab) => tab.sref === this.$state.current.name).index;
    }
}
ChallengesController.$inject = ['$scope', '$stateParams', 'pageService', 'challengesService', '$state', '$scope'];
export var Tab;
(function (Tab) {
    Tab[Tab["All"] = 0] = "All";
    Tab[Tab["ToDo"] = 1] = "ToDo";
    Tab[Tab["Completed"] = 2] = "Completed";
})(Tab || (Tab = {}));
