/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import router from '@/router';
import { RouteNames } from '@/router/routes/route-names.enum';

export default class CustomUiRouter {
  get current() {
    return router.currentRoute.value;
  }

  get params() {
    return router.currentRoute.value.params;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  go(to: string, params?: Record<string, any>, options?: { location: boolean | 'replace' }) {
    // This if statement is to replicate the Angular UI router
    if (to === '.') {
      router.replace({ name: router.currentRoute.value.name || undefined, params: router.currentRoute.value.params, query: params }).catch(() => {
        // Catch error message
      });
      return;
    }

    // We don't have this extra route anymore in Vue
    // Since it's still being used in Angular we replace it with the correct one here.
    if (to === 'container.main.news.detail.extra') {
      to = RouteNames.NewsDetail;
    }

    if (options?.location === 'replace') {
      router.replace({ name: to, params });
    } else {
      router.push({ name: to, params });
    }
  }

  // We can use the following line once we can import the types from the ui-router package
  // href(state: IState | string, params?: Record<string, any>, options?: IHrefOptions): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href(state: string, params?: Record<string, any>, options?: { absolute: boolean }): string {
    const route = router.resolve({ name: state, params });

    return options?.absolute ? location.origin + route.fullPath : route.path;
  }

  get() {
    // Get all routes and map them to an object that resembles the Angular syntax.
    return router.getRoutes().map((route) => ({
      $$state: () => ({
        url: {
          exec: (url: string) => {
            // Get the route that matches the url.
            const resolvedRoute = router.resolve(url);
            // Check if the route we're currently on in the loop is the same route as the resolved one.
            // If not, we just return null.
            if (resolvedRoute == null || resolvedRoute.name !== route.name) {
              return null;
            }
            // Return the params and query params from the resolved route.
            return {
              ...resolvedRoute.params,
              ...resolvedRoute.query,
            };
          },
        },
      }),
      name: route.name,
    }));
  }
}
