import { UiModule } from '../../../index';
import { IscModalDialogController } from './isc-modal-dialog.controller';
var template = "<div class=\"close-button-container\" ng-transclude=\"close\">\n</div>\n<div class=\"dialog\">\n  <md-toolbar ng-if=\"vm.hasToolbar\" ng-transclude=\"toolbar\">\n  </md-toolbar>\n  <hr ng-if=\"vm.hasToolbar\"></hr>\n  <md-dialog-content ng-transclude=\"content\">\n  </md-dialog-content>\n  <md-dialog-actions ng-if=\"vm.hasActions\" class=\"modal-actions\" ng-transclude=\"actions\">\n  </md-dialog-actions>\n</div>";
UiModule
    .component('iscModalDialog', {
    template: template,
    controllerAs: 'vm',
    controller: IscModalDialogController,
    transclude: {
        toolbar: '?iscModalToolbar',
        content: '?iscModalContent',
        actions: '?iscModalActions',
        close: '?iscModalClose',
    },
    bindings: {
        closeCallback: '&',
    },
});
