import { Ref } from 'vue';
import { IscFilterSelect, IscSegmentsFilter } from '.';
import { AuthorViewModel, Hashtag, QuestionViewModel, SegmentationForFilteringListItem, Tag  } from '@api/models/query';

export interface FilterData {
  authors: AuthorViewModel[];
  segmentationListItems: SegmentationForFilteringListItem[];
  tags: Tag[];
  hashtags: Hashtag[];
  questions: QuestionViewModel[];
}

export interface FilterOption<T extends number | string> {
  label: string;
  value: T;
}

export enum FilterSelectionOperation {
  Any = 'any' ,
  All = 'all',
}

export interface FilterNoSelection<T extends string | number = string | number> extends FilterOption<T> {
  selected: boolean | null;
  show: boolean;
}

export interface Filter<T extends string | number, U extends FilterType> {
  filterType: U;
  showSearch: boolean;
  selectedChips: FilterChip<T> | null;
  noSelection: FilterNoSelection<T>;
  selected: T extends string ? string[] : T extends number ? number[] : string[] | number[];
  label: string;
  defaultLabel: string;
  ref: Array<(U extends FilterType.Segment ? typeof IscSegmentsFilter : typeof IscFilterSelect)> | null;
}

export interface FilterChipArea<T extends string | number = string | number, U extends FilterType = FilterType>
  extends Omit<Filter<T, U>, 'ref' | 'selected' | 'selectedChips' | 'noSelection' | 'defaultLabel'>{
  selectedChips: Ref<FilterChip<T>> | Ref<null>;
}

export interface FilterEmit {
  searchText?: string;
  startDate: string;
  endDate: string;
  membersSelected: string[];
  segmentsSelected: string[];
  segmentFilterSelectionOperation: FilterSelectionOperation;
  stimuliSelected: number[];
  tagsSelected: string[];
  hashtagsSelected: string[];
  annotationsSelected: number[];
  ratingsSelected: number[];
  moderationsSelected: number[];
  questionsSelected: string[];
}

export enum FilterType {
  Search = 'Search',
  Member = 'member',
  Stimuli = 'stimuli',
  Segment = 'segment',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Tag = 'tag',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Hashtag = 'hashtag',
  Annotation = 'annotation',
  Rating = 'rating',
  Moderation = 'moderation',
  Question = 'question'
}

export interface FilterChip<T extends number | string> {
  filterSelections: Array<FilterOption<T>>;
}

export interface FilterSegmentChip {
  segmentIdentifier: string;
  filterSelections: Array<FilterOption<string>>;
}

export enum StimuliFilterOption {
  AnyStimuli = -1,
  NoStimuli = 0,
  Image = 1,
  Video = 2,
}

export enum AnnotationFilterOption {
  AnyAnnotation = -1,
  NoAnnotation = 0,
  Notes = 1,
  Highlights = 2,
}

export enum ModerationStatusOption {
  AnyModerationStatus = -1,
  Unmoderated = 0,
  ModeratedUpdateRequired = 1,
  ModeratedAccepted = 2,
  ModeratedOther = 3,
}

export enum RatingStatusOption {
  NoRating = 0,
}

export const tagOptionConstants = {
  noTagOptionGuid: '722e9c45-ff56-4c6e-89d5-63e684bbe3a3',
};

export const hashtagOptionConstants = {
  noHashtagOptionGuid: 'efa6dd60-ccc1-4d2e-b9c6-632774ac4588',
};

export const filterConstants = {
  anyAnnotation: 'Any annotation',
  noAnnotation: 'No annotation',
  noRating: 'No Rating',
  notes: 'Notes',
  highlights: 'Highlights',
  anyStimuli: 'Any stimuli',
  noStimuli: 'No stimuli',
  image: 'Image',
  video: 'Video',
  anyModerationStatus: 'Any moderation status',
  unmoderated: 'Unmoderated',
  moderatedUpdateRequired: 'Moderated (update required)',
  moderatedAccepted: 'Moderated (accepted)',
  moderatedOther: 'Moderated (other)',
  noTagOptionText: 'No tag',
  noHashtagOptionText: 'No hashtag',
};
