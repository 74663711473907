<template>
  <div
    v-show="showPlatformNotice"
    id="platform-notice"
    class="platform-notice"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="platformNotice?.customMessage" />
    <q-icon
      class="fa fa-xmark"
      @click="closePlatformNotice"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useParticipantStore } from '@/store/participant-store';
import { getCacheValue, setCache } from '@ui/common/utils/cache';
import { getCurrentPlatformNoticeDisplay } from '@api/services/query/default/PlatformNoticeService';
import { useConstantsStore } from '@/store/constants-store';
import { PlatformNoticeType } from '@/common/constants/enums';
import { DateTime } from 'luxon';

interface PlatformNoticeDisplay {
  noticeType: PlatformNoticeType;
  startNoticeDateTime?: DateTime;
  endNoticeDateTime?: DateTime;
  customMessage?: string;
}

const platformNoticeOption = ref<string | null>();
const platformNotice = ref<PlatformNoticeDisplay>();
const cacheKey = `platformNoticeOption_${useParticipantStore().participant.guid}`;

onMounted(async () => {
  platformNoticeOption.value = getCacheValue(cacheKey);
  platformNotice.value = await getCurrentPlatformNoticeDisplay(true, useConstantsStore().language);
});

const showPlatformNotice = computed(() => platformNotice.value
  && (!platformNoticeOption.value || platformNoticeOption.value !== platformNotice.value.customMessage));

const closePlatformNotice = () => {
  if(platformNotice.value === null){
    return;
  }

  platformNoticeOption.value = platformNotice.value?.customMessage;
  setCache({ key: cacheKey, value: platformNoticeOption.value });
};
</script>

<style lang="scss">
.platform-notice {
  background-color: $accent;
  min-height: 40px;
  display: flex;

  span {
    justify-content: center;
    align-items: center;
    padding: 5px;
    color: $light;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  .fa {
    margin: 5px 0 0;
    color: $light;
    cursor: pointer;
  }
}
</style>
