import { DecipherEmbedController } from './decipher-embed.controller';
const template = "<iframe class=\"decipher-embed\" scrolling=\"no\"></iframe>";
export const DecipherEmbedComponent = {
    controller: DecipherEmbedController,
    template,
    controllerAs: 'vm',
    bindings: {
        surveyUrl: '<',
        onSurveyComplete: '&',
    },
};
