export class ChangeEmailDialogController {
    constructor($state, $mdDialog, serverConstants) {
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.serverConstants = serverConstants;
        this.validationConstants = this.serverConstants.validationConstants;
    }
    redirect() {
        this.$mdDialog.hide();
        this.$state.go('container.main.profile');
    }
}
ChangeEmailDialogController.$inject = ['$state', '$mdDialog', 'serverConstants'];
