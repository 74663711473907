import { PriorityNavigationHelper } from './priorityNavigationHelper';
export class PriorityNavigationController {
    constructor($scope, $timeout, _, mediaservice) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this._ = _;
        this.mediaservice = mediaservice;
        this.breakout = [];
        this.delay = 500;
        this.throttledCheckNav = this._.throttle(() => this.checkNav(), this.delay, {
            leading: true,
            trailing: true,
        });
        this.destroylistPageWatch = $scope.$watch(() => this.listPage, (newval) => {
            if (newval) {
                $timeout(() => {
                    this.initialise();
                });
            }
        }, true);
    }
    initialise() {
        this.priorityContainer = document.querySelector('.js-priority-menu');
        if (this.priorityContainer) {
            this.priorityMenu = this.priorityContainer.querySelector('.priority');
            this.moreButton = this.priorityContainer.querySelector('.js-overflow-more-button');
            this.menuItems = this.priorityMenu.children;
            this.checkNav();
            this.addListeners();
        }
    }
    getPriorityWidth() {
        return Math.max(0, this.priorityMenu.offsetWidth - (this.mediaservice.isDesktop() ? 85 : 2 * 70));
    }
    checkNav() {
        this.$scope.$apply(() => {
            PriorityNavigationHelper.listMainPage = this.listPage;
            PriorityNavigationHelper.listPageOverflow = [];
        });
        this.$timeout(() => {
            const priorityWidth = this.getPriorityWidth();
            let menuItemsWidth = 0;
            let firstNotFitMenuIndex = -1;
            this._.each(this.menuItems, (item, index) => {
                if (firstNotFitMenuIndex > -1) {
                    return;
                }
                menuItemsWidth += item.offsetWidth;
                if (menuItemsWidth > priorityWidth) {
                    firstNotFitMenuIndex = index;
                }
            });
            if (firstNotFitMenuIndex > -1) {
                this.$scope.$apply(() => {
                    PriorityNavigationHelper.listPageOverflow = this._.takeRight(this.listPage, this.listPage.length - firstNotFitMenuIndex);
                    PriorityNavigationHelper.listMainPage = this._.take(this.listPage, firstNotFitMenuIndex);
                });
            }
        });
    }
    addListeners() {
        window.addEventListener('resize', this.throttledCheckNav);
    }
    $onDestroy() {
        this.destroylistPageWatch();
        window.removeEventListener('resize', this.throttledCheckNav);
    }
}
PriorityNavigationController.$inject = ['$scope', '$timeout', '_', 'mediaservice'];
