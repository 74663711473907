import { useTour } from '@/composables/use-tour';

interface TourService {
  startTour: (enabled?: boolean) => void;
  showMobileTourDialog: () => void;
}

const tourService: TourService = {
  startTour: () => useTour().startTour(),
  showMobileTourDialog: () => useTour().startTour(),
};

export default tourService;
