var ReplaceEmojis = (function () {
    function ReplaceEmojis(emojiservice, _) {
        this.emojiservice = emojiservice;
        this._ = _;
    }
    ReplaceEmojis.prototype.processElement = function (element) {
        if (element instanceof HTMLElement) {
            var textNodes = this._.filter(element.childNodes, function (node) {
                return node.nodeType === Node.TEXT_NODE;
            });
            this.replaceEmojisInTextNodes(textNodes, element);
        }
        return element;
    };
    ReplaceEmojis.prototype.replaceEmojisInTextNodes = function (textNodes, parent) {
        var _this = this;
        var uniqueEmojis = this.emojiservice.getUniqueEmojis();
        this._.each(textNodes, function (node) {
            _this._.each(uniqueEmojis, function (emoji) {
                var text = node.textContent;
                var emojiIndex = text.indexOf(emoji.text);
                if (emojiIndex !== -1) {
                    var nextNode = node.splitText(emojiIndex + emoji.text.length);
                    var emojiElement = _this.getEmojiElement(emoji);
                    node.textContent = node.textContent.replace(emoji.text, '');
                    parent.insertBefore(emojiElement, nextNode);
                    _this.replaceEmojisInTextNodes([nextNode], parent);
                }
            });
        });
    };
    ReplaceEmojis.prototype.getEmojiElement = function (emoji) {
        var html = this.emojiservice.getEmojiHtml(emoji);
        var helperDiv = document.createElement('div');
        helperDiv.innerHTML = html;
        return helperDiv.firstChild;
    };
    ReplaceEmojis.$inject = ['emojiservice', '_'];
    return ReplaceEmojis;
}());
export default ReplaceEmojis;
