import { Utils } from '../core/utils';
export class CustomController {
    constructor($window, constantsfactory, serverConstants) {
        this.$window = $window;
        this.constantsfactory = constantsfactory;
        this.serverConstants = serverConstants;
        this.hasCustomUrl = false;
        this.page = null;
    }
    $onChanges(changes) {
        if (changes.page && changes.page.currentValue) {
            this.page = changes.page.currentValue;
            if (this.page.CustomUrl) {
                this.adjustIdeaStreamUrl();
                this.constantsfactory.getLabelValue('LabelCustomPageRedirectText').then((value) => {
                    value = `<span>${value}`;
                    let label = value.replace('{url}', `</span><a class="link" href="${this.page.CustomUrl}" target="_blank">${this.page.CustomUrl}</a><span>`);
                    label = label.replace(/\{(.+)\|(url)\}/g, (match, text) => `</span><a class="link" href="${this.page.CustomUrl}" target="_blank" aria-label="${text}">${text}</a><span>`);
                    label += '</span>';
                    this.customPageRedirextText = label;
                    const tabOption = this.page.OpenInNewTab ? '_blank' : '_self';
                    this.$window.open(this.page.CustomUrl, tabOption);
                    this.hasCustomUrl = true;
                });
            }
            else {
                this.hasCustomUrl = false;
            }
        }
    }
    adjustIdeaStreamUrl() {
        if (this.page.CustomUrl && this.page.CustomUrl.indexOf('ideastreamsso') >= 0) {
            this.page.CustomUrl = Utils.buildUrl(this.page.CustomUrl, {
                sourceGuid: this.page.Guid,
                source: this.serverConstants.ideaStreamProjectTargetConstants.customPage,
            });
        }
    }
}
CustomController.$inject = ['$window', 'constantsfactory', 'serverConstants'];
