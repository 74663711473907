import { DateTime } from 'luxon';

interface Cacheable {
  key: string;
  value: unknown;
  duration?: number;
}

export const getOrSetCache = async <T>(getCallback: () => Promise<T>, cacheKey: string, durationDays: number): Promise<T> => {
  let cacheValue = getCacheValue<T>(cacheKey);

  if (!cacheValue) {
    cacheValue = await getCallback();
    setCache({ key: cacheKey, value: cacheValue, duration: durationDays });
  }

  return cacheValue;
};

export const removeCacheValue = (key: string): void => {
  if (localStorage) {
    localStorage.removeItem(key);
  } else {
    document.cookie = `${key}="";max-age=0`;
  }
};

export const isSet = (key: string): boolean => getCacheValue(key) != null;

export const setCache = (cacheable: Cacheable): void => {
  // Normally we use localStorage to cache things
  // But when the square gets opened from within a Webview in a native Android app,
  // The possibility exists that access to localStorage is disabled.
  // In that case cookies wil be used.

  if (cacheable.value == null) {
    removeCacheValue(cacheable.key);
  } else {
    if (localStorage) {
      const cache = {
        Expiry: cacheable.duration ? DateTime.utc().plus({ days: cacheable.duration }) : undefined,
        Value: cacheable.value,
      };

      localStorage.setItem(cacheable.key, JSON.stringify(cache));
    } else {
      const maxAge = 60 * 60 * 24 * (cacheable.duration ? cacheable.duration : 365);
      document.cookie = `${cacheable.key}=${JSON.stringify(cacheable.value)};max-age=${maxAge};samesite=lax`;
    }
  }
};

export const getCacheValue = <T>(keyName: string): T | null => {
  const cache = getCache<T>(keyName);
  if (!cache) {
    return null;
  }
  if (cache.expiry < DateTime.utc()) {
    removeCacheValue(keyName);
    return null;
  }

  return cache.value;
};

const getCache = <T>(keyName: string): { key: string; value: T; expiry: DateTime } | null =>
  localStorage ? getFromLocalStorage<T>(keyName) : getFromCookie<T>(keyName);

const getFromCookie = <T>(keyName: string): { key: string; value: T; expiry: DateTime } | null => {
  const value = findCookie(keyName);
  if (value) {
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
    } catch {
      // In theory we should never reach this code since everything should happen with the { key, expiry, value } format.
      // The reason we need this is because the old mobile app. There, the token gets sets as just a string instead of the format mentioned above.
      // It would be better if the app would follow the same format.
      parsedValue = value;
    }

    if (parsedValue) {
      return {
        key: keyName,
        expiry: DateTime.utc().plus({ days: 99999 }),
        value: parsedValue,
      };
    }
  }
  return null;
};

const getFromLocalStorage = <T>(keyName: string): { key: string; value: T; expiry: DateTime } | null => {
  const value = localStorage.getItem(keyName);

  if (value) {
    try {
      const json = JSON.parse(value);
      if (json && json.Value) {
        return {
          key: keyName,
          expiry: json.Expiry ? DateTime.fromISO(json.Expiry) : DateTime.utc().plus({ days: 99999 }),
          value: json.Value,
        };
      }
    } catch {
      // In theory we should never reach this code since everything should happen with the { key, expiry, value } format.
      // The reason we need this is because the mobile app. There, the token gets sets as just a string instead of the format mentioned above.
      // It would be better if the app would follow the same format.
      return {
        key: keyName,
        expiry: DateTime.utc().plus({ days: 99999 }),
        value: value as unknown as T,
      };
    }
  }

  return null;
};

const findCookie = (key: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};

export const clearExpiredItems = (): void => {
  // Only necessary for localStorage, cookies will clear themselves
  if (localStorage) {
    const keys = Object.keys(localStorage);

    keys.forEach((key) => {
      const cached = localStorage.getItem(key);
      if (cached) {
        try {
          const cacheValue = JSON.parse(cached);
          if (cacheValue && cacheValue.Expiry && DateTime.utc() > DateTime.fromISO(cacheValue.Expiry)) {
            localStorage.removeItem(key);
          }
        } catch {
          // Nothing
        }
      }
    });
  }
};
