import { __extends } from "tslib";
import { IscTaggingBaseController } from '../common-tagging/tagging-base.controller';
var IscHashtagController = (function (_super) {
    __extends(IscHashtagController, _super);
    function IscHashtagController($scope, $timeout, $attrs, $document, iscHashtagUtil, $element, $parse) {
        return _super.call(this, $scope, $timeout, $attrs, $document, iscHashtagUtil, $element, $parse) || this;
    }
    IscHashtagController.prototype.init = function () {
        this.$scope.hashtag = this;
        this.search = this.$parse(this.$attrs.iscHashtagSearch);
        this.select = this.$parse(this.$attrs.iscHashtagSelect);
    };
    IscHashtagController.$inject = ['$scope', '$timeout', '$attrs', '$document', 'iscHashtagUtil', '$element', '$parse'];
    return IscHashtagController;
}(IscTaggingBaseController));
export { IscHashtagController };
