import { CAPTCHA_METHOD, RECAPTCHA_FALLBACKMODE, RECAPTCHAV3_SITE_ID } from '@/common/env.config';
import { shouldShowCaptcha as participantShouldShowCaptcha } from '@api/services/query/default/ParticipantService';
import { useSquareStore } from '@/store/square-store';
import { ref } from 'vue';
import { shouldShowCaptcha } from '@api/services/query/default/AuthorizationService';
import { isMasterFeatureEnabledForSquare } from '../feature-service';
import { Feature } from '@api/models/query';

const RECAPTCHA_V3 = 'reCaptcha_v3';
const RECAPTCHA_V2 = 'reCaptcha_v2';
let reCaptchaPromise: Promise<void> | undefined;
let reCaptchaResponse: string;
export const showReCaptchaV2 = ref(false);
export const recaptchaVerified = (response: string) => reCaptchaResponse = response;
export const recaptchaLang =  ref('en');

const initReCaptcha = (lang: string) => {
  const reCaptchaDomain = RECAPTCHA_FALLBACKMODE ? 'recaptcha.net' : 'google.com';
  reCaptchaPromise = new Promise((resolve) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.id = 'reCaptchaScript';
    script.type = 'text/javascript';
    script.src = `https://www.${reCaptchaDomain}/recaptcha/api.js?render=${RECAPTCHAV3_SITE_ID}&hl=${lang}`;
    script.onload = () => resolve();
    head.appendChild(script);
  });
};

const loadCaptcha = () => {
  const language = useSquareStore().info.language;
  let lang = language != null ? language : 'en';
  if (lang.startsWith('zh')) {
    lang = 'zh-CN';
  }

  if (CAPTCHA_METHOD === RECAPTCHA_V3) {
    initReCaptcha(lang);
  } else if (CAPTCHA_METHOD === RECAPTCHA_V2) {
    if (!showReCaptchaV2.value) {
      showReCaptchaV2.value = true;
    }
    recaptchaLang.value = lang;
  }
};

const isCaptchaEnabled = async () => await isMasterFeatureEnabledForSquare(Feature.Captcha);

export const loadCaptchaIfNecessary = async (action: string) => {
  if (!await isCaptchaEnabled()) {
    return;
  }
  if (action === 'login') {
    const displayCaptcha = await shouldShowCaptcha();
    if (displayCaptcha.showCaptcha){
      loadCaptcha();
    }
  } else if (action === 'resetPassword') {
    const displayCaptcha = await participantShouldShowCaptcha();
    if(displayCaptcha.showCaptcha){
      loadCaptcha();
    }
  } else {
    loadCaptcha();
  }
};

export const hideCaptcha = async () => {
  if (await isCaptchaEnabled()) {
    if (CAPTCHA_METHOD === RECAPTCHA_V3) {
      const reCaptchaScript = document.getElementById('reCaptchaScript');
      if (reCaptchaScript) {
        reCaptchaScript.remove();
      }
      document.querySelectorAll('.grecaptcha-badge').forEach((el) => {
        el.remove();
      });
    } else {
      showReCaptchaV2.value = false;
    }
  }
};

export const getCaptchaResponse = async (act: string): Promise<string> => {
  if (!await isCaptchaEnabled()) {
    return '';
  }
  if (CAPTCHA_METHOD === RECAPTCHA_V2) {
    return reCaptchaResponse;
  }
  await reCaptchaPromise;
  if (reCaptchaPromise !== undefined) {
    return new Promise((resolve) => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (window as any).grecaptcha.ready(() => {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (window as any).grecaptcha
          .execute(RECAPTCHAV3_SITE_ID, { action: act })
          .then((token: string) => {
            resolve(token);
          });
      });
    });
  }
  return '';
};
