import { Cacheable } from './cacheable';
export class PlatformNoticeOption extends Cacheable {
    constructor() {
        super(...arguments);
        this.key = PlatformNoticeOption.KEY;
        this.duration = 365;
        this.value = null;
    }
}
PlatformNoticeOption.KEY = 'platformNoticeOption';
