import { StimuliPreviewDisplayType } from './../stimuli-preview/isc-stimuli-preview.model';
var IscVisualFocusedConversationController = (function () {
    function IscVisualFocusedConversationController(_, iscConfigurationService, iscLabelService, serverConstants) {
        this._ = _;
        this.iscConfigurationService = iscConfigurationService;
        this.iscLabelService = iscLabelService;
        this.serverConstants = serverConstants;
    }
    IscVisualFocusedConversationController.prototype.$onInit = function () {
        this.config.uploadConfig.isColumnLayout = this.config.isColumnLayout;
        this.config.isQuestion = this.isQuestion;
        var stimuliPreviewDisabled = !this.isEditMode || this.replyActive;
        if (this.config.isColumnLayout) {
            this.stimuliPreviewConfig = {
                displayType: StimuliPreviewDisplayType.carousel,
                isDisabled: stimuliPreviewDisabled,
            };
        }
        else {
            this.stimuliPreviewConfig = {
                displayType: StimuliPreviewDisplayType.list,
                isDisabled: stimuliPreviewDisabled,
            };
        }
    };
    IscVisualFocusedConversationController.prototype.isNullOrEmpty = function (checkedString) {
        return checkedString === null || checkedString === undefined || checkedString === '';
    };
    IscVisualFocusedConversationController.prototype.fileAddedHandler = function (data) {
        if (data && data.uploadedItems) {
            this.autosave();
        }
    };
    IscVisualFocusedConversationController.prototype.fileRemovedHandler = function (data) {
        if (data && data.removedItem) {
            this.autosave();
        }
    };
    IscVisualFocusedConversationController.prototype.iscMentionSearchFn = function (term) {
        if (this.iscMentionSearch) {
            return this.iscMentionSearch({ term: term });
        }
        return null;
    };
    IscVisualFocusedConversationController.prototype.iscMentionSelectFn = function (item) {
        if (this.iscMentionSelect) {
            return this.iscMentionSelect({ item: item });
        }
        return null;
    };
    IscVisualFocusedConversationController.prototype.iscHashtagSearchFn = function (term) {
        return this.iscHashtagSearch({ term: term });
    };
    IscVisualFocusedConversationController.prototype.iscHashtagSelectFn = function (item) {
        return this.iscHashtagSelect({ item: item });
    };
    IscVisualFocusedConversationController.prototype.validPost = function () {
        return (this.stimuli && this.stimuli.length > 0) ||
            (this.attachments && this.attachments.length > 0);
    };
    IscVisualFocusedConversationController.prototype.hasStimuli = function () {
        var _this = this;
        var stimli = this._.filter(this.stimuli, function (stimulus) {
            return stimulus.status !== _this.serverConstants.conversationStimuliStatusConstants.removed;
        });
        if (stimli.length > 0) {
            return true;
        }
        this.caption = null;
        return false;
    };
    IscVisualFocusedConversationController.prototype.getUploadProgress = function (stimulus) {
        if (this.uploadProgress) {
            return this.uploadProgress({ stimulus: stimulus });
        }
        return null;
    };
    IscVisualFocusedConversationController.prototype.getUploadAgain = function (stimulus) {
        if (this.uploadAgain) {
            return this.uploadAgain({ stimulus: stimulus });
        }
    };
    IscVisualFocusedConversationController.prototype.getStimulusThumbnail = function (stimulus) {
        return this.stimulusThumbnail && stimulus
            ? this.stimulusThumbnail({ stimulus: stimulus })
            : null;
    };
    IscVisualFocusedConversationController.prototype.isStimuliAndAttachmentSectionVisible = function () {
        return (this.stimuli && this.stimuli.length)
            || (this.attachments && this.attachments.length);
    };
    IscVisualFocusedConversationController.$inject = ['_', 'iscConfigurationService', 'iscLabelService', 'serverConstants'];
    return IscVisualFocusedConversationController;
}());
export { IscVisualFocusedConversationController };
