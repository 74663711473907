export class SpecialCharactersValidator {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    link(scope, element, attrs, ngModel) {
        ngModel.$validators.specialCharacters = (modelValue) => {
            const pattern = new RegExp(/[~`@!#$%\^*+=\[\]\\;/{}|\\":<>\?]/);
            return !pattern.test(modelValue);
        };
    }
}
