export class ProjectService {
    constructor(httpService, __env, squareFactory) {
        this.httpService = httpService;
        this.__env = __env;
        this.squareFactory = squareFactory;
    }
    getListLabel(language) {
        return this.httpService.get({
            url: '/ProjectService/ListLabel',
            params: {
                language,
            },
        }).then((response) => {
            const labelsDict = {};
            for (const label of response.data.List) {
                labelsDict[label.Name] = label.Value;
            }
            return labelsDict;
        });
    }
    getSquareInfo() {
        return this.httpService.get({
            url: '/ProjectService/SquareInfo',
        });
    }
    getTermsAndConditionsUrl() {
        return this.httpService.get({
            url: '/ProjectService/GetTermsAndConditionsUrl',
        }).then((response) => response.data);
    }
    getSquareAdminUrl() {
        return `${this.__env.insitesUrl}client/${this.squareFactory.ClientGuid}/program/${this.squareFactory.ProgramGuid}/square/${this.squareFactory.Guid}`;
    }
}
ProjectService.$inject = ['httpService', '__env', 'squareFactory'];
