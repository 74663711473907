export class ConfirmitEmbedEventsService {
    constructor($timeout) {
        this.$timeout = $timeout;
    }
    checkForComplete(win, completeCallback) {
        const inlinecomplete = win.document.querySelector('#inlinecomplete');
        if (inlinecomplete) {
            this.$timeout(completeCallback, 0);
        }
    }
    ensureScrollTopOnBackOrForwardButtonClicks(win) {
        const backbutton = win.document.getElementById('backbutton');
        if (backbutton) {
            this.scrollTopOnClickOn(backbutton, win);
        }
        const forwardButton = win.document.getElementById('forwardbutton');
        if (forwardButton) {
            this.scrollTopOnClickOn(forwardButton, win);
        }
    }
    scrollTopOnClickOn(element, iframe) {
        if (!element.hasAttribute('click-event-attached')) {
            element.setAttribute('click-event-attached', 'true');
            element.addEventListener('click', () => {
                window.scrollTo(0, 0);
                iframe.scrollTo(0, 0);
            });
        }
    }
}
ConfirmitEmbedEventsService.$inject = ['$timeout'];
