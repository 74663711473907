import { IscMentionMenuItemController } from './mention-menu-item.controller';
export var IscMentionMenuItemComponent = {
    template: "\n    <li ng-class=\"{'active': vm.active()}\" ng-mouseenter=\"vm.mouseEnter()\" ng-click=\"vm.click()\">\n      <div ng-bind-html=\"vm.item.label | iscMentionHighlight:vm.typedTerm:'menu-highlighted'\"></div>\n    </li>\n  ",
    controllerAs: 'vm',
    controller: IscMentionMenuItemController,
    bindings: {
        item: '<',
        isActive: '&',
        typedTerm: '<',
        activate: '&',
        selectItem: '&',
    },
    require: {
        iscMentionMenu: '^iscMentionMenu',
    },
};
