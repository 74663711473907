var AddWebcamMediaDialogController = (function () {
    function AddWebcamMediaDialogController($mdDialog) {
        this.$mdDialog = $mdDialog;
    }
    AddWebcamMediaDialogController.prototype.cancel = function () {
        this.$mdDialog.cancel();
    };
    AddWebcamMediaDialogController.prototype.submitCapture = function () {
        this.$mdDialog.hide(this.videoData);
    };
    AddWebcamMediaDialogController.$inject = ['$mdDialog'];
    return AddWebcamMediaDialogController;
}());
export { AddWebcamMediaDialogController };
