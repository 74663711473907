import { createApp } from 'vue';
import router from '@/router';
import { createPinia } from 'pinia';
import App from './App.vue';

import './angular';
import { Quasar } from 'quasar';
import quasarUserOptions from './quasar-user-options';

import vueSquareUi from '@ui/index';
import iscLabels from './isc-labels';

import '../../../FontAwesome/css/all.css';

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(createPinia())
  .use(router)
  .use(vueSquareUi, iscLabels)
  .mount('#app');
