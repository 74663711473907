var IscStimulusItemPreviewController = (function () {
    function IscStimulusItemPreviewController(iscSocialStimuli, $q, iscStimuliTypeService, _, iscStimuliDialogService, $scope) {
        this.iscSocialStimuli = iscSocialStimuli;
        this.$q = $q;
        this.iscStimuliTypeService = iscStimuliTypeService;
        this._ = _;
        this.iscStimuliDialogService = iscStimuliDialogService;
        this.$scope = $scope;
        this.inview = false;
        this.backgroundUrl = '';
        this.cursor = 'default';
        this.loaded = false;
        this.url = '/images/bg-video.png';
    }
    IscStimulusItemPreviewController.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watch('vm.stimulus.thumbnailUrl', function () {
            if (_this.stimulus.thumbnailUrl !== 'images/bg-video.png' && _this.stimulus.thumbnailUrl) {
                _this.newThumb = _this.stimulus.thumbnailUrl;
                _this.backgroundUrl = _this.createBackgroundUrl();
            }
            else {
                _this.setThumbnail();
            }
        });
        this.$scope.$watch('vm.inview', function () {
            _this.backgroundUrl = _this.createBackgroundUrl();
        });
        this.conversationStimuliType = this.iscStimuliTypeService.getTypes();
        this.stringStimuliType = this._.invert(this.conversationStimuliType);
        this.isVideoFormat = this.isVideo();
        this.setThumbnail();
        this.backgroundUrl = this.createBackgroundUrl();
    };
    IscStimulusItemPreviewController.prototype.setThumbnail = function () {
        var _this = this;
        if (this.stimulus.type === this.conversationStimuliType.youtube || this.stimulus.type === this.conversationStimuliType.vimeo) {
            this.iscSocialStimuli.getThumbnail(this.stringStimuliType[this.stimulus.type], this.stimulus.value).then(function (thumbnail) {
                _this.stimulus.thumbnailUrl = thumbnail;
                _this.loaded = true;
            });
        }
        else {
            this.loaded = true;
        }
    };
    IscStimulusItemPreviewController.prototype.isVideoLoaded = function () {
        return (this.newThumb && this.loaded && this.stimulus.thumbnailUrl !== 'images/bg-video.png');
    };
    IscStimulusItemPreviewController.prototype.isClickable = function () {
        return this.isVideo()
            ? this.stimulus.thumbnailUrl !== 'images/bg-video.png'
            : this.stimulus.thumbnailUrl !== '/images/default-stimuli.png';
    };
    IscStimulusItemPreviewController.prototype.getAriaLabel = function () {
        if (!this.isClickable()) {
            return '';
        }
        if (this.isImage()) {
            return '[aria-label](AriaLabelOpenImage)';
        }
        if (this.isVideo()) {
            return '[aria-label](AriaLabelOpenVideo)';
        }
    };
    IscStimulusItemPreviewController.prototype.openStimulus = function (event) {
        this.iscStimuliDialogService.openStimulusPreview(this.stimulus, this.allowDownload, event.target);
    };
    IscStimulusItemPreviewController.prototype.isImage = function () {
        return this.stimulus && this.stimulus.type === this.conversationStimuliType.image;
    };
    IscStimulusItemPreviewController.prototype.isVideo = function () {
        if (this.stimulus && this.stimulus.type) {
            if (this.stimulus.type === this.conversationStimuliType.image) {
                return false;
            }
            else if (this.stimulus.type === this.conversationStimuliType.vimeo ||
                this.stimulus.type === this.conversationStimuliType.video ||
                this.stimulus.type === this.conversationStimuliType.youtube) {
                return true;
            }
            else {
                throw new Error('Unknown stimulus type');
            }
        }
    };
    IscStimulusItemPreviewController.prototype.createBackgroundUrl = function () {
        if (this.inview && this.stimulus.thumbnailUrl) {
            return 'url(\'' + this.stimulus.thumbnailUrl + '\')';
        }
        return 'url(\'' + this.url + '\')';
    };
    IscStimulusItemPreviewController.prototype.getUploadProgress = function (stimulus) {
        if (this.uploadProgress) {
            return this.uploadProgress({ stimulus: stimulus });
        }
        return null;
    };
    IscStimulusItemPreviewController.prototype.showPreparingForPlayback = function () {
        var progress = this.getUploadProgress(this.stimulus);
        return !this.isVideoLoaded() && this.isVideo() && (progress === 100 || progress == null);
    };
    IscStimulusItemPreviewController.$inject = ['iscSocialStimuli', '$q', 'iscStimuliTypeService', '_', 'iscStimuliDialogService', '$scope'];
    return IscStimulusItemPreviewController;
}());
export { IscStimulusItemPreviewController };
