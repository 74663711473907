export class NewsDetailDialogController {
    constructor($stateParams, $mdDialog, $state, mediaservice, newsService, $document, languageService) {
        this.$stateParams = $stateParams;
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.mediaservice = mediaservice;
        this.newsService = newsService;
        this.$document = $document;
        this.languageService = languageService;
        this.hasPrevCard = false;
        this.hasNextCard = false;
        this.pageGuid = $stateParams.pageGuid;
        newsService.getNewsPageCards().then((cards) => {
            this.cards = cards;
        });
        this.hasPrev();
        this.hasNext();
    }
    cancel() {
        this.$mdDialog.cancel();
    }
    hasPrev(cardGuid) {
        this.newsService.getNewsPageCards().then(() => {
            this.hasPrevCard = this.newsService.getPrevCard(cardGuid || this.$stateParams.cardGuid) !== undefined;
        });
    }
    hasNext(cardGuid) {
        this.newsService.getNewsPageCards().then(() => {
            this.hasNextCard = this.newsService.getNextCard(cardGuid || this.$stateParams.cardGuid) !== undefined;
        });
    }
    keyUp($event) {
        if ($event.keyCode === 39) {
            this.next();
        }
        else if ($event.keyCode === 37) {
            this.prev();
        }
    }
    swipeLeft() {
        if (this.mediaservice.isMobile || this.mediaservice.isTablet) {
            this.next();
        }
    }
    swipeRight() {
        if (this.mediaservice.isMobile || this.mediaservice.isTablet) {
            this.prev();
        }
    }
    prev() {
        const card = this.newsService.getPrevCard(this.$stateParams.cardGuid);
        if (card) {
            const elements = this.$document[0].querySelectorAll('div[ui-view].news-detail');
            for (const element of Array.from(elements)) {
                angular.element(element).removeClass('back');
            }
            this.$state.go('container.main.news.detail', { cardGuid: card.Guid, card, back: true });
            this.hasPrev(card.Guid);
            this.hasNext(card.Guid);
        }
    }
    next() {
        const card = this.newsService.getNextCard(this.$stateParams.cardGuid);
        if (card) {
            const elements = this.$document[0].querySelectorAll('div[ui-view].news-detail');
            for (const element of Array.from(elements)) {
                angular.element(element).removeClass('back');
            }
            this.$state.go('container.main.news.detail', { cardGuid: card.Guid, card, back: false });
            this.hasPrev(card.Guid);
            this.hasNext(card.Guid);
        }
    }
    isRtl() {
        return this.languageService.isRtl();
    }
}
NewsDetailDialogController.$inject = ['$stateParams', '$mdDialog', '$state', 'mediaservice', 'newsService', '$document', 'languageService'];
