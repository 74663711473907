import {
  ShouldShowCaptchaResponse,
  SquareParticipantsAvailableForCallRequest,
  SquareParticipantUrlValidityResponse,
  SquareParticipantChangeRequestType,
  SquareParticipantRequestedEmailChangeResponse,
  GetSquareParticipantDetailsResponse,
  GetSquareParticipantDetailsRequest,
  SquarePasswordPolicyItemResponse,
  GetAdObjectIdForCurrentUserResponse,
  SquareParticipantDetailItemResponse,
  SquareParticipantPublicDetailsResponse,
  SquareParticipantListWithFiltersResponse,
  SquareParticipantListItemRequest,
  SquareParticipantGuidListItemRequest,
  SquareParticipantFilterRequest,
  SquareParticipantSegmentationsResponse,
  GetEmailMigrationInfoForAdLoginResponse,
  GetSegmentGuidsForAuthorsRequest,
  ListParticipantActivityItemResponse,
  SquareParticipantRoleResponse,
  GetSquareParticipantCustomEmailRequest,
  AzureAdDetailsDto,
  ConsentExpirationBannerResponse,
  GetSquareActivationQuestionsResponse,
  GetAvailableSquareParticipantEmailsResponse,
  GetMigrationStatusForEmailResponse,
  Role,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const shouldShowCaptcha = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ShouldShowCaptchaResponse>('/ParticipantService/ShouldShowCaptcha', undefined, config);

export const isUserUnique = (squareParticipantGuid: string, username?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/ParticipantService/IsUserUnique', { squareParticipantGuid, username }, config);

export const areSquareParticipantsAvailableForCall = (request: SquareParticipantsAvailableForCallRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<Record<string, string>>(
    '/ParticipantService/AreSquareParticipantsAvailableForCall',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const squareParticipantChangeRequestUrlValidity = (changeRequestGuid: string, type: SquareParticipantChangeRequestType, hoursBeforeExpiration: number, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareParticipantUrlValidityResponse>('/ParticipantService/SquareParticipantChangeRequestUrlValidity', { changeRequestGuid, type, hoursBeforeExpiration }, config);

export const squareParticipantRequestedEmailChange = (squareParticipantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareParticipantRequestedEmailChangeResponse>('/ParticipantService/SquareParticipantRequestedEmailChange', { squareParticipantGuid }, config);

export const getHealthReport = (requireSegments: boolean, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<object[]>('/ParticipantService/GetHealthReport', { requireSegments }, config);

export const getSquareParticipantDetailsAsync = (request: GetSquareParticipantDetailsRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<GetSquareParticipantDetailsResponse[]>(
    '/ParticipantService/GetSquareParticipantDetailsAsync',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const squarePasswordPolicyItems = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquarePasswordPolicyItemResponse>('/ParticipantService/SquarePasswordPolicyItems', undefined, config);

export const squareAdPasswordPolicyItems = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquarePasswordPolicyItemResponse>('/ParticipantService/SquareAdPasswordPolicyItems', undefined, config);

export const getSquareParticipantAdInformation = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetAdObjectIdForCurrentUserResponse>('/ParticipantService/GetSquareParticipantAdInformation', undefined, config);

export const squareParticipantDetails = (squareParticipantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareParticipantDetailItemResponse>('/ParticipantService/SquareParticipantDetails', { squareParticipantGuid }, config);

export const squareParticipantPublicDetails = (username?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareParticipantPublicDetailsResponse>('/ParticipantService/SquareParticipantPublicDetails', { username }, config);

export const squareParticipantList = (request: SquareParticipantListItemRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<SquareParticipantListWithFiltersResponse>(
    '/ParticipantService/SquareParticipantList',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const squareParticipantGuidList = (request: SquareParticipantGuidListItemRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string[]>(
    '/ParticipantService/SquareParticipantGuidList',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const squareParticipantIdList = (request: SquareParticipantFilterRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<number[]>(
    '/ParticipantService/SquareParticipantIdList',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const squareParticipantSegmentations = (squareParticipantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareParticipantSegmentationsResponse>('/ParticipantService/SquareParticipantSegmentations', { squareParticipantGuid }, config);

export const getEmailMigrationInfoForAdLogin = (email?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetEmailMigrationInfoForAdLoginResponse>('/ParticipantService/GetEmailMigrationInfoForAdLogin', { email }, config);

export const getSegmentGuidsForAuthorsAsync = (request: GetSegmentGuidsForAuthorsRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<Record<string, string[]>>(
    '/ParticipantService/GetSegmentGuidsForAuthorsAsync',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const listActivitiesForMember = (squareParticipantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListParticipantActivityItemResponse>('/ParticipantService/ListActivitiesForMember', { squareParticipantGuid }, config);

export const getActiveSquareParticipantRoles = (activityGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareParticipantRoleResponse[]>('/ParticipantService/GetActiveSquareParticipantRoles', { activityGuid }, config);

export const validateCurrentToken = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<void>('/ParticipantService/ValidateCurrentToken', undefined, config);

export const shouldRedirectToSplashPage = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/ParticipantService/ShouldRedirectToSplashPage', undefined, config);

export const hasUserAlreadyDownloadedTheMobileApp = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/ParticipantService/HasUserAlreadyDownloadedTheMobileApp', undefined, config);

export const getSquareParticipantCustomEmail = (request: GetSquareParticipantCustomEmailRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ParticipantService/GetSquareParticipantCustomEmail',
    request,
    undefined,
    config,
    QUERY_API_URL);

export const getInsitesUsersAvailableForSquare = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<AzureAdDetailsDto[]>('/ParticipantService/GetInsitesUsersAvailableForSquare', undefined, config);

export const getConsentExpirationBanners = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ConsentExpirationBannerResponse>('/ParticipantService/GetConsentExpirationBanners', undefined, config);

export const getSquareParticipantActivationQuestions = (squareParticipantGuid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetSquareActivationQuestionsResponse>('/ParticipantService/GetSquareParticipantActivationQuestions', { squareParticipantGuid }, config);

export const getValidSquareParticipantEmails = (squareParticipantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetAvailableSquareParticipantEmailsResponse>('/ParticipantService/GetValidSquareParticipantEmails', { squareParticipantGuid }, config);

export const isSquareParticipantMigrating = (squareParticipantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/ParticipantService/IsSquareParticipantMigrating', { squareParticipantGuid }, config);

export const getMigratedSquaresOfParticipant = (squareParticipantGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string[]>('/ParticipantService/GetMigratedSquaresOfParticipant', { squareParticipantGuid }, config);

export const getMigrationStatusForEmail = (role: Role, email?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetMigrationStatusForEmailResponse>('/ParticipantService/GetMigrationStatusForEmail', { role, email }, config);

export const getMigrationStatusFor = (email?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetMigrationStatusForEmailResponse>('/ParticipantService/GetMigrationStatusFor', { email }, config);
