import { __extends } from "tslib";
import { HotkeySubscription } from '../HotKeySubscription';
var UndoHotkey = (function (_super) {
    __extends(UndoHotkey, _super);
    function UndoHotkey($document) {
        var _this = _super.call(this) || this;
        _this.$document = $document;
        _this.keyCode = 90;
        _this.ctrlKey = true;
        return _this;
    }
    UndoHotkey.prototype.handler = function (event) {
        this.undo(event);
    };
    UndoHotkey.prototype.undo = function (event) {
        this.$document[0].execCommand('undo', false);
        event.preventDefault();
    };
    return UndoHotkey;
}(HotkeySubscription));
export { UndoHotkey };
