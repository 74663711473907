var IscInputController = (function () {
    function IscInputController($element) {
        this.$element = $element;
    }
    IscInputController.prototype.$postLink = function () {
        var inputElem = this.$element.find('input');
        this.ngModelController = inputElem.controller('ngModel');
    };
    IscInputController.prototype.onValueChanged = function (value) {
        this.ngModelController.$setValidity('serverErrors', true);
        if (this.isChanged) {
            this.isChanged({ test: null, newValue: value });
        }
    };
    IscInputController.prototype.onBlurObject = function () {
        if (this.onBlur) {
            this.onBlur();
        }
    };
    IscInputController.$inject = ['$element'];
    return IscInputController;
}());
export { IscInputController };
