import { __awaiter } from "tslib";
import { Utils } from '../utils';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
export class ConversationService {
    constructor(forumService, muxService, fileStorageService, participantFactory, $stateParams, pageThemes, taskList, emojiservice, iscPages, userService, $state, constantsfactory, serverConstants, constants, __env, $q, squareFactory) {
        this.forumService = forumService;
        this.muxService = muxService;
        this.fileStorageService = fileStorageService;
        this.participantFactory = participantFactory;
        this.$stateParams = $stateParams;
        this.pageThemes = pageThemes;
        this.taskList = taskList;
        this.emojiservice = emojiservice;
        this.iscPages = iscPages;
        this.userService = userService;
        this.$state = $state;
        this.constantsfactory = constantsfactory;
        this.serverConstants = serverConstants;
        this.constants = constants;
        this.__env = __env;
        this.$q = $q;
        this.squareFactory = squareFactory;
        this.replySucceededMessageShownForMilliseconds = 3000;
    }
    ensureInitialized() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.initPromise) {
                this.initPromise = this.init();
                this.initPromise.catch(() => {
                    this.initPromise = null;
                });
            }
            return yield this.initPromise;
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.iscPages.init();
            const contactPage = this.iscPages.helpCenterPages.filter((page) => page.PageType === this.iscPages.PageType.contact);
            const contactUrl = this.$state.href('container.main.helpcenter.contact', { helpcenterPage: contactPage[0].Guid }, { absolute: true });
            this.labelpromise = this.$q.all([
                this.constantsfactory.getLabelValueReplaceUrl('LabelForumRoomCensoredCause', contactUrl),
                this.constantsfactory.getLabelValue('LabelForumRoomCensored'),
            ]);
        });
    }
    onForumPostHeartClick(post) {
        return __awaiter(this, void 0, void 0, function* () {
            let promise;
            if (post.IsLiked) {
                promise = this.forumService.unlikeConversation(post.Guid);
            }
            else {
                promise = this.forumService.likeConversation(post.Guid);
            }
            return yield this.onHeartClick(promise, post);
        });
    }
    onQualHeartClick(post) {
        return __awaiter(this, void 0, void 0, function* () {
            let promise;
            if (post.IsLiked) {
                promise = this.forumService.unlikeQualConversation(post.Guid);
            }
            else {
                promise = this.forumService.likeQualConversation(post.Guid);
            }
            yield this.onHeartClick(promise, post);
        });
    }
    onHeartClick(promise, post) {
        return __awaiter(this, void 0, void 0, function* () {
            yield promise;
            if (post.IsLiked) {
                post.LikesCount--;
            }
            else {
                post.LikesCount++;
            }
            post.IsLiked = !post.IsLiked;
            return promise;
        });
    }
    sendForumReply(reply, message, stimuli, attachments, newAttachmentFiles, replyType, channel, caption) {
        return __awaiter(this, void 0, void 0, function* () {
            const request = yield this.prepareReply(reply, message, stimuli, attachments, newAttachmentFiles, replyType, channel, caption);
            const { publishingTask, errorTask, succeededTask } = this.makeTasks();
            publishingTask.show();
            let replyGuid;
            try {
                delete request.request.ChannelType;
                replyGuid = yield this.forumService.replyToForumConversation(request.request, request.newStimuli, request.newAttachments)
                    .then((response) => response, (response) => this.$q.reject(response), this.showProgressInTaskList(publishingTask));
                publishingTask.updateProgress(100);
                publishingTask.hide();
                succeededTask.show();
                window.setTimeout(() => {
                    succeededTask.hide();
                }, this.replySucceededMessageShownForMilliseconds);
            }
            catch (e) {
                publishingTask.hide();
                errorTask.show();
            }
            return replyGuid;
        });
    }
    sendQualReply(reply, message, stimuli, attachments, newAttachmentFiles, channel, caption) {
        return __awaiter(this, void 0, void 0, function* () {
            reply.Type = this.serverConstants.conversationElementTypeConstants.reply;
            const request = yield this.prepareReply(reply, message, stimuli, attachments, newAttachmentFiles, reply.Type, channel, caption);
            const { publishingTask, errorTask, succeededTask } = this.makeTasks();
            publishingTask.show();
            let replyGuid;
            try {
                delete request.request.Room;
                replyGuid = yield this.forumService.replyToQualConversation(request.request, request.newStimuli, request.newAttachments)
                    .then((response) => response, (response) => this.$q.reject(response), this.showProgressInTaskList(publishingTask));
                publishingTask.updateProgress(100);
                publishingTask.hide();
                succeededTask.show();
                window.setTimeout(() => {
                    succeededTask.hide();
                }, this.replySucceededMessageShownForMilliseconds);
            }
            catch (e) {
                publishingTask.hide();
                errorTask.show();
            }
            return replyGuid;
        });
    }
    replyToProbingQuestions(probingAnswers, activityType, conversationFocusType, contributionType, channelType) {
        return __awaiter(this, void 0, void 0, function* () {
            const { publishingTask, errorTask, succeededTask } = this.makeTasks();
            publishingTask.show();
            let replyGuid;
            try {
                const oldStimulusFiles = [].concat(...probingAnswers.map((answer) => answer.stimuli));
                const oldAttachmentFiles = [].concat(...probingAnswers.map((answer) => answer.attachments));
                let newStimulusFiles = [].concat(...probingAnswers.map((answer) => answer.newStimulusFiles));
                let newAttachmentFiles = [].concat(...probingAnswers.map((answer) => answer.newAttachmentFiles));
                const prepareResult = yield this.prepareStimuliAndNewStimuli(oldStimulusFiles);
                prepareResult.stimuli.forEach((stimulus) => {
                    if (stimulus.fileContentToBeRemoved) {
                        stimulus.file = undefined;
                    }
                });
                newStimulusFiles = prepareResult.files;
                const attachmentsAndFiles = yield this.prepareAttachmentsAndNewAttachments(oldAttachmentFiles);
                newAttachmentFiles = attachmentsAndFiles.files;
                probingAnswers.forEach((answer) => {
                    if (answer.stimuli && answer.stimuli.length > 0) {
                        answer.stimuli = answer.stimuli.map((stimulus) => {
                            if (stimulus.file) {
                                const stimulusIndex = newStimulusFiles.indexOf(stimulus.file);
                                if (stimulusIndex !== -1) {
                                    stimulus.id = stimulusIndex.toString();
                                }
                            }
                            return stimulus;
                        });
                    }
                    if (answer.attachments && answer.attachments.length > 0) {
                        answer.attachments = answer.attachments.map((attachment) => {
                            if (attachment.file) {
                                const attachmentIndex = newAttachmentFiles.indexOf(attachment.file);
                                if (attachmentIndex !== -1) {
                                    attachment.id = attachmentIndex.toString();
                                }
                            }
                            return attachment;
                        });
                    }
                });
                probingAnswers.forEach((answer) => {
                    var _a;
                    (_a = answer.stimuli) === null || _a === void 0 ? void 0 : _a.forEach((stimulus) => {
                        stimulus.videoPlayer = null;
                    });
                });
                replyGuid = yield this.forumService.replyToProbingQuestions(probingAnswers, newStimulusFiles, newAttachmentFiles, activityType, conversationFocusType, contributionType, channelType);
                publishingTask.updateProgress(100);
                publishingTask.hide();
                succeededTask.show();
                window.setTimeout(() => {
                    succeededTask.hide();
                }, this.replySucceededMessageShownForMilliseconds);
            }
            catch (e) {
                publishingTask.hide();
                errorTask.show();
            }
            return replyGuid;
        });
    }
    jumpToReplyQual(replyGuid_1, channel_1) {
        return __awaiter(this, arguments, void 0, function* (replyGuid, channel, activityType = null, replyStartDateTime = null) {
            if (!this.shouldJumpToReply(replyStartDateTime)) {
                return;
            }
            const reply = yield this.forumService.getQualConversationElementOrDefault(replyGuid, this.constants.itemsPerPage);
            const challengesPage = _.find(this.iscPages.mainPages, (item) => item.PageType === this.serverConstants.pageTypeConstants.activities);
            this.$state.go('container.main.challenges.qualactivity', {
                activityGuid: reply.ActivityGuid,
                channel,
                pageGuid: challengesPage.Guid,
                page: reply.PageIndex,
                expandedPosts: reply.ExpandedPosts,
                replyGuid: reply.Guid,
                activityType,
            });
        });
    }
    jumpToReplyForum(replyGuid_1) {
        return __awaiter(this, arguments, void 0, function* (replyGuid, replyStartDateTime = null) {
            if (!this.shouldJumpToReply(replyStartDateTime)) {
                return;
            }
            const currentPage = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
            const reply = yield this.forumService.getConversationLocation(replyGuid, this.constants.itemsPerPage);
            if (this.$state.current.name === 'container.main.forum.conversation' &&
                this.$stateParams.pageGuid === reply.PageGuid &&
                this.$stateParams.roomGuid === reply.RoomGuid &&
                this.$stateParams.conversationGuid === reply.ConversationGuid &&
                currentPage === reply.PageIndex) {
                Utils.anchorScrollWithWait(replyGuid);
            }
            else {
                this.$state.go('container.main.forum.conversation', {
                    pageGuid: reply.PageGuid,
                    roomGuid: reply.RoomGuid,
                    conversationGuid: reply.ConversationGuid,
                    page: reply.PageIndex,
                    replyGuid,
                });
            }
        });
    }
    getReplyForumLink(replyGuid_1, redirectTo_1) {
        return __awaiter(this, arguments, void 0, function* (replyGuid, redirectTo, replyStartDateTime = null) {
            if (!this.shouldJumpToReply(replyStartDateTime)) {
                return;
            }
            const reply = yield this.forumService.getConversationLocation(replyGuid, this.constants.itemsPerPage);
            switch (redirectTo) {
                case this.serverConstants.redirectForumTypeConstants.singleTopic:
                    return this.$state.href('container.main.forum.conversation', {
                        pageGuid: reply.PageGuid,
                        roomGuid: reply.RoomGuid,
                        conversationGuid: reply.ConversationGuid,
                        page: reply.PageIndex,
                    });
                case this.serverConstants.redirectForumTypeConstants.room:
                    return this.$state.href('container.main.forum.room', {
                        pageGuid: reply.PageGuid,
                        roomGuid: reply.RoomGuid,
                    });
                case this.serverConstants.redirectForumTypeConstants.newTopics:
                    return this.$state.href('container.main.forum.room', { roomGuid: this.forumService.EMPTYGUID, pageGuid: reply.PageGuid });
                default:
                    return this.$state.href('container.main.forum.conversation', {
                        pageGuid: reply.PageGuid,
                        roomGuid: reply.RoomGuid,
                        conversationGuid: reply.ConversationGuid,
                        page: reply.PageIndex,
                    });
            }
        });
    }
    reportAsInappropriate() {
        const contactPage = this.iscPages.helpCenterPages.filter((page) => page.PageType === this.iscPages.PageType.contact);
        this.iscPages.goToPage(contactPage[0]);
    }
    getAvatar(conversation) {
        if (conversation) {
            return conversation.SquareParticipantHasProfileImage ?
                this.participantFactory.getParticipantImageUrl(conversation.SquareParticipantGuid, conversation.SquareParticipantDateModified) :
                this.participantFactory.DefaultAvatar;
        }
        return undefined;
    }
    navigate(pageNumber, sortBy, expandedPosts, isGroupedByMember = null) {
        const params = this.makeNavigationStateParams(pageNumber, sortBy, expandedPosts, isGroupedByMember);
        this.$state.go('.', params);
    }
    makeNavigationStateParams(pageNumber, sortBy, expandedPosts, isGroupedByMember = null) {
        return {
            page: pageNumber,
            sort: sortBy,
            expandedPosts,
            replyGuid: null,
            isGroupedByMember,
        };
    }
    loadForumConversationElements(guid_1) {
        return __awaiter(this, arguments, void 0, function* (guid, currentPage = 1, itemsPerPage = 50, sortBy) {
            yield this.ensureInitialized();
            const response = yield this.forumService.getConversationElements(guid, currentPage, itemsPerPage, sortBy);
            yield this.censorConversations(response.Items);
            return response;
        });
    }
    loadQualConversationElements(activityGuid_1) {
        return __awaiter(this, arguments, void 0, function* (activityGuid, currentPage = 1, itemsPerPage = 5, sortBy, expandedPosts, expandLatestUpdatePost, isGroupedByMember) {
            yield this.ensureInitialized();
            const response = yield this.forumService.getQualConversationElements(activityGuid, currentPage, itemsPerPage, sortBy, expandedPosts, expandLatestUpdatePost, isGroupedByMember);
            yield this.censorConversations(response.ConversationElements.Items);
            return response;
        });
    }
    addInDraftRepliesToPost(conversationElements) {
        const repliesInDraft = conversationElements
            .filter((element) => element.IsInDraft);
        const parentMessageGuids = repliesInDraft.map((reply) => reply.ParentMessage);
        conversationElements
            .filter((element) => parentMessageGuids.indexOf(element.Guid) !== -1)
            .forEach((element) => {
            element.InDraftReply = _.find(repliesInDraft, (reply) => reply.ParentMessage === element.Guid);
        });
        return conversationElements.filter((element) => repliesInDraft.indexOf(element) === -1);
    }
    censorConversations(conversations) {
        return __awaiter(this, void 0, void 0, function* () {
            const labels = yield this.labelpromise;
            conversations.forEach((item) => {
                item.Message = this.returnMessage(item.Message, item.Censored, item.SquareParticipantGuid, labels);
            });
        });
    }
    returnMessage(message, censored, squareParticipantGuid, labels) {
        if (censored) {
            if (this.isRepliedByCurrentParticipant(squareParticipantGuid)) {
                return labels[0];
            }
            return labels[1];
        }
        return message;
    }
    isRepliedByCurrentParticipant(squareParticipantGuid) {
        return this.userService.userGuid() === squareParticipantGuid;
    }
    prepareReply(reply, message, stimuli, attachments, newattachments, replyType, channel, caption) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = {
                request: {
                    Room: this.$stateParams.roomGuid,
                    Guid: reply.InDraftReply ? reply.InDraftReply.Guid : reply.Guid,
                    ParentMessageGuid: reply.ParentMessage,
                    Message: this.prepareMessage(message),
                    Caption: this.prepareMessage(caption),
                    ChannelType: channel ? channel : 0,
                    Type: replyType,
                    Stimuli: [],
                    Attachments: attachments,
                },
                newStimuli: [],
                newAttachments: newattachments,
            };
            const preparedStimuliAndFiles = yield this.prepareStimuliAndNewStimuli(stimuli);
            preparedStimuliAndFiles.stimuli.forEach((stimulus) => {
                if (stimulus.fileContentToBeRemoved) {
                    stimulus.file = undefined;
                }
            });
            result.request.Stimuli = preparedStimuliAndFiles.stimuli;
            result.newStimuli = preparedStimuliAndFiles.files;
            const attachmentsAndFiles = yield this.prepareAttachmentsAndNewAttachments(attachments);
            result.request.Attachments = attachmentsAndFiles.attachments;
            result.newAttachments = attachmentsAndFiles.files;
            return result;
        });
    }
    prepareAttachmentsAndNewAttachments(attachments) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = {
                files: [],
                attachments: [],
            };
            for (const attachment of attachments) {
                if (this.__env.environment !== 'LOCALHOST' && attachment.file !== undefined) {
                    const attachmentUploadData = yield this.fileStorageService.GetNewUploadLinkForAttachment(attachment.file.type);
                    if (attachmentUploadData && attachmentUploadData.data) {
                        this.fileStorageService.uploadAttachment(attachmentUploadData.data, attachment.file);
                        attachment.mimeType = attachment.file.type;
                        attachment.file = undefined;
                        attachment.id = attachmentUploadData.data.AttachmentGuid;
                        attachment.url = attachmentUploadData.data.AttachmentSasUrl.split('?')[0];
                    }
                    else {
                        result.files.push(attachment.file);
                    }
                    result.attachments.push(attachment);
                }
                else {
                    result.attachments.push(attachment);
                    if (attachment.file !== undefined) {
                        result.files.push(attachment.file);
                    }
                }
            }
            return result;
        });
    }
    prepareStimuliAndNewStimuli(stimuli) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = {
                files: [],
                stimuli: [],
            };
            for (const stimulus of stimuli) {
                if (stimulus.file !== undefined && stimulus.type === 1) {
                    if (this.muxService.MuxServiceEnabled) {
                        const muxData = yield this.muxService.getNewUploadLinkForVideo();
                        if (muxData && muxData.data) {
                            this.muxService.uploadVideo(muxData.data, stimulus.file, (uploadProgress) => this.updateStimuliUploadTaskProgress(undefined, uploadProgress));
                            stimulus.fileContentToBeRemoved = true;
                            stimulus.id = muxData.data.Id;
                        }
                        else {
                            result.files.push(stimulus.file);
                        }
                    }
                    else {
                        const videoUploadData = yield this.fileStorageService.GetNewUploadLinkForVideo(stimulus.file.type);
                        if (videoUploadData && videoUploadData.data) {
                            this.fileStorageService.uploadVideo(videoUploadData.data, stimulus.file, (uploadProgress) => this.updateStimuliUploadTaskProgress(undefined, uploadProgress));
                            stimulus.fileContentToBeRemoved = true;
                            stimulus.id = videoUploadData.data.StimuliGuid;
                            stimulus.url = videoUploadData.data.Url.split('?')[0];
                            stimulus.thumbnailUrl = this.squareFactory.VideoStorage === this.serverConstants.clientVideoStorageConstants.azureMediaServices
                                ? this.GetThumbnailUrl(stimulus.url)
                                : null;
                        }
                        else {
                            result.files.push(stimulus.file);
                        }
                    }
                    result.stimuli.push(stimulus);
                }
                else if (this.__env.environment !== 'LOCALHOST' && stimulus.file !== undefined && stimulus.type === 0) {
                    const photoUploadData = yield this.fileStorageService.GetNewUploadLinkForPhoto(stimulus.file.type);
                    if (photoUploadData && photoUploadData.data) {
                        this.fileStorageService.uploadImage(photoUploadData.data, stimulus.file);
                        stimulus.fileContentToBeRemoved = true;
                        stimulus.url = photoUploadData.data.PhotoSasUrl.split('?')[0];
                        stimulus.thumbnailUrl = this.GetThumbnailUrl(photoUploadData.data.PhotoSasUrl.split('?')[0]);
                        stimulus.id = photoUploadData.data.StimuliGuid;
                    }
                    else {
                        result.files.push(stimulus.file);
                    }
                    result.stimuli.push(stimulus);
                }
                else {
                    result.stimuli.push(stimulus);
                    if (stimulus.file !== undefined) {
                        result.files.push(stimulus.file);
                    }
                }
            }
            return result;
        });
    }
    GetThumbnailUrl(url) {
        const extension = url.substring(url.lastIndexOf('.'));
        return url.replace(extension, '_medium.png');
    }
    prepareMessage(message) {
        let result = message;
        if (message) {
            const re = new RegExp(this.serverConstants.validationConstants.userMentionRegex, 'g');
            result = message.replace(re, (username) => _.unescape(username));
            const doc = (new DOMParser()).parseFromString(message, 'text/html');
            if (doc && doc.documentElement) {
                const spans = doc.querySelectorAll('span');
                _.forEach(spans, (span) => {
                    if (span.hasAttribute('class') && span.getAttribute('class').indexOf('ng-') !== -1) {
                        result = result.replace(span.outerHTML, span.innerHTML);
                    }
                });
            }
        }
        return this.emojiservice.htmlToShort(result);
    }
    makeTasks() {
        const pageGuid = this.$stateParams.pageGuid;
        const theme = this.pageThemes.getPageTheme(pageGuid);
        const publishingTask = this.taskList.createTask({
            title: '(LabelPublishingConversationTitle)',
            text: '(LabelPublishingConversationText)',
            theme,
        });
        const errorTask = this.taskList.createTask({
            title: '(LabelPublishConversationError)',
            text: '(LabelPublishConversationErrorText)',
            showCloseButton: true,
            theme,
            onClick: () => {
                errorTask.hide();
            },
        });
        const succeededTask = this.taskList.createTask({
            title: '(LabelPublishingConversationTitle)',
            text: '(LabelPublishingConversationSucceeded)',
            showCloseButton: true,
            theme,
            onClick: () => {
                succeededTask.hide();
            },
        });
        return { publishingTask, errorTask, succeededTask };
    }
    createAndShowVideoUploadTask(postGuid, stimulusProgressList) {
        if (postGuid && stimulusProgressList && stimulusProgressList.length) {
            const pageGuid = this.$stateParams.pageGuid;
            const theme = this.pageThemes.getPageTheme(pageGuid);
            const videoUploadTask = this.taskList.createTask({
                id: postGuid,
                title: '(LabelUploadInProgress)',
                text: '(LabelUploadingVideosAndMedia)',
                loadingElements: stimulusProgressList,
                theme,
            });
            videoUploadTask.show();
        }
    }
    hideVideoUploadTask(postGuid) {
        const videoUploadTask = this.taskList.getTaskById(postGuid);
        if (videoUploadTask) {
            videoUploadTask.hide();
        }
    }
    updateStimuliUploadTaskProgress(postGuid, updatedStimulusProgress) {
        let stimuliUploadTask = this.taskList.getTaskById(postGuid);
        if (!stimuliUploadTask) {
            stimuliUploadTask = this.taskList.tasks.find((task) => { var _a; return (_a = task.loadingElements) === null || _a === void 0 ? void 0 : _a.some((stimulusProgress) => stimulusProgress.stimulusValue === updatedStimulusProgress.stimulusValue); });
        }
        if (stimuliUploadTask) {
            const uploadingStimuli = stimuliUploadTask.loadingElements;
            const stimulusToUpdate = uploadingStimuli === null || uploadingStimuli === void 0 ? void 0 : uploadingStimuli.find((s) => s.stimulusValue === updatedStimulusProgress.stimulusValue);
            if (stimulusToUpdate) {
                stimulusToUpdate.progress = updatedStimulusProgress.progress;
            }
            const totalProgress = uploadingStimuli.reduce((a, b) => a + b.progress, 0) / uploadingStimuli.length;
            stimuliUploadTask.updateProgress(Math.round(totalProgress));
            if (totalProgress === 100 || uploadingStimuli.every((s) => s.progress == null)) {
                stimuliUploadTask.hide();
            }
        }
    }
    showProgressInTaskList(publishingTask) {
        return (progress) => {
            const percentage = Math.floor(100 * progress.loaded / progress.total);
            publishingTask.updateProgress(percentage === 100 ? 99 : percentage);
        };
    }
    shouldJumpToReply(replyStartDateTime) {
        if (!replyStartDateTime) {
            return true;
        }
        const publishDurationMinThresHoldMilliseconds = 3000;
        const publishDurationMilliseconds = DateTime.utc().toMillis() - replyStartDateTime.toMillis();
        return publishDurationMilliseconds <= publishDurationMinThresHoldMilliseconds;
    }
    updateStimulusAfterNewUpload(stimulus) {
        return this.forumService.updateStimulusAfterNewUpload(stimulus);
    }
}
ConversationService.$inject = [
    'forumService',
    'muxService',
    'fileStorageService',
    'participantFactory',
    '$stateParams',
    'pageThemes',
    'taskList',
    'emojiservice',
    'iscPages',
    'userService',
    '$state',
    'constantsfactory',
    'serverConstants',
    'constants',
    '__env',
    '$q',
    'squareFactory',
];
