import {
  ListPageItemResponse,
  Area,
  ListPageItemConsumerResponse,
  SquareAppPagesResponse,
  SquareAppPagesv2Response,
  PageDetailItemResponse,
  ListFaqCategoryResponse,
  ListFaqQuestionResponse,
  FaqPageDetailItemResponse,
  FaqCategoryType,
  PageConsumerListItem,
  RoomListItem,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const listPageAdmin = (area: Area, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListPageItemResponse>('/PageService/ListPageAdmin', { area }, config);

export const listPageConsumer = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListPageItemConsumerResponse>('/PageService/ListPageConsumer', undefined, config);

export const listMobileAppPages = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareAppPagesResponse>('/PageService/ListMobileAppPages', undefined, config);

export const listMobilePages = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareAppPagesv2Response>('/PageService/ListMobilePages', undefined, config);

export const selectPage = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<PageDetailItemResponse>('/PageService/SelectPage', { guid }, config);

export const listFaqCategory = (pageGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListFaqCategoryResponse>('/PageService/ListFaqCategory', { pageGuid }, config);

export const listFaqQuestion = (pageGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListFaqQuestionResponse>('/PageService/ListFaqQuestion', { pageGuid }, config);

export const getFaqPageDetails = (onlyFiltered: boolean, guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<FaqPageDetailItemResponse>('/PageService/GetFaqPageDetails', { onlyFiltered, guid }, config);

export const isFaqCategoryNameUnique = (categoryType: FaqCategoryType, name?: string, guid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/PageService/IsFaqCategoryNameUnique', { categoryType, name, guid }, config);

export const isFaqQuestionTitleUnique = (title?: string, guid?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/PageService/IsFaqQuestionTitleUnique', { title, guid }, config);

export const anyNewsPageForSquare = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/PageService/AnyNewsPageForSquare', undefined, config);

export const allowedToNavigateToPage = (pageGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/PageService/AllowedToNavigateToPage', { pageGuid }, config);

export const getPagesForConsumer = (squareParticipantGuid?: string, guestUser?: boolean, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<PageConsumerListItem[]>('/PageService/GetPagesForConsumer', { squareParticipantGuid, guestUser }, config);

export const getRoomsByPageGuid = (pageGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<RoomListItem[]>('/PageService/GetRoomsByPageGuid', { pageGuid }, config);
