<script lang="ts" setup>
import { useConstantsStore } from '@/store/constants-store';

// labels
const constantsStore = useConstantsStore();
const getLabelValue = constantsStore.getLabelValue;

const skipToContent = () => {
  const mainElement = document.getElementById('main') as HTMLElement;
  mainElement.focus();
};

</script>

<template>
  <router-link
    :to="{ hash: '#main' }"
    class="skip-to-content text-h6 q-pa-none"
    @click="skipToContent"
  >
    {{ getLabelValue('SkipToContent') }}
  </router-link>
</template>

<style lang="scss">
.skip-to-content {
  display: block;
  height: 0;
  overflow: hidden;
  color: var(--navigation-bar-text, $dark);
  text-decoration: none;

  &:focus {
    height: auto;
    display: inline;
    padding: $flex-gutter-xs;
    margin-left: $flex-gutter-sm;
  }
}
</style>
