export class FooterpageController {
    constructor($stateParams, pageService) {
        this.$stateParams = $stateParams;
        this.pageService = pageService;
    }
    $onInit() {
        this.pageService.getPageDetails(this.$stateParams.pageGuid).then((pageDetails) => {
            this.pageDetails = pageDetails;
        });
    }
}
FooterpageController.$inject = ['$stateParams', 'pageService'];
