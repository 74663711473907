import { __awaiter } from "tslib";
export class UnsubscribeController {
    constructor($stateParams, participantService, $state, imagesFactory, _, authService, constantsfactory) {
        this.$stateParams = $stateParams;
        this.participantService = participantService;
        this.$state = $state;
        this.imagesFactory = imagesFactory;
        this._ = _;
        this.authService = authService;
        this.constantsfactory = constantsfactory;
        this.valid = false;
        this.reasons = {
            details: [],
            otherMessage: '',
            other: false,
        };
        this.images = this.imagesFactory.images;
        this.dataLoading = false;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.imagesFactory.applyImages();
            this.reasons.details.push(yield this.getReason(1));
            this.reasons.details.push(yield this.getReason(2));
            this.reasons.details.push(yield this.getReason(3));
            this.reasons.details.push(yield this.getReason(4));
            this.reasons.details.push(yield this.getReason(5));
            this.reasons.details.push(yield this.getReason(6));
        });
    }
    getReason(index) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                checked: false,
                label: yield this.constantsfactory.getLabelValue(`LabelUnsubscribeReason${index}`),
            };
        });
    }
    change() {
        this.valid = this._.some(this.reasons.details, (value) => value.checked) || (this.reasons.other && this.reasons.otherMessage.length > 0);
    }
    unsubscribe() {
        this.dataLoading = true;
        const deleteParams = {
            SquareParticipantGuid: this.$stateParams.sqPartId,
            UnsubscribeReason: this.getSerializedUnsubscribeReasons(),
        };
        this.participantService.unsubscribeParticipant(deleteParams).then((data) => __awaiter(this, void 0, void 0, function* () {
            this.dataLoading = false;
            yield this.authService.signOut(false);
            if (data.SubscribeChangeGuid) {
                const route = this.$state.href('container.reactivate', {
                    subscribeGuid: data.SubscribeChangeGuid,
                });
                location.assign(location.origin + route);
            }
            else {
                const route = this.$state.href('container.login');
                location.assign(location.origin + route);
            }
        }));
    }
    getUnsubscribeReasons() {
        const reasons = [];
        this._.forEach(this.reasons.details, (value) => {
            if (value.checked) {
                reasons.push(value.label);
            }
        });
        if (this.reasons.other) {
            reasons.push(this.reasons.otherMessage);
        }
        return reasons;
    }
    getSerializedUnsubscribeReasons() {
        const reasons = this.getUnsubscribeReasons();
        return reasons.join(',');
    }
}
UnsubscribeController.$inject = ['$stateParams', 'participantService', '$state', 'imagesFactory', '_', 'authService', 'constantsfactory'];
