import { HtmlUtils } from '../../../../utils/htmlutils';
import { createPasteElementHelpers } from './pasteHelpers';
import cleanMsoHtml from './pasteHelpers/cleanMsoHtml';
var PastedContentHandler = (function () {
    function PastedContentHandler($sanitize, $sce, _, $injector) {
        this.$sanitize = $sanitize;
        this.$sce = $sce;
        this._ = _;
        this.$injector = $injector;
    }
    PastedContentHandler.prototype.init = function (richInput) {
        var _this = this;
        this.pasteElementHelpers = createPasteElementHelpers(this.$injector);
        richInput.subscribeToPaste(function (pastedContent) { return _this.onPaste(pastedContent, richInput.shouldRemoveAllStyles); });
    };
    PastedContentHandler.prototype.onPaste = function (pastedContent, shouldRemoveAllStyles) {
        pastedContent = pastedContent.replace(/<meta[\W\w]+<\/xml><\!\[endif\]-->/g, '');
        var match = pastedContent.match(/<!--StartFragment-->([\S\s]*?)<!--EndFragment-->/);
        if (match !== null) {
            pastedContent = match.pop();
        }
        if (shouldRemoveAllStyles) {
            pastedContent = HtmlUtils.removeStyles(pastedContent);
        }
        else {
            pastedContent = HtmlUtils.sanitizeRichEditorContent(pastedContent, this.$sanitize, this.$sce);
        }
        var pastedElement = document.createElement('div');
        pastedElement.innerHTML = pastedContent;
        pastedElement = this.processPastedElement(pastedElement);
        pastedElement = this.removeDataBase64Images(pastedElement);
        pastedElement = this.cleanupStylingForImages(pastedElement);
        pastedContent = pastedElement ? pastedElement.innerHTML : '';
        pastedContent = cleanMsoHtml(pastedContent);
        return pastedContent;
    };
    PastedContentHandler.prototype.processPastedElement = function (element) {
        var _this = this;
        this._.each(this.pasteElementHelpers, function (helper) {
            element = element && helper.processElement(element);
        });
        if (element) {
            var removedChildren_1 = [];
            this._.each(element.children, function (child) {
                var replaceChild = _this.processPastedElement(child);
                if (replaceChild === null) {
                    removedChildren_1.push(child);
                }
                else if (child !== replaceChild) {
                    element.replaceChild(replaceChild, child);
                }
            });
            this._.each(removedChildren_1, function (child) {
                element.removeChild(child);
            });
        }
        return element;
    };
    PastedContentHandler.prototype.removeDataBase64Images = function (element) {
        var allImages = element.querySelectorAll('img');
        var base64ImageSrcRegex = new RegExp('data\:[a-z\/]+?;base64\,[^"]+');
        for (var _i = 0, _a = allImages; _i < _a.length; _i++) {
            var image = _a[_i];
            if (image.src && base64ImageSrcRegex.test(image.src)) {
                var parent_1 = image.parentElement;
                parent_1.removeChild(image);
            }
        }
        return element;
    };
    PastedContentHandler.prototype.cleanupStylingForImages = function (element) {
        var allImages = element.querySelectorAll('img');
        for (var _i = 0, _a = allImages; _i < _a.length; _i++) {
            var image = _a[_i];
            image.removeAttribute('style');
            image.style.maxWidth = '640px';
            image.style.maxHeight = '480px';
        }
        return element;
    };
    PastedContentHandler.$inject = ['$sanitize', '$sce', '_', '$injector'];
    return PastedContentHandler;
}());
export default PastedContentHandler;
