var PaginationController = (function () {
    function PaginationController() {
        this.pages = [];
        this.showDotsAfterFirstPage = false;
        this.showDotsBeforeLastPage = false;
    }
    PaginationController.prototype.$onChanges = function () {
        this.totalPages = Math.max(1, this.totalPages);
        var firstPageToShow = 1;
        var lastPageToShow = 3;
        if (this.currentPage > 1) {
            firstPageToShow = this.currentPage - 1;
            lastPageToShow = this.currentPage + 1;
        }
        if (this.currentPage === this.totalPages) {
            firstPageToShow = this.currentPage - 2;
        }
        lastPageToShow = Math.min(this.totalPages - 1, lastPageToShow);
        firstPageToShow = Math.max(2, firstPageToShow);
        this.generatePages(firstPageToShow, lastPageToShow);
        this.showDotsAfterFirstPage = firstPageToShow > 2;
        this.showDotsBeforeLastPage = lastPageToShow < this.totalPages - 1;
    };
    PaginationController.prototype.generatePages = function (first, last) {
        this.pages = [];
        for (var i = first; i <= last; i++) {
            this.pages.push(i);
        }
    };
    PaginationController.prototype.navigate = function (pageNumber) {
        this.onPageClick({ pageNumber: pageNumber });
    };
    return PaginationController;
}());
export { PaginationController };
