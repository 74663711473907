import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';

const ProfilePage = () => import('@/pages/public-profile-page.vue');

export const publicProfileRoute: RouteRecordRaw = {
  name: RouteNames.PublicProfile,
  path: '/profile/:username',
  component: ProfilePage,
  meta: {
    requireLogin: true,
    title: '(LabelWindowTitlePublicProfile)',
  },
};
