import { __awaiter, __generator } from "tslib";
import { Utils } from '../../utils/utils';
var ConversationSortOptionsController = (function () {
    function ConversationSortOptionsController(iscLabelService, $timeout) {
        this.iscLabelService = iscLabelService;
        this.$timeout = $timeout;
    }
    ConversationSortOptionsController.prototype.$onInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4, this.getSortOptions()];
                    case 1:
                        _a.sortDropdownList = _c.sent();
                        _b = this;
                        return [4, this.getGroupByMemberLabel()];
                    case 2:
                        _b.groupByMemberLabel = _c.sent();
                        return [2];
                }
            });
        });
    };
    ConversationSortOptionsController.prototype.getSortOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var constants, keys, sortDropdownList, _i, keys_1, key, value, labelName, label;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        constants = this.sortOptions;
                        keys = Object.keys(constants);
                        sortDropdownList = [];
                        _i = 0, keys_1 = keys;
                        _a.label = 1;
                    case 1:
                        if (!(_i < keys_1.length)) return [3, 4];
                        key = keys_1[_i];
                        value = constants[key];
                        labelName = 'LabelConversationSort' + key[0].toUpperCase() + key.slice(1);
                        return [4, this.iscLabelService.getLabelValue(labelName)];
                    case 2:
                        label = (_a.sent()) || labelName;
                        sortDropdownList.push({
                            label: label,
                            value: value,
                        });
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3, 1];
                    case 4: return [2, sortDropdownList];
                }
            });
        });
    };
    ConversationSortOptionsController.prototype.onDropdownChange = function (value) {
        var _this = this;
        this.$timeout(function () { return _this.onChange({ value: value }); }, 600);
    };
    ConversationSortOptionsController.prototype.groupedByMemberChecked = function () {
        var _this = this;
        var sortOption = this.model;
        this.$timeout(function () { return _this.onChange({ value: sortOption }); }, 600);
    };
    ConversationSortOptionsController.prototype.isMobileView = function () {
        return Utils.isMobileView();
    };
    ConversationSortOptionsController.prototype.getGroupByMemberLabel = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.iscLabelService.getLabelValue('LabelConversationGroupByMember')];
                    case 1: return [2, (_a.sent()) || 'Group by member'];
                }
            });
        });
    };
    ConversationSortOptionsController.$inject = ['iscLabelService', '$timeout'];
    return ConversationSortOptionsController;
}());
export { ConversationSortOptionsController };
