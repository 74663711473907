import { IndentHotkey } from './IndentHotkey';
import { OutdentHotkey } from './outdentHotkey';
var IndentOutdentHotkeys = (function () {
    function IndentOutdentHotkeys($document) {
        this.$document = $document;
    }
    IndentOutdentHotkeys.prototype.init = function (richInput) {
        richInput.subscribeToKeyDown(new IndentHotkey(this.$document));
        richInput.subscribeToKeyDown(new OutdentHotkey(this.$document));
    };
    IndentOutdentHotkeys.$inject = ['$document'];
    return IndentOutdentHotkeys;
}());
export default IndentOutdentHotkeys;
