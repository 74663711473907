import { CacheService } from './services/cache.service';
CustomStylesConfig.$inject = ['$mdThemingProvider', '_', '$provide'];
function CustomStylesConfig($mdThemingProvider, _, $provide) {
    $mdThemingProvider.generateThemesOnDemand(true);
    $mdThemingProvider.alwaysWatchTheme(true);
    _.forEach(_.range(0, 10), (index) => {
        $mdThemingProvider.theme(`pageTheme${index}`);
    });
    $mdThemingProvider.theme('secondary');
    $provide.value('mdThemingProvider', $mdThemingProvider);
}
export class PageThemesService {
    constructor($mdTheming, _) {
        this.$mdTheming = $mdTheming;
        this._ = _;
        this.themes = {};
        this.pageDesign = [];
        this.homePageTheme = 'pageTheme0';
        this.defaultGreyTheme = 'greydefault';
    }
    getPageTheme(guid) {
        if (!guid) {
            return this.homePageTheme;
        }
        const themeName = this.themes[guid];
        if (themeName) {
            this.$mdTheming.generateTheme(themeName);
            return themeName;
        }
        return 'secondary';
    }
    addPageTheme(guid, themeName) {
        this.themes[guid] = themeName;
    }
    getColorForPage(pageGuid) {
        const pageDesign = this._.find(this.pageDesign, {
            PageGuid: pageGuid,
        });
        if (pageDesign) {
            return pageDesign.Color;
        }
        return undefined;
    }
}
PageThemesService.$inject = ['$mdTheming', '_'];
GetPageThemeFilter.$inject = ['pageThemes'];
function GetPageThemeFilter(pageThemes) {
    return (pageGuid) => pageThemes.getPageTheme(pageGuid);
}
customStylesFactory.$inject = ['squareService', '$q', '$parse', '$http', '_',
    '$mdTheming', 'pageThemes', 'mdThemingProvider', 'tinycolor', '__env', 'serverConstants'];
function customStylesFactory(squareService, $q, $parse, $http, _, $mdTheming, pageThemes, mdThemingProvider, tinycolor, __env, serverConstants) {
    const deferred = $q.defer();
    return {
        run,
        promise: deferred.promise,
    };
    function run(useRtlStyles) {
        const styleMatchRegex = /\/\.tmp\/site\.css|app-.+\.css$/;
        const getSquareDesign = squareService.selectSquareDesign().then((response) => response.data);
        const iscStyleElement = _.chain(document.getElementsByTagName('link'))
            .filter((link) => link.href.match(styleMatchRegex))
            .head()
            .value();
        $q.all([getSquareDesign, getCss(useRtlStyles)])
            .then((results) => {
            const squareDesign = results[0];
            const css = results[1];
            pageThemes.pageDesign = squareDesign.PageDesign;
            if (iscStyleElement && iscStyleElement.parentNode) {
                iscStyleElement.parentNode.removeChild(iscStyleElement);
            }
            updateMaterialThemes(squareDesign);
            updateStyles(squareDesign, css);
            appendUpdatedCss(squareDesign.CustomCSS);
            document.body.style.setProperty('--square-primary', squareDesign.SquareDesign.BaseColor1);
            const images = [];
            if (squareDesign.SquareDesign.HasLogoTopBar) {
                images.push(serverConstants.imagesConstants.logoTopBar);
            }
            if (squareDesign.SquareDesign.HasHeaderLogo) {
                images.push(serverConstants.imagesConstants.headerLogo);
            }
            if (squareDesign.SquareDesign.HasHeaderDesktop) {
                images.push(serverConstants.imagesConstants.headerDesktop);
            }
            if (squareDesign.SquareDesign.HasHeaderMobile) {
                images.push(serverConstants.imagesConstants.headerMobile);
            }
            if (squareDesign.SquareDesign.HasBackground) {
                images.push(serverConstants.imagesConstants.background);
            }
            CacheService.setCache({ key: 'Images', value: JSON.stringify(images) });
            $mdTheming.setBrowserColor({
                theme: 'default',
                palette: 'primary',
                hue: '800',
            });
            deferred.resolve();
        });
        function updateStyles(squareDesign, css) {
            const variablesAvailableInCss = _.extend({}, squareDesign, {
                defaultPalette: $mdTheming.PALETTES.defaultPalette,
            });
            const updatedCss = css.replace(/["']?\{\{([^{}]+)\}\}["']?/g, (match, tag) => resolveTagValue(variablesAvailableInCss, tag));
            appendUpdatedCss(updatedCss);
        }
        const cachedValues = {};
        function resolveTagValue(squareDesign, tag) {
            if (Object.prototype.hasOwnProperty.call(cachedValues, tag)) {
                return cachedValues[tag];
            }
            const value = $parse(tag)(squareDesign);
            if (typeof value !== 'undefined') {
                cachedValues[tag] = value;
                return value;
            }
            return 'red';
        }
        function appendUpdatedCss(css) {
            const style = document.createElement('style');
            style.type = 'text/css';
            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            }
            else {
                style.appendChild(document.createTextNode(css));
            }
            const head = document.head || document.getElementsByTagName('head')[0];
            head.appendChild(style);
        }
        function updateMaterialThemes(squareDesign) {
            _.forEach(squareDesign.PageDesign, (page, index) => {
                const themeName = `pageTheme${index}`;
                const paletteName = `pagePalette${index}`;
                generatePalette(themeName, paletteName, page.Color);
                pageThemes.addPageTheme(page.PageGuid, themeName);
            });
            generatePalette('default', 'defaultPalette', squareDesign.SquareDesign.BaseColor1);
            generatePalette('secondary', 'secondaryPalette', squareDesign.SquareDesign.BaseColor2 || squareDesign.SquareDesign.BaseColor1);
            generatePalette(pageThemes.defaultGreyTheme, 'greyPalette', '#757575');
            $mdTheming.generateTheme('default');
            $mdTheming.generateTheme('secondary');
            $mdTheming.generateTheme(pageThemes.homePageTheme);
            $mdTheming.generateTheme(pageThemes.defaultGreyTheme);
        }
        function generatePalette(themeName, paletteName, baseColor) {
            const color = tinycolor(baseColor);
            const paletteMap = extendPalette('grey', {
                50: color.clone().lighten(25).toHexString(),
                100: color.clone().lighten(20).toHexString(),
                200: color.clone().lighten(15).toHexString(),
                300: color.clone().lighten(10).toHexString(),
                400: color.clone().lighten(5).toHexString(),
                500: baseColor,
                600: color.clone().darken(5).toHexString(),
                700: color.clone().darken(10).toHexString(),
                800: color.clone().darken(15).toHexString(),
                900: color.clone().darken(20).toHexString(),
                A100: color.clone().lighten(10).toHexString(),
                A200: baseColor,
                A400: color.clone().darken(6).toHexString(),
                A700: color.clone().darken(12).toHexString(),
            });
            $mdTheming.PALETTES[paletteName] = paletteMap;
            mdThemingProvider._PALETTES[paletteName] = paletteMap;
            mdThemingProvider.theme(themeName)
                .primaryPalette(paletteName)
                .accentPalette(paletteName);
        }
        function extendPalette(name, object) {
            const newPalette = angular.copy($mdTheming.PALETTES[name]);
            _.forEach(object, (value, key) => {
                const color = tinycolor(value);
                const rgb = color.toRgb();
                newPalette[key].hex = value;
                newPalette[key].value = [rgb.r, rgb.g, rgb.b];
                if (color.darken(3).isDark()) {
                    newPalette[key].contrast = [255, 255, 255, 1];
                }
                else {
                    newPalette[key].contrast = [0, 0, 0, 1];
                }
            });
            return newPalette;
        }
        function getCss(rtlStyles) {
            const stylesJsonUrl = rtlStyles
                ? '/styles-rtl.json'
                : '/styles-ltr.json';
            return $http({
                method: 'GET',
                url: stylesJsonUrl,
            }).then((response) => $http({
                method: 'GET',
                url: response.data.filename,
                headers: {
                    'Content-Type': 'text/css',
                },
            }).then((r) => r.data));
        }
    }
}
angular.module('insitesApp.core')
    .config(CustomStylesConfig)
    .service('pageThemes', PageThemesService)
    .filter('getPageTheme', GetPageThemeFilter)
    .factory('customStylesFactory', customStylesFactory);
