import { __awaiter, __generator } from "tslib";
import * as angular from 'angular';
var IscCarouselController = (function () {
    function IscCarouselController($timeout, $scope, $window, $element, _, iscStimuliTypeService, serverConstants, $q) {
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.$window = $window;
        this.$element = $element;
        this._ = _;
        this.iscStimuliTypeService = iscStimuliTypeService;
        this.serverConstants = serverConstants;
        this.$q = $q;
        this.currentIndex = 0;
        this.currentMarginValue = 0;
        this.radioButtonIndex = 0;
        this.transitionsEnabled = true;
        this.slideWidth = 614;
        this.slideHeight = 768;
        this.loaded = false;
        this.showRightArrow = false;
        this.showLeftArrow = false;
        this.showContent = false;
        this.playerVars = {
            autoplay: 0,
        };
        this.videoDefaultUrl = '/images/bg-video.png';
    }
    IscCarouselController.prototype.$onInit = function () {
        var _this = this;
        this.conversationStimuliType = this.iscStimuliTypeService.getTypes();
        this.resetObjects();
        this.onDataChange();
        this.$scope.$watchCollection(function () { return _this.stimuli; }, function (newVal, oldVal) {
            if (newVal !== oldVal) {
                _this.onDataChange();
            }
        });
        setTimeout(function () {
            _this.waitForImagesToLoad().then(function () {
                _this.resizeHandler();
            });
        }, 0);
        angular.element(this.$window).bind('resize', function () {
            _this.resizeHandler();
        });
    };
    IscCarouselController.prototype.resizeHandler = function () {
        var currentCarouselWidth = this.carouselParent[0].offsetWidth;
        if (currentCarouselWidth > 0 && currentCarouselWidth <= this.slideWidth) {
            this.setElementSize(currentCarouselWidth);
        }
    };
    IscCarouselController.prototype.waitForImagesToLoad = function () {
        return __awaiter(this, void 0, void 0, function () {
            var imagesNodeList, images;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        imagesNodeList = this.carousel[0].querySelectorAll('.slide img');
                        images = Array.from(imagesNodeList);
                        return [4, this.$q.all(images.map(function (img) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4, this.waitForImageToLoad(img)];
                                        case 1:
                                            _a.sent();
                                            return [2];
                                    }
                                });
                            }); }))];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    IscCarouselController.prototype.waitForImageToLoad = function (img) {
        return this.$q(function (resolve, reject) {
            var tmpImg = new Image();
            tmpImg.onload = resolve;
            tmpImg.onerror = reject;
            tmpImg.src = img.src;
        });
    };
    IscCarouselController.prototype.resetObjects = function () {
        this.maxImageHeight = 0;
        this.stimuli.forEach(function (stimulus) { return stimulus.videoPlayer = null; });
    };
    IscCarouselController.prototype.registerElement = function () {
        this.carousel = this.$element.find('carousel');
        this.progressCircular = this.$element.find('md-progress-circular');
        this.carouselParent = this.carousel.parent();
        this.carouselParent.css('max-width', this.maxWidth + 'px');
        var slidesContainerHtml = this.carousel.children()[0];
        this.slidesContainer = angular.element(slidesContainerHtml);
    };
    IscCarouselController.prototype.onDataChange = function () {
        var _this = this;
        if (!this.loaded) {
            this.loaded = true;
            this.registerElement();
        }
        if (this.isDataInvalidOrTooSmall()) {
            return;
        }
        this.$timeout(function () {
            _this.updateSlidesContainerWidth();
        });
    };
    IscCarouselController.prototype.updateSlidesContainerWidth = function () {
        var _this = this;
        this.loadContent().then(function () {
            var carouselParentCurrentElementWidth = _this.carouselParent[0].offsetWidth;
            var progressCircularElementWidth = _this.progressCircular[0].offsetWidth + (2 * _this.progressCircular[0].offsetLeft) - (2 * 5);
            var currentElementWidth = carouselParentCurrentElementWidth > 0 ? carouselParentCurrentElementWidth : progressCircularElementWidth;
            currentElementWidth = (currentElementWidth > 0 && currentElementWidth < _this.slideWidth) ? currentElementWidth : _this.slideWidth;
            _this.maxImageHeight = _this.maxImageHeight < _this.minHeight ? _this.minHeight : _this.maxImageHeight;
            _this.slideHeight = _this.maxImageHeight > _this.maxHeight ? _this.maxHeight : _this.maxImageHeight;
            _this.setElementSize(currentElementWidth);
            _this.restartFromFirstItem();
            _this.showContent = true;
        });
    };
    IscCarouselController.prototype.setElementSize = function (currentWidth) {
        this.currentWidth = currentWidth;
        this.currentHeight = currentWidth * this.slideHeight / this.slideWidth;
        this.currentHeight = this.currentHeight > this.maxHeight ? this.maxHeight : this.currentHeight;
        this.scaleContent();
        this.resizeSlides();
        var newSlidesContainerWidth = this.currentWidth * this.stimuli.length;
        this.slidesContainer.css('width', newSlidesContainerWidth + 'px');
        this.scaleMargin(newSlidesContainerWidth);
        this.currentSlidesContainerWidth = newSlidesContainerWidth;
    };
    IscCarouselController.prototype.loadContent = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.promiseArray = [];
                angular.forEach(this.stimuli, function (stimulus, key) {
                    var guid = stimulus.guid ? stimulus.guid : stimulus.temporaryGuid;
                    if (_this.isImage(stimulus)) {
                        if (stimulus.value) {
                            stimulus.url = stimulus.value;
                        }
                        else {
                            if (stimulus.file) {
                                stimulus.url = URL.createObjectURL(stimulus.file);
                            }
                            _this.promiseArray.push(_this.loadingImage(stimulus.url || _this.videoDefaultUrl, guid));
                        }
                    }
                    else if (_this.isVideo(stimulus)) {
                        if (!stimulus.url) {
                            if (stimulus.file) {
                                stimulus.url = URL.createObjectURL(stimulus.file);
                                _this.promiseArray.push(_this.loadingVideo(stimulus.url));
                            }
                            else {
                                _this.promiseArray.push(_this.loadingImage(_this.videoDefaultUrl, guid));
                            }
                        }
                        else {
                            _this.promiseArray.push(_this.loadingVideo(stimulus.url));
                        }
                    }
                });
                return [2, this.$q.all(this.promiseArray)];
            });
        });
    };
    IscCarouselController.prototype.loadingImage = function (src, key) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2, this.$q(function (resolve) {
                        var img = document.getElementById(key);
                        if (img) {
                            img.src = src;
                            img.addEventListener('load', function () {
                                var realImageHeight = (img.height * _this.maxWidth) / img.width;
                                if (realImageHeight > _this.maxImageHeight) {
                                    _this.maxImageHeight = realImageHeight;
                                }
                                resolve(img);
                            });
                        }
                        else {
                            resolve(null);
                        }
                    })];
            });
        });
    };
    IscCarouselController.prototype.loadingVideo = function (src) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$q(function (resolve) {
                        var videoElement = document.createElement('video');
                        var sourceMP4 = document.createElement('source');
                        sourceMP4.type = 'video/mp4';
                        sourceMP4.src = src;
                        videoElement.appendChild(sourceMP4);
                        videoElement.addEventListener('loadstart', function () {
                            resolve(videoElement);
                        });
                    })];
            });
        });
    };
    IscCarouselController.prototype.scaleContent = function () {
        this.carousel.css('width', this.currentWidth + 'px');
        this.carousel.css('max-height', this.maxHeight + 'px');
    };
    IscCarouselController.prototype.resizeSlides = function () {
        var _this = this;
        var slides = this.carousel[0].querySelectorAll('.slide');
        this._.each(slides, function (element) {
            var slide = angular.element(element);
            slide.css('width', _this.currentWidth + 'px');
            slide.css('max-height', _this.maxHeight + 'px');
            var mediaElementHtml = slide.children()[0];
            var mediaElement = angular.element(mediaElementHtml);
            if (mediaElementHtml) {
                var videoHtml = mediaElementHtml.getElementsByTagName('video')[0];
                if (videoHtml) {
                    var videoWrapper = angular.element(mediaElementHtml.children[0]);
                    var video = angular.element(videoHtml);
                    var videoWidth = _this.currentWidth - 2;
                    video.css('position', 'static');
                    video.css('width', 100 + '%');
                    videoWrapper.css('width', videoWidth + 'px');
                }
                else {
                    var originalWidth = mediaElementHtml.width;
                    mediaElementHtml.width = mediaElementHtml.width || _this.currentWidth;
                    mediaElementHtml.height = mediaElementHtml.height || _this.currentHeight;
                    if (mediaElementHtml.width && mediaElementHtml.nodeName === 'IMG') {
                        mediaElement.css('width', _this.currentWidth + 'px');
                        mediaElement.css('height', 'auto');
                        var ratio = _this.currentWidth / mediaElementHtml.width;
                        var newHeight = mediaElementHtml.height * ratio;
                        if (newHeight > _this.currentHeight) {
                            mediaElement.css('height', newHeight + 'px');
                            mediaElement.css('width', originalWidth !== 0 ? (originalWidth * ratio) + 'px' : 'auto');
                        }
                    }
                    if (mediaElementHtml.width && mediaElementHtml.nodeName === 'IFRAME') {
                        mediaElement.css('width', _this.currentWidth - 2 + 'px');
                        if (_this.currentHeight > _this.minHeight) {
                            mediaElement.css('height', _this.minHeight + 'px');
                        }
                    }
                }
            }
        });
    };
    IscCarouselController.prototype.scaleMargin = function (newSlidesContainerWidth) {
        if (this.currentSlidesContainerWidth &&
            this.currentSlidesContainerWidth !== newSlidesContainerWidth) {
            this.currentMarginValue =
                this.currentMarginValue * newSlidesContainerWidth;
            this.currentMarginValue =
                this.currentMarginValue / this.currentSlidesContainerWidth;
            this.disableTransitions();
            this.applyMargin();
            this.enableTransitions();
        }
    };
    IscCarouselController.prototype.restartFromFirstItem = function () {
        if (!this.currentWidth) {
            return;
        }
        this.disableTransitions();
        this.currentMarginValue = 0;
        this.applyMargin();
        this.currentIndex = 0;
        this.radioButtonIndex = this.currentIndex;
        this.enableTransitions();
        this.showArrow();
    };
    IscCarouselController.prototype.navigate = function (position) {
        if (this.isDataInvalidOrTooSmall()) {
            return;
        }
        var index = this.currentIndex + position;
        if (index >= 0 && index < this.stimuli.length) {
            this.checkAndStopPlaying(this.currentIndex);
            this.currentIndex = index;
            this.radioButtonIndex = this.currentIndex;
            this.currentMarginValue = this.currentMarginValue - (this.currentWidth * position);
            this.applyMargin();
            this.showArrow();
        }
    };
    IscCarouselController.prototype.checkAndStopPlaying = function (index) {
        var _a;
        var stimulus = this.stimuli[index];
        if (stimulus) {
            if (this.isVideo(stimulus)) {
                (_a = stimulus.videoPlayer) === null || _a === void 0 ? void 0 : _a.pause();
            }
            else if (this.isYoutubeVideo(stimulus)) {
                try {
                    if (stimulus.videoPlayer) {
                        if (stimulus.videoPlayer.pauseVideo) {
                            stimulus.videoPlayer.pauseVideo();
                        }
                        else if (stimulus.videoPlayer.h && stimulus.videoPlayer.h.contentWindow) {
                            stimulus.videoPlayer.h.contentWindow
                                .postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo', args: '' }), '*');
                        }
                    }
                }
                catch (_b) {
                }
            }
        }
    };
    IscCarouselController.prototype.applyMargin = function () {
        var marginProperty = getComputedStyle(this.slidesContainer[0]).direction === 'rtl' ? 'margin-right' : 'margin-left';
        this.slidesContainer.css(marginProperty, this.currentMarginValue + 'px');
    };
    IscCarouselController.prototype.disableTransitions = function () {
        this.slidesContainer.css('transition', 'none');
        this.transitionsEnabled = false;
    };
    IscCarouselController.prototype.enableTransitions = function () {
        var _this = this;
        this.$timeout(function () {
            _this.slidesContainer.css('transition', 'margin 0.5s ease-in-out');
            _this.transitionsEnabled = true;
        }, 200);
    };
    IscCarouselController.prototype.showArrow = function () {
        this.showRightArrow = true;
        this.showLeftArrow = true;
        if (this.currentIndex === 0) {
            this.showLeftArrow = false;
        }
        if (this.currentIndex === this.stimuli.length - 1) {
            this.showRightArrow = false;
        }
    };
    IscCarouselController.prototype.removeStimulus = function () {
        var removedItem = this.stimuli[this.currentIndex];
        this._.pull(this.stimuli, removedItem);
        if (this.onFileRemoved) {
            this.onFileRemoved({ stimulus: removedItem });
        }
        this.resetObjects();
    };
    IscCarouselController.prototype.onRadioButtonClick = function () {
        var multiplier;
        if (this.radioButtonIndex > this.currentIndex) {
            multiplier = this.radioButtonIndex - this.currentIndex;
            this.currentMarginValue -= this.currentWidth * multiplier;
        }
        else {
            multiplier = this.currentIndex - this.radioButtonIndex;
            this.currentMarginValue += this.currentWidth * multiplier;
        }
        this.checkAndStopPlaying(this.currentIndex);
        this.currentIndex = this.radioButtonIndex;
        this.applyMargin();
        this.showArrow();
    };
    IscCarouselController.prototype.isDataInvalidOrTooSmall = function () {
        return (!this.stimuli || this.stimuli.length === 0);
    };
    IscCarouselController.prototype.isImage = function (stimulus) {
        return stimulus.type === this.conversationStimuliType.image;
    };
    IscCarouselController.prototype.isVideo = function (stimulus) {
        return stimulus.type === this.conversationStimuliType.video;
    };
    IscCarouselController.prototype.isYoutubeVideo = function (stimulus) {
        return stimulus.type === this.conversationStimuliType.youtube;
    };
    IscCarouselController.prototype.isVimeoVideo = function (stimulus) {
        return stimulus.type === this.conversationStimuliType.vimeo;
    };
    IscCarouselController.prototype.isStimuliDownloadSupported = function () {
        var stimulus = this.stimuli[this.currentIndex];
        return !this.isEditMode && (stimulus.type === this.conversationStimuliType.image ||
            stimulus.type === this.conversationStimuliType.video);
    };
    IscCarouselController.prototype.getVideoType = function (stimulus) {
        if (stimulus && stimulus.file) {
            return stimulus.file.type;
        }
        return null;
    };
    IscCarouselController.prototype.getUploadProgress = function (stimulus) {
        if (this.uploadProgress) {
            return this.uploadProgress({ stimulus: stimulus });
        }
        return null;
    };
    IscCarouselController.prototype.getUploadAgain = function (stimulus) {
        if (this.uploadAgain) {
            return this.uploadAgain({ stimulus: stimulus });
        }
    };
    IscCarouselController.prototype.showStimuliPlaceholder = function (slideItem) {
        return !slideItem.url
            && (this.isVideo(slideItem) || this.isImage(slideItem))
            && !slideItem.IsBroken;
    };
    IscCarouselController.prototype.showPreparingForPlayback = function (slideItem) {
        return this.isVideo(slideItem);
    };
    IscCarouselController.prototype.isVideoLoaded = function (slideItem) {
        if (this.loaded && slideItem.thumbnailUrl !== 'images/bg-video.png') {
            return true;
        }
        return false;
    };
    IscCarouselController.prototype.isUploading = function (stimulus) {
        var isUploadProgress = this.getUploadProgress(stimulus) != null;
        if (isUploadProgress && stimulus.IsBroken) {
            stimulus.IsBroken = false;
        }
        return isUploadProgress;
    };
    IscCarouselController.$inject = ['$timeout', '$scope', '$window', '$element', '_', 'iscStimuliTypeService', 'serverConstants', '$q'];
    return IscCarouselController;
}());
export { IscCarouselController };
