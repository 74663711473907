import { createInsitesApp } from '@/angular/app.module';
import { initPlatformHealth, initStores, setCustomStyles, setLanguage, setSquareInfo, setupAppInsights, isInitialized } from '@/common/utils/startup';
import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from '../routes/route-names.enum';
import { validateCurrentToken } from '@api/services/query/default/ParticipantService';
import { useAuthenticationStore } from '@/store/authentication-store';

// The reason why we do this in a guard instead of in main.ts is
// because we need to wait for the router to be ready before we can get the current route.
// This would mean that the other guards would already run before this code is executed. Which is not what we want.
// By putting it in a guard, we can make sure that this code is executed before any other guard.
export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
  // Don't do anything if already initialized
  if (isInitialized.value) {
    // Ideally we never reach this point since the guard should be deregistered after `isInitialized` is set to true.
    // But just in case, we return here to prevent the guard from running again.
    return;
  }

  // Angular
  // Since the not found page is still an Angular page, we have to initialize Angular here already.
  // Once the not found page is migrated to Vue, this can be moved after the "noSquareInfo" check.
  await createInsitesApp();

  // When going to the NotFound page with noSquareInfo query param present, don't initialize anything
  if (to.name?.toString() === RouteNames.NotFound && to.query.noSquareInfo === 'true') {
    isInitialized.value = true;
    return;
  }

  if(!await setSquareInfo()){
    isInitialized.value = true;
    return false;
  }
  await initStores(to);
  await setLanguage();
  await setCustomStyles();
  setupAppInsights();
  initPlatformHealth();

  isInitialized.value = true;

  // After token is loaded in, validate token. This only happens on the first page load (new window or refresh)
  // Endpoint throws 401 if token is invalid so it redirects to login
  const authStore = useAuthenticationStore();
  if(authStore.token){
    await validateCurrentToken();
  }
};
