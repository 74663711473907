import { SquareNotActiveController } from './square-not-active.controller';
const template = "<div class=\"square-not-active-container\">\n    {{vm.message}}\n</div>";
export const SquareNotActiveComponent = {
    controller: SquareNotActiveController,
    template,
    controllerAs: 'vm',
    bindings: {
        squareStatus: '=',
    },
};
