import { __awaiter } from "tslib";
import * as _ from 'lodash';
export class ConfirmitEmbedController {
    constructor($q, customStylesFactory, $element, confirmitEmbedCustomElementService, confirmitEmbedEventsService, mediaservice, languageService) {
        this.$q = $q;
        this.customStylesFactory = customStylesFactory;
        this.$element = $element;
        this.confirmitEmbedCustomElementService = confirmitEmbedCustomElementService;
        this.confirmitEmbedEventsService = confirmitEmbedEventsService;
        this.mediaservice = mediaservice;
        this.languageService = languageService;
        this.minIframeHeight = this.mediaservice.isIOSDeviceType ? 2000 : 48;
        this.cleanupFunctions = [];
    }
    $onInit() {
        this.$q.all([
            this.initIframe(),
            this.customStylesFactory.promise,
        ]).then((results) => {
            const iframe = results[0];
            this.watchIframeHeight(iframe);
            return this.addStylesToIframe(iframe).then(() => iframe);
        }).then((iframe) => {
            this.createConfirmitMutationObserver(iframe.contentWindow);
            this.insertConfirmitInIframe(iframe, this.surveyUrl);
        });
    }
    $onDestroy() {
        _.each(this.cleanupFunctions, (cleanupFunction) => {
            cleanupFunction();
        });
    }
    createConfirmitMutationObserver(win) {
        if (win) {
            const observer = new MutationObserver(() => {
                this.confirmitEmbedCustomElementService.updateElements(win);
                this.confirmitEmbedEventsService.checkForComplete(win, this.onSurveyComplete);
                this.confirmitEmbedEventsService.ensureScrollTopOnBackOrForwardButtonClicks(win);
            });
            observer.observe(win.document.body, {
                subtree: true,
                attributes: false,
                childList: true,
                characterData: false,
            });
            this.cleanupFunctions.push(() => {
                observer.disconnect();
            });
        }
    }
    initIframe() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$q((resolve) => {
                const iframe = this.$element[0].querySelector('iframe');
                iframe.addEventListener('load', () => {
                    resolve(iframe);
                });
                iframe.src = 'about:blank';
            });
        });
    }
    watchIframeHeight(iframe) {
        iframe.height = `${this.minIframeHeight}px`;
        let oldHeight = this.minIframeHeight;
        const interval = setInterval(() => {
            const confirmitInline = iframe.contentWindow.document.querySelector('.confirmit-inline');
            if (confirmitInline) {
                let height = confirmitInline.getBoundingClientRect().height;
                height = Math.max(this.minIframeHeight, height);
                if (height !== oldHeight) {
                    iframe.height = `${height}px`;
                    oldHeight = height;
                }
            }
        }, 100);
        this.cleanupFunctions.push(() => {
            clearInterval(interval);
        });
    }
    addStylesToIframe(iframe) {
        return __awaiter(this, void 0, void 0, function* () {
            const resultPromises = [];
            const stylesheets = document.querySelectorAll('style');
            _.each(stylesheets, (stylesheet) => {
                const doc = iframe.contentWindow.document;
                const newStyle = doc.createElement('style');
                newStyle.textContent = stylesheet.textContent;
                doc.head.appendChild(newStyle);
            });
            const linkTags = document.querySelectorAll('link[rel=stylesheet]');
            _.each(linkTags, (link) => {
                const doc = iframe.contentWindow.document;
                const newLink = doc.createElement('link');
                if ('onload' in newLink) {
                    const deferred = this.$q.defer();
                    newLink.onload = deferred.resolve;
                    newLink.onerror = deferred.reject;
                    resultPromises.push(deferred.promise);
                }
                newLink.rel = 'stylesheet';
                newLink.href = link.href;
                doc.head.appendChild(newLink);
            });
            iframe.contentWindow.document.querySelector('html').className = 'inline-survey';
            iframe.contentWindow.document.querySelector('body').className = 'inline-survey';
            if (this.languageService.isRtl()) {
                iframe.contentWindow.document.querySelector('html').dir = 'rtl';
            }
            return this.$q.all(resultPromises);
        });
    }
    insertConfirmitInIframe(iframe, surveyUrl) {
        const doc = iframe.contentWindow.document;
        doc.write = (html) => {
            const div = doc.createElement('div');
            div.innerHTML = html;
            _.each(div.childNodes, (node) => {
                doc.body.appendChild(node);
            });
        };
        const script = doc.createElement('script');
        script.src = surveyUrl;
        iframe.contentWindow.document.body.appendChild(script);
    }
}
ConfirmitEmbedController.$inject = ['$q', 'customStylesFactory', '$element', 'confirmitEmbedCustomElementService',
    'confirmitEmbedEventsService', 'mediaservice', 'languageService'];
