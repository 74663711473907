var SequencedCardControlllerBase = (function () {
    function SequencedCardControlllerBase() {
        this.index = 0;
        this.defaultStimuli = {
            ThumbnailUrl: '/images/default-stimuli.png',
            Type: 'image/',
        };
    }
    SequencedCardControlllerBase.prototype.showTwoMoreCardsInStack = function () {
        return this.cardsCount() - this.index - 1 >= 2;
    };
    SequencedCardControlllerBase.prototype.showOneMoreCardInStack = function () {
        return this.cardsCount() - this.index - 1 >= 1;
    };
    SequencedCardControlllerBase.prototype.currentCardIndex = function () {
        return this.index;
    };
    SequencedCardControlllerBase.prototype.cardsCount = function () {
        return this.getSequence().cards.length;
    };
    SequencedCardControlllerBase.prototype.isInStack = function () {
        return this.cardsCount() > 1;
    };
    SequencedCardControlllerBase.prototype.goToPreviousCard = function () {
        this.index--;
        this.currentIndex = this.index;
        this.card = this.getSequence().cards[this.index];
    };
    SequencedCardControlllerBase.prototype.goToNextCard = function () {
        this.index++;
        this.currentIndex = this.index;
        this.card = this.getSequence().cards[this.index];
    };
    SequencedCardControlllerBase.prototype.hasPrevious = function () {
        return this.index >= 1;
    };
    SequencedCardControlllerBase.prototype.hasNext = function () {
        return this.index < this.cardsCount() - 1;
    };
    SequencedCardControlllerBase.prototype.isLocked = function () {
        return this.card.IsLocked;
    };
    Object.defineProperty(SequencedCardControlllerBase.prototype, "sequenceProgress", {
        get: function () {
            return "".concat(this.currentCardIndex() + 1, "/").concat(this.cardsCount());
        },
        enumerable: false,
        configurable: true
    });
    return SequencedCardControlllerBase;
}());
export { SequencedCardControlllerBase };
