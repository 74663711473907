import { IS_ENVIRONMENT_AS_PREFIX, ENVIRONMENT, AZURE_BASE_URL } from '@/common/env.config';
import { useSquareStore } from '@/store/square-store';
import { DateTime } from 'luxon';
import { post, upload } from '../http-service';
import { ResetPasswordResponse, SaveSquareParticipantAvatarResponse } from '@api/models/command';

export interface SaveSquareParticipantAvatarRequest {
  file: File;
  squareParticipantGuid: string;
}

export interface ResetPasswordRequest {
  captchaResponse?: string;
  email: string;
}

export const getParticipantImageUrl = (participantGuid: string, dateModified: DateTime | undefined): string => {
  const squareInfo = useSquareStore().info;
  const envPrefix = IS_ENVIRONMENT_AS_PREFIX ? `${ENVIRONMENT.toLowerCase()}/` : '';
  const avatarUrl = `${AZURE_BASE_URL + envPrefix + squareInfo.clientGuid}`
      + `/${squareInfo.guid}/participant-avatars/${participantGuid}.jpg${dateModified ? `?ts=${dateModified.toMillis()}` : ''}`;

  return avatarUrl;
};

export const saveParticipantAvatar = (request: SaveSquareParticipantAvatarRequest): Promise<SaveSquareParticipantAvatarResponse> =>
  upload<SaveSquareParticipantAvatarResponse>('/ParticipantService/SaveParticipantAvatar', {
    ...request,
  });

export const resetPassword = (resetPasswordRequest: ResetPasswordRequest) => {
  const requestUrlEncoded = new URLSearchParams();
  Object.entries(resetPasswordRequest).forEach(([k, v]) => {
    if (v) {
      requestUrlEncoded.append(k, v);
    }
  });

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  return post<ResetPasswordResponse>('/ParticipantService/ResetPassword', requestUrlEncoded.toString(), undefined, { headers });
};
