var ImageStimuliDialogController = (function () {
    function ImageStimuliDialogController($mdDialog, stimulusGuid, allowDownload, imageUrl) {
        this.$mdDialog = $mdDialog;
        this.stimulusGuid = stimulusGuid;
        this.allowDownload = allowDownload;
        this.imageUrl = imageUrl;
    }
    ImageStimuliDialogController.prototype.close = function () {
        this.$mdDialog.cancel();
    };
    ImageStimuliDialogController.$inject = ['$mdDialog', 'stimulusGuid', 'allowDownload', 'imageUrl'];
    return ImageStimuliDialogController;
}());
export { ImageStimuliDialogController };
