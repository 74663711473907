import { __extends } from "tslib";
import { HotkeySubscription } from '../HotKeySubscription';
var IndentHotkey = (function (_super) {
    __extends(IndentHotkey, _super);
    function IndentHotkey($document) {
        var _this = _super.call(this) || this;
        _this.$document = $document;
        _this.keyCode = 9;
        return _this;
    }
    IndentHotkey.prototype.handler = function (event) {
        this.indent(event);
    };
    IndentHotkey.prototype.indent = function (event) {
        this.$document[0].execCommand('indent', false);
        event.preventDefault();
    };
    return IndentHotkey;
}(HotkeySubscription));
export { IndentHotkey };
