import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';
import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { guidRegex } from '@/router/router.constants';

export const gotoNotificationRoute: RouteRecordRaw = {
  name: RouteNames.GotoNotification,
  path: `/gotonotification/:notificationguid(${guidRegex})/:channelType?`,
  component: getWrappedAngularComponent('gotonotificationComponent'),
  meta: {
    requireLogin: true,
    adSquareHandle: true,
    title: '(LabelWindowTitleHome)',
  },
};
