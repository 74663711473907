import { RouterHelper } from './router-helper';
export class RouterHelperProvider {
    constructor($urlRouterProvider, $stateProvider, $locationProvider, $injector) {
        this.$urlRouterProvider = $urlRouterProvider;
        this.$stateProvider = $stateProvider;
        this.$injector = $injector;
        this.config = {
            docTitle: undefined,
            resolveAlways: {},
        };
        this.configure = (cfg) => angular.extend(this.config, cfg);
        this.$get = RouterHelper.factory(this.config, this.$urlRouterProvider, this.$stateProvider, this.$injector);
        if (!(window.history && window.history.pushState)) {
            window.location.hash = '/';
        }
        $locationProvider.html5Mode({ enabled: true, requireBase: true, rewriteLinks: true }).hashPrefix('!');
    }
}
RouterHelperProvider.$inject = ['$urlRouterProvider', '$stateProvider', '$locationProvider', '$injector'];
