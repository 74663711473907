import { __awaiter, __generator } from "tslib";
import { StimuliPreviewDisplayType } from './../stimuli-preview/isc-stimuli-preview.model';
import * as _ from 'lodash';
import { IscDraftService } from '../../services/activity/draft.service';
import { IscUIUtils } from '../../utils/sharedutils';
var ConversationEditorController = (function () {
    function ConversationEditorController(iscStimuliTypeService, serverConstants, iscSocialStimuliService, iscDraftService, iscTranslate, $scope) {
        this.iscStimuliTypeService = iscStimuliTypeService;
        this.serverConstants = serverConstants;
        this.iscSocialStimuliService = iscSocialStimuliService;
        this.iscDraftService = iscDraftService;
        this.iscTranslate = iscTranslate;
        this.$scope = $scope;
        this.showStimuliWarning = false;
        this.invalidFiles = [];
        this.isMobileBrowser = false;
        this.fileConfiguration = [];
        this.savedAsDraft = false;
        this.savedAsDraftMessage = '';
        this.replyContentWhileSavingDisabled = false;
    }
    ConversationEditorController.prototype.$onInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.config.uploadConfig.isColumnLayout = this.config.uploadConfig.isColumnLayout || false;
                this.stimuliPreviewConfig = {
                    displayType: StimuliPreviewDisplayType.list,
                    isDisabled: this.config.isDisabled,
                };
                this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);
                this.stimuliTypes = this.iscStimuliTypeService.getTypes();
                if (typeof this.stimuli === 'undefined') {
                    this.stimuli = [];
                }
                if (typeof this.attachments === 'undefined') {
                    this.attachments = [];
                }
                this.$scope.$watch(function () { return _this.config.isDisabled; }, function (newValue) {
                    _this.stimuliPreviewConfig.isDisabled = newValue;
                });
                if (this.draftUid) {
                    this.iscDraftService.setOnDraftSaving(this.draftUid, function () {
                        _this.savedAsDraft = true;
                        _this.iscTranslate.replaceLabels('(QualConversationSavingAsDraftMessage)')
                            .then(function (label) {
                            _this.savedAsDraftMessage = label;
                            setTimeout(function () {
                                _this.savedAsDraftMessage = null;
                                _this.savedAsDraft = false;
                            }, 2000);
                        });
                        return true;
                    });
                    this.iscDraftService.setOnDraftSaved(this.draftUid, function (value) {
                        if (value.value && value.value.message) {
                            _this.savedAsDraft = true;
                            var date_1 = new Date();
                            _this.iscTranslate.replaceLabels('(QualConversationSavedAsDraftMessage)')
                                .then(function (label) {
                                _this.savedAsDraftMessage =
                                    "".concat(label, " ").concat(IscDraftService.formatSavedDate(date_1));
                                setTimeout(function () {
                                    _this.savedAsDraftMessage = null;
                                    _this.savedAsDraft = false;
                                }, 2000);
                            });
                        }
                        return true;
                    });
                }
                return [2];
            });
        });
    };
    ConversationEditorController.prototype.iscMentionSearchFn = function (term) {
        return this.iscMentionSearch({ term: term });
    };
    ConversationEditorController.prototype.iscMentionSelectFn = function (item) {
        return this.iscMentionSelect({ item: item });
    };
    ConversationEditorController.prototype.iscHashtagSearchFn = function (term) {
        if (this.iscHashtagSearch) {
            return this.iscHashtagSearch({ term: term });
        }
    };
    ConversationEditorController.prototype.iscHashtagSelectFn = function (item) {
        if (this.iscHashtagSelect) {
            return this.iscHashtagSelect({ item: item });
        }
    };
    ConversationEditorController.prototype.validPost = function () {
        if (this.config.showTitle) {
            return (this.postTitle && this.postTitle.length > 0)
                && (this.message && this.message.length > 0);
        }
        else {
            return (this.message && this.message.length > 0)
                || (this.stimuli && this.stimuli.length > 0)
                || (this.attachments && this.attachments.length > 0);
        }
    };
    ConversationEditorController.prototype.fileAddedHandler = function (data) {
        if (data && data.uploadedItems) {
            this.autosave();
        }
    };
    ConversationEditorController.prototype.fileRemovedHandler = function (data) {
        if (data && data.removedItem) {
            this.autosave();
        }
    };
    ConversationEditorController.prototype.hasStimuli = function () {
        var _this = this;
        var stimuli = _.filter(this.stimuli, function (stimulus) {
            return stimulus.status !== _this.serverConstants.conversationStimuliStatusConstants.removed;
        });
        if (stimuli.length > 0) {
            return true;
        }
        this.caption = null;
        return false;
    };
    ConversationEditorController.prototype.getUploadProgress = function (stimulus) {
        if (this.uploadProgress) {
            return this.uploadProgress({ stimulus: stimulus });
        }
        return null;
    };
    ConversationEditorController.$inject = [
        'iscStimuliTypeService',
        'serverConstants',
        'iscSocialStimuli',
        'iscDraftService',
        'iscTranslate',
        '$scope'
    ];
    return ConversationEditorController;
}());
export { ConversationEditorController };
