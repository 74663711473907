import { __awaiter } from "tslib";
const loginSsoModal = "﻿<isc-modal-dialog class=\"completion-modal buttons-left\">\n  <isc-modal-close>\n      <isc-button class=\"icon-button\" ng-click=\"vm.cancel()\">\n        <isc-button-icon>\n          <md-icon md-svg-src=\"/images/button-icons/Remove.svg\"></md-icon>\n        </isc-button-icon>\n      </isc-button>\n    </isc-modal-close>\n  <isc-modal-content>\n    <div class=\"title\" isc-translate=\"(LabelLoginSsoRequiredTitle)\"></div>\n    <span isc-translate=\"(LabelLoginSsoRequiredContent)\"></span>\n  </isc-modal-content>\n  <isc-modal-actions layout=\"row\" layout-align=\"space-between\">\n    <isc-button class=\"flat primary cancel\" ng-click=\"vm.cancel()\">\n      <isc-button-label isc-translate=\"(LabelAriaCancel)\"></isc-button-label>\n    </isc-button>\n    <isc-button class=\"primary ok\" ng-click=\"vm.ok()\" layout-align=\"end\">\n      <isc-button-label isc-translate=\"(LabelGoToLogin)\"></isc-button-label>\n    </isc-button>\n  </isc-modal-actions>\n</isc-modal-dialog>";
import { LoginSsoModalController } from './login-sso/login-sso-modal.controller';
export class LoginController {
    constructor($state, imagesFactory, authService, ssoService, loginFlowService, featureToggleService, captchaService, $mdDialog, featureService, serverConstants, __env) {
        this.$state = $state;
        this.imagesFactory = imagesFactory;
        this.authService = authService;
        this.ssoService = ssoService;
        this.loginFlowService = loginFlowService;
        this.featureToggleService = featureToggleService;
        this.captchaService = captchaService;
        this.$mdDialog = $mdDialog;
        this.featureService = featureService;
        this.serverConstants = serverConstants;
        this.__env = __env;
        this.shouldShowFooter = this.featureToggleService.shouldShowFooter;
        this.images = this.imagesFactory.images;
        this.model = {
            Email: '',
            Password: '',
            Unauthorized: false,
            Locked: false,
            Suspended: false,
            InvalidCaptcha: false,
        };
        this.dataLoading = false;
        this.isAzureADFeatureEnabled = false;
        this.showForgotPasswordLink = false;
        this.useSsoLoginPage = this.__env.useSsoLoginPage;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isAzureADFeatureEnabled = yield this.featureService.isMasterFeatureEnabledForSquare(this.serverConstants.featureConstants.azureAdB2CLogin);
            this.showForgotPasswordLink = !this.isAzureADFeatureEnabled;
            this.imagesFactory.applyImages();
            this.authService.signOut(false, false);
        });
    }
    login() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataLoading = true;
            this.model.Unauthorized = false;
            this.model.Locked = false;
            this.model.Suspended = false;
            this.model.InvalidCaptcha = false;
            try {
                const captchaResponse = yield this.captchaService.getCaptchaResponse('login');
                if (!(yield this.loginFlowService.login(this.model.Email, this.model.Password, captchaResponse))) {
                    yield this.loginThroughSsoDialog();
                }
            }
            catch (error) {
                this.handleLoginError(error);
                yield this.captchaService.loadCaptchaIfNecessary('login');
            }
            finally {
                this.dataLoading = false;
            }
        });
    }
    loginThroughSsoDialog() {
        return this.$mdDialog.show({
            controller: LoginSsoModalController,
            controllerAs: 'vm',
            template: loginSsoModal,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                cancel: () => this.$mdDialog.hide(false),
                ok: () => this.$mdDialog.hide(true),
            },
        }).then((login) => {
            if (login) {
            }
        });
    }
    checkDomain() {
        return __awaiter(this, void 0, void 0, function* () {
            const isSsoDomain = yield this.ssoService.isSsoDomain(this.model.Email);
            this.showForgotPasswordLink = !isSsoDomain && !this.useSsoLoginPage;
            if (isSsoDomain) {
                this.startAdminSsoLogin();
            }
        });
    }
    startAdminSsoLogin() {
        const redirectState = this.$state.params.redirectState;
        const redirectParams = this.$state.params.redirectParams;
        const redirectUrl = this.$state.href(redirectState, redirectParams);
        this.authService.startAdminSsoLogin(redirectUrl);
    }
    goToForgotPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.captchaService.hideCaptcha();
            this.$state.go('container.resetPassword');
        });
    }
    handleLoginError(error) {
        if (error.data.error === 'User suspended') {
            this.model.Suspended = true;
        }
        else if (error.data.error === 'User locked') {
            this.model.Locked = true;
        }
        else if (error.data.error === 'Invalid captcha') {
            this.model.InvalidCaptcha = true;
        }
        else {
            this.model.Unauthorized = true;
        }
    }
}
LoginController.$inject = ['$state', 'imagesFactory', 'authService', 'ssoService', 'loginFlowService',
    'featureToggleService', 'captchaService', '$mdDialog', 'featureService', 'serverConstants', '__env'];
