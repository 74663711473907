const core = angular.module('insitesApp.core');
const conf = {
    appErrorPrefix: '[Square Error] ',
    appTitle: 'Square',
};
core.value('config', conf);
core.config(configure);
core.run(run);
configure.$inject = ['$logProvider', 'routerHelperProvider', 'exceptionHandlerProvider', '$provide',
    '$httpProvider', 'ngWigToolbarProvider', '$mdGestureProvider', '__env', '$mdAriaProvider', '$compileProvider',
    '$qProvider', '$sanitizeProvider'];
function configure($logProvider, routerHelperProvider, exceptionHandlerProvider, $provide, $httpProvider, ngWigToolbarProvider, $mdGestureProvider, __env, $mdAriaProvider, $compileProvider, $qProvider, $sanitizeProvider) {
    if ($logProvider.debugEnabled) {
        $logProvider.debugEnabled(__env.enableDebug);
    }
    if ($mdAriaProvider.disableWarnings && !__env.enableDebug) {
        $mdAriaProvider.disableWarnings();
    }
    if ($compileProvider.debugInfoEnabled) {
        $compileProvider.debugInfoEnabled(__env.enableDebug);
    }
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|skydl):/);
    exceptionHandlerProvider.configure(conf.appErrorPrefix);
    routerHelperProvider.configure({ docTitle: `${conf.appTitle}: ` });
    $httpProvider.interceptors.push('httpRequestInterceptor');
    ngWigToolbarProvider.addStandardButton('underline', 'Underline', 'underline', 'fa-underline');
    $mdGestureProvider.skipClickHijack();
    $provide.decorator('iscLabelService', extendIscLabelService);
    $provide.decorator('iscStimuliTypeService', implementIscStimuliTypeService);
    $provide.decorator('iscStimuliService', extendIscStimuliService);
    $provide.decorator('iscSpinnerService', extendIscSpinnerService);
    $provide.decorator('iscEnvInfoService', extendIscEnvInfoService);
    $provide.decorator('iscConfigurationService', extendIscConfigurationService);
    disableHttpCaching($httpProvider);
    $qProvider.errorOnUnhandledRejections(false);
    $sanitizeProvider.addValidAttrs(['data-username']);
}
extendIscLabelService.$inject = ['$delegate', 'constantsfactory'];
function extendIscLabelService($delegate, constantsfactory) {
    $delegate.getLabels = constantsfactory.getLabels.bind(constantsfactory);
    return $delegate;
}
implementIscStimuliTypeService.$inject = ['$delegate', 'serverConstants'];
function implementIscStimuliTypeService($delegate, serverConstants) {
    $delegate.getTypes = () => serverConstants.conversationStimuliTypeConstants;
    return $delegate;
}
extendIscStimuliService.$inject = ['$delegate', 'forumService'];
function extendIscStimuliService($delegate, forumService) {
    $delegate.getStimuliStreamingUrl = forumService.createConversationStimuliStreamingUrl
        .bind(forumService);
    $delegate.getVimeoThumbnailImagePath = forumService.getVimeoThumbnailImagePath
        .bind(forumService);
    $delegate.downloadStimuli = forumService.downloadStimuli.bind(forumService);
    return $delegate;
}
extendIscSpinnerService.$inject = ['$delegate', 'spinnerservice'];
function extendIscSpinnerService($delegate, spinnerservice) {
    $delegate.show = spinnerservice.show.bind(spinnerservice);
    $delegate.hide = spinnerservice.hide.bind(spinnerservice);
    return $delegate;
}
extendIscEnvInfoService.$inject = ['$delegate'];
function extendIscEnvInfoService($delegate) {
    $delegate.getSquareBaseUrlWithSubdomain = () => `${window.location.origin}/`;
    return $delegate;
}
extendIscConfigurationService.$inject = ['$delegate', 'configurationService'];
function extendIscConfigurationService($delegate, configurationService) {
    $delegate.getFileConfiguration = configurationService.getFileConfiguration.bind(configurationService);
    $delegate.getFileTypes = configurationService.getFileTypes.bind(configurationService);
    $delegate.getAcceptedMimeTypesAndExtensions = configurationService.getAcceptedMimeTypesAndExtensions.bind(configurationService);
    return $delegate;
}
run.$inject = ['$anchorScroll', '__env'];
function run($anchorScroll) {
    $anchorScroll.yOffset = 60;
}
function disableHttpCaching($httpProvider) {
    const cacheControlHeader = 'Cache-Control';
    const pragmaHeader = 'Pragma';
    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }
    if (!$httpProvider.defaults.headers.post) {
        $httpProvider.defaults.headers.post = {};
    }
    $httpProvider.defaults.headers.get[cacheControlHeader] = 'no-cache';
    $httpProvider.defaults.headers.get[pragmaHeader] = 'no-cache';
    $httpProvider.defaults.headers.post[cacheControlHeader] = 'no-cache';
    $httpProvider.defaults.headers.post[pragmaHeader] = 'no-cache';
}
export {};
