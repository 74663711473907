import { UiModule } from './../index';
UiModule.config(configureNgWig);
configureNgWig.$inject = ['ngWigToolbarProvider'];
function configureNgWig(ngWigToolbarProvider) {
    ngWigToolbarProvider.addCustomButton('emoji', 'isc-nw-emoji-picker');
    ngWigToolbarProvider.addCustomButton('customlink', 'isc-nw-target-blank-link');
    ngWigToolbarProvider.addCustomButton('addimage', 'isc-nw-add-image');
    ngWigToolbarProvider.addStandardButton('indent', 'Indent', 'indent', 'isc-nw-indent');
    ngWigToolbarProvider.addStandardButton('outdent', 'Outdent', 'outdent', 'isc-nw-outdent');
    ngWigToolbarProvider.addStandardButton('removeFormat', 'Remove format', 'removeFormat', 'isc-nw-remove-format');
}
