import { __awaiter } from "tslib";
import { activityStartedKey } from '../../core/dataservices/activity.service';
import { CacheService } from '../../core/services/cache.service';
import * as _ from 'lodash';
export class AutoLoginController {
    constructor(authService, $stateParams, $state, activityService, logger, activitySequenceDataService, activitySequenceHelperService, iscPages, serverConstants) {
        this.authService = authService;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.activityService = activityService;
        this.logger = logger;
        this.activitySequenceDataService = activitySequenceDataService;
        this.activitySequenceHelperService = activitySequenceHelperService;
        this.iscPages = iscPages;
        this.serverConstants = serverConstants;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const sampleGuid = this.$stateParams.sampleGuid;
            const activityStartedSampleGuid = CacheService.getCacheValue(activityStartedKey);
            if (activityStartedSampleGuid && sampleGuid && activityStartedSampleGuid.toLowerCase() !== sampleGuid.toLowerCase()) {
                this.authService.signOut(true);
            }
            else {
                try {
                    yield this.authService.autologin(sampleGuid);
                    yield this.redirectAfterLogin();
                }
                catch (error) {
                    this.logger.error('Error', error);
                    this.authService.signOut(true);
                }
            }
        });
    }
    redirectAfterLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            this.activitySequenceDataService.isSequencedActivitySample(this.$stateParams.sampleGuid)
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response) {
                    const request = {
                        finishedActivityGuid: null,
                        finishedSampleGuid: this.$stateParams.sampleGuid,
                    };
                    this.activitySequenceHelperService.onSequencedActivityCompleted(request);
                    yield this.redirectToToDoActivities();
                    return;
                }
                const activityCompleteRedirect = yield this.activityService.getActivityCompleteRedirect(this.$stateParams.sampleGuid);
                if (activityCompleteRedirect.ActivityGuid) {
                    this.redirectToState('container.main.profile.activity', {
                        activityGuid: activityCompleteRedirect.ActivityGuid,
                    });
                    return;
                }
                const todoActivitiesCount = yield this.activityService.getTodoNotificationsCount();
                if (todoActivitiesCount > 0) {
                    yield this.redirectToToDoActivities();
                    return;
                }
                this.redirectToState('container.main.home');
            }));
        });
    }
    redirectToState(stateName, stateParams = {}) {
        this.$state.go(stateName, stateParams, { location: 'replace' });
    }
    redirectToToDoActivities() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.iscPages.init();
            const challengesPage = _.find(this.iscPages.mainPages, (item) => item.PageType === this.serverConstants.pageTypeConstants.activities);
            this.redirectToState('container.main.challenges.todo', {
                pageGuid: challengesPage.Guid,
            });
        });
    }
}
AutoLoginController.$inject = ['authService', '$stateParams', '$state', 'activityService', 'logger',
    'activitySequenceDataService', 'activitySequenceHelperService', 'iscPages', 'serverConstants'];
