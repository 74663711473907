import { __awaiter } from "tslib";
import { IscPagesService } from '../../core/isc-pages.service';
import { PriorityNavigationHelper } from '../priority-navigation/priorityNavigationHelper';
import { PageAccessResolver } from '../../core/pageAccessResolver';
export class NavigationMenuController {
    constructor(_, $state, tourService, activityService, imagesFactory, participantFactory, iscPages, authService, $transitions, mediaservice, incentiveService, navigationMenuService, squareFactory, serverConstants, notifications, $scope, $location, mobiletourService, constantsfactory, participantService) {
        this._ = _;
        this.$state = $state;
        this.tourService = tourService;
        this.activityService = activityService;
        this.imagesFactory = imagesFactory;
        this.participantFactory = participantFactory;
        this.iscPages = iscPages;
        this.authService = authService;
        this.$transitions = $transitions;
        this.mediaservice = mediaservice;
        this.incentiveService = incentiveService;
        this.navigationMenuService = navigationMenuService;
        this.squareFactory = squareFactory;
        this.serverConstants = serverConstants;
        this.notifications = notifications;
        this.$location = $location;
        this.mobiletourService = mobiletourService;
        this.constantsfactory = constantsfactory;
        this.participantService = participantService;
        this.pageTypeConstants = this.serverConstants.pageTypeConstants;
        this.images = this.imagesFactory.images;
        this.participant = this.participantFactory;
        this.state = this.$state;
        this.squareInfo = this.squareFactory;
        this.cleanupFunctions = [];
        this.profileActivities = [];
        this.todoProfilesArray = [];
        this.isFirstLogin = false;
        this.arePagesLoaded = false;
        this.resizeActions = () => {
            if (this.mediaservice.isDesktop()) {
                this.navigationMenuService.closeBottomSheet();
            }
        };
        const unsubscribeFromTransition = this.$transitions.onSuccess({}, (transition) => __awaiter(this, void 0, void 0, function* () {
            yield this.setMobileNavigationInfo(transition.$to());
        }));
        this.cleanupFunctions = this.cleanupFunctions || [];
        this.destroyListPageOverflowWatch = $scope.$watch(() => PriorityNavigationHelper.listPageOverflow, () => __awaiter(this, void 0, void 0, function* () {
            yield this.setMobileNavigationInfo($state.current);
        }), true);
        this.cleanupFunctions.push(this.destroyListPageOverflowWatch);
        this.cleanupFunctions.push(unsubscribeFromTransition);
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.setMobileNavigationInfo(this.state.current);
            window.addEventListener('resize', this.resizeActions);
            window.addEventListener('scroll', this.navigationMenuService.scroll);
            this.notificationTypes = this.serverConstants.notificationTypeConstants;
            this.notificationStatuses = this.serverConstants.notificationStatusConstants;
            this.todoActivitiesCount = yield this.activityService.getTodoNotificationsCount();
            this.activityService.todoActivitiesCount.subscribe((count) => __awaiter(this, void 0, void 0, function* () {
                if (count !== 0) {
                    this.todoActivitiesCount = yield this.activityService.getTodoNotificationsCount();
                }
            }));
            this.activityService.profileActivities.subscribe((activities) => {
                this.profileActivities = activities;
                this.todoProfilesArray = this._.filter(this.profileActivities, (act) => act.SampleStatus !== this.serverConstants.sampleStatusConstants.completed);
            });
            this.participantFactory.loadingPromise.then(() => {
                if (this.participantFactory.isFirstLogin()) {
                    if (!this.tourService.alreadyStarted) {
                        this.isFirstLogin = true;
                        this.checkAndStartTour();
                    }
                    else {
                        this.isFirstLogin = false;
                    }
                }
            });
            this.loadPages();
            this.pagesChangedSubscription = this.notifications
                .pagesChanged
                .subscribe(() => {
                PageAccessResolver.clearCache();
                this.loadPages(true);
            });
            this.cleanupFunctions.push(this.pagesChangedSubscription);
            this.notificationsConnectedSubscription = this.notifications
                .connected
                .subscribe((connected) => {
                if (!connected) {
                    return;
                }
                this.loadPages(true);
            });
            this.cleanupFunctions.push(this.notificationsConnectedSubscription);
        });
    }
    $onDestroy() {
        this.iscPages.init.cache.clear();
        this._.each(this.cleanupFunctions, (cleanup) => {
            cleanup();
        });
        PriorityNavigationHelper.emptyThePageList();
        window.removeEventListener('scroll', this.navigationMenuService.scroll);
        this.iscPages.allPages = [];
    }
    isPlatformTourStarted() {
        return this.tourService.isTourStarted;
    }
    gotoPrev() {
        this.navigationMenuService.gotoPrev(this.mobileNavigation.shouldGoToMorePage);
    }
    checkAndStartTour() {
        if (this.isFirstLogin
            && !this.tourService.isTourStarted
            && this.arePagesLoaded) {
            const tabs = this.HCPages;
            const enablePlatformTour = this._.some(tabs, (tabPage) => tabPage.PageType === this.iscPages.PageType.platformTour);
            if (this.mediaservice.isDesktop()) {
                this.tourService.startTour(enablePlatformTour);
                return;
            }
            if (enablePlatformTour) {
                this.mobiletourService.showMobileTourDialog();
            }
        }
    }
    loadPages() {
        return __awaiter(this, arguments, void 0, function* (clearCache = false) {
            if (clearCache) {
                this.iscPages.init.cache.clear();
            }
            yield this.iscPages.init();
            this.listPage = this._.clone(this.iscPages.mainPages);
            this.homePage = this.listPage[0];
            this.HCPages = this._.clone(this.iscPages.helpCenterPages);
            this.listFooter = this._.clone(this.iscPages.footerPages);
            this.isEnabledMyIncentivePage = this._.some(this.iscPages.incentives, ['PageType', this.serverConstants.pageTypeConstants.myIncentive]);
            if (this.listPage.length > 0 && !clearCache) {
                this.arePagesLoaded = true;
                this.checkAndStartTour();
            }
        });
    }
    gotohelpcenter() {
        if (this.$state.current.name.indexOf('container.main.helpcenter.') === -1) {
            this.$state.go('container.main.helpcenter');
        }
    }
    gotoMorePage() {
        this.$state.go('container.main.more');
    }
    getPlatformTourMessageForPage(page) {
        const intro = this.getIntroForPage(page);
        if (intro) {
            return this.formatPlatformTourMessage(page.Name, intro);
        }
        return '';
    }
    formatPlatformTourMessage(title, intro) {
        const classes = 'introjs-title';
        return `<div class="${classes}">${title}</div>${intro}`;
    }
    getIntroForPage(page) {
        if (!page) {
            return '';
        }
        switch (page.PageType) {
            case this.pageTypeConstants.home:
                return '(LabelHomeIntro)';
            case this.pageTypeConstants.mainPageCustom:
                return '';
            default:
                return page.Intro;
        }
    }
    switchInterface() {
        const url = this.authService.getSwitchInterfaceUrl();
        window.open(url, '_blank');
    }
    isActiveMenu(menu) {
        return this.$state.current.name.indexOf(`container.main.${menu}`) === 0;
    }
    setMobileNavigationInfo(state) {
        return __awaiter(this, void 0, void 0, function* () {
            this._mobileNavigationInfo = {
                isHomePageNavigation: true,
                pageTitle: '',
                shouldGoToMorePage: false,
            };
            if (this.state.current.name === 'container.main.home' ||
                this.state.current.name === 'container.main.more' ||
                !PriorityNavigationHelper.listPageOverflow.length) {
                return;
            }
            const isFirstLayer = (state.self || state).prevState === 'container.main.home';
            const overFlowPageDetail = this._.find(PriorityNavigationHelper.listPageOverflow.concat([IscPagesService.profilePage]), (page) => this.$location.$$url !== '/' && !overFlowPageDetail &&
                (page.Link.indexOf(this.$location.$$url) === 0 || this.$location.$$url.indexOf(page.Link) === 0));
            let currentPageDetail = overFlowPageDetail;
            if (!currentPageDetail) {
                currentPageDetail = this._.find(this.iscPages.allPages, (page) => page.Link && this.$location.$$url !== '/' && page.Link !== '/' && !currentPageDetail &&
                    (page.Link.indexOf(this.$location.$$url) === 0 || this.$location.$$url.indexOf(page.Link) === 0));
            }
            const navigationTitle = currentPageDetail ? currentPageDetail.Name : undefined;
            this._mobileNavigationInfo.isHomePageNavigation = isFirstLayer &&
                currentPageDetail && currentPageDetail.Area === this.serverConstants.areaConstants.mainPages && !overFlowPageDetail;
            this._mobileNavigationInfo.shouldGoToMorePage = !this._mobileNavigationInfo.isHomePageNavigation && isFirstLayer;
            this._mobileNavigationInfo.pageTitle = navigationTitle || (state.self || state).mobileTitle;
            this.mobileNavigationPageTitle = yield this.getMobileNavigationPageTitle();
        });
    }
    getLabel(text) {
        if (!text) {
            return '';
        }
        const labelPattern = /\((.*)\)/;
        const labelMatches = text.match(labelPattern);
        return (labelMatches && labelMatches.length >= 2) ? labelMatches[1] : text;
    }
    getMobileNavigationPageTitle() {
        return __awaiter(this, void 0, void 0, function* () {
            const label = this.getLabel(this._mobileNavigationInfo.pageTitle);
            const labelValue = yield this.constantsfactory.getLabelValue(label);
            return labelValue !== '' ? labelValue : this._mobileNavigationInfo.pageTitle;
        });
    }
    get mobileNavigation() {
        return this._mobileNavigationInfo;
    }
    get listPageOverflow() {
        return PriorityNavigationHelper.listPageOverflow;
    }
    get listMainPage() {
        return PriorityNavigationHelper.listMainPage;
    }
    get onBehalfOfUsername() {
        return this.authService.isImpersonating
            ? this.authService.impersonate.username
            : '';
    }
    isDesktop() {
        return this.mediaservice.isDesktop();
    }
    moreButtonClicked() {
        this.state.go('container.main.more');
    }
    menuClick(stateName, stateParams) {
        this.state.go(stateName, stateParams);
    }
}
NavigationMenuController.$inject = ['_', '$state', 'tourService', 'activityService',
    'imagesFactory', 'participantFactory', 'iscPages', 'authService',
    '$transitions', 'mediaservice', 'incentiveService', 'navigationMenuService',
    'squareFactory', 'serverConstants', 'notifications', '$scope', '$location',
    'mobiletourService', 'constantsfactory', 'participantService'];
