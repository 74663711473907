import { UiModule } from './../../index';
import { IscMentionDirective } from './mention.directive';
import { IscMentionMenuComponent } from './mention-menu.component';
import { IscMentionHighlightFilter } from './mention-highlight.filter';
import { IscMentionMenuItemComponent } from './mention-menu-item.component';
import { IscMentionUtil } from './mention.service';
UiModule
    .directive('iscMention', IscMentionDirective.factory())
    .component('iscMentionMenu', IscMentionMenuComponent)
    .component('iscMentionMenuItem', IscMentionMenuItemComponent)
    .service('iscMentionUtil', IscMentionUtil)
    .filter('iscMentionHighlight', IscMentionHighlightFilter);
