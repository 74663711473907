<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { useConstantsStore } from '@/store/constants-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { IscWrapperPage } from '@ui/components';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { CookieConsent, GetCookieConsent } from '@/common/utils/cookie-yes';
import Cookies from 'js-cookie';

const route = useRoute();
const router = useRouter();
const getLabelValue = useConstantsStore().getLabelValue;
const timeOut = parseInt(route.query.timeout as string ?? 0, 10);
const cookieConsent = ref<Partial<CookieConsent>>({});
const areCookiesDisabled = computed(() => cookieConsent.value.action?.toLowerCase() === 'yes' && cookieConsent.value.consent?.toLowerCase() === 'no');
// When redirected from Angular, we need to remove the enclosing parentheses of labels
const trimParentheses = (label: string) => label.replace(/^\(|\)$/g, '');

const landingTitle = ref(getLabelValue(trimParentheses(route.query.landingTitle as string)));
const landingMessage = ref(getLabelValue(trimParentheses(route.query.landingMessage as string)));
let interval: null | ReturnType<typeof setTimeout> = null;

if(timeOut >= 0) {
  setTimeout(() => {
    if (route.query.isExternalLink !== 'true') {
      const query = route.query.redirectParams ? JSON.parse(route.query.redirectParams as string) : {};
      if(route.query.redirectToMobileAppHome === 'true') {
        query.autologin = true; // hack for old mobile app, is this still needed?
      }
      router.push({
        name: route.query.redirectToMobileAppHome === 'true' ? RouteNames.Home : route.query.redirectState as string,
        query,
      });
    } else {
      window.location.href = route.query.redirectState as string;
    }
  }, timeOut || 1);
}

onMounted(() => {
  if(route.query.isCookiePage === 'true') {
    landingTitle.value = '';
    watchCookieChanges();
  }
});

onUnmounted(() => {
  removeInterval();
});

const watchCookieChanges = () => {
  interval = setInterval(() => {
    cookieConsent.value = GetCookieConsent();
    if(!cookieConsent.value) {
      return;
    }

    // The user will need to refresh to bring back the cookiebanner so we can clear the interval here to
    if(!cookieConsent.value.action || !cookieConsent.value.consent) {
      return;
    }
    // If there is an action recorded and he did not give his consent, show another message on the page
    if(cookieConsent.value.action === 'yes' && cookieConsent.value.consent === 'no') {
      landingTitle.value = getLabelValue('LabelCookiePageTitle');
      landingMessage.value = getLabelValue('LabelWrongCookieSettingSelectedMessage');
      removeInterval();
    } else if(cookieConsent.value.action === 'yes' && cookieConsent.value.consent === 'yes') {
      // If there is an action recorded and he did give his consent, redirect him to the activity link
      redirectToIntakeSurvey();
      removeInterval();
    }
  }, 1000);
};

const removeInterval = () => {
  if(interval) {
    clearInterval(interval);
  }
};

const reEnterCookie = () => {
  // Ex: url is squarename.dev-insiteseco.com
  // cookieYes cookie will only be removed when removing by domain '.dev-insiteseco.com'
  // So not squarename.dev-insiteseco.com
  const domainParts = location.hostname.split('.');
  const domain = `.${domainParts.slice(-2).join('.')}`;
  Cookies.remove('cookieyes-consent', { path: '/', domain });
  window.location.reload();
};

const redirectToIntakeSurvey = () => {
  const params = route.query.redirectParams ? JSON.parse(route.query.redirectParams as string) : {};
  router.push({
    name: RouteNames.ActivityIntake,
    params,
    query: {
      ...route.query,
    },
  });
};

</script>

<template>
  <isc-wrapper-page
    :top-margin="false"
    :x-padding="false"
    :y-padding="false"
    class="landing-page q-pa-xl"
  >
    <div
      class="landing-page__title text-insites-blue text-center q-mt-xl"
      :class="$q.screen.gt.xs ? 'text-h1 q-mb-xl q-mx-xl q-px-xl' : 'text-h4 q-mb-lg'"
    >
      {{ landingTitle }}
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="landing-page__message text-subtitle1 text-weight-medium text-center q-pb-xl"
      v-html="landingMessage"
    />

    <div
      v-if="areCookiesDisabled"
      class="text-center q-pb-md"
    >
      <q-btn
        class="landing-page__button"
        :label="getLabelValue('LabelTryAgain')"
        @click="reEnterCookie()"
      />
    </div>

    <!-- eslint-enable vue/no-v-html -->
  </isc-wrapper-page>
</template>


<style lang="scss">
.landing-page {
  &__button {
    color: $light !important;
    background-color: $dark !important;
  }
}
</style>
