import { UiModule } from './../../index';
var EnvInfoService = (function () {
    function EnvInfoService() {
    }
    EnvInfoService.prototype.getSquareBaseUrlWithSubdomain = function () {
        throw new Error('Please override this function with a function that gets the stimuli streaming URL from the backend');
    };
    return EnvInfoService;
}());
export { EnvInfoService };
UiModule.service('iscEnvInfoService', EnvInfoService);
