import { __awaiter } from "tslib";
export class IncentiveService {
    constructor(userService, httpService, notifications, featureService, serverConstants, authService) {
        this.userService = userService;
        this.httpService = httpService;
        this.notifications = notifications;
        this.featureService = featureService;
        this.serverConstants = serverConstants;
        this.authService = authService;
        this.getManualRewardsEarnedList = () => __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.get({
                url: '/IncentiveService/GetManualRewardsEarnedList',
            });
            return response.data;
        });
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const isAuthorized = yield this.authService.isAuthorized();
            if (isAuthorized) {
                this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards)
                    .then((isSavingUpEnabled) => {
                    if (!isSavingUpEnabled) {
                        this.notifications.totalCredits.startWith(() => this.getSquareParticipantTotalCredits());
                    }
                    this.notifications.totalCredits.subscribe((totalCredits) => {
                        this._totalCredits = totalCredits;
                    });
                });
            }
        });
    }
    get totalCredits() {
        return this._totalCredits;
    }
    set totalCredits(value) {
        this._totalCredits = value;
    }
    getProfileIncentiveDetails() {
        return this.httpService.get({
            url: '/IncentiveService/ProfileIncentive',
            params: {
                squareParticipantGuid: this.userService.userGuid(),
            },
        });
    }
    isParticipantAlmostInactive() {
        return this.httpService.get({
            url: '/ParticipantService/SquareParticipantDetails',
            params: {
                squareParticipantGuid: this.userService.userGuid(),
            },
        }).then((response) => response.data.Detail.IsAlmostInactiveInActivities);
    }
    getSquareParticipantTotalCredits() {
        return this.httpService.get({
            url: '/IncentiveService/GetSquareParticipantTotalCredits',
            params: {
                squareParticipantGuid: this.userService.userGuid(),
            },
        }).then((response) => response.data.TotalCredits);
    }
    getPotentialWinnerUserDetails(rewardWinnerGuid) {
        return this.httpService.get({
            url: '/IncentiveService/CheckSquareParticipantIsRewardWinner',
            params: {
                rewardWinnerGuid,
                squareParticipantGuid: this.userService.userGuid(),
            },
        }).then((response) => response.data);
    }
    checkGetRewardLinkValidity(rewardWinnerGuid) {
        return this.httpService.get({
            url: '/IncentiveService/CheckGetRewardLinkValidity',
            params: {
                rewardWinnerGuid,
            },
        }).then((response) => response.data);
    }
    confirmRewardWinnerDetails(request) {
        return this.httpService.post({
            url: '/IncentiveService/ConfirmRewardWinnerDetails',
            data: request,
        });
    }
    getSavingUpRewardRedemptionList() {
        return this.httpService.get({
            url: '/IncentiveService/GetSavingUpRewardRedemptionList',
        }).then((response) => response.data);
    }
    getSavingUpRewardItemList() {
        return this.httpService.get({
            url: '/IncentiveService/GetSavingUpRewardItemList',
        }).then((response) => response.data);
    }
    getSavingUpInvalidationList() {
        return this.httpService.get({
            url: '/IncentiveService/GetSavingUpInvalidationList',
        }).then((response) => response.data);
    }
    getSavingUpRedeemOverview() {
        return this.httpService.get({
            url: '/IncentiveService/GetSavingUpRedeemOverview',
            params: {
                squareParticipantGuid: this.userService.userGuid(),
            },
        }).then((response) => response.data);
    }
    redeemSavingUpReward() {
        return this.httpService.post({
            url: '/IncentiveService/RedeemSavingUpReward',
        });
    }
}
IncentiveService.$inject = ['userService', 'httpService', 'notifications', 'featureService', 'serverConstants', 'authService'];
