const highlightTextFilter = (htmlTextFilter, $sce) => {
    const isMatch = (text, keyword) => {
        const textWithoutHtml = text.replace(/<[^<>]+?>/g, '');
        const match = textWithoutHtml.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
        return match;
    };
    return (text, query, highlight, isHighlightingEnabled = true) => {
        if (highlight && isHighlightingEnabled && text && query && isMatch(text, query)) {
            const tagRegex = /(<\/?[^>]+>)/g;
            const filterRegex = new RegExp(`(${query})`, 'gi');
            text = text.split(tagRegex).map((myString) => {
                if (myString.match(tagRegex)) {
                    return myString;
                }
                return myString.replace(filterRegex, '<span class="highlight-text-match">$1</span>');
            }).join('');
        }
        return $sce.trustAsHtml(text);
    };
};
highlightTextFilter.$inject = ['htmlTextFilter', '$sce'];
angular
    .module('insitesApp.layout')
    .filter('highlightText', highlightTextFilter);
