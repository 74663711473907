import * as _ from 'lodash';
const participantFactory = (__env, $q, squareFactory) => {
    const getParticipantImageUrl = (participantGuid, datemodified) => {
        if (participantGuid === participant.Guid && participant.Avatar !== defaultAvatar) {
            return participant.Avatar;
        }
        let envPrefix = '';
        if (__env.isEnvironmentAsPrefix) {
            envPrefix = `${__env.environment.toLowerCase()}/`;
        }
        const avatarUrl = `${__env.azureBaseUrl + envPrefix + squareFactory.ClientGuid}/${squareFactory.Guid}/participant-avatars/${participantGuid}.jpg${datemodified ? `?ts=${new Date(datemodified).getTime()}` : ''}`;
        if (participant.Guid === participantGuid) {
            participant.Avatar = avatarUrl;
        }
        return avatarUrl;
    };
    const isFirstLogin = () => participant.LastLoginDate === null;
    const setParticipantDefaultAvatar = () => {
        participant.Avatar = defaultAvatar;
    };
    const setSquareParticipantDetails = (details) => {
        if (details) {
            _(Object.keys(details)).forEach((key) => {
                participant[key] = details[key];
            });
        }
        if (participant.Guid) {
            if (participant.HasProfileImage) {
                participant.Avatar = getParticipantImageUrl(participant.Guid, participant.DateModified);
            }
        }
        loadingLoggedInUserDetails.resolve();
    };
    const defaultAvatar = '/images/default-profile.svg';
    const loadingLoggedInUserDetails = $q.defer();
    const participant = {
        Avatar: defaultAvatar,
        DefaultAvatar: defaultAvatar,
        HasProfileImage: false,
        Username: '',
        getParticipantImageUrl,
        loadingPromise: loadingLoggedInUserDetails.promise,
        Guid: '',
        DateModified: null,
        LastLoginDate: null,
        isFirstLogin,
        setParticipantDefaultAvatar,
        Email: '',
        PhoneNumber: '',
        setSquareParticipantDetails,
    };
    return participant;
};
participantFactory.$inject = ['__env', '$q', 'squareFactory'];
angular
    .module('insitesApp.core')
    .factory('participantFactory', participantFactory);
