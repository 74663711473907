export class IdeastreamssoService {
    constructor(notifications, $window) {
        this.notifications = notifications;
        this.$window = $window;
    }
    startListening() {
        this.subscription = this.notifications.ideaStreamLoginFinished.subscribe((redirectUrl) => {
            this.$window.open(redirectUrl, '_self');
        });
    }
    stopListening() {
        if (this.subscription) {
            this.subscription();
            this.subscription = null;
        }
    }
}
IdeastreamssoService.$inject = ['notifications', '$window'];
