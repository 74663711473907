export class PaginationController {
    constructor() {
        this.pages = [];
        this.showDotsAfterFirstPage = false;
        this.showDotsBeforeLastPage = false;
    }
    $onChanges() {
        this.totalPages = Math.max(1, this.totalPages);
        let firstPageToShow = 1;
        let lastPageToShow = 3;
        if (this.currentPage > 1) {
            firstPageToShow = this.currentPage - 1;
            lastPageToShow = this.currentPage + 1;
        }
        if (this.currentPage === this.totalPages) {
            firstPageToShow = this.currentPage - 2;
        }
        lastPageToShow = Math.min(this.totalPages - 1, lastPageToShow);
        firstPageToShow = Math.max(2, firstPageToShow);
        this.generatePages(firstPageToShow, lastPageToShow);
        this.showDotsAfterFirstPage = firstPageToShow > 2;
        this.showDotsBeforeLastPage = lastPageToShow < this.totalPages - 1;
    }
    generatePages(first, last) {
        this.pages = [];
        for (let i = first; i <= last; i++) {
            this.pages.push(i);
        }
    }
    navigate(pageNumber) {
        this.onPageClick({ pageNumber });
    }
}
