import Hls from 'hls.js';
import { VideoStimuliDialogController } from '../stimuli/video-stimuli-dialog.controller';
var IscVideoController = (function () {
    function IscVideoController($element, $window, $scope) {
        this.$element = $element;
        this.$window = $window;
        this.$scope = $scope;
        this.videoId = null;
        this.videoThumbnail = null;
        this.videoType = null;
        this.mediaPlayer = null;
        this.mediaPlayerOptions = {
            logo: {
                enabled: false,
            },
            controls: true,
            autoplay: false,
            heuristicProfile: 'QuickStart',
        };
    }
    IscVideoController.prototype.$onInit = function () {
        this.videoElement = this.$element[0].querySelector('video');
        this.videoElement.id = IscVideoController.getUniqueIdForVideoElement();
        this.initMediaPlayer();
    };
    IscVideoController.prototype.initMediaPlayer = function () {
        var _this = this;
        this.videoType = this.videoType || IscVideoController.getMediaPlayerSourceType(this.videoId);
        if (!this.videoElement.canPlayType(this.videoType)
            && this.videoType !== VideoStimuliDialogController.defaultMediaServicesType) {
            if (Hls.isSupported()) {
                this.videoElement.poster = this.videoThumbnail;
                this.videoElement.controls = false;
                var hls_1 = new Hls();
                hls_1.config.autoStartLoad = false;
                hls_1.loadSource(this.videoId);
                hls_1.attachMedia(this.videoElement);
                this.videoElement.onplay = function () {
                    hls_1.startLoad(-1);
                };
                this.videoElement.onclick = function () {
                    _this.startOnClick();
                };
            }
        }
        else {
            this.mediaPlayer = this.$window.amp(this.videoElement, this.mediaPlayerOptions);
            this.mediaPlayer.src([
                {
                    src: this.videoId,
                    type: this.videoType,
                },
            ]);
            this.$scope.$on('$destroy', function () {
                _this.mediaPlayer.dispose();
            });
        }
    };
    IscVideoController.getUniqueIdForVideoElement = function () {
        IscVideoController.videoCounter++;
        var id = 'amp-video-' + IscVideoController.videoCounter;
        return id;
    };
    IscVideoController.getMediaPlayerSourceType = function (videoUrl) {
        var result = VideoStimuliDialogController.defaultMediaServicesType;
        if (videoUrl) {
            if (videoUrl.indexOf('.mp4') !== -1 || videoUrl.indexOf('blob') === 0) {
                result = 'video/mp4';
            }
            else if (videoUrl.indexOf('.m3u8') !== -1) {
                result = 'application/vnd.apple.mpegurl';
            }
        }
        return result;
    };
    IscVideoController.prototype.startOnClick = function () {
        this.videoElement.poster = '';
        var buttonElement = document.getElementById(this.videoId);
        if (buttonElement) {
            buttonElement.hidden = true;
        }
        this.videoElement.controls = true;
        this.videoElement.play();
        this.videoElement.onclick = null;
    };
    IscVideoController.prototype.pause = function () {
        var videoElement = this.$element.find('video')[0];
        if (videoElement && !videoElement.paused) {
            videoElement.pause();
        }
    };
    IscVideoController.$inject = ['$element', '$window', '$scope'];
    IscVideoController.videoCounter = 0;
    return IscVideoController;
}());
export { IscVideoController };
