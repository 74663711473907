export class ConfirmitEmbedCustomElementService {
    constructor(_, logger) {
        this._ = _;
        this.logger = logger;
        this.mutations = [{
                selector: 'input[type=button]',
                apply: (element) => {
                    element.className += ' md-button md-primary md-raised isc-button';
                },
            }, {
                selector: 'input[type=checkbox]',
                apply: (element, window) => {
                    const checkboxHtml = `<md-checkbox class="md-primary ng-valid ng-dirty ng-valid-parse ng-touched ng-not-empty">
        <div class="md-container"><div class="md-icon" ></div></div></md-checkbox>`;
                    const mdCheckbox = this.parseHtmlElement(window, checkboxHtml);
                    this.insertAfter(element, mdCheckbox);
                    mdCheckbox.onclick = () => {
                        element.click();
                    };
                    element.classList.add('hidden');
                    this.onElementRemoved(window, element, () => this.removeNode(mdCheckbox));
                },
            },
            {
                selector: 'input[type=text]:not(.multi-ranking-input), textarea',
                apply: (element, window) => {
                    element.classList.add('md-input');
                    const mdInputContainer = window.document.createElement('md-input-container');
                    mdInputContainer.className = 'md-input-has-value md-default-theme';
                    element.parentElement.replaceChild(mdInputContainer, element);
                    mdInputContainer.appendChild(element);
                    element.addEventListener('focus', () => {
                        mdInputContainer.classList.add('md-input-focused');
                    });
                    element.addEventListener('blur', () => {
                        mdInputContainer.classList.remove('md-input-focused');
                    });
                    element.focus();
                },
            },
            {
                selector: 'input[type=radio]',
                apply: (element, window) => {
                    const radioGroup = window.document.createElement('md-radio-group');
                    radioGroup.innerHTML = `<md-radio-button class="md-primary"><div class="md-container md-ink-ripple">
        <div class="md-off"></div><div class="md-on"></div><div class="md-ripple-container"></div></div></md-radio-button>`;
                    this.insertAfter(element, radioGroup);
                    radioGroup.onclick = () => {
                        element.click();
                    };
                    element.classList.add('hidden');
                },
            },
            {
                selector: '.multi-ranking-input',
                apply: (element, window) => {
                    const mdSelectHtml = `<md-select class="ng-pristine ng-valid ng-touched">
        <md-select-value class="md-select-value md-select-placeholder"><span class="ranking-select-container">
        <span class="md-select-icon" aria-hidden="true"></span>
        <select class="ranking-select"></select>
        </span></md-select-value></md-select>`;
                    const mdSelect = this.parseHtmlElement(window, mdSelectHtml);
                    this.insertAfter(element, mdSelect);
                    const rankingSelect = mdSelect.querySelector('.ranking-select');
                    populateOptions(rankingSelect);
                    rankingSelect.value = element.value;
                    element.classList.add('hidden');
                    rankingSelect.addEventListener('change', () => {
                        if (element.value !== rankingSelect.value) {
                            element.value = rankingSelect.value;
                        }
                    });
                    function populateOptions(select) {
                        const container = this.findParentWithClass(select, 'question-form');
                        const multiRankingInputs = container.querySelectorAll('.multi-ranking-input');
                        for (let i = 0; i < multiRankingInputs.length; i++) {
                            const option = window.document.createElement('option');
                            option.value = i + 1;
                            option.innerText = i + 1;
                            select.appendChild(option);
                        }
                    }
                },
            }];
    }
    updateElements(win) {
        this.checkForRemovedElements(win);
        this.applyMutations(win);
    }
    applyMutations(win) {
        this._.each(this.mutations, (mutation) => {
            const elements = win.document.querySelectorAll(mutation.selector);
            this._.each(elements, (element) => {
                if (!this.isMutationApplied(element)) {
                    try {
                        mutation.apply(element, win);
                    }
                    catch (e) {
                        this.logger.error('Failed to execute mutation', e);
                    }
                    this.markMutationApplied(element);
                }
            });
        });
    }
    isMutationApplied(element) {
        return element.hasAttribute('data-mutation-applied');
    }
    markMutationApplied(element) {
        element.setAttribute('data-mutation-applied', true);
    }
    checkForRemovedElements(win) {
        const elementRemovedHandlers = win._iscElementRemovedHandlers || [];
        this._.each(elementRemovedHandlers, (handler) => {
            if (!this.isInDom(win, handler.element)) {
                handler.callback();
            }
        });
    }
    onElementRemoved(win, element, onRemoved) {
        win._iscElementRemovedHandlers = win._iscElementRemovedHandlers || [];
        win._iscElementRemovedHandlers.push({
            element,
            callback: onRemoved,
        });
    }
    isInDom(window, element) {
        if (element === window.document.body) {
            return true;
        }
        return element.parentElement && this.isInDom(window, element.parentElement);
    }
    parseHtmlElement(window, html) {
        return this.parseHtml(window, html)[0];
    }
    parseHtml(window, html) {
        const helper = window.document.createElement('div');
        helper.innerHTML = html;
        return this._.toArray(helper.childNodes);
    }
    insertAfter(targetNode, nodeToInsert) {
        targetNode.parentElement.insertBefore(nodeToInsert, targetNode.nextSibling);
    }
    findParentWithClass(element, className) {
        if (element.classList.contains(className)) {
            return element;
        }
        else if (element.parentElement) {
            return this.findParentWithClass(element.parentElement, className);
        }
        return undefined;
    }
    removeNode(node) {
        const parent = node.parentNode;
        if (parent) {
            parent.removeChild(node);
        }
    }
}
ConfirmitEmbedCustomElementService.$inject = ['_', 'logger'];
