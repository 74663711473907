export class TestProfileActivityController {
    constructor(activityService, $stateParams, imagesFactory, serverConstants) {
        this.activityService = activityService;
        this.$stateParams = $stateParams;
        this.imagesFactory = imagesFactory;
        this.serverConstants = serverConstants;
        this.images = this.imagesFactory.images;
        this.surveyUrl = '';
        this.activityName = 'Test profile activity';
        this.activityTypes = this.serverConstants.squareActivityTypeConstants;
    }
    $onInit() {
        this.surveyUrl = this.$stateParams.link;
        this.activityService.getTestActivityDetails(this.$stateParams.activityGuid).then((activity) => {
            if (activity && activity.name) {
                this.activityName = activity.name;
                this.activityType = activity.type;
            }
        });
    }
}
TestProfileActivityController.$inject = ['activityService', '$stateParams', 'imagesFactory', 'serverConstants'];
