import { UiModule } from './../../index';
UiModule.factory('iscDomhelperservice', domhelperservice);
domhelperservice.$inject = [];
function domhelperservice() {
    var indentation = 40;
    return {
        findLastNonEmptyChild: findLastNonEmptyChild,
        isEmptyNode: isEmptyNode,
        isBlockLevelElement: isBlockLevelElement,
        isAncestorOf: isAncestorOf,
        findAncestor: findAncestor,
        elementMatches: elementMatches,
        insertHtmlInNgWig: insertHtmlInNgWig,
        indentInNgWig: indentInNgWig,
        outdentInNgWig: outdentInNgWig,
    };
    function findLastNonEmptyChild(element) {
        var lastNonEmptyChild = element.lastChild;
        while (lastNonEmptyChild.previousSibling && isEmptyNode(lastNonEmptyChild)) {
            lastNonEmptyChild = lastNonEmptyChild.previousSibling;
        }
        if (lastNonEmptyChild.lastChild && isBlockLevelElement(lastNonEmptyChild)) {
            lastNonEmptyChild = findLastNonEmptyChild(lastNonEmptyChild);
        }
        return lastNonEmptyChild;
    }
    function isEmptyNode(node) {
        return !node.textContent.trim()
            && node.nodeName !== 'IMG'
            && (node.querySelector && !node.querySelector('img'));
    }
    function isBlockLevelElement(element) {
        return (element instanceof HTMLElement)
            && window.getComputedStyle(element).display.indexOf('block') !== -1;
    }
    function isAncestorOf(el, selector) {
        var ancestor = findAncestor(el, selector);
        return ancestor !== null;
    }
    function findAncestor(el, selector) {
        var result = null;
        while (el) {
            if (elementMatches(el, selector)) {
                result = el;
                break;
            }
            el = el.parentNode;
        }
        return result;
    }
    function elementMatches(el, selector) {
        var isTextOrComment = el.nodeType === 3 || el.nodeType === 8;
        if (isTextOrComment) {
            return false;
        }
        var matches = el.matches
            || el.matchesSelector
            || el.mozMatchesSelector
            || el.msMatchesSelector
            || el.oMatchesSelector
            || el.webkitMatchesSelector;
        if (typeof selector === 'string') {
            return matches && matches.call(el, selector);
        }
        else {
            return el === selector;
        }
    }
    function hasLiParent(el, ngWigEditor) {
        var foundIt = false;
        if (!el.parentNode) {
            el = el.focusNode;
        }
        while (el) {
            if (el === ngWigEditor) {
                foundIt = false;
                break;
            }
            if (el.nodeName && el.nodeName === 'LI') {
                foundIt = true;
                break;
            }
            el = el.parentNode;
        }
        return foundIt;
    }
    function insertHtmlInNgWig(html, ngWigEditor) {
        var selection = window.getSelection();
        if (isSelectionInsideEditor(selection, ngWigEditor)) {
            selectNgWig(selection, ngWigEditor);
        }
        document.execCommand('insertHtml', false, html);
        ngWigEditor.focus();
    }
    function isSelectionInsideEditor(selection, ngWigEditor) {
        return !isAncestorOf(selection.anchorNode, ngWigEditor) || !isAncestorOf(selection.focusNode, ngWigEditor);
    }
    function selectNgWig(selection, ngWigEditor) {
        var range = document.createRange();
        var endOfLastLine = findLastNodeOfLastLine(ngWigEditor);
        range.setStartAfter(endOfLastLine);
        range.setEndAfter(endOfLastLine);
        selection.removeAllRanges();
        selection.addRange(range);
    }
    function findLastNodeOfLastLine(ngWigEditor) {
        var result = null;
        if (!ngWigEditor.lastChild) {
            var newNode = document.createTextNode('');
            ngWigEditor.appendChild(newNode);
            result = newNode;
        }
        else {
            result = findLastNonEmptyChild(ngWigEditor);
        }
        return result;
    }
    function indentInNgWig() {
        var selection = window.getSelection();
        var ngWig = findAncestor(selection.focusNode, '.ng-wig');
        var rtl = isRTL(ngWig);
        if (!hasLiParent(selection, ngWig) && rtl) {
            document.execCommand('formatBlock', false, 'p');
            changeMargin(ngWig, true);
        }
        else {
            document.execCommand('indent', false);
        }
    }
    function outdentInNgWig() {
        var selection = window.getSelection();
        var ngWig = findAncestor(selection.focusNode, '.ng-wig');
        var rtl = isRTL(ngWig);
        if (!hasLiParent(selection, ngWig) && rtl) {
            changeMargin(ngWig, false);
        }
        else {
            document.execCommand('outdent', false);
        }
    }
    function changeMargin(ngWigEditor, indent) {
        var selectedElement = window.getSelection().focusNode.parentNode;
        var declaration = selectedElement.style;
        var margin = declaration.marginRight;
        var currentValue = (margin !== '') ? parseInt(margin.replace('px', ''), 10) : 0;
        if (indent) {
            margin = currentValue + indentation + 'px';
            declaration.marginRight = margin;
        }
        if (!indent && currentValue >= indentation) {
            if (currentValue === indentation) {
                while (selectedElement.childNodes.length > 0) {
                    selectedElement.parentNode.insertBefore(selectedElement.childNodes[0], selectedElement);
                }
                selectedElement.remove();
            }
            else if (currentValue > indentation) {
                margin = (currentValue - indentation) + 'px';
                declaration.marginRight = margin;
            }
        }
    }
    function isRTL(ngWigEditor) {
        var computedStyle = getComputedStyle(ngWigEditor);
        if (computedStyle.direction === 'rtl') {
            return true;
        }
        return false;
    }
    function getComputedStyle(element) {
        var style;
        var returns = {};
        if (window.getComputedStyle) {
            style = window.getComputedStyle(element, null);
            for (var i = 0, l = style.length; i < l; i++) {
                var prop = style[i];
                var val = style.getPropertyValue(prop);
                returns[prop] = val;
            }
            return returns;
        }
        if (element.currentStyle) {
            style = element.currentStyle;
            for (var _i = 0, style_1 = style; _i < style_1.length; _i++) {
                var prop = style_1[_i];
                returns[prop] = style[prop];
            }
            return returns;
        }
        if (style === element.style) {
            for (var prop in style) {
                if (typeof style[prop] !== 'function') {
                    returns[prop] = style[prop];
                }
            }
            return returns;
        }
        return returns;
    }
}
