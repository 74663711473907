import { usePagesStore } from '@/store/pages-store';
import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';
import { RouteParams, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { guidRegex } from '@/router/router.constants';
const DiscussionActivity = () => import('@/pages/discussion-activity/discussion-activity-page.vue');
const ActivitiesPage = () => import('@/pages/activities/activities-page.vue');
const ActivitiesFilteredPage = () => import('@/pages/activities/activities-filtered-page.vue');

const meta = {
  title: (routeParams: RouteParams) => {
    const pagesStore = usePagesStore();
    return pagesStore.pageByGuid(routeParams.pageGuid as string)?.name || '(LabelWindowTitleChallenges)';
  },
  requireLogin: true,
};

export const challengesRoutes: RouteRecordRaw = {
  name: RouteNames.Challenges,
  path: `/page/:pageGuid(${guidRegex})`,
  component: ActivitiesPage,
  meta,
  redirect: (to) => ({ name: RouteNames.ChallengesAll, params: to.params, query: to.query }),
  children: [{
    name: RouteNames.ChallengesAll,
    path: 'all',
    component: ActivitiesFilteredPage,
    meta,
  }, {
    name: RouteNames.ChallengesCompleted,
    path: 'completed',
    component: ActivitiesFilteredPage,
    meta,
  }, {
    name: RouteNames.ChallengesTodo,
    path: 'todo',
    component: ActivitiesFilteredPage,
    meta,
  }, {
    name: RouteNames.ChallengesQual,
    path: `conversation/:activityGuid(${guidRegex})/:channel?`,
    component: getWrappedAngularComponent('qualActivityComponent'),
    meta: {
      title: '(LabelWindowTitleChallenges)',
      requireLogin: true,
      flutterInAppHandler: 'activityClickedInWebview', // indicates that this route might be called from within a webview in the app
    },
  }, {
    name: RouteNames.ChallengesNewDisc20,
    path: `discussion/:activityGuid(${guidRegex})/:channel?`,
    component: DiscussionActivity,
    meta: {
      title: '(LabelWindowTitleChallenges)',
      requireLogin: true,
      flutterInAppHandler: 'activityClickedInWebview', // indicates that this route might be called from within a webview in the app
    },
  }],
};
