import { IscUIUtils } from '../../utils/sharedutils';
import { DateTime } from 'luxon';
var ForumPostController = (function () {
    function ForumPostController(serverConstants, iscDraftService) {
        this.serverConstants = serverConstants;
        this.iscDraftService = iscDraftService;
        this.isVisualFocusedOpeningPost = false;
    }
    ForumPostController.prototype.$onInit = function () {
        this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);
        this.isVisualFocusedOpeningPost = this.isActivityVisualFocusedConversation(this.conversationFocusType);
        this.initDraft();
    };
    ForumPostController.prototype.initDraft = function () {
        var _this = this;
        var initialUid = this.draftUid ? this.draftUid() : undefined;
        if (initialUid) {
            if (this.draftSet) {
                var cached = this.iscDraftService.getDraft(initialUid);
                if (cached) {
                    this.draftSet({ draft: cached });
                }
            }
            this.iscDraftService.setOnDraftUpdate(initialUid, function (update) {
                var modelUid = _this.draftUid ? _this.draftUid() : undefined;
                if (modelUid === update.uid) {
                    var draft = update.value || _this.draftGet ? _this.draftGet() : undefined;
                    if (draft) {
                        _this.iscDraftService.setDraft(update.uid, draft, true);
                        return true;
                    }
                }
                return false;
            });
        }
        else {
        }
    };
    ForumPostController.prototype.isActivityVisualFocusedConversation = function (conversationFocusType) {
        return conversationFocusType !== null &&
            conversationFocusType !== undefined &&
            conversationFocusType === this.serverConstants.conversationFocusTypeConstants.visual;
    };
    ForumPostController.prototype.getLabelConversationCreatedPosted = function (dateCreated) {
        if (dateCreated != null && DateTime.utc() < DateTime.fromISO(dateCreated)) {
            return '(LabelForumRoomConversationCreatedAt)';
        }
        return '(LabelForumRoomConversationPostedAt)';
    };
    ForumPostController.prototype.getLabelTimeAgo = function (date) {
        return DateTime.fromISO(date).toRelative({ base: DateTime.utc() });
    };
    ForumPostController.prototype.onSetAccepted = function () {
        if (this.setAccepted !== undefined) {
            this.setAccepted();
        }
    };
    ForumPostController.prototype.$onDestroy = function () {
        if (this.draftInterval !== -1) {
            clearInterval(this.draftInterval);
        }
    };
    ForumPostController.$inject = ['serverConstants', 'iscDraftService'];
    return ForumPostController;
}());
export { ForumPostController };
