import { __awaiter } from "tslib";
import { BaseDataService } from './base.data.service';
import * as _ from 'lodash';
import * as UpChunk from '@mux/upchunk';
import { Utils } from '../utils';
export class MuxService extends BaseDataService {
    constructor(logger, httpService, serverConstants, squareFactory, beaconService, $window, $location, mediaService) {
        super(httpService);
        this.logger = logger;
        this.serverConstants = serverConstants;
        this.squareFactory = squareFactory;
        this.beaconService = beaconService;
        this.$window = $window;
        this.$location = $location;
        this.mediaService = mediaService;
        this._stimuliUploads = [];
        this.$window.addEventListener('beforeunload', (ev) => {
            if (this._stimuliUploads && _.find(this._stimuliUploads, (s) => s.progress !== 100)) {
                ev.preventDefault();
                ev.returnValue = '';
            }
        });
        const self = this;
        this.$window.document.addEventListener('visibilitychange', () => {
            if ($window.document.visibilityState === 'hidden') {
                if (self._stimuliUploads && self._stimuliUploads.length > 0) {
                    const unfinishedUploads = _.filter(self._stimuliUploads, (s) => s.progress !== 100);
                    if (unfinishedUploads && unfinishedUploads.length > 0) {
                        self.logWarningOnBackend(_.map(unfinishedUploads, (e) => e.stimulusValue));
                    }
                }
            }
        });
    }
    getSource() {
        return `mux.service @ ${this.$location.absUrl()}`;
    }
    get MuxServiceEnabled() {
        return this.squareFactory
            && this.squareFactory.VideoStorage
            && this.squareFactory.VideoStorage === this.serverConstants.clientVideoStorageConstants.mux;
    }
    uploadVideo(_muxData, _file, uploadCallback) {
        if (!_file.lastModified) {
            _file = new File([_file], _file.name);
        }
        this._stimuliUploads.push({
            stimulusValue: _muxData.Id,
            progress: 0,
        });
        this.logger.info(`upload request for ${_muxData.Id} (${_file.size} bytes)`);
        const upload = UpChunk.createUpload({
            endpoint: _muxData.Url,
            file: _file,
            chunkSize: 5120,
        });
        this.logStatisticsOnBackend({
            uploadId: _muxData.Id,
            sizeInMB: this.convertBToMB(_file.size),
            extension: Utils.getExtensionFromFilename(_file.name),
            mimeType: _file.type,
            isMobile: this.mediaService.isMobile,
            isMobileApp: this.mediaService.isMobileApp,
        });
        upload.on('error', (err) => {
            this.removeStimulusFromUploadingList(_muxData.Id);
            this.logger.error(`'${err.detail.message}' while uploading for ${_muxData.Id}`, err);
            this.logErrorOnBackend(_muxData.Id);
        });
        upload.on('success', () => {
            this.logger.success(`uploading for ${_muxData.Id}`);
        });
        upload.on('progress', (progress) => {
            const stimulusUploadProgress = {
                stimulusValue: _muxData.Id,
                progress: this.getStimulusUploadProgress(_muxData.Id),
            };
            if (stimulusUploadProgress.progress && uploadCallback) {
                uploadCallback(stimulusUploadProgress);
            }
            const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === _muxData.Id)[0];
            if (stimulusUpload && progress.detail > stimulusUpload.progress) {
                stimulusUpload.progress = progress.detail;
            }
        });
    }
    convertBToMB(size) {
        return size / 1024 / 1024;
    }
    logErrorOnBackend(uploadId) {
        return __awaiter(this, void 0, void 0, function* () {
            const uploadData = {
                source: this.getSource(),
                message: `Upload failed on client for uploadId: ${uploadId}`,
            };
            return this.httpService.post({
                url: '/ConversationService/LogErrorFromClient',
                data: uploadData,
            });
        });
    }
    logWarningOnBackend(uploadIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const uploadIdsString = uploadIds.join('; ');
            const uploadData = {
                source: this.getSource(),
                message: `Upload warning (tab may have closed) on client for uploadIds: ${uploadIdsString}`,
            };
            return this.beaconService.sendBeacon('/ConversationService/LogAnonymousErrorFromClient', uploadData);
        });
    }
    logStatisticsOnBackend(statistics) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: '/PlatformHealthService/LogVideoStatisticsFromClient',
                data: statistics,
            });
        });
    }
    getNewUploadLinkForVideo() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: '/ConversationService/GetNewUploadLinkForVideo?mimeType=',
            });
        });
    }
    cancelUploadForVideo(_muxData) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: '/ConversationService/CancelUploadLinkForVideo',
                data: _muxData,
            });
        });
    }
    processAndUploadStimuliBeforePost(stimuli) {
        return __awaiter(this, void 0, void 0, function* () {
            let result = stimuli;
            if (this.MuxServiceEnabled
                && stimuli && stimuli.length > 0) {
                result = [];
                for (const stimulus of stimuli) {
                    if (stimulus.type
                        && stimulus.type === 1
                        && stimulus.file) {
                        const videoData = yield this.getNewUploadLinkForVideo();
                        if (videoData.data && videoData.data.Url) {
                            this.uploadVideo(videoData.data, stimulus.file, undefined);
                            stimulus.file = undefined;
                            stimulus.id = videoData.data.Id;
                        }
                    }
                    result.push(stimulus);
                }
            }
            return result;
        });
    }
    getStimulusUploadProgress(stimulusValue) {
        const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === stimulusValue)[0];
        return stimulusUpload ? stimulusUpload.progress : null;
    }
    removeStimulusFromUploadingList(stimulusValue) {
        const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === stimulusValue)[0];
        if (stimulusUpload) {
            this._stimuliUploads = this._stimuliUploads.filter((s) => s.stimulusValue !== stimulusValue);
        }
    }
}
MuxService.$inject = [
    'logger',
    'httpService',
    'serverConstants',
    'squareFactory',
    'beaconService',
    '$window',
    '$location',
    'mediaservice',
];
