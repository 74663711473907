export class ToastNotificationService {
    constructor(taskList, $timeout) {
        this.taskList = taskList;
        this.$timeout = $timeout;
    }
    show(options) {
        if (options !== undefined) {
            const task = this.taskList.createTask({
                title: options.title,
                text: options.text,
                onClick: options.onClick,
                showCloseButton: options.showCloseButton,
                theme: options.theme,
            });
            task.show();
            this.$timeout(() => {
                task.hide();
            }, options.hideDelay);
        }
    }
}
ToastNotificationService.$inject = ['taskList', '$timeout'];
