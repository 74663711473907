import { UiModule } from '../../index';
import { DownloadStimuliController } from './isc-stimuli-download.controller';
var template = "<i ng-click=\"vm.downloadStimuli()\" class=\"fa fa-download\">\n  <md-tooltip>Download</md-tooltip>\n</i>\n";
UiModule.component('iscStimuliDownload', {
    template: template,
    controllerAs: 'vm',
    controller: DownloadStimuliController,
    bindings: {
        stimulusId: '<',
    },
});
