export class LanguageService {
    constructor() {
        this._isRtl = false;
    }
    isLtr() {
        return !this.isRtl();
    }
    isRtl() {
        return this._isRtl;
    }
    setRtl(isRtl) {
        this._isRtl = isRtl;
    }
}
LanguageService.$inject = [];
