import * as __ from 'lodash';
var IscStimulusItemRetryController = (function () {
    function IscStimulusItemRetryController(iscSocialStimuli, $q, iscStimuliTypeService, _, iscStimuliDialogService, $scope, iscConfigurationService, serverConstants) {
        this.iscSocialStimuli = iscSocialStimuli;
        this.$q = $q;
        this.iscStimuliTypeService = iscStimuliTypeService;
        this._ = _;
        this.iscStimuliDialogService = iscStimuliDialogService;
        this.$scope = $scope;
        this.iscConfigurationService = iscConfigurationService;
        this.serverConstants = serverConstants;
        this.inview = false;
        this.backgroundUrl = '';
        this.cursor = 'default';
        this.loaded = false;
        this.url = '/images/bg-video.png';
        this.invalidFiles = [];
        this.config = {
            isColumnLayout: false,
            maxAttachments: 10,
            maxStimuli: 10,
            showAttachment: false,
            showImageUpload: true,
            showVideoLink: true,
            showVideoUpload: true,
        };
        this.fileConfiguration = [];
    }
    IscStimulusItemRetryController.prototype.$onInit = function () {
        var _this = this;
        this.stimuliTypes = this.iscStimuliTypeService.getTypes();
        var stimulusType = this.stimulus.type;
        if (!stimulusType && this.stimulus.hasOwnProperty('Type')) {
            stimulusType = this.stimulus.Type;
        }
        this.config.showImageUpload = this.stimulus && stimulusType === this.serverConstants.conversationStimuliTypeConstants.image;
        this.config.showVideoUpload = this.stimulus && stimulusType === this.serverConstants.conversationStimuliTypeConstants.video;
        this.$scope.$watch('vm.inview', function () {
            _this.backgroundUrl = _this.createBackgroundUrl();
        });
        this.conversationStimuliType = this.iscStimuliTypeService.getTypes();
        this.stringStimuliType = this._.invert(this.conversationStimuliType);
        this.isVideoFormat = this.isVideo();
        this.setThumbnail();
        this.stimulusGuid = this.stimulus.guid;
        if (!this.stimulusGuid && this.stimulus.hasOwnProperty('Guid')) {
            this.stimulusGuid = this.stimulus.Guid;
        }
        this.backgroundUrl = this.createBackgroundUrl();
        this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
        this.iscConfigurationService.getFileConfiguration()
            .then(function (response) {
            _this.fileConfiguration = response;
            _this.initFileMaxSizes();
        });
    };
    IscStimulusItemRetryController.prototype.initFileMaxSizes = function () {
        var _this = this;
        this.imageStimuliMaxSizeBytes = __.find(this.fileConfiguration, function (config) { return config.FileType === _this.fileTypeConstants.image; }).MaxFileSizeMb * Math.pow(1024, 2);
        this.videoStimuliMaxSizeBytes = __.find(this.fileConfiguration, function (config) { return config.FileType === _this.fileTypeConstants.video; }).MaxFileSizeMb * Math.pow(1024, 2);
        this.attachmentMaxSizeBytes = __.find(this.fileConfiguration, function (config) { return config.FileType === _this.fileTypeConstants.attachment; }).MaxFileSizeMb * Math.pow(1024, 2);
    };
    IscStimulusItemRetryController.prototype.setThumbnail = function () {
        var _this = this;
        if (this.stimulus.type === this.conversationStimuliType.youtube || this.stimulus.type === this.conversationStimuliType.vimeo) {
            this.iscSocialStimuli.getThumbnail(this.stringStimuliType[this.stimulus.type], this.stimulus.value).then(function (thumbnail) {
                _this.stimulus.thumbnailUrl = thumbnail;
                _this.loaded = true;
            });
        }
        else {
            this.loaded = true;
        }
    };
    IscStimulusItemRetryController.prototype.isVideoLoaded = function () {
        if (this.newThumb && this.loaded && this.stimulus.thumbnailUrl !== 'images/bg-video.png') {
            return true;
        }
        return false;
    };
    IscStimulusItemRetryController.prototype.isClickable = function () {
        return this.isVideo()
            ? this.stimulus.thumbnailUrl !== 'images/bg-video.png'
            : this.stimulus.thumbnailUrl !== '/images/default-stimuli.png';
    };
    IscStimulusItemRetryController.prototype.openStimulus = function (event) {
        this.iscStimuliDialogService.openStimulusPreview(this.stimulus, this.allowDownload, event.target);
    };
    IscStimulusItemRetryController.prototype.isImage = function () {
        return this.stimulus && this.stimulus.type === this.conversationStimuliType.image;
    };
    IscStimulusItemRetryController.prototype.isVideo = function () {
        if (this.stimulus && this.stimulus.type) {
            if (this.stimulus.type === this.conversationStimuliType.image) {
                return false;
            }
            else if (this.stimulus.type === this.conversationStimuliType.vimeo ||
                this.stimulus.type === this.conversationStimuliType.video ||
                this.stimulus.type === this.conversationStimuliType.youtube) {
                return true;
            }
            else {
                throw new Error('Unknown stimulus type');
            }
        }
    };
    IscStimulusItemRetryController.prototype.createBackgroundUrl = function () {
        if (this.inview && this.stimulus.thumbnailUrl) {
            return 'url(\'' + this.stimulus.thumbnailUrl + '\')';
        }
        return 'url(\'' + this.url + '\')';
    };
    IscStimulusItemRetryController.prototype.getUploadProgress = function (stimulus) {
        if (this.uploadProgress) {
            return this.uploadProgress({ stimulus: stimulus });
        }
        return null;
    };
    IscStimulusItemRetryController.prototype.getPatternForFileType = function (fileTypes) {
        this.parseFileTypesArrayForCurrentConfig(fileTypes);
        var patterns = this.iscConfigurationService.getAcceptedMimeTypesAndExtensions(this.fileConfiguration, fileTypes).patterns;
        return "'".concat(patterns.toString(), "'");
    };
    IscStimulusItemRetryController.prototype.parseFileTypesArrayForCurrentConfig = function (initalFileTypes) {
        var applyConfig = function (fileTypes, configValue, fileType) {
            if (configValue === false) {
                var imageIndex = fileTypes.indexOf(fileType);
                if (imageIndex !== -1) {
                    initalFileTypes.splice(imageIndex, 1);
                }
            }
        };
        applyConfig(initalFileTypes, this.config.showImageUpload, this.fileTypeConstants.image);
        applyConfig(initalFileTypes, this.config.showVideoUpload, this.fileTypeConstants.video);
        applyConfig(initalFileTypes, this.config.showAttachment, this.fileTypeConstants.attachment);
    };
    IscStimulusItemRetryController.prototype.getAcceptedMimeTypesForFileType = function (fileTypes) {
        this.parseFileTypesArrayForCurrentConfig(fileTypes);
        var mimeTypes = this.iscConfigurationService.getAcceptedMimeTypesAndExtensions(this.fileConfiguration, fileTypes).mimeTypes;
        return mimeTypes.toString();
    };
    IscStimulusItemRetryController.prototype.getStimuliUploadLabel = function () {
        if (this.config.showImageUpload && this.config.showVideoUpload) {
            return '(LabelForumConversationEditorUploadStimuli)';
        }
        if (this.config.showImageUpload) {
            return '(LabelForumConversationEditorUploadStimuliImageOnly)';
        }
        if (this.config.showVideoUpload) {
            return '(LabelForumConversationEditorUploadStimuliVideoOnly)';
        }
    };
    IscStimulusItemRetryController.prototype.filesChanged = function ($files, $newFiles, $invalidFiles) {
        var validStimuli = [];
        var newFilesCounter = 0;
        for (var _i = 0, $newFiles_1 = $newFiles; _i < $newFiles_1.length; _i++) {
            var file = $newFiles_1[_i];
            newFilesCounter++;
            if (this.validateUploadingStimuli(file, $invalidFiles, newFilesCounter) === true) {
                validStimuli.push(this.createStimulus(file));
            }
        }
        if (validStimuli.length > 0) {
            this.addStimuli(validStimuli);
        }
        $invalidFiles.splice(0);
        $files.splice(0);
    };
    IscStimulusItemRetryController.prototype.addStimuli = function (stimuli) {
        if (stimuli && stimuli.length > 0) {
            if (this.onFileAdded) {
                var stimulus = stimuli.pop();
                this.onFileAdded({ stimulus: stimulus });
            }
        }
    };
    Object.defineProperty(IscStimulusItemRetryController.prototype, "isReadonly", {
        get: function () {
            return this.stimulus.IsBrokenAndBelongsToCurrentUser === true
                ? !this.config.showImageUpload && !this.config.showVideoUpload
                : true;
        },
        enumerable: false,
        configurable: true
    });
    IscStimulusItemRetryController.prototype.validateUploadingStimuli = function (file, invalidFiles, newFileCounter) {
        var validationResult = false;
        if (file) {
            if ((file.type.indexOf('image') === 0 && file.size > this.imageStimuliMaxSizeBytes) ||
                (file.type.indexOf('video') === 0 && file.size > this.videoStimuliMaxSizeBytes)) {
                file.$errorMessages = file.$errorMessages || {};
                file.$errorMessages.maxSize = true;
                file.maxSize = Math.floor((file.type.indexOf('image') === 0 ? this.imageStimuliMaxSizeBytes : this.videoStimuliMaxSizeBytes) / Math.pow(1024, 2));
                this.invalidFiles.push(file);
            }
            if (!file.$errorMessages) {
                validationResult = true;
            }
            else {
                if (invalidFiles.indexOf(file) >= 0) {
                    this.invalidFiles.push(file);
                }
            }
        }
        return validationResult;
    };
    IscStimulusItemRetryController.prototype.createStimulus = function (file) {
        var stimulus = {
            guid: this.stimulusGuid,
            file: file,
            type: this.getStimulusType(file),
            isPreview: false,
            status: this.serverConstants.conversationStimuliStatusConstants.new,
        };
        return stimulus;
    };
    IscStimulusItemRetryController.prototype.getStimulusType = function (file) {
        if (file.type.indexOf('image') === 0) {
            return this.stimuliTypes.image;
        }
        else if (file.type.indexOf('video') === 0) {
            return this.stimuliTypes.video;
        }
        else {
            return -1;
        }
    };
    IscStimulusItemRetryController.$inject = ['iscSocialStimuli', '$q', 'iscStimuliTypeService', '_', 'iscStimuliDialogService', '$scope', 'iscConfigurationService', 'serverConstants'];
    return IscStimulusItemRetryController;
}());
export { IscStimulusItemRetryController };
