export function IscHashtagHighlightFilter() {
    function escapeRegexp(queryToEscape) {
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }
    return function (matchItem, query, highlightClass) {
        if (query) {
            var replaceText = highlightClass ?
                "<span class=\"".concat(highlightClass, "\">$&</span>") :
                '<strong>$&</strong>';
            return ('' + matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), replaceText);
        }
        else {
            return matchItem;
        }
    };
}
