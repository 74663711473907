import { __extends } from "tslib";
import { IscTaggingMenuBaseController } from '../common-tagging/tagging-menu-base.controller';
var IscHashtagMenuController = (function (_super) {
    __extends(IscHashtagMenuController, _super);
    function IscHashtagMenuController($scope, iscHashtagUtil, $window, $element) {
        return _super.call(this, $scope, iscHashtagUtil, $window, $element) || this;
    }
    IscHashtagMenuController.prototype.tagTypeName = function () {
        return 'hashtag';
    };
    IscHashtagMenuController.$inject = ['$scope', 'iscHashtagUtil', '$window', '$element'];
    return IscHashtagMenuController;
}(IscTaggingMenuBaseController));
export { IscHashtagMenuController };
