import { __awaiter } from "tslib";
export class RegisterController {
    constructor($scope, participantService, $state, _, imagesFactory, $stateParams, authService, serverConstants, loginFlowService, userService, featureToggleService, captchaService) {
        this.$scope = $scope;
        this.participantService = participantService;
        this.$state = $state;
        this._ = _;
        this.imagesFactory = imagesFactory;
        this.$stateParams = $stateParams;
        this.authService = authService;
        this.serverConstants = serverConstants;
        this.loginFlowService = loginFlowService;
        this.userService = userService;
        this.featureToggleService = featureToggleService;
        this.captchaService = captchaService;
        this.shouldShowFooter = this.featureToggleService.shouldShowFooter;
        this.images = this.imagesFactory.images;
        this.participant = {
            Email: '',
            Username: '',
            Password: '',
            ConfirmPassword: '',
            SquareParticipantGuid: this.$stateParams.sqPartId,
            AnonymousSquare: false,
            FirstName: '',
            LastName: '',
            Role: '',
            ReCaptchaResponse: '',
            GtCaptchaResponse: '',
        };
        this.validationConstants = this.serverConstants.validationConstants;
        this.dataLoading = false;
        this.showRegistrationForm = false;
        this.invalidCaptcha = false;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (yield this.validateCurrentUser()) {
                this.imagesFactory.applyImages();
                yield this.handleRoutingBySquareParticipantStatus();
                try {
                    this.$scope.$evalAsync();
                }
                catch (_a) {
                }
            }
        });
    }
    register() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataLoading = true;
            if (this.captchaService.captchaMethod === this.captchaService.RECAPTCHA) {
                this.participant.ReCaptchaResponse = yield this.captchaService.getCaptchaResponse('register');
            }
            else {
                this.participant.GtCaptchaResponse = yield this.captchaService.getCaptchaResponse('register');
            }
            try {
                const result = yield this.participantService.registerParticipant(this.participant);
                if (result.data) {
                    if (result.data.Error === 'Invalid captcha') {
                        this.invalidCaptcha = true;
                        this.dataLoading = false;
                        return;
                    }
                    if (result.data.Token) {
                        yield this.loginFlowService.loginAfterRegistration(result.data.Token);
                    }
                }
            }
            catch (error) {
                this.handleRegistrationError(error);
            }
            finally {
                this.dataLoading = false;
            }
        });
    }
    setValidity(key) {
        this.registrationForm[key].$setValidity('serverErrors', true);
    }
    validateCurrentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const loggedInUserGuid = this.userService.userGuid();
            if (loggedInUserGuid && loggedInUserGuid.toLowerCase() !== this.participant.SquareParticipantGuid.toLowerCase()) {
                yield this.authService.signOut(false, false);
                return false;
            }
            return true;
        });
    }
    handleRoutingBySquareParticipantStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.authService.getSquareParticipantDetails(this.participant.SquareParticipantGuid);
                if (response.data.Detail) {
                    if (response.data.Detail.Suspended) {
                        this.$state.go('container.login');
                    }
                    else {
                        switch (response.data.Detail.Status) {
                            case this.serverConstants.registrationStatusConstants.singleOptIn:
                            case this.serverConstants.registrationStatusConstants.singleOptInReminder: {
                                yield this.participantService
                                    .prepareParticipantForActivation(this.participant.SquareParticipantGuid);
                                this.showRegistrationForm = true;
                                break;
                            }
                            case this.serverConstants.registrationStatusConstants.active: {
                                this.$state.go('container.main.home');
                                break;
                            }
                            case this.serverConstants.registrationStatusConstants.unsubscribed: {
                                this.$state.go('container.reactivate', { sqPartId: this.participant.SquareParticipantGuid });
                                break;
                            }
                            default:
                                this.showRegistrationForm = true;
                                break;
                        }
                    }
                    this.participant.Email = response.data.Detail.Email;
                    this.participant.FirstName = response.data.Detail.FirstName;
                    this.participant.LastName = response.data.Detail.LastName;
                    this.participant.AnonymousSquare = response.data.Detail.AnonymousSquare;
                    this.participant.Role = response.data.Detail.Role;
                    this.participant.Username = response.data.Detail.Username;
                }
            }
            catch (_a) {
                this.$state.go('container.login');
            }
        });
    }
    handleRegistrationError(error) {
        if (error.status === 400) {
            const data = error.data;
            const grouped = this._.groupBy(data.ValidationErrors, 'PropertyName');
            this._.forEach(grouped, (item, key) => {
                let message = '';
                this._.forEach(item, (errorMessage) => {
                    message += `${errorMessage.ErrorMessage} `;
                });
                this.registrationForm[key].$setValidity('serverErrors', false);
                this.registrationForm[key].errorMessage = message;
            });
        }
    }
}
RegisterController.$inject = ['$scope', 'participantService', '$state', '_', 'imagesFactory', '$stateParams', 'authService',
    'serverConstants', 'loginFlowService', 'userService', 'featureToggleService', 'captchaService', '$timeout'];
