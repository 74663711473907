const redirectAbstractStatesToDefaultChild = ($transitions, $state) => {
    $transitions.onBefore({
        to: (state) => !!state.abstract && angular.isString(state.default),
    }, (transition) => {
        const toState = transition.to();
        const defaultState = toState.name + toState.default;
        return $state.target(defaultState, transition.params());
    });
};
redirectAbstractStatesToDefaultChild.$inject = ['$transitions', '$state'];
angular
    .module('blocks.router')
    .run(redirectAbstractStatesToDefaultChild);
