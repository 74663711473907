import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';

const SplashPage = () => import('@/pages/splash-page.vue');

export const splashPageRoute: RouteRecordRaw = {
  name: RouteNames.SplashPage,
  path: '/splashPage',
  component: SplashPage,
  meta: {
    requireLogin: true,
  },
};
