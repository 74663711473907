import './notifications';
import './platformhealth.service';
import { MediaService } from './media.service';
import './paramresolver';
import { CardService } from './card.service';
import { TrackingService } from './tracking.service';
import { ConversationService } from './conversation.service';
import { ConversationEventsService } from './conversationEvents.service';
import { ToastNotificationService } from './toastNotification.service';
import { SplashCheckService } from './splashCheck.service';
import { ForceMobileAppService } from './force-mobile-app.service';
import { RedirectService } from './redirect.service';
import { DownloadMobileAppService } from './download-mobile-app.service';
import { UserService } from '../dataservices/user.service';
import { FeatureToggleService } from './featureToggle.service';
import { CacheService } from './cache.service';
import { LanguageService } from './language.service';
import { LoginFlowService } from './loginflow.service';
import { SpinnerService } from './spinner.service';
import { DownloadService } from './download.service';
import { MappingService } from './mapping.service';
import { CaptchaService } from './captcha.service';
angular
    .module('insitesApp.core')
    .service('userService', UserService)
    .service('cardService', CardService)
    .service('conversationService', ConversationService)
    .service('trackingService', TrackingService)
    .service('conversationEventsService', ConversationEventsService)
    .service('toastNotificationService', ToastNotificationService)
    .service('mediaservice', MediaService)
    .service('splashCheckService', SplashCheckService)
    .service('forceMobileAppService', ForceMobileAppService)
    .service('redirectService', RedirectService)
    .service('mediaservice', MediaService)
    .service('downloadservice', DownloadService)
    .service('downloadappservice', DownloadMobileAppService)
    .service('conversationEventsService', ConversationEventsService)
    .service('languageService', LanguageService)
    .service('featureToggleService', FeatureToggleService)
    .service('cacheService', CacheService)
    .service('loginFlowService', LoginFlowService)
    .service('spinnerservice', SpinnerService)
    .service('mappingService', MappingService)
    .service('captchaService', CaptchaService);
