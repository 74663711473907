import * as angular from 'angular';
export class TaskListService {
    constructor() {
        this.tasks = [];
    }
    createTask(options) {
        const task = new Task(this);
        angular.extend(task, options);
        return task;
    }
}
class Task {
    constructor(service) {
        this.service = service;
        this.title = '';
        this.text = '';
        this.showCloseButton = false;
        this.onClick = null;
        this.theme = 'default';
    }
    show() {
        this.service.tasks.push(this);
    }
    hide() {
        const index = this.service.tasks.indexOf(this);
        if (index !== -1) {
            this.service.tasks.splice(index, 1);
        }
    }
}
Task.$inject = ['service'];
