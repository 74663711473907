export class ForumNewConversationControllerBase {
    constructor($stateParams, $state, forumService, taskList, pageThemes, emojiservice, serverConstants, mappingService, conversationService, $timeout) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.forumService = forumService;
        this.taskList = taskList;
        this.pageThemes = pageThemes;
        this.emojiservice = emojiservice;
        this.serverConstants = serverConstants;
        this.mappingService = mappingService;
        this.conversationService = conversationService;
        this.$timeout = $timeout;
        this.title = '';
        this.message = '';
        this.caption = '';
        this.stimuli = [];
        this.attachments = [];
        this.isSaving = false;
    }
    $onInit() {
        this.newStimulusFiles = [];
        this.newAttachmentFiles = [];
        this.conversationEditorConfig = {
            showTitle: true,
            showTextEditor: true,
            showCaptionEditor: false,
            saveLabel: '(LabelForumConversationEditorStartButton)',
            messageLabel: '(LabelForumConversationEditorMessage)',
            titleMaxLength: this.serverConstants.validationConstants.conversationTitleMaxLength,
            actionButtonsDisabled: this.isSaving,
            uploadConfig: {
                showImageUpload: true,
                showVideoUpload: true,
                showVideoLink: true,
                showAttachment: true,
                maxStimuli: this.serverConstants.validationConstants.conversationStimuliMaxNo,
                maxAttachments: this.serverConstants.validationConstants.conversationAttachmentMaxNo,
            },
        };
    }
    cancel() {
        this.goToRoom();
    }
    goToRoom() {
        this.$state.go('container.main.forum.room', {
            pageGuid: this.$stateParams.pageGuid,
            roomGuid: this.$stateParams.roomGuid,
        });
    }
}
ForumNewConversationControllerBase.$inject = [
    '$stateParams',
    '$state',
    'forumService',
    'taskList',
    'pageThemes',
    'emojiservice',
    'serverConstants',
    'mappingService',
    'conversationService',
    '$timeout',
];
