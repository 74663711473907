import { __awaiter } from "tslib";
export class ChallengesListController {
    constructor(challengesService, notifications, $scope, $state) {
        this.challengesService = challengesService;
        this.notifications = notifications;
        this.$state = $state;
        $scope.$watch(() => this.type, () => __awaiter(this, void 0, void 0, function* () {
            yield this.init();
        }));
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.type === undefined) {
                switch (this.$state.current.name) {
                    case 'container.main.challenges.all':
                        this.type = 'all';
                        break;
                    case 'container.main.challenges.completed':
                        this.type = 'completed';
                        break;
                    case 'container.main.challenges.todo':
                        this.type = 'todo';
                        break;
                    default:
                        this.type = 'all';
                }
            }
            this.todoActivitiesSubscription =
                this.notifications.todoActivities
                    .subscribe(() => __awaiter(this, void 0, void 0, function* () {
                    this.challengesService.listPromise = undefined;
                    yield this.loadChallenges();
                }));
            yield this.init();
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadChallenges();
            if (this.type === 'completed') {
                this.groups.forEach((group) => {
                    group.sequences.forEach((el) => {
                        if (el.startIndex !== 0) {
                            el.startIndex -= 1;
                        }
                    });
                });
            }
        });
    }
    $onDestroy() {
        if (this.todoActivitiesSubscription) {
            this.todoActivitiesSubscription();
        }
    }
    loadChallenges() {
        return __awaiter(this, void 0, void 0, function* () {
            let challenges;
            switch (this.type) {
                case 'completed':
                    challenges = yield this.challengesService.listCompletedChallenges();
                    break;
                case 'todo':
                    challenges = yield this.challengesService.listTodoChallenges();
                    break;
                default:
                    challenges = yield this.challengesService.listChallenges();
                    break;
            }
            if (challenges) {
                this.groups = challenges.groups;
                this.themeCount = challenges.themeCount;
            }
        });
    }
}
ChallengesListController.$inject = ['challengesService', 'notifications', '$scope', '$state'];
