const exception = ($q, logger) => {
    const catcher = (message) => (e) => {
        let thrownDescription;
        let newMessage;
        if (e.data && e.data.description) {
            thrownDescription = `\n${e.data.description}`;
            newMessage = message + thrownDescription;
            e.data.description = newMessage;
        }
        logger.error(newMessage);
        return $q.reject(e);
    };
    const service = {
        catcher,
    };
    return service;
};
exception.$inject = ['$q', 'logger'];
angular
    .module('blocks.exception')
    .factory('exception', exception);
