import { RedoHotkey } from './RedoHotKey';
import { UndoHotkey } from './undoHotkey';
import { UndoHotkeyMac } from './UndoHotKeyMac';
import { RedoHotkeyMac } from './RedoHotKeyMac';
var UndoRedoHotkeys = (function () {
    function UndoRedoHotkeys($document) {
        this.$document = $document;
    }
    UndoRedoHotkeys.prototype.init = function (richInput) {
        richInput.subscribeToKeyDown(new UndoHotkey(this.$document));
        richInput.subscribeToKeyDown(new UndoHotkeyMac(this.$document));
        richInput.subscribeToKeyDown(new RedoHotkey(this.$document));
        richInput.subscribeToKeyDown(new RedoHotkeyMac(this.$document));
    };
    UndoRedoHotkeys.$inject = ['$document'];
    return UndoRedoHotkeys;
}());
export default UndoRedoHotkeys;
