import { __awaiter } from "tslib";
import { EventPubSub } from 'isc-ui/dist/event/eventpubsub';
import { ReplayEventPubSub } from 'isc-ui/dist/event/replayeventpubsub';
import * as _ from 'lodash';
export class NotificationsServiceBase {
    constructor(signalr, logger, $rootScope, $q, __env, authService) {
        this.signalr = signalr;
        this.logger = logger;
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.__env = __env;
        this.authService = authService;
        this.pendingCalls = [];
        this.groups = [];
        this.hubRegistrations = {};
        this.onUserAddedToActivity = _.noop;
        this.onUserRemovedFromActivity = _.noop;
        this.disconnect = _.noop;
        this.notificationsScope = this.$rootScope.$new(true);
        this.connected = new ReplayEventPubSub(this.notificationsScope, 'SignalrConnected', $q, _);
    }
    static get ConnectionId() {
        return NotificationsServiceBase.connectionId;
    }
    addUserToGroup(groupName, isActivity) {
        const forwardCall = () => {
            this.forwardCallToHub('Notifications', 'addUserToGroup', groupName);
            if (!_.includes(this.groups, groupName)) {
                this.groups.push(groupName);
            }
        };
        if (!NotificationsServiceBase.connectionId) {
            this.pendingCalls.push(forwardCall);
        }
        else {
            forwardCall();
        }
        if (isActivity) {
            this.onUserAddedToActivity(groupName);
        }
    }
    removeUserFromGroup(groupName, isActivity) {
        const forwardCall = () => {
            this.forwardCallToHub('Notifications', 'removeUserFromGroup', groupName);
            _.remove(this.groups, (item) => item === groupName);
        };
        if (!NotificationsServiceBase.connectionId) {
            this.pendingCalls.push(forwardCall);
        }
        else {
            forwardCall();
        }
        if (isActivity) {
            this.onUserRemovedFromActivity(groupName);
        }
    }
    onLogout() {
        this.disconnect();
    }
    createEvent(hubName, signalrEvent) {
        if (!this.hubRegistrations[hubName]) {
            this.hubRegistrations[hubName] = { events: [] };
        }
        const pubSub = new EventPubSub(this.notificationsScope, signalrEvent, this.$q, _);
        this.hubRegistrations[hubName].events.push(pubSub);
        return pubSub;
    }
    createReplayEvent(hubName, signalrEvent) {
        if (!this.hubRegistrations[hubName]) {
            this.hubRegistrations[hubName] = { events: [] };
        }
        const pubSub = new ReplayEventPubSub(this.notificationsScope, signalrEvent, this.$q, _);
        this.hubRegistrations[hubName].events.push(pubSub);
        return pubSub;
    }
    rejectNotConnected() {
        return this.$q.reject('Signalr not connected');
    }
    forwardCallToHub(hubName, method, ...args) {
        if (!NotificationsServiceBase.connectionId) {
            return this.$q.reject('signalr connection not started');
        }
        const hubRegistration = this.hubRegistrations[hubName];
        if (!hubRegistration || !hubRegistration.proxy) {
            return this.$q.reject(`no hub registered with name ${hubName}`);
        }
        args = _.concat([method], args);
        const deffered = this.$q.defer();
        const signalrCall = () => {
            hubRegistration.proxy.invoke(...args).done(() => {
                deffered.resolve();
            }).fail((error) => {
                deffered.reject(error);
            });
        };
        if (hubRegistration.proxy.connection.state !== 1) {
            this.pendingCalls.push(signalrCall);
        }
        else {
            signalrCall();
        }
        return deffered.promise;
    }
    connect() {
        this.onLogout();
        const connection = this.signalr(this.__env.signalrUrl, { useDefaultPath: false, logging: this.__env.enableDebug });
        const onDisconnect = [];
        _.forEach(this.hubRegistrations, (registrations, hubName) => {
            registrations.proxy = connection.createHubProxy(hubName);
            for (const pubsub of registrations.events) {
                const off = pubsub.useEmitter(registrations.proxy);
                onDisconnect.push(off);
            }
        });
        let updateTokenInQueryString = () => __awaiter(this, void 0, void 0, function* () {
            connection.qs.token = yield this.getSignalRToken();
            if (connection.qs && connection.qs.token === '') {
                throw new Error('Empty token');
            }
        });
        let connect = () => __awaiter(this, void 0, void 0, function* () {
            connection.qs = { version: this.__env.buildVersion };
            if (this.authService.isImpersonating) {
                connection.qs.ImpersonateGuid = this.authService.impersonate.squareParticipantGuid;
            }
            yield updateTokenInQueryString();
            connection.start({ withCredentials: false, transport: ['webSockets', 'serverSentEvents', 'longPolling'] })
                .done(() => {
                NotificationsServiceBase.connectionId = connection.id;
                this.connected.next(true);
                _.forEach(this.hubRegistrations, (reg) => {
                    _.forEach(reg.events, (event) => event.resetSubscriptions());
                });
                for (const groupName of this.groups) {
                    this.addUserToGroup(groupName);
                }
                for (const pending of this.pendingCalls) {
                    pending();
                }
                this.pendingCalls = [];
            })
                .fail((e) => {
                this.logger.log(e.message);
            });
        });
        connect();
        let connectAttempts = 1;
        const maxConnectAttempts = 5;
        let timeoutInSeconds = 5;
        connection.disconnected(() => {
            if (connect && connectAttempts < maxConnectAttempts) {
                connectAttempts++;
                timeoutInSeconds = _.random(timeoutInSeconds, 60) * connectAttempts / maxConnectAttempts;
                setTimeout(() => {
                    if (connect) {
                        connect();
                    }
                }, timeoutInSeconds * 1000);
            }
        });
        this.disconnect = () => {
            for (const off of onDisconnect) {
                off();
            }
            this.pendingCalls = [];
            connection.stop();
            connect = null;
            updateTokenInQueryString = null;
            delete connection.qs;
            this.disconnect = _.noop;
            NotificationsServiceBase.connectionId = null;
            this.connected.next(false);
        };
    }
}
