import { __awaiter } from "tslib";
import { Utils } from '../../core/utils';
import { IscUIUtils } from 'isc-ui';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
export class QualActivityController {
    constructor($stateParams, $state, $scope, conversationService, userNotificationService, serverConstants, constantsfactory, constants, logger, notifications, conversationEventsService, activitySequenceDataService, activitySequenceHelperService, userService, authService, mappingService, muxService, fileStorageService, pageService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$scope = $scope;
        this.conversationService = conversationService;
        this.userNotificationService = userNotificationService;
        this.serverConstants = serverConstants;
        this.constantsfactory = constantsfactory;
        this.constants = constants;
        this.logger = logger;
        this.notifications = notifications;
        this.conversationEventsService = conversationEventsService;
        this.activitySequenceDataService = activitySequenceDataService;
        this.activitySequenceHelperService = activitySequenceHelperService;
        this.userService = userService;
        this.authService = authService;
        this.mappingService = mappingService;
        this.muxService = muxService;
        this.fileStorageService = fileStorageService;
        this.pageService = pageService;
        this.unsubscribeShowVideoThumbnail = _.noop;
        this.unsubscribeShowPhotoThumbnail = _.noop;
        this.unsubscribeUpdateAttachmentUrl = _.noop;
        this.unsubscribeDiscussionChange = _.noop;
        this.currentPage = 0;
        this.totalPages = 1;
        this.conversationElements = [];
        this.contactUrl = '';
        this.stimuli = [];
        this.sortByChanged = false;
        this.expandedPosts = [];
        this.expandLatestUpdatePost = true;
        this.prePopulateDescription = '';
        this.unsubscribeNextActivityInSequenceLaunched = _.noop;
        this.isScoutDiscussionType = false;
        this.topicWithProbeQuestions = [];
        this.isGroupByMemberEnabled = false;
        this.isGroupedByMember = false;
        this.isSequencedActivity = false;
        this.isDiscussionChanged = false;
        this.init = (...args_1) => __awaiter(this, [...args_1], void 0, function* (scrollToTopic = false) {
            let defaultSortOption = this.serverConstants.conversationSortOptionConstants.chronological;
            if (this.activityType) {
                this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);
            }
            if (this.isScoutDiscussionType) {
                defaultSortOption = this.serverConstants.conversationSortOptionConstants.mostRecent;
            }
            this.isGroupedByMember = this.$stateParams.isGroupedByMember === undefined ? false : JSON.parse(this.$stateParams.isGroupedByMember);
            if (this.sortBy === undefined) {
                this.sortBy = this.$stateParams.sort === undefined ? defaultSortOption : parseInt(this.$stateParams.sort, 10);
            }
            this.currentPage = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
            this.watchStateParams();
            this.initExpandedPosts(this.$stateParams.expandedPosts);
            yield this.loadConversationElements();
            if (scrollToTopic) {
                const openingPost = this.getOpeningPost();
                if (this.currentPage > 1) {
                    this.navigate(1);
                }
                this.scrollReply(openingPost.Guid);
            }
            else {
                const firstQuestion = _.find(this.conversationElements, (conv) => !conv.IsReadonly && this.isIndividualUpdatePost(conv));
                this.scrollReply(this.$stateParams.replyGuid ? this.$stateParams.replyGuid : (firstQuestion ? firstQuestion.Guid : null));
            }
            yield this.userNotificationService.markActivityNotificationsRead(this.activityGuid);
        });
        this.sortOptions = this.serverConstants.conversationSortOptionConstants;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setParamValues();
            this.pageDetails = yield this.pageService.getPageDetails(this.$stateParams.pageGuid);
            if (this.userService.hasModeratorRole() || this.userService.hasObserverRole() || this.userService.hasInsitesRole()) {
                this.timer = window.setInterval(() => {
                    this.automaticRefresh();
                }, 300000);
            }
            this.currentSquareParticipantGuid = this.authService.isImpersonating
                ? this.authService.impersonate.squareParticipantGuid
                : this.userService.userGuid();
            const isSequenceActivityUnlocked = yield this.activitySequenceDataService.isSequencedActivityUnlocked(this.activityGuid);
            if (isSequenceActivityUnlocked) {
                try {
                    yield this.init();
                    this.setSubscribeEvents();
                }
                catch (e) {
                    this.handleInitError(e);
                }
            }
            else {
                const firstUnlockedActivityGuid = yield this.activitySequenceDataService.getFirstIncompleteActivityGuidInSequence(this.activityGuid, null);
                this.$state.go('container.landingpage', {
                    landingTitle: '(LabelLandingPageTitleActivityLocked)',
                    landingMessage: '(LabelLandingPageMessageActivityLocked)',
                    redirectState: 'container.researchactivity',
                    activityGuid: firstUnlockedActivityGuid,
                    channelType: this.serverConstants.channelTypeConstants.researchActivityChallenge,
                    timeout: 5000,
                });
            }
        });
    }
    automaticRefresh() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isDiscussionChanged) {
                return;
            }
            this.expandLatestUpdatePost = true;
            this.loadConversationElements(true);
            this.isDiscussionChanged = false;
        });
    }
    setParamValues() {
        this.activityGuid = this.$stateParams.activityGuid;
        this.channel = this.$stateParams.channel;
        this.activityType = this.$stateParams.activityType;
    }
    handleInitError(e) {
        if (e.data) {
            switch (e.data.Reason) {
                case 'not_sampled':
                case 'activity_not_live':
                case 'activity_has_invalid_activity_guid':
                    this.$state.go('container.landingpage', {
                        landingTitle: '(LabelLandingPageTitleActivities)',
                        landingMessage: '(LabelLandingPageMessageActivityUnavailable)',
                        redirectState: 'container.main.home',
                        timeout: 5000,
                    });
                    break;
                default:
                    this.logger.error('Failed to load conversation', e);
                    break;
            }
        }
        else {
            this.logger.error('Failed to load conversation', e);
        }
    }
    watchStateParams() {
        if (!this.$stateParams.page) {
            this.$stateParams.page = 1;
        }
        if (!this.watchParams) {
            this.watchParams = this.$scope.$watchGroup([() => this.$stateParams.page, () => this.$stateParams.activityGuid], (params) => __awaiter(this, void 0, void 0, function* () {
                if (params[0]
                    && (params[0] !== this.currentPage
                        || params[1] !== this.activityGuid)) {
                    this.setParamValues();
                    yield this.init();
                }
            }));
        }
        if (!this.watchSort) {
            this.watchSort = this.$scope.$watch(() => this.sortByChanged, (sort) => __awaiter(this, void 0, void 0, function* () {
                if (sort === true) {
                    yield this.init();
                    this.sortByChanged = false;
                }
            }));
        }
    }
    setSubscribeEvents() {
        this.unsubscribeShowVideoThumbnail = this.notifications.showVideoThumbnail.subscribe((stimuli) => this.updateStimuliUrlAndThumbnailUrl(this.serverConstants.conversationStimuliTypeConstants.video, stimuli));
        this.unsubscribeShowPhotoThumbnail = this.notifications.showPhotoThumbnail.subscribe((stimuli) => this.updateStimuliUrlAndThumbnailUrl(this.serverConstants.conversationStimuliTypeConstants.image, stimuli));
        this.unsubscribeUpdateAttachmentUrl = this.notifications.updateAttachmentUrl.subscribe((attachment) => this.updateAttachmentUrl(attachment));
        const throttledloadConversationElements = _.throttle(() => __awaiter(this, void 0, void 0, function* () {
            this.expandLatestUpdatePost = true;
            yield this.loadConversationElements(true);
        }), 10000);
        this.unsubscribeDiscussionChange = this.conversationEventsService.discussionChange.subscribe(this.activityGuid, () => __awaiter(this, void 0, void 0, function* () {
            this.isDiscussionChanged = true;
            if (!this.userService.hasParticipantRole()) {
                yield throttledloadConversationElements();
            }
        }));
    }
    updateStimuliUrlAndThumbnailUrl(stimuliType, stimuliUploaded) {
        _.forEach(this.conversationElements, (item) => {
            _.forEach(item.Stimuli, (stimulus) => {
                if (stimulus.Guid === stimuliUploaded.Guid) {
                    stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
                    if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                        stimulus.Value = stimuliUploaded.Url;
                        this.fileStorageService.removeStimulusFromUploadingList(stimulus.Guid);
                    }
                    else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                        stimulus.Url = stimuliUploaded.Url;
                        this.muxService.removeStimulusFromUploadingList(stimulus.Value);
                    }
                    return false;
                }
            });
            if (item.ProbeQuestions) {
                _.forEach(item.ProbeQuestions, (probeQuestion) => {
                    if (probeQuestion.Stimuli && probeQuestion.Stimuli.length) {
                        _.forEach(probeQuestion.Stimuli, (stimulus) => {
                            if (stimulus.Guid === stimuliUploaded.Guid) {
                                stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
                                if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                                    stimulus.Value = stimuliUploaded.Url;
                                    this.fileStorageService.removeStimulusFromUploadingList(stimulus.Guid);
                                }
                                else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                                    stimulus.Url = stimuliUploaded.Url;
                                    this.muxService.removeStimulusFromUploadingList(stimulus.Value);
                                }
                                return false;
                            }
                        });
                    }
                    if (probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
                        _.forEach(probeQuestion.Answer.Stimuli, (stimulus) => {
                            if (stimulus.Guid === stimuliUploaded.Guid) {
                                stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
                                if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                                    stimulus.Value = stimuliUploaded.Url;
                                    this.fileStorageService.removeStimulusFromUploadingList(stimulus.Guid);
                                }
                                else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                                    stimulus.Url = stimuliUploaded.Url;
                                    this.muxService.removeStimulusFromUploadingList(stimulus.Value);
                                }
                                return false;
                            }
                        });
                    }
                });
            }
            if (item.ProbeQuestionsForEditor) {
                _.forEach(item.ProbeQuestionsForEditor, (probeQuestion) => {
                    if (probeQuestion.answer && probeQuestion.answer.stimuli && probeQuestion.answer.stimuli.length) {
                        _.forEach(probeQuestion.answer.stimuli, (stimulus) => {
                            if (stimulus.guid === stimuliUploaded.Guid) {
                                stimulus.thumbnailUrl = stimuliUploaded.ThumbnailUrl;
                                if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                                    stimulus.value = stimuliUploaded.Url;
                                    this.fileStorageService.removeStimulusFromUploadingList(stimulus.guid);
                                }
                                else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                                    stimulus.url = stimuliUploaded.Url;
                                    this.muxService.removeStimulusFromUploadingList(stimulus.value);
                                }
                                return false;
                            }
                        });
                    }
                });
            }
        });
        this.$scope.$broadcast(`broken_stimulus_${stimuliUploaded.Guid}`, { url: stimuliUploaded.Url, thumbnail: stimuliUploaded.ThumbnailUrl });
    }
    updateAttachmentUrl(attachmentUploaded) {
        _.forEach(this.conversationElements, (item) => {
            _.forEach(item.Attachments, (attachment) => {
                if (attachment.Guid === attachmentUploaded.Guid) {
                    attachment.Url = attachmentUploaded.Url;
                }
                return false;
            });
            if (item.ProbeQuestions) {
                _.forEach(item.ProbeQuestions, (probeQuestion) => {
                    if (probeQuestion.Attachments && probeQuestion.Attachments.length) {
                        _.forEach(probeQuestion.Attachments, (attachment) => {
                            if (attachment.Guid === attachmentUploaded.Guid) {
                                attachment.Url = attachmentUploaded.Url;
                                return false;
                            }
                        });
                    }
                    if (probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
                        _.forEach(probeQuestion.Answer.Attachments, (attachment) => {
                            if (attachment.Guid === attachmentUploaded.Guid) {
                                attachment.Url = attachmentUploaded.Url;
                                return false;
                            }
                        });
                    }
                });
            }
            if (item.ProbeQuestionsForEditor) {
                _.forEach(item.ProbeQuestionsForEditor, (probeQuestion) => {
                    if (probeQuestion.answer && probeQuestion.answer.attachments && probeQuestion.answer.attachments.length) {
                        _.forEach(probeQuestion.answer.attachments, (attachment) => {
                            if (attachment.guid === attachmentUploaded.Guid) {
                                attachment.url = attachmentUploaded.Url;
                                return false;
                            }
                        });
                    }
                });
            }
        });
    }
    $onDestroy() {
        this.unsubscribeShowVideoThumbnail();
        this.unsubscribeShowPhotoThumbnail();
        this.unsubscribeUpdateAttachmentUrl();
        this.unsubscribeDiscussionChange();
        this.unsubscribeNextActivityInSequenceLaunched();
        clearInterval(this.timer);
        if (this.watchParams) {
            this.watchParams();
        }
        if (this.watchSort) {
            this.watchSort();
        }
    }
    initExpandedPosts(expandedPosts) {
        if (expandedPosts !== undefined) {
            this.expandedPosts = _.isArray(expandedPosts) ? expandedPosts : [expandedPosts];
        }
    }
    toggleReplies(conversation) {
        conversation.IsExpanded = !conversation.IsExpanded;
        if (conversation.IsExpanded) {
            this.expandedPosts = _.union(this.expandedPosts, [conversation.Guid]);
        }
        else {
            this.expandedPosts = _.without(this.expandedPosts, conversation.Guid);
        }
        this.loadConversationElements(true);
    }
    scrollReply(replyGuid) {
        if (replyGuid) {
            Utils.anchorScrollWithWait(replyGuid);
        }
    }
    loadConversationElements(keepUnpublishedAnswer) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.conversationService.loadQualConversationElements(this.activityGuid, this.currentPage, this.constants.itemsPerPage, this.sortBy, this.expandedPosts, this.expandLatestUpdatePost, this.isGroupedByMember);
            yield this.initConversationElements(response, keepUnpublishedAnswer);
        });
    }
    initConversationElements(loadConversationsResponse, keepUnpublishedAnswer) {
        return __awaiter(this, void 0, void 0, function* () {
            this.title = loadConversationsResponse.ActivityTitle;
            this.topicWithProbeQuestions = loadConversationsResponse.TopicWithProbeQuestions;
            this.prePopulateDescription = loadConversationsResponse.PrePopulateDescription;
            this.conversationFocusType = loadConversationsResponse.ConversationFocusType;
            this.contributionType = loadConversationsResponse.ContributionType;
            this.isDiary = loadConversationsResponse.ContributionType === this.serverConstants.activityContributionTypeConstants.diary;
            this.visibilityBufferPeriodPassed = loadConversationsResponse.VisibilityBufferPeriodPassed;
            this.isSequencedActivity = loadConversationsResponse.ActivitySequenceRole !== this.serverConstants.activitySequenceRoleConstants.unsequenced;
            const conversationElements = loadConversationsResponse.ConversationElements;
            const parent = Object.assign({}, this.conversationElements.find((item) => item.Guid === item.FirstParentGuid));
            const userGuid = this.userService.userGuid();
            conversationElements.Items.forEach((item) => {
                if (item.Stimuli.length > 0) {
                    item.Stimuli.forEach((stimulus) => {
                        if (stimulus.IsBroken === true) {
                            stimulus.IsBrokenAndBelongsToCurrentUser = item.SquareParticipantGuid === userGuid;
                        }
                    });
                }
            });
            this.conversationElements = conversationElements.Items;
            this.isGroupByMemberEnabled = this.isDiary && !this.userService.hasParticipantRole();
            const isScoutDiscussionTypeFromBackend = IscUIUtils.isActivityScoutDiscussionType(loadConversationsResponse.ActivityType, this.serverConstants);
            if (isScoutDiscussionTypeFromBackend !== this.isScoutDiscussionType) {
                this.activityType = loadConversationsResponse.ActivityType;
                this.init();
            }
            this.isDividedDiscussion = this.isActivityDividedDiscussionType(loadConversationsResponse.ActivityType);
            if (this.isDividedDiscussion || this.isScoutDiscussionType) {
                this.initProbeQuestionDiscussions();
            }
            if (!_.isEmpty(parent) && keepUnpublishedAnswer) {
                const parentIndex = this.conversationElements.findIndex((item) => item.Guid === item.FirstParentGuid);
                this.conversationElements[parentIndex] = parent;
            }
            this.conversationElements = this.conversationService.addInDraftRepliesToPost(this.conversationElements);
            this.totalPages = conversationElements.TotalPages;
            const latestUpdatePageIndex = loadConversationsResponse.LatestUpdatePageIndex;
            const latestUpdateGuid = loadConversationsResponse.LatestUpdateGuid;
            this.latestUpdatePageIndex = latestUpdatePageIndex;
            yield this.setPostUpdateRedirectText(latestUpdatePageIndex, latestUpdateGuid);
            if (this.expandLatestUpdatePost) {
                this.initExpandLatestUpdatePost(loadConversationsResponse);
            }
            this.setDisplayLevel();
        });
    }
    initExpandLatestUpdatePost(loadConversationsResponse) {
        const level1ParentMessageGuids = this.conversationElements
            .filter((el) => el.Level === 1)
            .map((el) => el.ParentMessage);
        const expandedTopicGuids = this.conversationElements
            .filter((el) => el.Level === 0 && level1ParentMessageGuids.indexOf(el.Guid) !== -1)
            .map((el) => el.Guid);
        this.expandedPosts = _.uniq([loadConversationsResponse.LatestUpdateGuid].concat(expandedTopicGuids));
        this.expandLatestUpdatePost = false;
    }
    initProbeQuestionDiscussions() {
        this.setAllQuestionsAnswered();
        this.addAnswersToProbeQuestions();
        this.addAnswerSetsOfTopicToConversation();
        this.addProbingQuestionsToParentMessage();
        this.createIndividualUpdatePostConversations();
        this.filterIndividualUpdatePosts();
        this.setIsNewContributionNeeded();
        this.setCompleteCriteriaMessage();
        this.setAcceptedVisible();
    }
    setAllQuestionsAnswered() {
        const questionsForCurrentSquareParticipant = this.conversationElements
            .filter((element) => {
            if (element.IsInDraft) {
                return false;
            }
            const parentMessage = this.getConversationByGuid(element.ParentMessage);
            if (!parentMessage) {
                return;
            }
            const grandParentMessage = this.getConversationByGuid(parentMessage.ParentMessage);
            if (!grandParentMessage) {
                return;
            }
            return (this.isOpeningPost(parentMessage)
                || (parentMessage.ParentSquareParticipantGuid && parentMessage.ParentSquareParticipantGuid === this.currentSquareParticipantGuid))
                && this.isQuestion(element);
        });
        this.allQuestionsAnswered = !questionsForCurrentSquareParticipant ||
            !questionsForCurrentSquareParticipant.length ||
            questionsForCurrentSquareParticipant.every((element) => element.HasBeenAnswered === true);
        this.allUnansweredQuestionsOptional = !_.some(questionsForCurrentSquareParticipant, (q) => !q.HasBeenAnswered && q.AnswerRequired);
    }
    addAnswersToProbeQuestions() {
        const allTopicQuestionsAnswered = this.conversationElements
            .filter((element) => this.isQuestion(element) && element.Level === 0)
            .every((element) => element.HasBeenAnswered);
        const probeAnswers = this.conversationElements
            .filter((element) => this.isAnswer(element));
        const probeAnswersToRemoveFromList = [];
        const probeQuestionGuids = this.conversationElements
            .filter((element) => this.isQuestion(element))
            .map((element) => element.Guid);
        this.conversationElements
            .filter((element) => probeQuestionGuids.indexOf(element.Guid) !== -1)
            .forEach((element) => {
            const parentMessage = this.getConversationByGuid(element.ParentMessage);
            let answer;
            if (this.isTopic(parentMessage)) {
                if (allTopicQuestionsAnswered && !this.isDiary) {
                    return;
                }
                answer = _.find(probeAnswers, (a) => a.ParentMessage === element.Guid &&
                    a.SquareParticipantGuid === this.currentSquareParticipantGuid &&
                    a.IsInDraft);
                if (answer) {
                    probeAnswersToRemoveFromList.push(answer);
                }
            }
            else if (this.isIndividualUpdatePost(parentMessage)) {
                answer = _.find(probeAnswers, (a) => a.ParentMessage === element.Guid);
                if (answer && !answer.IsInDraft) {
                    parentMessage.IsReadonly = true;
                    parentMessage.HasBeenAnswered = true;
                }
            }
            element.Answer = {
                Guid: answer ? answer.Guid : undefined,
                ProbeQuestionGuid: element.Guid,
                Message: answer ? answer.Message : '',
                Caption: answer ? answer.Caption : '',
                Stimuli: answer ? answer.Stimuli : [],
                Attachments: answer ? answer.Attachments : [],
                HasBeenSaved: answer != null,
                IsValidAnswer: answer ? answer.IsValidAnswer : false,
                IsInDraft: answer ? answer.IsInDraft : false,
            };
        });
        this.conversationElements = this.conversationElements.filter((element) => probeAnswersToRemoveFromList.indexOf(element) === -1);
    }
    addAnswerSetsOfTopicToConversation() {
        const topic = this.getOpeningPost();
        const topicQuestions = this.topicWithProbeQuestions
            .filter((element) => this.isQuestion(element));
        const answers = this.conversationElements
            .filter((element) => {
            const parentMessageIsQuestion = topicQuestions.map((question) => question.Guid).indexOf(element.ParentMessage) !== -1;
            return this.isAnswer(element) && !element.IsInDraft && parentMessageIsQuestion;
        });
        const squareParticipantGuidSetPairs = _.uniqBy(answers.map((a) => ({
            SquareParticipantGuid: a.SquareParticipantGuid,
            Set: a.Set,
        })), 'Set');
        const squareParticipantGuidSetsList = squareParticipantGuidSetPairs.reduce((list, answerSetPair) => {
            list[answerSetPair.SquareParticipantGuid] = [...list[answerSetPair.SquareParticipantGuid] || [], answerSetPair.Set];
            return list;
        }, {});
        const sets = _.uniq(answers.map((element) => element.Set));
        _.each(sets, (set) => {
            const probeAnswers = this.conversationElements.filter((element) => this.isAnswer(element) && element.Set === set);
            const firstAnswer = probeAnswers[0];
            const lastAnswer = probeAnswers[probeAnswers.length - 1];
            if (!firstAnswer) {
                return false;
            }
            const questions = angular.copy(topicQuestions);
            questions
                .forEach((question) => {
                const answer = _.find(probeAnswers, (a) => a.ParentMessage === question.Guid);
                question.Answer = {
                    Guid: answer ? answer.Guid : undefined,
                    ProbeQuestionGuid: question.Guid,
                    Message: answer ? answer.Message : '',
                    Caption: answer ? answer.Caption : '',
                    Stimuli: answer ? answer.Stimuli : [],
                    Attachments: answer ? answer.Attachments : [],
                    IsValidAnswer: answer ? answer.IsValidAnswer : false,
                };
            });
            const datePosted = lastAnswer ? lastAnswer.DatePosted : firstAnswer.DatePosted;
            const answerSet = Object.assign(Object.assign({}, firstAnswer), { Message: '', Caption: '', Stimuli: [], Attachments: [], ParentMessage: topic.Guid, IsReadonly: true, DatePosted: datePosted, ProbeQuestions: questions });
            if (this.isDiary) {
                let indexOfSet = answerSet.SetIndex;
                if (!indexOfSet) {
                    const squareParticipantSets = squareParticipantGuidSetsList[firstAnswer.SquareParticipantGuid].sort();
                    indexOfSet = squareParticipantSets.indexOf(set);
                }
                answerSet.Title = `#${indexOfSet + 1} - ${DateTime.fromISO(datePosted).toLocaleString(DateTime.DATETIME_MED)}`;
            }
            this.conversationElements.splice(_.indexOf(this.conversationElements, firstAnswer), probeAnswers.length, answerSet);
        });
    }
    addProbingQuestionsToParentMessage() {
        const probeQuestions = this.conversationElements
            .filter((element) => this.isQuestion(element));
        if (probeQuestions.length === 0) {
            return;
        }
        const openingPost = this.getOpeningPost();
        if (openingPost && openingPost.IsOneByOne && !this.userService.hasParticipantRole()) {
            openingPost.IsOneByOne = false;
            this.conversationElements = this.conversationElements
                .filter((element) => !this.isQuestion(element) || probeQuestions.indexOf(element) !== -1);
        }
        const parentMessageGuids = probeQuestions.map((element) => element.ParentMessage);
        this.conversationElements.filter((element) => parentMessageGuids.indexOf(element.Guid) !== -1).forEach((element) => {
            element.ProbeQuestions = [...probeQuestions.filter(((q) => q.ParentMessage === element.Guid))];
            element.ProbeQuestionsForEditor = this.mappingService.mapProbeQuestionsForProbeQuestionEditor(element.ProbeQuestions);
            if (this.isIndividualUpdatePost(element)) {
                const parentMessage = this.getConversationByGuid(element.ParentMessage);
                if (!parentMessage) {
                    return;
                }
                element.IsReadonly = (parentMessage.SquareParticipantGuid !== this.userService.userGuid()
                    || this.authService.impersonate !== null) || element.HasBeenAnswered;
            }
        });
        this.conversationElements = this.conversationElements.filter((element) => probeQuestions.indexOf(element) === -1);
        this.conversationElements = this.conversationElements.map((element) => {
            if (element.ProbeQuestions && element.ProbeQuestions.length > 0) {
                element.ProbeQuestionsForEditor = this.mappingService.mapProbeQuestionsForProbeQuestionEditor(element.ProbeQuestions);
            }
            return element;
        });
    }
    createIndividualUpdatePostConversations() {
        const answeredIndividualUpdatePosts = this.conversationElements.filter((element) => this.isIndividualUpdatePost(element) && element.HasBeenAnswered);
        _.forEach(answeredIndividualUpdatePosts, (individualUpdatePost) => {
            const firstQuestion = individualUpdatePost.ProbeQuestions[0];
            const firstAnswer = _.find(this.conversationElements, (element) => element.ParentMessage === firstQuestion.Guid);
            const lastQuestion = individualUpdatePost.ProbeQuestions[individualUpdatePost.ProbeQuestions.length - 1];
            const lastAnswer = _.find(this.conversationElements, (element) => element.ParentMessage === lastQuestion.Guid);
            const conversationElement = Object.assign(Object.assign({}, firstAnswer), { Message: '', Caption: '', Stimuli: [], Attachments: [], ParentMessage: individualUpdatePost.Guid, DatePosted: lastAnswer.DatePosted, ProbeQuestions: angular.copy(individualUpdatePost.ProbeQuestions), ProbeQuestionsForEditor: angular.copy(individualUpdatePost.ProbeQuestionsForEditor), IsReadonly: true, HasBeenAnswered: true });
            individualUpdatePost.HasBeenAnswered = false;
            _.forEach(individualUpdatePost.ProbeQuestions, (question) => {
                question.Answer = null;
            });
            if (_.indexOf(this.conversationElements, individualUpdatePost) + 1 === this.conversationElements.length) {
                this.conversationElements.push(conversationElement);
            }
            else {
                this.conversationElements.splice(_.indexOf(this.conversationElements, individualUpdatePost) + 1, 0, conversationElement);
            }
        });
        this.conversationElements = this.conversationElements
            .filter((element) => !this.isAnswer(element) || element.ProbeQuestions);
    }
    filterIndividualUpdatePosts() {
        const postsToBeRemoved = [];
        this.conversationElements
            .filter((element) => this.isIndividualUpdatePost(element) || this.isReplyWithAnswerRequired(element))
            .forEach((element) => {
            const onlyOptionalQuestions = !_.some(element.ProbeQuestionsForEditor, (q) => q.answerRequired);
            const isReplyWithAnswerRequired = this.isReplyWithAnswerRequired(element);
            const updateLabelRequired = (element.ProbeQuestionsForEditor &&
                element.ProbeQuestionsForEditor.length &&
                !onlyOptionalQuestions) ||
                isReplyWithAnswerRequired;
            if (updateLabelRequired) {
                const parent = this.conversationElements.filter((p) => p.Guid === element.ParentMessage)[0] || null;
                if (parent && (!onlyOptionalQuestions || isReplyWithAnswerRequired)) {
                    parent.UpdateLabel = '(LabelQualConversationUpdateRequired)';
                }
                if (element.ProbeQuestionsForEditor &&
                    element.ProbeQuestionsForEditor.every((q) => q.hasBeenAnswered && q.answer && q.answer.guid && !q.answer.isInDraft)) {
                    if (this.isScoutDiscussionType && this.userService.hasParticipantRole()) {
                        postsToBeRemoved.push(element);
                    }
                    const answerPost = this.conversationElements.filter((p) => p.ParentMessage === element.Guid)[0] || null;
                    if (answerPost && !onlyOptionalQuestions) {
                        answerPost.UpdateLabel = '(LabelQualConversationAnswerUpdated)';
                    }
                }
                else if (element.ParentSquareParticipantGuid &&
                    element.ParentSquareParticipantGuid !== this.currentSquareParticipantGuid &&
                    this.userService.hasParticipantRole()) {
                    postsToBeRemoved.push(element);
                }
            }
        });
        this.conversationElements = this.conversationElements.filter((element) => postsToBeRemoved.indexOf(element) === -1);
    }
    setIsNewContributionNeeded() {
        const currentSquareParticipantAnswers = this.conversationElements.filter((c) => this.isAnswer(c) && c.Level === 1 && c.SquareParticipantGuid === this.currentSquareParticipantGuid);
        this.isNewContributionNeeded =
            currentSquareParticipantAnswers &&
                currentSquareParticipantAnswers.length > 0 &&
                !currentSquareParticipantAnswers.some((a) => a.IsAccepted) &&
                currentSquareParticipantAnswers.every((a) => this.conversationElements.some((r) => r.ParentMessage === a.Guid && this.isReplyWithAnswerRequired(r)));
    }
    setCompleteCriteriaMessage() {
        if (!this.allQuestionsAnswered && !this.allUnansweredQuestionsOptional) {
            this.completeCriteriaMessage = '(QualConversationProbeCompleteCriteriaMessage)';
        }
        else if (this.isNewContributionNeeded) {
            this.completeCriteriaMessage = '(QualConversationNewSubmissionCompleteCriteriaMessage)';
        }
    }
    setDisplayLevel() {
        this.conversationElements
            .forEach((element) => {
            if (this.isScoutDiscussionType &&
                (this.isIndividualUpdatePost(element) || this.isAnswer(element) || this.isReplyWithAnswerRequired(element))) {
                element.DisplayLevel = 1;
            }
            else {
                element.DisplayLevel = element.Level;
            }
        });
    }
    setAcceptedVisible() {
        this.conversationElements
            .forEach((element) => {
            element.IsAcceptedVisible =
                element.IsAccepted
                    && this.isDiary
                    && this.isAnswer(element);
            if (element.IsAcceptedVisible) {
                element.UpdateLabel = null;
            }
        });
    }
    isQuestion(element) {
        return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.question;
    }
    isAnswer(element) {
        return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.answer;
    }
    isTopic(element) {
        return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.topic;
    }
    isIndividualUpdatePost(element) {
        return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost;
    }
    isActivityDividedDiscussionType(activityType) {
        return parseInt(activityType, 10) === this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch ||
            parseInt(activityType, 10) === this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch;
    }
    isReplyWithAnswerRequired(element) {
        return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.reply && element.AnswerRequired;
    }
    getConversationByGuid(conversationGuid) {
        let conversation = _.find(this.conversationElements, (c) => c.Guid === conversationGuid);
        if (!conversation) {
            conversation = _.find(this.topicWithProbeQuestions, (c) => c.Guid === conversationGuid);
        }
        return conversation;
    }
    getOpeningPost() {
        let openingPost = _.find(this.conversationElements, (c) => this.isTopic(c) && c.Guid === c.ParentMessage);
        if (!openingPost) {
            openingPost = _.find(this.topicWithProbeQuestions, (c) => this.isTopic(c) && c.Guid === c.ParentMessage);
        }
        return openingPost;
    }
    navigate(pageNumber) {
        this.conversationService.navigate(pageNumber, this.sortBy, this.expandedPosts, this.isGroupedByMember);
    }
    setPostUpdateRedirectText(latestUpdatePageIndex, latestUpdateGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const stateParams = this.conversationService.makeNavigationStateParams(latestUpdatePageIndex, this.sortBy, this.expandedPosts);
            const link = this.$state.href('container.main.challenges.qualactivity', Object.assign(Object.assign({}, stateParams), { replyGuid: latestUpdateGuid }));
            let value = yield this.constantsfactory.getLabelValue('LabelQualConversationPostUpdated');
            value = `<span>${value}`;
            let label = value.replace(/\{(\w+)\|(url)\}/g, (match, text) => `</span><a class="link" href="${link}">${text}</a><span>`);
            label += '</span>';
            this.postUpdateRedirectText = label;
        });
    }
    onSortChange(value) {
        this.sortByChanged = true;
        this.sortBy = value;
        this.navigate(1);
    }
    shouldShowPostUpdateRedirect(item) {
        return this.isOpeningPost(item) && this.latestUpdatePageIndex > 1;
    }
    isOpeningPost(item) {
        return item.Guid === item.ParentMessage;
    }
    showQuestionsForPost(item) {
        let result = false;
        if (item.ProbeQuestions && item.ProbeQuestions.length > 1) {
            result = true;
        }
        else {
            if (item.Level > 1) {
                result = true;
            }
            else {
                if (item.ProbeQuestions && item.ProbeQuestions.length === 1) {
                    result = !item.IsValidAnswer;
                }
            }
        }
        return result;
    }
}
QualActivityController.$inject = ['$stateParams', '$state', '$scope', 'conversationService',
    'userNotificationService', 'serverConstants',
    'constantsfactory', 'constants', 'logger', 'notifications',
    'conversationEventsService', 'activitySequenceDataService', 'activitySequenceHelperService',
    'userService', 'authService', 'mappingService', 'muxService', 'fileStorageService', 'pageService'];
