export class IncentivesCustomController {
    constructor($stateParams, pageService, $state) {
        this.$stateParams = $stateParams;
        this.pageService = pageService;
        this.$state = $state;
    }
    $onInit() {
        this.pageService.getPageDetails(this.$stateParams.customPage).then((pageDetails) => {
            if (pageDetails === null) {
                this.$state.go('container.main.home');
                return;
            }
            delete pageDetails.Name;
            this.pageDetails = pageDetails;
        });
    }
}
IncentivesCustomController.$inject = ['$stateParams', 'pageService', '$state'];
