import { __awaiter, __generator } from "tslib";
import { DateTime } from 'luxon';
var NewsCardController = (function () {
    function NewsCardController($element) {
        this.$element = $element;
        this.isCardViewed = false;
    }
    NewsCardController.prototype.$onInit = function () {
        this.card.DateDiff = DateTime.fromISO(this.card.StartDateTime).toRelative({ base: DateTime.utc() });
    };
    NewsCardController.prototype.$postLink = function () {
        this.unregisterCard = this.timeline.registerCard(this.card.Guid, this.$element);
    };
    NewsCardController.prototype.$onDestroy = function () {
        this.unregisterCard();
    };
    NewsCardController.prototype.likeClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.onLikeClick(this.card)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    NewsCardController.prototype.ctaClick = function () {
        this.onCtaClick({ card: this.card });
    };
    NewsCardController.prototype.trackViewed = function () {
        if (!this.isCardViewed) {
            this.isCardViewed = true;
            this.onTrackViewed({ card: this.card });
        }
    };
    NewsCardController.prototype.isSticky = function () {
        return this.card.StickyDate;
    };
    NewsCardController.$inject = ['$element'];
    return NewsCardController;
}());
export { NewsCardController };
