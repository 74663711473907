const logger = ($log, $injector) => {
    let $mdToast;
    const error = (message, data) => {
        if (!$mdToast) {
            $mdToast = $injector.get('$mdToast');
        }
        $mdToast.show({
            hideDelay: 3000,
            position: 'bottom right',
            controller: 'ToastController',
            controllerAs: 'vm',
            template: `${'<md-toast class="toast-error">' +
                '<span class="md-toast-text">'}${message}</span>` +
                '<md-icon ng-click="vm.closeToast()" class="typcn typcn-times"></md-icon>' +
                '</md-toast>',
        });
        $log.error(`Error: ${message}`, data);
    };
    const info = (message, data) => {
        $log.info(`Info: ${message}`, data);
    };
    const success = (message, data) => {
        if (!$mdToast) {
            $mdToast = $injector.get('$mdToast');
        }
        $log.info(`Success: ${message}`, data);
    };
    const warning = (message, data) => {
        if (!$mdToast) {
            $mdToast = $injector.get('$mdToast');
        }
        $log.warn(`Warning: ${message}`, data);
    };
    const service = {
        showToasts: true,
        error,
        info,
        success,
        warning,
        log: $log.log,
    };
    return service;
};
logger.$inject = ['$log', '$injector'];
class ToastController {
    constructor($mdToast) {
        this.$mdToast = $mdToast;
    }
    closeToast() {
        this.$mdToast.hide();
    }
}
ToastController.$inject = ['$mdToast'];
angular
    .module('blocks.logger')
    .factory('logger', logger)
    .controller('ToastController', ToastController);
