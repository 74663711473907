import { __awaiter } from "tslib";
export class DownloadMobileAppController {
    constructor(downloadappservice, mediaservice) {
        this.downloadappservice = downloadappservice;
        this.mediaservice = mediaservice;
        this.downloadUrl = null;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this._shouldSuggestMobileAppDownload = yield this.downloadappservice.shouldSuggestMobileAppDownload();
            if (this._shouldSuggestMobileAppDownload) {
                this.downloadUrl = this.downloadappservice.getMobileAppDownloadUrl();
            }
        });
    }
    hide() {
        this.shouldSuggestMobileAppDownload = false;
    }
    get shouldSuggestMobileAppDownload() {
        return this._shouldSuggestMobileAppDownload &&
            this.downloadUrl != null &&
            !this.mediaservice.isMobileApp &&
            (this.mediaservice.isMobile || this.mediaservice.isTablet);
    }
    set shouldSuggestMobileAppDownload(value) {
        this._shouldSuggestMobileAppDownload = value;
    }
}
DownloadMobileAppController.$inject = ['downloadappservice', 'mediaservice'];
