import { appInsights } from '../../lib/appInsights';
import { INSTRUMENTATION_KEY } from './env.config';

let appInsightsObject: ReturnType<typeof appInsights>;

export const setupAppInsights = () => {
  if (!appInsightsObject) {
    appInsightsObject = appInsights({
      instrumentationKey: INSTRUMENTATION_KEY,
    });
  }
};

export const getAppInsights = () => appInsightsObject;
