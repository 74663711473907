import { Isc404PageController } from './isc-404-page.controller';
import { UiModule } from '../../index';
var isc404PageTemplate = "<md-content class=\"page-not-found-page\">\n  <div class=\"image-top-container\">\n    <img src=\"{{vm.pageNotFoundImage}}\" alt=\"Page not found\">\n  </div>\n  <h1 class=\"title-1\">\n    Oops!\n  </h1>\n  <h1 class=\"title-2\">\n    Page not found.\n  </h1>\n  <div class=\"content\">\n    <p>\n      We can't seem to find the page you're looking for.\n    </p>\n    <p>\n      If you think something is broken, please\n      <a href=\"mailto:support@square-community.io\">get in touch</a>.\n    </p>\n  </div>\n  <div class=\"contact\">\n    <!-- NEW LOGO: The new square logo should be displayed here. Check the history for more details -->\n    <a href=\"mailto:support@square-community.io\">Contact</a>\n  </div>\n</md-content>\n";
UiModule
    .component('isc404Page', {
    controller: Isc404PageController,
    template: isc404PageTemplate,
    controllerAs: 'vm',
    bindings: {
        pageNotFoundImage: '@',
        squareLogo: '@',
    },
});
