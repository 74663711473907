import { __extends } from "tslib";
import { KeyEventSubscription } from './IKeyEventSubscription';
var HotkeySubscription = (function (_super) {
    __extends(HotkeySubscription, _super);
    function HotkeySubscription() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ctrlKey = false;
        _this.shiftKey = false;
        _this.altKey = false;
        _this.metaKey = false;
        return _this;
    }
    HotkeySubscription.prototype.match = function (event) {
        return _super.prototype.match.call(this, event)
            && event.altKey === this.altKey
            && event.ctrlKey === this.ctrlKey
            && event.metaKey === this.metaKey
            && event.shiftKey === this.shiftKey;
    };
    return HotkeySubscription;
}(KeyEventSubscription));
export { HotkeySubscription };
