import { UiModule } from './../../../../../index';
var nwBlankLinkTemplate = "<button class=\"nw-button fa-link\" title=\"Link\" ng-click=\"vm.addLink()\" ng-disabled=\"vm.editMode || vm.disabled\">'Link\n</button>";
UiModule
    .component('iscNwTargetBlankLink', {
    template: nwBlankLinkTemplate,
    controller: TargetBlankLinkController,
    controllerAs: 'vm',
    bindings: {
        execCommand: '=',
        editMode: '=',
        disabled: '=',
        content: '=',
    },
});
TargetBlankLinkController.$inject = ['$element', 'iscDomhelperservice', 'iscLabelService', '$timeout'];
function TargetBlankLinkController($element, iscDomhelperservice, iscLabelService, $timeout) {
    var vm = this;
    var ngWig;
    var ngWigEditor;
    vm.$postLink = function () {
        $timeout(function () {
            ngWig = iscDomhelperservice.findAncestor($element[0], 'ng-wig');
            ngWigEditor = ngWig.querySelector('#ng-wig-editable');
        });
    };
    vm.addLink = function () {
        iscLabelService.getLabelValue('LabelLinkPrompt').then(function (label) {
            var url = window.prompt(label, 'http://');
            if (!url) {
                return;
            }
            var selectedText = window.getSelection().toString();
            var text = selectedText || url;
            var html = createLinkHtml(url, text);
            iscDomhelperservice.insertHtmlInNgWig(html, ngWigEditor);
        });
    };
    function createLinkHtml(url, text) {
        var element = document.createElement('a');
        element.target = '_blank';
        element.href = url;
        element.innerText = text;
        var wrapper = document.createElement('div');
        wrapper.appendChild(element);
        var anchorHtml = wrapper.innerHTML;
        return anchorHtml;
    }
}
