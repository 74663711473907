import { IscRedemptionThresholdController } from './isc-redemption-threshold.controller';
var iscRedemptionThresholdTemplate = "<table class=\"container\">\n  <tr>\n    <td>\n      <isc-redemption-threshold-card title-text=\"{{vm.labelPointsSavedUp}}\" points=\"vm.pointsSavedUp\"\n        unit-label=\"{{vm.unitLabel}}\">\n      </isc-redemption-threshold-card>\n    </td>\n    <td ng-if=\"vm.isAutomaticHandlingEnabled\" class=\"connecting-line-column\">\n      <span class=\"connecting-line active\"></span>\n    </td>\n    <td ng-if=\"vm.isAutomaticHandlingEnabled\">\n      <isc-redemption-threshold-card title-text=\"{{vm.labelPointsRequiredToRedeem}}\"\n        points=\"vm.canRedeem ? 0 : (vm.minimumPointsToRedeem - vm.pointsSavedUp > 0 ? vm.minimumPointsToRedeem - vm.pointsSavedUp : 0)\" unit-label=\"{{vm.unitLabel}}\">\n      </isc-redemption-threshold-card>\n    </td>\n    <td ng-if=\"vm.isAutomaticHandlingEnabled\" class=\"connecting-line-column\">\n      <span class=\"{{vm.canRedeem ? 'connecting-line active' : 'connecting-line'}}\"></span>\n    </td>\n    <td ng-if=\"vm.isAutomaticHandlingEnabled\">\n      <isc-redemption-threshold-card class=\"{{(vm.canRedeem) ? 'active' : ''}}\"\n        title-text=\"{{(vm.canRedeem) ? vm.labelGetReward : vm.labelMinimumPointsToRedeem}}\"\n        points=\"vm.canRedeem ? vm.pointsSavedUp : vm.minimumPointsToRedeem\" unit-label=\"{{vm.unitLabel}}\"\n        value=\"vm.canRedeem ? vm.totalValue.toFixed(2) : vm.remainderForMinimum.toFixed(2)\"\n        currency=\"{{vm.currency}}\" ng-click=\"vm.redeemAllowed && vm.redeem()\">\n      </isc-redemption-threshold-card>\n    </td>\n  </tr>\n</table>";
export var IscRedemptionThresholdComponent = {
    template: iscRedemptionThresholdTemplate,
    controller: IscRedemptionThresholdController,
    controllerAs: 'vm',
    bindings: {
        pointsSavedUp: '<',
        minimumPointsToRedeem: '<',
        totalValue: '<',
        currency: '@',
        remainderForMinimum: '<',
        unitLabel: '@',
        redeemAllowed: '<',
        onRedeem: '&',
        isAutomaticHandlingEnabled: '<',
    },
};
