import { __extends } from "tslib";
import { HotkeySubscription } from '../HotKeySubscription';
var UndoHotkeyMac = (function (_super) {
    __extends(UndoHotkeyMac, _super);
    function UndoHotkeyMac($document) {
        var _this = _super.call(this) || this;
        _this.$document = $document;
        _this.keyCode = 90;
        _this.metaKey = true;
        return _this;
    }
    UndoHotkeyMac.prototype.handler = function (event) {
        this.undo(event);
    };
    UndoHotkeyMac.prototype.undo = function (event) {
        this.$document[0].execCommand('undo', false);
        event.preventDefault();
    };
    return UndoHotkeyMac;
}(HotkeySubscription));
export { UndoHotkeyMac };
