<template>
  <q-header
    class="navigation-bar-desktop"
    data-v-step="welcome"
  >
    <square-not-active />
    <platform-notice />
    <skip-to-content />
    <div class="row justify-between items-center no-wrap q-py-xs q-px-md">
      <div
        ref="logosDiv"
        class="row navigation-bar-logos items-center q-mr-md"
      >
        <header-consumer-logo />
      </div>
      <navigation-tabs-desktop class="navigation-bar-desktop__tabs q-mx-md" />

      <div
        ref="optionsDiv"
        class="navigation-bar-desktop__options row justify-center items-center q-ml-md"
      >
        <div
          v-if="isEnabledMyIncentivePage"
          class="navigation-bar-desktop__incentive-option"
          data-v-step="incentives"
        >
          <router-link
            :to="{ name: incentivePages[0].route }"
            :aria-label="getLabelValue('LabelAriaIncentives')"
          >
            <q-avatar
              icon="fas fa-coins"
              padding="sm"
              round
              size="lg"
            >
              <isc-badge-wrapper
                v-if="totalCredits > 0"
                :notifications="totalCredits"
              />
            </q-avatar>
          </router-link>
        </div>
        <div data-v-step="notifications">
          <user-notification />
        </div>
        <div
          class="navigation-bar-desktop__helpcenter-option"
          data-v-step="helpcenter"
        >
          <router-link
            :to="{ name: RouteNames.Helpcenter }"
            :aria-label="getLabelValue('LabelAriaHelpcenter')"
          >
            <q-avatar
              icon="fas fa-circle-info"
              padding="sm"
              round
              size="lg"
            />
          </router-link>
        </div>
        <profile-image class="col" />
        <div
          v-if="userRole && [Role.Human8, Role.ProfessionalAdmin, Role.ClientAdmin, Role.ClientEditor, Role.Observer].includes(userRole)"
          class="navigation-bar-desktop__switch-interface-option"
        >
          <q-btn
            flat
            round
            icon="fas fa-right-left"
            padding="sm"
            :aria-label="getLabelValue('LabelAriaSwitchInterfaces')"
            @click="switchInterface"
          >
            <q-tooltip>
              {{ getLabelValue('LabelAriaSwitchInterfaces') }}
            </q-tooltip>
          </q-btn>
        </div>
      </div>
    </div>
  </q-header>
</template>

<script setup lang="ts">
import { PageType, Role, Feature } from '@/common/constants/enums';
import { computed, onMounted, Ref, ref, watch } from 'vue';
import NavigationTabsDesktop from './navigation-tabs-desktop.vue';
import UserNotification from './user-notification.vue';
import { useConstantsStore } from '@/store/constants-store';
import { usePagesStore } from '@/store/pages-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { useNotificationsStore } from '@/store/notifications-store';
import PlatformNotice from '../platform-notice/platform-notice.vue';
import { getSwitchInterfaceUrl } from '@/common/utils/url';
import SquareNotActive from '../square-not-active/square-not-active.vue';
import ProfileImage from './profile-logo.vue';
import { IscBadgeWrapper } from '@ui/components';
import HeaderConsumerLogo from './header-consumer-logo.vue';
import { isMasterFeatureEnabledForSquare } from '@/services/feature-service';
import SkipToContent from '../skip-to-content/skip-to-content.vue';
// pages
const IsMasterFeatureEnabledForSquare = ref(true);
const incentivePages =  computed(() => usePagesStore().incentivePages);
const isEnabledMyIncentivePage = computed(() => IsMasterFeatureEnabledForSquare.value && incentivePages.value.some((p) => p.pageType === PageType.MyIncentive));

// labels
const constantsStore = useConstantsStore();
const getLabelValue = constantsStore.getLabelValue;

// incentives
const notificationsStore = useNotificationsStore();
const totalCredits = computed(() => notificationsStore.totalCredits ?? 0);

const authStore = useAuthenticationStore();
const userRole = computed(() => authStore.tokenInfo?.role);

// switch interface
const switchInterface = () => {
  window.open(getSwitchInterfaceUrl(), '_blank');
};

// Element width
// To make sure that the page tabs are centered, the logos and options div have to have the same width.
// This is done by checking which one has the biggest width and assigning that to the other element.
const optionsDiv: Ref<HTMLDivElement | null> = ref(null);
const logosDiv: Ref<HTMLDivElement | null> = ref(null);
const calculateAndSetCenterTabsWidth = () => {
  if (!optionsDiv.value || !logosDiv.value) {
    return;
  }

  const optionsDivWidth = optionsDiv.value.getBoundingClientRect().width || 0;
  const logosDivWidth = logosDiv.value.getBoundingClientRect().width || 0;

  if (optionsDivWidth > logosDivWidth) {
    logosDiv.value.style.minWidth = `${optionsDivWidth}px`;
  } else if (logosDivWidth > optionsDivWidth) {
    optionsDiv.value.style.minWidth = `${logosDivWidth}px`;
  }
};

const createResizeObserver = () => {
  if (optionsDiv.value && logosDiv.value) {
    const observer = new ResizeObserver(calculateAndSetCenterTabsWidth);
    observer.observe(optionsDiv.value);
    observer.observe(logosDiv.value);
  }
};

onMounted(async () => {
  watch(() => incentivePages, async () => {
    IsMasterFeatureEnabledForSquare.value = await isMasterFeatureEnabledForSquare(Feature.SavingUpRewards);
  });

  IsMasterFeatureEnabledForSquare.value = await isMasterFeatureEnabledForSquare(Feature.SavingUpRewards);
  createResizeObserver();
});
</script>

<style lang="scss">
.navigation-bar-desktop.q-header {
  z-index: 69; // Set page-header z-index lower than modal, higher than timeline
}
.navigation-bar-desktop {
  background: linear-gradient(to right, var(--navigation-bar-start, $light) 0%, var(--navigation-bar-end, $light) 100%);

  /* Don't include the badges, they are red and we want the text to stay white */
  *:not(.q-badge, .square-not-active) {
    color: var(--navigation-bar-text, $dark);
  }

  a {
    text-decoration: none;
  }

  &__options {
    max-width: 20%;
  }
}
</style>
