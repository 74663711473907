import { IscUIUtils } from '../../../utils/sharedutils';
var ShowRepliesButtonController = (function () {
    function ShowRepliesButtonController(serverConstants) {
        this.serverConstants = serverConstants;
    }
    ShowRepliesButtonController.prototype.$onInit = function () {
        this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);
    };
    ShowRepliesButtonController.$inject = ['serverConstants'];
    return ShowRepliesButtonController;
}());
export { ShowRepliesButtonController };
