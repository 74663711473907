import { __awaiter } from "tslib";
export class UserNotificationController {
    constructor(_, serverConstants, userNotificationService, trackingService) {
        this._ = _;
        this.serverConstants = serverConstants;
        this.userNotificationService = userNotificationService;
        this.trackingService = trackingService;
        this.menuOffset = '20 64';
        this.viewedNotifications = new Array();
    }
    $onInit() {
        this.borderedIcon = this.borderedIcon || false;
        this.notificationTypes = this.serverConstants.notificationTypeConstants;
        this.notificationCategories = this.serverConstants.notificationCategoryConstants;
        this.menuOffset = this.isMobile ? '20 70' : this.menuOffset;
    }
    get notifications() {
        return this.userNotificationService.userNotifications;
    }
    clearAllNotifications() {
        this.userNotificationService.clearAllNotifications();
    }
    trackResearchActivityNotificationsView() {
        return __awaiter(this, void 0, void 0, function* () {
            this._.each(this.notifications, (item) => {
                if (item.Category !== this.notificationCategories.activity) {
                    return;
                }
                if (!this._.some(this.viewedNotifications, (o) => o === item)) {
                    this.viewedNotifications.push(item);
                    this.userNotificationService.resolveNotificationTargetGuid(item).then((response) => {
                        const notificationTargetGuid = response;
                        this.trackingService.researchActivityNotificationView(notificationTargetGuid);
                    });
                }
            });
        });
    }
    notificationClicked(notification) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.userNotificationService.notificationClicked(notification);
        });
    }
}
UserNotificationController.$inject = ['_', 'serverConstants', 'userNotificationService', 'trackingService'];
