import { UiModule } from './../../../../index';
import { HtmlUtils } from '../../../../utils/htmlutils';
var BindEmojitext = (function () {
    function BindEmojitext(emojione, $compile, $parse, $sce, $sanitize) {
        this.emojione = emojione;
        this.$compile = $compile;
        this.$parse = $parse;
        this.$sce = $sce;
        this.$sanitize = $sanitize;
    }
    BindEmojitext.prototype.compile = function (cElement, cAttrs) {
        var _this = this;
        var bindEmojitextGetter = this.$parse(cAttrs.bindEmojitext);
        var bindEmojitextWatch = this.$parse(cAttrs.bindEmojitext, function (val) {
            return _this.$sce.valueOf(val);
        });
        this.$compile.$$addBindingClass(cElement);
        return function (scope, element, attr) {
            _this.$compile.$$addBindingInfo(element, attr.bindEmojitext);
            scope.$watch(bindEmojitextWatch, function () {
                var value = bindEmojitextGetter(scope);
                if (value && value.$$unwrapTrustedValue) {
                    value = value.$$unwrapTrustedValue();
                }
                if (value && typeof (value) === 'string') {
                    value = _this.emojione.toImage(value);
                    var sanititizedHtml = HtmlUtils.sanitizeRichEditorContent(value, _this.$sanitize, _this.$sce);
                    element.html(sanititizedHtml);
                }
            });
        };
    };
    BindEmojitext.factory = function () {
        var directive = function (emojione, $compile, $parse, $sce, $sanitize) { return new BindEmojitext(emojione, $compile, $parse, $sce, $sanitize); };
        directive.$inject = ['emojione', '$compile', '$parse', '$sce', '$sanitize'];
        return directive;
    };
    BindEmojitext.$inject = ['emojione', '$compile', '$parse', '$sce', '$sanitize'];
    return BindEmojitext;
}());
export { BindEmojitext };
UiModule
    .directive('bindEmojitext', BindEmojitext.factory());
