import { BaseDataService } from './base.data.service';
export class FeatureService extends BaseDataService {
    constructor(httpService, $window) {
        super(httpService);
        this.$window = $window;
    }
    isFeatureEnabledForSquare(feature) {
        return this.getOrCached({
            url: '/FeatureService/IsFeatureEnabledForSquare',
            params: {
                feature,
            },
        }, BaseDataService.SECONDS_ONE_DAY).then((response) => response.data);
    }
    isMasterFeatureEnabledForSquare(feature) {
        return this.getOrCached({
            url: '/FeatureService/IsMasterFeatureEnabledForSquare',
            params: {
                feature,
            },
        }, BaseDataService.SECONDS_ONE_DAY).then((response) => response.data);
    }
    checkFeatureAccessibility(feature) {
        return this.getOrCached({
            url: '/FeatureService/CheckFeatureAccessibility',
            params: {
                feature,
            },
        }, BaseDataService.SECONDS_ONE_DAY).then((response) => response.data);
    }
    isForceMobileAppEnabledForSquare() {
        const url = this.$window.location.origin;
        const subdomainRegex = /:\/\/(.*?)\./;
        const squareUrl = url.match(subdomainRegex)[1];
        return this.getOrCached({
            url: '/FeatureService/IsForceMobileAppEnabledForSquare',
            params: {
                squareUrl,
            },
        }, BaseDataService.SECONDS_ONE_DAY).then((response) => response.data);
    }
}
FeatureService.$inject = ['httpService', '$window'];
