const redirectUserNotAuthenticated = ($transitions, authService, $q) => {
    $transitions.onBefore({}, (trans) => {
        const targetState = trans.targetState();
        const requiresLogin = targetState._definition.requireLogin !== false;
        return authService.isAuthorized()
            .then((isAuth) => {
            if (requiresLogin && !isAuth) {
                const redirectState = targetState.name();
                const redirectParams = targetState.params();
                return trans.router.stateService.target('container.login', {
                    redirectState,
                    redirectParams,
                });
            }
            return true;
        })
            .catch((errorOrTargetState) => {
            const asTargetState = errorOrTargetState;
            if (asTargetState.valid && asTargetState.valid()) {
                if (trans.targetState().name() === asTargetState.name()) {
                    return true;
                }
                return asTargetState;
            }
            return $q.reject(errorOrTargetState);
        });
    });
};
redirectUserNotAuthenticated.$inject = ['$transitions', 'authService', '$q'];
angular
    .module('blocks.router')
    .run(redirectUserNotAuthenticated);
export {};
