import 'angular-animate';
import 'angular-touch';
import 'angular-aria';
import 'angular-material';
import 'angular-messages';
import 'angular-read-more';
import 'angular-sanitize';
import 'angular-vimeo-embed';
import 'angular-youtube-embed';
import 'angular-inview';
import * as introjs from 'intro.js';
window.introJs = introjs.introJs;
import 'lodash';
import 'ng-file-upload';
import 'ng-img-crop/compile/unminified/ng-img-crop.js';
import 'ng-wig';
import 'tinycolor2';
import 'v-accordion';
import 'angular-recaptcha';
import 'recordrtc';
import 'webrtc-adapter/dist/adapter_core.js';
import * as emojione from 'emojione';
window.emojione = emojione;
import 'isc-ui/dist/services';
import 'isc-ui/dist/directives/fallback';
import 'isc-ui/dist/directives/mention';
import 'isc-ui/dist/directives/hashtag';
import 'isc-ui/dist/components/conversation-editor';
import 'isc-ui/dist/components/input/isc-input';
import 'isc-ui/dist/components/input/isc-rich-input';
import 'isc-ui/dist/components/forum-post';
import 'isc-ui/dist/components/stimuli';
import 'isc-ui/dist/components/card/isc-challenge-card';
import 'isc-ui/dist/components/button/isc-button';
import 'isc-ui/dist/components/button/show-replies-button';
import 'isc-ui/dist/components/input/isc-dropdown';
import 'isc-ui/dist/components/checkbox/isc-checkbox';
import 'isc-ui/dist/components/conversation-sort-options';
import 'isc-ui/dist/components/conversation-list';
import 'isc-ui/dist/components/incentive';
import 'isc-ui/dist/components/timeline';
import 'isc-ui/dist/components/notifications';
import 'isc-ui/dist/components/notifications-counter';
import 'isc-ui/dist/components/modal/isc-modal-dialog';
import 'isc-ui/dist/components/button/like-button';
import 'isc-ui/dist/components/pagination';
import 'isc-ui/dist/components/media-recorder';
import 'isc-ui/dist/components/probequestioneditor';
import 'isc-ui/dist/components/probe-questions-answer-editor';
import 'isc-ui/dist/components/isc-redemption-threshold';
import 'isc-ui/dist/config';
import 'isc-ui/lib/signalr';
import 'isc-ui/dist/directives/conversationeditor';
import 'isc-ui/dist/components/carousel';
import 'isc-ui/dist/components/video';
import 'isc-ui/dist/components/caption-editor';
import 'isc-ui/dist/components/visual-focused-conversation';
import 'isc-ui/dist/components/upload-conversation';
import 'isc-ui/dist/components/stimuli-preview';
import 'isc-ui/dist/components/404';
import 'isc-ui/dist/directives/isc-button';
import './core.module';
import './core.route';
import './../blocks';
import './config';
import './constants.factory';
import './serverconstants';
import './constants';
import './custom-styles';
import './htmlText.filter';
import './httpinterceptor';
import './images.factory';
import { IscPagesService } from './isc-pages.service';
import './participant.factory';
import './square.factory';
import './user-role.filter';
import './spinner.component';
import './isc-tour';
import './dataservices';
import './services';
import './directives';
import './filters';
import { ConstantsFactory } from './constants.factory';
import 'angular-xregexp';
angular
    .module('insitesApp.core')
    .service('constantsfactory', ConstantsFactory)
    .service('iscPages', IscPagesService);
