import { CacheService } from '../services/cache.service';
export class PlatformNoticeService {
    constructor(httpService) {
        this.httpService = httpService;
    }
    displayNotice() {
        return this.httpService.get({
            url: '/PlatformNoticeService/GetCurrentPlatformNoticeDisplay',
            params: {
                language: CacheService.getCacheValue('Language'),
                square: true,
            },
        }).then((response) => response.data);
    }
}
PlatformNoticeService.$inject = [
    'httpService',
];
