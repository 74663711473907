import { UiModule } from '../../../index';
import { IscCheckboxController } from './isc-checkbox.controller';
var iscCheckboxTemplate = "<md-input-container class=\"md-block inputField\" tabindex=\"-1\">\n  <md-checkbox\n    role=\"radio\"\n    class=\"md-accent\"\n    ng-class=\"{'md-indeterminate': vm.indeterminate}\"\n    ng-disabled=\"vm.isDisabled\"\n    ng-model=\"vm.model\"\n    aria-label=\"{{vm.label ? vm.label : vm.ariaLabel}}\"\n    aria-checked=\"vm.ariaChecked\"\n    ng-true-value=\"true\"\n    ng-false-value=\"false\"\n    ng-change=\"vm.onChange()\"\n    tabindex=\"0\"\n    ng-keydown=\"$event.keyCode === 13 && vm.toggleCheckbox()\">\n    {{vm.label}}\n    <div class=\"tooltip\" ng-if=\"vm.tooltip\">\n      <i class=\"fa fa-circle-info\" aria-hidden=\"true\"></i>\n      <md-tooltip  md-direction=\"::vm.tooltipDirection ?? 'right'\">\n        {{vm.tooltip}}\n      </md-tooltip>\n    </div>\n  </md-checkbox>\n</md-input-container>";
UiModule
    .component('iscCheckbox', {
    template: iscCheckboxTemplate,
    controller: IscCheckboxController,
    controllerAs: 'vm',
    bindings: {
        label: '@',
        tooltip: '@',
        model: '=',
        isDisabled: '<',
        ariaLabel: '@',
        ariaChecked: '<',
        indeterminate: '<',
        onChange: '&?',
        tooltipDirection: '<?',
    },
});
