import { __awaiter } from "tslib";
export class CompletionModalController {
    constructor(info, homePageName, cancel, ok, activitySequenceDataService) {
        this.info = info;
        this.homePageName = homePageName;
        this.cancel = cancel;
        this.ok = ok;
        this.activitySequenceDataService = activitySequenceDataService;
        this.isNextActivityReady = false;
    }
    $onInit() {
        this.checkIfSampleIsCompleted();
    }
    checkIfSampleIsCompleted() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.info.NextActivityGuid) {
                return;
            }
            this.isNextActivityReady = yield this.activitySequenceDataService.isSequencedActivityUnlocked(this.info.NextActivityGuid);
            if (!this.isNextActivityReady) {
                setTimeout(() => __awaiter(this, void 0, void 0, function* () { return yield this.checkIfSampleIsCompleted(); }), 500);
            }
        });
    }
}
CompletionModalController.$inject = ['info', 'homePageName', 'cancel', 'ok', 'activitySequenceDataService'];
