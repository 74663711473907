export class IscHideMobileDirective {
    constructor($location, mediaservice) {
        this.$location = $location;
        this.mediaservice = mediaservice;
        this.restrict = 'A';
    }
    link(scope, element) {
        if (this.mediaservice.isMobileApp) {
            element[0].style.display = 'none';
        }
    }
    static factory() {
        const directive = ($location, mediaservice) => new IscHideMobileDirective($location, mediaservice);
        directive.$inject = ['$location', 'mediaservice'];
        return directive;
    }
}
IscHideMobileDirective.$inject = ['$location', 'mediaservice'];
