import * as _ from 'lodash';
export class MappingService {
    constructor(serverConstants) {
        this.serverConstants = serverConstants;
    }
    mapConversationStimuliForConversationEditor(input) {
        let result = [];
        if (input && input.length) {
            result = input.map((stimulus) => {
                var _a;
                if (stimulus) {
                    let idValue = null;
                    if (stimulus.Id !== null) {
                        idValue = (_a = stimulus === null || stimulus === void 0 ? void 0 : stimulus.Id) === null || _a === void 0 ? void 0 : _a.toString();
                    }
                    return {
                        file: stimulus.File,
                        type: stimulus.Type,
                        thumbnailUrl: stimulus.ThumbnailUrl,
                        url: stimulus.Url,
                        guid: stimulus.Guid,
                        value: stimulus.Value,
                        id: idValue,
                        isPreview: true,
                        status: this.serverConstants.conversationStimuliStatusConstants.saved,
                        IsBroken: stimulus.IsBroken,
                        IsBrokenAndBelongsToCurrentUser: stimulus.IsBrokenAndBelongsToCurrentUser,
                    };
                }
            });
        }
        return result;
    }
    mapConversationAttachmentsForConversationEditor(input) {
        let result = [];
        if (input && input.length) {
            result = input.map((attachment) => {
                var _a;
                if (attachment) {
                    let idValue = null;
                    if (attachment.Id !== null) {
                        idValue = (_a = attachment === null || attachment === void 0 ? void 0 : attachment.Id) === null || _a === void 0 ? void 0 : _a.toString();
                    }
                    return {
                        file: attachment.File,
                        type: -1,
                        thumbnailUrl: '',
                        guid: attachment.Guid,
                        value: attachment.Url,
                        url: attachment.Url,
                        id: idValue,
                        name: attachment.Name,
                        isPreview: true,
                        status: this.serverConstants.conversationStimuliStatusConstants.saved,
                    };
                }
            });
        }
        return result;
    }
    mapProbeQuestionsForProbeQuestionEditor(probeQuestions) {
        const model = [];
        _.forEach(probeQuestions, (probeQuestion) => {
            const probeQuestionEditorModel = this.mapProbeQuestionForProbeQuestionEditor(probeQuestion);
            model.push(probeQuestionEditorModel);
        });
        return model;
    }
    mapProbeQuestionForProbeQuestionEditor(probeQuestion) {
        const answer = {
            squareParticipantGuid: probeQuestion.SquareParticipantGuid,
            squareParticipantId: probeQuestion.SquareParticipantId,
            probeQuestionGuid: probeQuestion.Guid,
            guid: undefined,
            stimuli: [],
            newStimulusFiles: [],
            newAttachmentFiles: [],
            isValidAnswer: false,
            isInDraft: false,
        };
        if (probeQuestion.Answer) {
            answer.stimuli = this.mapConversationStimuliForConversationEditor(probeQuestion.Answer.Stimuli);
            answer.attachments = this.mapConversationAttachmentsForConversationEditor(probeQuestion.Answer.Attachments);
            answer.message = probeQuestion.Answer.Message;
            answer.caption = probeQuestion.Answer.Caption;
            answer.guid = probeQuestion.Answer.Guid;
            answer.isValidAnswer = probeQuestion.Answer.IsValidAnswer;
            answer.isInDraft = probeQuestion.Answer.IsInDraft;
        }
        const probeQuestionEditorModel = {
            isSlideIn: probeQuestion.IsSlideIn,
            level: probeQuestion.Level,
            message: probeQuestion.Message,
            questionType: probeQuestion.QuestionType,
            guid: probeQuestion.Guid,
            hasBeenAnswered: probeQuestion.HasBeenAnswered,
            answerRequired: probeQuestion.AnswerRequired,
            answer,
        };
        return probeQuestionEditorModel;
    }
    mapConversationStimuliForStimulusProgressList(input) {
        let result = [];
        if (input && input.length) {
            result = input.map((stimulus) => {
                if (stimulus) {
                    return {
                        stimulusValue: stimulus.id,
                        progress: 0,
                    };
                }
            });
        }
        return result;
    }
}
MappingService.$inject = ['serverConstants'];
