var TransformMsoListsToListElements = (function () {
    function TransformMsoListsToListElements() {
    }
    TransformMsoListsToListElements.prototype.processElement = function (element) {
        if (element instanceof HTMLElement) {
            if (element.hasAttribute('data-isc-processed')) {
                return null;
            }
            var listItems = this.extractListItems(element);
            if (listItems.length > 0) {
                var hierarchy = this.processChildLists(listItems);
                var list = this.generateListElementsFromListItemHierarchy(hierarchy);
                for (var _i = 0, listItems_1 = listItems; _i < listItems_1.length; _i++) {
                    var li = listItems_1[_i];
                    var e = li.element;
                    e.setAttribute('data-isc-processed', '');
                }
                return list;
            }
        }
        return element;
    };
    TransformMsoListsToListElements.prototype.extractListItems = function (startingElement) {
        var listItems = [];
        var currentElement = startingElement;
        var currentElementDetails = this.getListItemDetails(startingElement);
        while (currentElement && currentElementDetails.isListItem) {
            listItems.push(currentElementDetails);
            var nextElement = currentElement.nextElementSibling;
            while (nextElement && !(nextElement instanceof HTMLElement)) {
                nextElement = nextElement.nextElementSibling;
            }
            currentElement = nextElement;
            currentElementDetails = currentElement && this.getListItemDetails(currentElement);
        }
        return listItems;
    };
    TransformMsoListsToListElements.prototype.processChildLists = function (listItems) {
        this.fixListItemLevels(listItems);
        var hierarchicalListItems = [];
        var root = {
            level: 0,
            children: [],
        };
        var lastItemsByLevel = [root];
        for (var _i = 0, listItems_2 = listItems; _i < listItems_2.length; _i++) {
            var li = listItems_2[_i];
            lastItemsByLevel[li.level] = li;
            li.children = li.children || [];
            lastItemsByLevel[li.level - 1].children.push(li);
        }
        return root.children;
    };
    TransformMsoListsToListElements.prototype.fixListItemLevels = function (listItems) {
        for (var _i = 0, listItems_3 = listItems; _i < listItems_3.length; _i++) {
            var li = listItems_3[_i];
            if (!li.level || isNaN(li.level)) {
                li.level = 1;
            }
        }
        var lastLevel = 0;
        for (var _a = 0, listItems_4 = listItems; _a < listItems_4.length; _a++) {
            var li = listItems_4[_a];
            if (li.level > lastLevel + 1) {
                li.level = lastLevel + 1;
            }
            lastLevel = li.level;
        }
    };
    TransformMsoListsToListElements.prototype.generateListElementsFromListItemHierarchy = function (listItems) {
        var result = [];
        var isOrdered = listItems[0] && listItems[0].isOrdered;
        var listElement = document.createElement(isOrdered ? 'ol' : 'ul');
        for (var _i = 0, listItems_5 = listItems; _i < listItems_5.length; _i++) {
            var item = listItems_5[_i];
            var li = document.createElement('li');
            li.innerHTML = item.element.innerHTML;
            if (item.children.length > 0) {
                var childList = this.generateListElementsFromListItemHierarchy(item.children);
                li.appendChild(childList);
            }
            listElement.appendChild(li);
        }
        return listElement;
    };
    TransformMsoListsToListElements.prototype.getListItemDetails = function (element) {
        var isAlreadyAListItem = element.nodeName === 'LI'
            && (['UL', 'OL'].indexOf(element.parentElement.nodeName) !== -1);
        if (isAlreadyAListItem) {
            return {
                isListItem: false,
                level: 0,
                symbol: '',
                isOrdered: false,
                element: element,
            };
        }
        var result = this.getListItemDetailsForWordList(element);
        if (!result.isListItem) {
            result = this.getListItemDetailsForPowerPointList(element);
        }
        return result;
    };
    TransformMsoListsToListElements.prototype.getListItemDetailsForWordList = function (element) {
        var isListItem = false;
        var level = 0;
        var symbol = '';
        var isOrdered = false;
        var styleAttribute = element.attributes.getNamedItem('style');
        var styleValue = (styleAttribute && styleAttribute.value) || '';
        isListItem = styleValue.indexOf('mso-list') !== -1
            && element.nodeName !== 'LI'
            && element.parentElement.nodeName !== 'UL'
            && element.parentElement.nodeName !== 'OL'
            && !/mso-list:\s*Ignore/i.test(styleValue);
        if (isListItem) {
            var match = styleValue.match(/level(\d+)/);
            if (match && match[1]) {
                level = parseInt(match[1], 10);
            }
            symbol = element.innerText.trim().substring(0, 1);
            isOrdered = !isNaN(+symbol);
        }
        return {
            isListItem: isListItem,
            level: level,
            symbol: symbol,
            isOrdered: isOrdered,
            element: element,
        };
    };
    TransformMsoListsToListElements.prototype.getListItemDetailsForPowerPointList = function (element) {
        var isListItem = false;
        var level = 0;
        var symbol = '';
        var isOrdered = false;
        var firstGrandchild = element.firstElementChild
            && element.firstElementChild.firstElementChild;
        if (firstGrandchild && firstGrandchild instanceof HTMLElement) {
            var styleAttribute = firstGrandchild.attributes.getNamedItem('style');
            var styleValue = (styleAttribute && styleAttribute.value) || '';
            var isBullet = /mso-special-format:[\w"']*bullet/.test(styleValue);
            var isNumBullet = /mso-special-format:[\w"']*numbullet/.test(styleValue);
            if (isBullet || isNumBullet) {
                isListItem = true;
                level = 1;
                symbol = firstGrandchild.innerText.trim().substring(0, 1);
                isOrdered = isNumBullet;
                var match = element.className.match(/O(\d+)/);
                if (match && match[1]) {
                    level = parseInt(match[1], 10) + 1;
                }
            }
        }
        return {
            isListItem: isListItem,
            level: level,
            symbol: symbol,
            isOrdered: isOrdered,
            element: element,
        };
    };
    return TransformMsoListsToListElements;
}());
export default TransformMsoListsToListElements;
