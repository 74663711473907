// We need to make sure that our Vuex store can be used in our AngularJS controllers
// without having to change the code there

import { useSquareStore } from '@/store/square-store';

interface SquareFactory {
  Guid: string;
  Id: number;
  SquareName: string;
  Subdomain: string;
  Language: string;
  Status: number;
  ClientGuid: string;
  ClientName: string;
  ProgramGuid: string;
  SsoEnabled: boolean;
  IsCompleted: boolean;
  VideoStorage: number;
  SsoExternalLoginUrl: string;
  DateLastImageChange: string;
  Anonymous: boolean;
  IsPublicProfileEnabled: boolean;
  IsActive?: () => boolean;
}

export default new Proxy({
  IsActive: () => useSquareStore().isActive,
}, {
  get: (target, key: keyof SquareFactory) => {
    const squareStore = useSquareStore();
    type SquareInfo = typeof squareStore.info;
    if (key !== 'IsActive') {
      const lowercasedKey = key.charAt(0).toLowerCase() + key.substring(1) as keyof SquareInfo;
      return squareStore.info[lowercasedKey];
    }

    return Reflect.get(target, key);
  },
  set: (_, key: keyof SquareFactory, value) => {
    const squareStore = useSquareStore();
    type SquareInfo = typeof squareStore.info;
    if (key === 'IsActive') {
      // Make it impossible to overwrite the 'IsActive' function
      return false;
    }

    const lowercasedKey = key.charAt(0).toLowerCase() + key.substring(1) as keyof SquareInfo;
    (squareStore.info[lowercasedKey] as SquareInfo[keyof SquareInfo]) = value;

    return true;
  },
}) as SquareFactory;
