import { __extends } from "tslib";
import { RichInputLengthCalculatorFeature } from '../IRichInputFeature';
import { MaxLengthSubscription } from './MaxLengthSubscription';
var ValidateMaxLength = (function (_super) {
    __extends(ValidateMaxLength, _super);
    function ValidateMaxLength($sce) {
        var _this = _super.call(this) || this;
        _this.$sce = $sce;
        return _this;
    }
    ValidateMaxLength.prototype.init = function (richInput) {
        this.maxLengthSubscription = new MaxLengthSubscription(this.$sce, richInput);
        richInput.subscribeToKeyUp(this.maxLengthSubscription);
    };
    ValidateMaxLength.prototype.getValueRealLength = function () {
        return this.maxLengthSubscription.getValueRealLength();
    };
    ValidateMaxLength.$inject = ['$sce'];
    return ValidateMaxLength;
}(RichInputLengthCalculatorFeature));
export default ValidateMaxLength;
