export class SsocheckService {
    constructor($location) {
        this.$location = $location;
    }
    hasSsoToken() {
        return !!this.$location.search().token;
    }
    hasNullSsoToken() {
        return this.hasSsoToken() &&
            this.$location.search().token === 'null';
    }
    getSsoToken() {
        const ssoToken = this.$location.search().token;
        return ssoToken;
    }
}
SsocheckService.$inject = ['$location'];
