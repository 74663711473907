import { __awaiter } from "tslib";
export class ResetPasswordController {
    constructor(participantService, imagesFactory, _, featureToggleService, captchaService, $state, featureService, serverConstants) {
        this.participantService = participantService;
        this.imagesFactory = imagesFactory;
        this._ = _;
        this.featureToggleService = featureToggleService;
        this.captchaService = captchaService;
        this.$state = $state;
        this.featureService = featureService;
        this.serverConstants = serverConstants;
        this.shouldShowFooter = this.featureToggleService.shouldShowFooter;
        this.images = this.imagesFactory.images;
        this.Email = '';
        this.isSubmitted = false;
        this.processing = false;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const isAzureADFeatureEnabled = yield this.featureService.isMasterFeatureEnabledForSquare(this.serverConstants.featureConstants.azureAdB2CLogin);
            if (isAzureADFeatureEnabled) {
                this.$state.go('container.main.home');
            }
            this.imagesFactory.applyImages();
        });
    }
    resetPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            this.processing = true;
            try {
                const captchaResponse = yield this.captchaService.getCaptchaResponse('resetPassword');
                yield this.participantService.resetPassword({ email: this.Email, captchaResponse });
                this.isSubmitted = true;
            }
            catch (error) {
                this.handleResetPasswordError(error);
            }
            finally {
                this.processing = false;
            }
        });
    }
    handleResetPasswordError(error) {
        const showCaptcha = error.data && error.data.error === 'show_captcha';
        if (showCaptcha) {
            this.captchaService.loadCaptcha();
            const errorObject = this._.groupBy(error.data.ValidationErrors, 'PropertyName');
            if (errorObject.ssoDomain) {
                this.resetPasswordForm.Email.$setValidity('ssoDomain', false);
                this.processing = false;
            }
        }
    }
}
ResetPasswordController.$inject = ['participantService', 'imagesFactory', '_', 'featureToggleService', 'captchaService', '$state', 'featureService', 'serverConstants'];
