import { ImageStimuliDialogController } from './image-stimuli-dialog.controller';
import { VideoStimuliDialogController } from './video-stimuli-dialog.controller';
import { VimeoStimuliDialogController } from './vimeo-stimuli-dialog.controller';
import { YouTubeStimuliDialogController } from './youtube-stimuli-dialog.controller';
var imageStimuliTemplate = "<md-dialog class=\"insites-dialog image-stimuli-dialog\">\n  <md-button hide-lt-md md-no-ink class=\"md-icon-button close-button\" ng-click=\"vm.close()\" isc-translate=\"[aria-label](LabelAriaCancel)\">\n    <i class=\"typcn typcn-times\"></i>\n  </md-button>\n  <isc-stimuli-download ng-if=\"vm.allowDownload\" stimulus-id=\"vm.stimulusGuid\"></isc-stimuli-download>\n  <md-dialog-content>\n    <img ng-src=\"{{vm.imageUrl}}\" />\n  </md-dialog-content>\n</md-dialog>\n";
var youtubeStimuliTemplate = "<md-dialog class=\"insites-dialog youtube-stimuli-dialog\">\n  <md-button hide-lt-md md-no-ink class=\"md-icon-button close-button\" ng-click=\"vm.close()\" isc-translate=\"[aria-label](LabelAriaCancel)\">\n    <i class=\"typcn typcn-times\"></i>\n  </md-button>\n  <md-dialog-content>\n    <youtube-video video-id=\"vm.videoId\" player-vars=\"vm.playerVars\" />\n  </md-dialog-content>\n</md-dialog>";
var vimeoStimuliTemplate = "<md-dialog class=\"insites-dialog vimeo-stimuli-dialog\">\n  <md-button hide-lt-md md-no-ink class=\"md-icon-button close-button\" ng-click=\"vm.close()\" isc-translate=\"[aria-label](LabelAriaCancel)\">\n    <i class=\"typcn typcn-times\"></i>\n  </md-button>\n  <md-dialog-content>\n    <vimeo-video player-id=\"vm.id\" video-id=\"vm.videoId\" player-opts=\"vm.playerVars\" />\n  </md-dialog-content>\n</md-dialog>";
var videoStimuliTemplate = "<md-dialog class=\"insites-dialog video-stimuli-dialog\">\n  <isc-stimuli-download ng-if=\"vm.allowDownload\" stimulus-id=\"vm.stimulusGuid\"></isc-stimuli-download>\n  <md-button hide-lt-md md-no-ink class=\"md-icon-button close-button\" ng-click=\"vm.close()\" isc-translate=\"[aria-label](LabelAriaCancel)\">\n    <i class=\"typcn typcn-times\"></i>\n  </md-button>\n  <md-dialog-content>\n    <video class=\"azuremediaplayer amp-default-skin amp-big-play-centered\" width=\"100%\" height=\"100%\"></video>\n  </md-dialog-content>\n</md-dialog>\n";
import * as angular from 'angular';
var StimuliDialogService = (function () {
    function StimuliDialogService($mdDialog, iscStimuliTypeService) {
        this.$mdDialog = $mdDialog;
        this.iscStimuliTypeService = iscStimuliTypeService;
        this.conversationStimuliType = this.iscStimuliTypeService.getTypes();
    }
    StimuliDialogService.prototype.openStimulusPreview = function (stimulus, allowDownload, trigger) {
        if (trigger instanceof Event) {
            trigger = trigger.target;
        }
        var triggerElement = angular.element(trigger);
        if (stimulus && this.conversationStimuliType) {
            if (stimulus.type === this.conversationStimuliType.image ||
                stimulus.type.toString().indexOf('image/') !== -1) {
                this.openImageStimuli(stimulus.url || stimulus.value, stimulus.guid, allowDownload, triggerElement);
            }
            else if (stimulus.type === this.conversationStimuliType.vimeo) {
                this.openVimeoStimuli(stimulus.value, triggerElement);
            }
            else if (stimulus.type === this.conversationStimuliType.video) {
                this.openVideoStimuli(stimulus.url, stimulus.guid, allowDownload, triggerElement);
            }
            else if (stimulus.type === this.conversationStimuliType.youtube) {
                this.openYouTubeStimuli(stimulus.value, triggerElement);
            }
            else {
                throw new Error('Unknown stimuli type');
            }
        }
    };
    StimuliDialogService.prototype.openStimuli = function (stimuli, allowDownload, trigger) {
        if (trigger instanceof Event) {
            trigger = trigger.target;
        }
        var triggerElement = angular.element(trigger);
        if (stimuli && this.conversationStimuliType) {
            if (stimuli.Type === this.conversationStimuliType.image ||
                stimuli.Type.toString().indexOf('image/') !== -1 ||
                (stimuli.TypeName && stimuli.TypeName.toString().indexOf('image/') !== -1)) {
                this.openImageStimuli(stimuli.Value || stimuli.Url, stimuli.Guid, allowDownload, triggerElement);
            }
            else if (stimuli.Type === this.conversationStimuliType.vimeo) {
                this.openVimeoStimuli(stimuli.Value, triggerElement);
            }
            else if (stimuli.Type === this.conversationStimuliType.video ||
                stimuli.Type.toString().indexOf('video/') !== -1 ||
                (stimuli.TypeName && stimuli.TypeName.toString().indexOf('video/') !== -1)) {
                this.openVideoStimuli(stimuli.Url, stimuli.Guid, allowDownload, triggerElement);
            }
            else if (stimuli.Type === this.conversationStimuliType.youtube) {
                this.openYouTubeStimuli(stimuli.Value, triggerElement);
            }
            else {
                throw new Error('Unknown stimuli type');
            }
        }
    };
    StimuliDialogService.prototype.openImageStimuli = function (imageUrl, stimulusGuid, allowDownload, trigger) {
        var _a;
        this.$mdDialog.show({
            multiple: true,
            controller: ImageStimuliDialogController,
            controllerAs: 'vm',
            template: imageStimuliTemplate,
            clickOutsideToClose: true,
            fullscreen: false,
            parent: (_a = trigger[0].closest('isc-thumbnail')) !== null && _a !== void 0 ? _a : angular.element(document.body),
            locals: {
                stimulusGuid: stimulusGuid,
                allowDownload: allowDownload,
                imageUrl: imageUrl,
            },
        }).then(function () {
            trigger[0].focus();
        });
    };
    StimuliDialogService.prototype.openInlineImageStimuli = function (imageUrl, trigger) {
        var _a;
        this.$mdDialog.show({
            multiple: true,
            controller: ImageStimuliDialogController,
            controllerAs: 'vm',
            template: imageStimuliTemplate,
            clickOutsideToClose: true,
            fullscreen: false,
            parent: (_a = trigger[0].closest('isc-thumbnail')) !== null && _a !== void 0 ? _a : angular.element(document.body),
            locals: {
                stimulus: null,
                allowDownload: false,
                imageUrl: imageUrl,
            },
        }).then(function () {
            trigger[0].focus();
        });
    };
    StimuliDialogService.prototype.openYouTubeStimuli = function (videoId, trigger) {
        var _a;
        this.$mdDialog.show({
            multiple: true,
            controller: YouTubeStimuliDialogController,
            controllerAs: 'vm',
            template: youtubeStimuliTemplate,
            clickOutsideToClose: true,
            fullscreen: false,
            parent: (_a = trigger[0].closest('isc-thumbnail')) !== null && _a !== void 0 ? _a : angular.element(document.body),
            locals: {
                videoId: videoId,
            },
        }).then(function () {
            trigger[0].focus();
        });
    };
    StimuliDialogService.prototype.openVimeoStimuli = function (videoId, trigger) {
        var _a;
        this.$mdDialog.show({
            multiple: true,
            controller: VimeoStimuliDialogController,
            controllerAs: 'vm',
            template: vimeoStimuliTemplate,
            clickOutsideToClose: true,
            fullscreen: false,
            parent: (_a = trigger[0].closest('isc-thumbnail')) !== null && _a !== void 0 ? _a : angular.element(document.body),
            locals: {
                videoId: videoId,
            },
        })
            .then(function () {
            trigger[0].focus();
        });
    };
    StimuliDialogService.prototype.openVideoStimuli = function (videoUrl, stimulusGuid, allowDownload, trigger) {
        var _a;
        this.$mdDialog.show({
            multiple: true,
            controller: VideoStimuliDialogController,
            controllerAs: 'vm',
            template: videoStimuliTemplate,
            clickOutsideToClose: true,
            fullscreen: false,
            parent: (_a = trigger[0].closest('isc-thumbnail')) !== null && _a !== void 0 ? _a : angular.element(document.body),
            locals: {
                videoUrl: videoUrl,
                stimulusGuid: stimulusGuid,
                allowDownload: allowDownload,
            },
        }).then(function () {
            trigger[0].focus();
        });
    };
    StimuliDialogService.$inject = ['$mdDialog', 'iscStimuliTypeService'];
    return StimuliDialogService;
}());
export { StimuliDialogService };
