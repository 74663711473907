import { __awaiter } from "tslib";
import * as angular from 'angular';
export class ForumService {
    constructor($http, httpService, $rootScope, downloadservice, notifications, $q, serverConstants) {
        this.$http = $http;
        this.httpService = httpService;
        this.$rootScope = $rootScope;
        this.downloadservice = downloadservice;
        this.notifications = notifications;
        this.$q = $q;
        this.serverConstants = serverConstants;
        this.EMPTYGUID = '00000000-0000-0000-0000-000000000000';
        this.scope = this.$rootScope.$new(true);
        this.conversationChangedEventName = 'onConversationChangedEvent';
        this.onConversationChanged = {
            subscribe: (callback) => this.scope.$on(this.conversationChangedEventName, (e, data) => {
                callback(data);
            }),
        };
        this.notifications.onUserAddedToActivity = (groupName) => {
            this.addUserToActivity(groupName);
        };
        this.notifications.onUserRemovedFromActivity = (groupName) => {
            this.removeUserFromActivity(groupName);
        };
    }
    getConversationsInRoom(roomGuid, pageNumber, limit, pageGuid) {
        return this.httpService.post({
            url: '/ForumService/GetConversationsInRoom',
            data: {
                roomGuid,
                pageNumber,
                limit,
                pageGuid,
            },
        }).then((response) => response.data);
    }
    getTopicNotificationCounts() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/ForumService/GetTopicNotificationCounts',
            });
            return response.data.map((data) => ({
                TopicId: data.TopicId,
                Count: data.Count,
            }));
        });
    }
    getMentionUsersForConversationForForum(pageGuid, searchQuery, parentReplyGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/ForumService/GetMentionUsersForConversationForForum',
                data: {
                    pageGuid,
                    searchQuery,
                    parentReplyGuid,
                },
            });
            return response.data.map((mention) => ({ label: mention.Label }));
        });
    }
    getMentionUsersForConversation(activityGuid, searchQuery, parentReplyGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/ForumService/GetMentionUsersForConversation',
                data: {
                    activityGuid,
                    searchQuery,
                    parentReplyGuid,
                },
            });
            return response.data.map((mention) => ({ label: mention.Label }));
        });
    }
    getHashtagsForConversation(activityGuid, searchQuery) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/ForumService/GetHashtagsForConversation',
                data: {
                    activityGuid,
                    searchQuery,
                },
            });
            return response.data.map((hashtag) => ({ text: hashtag.Text }));
        });
    }
    createConversationStimuliStreamingUrl(stimuliGuid) {
        return this.httpService.post({
            url: '/ConversationService/CreateConversationStimuliStreamingUrl',
            data: {
                StimuliGuid: stimuliGuid,
            },
        }).then((response) => response.data.Url);
    }
    downloadStimuli(stimuliGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.httpService.post({
                    url: '/ExportService/DownloadStimuli',
                    responseType: 'arraybuffer',
                    params: {
                        stimuliGuid,
                    },
                });
                this.downloadservice.downloadResponse(response);
            }
            catch (_a) {
            }
            return this.$q.resolve();
        });
    }
    getConversationElements(conversationGuid, pageNumber, limit, sortOption) {
        return this.httpService.post({
            url: '/ForumService/GetConversationElements',
            data: {
                conversationGuid,
                pageNumber,
                limit,
                sortOption,
            },
        }).then((response) => response.data);
    }
    getQualConversationElements(activityGuid, pageNumber, limit, sortOption, expandedPosts, expandLatestUpdatePost, isGroupedByMember) {
        return this.httpService.post({
            url: '/ForumService/GetQualConversationElements',
            params: {
                activityGuid,
            },
            data: {
                activityGuid,
                pageNumber,
                limit,
                sortOption,
                expandedPosts,
                expandLatestUpdatePost,
                isGroupedByMember,
            },
        }).then((response) => response.data);
    }
    getQualConversationElementOrDefault(replyGuid, limit) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: '/ForumService/GetQualConversationElementOrDefault',
                data: {
                    replyGuid,
                    limit,
                },
            }).then((response) => response.data);
        });
    }
    getConversation(conversationGuid) {
        return this.httpService.post({
            url: '/ForumService/GetForumConversation',
            data: {
                conversationGuid,
            },
        }).then((response) => response.data);
    }
    likeConversation(conversationGuid) {
        return this.httpService.post({
            url: '/ConversationService/LikeConversation',
            data: {
                ConversationGuid: conversationGuid,
            },
        });
    }
    unlikeConversation(conversationGuid) {
        return this.httpService.post({
            url: '/ConversationService/UnlikeConversation',
            data: {
                ConversationGuid: conversationGuid,
            },
        });
    }
    likeQualConversation(conversationGuid) {
        return this.httpService.post({
            url: '/ConversationService/LikeQualConversation',
            data: {
                ConversationGuid: conversationGuid,
            },
        });
    }
    unlikeQualConversation(conversationGuid) {
        return this.httpService.post({
            url: '/ConversationService/UnlikeQualConversation',
            data: {
                ConversationGuid: conversationGuid,
            },
        });
    }
    getSquareForumRooms(pageGuid) {
        return this.httpService.post({
            url: '/ForumService/GetForumRooms',
            data: {
                Guid: pageGuid,
            },
        }).then((response) => response.data.Rooms);
    }
    createForumConversation(request, files, attachments) {
        return this.httpService.upload({
            url: '/ConversationService/CreateConversation',
            data: {
                request: angular.toJson(request),
                files,
                attachments,
            },
        }).then(() => {
            this.scope.$emit(this.conversationChangedEventName);
        });
    }
    getConversationLocation(replyGuid, limit) {
        return this.httpService.post({
            url: '/ForumService/GetConversationLocation',
            data: {
                ReplyGuid: replyGuid,
                Limit: limit,
            },
        }).then((response) => response.data);
    }
    replyToForumConversation(request, files, attachments) {
        return this.httpService.upload({
            url: '/ConversationService/ReplyToConversation',
            data: {
                request: angular.toJson(request),
                files,
                attachments,
            },
        }).then((response) => {
            this.scope.$emit(this.conversationChangedEventName);
            return response.data;
        });
    }
    replyToQualConversation(request, files, attachments) {
        return this.httpService.upload({
            url: '/ConversationService/ReplyToQualConversation',
            data: {
                request: angular.toJson(request),
                files,
                attachments,
            },
        }).then((response) => {
            this.scope.$emit(this.conversationChangedEventName);
            return response.data;
        });
    }
    pageChangedInForumConversation() {
        this.scope.$emit(this.conversationChangedEventName);
    }
    replyToProbingQuestions(probingAnswers, files, attachments, activityType, conversationFocusType, contributionType, channelType) {
        return this.httpService.upload({
            url: '/ConversationService/ReplyToProbingQuestions',
            data: {
                request: angular.toJson({ probingAnswers, activityType, conversationFocusType, contributionType, channelType }),
                files,
                attachments,
            },
        }).then((response) => response.data);
    }
    getRoomDetails(roomGuid) {
        if (roomGuid !== this.EMPTYGUID) {
            return this.httpService.post({
                url: '/ForumService/GetRoomDetails',
                data: {
                    guid: roomGuid,
                },
            }).then((response) => response.data);
        }
        return this.$q.resolve(null);
    }
    getQualSampleGuidFromReply(replyGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: '/ForumService/GetQualSampleGuidFromReply',
                data: {
                    replyGuid,
                },
            }).then((response) => response.data);
        });
    }
    getVimeoThumbnailImagePath(videoid) {
        return this.httpService.get({
            url: '/ForumService/GetVimeoThumbnailImagePath',
            params: {
                videoId: videoid,
            },
        });
    }
    addUserToActivity(group) {
        return this.httpService.post({
            url: '/ConversationService/AddUserToGroup',
            params: {
                groupName: group,
            },
        });
    }
    removeUserFromActivity(group) {
        return this.httpService.post({
            url: '/ConversationService/RemoveUserFromGroup',
            params: {
                groupName: group,
            },
        });
    }
    updateStimulusAfterNewUpload(stimulus) {
        return this.httpService.post({
            url: '/ConversationService/UpdateStimulus',
            data: {
                Guid: stimulus.guid,
                Id: stimulus.id,
                Value: stimulus.value,
                Type: stimulus.type,
                Url: stimulus.url,
                ThumbnailUrl: stimulus.thumbnailUrl,
            },
        });
    }
}
ForumService.$inject = [
    '$http',
    'httpService',
    '$rootScope',
    'downloadservice',
    'notifications',
    '$q',
    'serverConstants',
];
