import { __awaiter } from "tslib";
import { noop } from 'lodash';
export class ForumRoomController {
    constructor($stateParams, forumService, $state, participantFactory, pageThemes, _, notifications, featureService, serverConstants, authService, $scope) {
        this.$stateParams = $stateParams;
        this.forumService = forumService;
        this.$state = $state;
        this.participantFactory = participantFactory;
        this.pageThemes = pageThemes;
        this._ = _;
        this.notifications = notifications;
        this.featureService = featureService;
        this.serverConstants = serverConstants;
        this.authService = authService;
        this.$scope = $scope;
        this.ITEMS_PER_PAGE = 15;
        this.currentPage = 1;
        this.newPage = 1;
        this.totalPages = 1;
        this.conversations = [];
        this.isVisible = false;
        this.isRecentTopics = false;
        this.TopicNotificationCounts = [];
        this.isDiscussion20NewEnabled = false;
        this.roomGuidWatch = noop;
    }
    $onInit() {
        this.roomGuidWatch = this.$scope.$watch(() => this.$stateParams.roomGuid, () => {
            this.init();
        });
    }
    init() {
        this.roomGuid = this.$stateParams.roomGuid;
        this.pageGuid = this.$stateParams.pageGuid;
        const isAuthorized = this.authService.isAuthorized();
        if (isAuthorized) {
            this.featureService.checkFeatureAccessibility(this.serverConstants.featureConstants.discussion20New)
                .then((isDiscussion20NewEnabled) => {
                if (isDiscussion20NewEnabled) {
                    this.isDiscussion20NewEnabled = isDiscussion20NewEnabled;
                }
            });
        }
        this.userNotificationPromise = this.notifications.userNotification
            .subscribe(() => {
            this.loadConversations();
        });
        this.getRoomDetailsPromise = this.forumService.getRoomDetails(this.roomGuid).then((room) => {
            if (room && room.Detail) {
                this.isVisible = room.Detail.IsVisible;
                this.roomName = room.Detail.Name;
            }
            this.isRecentTopics = this.roomGuid === this.forumService.EMPTYGUID;
            if (this.isVisible || this.isRecentTopics) {
                this.currentPage = parseInt(this.$stateParams.page, 10) || 1;
                this.loadConversations();
                return this.forumService.onConversationChanged.subscribe(() => this.loadConversations());
            }
            this.$state.go('container.main.home');
            return this._.noop;
        });
    }
    $onDestroy() {
        this.roomGuidWatch();
        this.getRoomDetailsPromise.then((unsubscribe) => {
            unsubscribe();
        });
        this.userNotificationPromise();
    }
    loadConversations() {
        return __awaiter(this, void 0, void 0, function* () {
            this.currentPage = this.newPage;
            this.TopicNotificationCounts = yield this.forumService.getTopicNotificationCounts();
            this.forumService.getConversationsInRoom(this.roomGuid, this.currentPage, this.ITEMS_PER_PAGE, this.pageGuid).then((count) => {
                this.totalPages = count.TotalPages;
                this.conversations = count.Items;
                this._.forEach(this.conversations, (conversation) => {
                    conversation.avatar = conversation.SquareParticipantHasProfileImage ?
                        this.participantFactory.getParticipantImageUrl(conversation.SquareParticipantGuid, conversation.SquareParticipantDateModified) :
                        this.participantFactory.DefaultAvatar;
                    conversation.HasNotification = this.hasNotification(conversation.Id);
                });
            });
        });
    }
    hasNotification(topicId) {
        return this._.findIndex(this.TopicNotificationCounts, (o) => o.TopicId === topicId) > -1;
    }
    navigate(pageNumber) {
        this.newPage = pageNumber;
        this.$state.go('.', { page: pageNumber });
        this.forumService.pageChangedInForumConversation();
    }
    newConversation(routeSuffix) {
        routeSuffix = routeSuffix !== null && routeSuffix !== void 0 ? routeSuffix : 'new';
        const routeForForumPage = `container.main.forum.${routeSuffix}`;
        this.$state.go(routeForForumPage, {
            pageGuid: this.$stateParams.pageGuid,
            roomGuid: this.$stateParams.roomGuid,
        });
    }
    getPageColor() {
        return this.pageThemes.getColorForPage(this.pageGuid);
    }
    goToConversation(conversation) {
        const url = conversation.IsDiscussionNewConversation
            ? 'container.main.forum.discussionTopic'
            : 'container.main.forum.conversation';
        this.$state.go(url, {
            pageGuid: this.$stateParams.pageGuid,
            roomGuid: conversation.RoomGuid,
            conversationGuid: conversation.Guid,
        });
    }
}
ForumRoomController.$inject = ['$stateParams', 'forumService', '$state', 'participantFactory', 'pageThemes', '_', 'notifications',
    'featureService', 'serverConstants', 'authService', '$scope'];
