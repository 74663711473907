export class AvatarCropController {
    constructor(file, $mdDialog, participantService, user) {
        this.file = file;
        this.$mdDialog = $mdDialog;
        this.participantService = participantService;
        this.user = user;
    }
    cancel() {
        this.$mdDialog.cancel();
    }
    save() {
        this.saving = true;
        this.participantService.saveParticipantAvatar(this.file, this.cropArea, this.user.Guid).then((response) => {
            this.$mdDialog.hide(response.data);
        }).finally(() => this.saving = false);
    }
}
AvatarCropController.$inject = ['file', '$mdDialog', 'participantService', 'user'];
