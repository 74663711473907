import { __awaiter } from "tslib";
import { DateTime } from 'luxon';
import { NewsDetailDialogController } from './detail/newsdetail-dialog.controller';
const newsDetailDialogTemplate = "<md-dialog class=\"news-detail insites-dialog\" md-theme=\"{{vm.pageGuid | getPageTheme}}\" ng-swipe-left=\"vm.swipeLeft()\" ng-swipe-right=\"vm.swipeRight()\"\n  ng-keyup=\"vm.keyUp($event)\">\n  <md-toolbar hide-gt-sm>\n    <div class=\"md-toolbar-tools\">\n      <md-button class=\"md-icon-button\" ng-click=\"vm.cancel()\" isc-translate=\"[aria-label](LabelAriaCancel)\">\n        <i class=\"typcn typcn-times\"></i>\n      </md-button>\n    </div>\n  </md-toolbar>\n  <md-button hide-lt-md md-no-ink class=\"md-icon-button close-button\" ng-click=\"vm.cancel()\" isc-translate=\"[aria-label](LabelAriaCancel)\">\n    <i class=\"typcn typcn-times\"></i>\n  </md-button>\n  <nav>\n    <i class=\"typcn typcn-chevron-left\" ng-click=\"vm.prev($event)\" ng-if=\"vm.hasPrevCard\"></i>\n    <i class=\"typcn typcn-chevron-right\" ng-click=\"vm.next($event)\" ng-if=\"vm.hasNextCard\"></i>\n  </nav>\n  <md-dialog-content class=\"{{vm.isRtl() ? 'rtl' : 'ltr'}}\">\n    <!-- <div ui-view=\"news-detail\" class=\"news-detail\"></div> -->\n    <news-detail class=\"news-detail\"></news-detail>\n  </md-dialog-content>\n</md-dialog>";
export class NewsController {
    constructor(pageService, $stateParams, $state, newsService, _, logger, trackingService, $scope, $mdDialog) {
        this.pageService = pageService;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.newsService = newsService;
        this._ = _;
        this.logger = logger;
        this.trackingService = trackingService;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.viewedCards = [];
        this.unsubscribe = _.noop;
        this.$scope.$watch(() => this.$state.current.name, (newVal) => {
            if (newVal === 'container.main.news.detail') {
                setTimeout(() => {
                    this.$mdDialog.show({
                        template: newsDetailDialogTemplate,
                        controllerAs: 'vm',
                        controller: NewsDetailDialogController,
                        clickOutsideToClose: true,
                        fullscreen: true,
                    }).catch(() => {
                        $state.go('container.main.news');
                    });
                }, 0);
            }
            else {
                this.$mdDialog.hide();
            }
        });
    }
    $onInit() {
        const initPromise = this.init();
        this.initPromise = initPromise.catch((e) => {
            this.logger.error('Error loading news page', e);
        });
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            this.pageDetails = yield this.pageService.getPageDetails(this.$stateParams.pageGuid);
            const cards = yield this.newsService.getNewsPageCards();
            cards.forEach((card) => {
                card.Stimuli = {
                    ThumbnailUrl: card.StimuliThumbnailUrl || card.StimuliUrl,
                    Value: card.StimuliUrl,
                    Url: card.StimuliUrl,
                    Type: card.FileType,
                };
                card.DateDiff = DateTime.fromISO(card.StartDateTime).toRelative({ base: DateTime.utc() });
            });
            this.cards = cards;
            this.unsubscribe = this.newsService.on('likeClick', (e, data) => {
                const index = this._(this.cards).findIndex((card) => card.Guid === data.Guid);
                this.cards[index] = data;
            });
            try {
                this.$scope.$evalAsync();
            }
            catch (_a) {
            }
        });
    }
    $onDestroy() {
        this.initPromise.finally(() => {
            this.$mdDialog.hide();
            this.unsubscribe();
            this.newsService.clear();
        });
    }
    likeClick(card) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.newsService.likeClick(card);
        });
    }
    openDetail(card) {
        this.trackingService.newsCardOnNewsPageClick(card.CommunicationSampleGuid);
        this.$state.go('container.main.news.detail', { cardGuid: card.Guid, card });
    }
    trackViewed(card) {
        if (!this._.some(this.viewedCards, card)) {
            this.viewedCards.push(card);
            this.trackingService.newsCardOnNewsPageView(card.CommunicationSampleGuid);
        }
    }
    isSticky(card) {
        return card.StickyDate;
    }
}
NewsController.$inject = ['pageService', '$stateParams', '$state', 'newsService', '_', 'logger', 'trackingService', '$scope', '$mdDialog'];
