import { __extends } from "tslib";
import { IscTaggingBaseUtil } from '../common-tagging/tagging-base.service';
var IscMentionUtil = (function (_super) {
    __extends(IscMentionUtil, _super);
    function IscMentionUtil($window, $location, $anchorScroll, $timeout) {
        return _super.call(this, $window, $location, $anchorScroll, $timeout) || this;
    }
    IscMentionUtil.prototype.tagCssClassName = function () {
        return 'mention';
    };
    IscMentionUtil.$inject = ['$window', '$location', '$anchorScroll', '$timeout'];
    return IscMentionUtil;
}(IscTaggingBaseUtil));
export { IscMentionUtil };
