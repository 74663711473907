import { __extends } from "tslib";
import { SequencedCardControlllerBase } from '../sequencedCardControlllerBase';
var IscChallengeCardController = (function (_super) {
    __extends(IscChallengeCardController, _super);
    function IscChallengeCardController(serverConstants) {
        var _this = _super.call(this) || this;
        _this.serverConstants = serverConstants;
        return _this;
    }
    IscChallengeCardController.prototype.$onInit = function () {
        this.index = this.sequence.startIndex;
        this.card = this.sequence.cards[this.index];
    };
    IscChallengeCardController.prototype.showCallToActionButton = function () {
        return (this.card.ShowCallToActionButton && (!this.showEarnedCredits() || this.showCompletionPoints()));
    };
    IscChallengeCardController.prototype.getSequence = function () {
        return this.sequence;
    };
    IscChallengeCardController.prototype.stimuli = function () {
        return this.card.Stimuli.ThumbnailUrl ? this.card.Stimuli : this.defaultStimuli;
    };
    IscChallengeCardController.prototype.showStickyLabel = function () {
        return !this.card.Completed && (this.card.StickyDate || this.card.isSticky === true);
    };
    IscChallengeCardController.prototype.showAvailableCredits = function () {
        return !this.card.Completed && this.card.Credits > 0;
    };
    IscChallengeCardController.prototype.showEarnedCredits = function () {
        return this.card.Completed && this.card.Credits > 0;
    };
    IscChallengeCardController.prototype.showRewards = function () {
        return !this.card.Completed && this.card.RaffleRewards.length > 0;
    };
    IscChallengeCardController.prototype.showEndDate = function () {
        return !this.card.Completed || this.card.isDiscussionActivity;
    };
    IscChallengeCardController.prototype.showCompletedLabel = function () {
        return this.card.Completed && !this.card.isDiscussionActivity;
    };
    IscChallengeCardController.prototype.showCompletionPoints = function () {
        return this.card.CompletionPoints > 0;
    };
    IscChallengeCardController.prototype.getStarsList = function () {
        return new Array(this.card.Complexity);
    };
    IscChallengeCardController.prototype.showSpotsLeft = function () {
        return this.card.CompletionTarget != null;
    };
    IscChallengeCardController.prototype.spotsLeft = function () {
        return Math.max(this.card.CompletionTarget - this.card.ParticipantsCompleted, 0);
    };
    IscChallengeCardController.prototype.getCompletedLabel = function () {
        if (!this.card.Completed) {
            return '';
        }
        if (this.card.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.screened ||
            this.card.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.quotaFull) {
            return '(LabelChallengesHomePageChallengeNotQualified)';
        }
        return '(LabelChallengesHomePageChallengeCompleted)';
    };
    IscChallengeCardController.$inject = ['serverConstants'];
    return IscChallengeCardController;
}(SequencedCardControlllerBase));
export { IscChallengeCardController };
