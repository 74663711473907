export class MaintenanceController {
    constructor($interval, projectService, $stateParams) {
        this.$interval = $interval;
        this.projectService = projectService;
        this.$stateParams = $stateParams;
    }
    $onInit() {
        this.goto = this.$stateParams.goto;
        this._intervalPromise = this.$interval(() => this.checkIfClientLoaded(), 10000);
    }
    $onDestroy() {
        this.$interval.cancel(this._intervalPromise);
    }
    checkIfClientLoaded() {
        this.projectService.getSquareInfo().then(() => {
            this.$onDestroy();
            window.location.href = this.goto;
        });
    }
}
MaintenanceController.$inject = ['$interval', 'projectService', '$stateParams'];
