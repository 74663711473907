import { __awaiter } from "tslib";
export class GoToNotificationController {
    constructor($stateParams, userNotificationService) {
        this.$stateParams = $stateParams;
        this.userNotificationService = userNotificationService;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.userNotificationService.goToNotification(this.$stateParams.notificationguid);
        });
    }
}
GoToNotificationController.$inject = ['$stateParams', 'userNotificationService'];
