import { UiModule } from './../../index';
var StimuliTypeService = (function () {
    function StimuliTypeService() {
    }
    StimuliTypeService.prototype.getTypes = function () {
        throw new Error('Please override this function with a function that gets the stimuli types from the backend');
    };
    return StimuliTypeService;
}());
export { StimuliTypeService };
UiModule.service('iscStimuliTypeService', StimuliTypeService);
