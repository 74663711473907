import { __awaiter } from "tslib";
export class DiscussionService {
    constructor(httpService) {
        this.httpService = httpService;
    }
    createOrUpdatePost(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/DiscussionService/CreateOrUpdatePost',
                data: Object.assign({}, request),
            });
            return response.data;
        });
    }
    createOrUpdateMessage(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/DiscussionService/CreateOrUpdateMessage',
                data: Object.assign({}, request),
            });
            return response.data;
        });
    }
}
DiscussionService.$inject = ['httpService'];
