/* eslint-disable no-console */
// The console.error in this file is used to denote that we try and use a mocked method in the wrapper Angular components,
// but we don't have an implementation for it. By printing to the console, we can better debug issues caused by this.

import router from '@/router';

export default class CustomLocation {
  #url = '';

  replace() {
    router.replace({ path: this.#url });
  }

  url(path?: string): string | CustomLocation {
    if (!path) {
      return router.currentRoute.value.fullPath;
    }
    this.#url = path;
    return this;
  }

  path(){
    return null;
  }

  search(){
    return router.currentRoute.value.query;
  }

  hash(){
    return null;
  }

  port(){
    return null;
  }

  protocol(){
    return null;
  }

  host(){
    return null;
  }
}

/* eslint-enable no-console */
