import { getAppInsights } from '@/common/app-insights';
import router from '@/router';
import { useParticipantStore } from '@/store/participant-store';
import { useSquareStore } from '@/store/square-store';
import { DateTime } from 'luxon';
import { connected, getConnectionId, pageVisited } from '@/common/utils/events';

let connectionId: string | null;
let navigationInProgress = false;
const pathsNotToTrack = ['/login'];

export const initPlatformHealth = () => {
  connected.subscribe((isConnected) => {
    const appInsights = getAppInsights();
    if (isConnected) {
      connectionId = getConnectionId();
      const participantGuid = useParticipantStore().participant.guid;
      const squareGuid = useSquareStore().info.guid;


      if (appInsights && squareGuid && participantGuid) {
        appInsights.setAuthenticatedUserContext(participantGuid, squareGuid);
      }
    } else {
      connectionId = null;
      if (appInsights) {
        appInsights.clearAuthenticatedUserContext();
      }
    }
    sendPath();
  });
};


let startTimeStamp: DateTime;
let previousPath: string | null;

export const onStart = () => {
  startTimeStamp = DateTime.utc();
  previousPath = router.currentRoute.value.path;
  navigationInProgress = true;
};

export const onSuccess = () => {
  const currentPath = router.currentRoute.value.path;
  if (!navigationInProgress || pathsNotToTrack.some((p) => p === currentPath)) {
    return;
  }

  const stopTimestamp = DateTime.utc();
  const duration = stopTimestamp.toMillis() - startTimeStamp.toMillis();
  navigationInProgress = false;

  const appInsights = getAppInsights();
  if (appInsights) {
    appInsights.trackPageView(null, currentPath, {
      PreviousPath: previousPath,
      ConnectionId: connectionId,
    }, null, duration);
  }

  // If a reload happens we have the same route but a different signalR connection so that's
  // why a page visit needs to happen
  sendPath();
};

const sendPath = () => {
  pageVisited(router.currentRoute.value.path);
};
