import * as angular from 'angular';
var IscTaggingMenuBaseController = (function () {
    function IscTaggingMenuBaseController($scope, iscTaggingBaseUtil, $window, $element) {
        var _this = this;
        this.$scope = $scope;
        this.iscTaggingBaseUtil = iscTaggingBaseUtil;
        this.$window = $window;
        this.$element = $element;
        this.visible = false;
        this.windowListener = function () {
            if (_this.isVisible()) {
                var triggerCharSet = [];
                triggerCharSet.push(_this.triggerChar);
                _this.iscTaggingBaseUtil.popUnderTagging(triggerCharSet, _this.$element);
            }
        };
    }
    IscTaggingMenuBaseController.prototype.$onInit = function () {
        var _this = this;
        this.addMenu({ menuScope: this });
        angular.element(this.$window).on('resize', this.windowListener);
        this.$scope.$watch('vm.isVisible()', function (visible) {
            if (visible) {
                var triggerCharSet = [];
                triggerCharSet.push(_this.triggerChar);
                _this.iscTaggingBaseUtil.popUnderTagging(triggerCharSet, _this.$element);
            }
        });
    };
    IscTaggingMenuBaseController.prototype.$onDestroy = function () {
        angular.element(this.$window).off('resize', this.windowListener);
        this.$element.remove();
    };
    IscTaggingMenuBaseController.prototype.$onChanges = function (changes) {
        if (changes.hasOwnProperty('items')) {
            var items = changes.items.currentValue;
            this.items = items;
            if (items && items.length > 0) {
                this.activate(items[0]);
                if (!this.visible && this.requestVisiblePendingSearch) {
                    this.visible = true;
                    this.requestVisiblePendingSearch = false;
                }
            }
            else {
                this.hideMenu();
            }
        }
    };
    IscTaggingMenuBaseController.prototype.adjustScroll = function (direction) {
        var menuEl = this.$element[0];
        var menuItemsList = menuEl.querySelector('ul');
        var menuItem = menuEl.querySelector("isc-".concat(this.tagTypeName(), "-menu-item > li.active"));
        if (this.isFirstItemActive()) {
            return menuItemsList.scrollTop = 0;
        }
        else if (this.isLastItemActive()) {
            return menuItemsList.scrollTop = menuItemsList.scrollHeight;
        }
        if (direction === 1) {
            menuItemsList.scrollTop += menuItem.offsetHeight;
        }
        else {
            menuItemsList.scrollTop -= menuItem.offsetHeight;
        }
    };
    IscTaggingMenuBaseController.prototype.activate = function (item) {
        this.activeItem = item;
    };
    IscTaggingMenuBaseController.prototype.hideMenu = function () {
        this.visible = false;
        this.$element.css('display', 'none');
    };
    IscTaggingMenuBaseController.prototype.isActive = function (item) {
        return this.activeItem === item;
    };
    IscTaggingMenuBaseController.prototype.selectItem = function (item) {
        var text = this.select({
            item: item,
        });
        this.replaceText({ text: text });
    };
    IscTaggingMenuBaseController.prototype.activateNextItem = function () {
        var index = this.items.indexOf(this.activeItem);
        this.activate(this.items[(index + 1) % this.items.length]);
    };
    IscTaggingMenuBaseController.prototype.activatePreviousItem = function () {
        var index = this.items.indexOf(this.activeItem);
        this.activate(this.items[index === 0 ? this.items.length - 1 : index - 1]);
    };
    IscTaggingMenuBaseController.prototype.isFirstItemActive = function () {
        var index = this.items.indexOf(this.activeItem);
        return index === 0;
    };
    IscTaggingMenuBaseController.prototype.isLastItemActive = function () {
        var index = this.items.indexOf(this.activeItem);
        return index === (this.items.length - 1);
    };
    IscTaggingMenuBaseController.prototype.selectActive = function () {
        this.selectItem(this.activeItem);
    };
    IscTaggingMenuBaseController.prototype.isVisible = function () {
        return this.visible;
    };
    IscTaggingMenuBaseController.prototype.showMenu = function () {
        if (!this.visible) {
            this.requestVisiblePendingSearch = true;
        }
    };
    return IscTaggingMenuBaseController;
}());
export { IscTaggingMenuBaseController };
