import { __awaiter } from "tslib";
export class SsoDomainValidator {
    constructor(ssoService) {
        this.ssoService = ssoService;
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    link(scope, element, attrs, ngModel) {
        ngModel.$validators.ssoDomain = (modelValue) => __awaiter(this, void 0, void 0, function* () {
            if (modelValue) {
                return !(yield this.ssoService.isSsoDomain(modelValue));
            }
            return true;
        });
    }
    static factory() {
        const directive = (ssoService) => new SsoDomainValidator(ssoService);
        directive.$inject = ['ssoService'];
        return directive;
    }
}
SsoDomainValidator.$inject = ['ssoService'];
