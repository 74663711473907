import { UiModule } from '../../../index';
import { IscRichInputController } from './isc-rich-input.controller';
var iscRichInputTemplate = "<md-input-container\n  ng-class=\"{validinput: vm.showValidInput(), 'md-input-invalid': vm.isTouchedAndInvalid() }\" md-no-float>\n  <label ng-class=\"{ fieldWithAsterisk: vm.isRequired }\" class=\"md-no-float isc-rich-input-label\" ng-if=\"vm.label\">{{vm.label}}</label>\n  <div ng-if=\"::vm.prefixLabel\" class=\"isc-rich-input-prefixsuffix\">\n    <span ng-bind-html=\"vm.prefixLabel\"></span>\n  </div>\n  <ng-wig\n    autofocus=\"false\"\n    class=\"{{vm.isDisabled || vm.hideToolbar ? 'hide-toolbar' : ''}}\"\n    ng-attr-rich-input-validator=\"{{vm.isRequired}}\"\n    ng-model=\"vm.model\"\n    ng-attr-buttons=\"{{vm.ngButtons}}\"\n    ng-attr-name=\"{{::vm.name}}\"\n    ng-click=\"vm.gotFocus()\"\n    ng-blur=\"vm.loseFocus()\"\n    on-paste=\"vm.onTextPaste($event, pasteContent)\"\n    ondrop=\"return false;\"\n    ng-keydown=\"vm.onKeyDown($event)\"\n    ng-keyup=\"vm.onKeyUp($event)\"\n    ng-disabled=\"vm.isDisabled\"\n    isc-mention\n    isc-mention-search=\"vm.iscMentionSearchFn(term)\"\n    isc-mention-select=\"vm.iscMentionSelectFn(item)\"\n    isc-hashtag\n    isc-hashtag-search=\"vm.iscHashtagSearchFn(term)\"\n    isc-hashtag-select=\"vm.iscHashtagSelectFn(item)\"\n    maxlength=\"vm.maxlength\"\n    placeholder=\"{{vm.placeholder}}\"\n    aria-label=\"{{vm.ariaLabel}}\"\n    tabindex=\"-1\"\n    >\n  </ng-wig>\n  <div ng-if=\"vm.shouldShowCharacterCount()\" class=\"md-char-counter\" >\n    {{vm.getValueRealLength(vm.ngModelController.$viewValue)}} / {{vm.maxlength}}\n  </div>\n  <div ng-if=\"vm.isTouchedAndInvalid()\"\n    class=\"error-message\"\n    ng-messages=\"vm.ngModelController.$error\" ng-transclude=\"errors\">\n  </div>\n  <div ng-if=\"::vm.suffixLabel\" class=\"isc-rich-input-prefixsuffix\">\n    <span ng-bind-html=\"vm.suffixLabel\"></span>\n  </div>\n</md-input-container>\n";
UiModule.component('iscRichInput', {
    template: iscRichInputTemplate,
    controllerAs: 'vm',
    controller: IscRichInputController,
    bindings: {
        label: '@',
        buttons: '@',
        ariaLabel: '@',
        isRequired: '<',
        isDisabled: '<',
        onPaste: '&',
        onKeydown: '&',
        onFocus: '&?',
        name: '@',
        maxlength: '<',
        model: '=',
        iscMentionSearch: '&',
        iscMentionSelect: '&',
        iscHashtagSearch: '&?',
        iscHashtagSelect: '&?',
        placeholder: '@',
        autosave: '&',
        prefixLabel: '<',
        suffixLabel: '<',
        shouldRemoveAllStyles: '<',
        removeFormatting: '<',
        characterCountEnabled: '<?',
        isCustomPage: '<?',
    },
    transclude: {
        errors: '?iscRichInputErrors',
    },
});
