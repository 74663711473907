<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { password } from './__composables__/login-composables';
import { useConstantsStore } from '@/store/constants-store';
import { ref } from 'vue';

const getLabelValue = useConstantsStore().getLabelValue;
const isPassword = ref(true);

const v$ = useVuelidate({
  password: {
    required: helpers.withMessage(getLabelValue('LabelPasswordRequired'), required),
  } } ,
{ password });

</script>

<template>
  <q-input
    v-model="password"
    :type="isPassword? 'password' : 'text'"
    class="login-page-form__password"
    :class="$q.screen.lt.md ? 'q-pb-lg' : 'q-pb-sm'"
    input-class="text-body2"
    label-slot
    bottom-slots
    no-error-icon
    :error="v$.password.$errors.length > 0"
    @blur="v$.password.$touch"
  >
    <template #label>
      <div class="text-body2">
        {{ getLabelValue('LabelAccountPassword') }}*
      </div>
    </template>
    <template #error>
      <span class="login-page-form__password-error text-weight-regular text-caption">
        {{ v$.password.$errors[0].$message }}
      </span>
    </template>
    <template #append>
      <q-icon
        :name="isPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"
        size="xs"
        class="cursor-pointer"
        tabindex="0"
        role="button"
        :label="isPassword ? 'Show password' :'Hide password'"
        @click="isPassword = !isPassword"
        @keydown.enter="isPassword = !isPassword"
      />
    </template>
  </q-input>
</template>
