import { validationConstants } from '@/common/constants/constants';
import { PageType, RedirectForumType, SquareActivityType } from '@/common/constants/enums';
import router from '@/router';
import { RouteNames } from '@/router/routes/route-names.enum';
import { usePagesStore } from '@/store/pages-store';
import { DateTime } from 'luxon';
import { ReplyForumRoute } from './conversation.types';
import { anchorScrollWithWait } from '@/common/utils/scroll';
import { getConversationLocation, getQualConversationElementOrDefault } from '@api/services/command/default/ForumService';

export const getReplyForumRoute = async (
  replyGuid: string,
  redirectTo: RedirectForumType | null,
  replyStartDateTime: DateTime | null = null,
): Promise<ReplyForumRoute> => {
  if (!shouldJumpToReply(replyStartDateTime)) {
    return {};
  }

  const conversationLocation = await getConversationLocation({ replyGuid, limit: 50 });
  switch (redirectTo) {
    case RedirectForumType.Room:
      return {
        name: RouteNames.ForumRoom,
        params: {
          roomGuid: conversationLocation.roomGuid,
          pageGuid: conversationLocation.pageGuid,
        },
      };
    case RedirectForumType.NewTopics:
      return {
        name: RouteNames.ForumRoom,
        params: {
          roomGuid: validationConstants.emptyGuid,
          pageGuid: conversationLocation.pageGuid,
        },
      };
    case RedirectForumType.SingleTopic:
    default:
      return {
        name: RouteNames.ForumConversation,
        params: {
          pageGuid: conversationLocation.pageGuid,
          roomGuid: conversationLocation.roomGuid,
          conversationGuid: conversationLocation.conversationGuid,
        },
        query: {
          page: conversationLocation.pageIndex,
        },
      };
  }

};

export const jumpToReplyForum = async (replyGuid: string, replyStartDateTime: DateTime | null = null) => {
  if (!shouldJumpToReply(replyStartDateTime)) {
    return;
  }
  const currentPage = parseInt(router.currentRoute.value.query.page as string) || 1;
  const reply = await getConversationLocation({ replyGuid, limit: 50 });
  const { pageGuid, roomGuid, conversationGuid } = router.currentRoute.value.params;

  if (router.currentRoute.value.name === RouteNames.ForumConversation &&
      pageGuid === reply.pageGuid &&
      roomGuid === reply.roomGuid &&
      conversationGuid === reply.conversationGuid &&
      currentPage === reply.pageIndex) {
    anchorScrollWithWait(replyGuid);
  } else {
    router.push({ name: RouteNames.ForumConversation,
      params: {
        pageGuid: reply.pageGuid,
        roomGuid: reply.roomGuid,
        conversationGuid: reply.conversationGuid,
      },
      query: {
        page: reply.pageIndex,
        replyGuid,
      },
    });
  }
};

export const jumpToReplyQual = async (
  replyGuid: string,
  channel: number,
  activityType: SquareActivityType | null = null,
  replyStartDateTime: DateTime | null = null,
  activityGuid: string | null = null,
  isDiscussionNew = false,
) => {
  if (!shouldJumpToReply(replyStartDateTime)) {
    return;
  }
  const pagesStore = usePagesStore();
  const challengesPage = pagesStore.mainPages.find((item: { pageType: PageType }) => item.pageType === PageType.Activities);

  if(!isDiscussionNew) {
    const reply = await getQualConversationElementOrDefault({ replyGuid, limit: 50 });

    router.push({ name: RouteNames.ChallengesQual,
      params: {
        activityGuid: reply.activityGuid,
        channel,
        pageGuid: challengesPage?.guid,
      },
      query: {
        page: reply.pageIndex,
        expandedPosts: reply.expandedPosts,
        replyGuid: reply.guid,
        // if activityType is null, it is set to undefined so it isn't added to the url
        activityType: activityType || undefined,
      },
      force: true,
    });
  } else {
    if (activityGuid) {
      router.push({ name: RouteNames.ChallengesNewDisc20,
        params: {
          activityGuid,
          channel,
          pageGuid: challengesPage?.guid,
        },
        query: {
          replyGuid,
        },
        force: true,
      });
    }
  }
};

const shouldJumpToReply = (replyStartDateTime: DateTime | null) => {
  if (!replyStartDateTime) {
    return true;
  }

  const publishDurationMinThresHoldMilliseconds = 3000;
  const publishDurationMilliseconds = DateTime.utc().diff(replyStartDateTime).toMillis();
  return publishDurationMilliseconds <= publishDurationMinThresHoldMilliseconds;
};
