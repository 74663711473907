import { __awaiter } from "tslib";
import { BaseDataService } from './base.data.service';
import { BlockBlobClient } from '@azure/storage-blob';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
export class FileStorageService extends BaseDataService {
    constructor(httpService, $window, $timeout) {
        super(httpService);
        this.$window = $window;
        this.$timeout = $timeout;
        this._stimuliUploads = [];
        this.$window.addEventListener('beforeunload', (ev) => {
            if (this._stimuliUploads && _.find(this._stimuliUploads, (s) => s.progress !== 100)) {
                ev.preventDefault();
                ev.returnValue = '';
            }
        });
    }
    GetNewUploadLinkForPhoto(mimeType) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: `/ConversationService/GetNewUploadLinkForPhoto?mimeType=${encodeURIComponent(mimeType)}`,
            });
        });
    }
    GetNewUploadLinkForVideo(mimeType) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: `/ConversationService/GetNewUploadLinkForVideo?mimeType=${encodeURIComponent(mimeType)}`,
            });
        });
    }
    GetNewUploadLinkForAttachment(mimeType) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: `/ConversationService/GetNewUploadLinkForAttachment?mimeType=${encodeURIComponent(mimeType)}`,
            });
        });
    }
    uploadAttachment(attachmentUploadData, attachment) {
        return __awaiter(this, void 0, void 0, function* () {
            const blobClient = new BlockBlobClient(attachmentUploadData.AttachmentSasUrl);
            blobClient.uploadData(attachment, {
                blobHTTPHeaders: {
                    blobContentType: attachment.type,
                },
            });
        });
    }
    uploadImage(photoUploadData, image) {
        this._stimuliUploads.push({
            stimulusValue: photoUploadData.StimuliGuid,
            progress: 0,
        });
        const blobClient = new BlockBlobClient(photoUploadData.PhotoSasUrl);
        blobClient.uploadData(image, {
            onProgress: (ev) => {
                const progress = Math.round(100 * ev.loadedBytes / image.size);
                const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === photoUploadData.StimuliGuid)[0];
                if (stimulusUpload) {
                    stimulusUpload.progress = progress;
                }
            },
            blobHTTPHeaders: {
                blobContentType: image.type,
            },
        });
    }
    uploadVideo(videoUploadData, video, uploadCallback) {
        this._stimuliUploads.push({
            stimulusValue: videoUploadData.StimuliGuid,
            progress: 0,
        });
        const blobClient = new BlockBlobClient(videoUploadData.Url);
        blobClient.uploadData(video, {
            onProgress: (ev) => {
                const progress = Math.round(100 * ev.loadedBytes / video.size);
                const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === videoUploadData.StimuliGuid)[0];
                if (stimulusUpload) {
                    this.$timeout(() => {
                        stimulusUpload.progress = progress;
                        uploadCallback(stimulusUpload);
                    });
                }
            },
            blobHTTPHeaders: {
                blobContentType: video.type,
            },
        }).then((success) => {
            if (success && success._response && success._response.status >= 200 && success._response.status < 300) {
                this.removeStimulusFromUploadingList(videoUploadData.StimuliGuid);
                this.httpService.post({
                    url: '/ConversationService/StartEncodingForVideo',
                    data: videoUploadData,
                });
            }
        });
    }
    getStimulusUploadProgress(stimulusValue) {
        const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === stimulusValue)[0];
        return stimulusUpload ? stimulusUpload.progress : null;
    }
    removeStimulusFromUploadingList(stimulusValue) {
        const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === stimulusValue)[0];
        if (stimulusUpload) {
            this._stimuliUploads = this._stimuliUploads.filter((s) => s.stimulusValue !== stimulusValue);
        }
    }
    checkStimulusUrl(url, callback) {
        if (url && url !== '' && callback) {
            FileStorageService.stimulusCheckUrl(DateTime.utc(), this.httpService, url, callback);
        }
    }
    static stimulusCheckUrl(startDate, httpService, url, callback) {
        httpService.exists(url).then((success) => {
            if (success) {
                callback(false);
            }
        }, (error) => {
            if (error) {
                callback(true);
                const utccheck = DateTime.utc().minus({ minutes: 5 });
                if (startDate > utccheck) {
                    setTimeout(FileStorageService.stimulusCheckUrl, 2000, startDate, httpService, url, callback);
                }
            }
        });
    }
}
FileStorageService.$inject = [
    'httpService',
    '$window',
    '$timeout',
];
