import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';

const AdEmailMigrationPage = () => import('@/pages/ad-emailmigration-page.vue');

export const adEmailMigrationRoute: RouteRecordRaw = {
  name: RouteNames.ADEmailMigration,
  path: '/migrate',
  component: AdEmailMigrationPage,
  meta: {
    title: '',
    requireLogin: true,
    hideFooter: false,
  },
  beforeEnter: async () => {
    const squareStore = useSquareStore();
    const authStore = useAuthenticationStore();
    if (!squareStore.info.adLogin
      || !authStore.isAuthorized
      || (authStore.isAuthorized && authStore.isAdUser)) {
      return { name: RouteNames.Home };
    }
  },
};
