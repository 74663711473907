import { RouteParams, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { h } from 'vue';
import { guidRegex } from '@/router/router.constants';
import { usePagesStore } from '@/store/pages-store';

const NewsPage = () => import('@/pages/news-page.vue');

export const newsRoute: RouteRecordRaw = {
  name: RouteNames.News,
  path: `/news/:pageGuid(${guidRegex})`,
  component: NewsPage,
  meta: {
    requireLogin: true,
    title: (routeParams: RouteParams) => {
      const pagesStore = usePagesStore();
      return pagesStore.pageByGuid(routeParams.pageGuid as string)?.name || '(LabelWindowTitleNews)';
    },
  },
  children: [
    {
      name: RouteNames.NewsDetail,
      path: `detail/:cardGuid(${guidRegex})`,
      component: h(() => ''),
      meta: {
        requireLogin: true,
        title: '(LabelWindowTitleNews)',
      },
    },
  ],
};
