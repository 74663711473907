import { UiModule } from './../../../../../index';
var nwIndent = "<button class=\"nw-button fa-indent\" title=\"Indent\" ng-click=\"vm.indent()\" ng-disabled=\"vm.editMode || vm.disabled\">'Indent\n</button>";
UiModule
    .component('iscNwIndent', {
    template: nwIndent,
    controller: IndentController,
    controllerAs: 'vm',
    bindings: {
        execCommand: '=',
        editMode: '=',
        disabled: '=',
        content: '=',
    },
});
IndentController.$inject = ['iscDomhelperservice', '$element'];
function IndentController(iscDomhelperservice, $element) {
    var vm = this;
    vm.indent = function () {
        iscDomhelperservice.indentInNgWig();
    };
}
