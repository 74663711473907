const htmlTextFilter = (iscDomhelperservice) => {
    const addCommasForUnorderedListItems = (el) => {
        const uls = el.querySelectorAll('ul');
        _.forEach(uls, (ul) => {
            const listItems = _.filter(ul.childNodes, (childNode) => iscDomhelperservice.elementMatches(childNode, 'li'));
            listItems.splice(-1, 1);
            _.forEach(listItems, (li) => {
                const comma = document.createTextNode(', ');
                li.appendChild(comma);
            });
        });
    };
    const addNumbersForOrderedListItems = (el) => {
        const ols = el.querySelectorAll('ol');
        _.forEach(ols, (ol) => {
            const listItems = _.filter(ol.childNodes, (childNode) => iscDomhelperservice.elementMatches(childNode, 'li'));
            _.forEach(listItems, (li, index) => {
                const num = document.createTextNode(` ${index + 1}. `);
                li.insertBefore(num, li.firstChild);
            });
        });
    };
    const element = document.createElement('div');
    return (html) => {
        element.innerHTML = html;
        addCommasForUnorderedListItems(element);
        addNumbersForOrderedListItems(element);
        const text = element.textContent;
        element.innerHTML = '';
        return text;
    };
};
htmlTextFilter.$inject = ['iscDomhelperservice'];
angular
    .module('insitesApp.core')
    .filter('htmlText', htmlTextFilter);
