const RequestInterceptor = (logger, $q, $state, ssocheckService, $injector, __env, userService) => ({
    responseError: (response) => {
        if (response.status === 401) {
            if (!userService.isMobileAppUser()) {
                if (!ssocheckService.hasSsoToken()) {
                    $injector.get('authService').signOut(true);
                }
            }
        }
        else if (response.status === 500) {
            if (response.data.Errors || response.data.Errors) {
                const errors = response.data.Errors;
                for (const error of errors) {
                    logger.error('Something went wrong server side, check the console for the Stacktrace ------- ', error);
                }
            }
            else if (response.data.Message) {
                logger.error(response.data.Message);
            }
            else {
                logger.error('Something went wrong');
            }
        }
        else if (response.status === 409) {
            const buildversion = response.data.BuildVersion;
            let location = document.location.pathname;
            const regex = new RegExp(/^(\/\d{8}_\d{3}\/)/g);
            if (regex.test(location)) {
                location = location.replace(/^(\/\d{8}_\d{3}\/)/g, (match, text) => '/');
            }
            else {
                location = `/${buildversion}${location}`;
            }
            window.location.href = location;
        }
        else if (response.status === 503) {
            if ($state.current.name !== 'maintenance') {
                $state.go('maintenance', { goto: document.location.href });
            }
        }
        return $q.reject(response);
    },
});
RequestInterceptor.$inject = ['logger', '$q', '$state', 'ssocheckService', '$injector', '__env', 'userService'];
angular
    .module('insitesApp.core')
    .factory('httpRequestInterceptor', RequestInterceptor);
