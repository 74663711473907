import { __extends } from "tslib";
import { IscTaggingMenuItemController } from '../common-tagging/tagging-menu-item-base.controller';
var IscHashtagMenuItemController = (function (_super) {
    __extends(IscHashtagMenuItemController, _super);
    function IscHashtagMenuItemController() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return IscHashtagMenuItemController;
}(IscTaggingMenuItemController));
export { IscHashtagMenuItemController };
