import { UiModule } from '../../../index';
import { IscInputController } from './isc-input.controller';
var iscInputTemplate = "<md-input-container md-no-float>\n  <label ng-show=\"vm.label\" class=\"md-no-float isc-input-label\">{{vm.label}}</label>\n  <input\n    ng-attr-type=\"{{vm.type || text}}\"\n    ng-attr-min=\"{{vm.minValue}}\"\n    ng-attr-max=\"{{vm.maxValue}}\"\n    ng-model=\"vm.model\"\n    ng-required=\"vm.isRequired\"\n    ng-disabled=\"vm.isDisabled\"\n    ng-attr-name=\"{{vm.name}}\"\n    ng-pattern=\"vm.pattern\"\n    ng-attr-placeholder=\"{{vm.placeholder}}\"\n    ng-attr-md-autofocus=\"{{vm.autofocus}}\"\n    ng-change=\"vm.onValueChanged(vm.model)\"\n    ng-minlength=\"vm.minlength\"\n    md-maxlength=\"vm.maxlength\"\n    ng-focus=\"vm.onFocus()\"\n    ng-blur=\"vm.onBlurObject()\"\n    autocomplete=\"{{vm.isAutocompleteDisabled ? 'off' : 'on'}}\"\n    aria-label=\"{{ vm.label || vm.ariaLabel }}\"/>\n  <div ng-messages=\"vm.ngModelController.$error\" ng-transclude=\"errors\">\n  </div>\n</md-input-container>";
UiModule
    .component('iscInput', {
    template: iscInputTemplate,
    controllerAs: 'vm',
    controller: IscInputController,
    bindings: {
        type: '@',
        pattern: '<',
        label: '@',
        ariaLabel: '@',
        name: '@',
        autofocus: '@',
        placeholder: '@',
        isDisabled: '<',
        isRequired: '<',
        minValue: '<',
        maxValue: '<',
        minlength: '<',
        maxlength: '<',
        isChanged: '&?',
        model: '=',
        onBlur: '&?',
        isAutocompleteDisabled: '<',
    },
    transclude: {
        errors: '?iscInputErrors',
    },
});
UiModule
    .directive('iscInputErrors', function () {
    return {
        restrict: 'E',
        transclude: true,
        link: function ($scope, $element, $attr, ctrl, transclude) {
            transclude(function (clone) {
                if (clone.length) {
                    $element.replaceWith(clone);
                }
                else {
                    $element.remove();
                }
            }, $element.parent());
        },
    };
});
UiModule
    .directive('ngMessage', function () {
    return {
        restrict: 'A',
        compile: function (element, templateAttributes) {
            element.addClass('md-input-message-animation');
        },
    };
});
