import { CacheService } from './services/cache.service';
const ImagesFactory = ($q, _, squareFactory, __env, serverConstants) => {
    let envPrefix = '';
    if (__env.isEnvironmentAsPrefix) {
        envPrefix = `${__env.environment.toLowerCase()}/`;
    }
    const baseUrl = `${__env.azureBaseUrl + envPrefix + squareFactory.ClientGuid}/${squareFactory.Guid}/design-images/`;
    const emptyImage = '/images/empty.png';
    const imagesTparam = squareFactory.DateLastImageChange ? `?t=${new Date(squareFactory.DateLastImageChange).getTime()}` : '';
    const designImages = JSON.parse(CacheService.getCacheValue('Images'));
    const images = {
        logoTopBar: emptyImage,
        headerLogo: emptyImage,
        headerDesktop: emptyImage,
        headerMobile: emptyImage,
        background: emptyImage,
    };
    const applyImages = () => {
        applyFallbackForImages();
        applyContentBackground();
    };
    const applyFallbackForImages = () => {
        _.each(designImages, (key) => {
            images[_.camelCase(key)] = baseUrl + key + imagesTparam;
        });
        _.each(images, (imageSrc, key) => {
            checkImage(imageSrc).then((result) => {
                if ((key === _.camelCase(serverConstants.imagesConstants.headerDesktop) || key === _.camelCase(serverConstants.imagesConstants.headerMobile))
                    && images[key] === emptyImage) {
                    images[key] = null;
                }
                if (!result) {
                    images[key] = emptyImage;
                }
            });
        });
    };
    const checkImage = (imgSrc) => $q((resolve) => {
        const img = new Image();
        img.onerror = () => {
            resolve(false);
        };
        img.onload = () => {
            resolve(true);
        };
        img.src = imgSrc;
    });
    const applyContentBackground = () => {
        const src = images.background;
        const content = document.getElementsByClassName('app-background')[0];
        content.style.backgroundImage = `url(${src})`;
    };
    return {
        applyImages,
        images,
    };
};
ImagesFactory.$inject = ['$q', '_', 'squareFactory', '__env', 'serverConstants'];
angular
    .module('insitesApp.core')
    .factory('imagesFactory', ImagesFactory);
