import { DateTime } from 'luxon';
export function IscDateFormatFilter() {
    return function (value, format) {
        if (format === void 0) { format = 'DATE_SHORT'; }
        var dateTimeToUse;
        if (!value) {
            return '';
        }
        else if (value instanceof DateTime) {
            dateTimeToUse = value;
        }
        else if (value instanceof Date) {
            dateTimeToUse = DateTime.fromJSDate(value);
        }
        else {
            dateTimeToUse = DateTime.fromISO(value);
        }
        return dateTimeToUse.isValid ? dateTimeToUse.toFormat(format) : '';
    };
}
