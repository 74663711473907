import { NotificationsService } from './notifications';
import { DateTime } from 'luxon';
const platformhealth = (notifications, userService, $transitions, $location, featureToggleService) => {
    let notificationsConnected = false;
    const appInsights = window.appInsights;
    let startTimestamp;
    let durationMs;
    let previousPath;
    let currentPath;
    let connectionId;
    const skipPageViewLog = window.__env ? window.__env.environment === 'LOCALHOST' : false;
    let trackPageView;
    const path = () => $location.path();
    $transitions.onStart({}, () => {
        if (!skipPageViewLog) {
            onStart();
        }
    });
    $transitions.onSuccess({}, () => {
        if (!skipPageViewLog) {
            onSuccess();
            processInsights();
        }
        if (notificationsConnected && trackPageView) {
            sendPath();
        }
    });
    notifications.connected.subscribe((connected) => {
        notificationsConnected = connected;
        if (!skipPageViewLog) {
            if (notificationsConnected) {
                connectionId = NotificationsService.ConnectionId;
                const squareGuid = userService.squareGuid();
                const squareParticipantGuid = userService.userGuid();
                if (appInsights && squareGuid && squareParticipantGuid) {
                    appInsights.setAuthenticatedUserContext(squareParticipantGuid, squareGuid);
                }
            }
            else {
                connectionId = null;
                if (appInsights) {
                    appInsights.clearAuthenticatedUserContext();
                }
            }
        }
        sendPath();
    });
    const onStart = () => {
        startTimestamp = DateTime.utc();
        previousPath = $location.path();
    };
    const onSuccess = () => {
        const stopTimestamp = DateTime.utc();
        durationMs = stopTimestamp.toMillis() - startTimestamp.toMillis();
        trackPageView = true;
        currentPath = path();
        if (previousPath === currentPath) {
            previousPath = null;
            trackPageView = false;
        }
    };
    const sendPath = () => {
        notifications.pageVisited($location.path());
    };
    const processInsights = () => {
        if (appInsights && featureToggleService.shouldTrackInAppInsites) {
            appInsights.trackPageView(null, currentPath, {
                PreviousPath: previousPath,
                ConnectionId: connectionId,
            }, null, durationMs);
        }
    };
};
platformhealth.$inject = ['notifications', 'userService', '$transitions', '$location', 'featureToggleService'];
angular
    .module('insitesApp.core')
    .run(platformhealth);
