import { __extends } from "tslib";
import { HotkeySubscription } from '../HotKeySubscription';
var RedoHotkey = (function (_super) {
    __extends(RedoHotkey, _super);
    function RedoHotkey($document) {
        var _this = _super.call(this) || this;
        _this.$document = $document;
        _this.keyCode = 89;
        _this.ctrlKey = true;
        return _this;
    }
    RedoHotkey.prototype.handler = function (event) {
        this.redo(event);
    };
    RedoHotkey.prototype.redo = function (event) {
        this.$document[0].execCommand('redo', false);
        event.preventDefault();
    };
    return RedoHotkey;
}(HotkeySubscription));
export { RedoHotkey };
