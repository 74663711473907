import { __awaiter, __generator } from "tslib";
import { UiModule } from './../../index';
var SocialStimuliService = (function () {
    function SocialStimuliService($http, $q, _, stimuliservice, serverConstants) {
        var _this = this;
        this.$http = $http;
        this.$q = $q;
        this._ = _;
        this.stimuliservice = stimuliservice;
        this.serverConstants = serverConstants;
        this.socialStimuli = {
            youtube: {
                type: 'youtube',
                pattern: /(?:youtube\.com.+v=|youtu\.be\/)([a-z0-9_-]+)/i,
                getThumbnail: function (videoId) {
                    return _this.$q.resolve('https://img.youtube.com/vi/' + videoId + '/mqdefault.jpg');
                },
            },
            vimeo: {
                type: 'vimeo',
                pattern: /vimeo.com\/(?:[a-z]*\/)*([0-9]{6,11})/,
                getThumbnail: function (videoId) {
                    return _this.stimuliservice.getVimeoThumbnailImagePath(videoId).then(function (response) {
                        if (response.status === 200) {
                            return _this.$q.resolve(response.data);
                        }
                        else {
                            return _this.$q.reject(response);
                        }
                    });
                },
            },
        };
    }
    SocialStimuliService.prototype.parseUrl = function (url) {
        var result = null;
        this._.forEach(this.socialStimuli, function (s) {
            var match = url.match(s.pattern);
            if (match) {
                var videoId = match[1];
                result = {
                    type: s.type,
                    value: videoId,
                    thumbnailUrl: '',
                };
            }
        });
        return result;
    };
    SocialStimuliService.prototype.getThumbnail = function (socialStimuliType, videoId) {
        var result = null;
        var s = this._.find(this.socialStimuli, {
            type: socialStimuliType,
        });
        if (s) {
            result = s.getThumbnail(videoId);
        }
        return result;
    };
    SocialStimuliService.prototype.getVimeoThumbnail = function (videoId) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.stimuliservice.getVimeoThumbnailImagePath(videoId)];
                    case 1:
                        response = _a.sent();
                        if (response.status === 200) {
                            return [2, response.data];
                        }
                        return [2, this.$q.reject(response)];
                }
            });
        });
    };
    SocialStimuliService.prototype.getYoutubeThumbnail = function (videoId) {
        return 'https://img.youtube.com/vi/' + videoId + '/mqdefault.jpg';
    };
    SocialStimuliService.prototype.getStimulusThumbnail = function (stimulus) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = stimulus.type;
                        switch (_a) {
                            case this.serverConstants.conversationStimuliTypeConstants.image: return [3, 1];
                            case this.serverConstants.conversationStimuliTypeConstants.video: return [3, 2];
                            case this.serverConstants.conversationStimuliTypeConstants.youtube: return [3, 3];
                            case this.serverConstants.conversationStimuliTypeConstants.vimeo: return [3, 4];
                        }
                        return [3, 6];
                    case 1: return [2, stimulus.thumbnailUrl];
                    case 2: return [2, stimulus.thumbnailUrl];
                    case 3: return [2, this.getYoutubeThumbnail(stimulus.value)];
                    case 4: return [4, this.getVimeoThumbnail(stimulus.value)];
                    case 5: return [2, _b.sent()];
                    case 6: return [2, ''];
                }
            });
        });
    };
    SocialStimuliService.$inject = ['$http', '$q', '_', 'iscStimuliService', 'serverConstants'];
    return SocialStimuliService;
}());
export { SocialStimuliService };
UiModule.service('iscSocialStimuli', SocialStimuliService);
