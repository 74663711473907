export class PasswordValidator {
    constructor(serverConstants) {
        this.serverConstants = serverConstants;
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    link(scope, element, attrs, ngModel) {
        const validationConstants = this.serverConstants.validationConstants;
        ngModel.$validators.complexity = (modelValue, viewValue) => {
            const length = viewValue && viewValue.length >= validationConstants.passwordMinLength || false;
            const errors = {
                length: length !== false,
            };
            ngModel.$complexityError = errors;
            return length;
        };
    }
}
