import { ActivityController } from './activity.controller';
const template = "<landing-page\n  ng-hide=\"vm.type === 'activity'\"\n  landing-title=\"::'(ActivityPleaseWaitRedirectTitle)'\"\n  landing-message=\"::'(ActivityPleaseWaitRedirectMessage)'\">\n</landing-page>";
export const ActivityComponent = {
    controller: ActivityController,
    controllerAs: 'vm',
    template,
    bindings: {
        type: '@',
        sampleGuid: '@',
        activityGuid: '@',
        channel: '@?',
        bId: '@',
        partnerId: '@',
    },
};
