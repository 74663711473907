export const activityStartedKey = 'ASw77DjUMWvCRXSsb268TybU';
export class ActivityService {
    constructor(httpService, notifications, userService, squareFactory, $q) {
        this.httpService = httpService;
        this.notifications = notifications;
        this.userService = userService;
        this.squareFactory = squareFactory;
        this.$q = $q;
        this.profileActivities = this.notifications.profileActivities.startWith(() => this.getParticipantProfileActivities());
        this.progress = this.notifications.progress.startWith(() => this.getParticipantProgress());
        this.todoActivitiesCount = this.notifications.todoActivities.startWith(() => this.getTodoNotificationsCount());
    }
    getParticipantProfileActivities() {
        if (this.squareFactory.SsoEnabled) {
            return this.$q.resolve([]);
        }
        return this.httpService.get({
            url: '/ActivityService/ListParticipantProfileActivities',
            params: {
                Guid: this.userService.userGuid(),
            },
        }).then((response) => response.data.List);
    }
    getParticipantProgress() {
        return this.httpService.get({
            url: '/ActivityService/ListParticipantProgress',
            params: {
                participantGuid: this.userService.userGuid(),
            },
        }).then((response) => response.data);
    }
    getResearchSurveyLink(sampleGuid, channel) {
        return this.httpService.post({
            url: '/ActivityService/GetResearchSurveyLink',
            params: {
                sampleGuid,
                channel,
            },
        }).then((response) => response.data.Link);
    }
    getResearchSurveyLinkForActivity(activityGuid, channel) {
        return this.httpService.post({
            url: '/ActivityService/GetResearchSurveyLinkForActivity',
            params: {
                activityGuid,
                channel,
            },
        }).then((response) => response.data);
    }
    getProfileSurveyLink(activityGuid) {
        return this.httpService.post({
            url: '/ActivityService/GetProfileSurveyLink',
            params: {
                activityGuid,
            },
        }).then((response) => response.data.Link);
    }
    getScreenerSurveyLink(data) {
        return this.httpService.post({
            url: '/ActivityService/GetScreenerSurveyLink',
            data,
        }).then((response) => response.data.Link);
    }
    getTestSurveyLink(activityGuid) {
        return this.httpService.post({
            url: '/ActivityService/GetTestSurveyLink',
            params: {
                activityGuid,
            },
        }).then((response) => response.data);
    }
    getIntakeCallback(intakeGuid) {
        return this.httpService.get({
            url: '/ActivityService/SelectIntakeCallback',
            params: {
                intakeGuid,
            },
        }).then((response) => response.data);
    }
    getTestActivityDetails(activityGuid) {
        return this.httpService.get({
            url: '/ActivityService/GetTestActivityDetails',
            params: {
                activityGuid,
            },
        }).then((response) => ({
            guid: response.data.Guid,
            name: response.data.Name,
            type: response.data.Type,
        }));
    }
    getTodoNotificationsCount() {
        return this.httpService.get({
            url: '/SquareService/GetTodoNotificationsCount',
        }).then((response) => response.data);
    }
    getActivityCompleteRedirect(sampleGuid) {
        return this.httpService.get({
            url: '/ActivityService/GetActivityCompleteRedirect',
            params: {
                sampleGuid,
            },
        }).then((response) => response.data);
    }
}
ActivityService.$inject = ['httpService', 'notifications', 'userService', 'squareFactory', '$q'];
