var RemoveEmptyElement = (function () {
    function RemoveEmptyElement() {
    }
    RemoveEmptyElement.prototype.processElement = function (element) {
        if (element instanceof HTMLElement) {
            var isTextEmpty = element.innerText === '';
            var isBreak = isTextEmpty ? element.tagName === 'br' || element.tagName === 'BR' : false;
            var isImage = element.tagName === 'IMG';
            var containsImage = element.querySelector('img');
            if ((isTextEmpty && !isBreak) && !isImage && !containsImage) {
                return null;
            }
        }
        return element;
    };
    return RemoveEmptyElement;
}());
export default RemoveEmptyElement;
