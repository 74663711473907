import { AxiosRequestConfig, AxiosRequestTransformer } from 'axios';

export let httpService: {
  get: <T = unknown>(url: string, params?: Record<string, unknown>, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>, baseUrl?: string) => Promise<T>;
  post: <T = unknown>(url: string, data?: unknown, params?: Record<string, unknown>, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>, baseUrl?: string) => Promise<T>;
  put: <T = unknown>(url: string, data?: unknown, params?: Record<string, unknown>, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>, baseUrl?: string) => Promise<T>;
};
export let QUERY_API_URL: string;
export let COMMAND_API_URL: string;
export let MEMBER_API_URL: string;

export const configureApi = ($httpService: typeof httpService, queryApiUrl: string, commandApiUrl: string, memberApiUrl: string) => {
  httpService = $httpService;
  QUERY_API_URL = queryApiUrl;
  COMMAND_API_URL = commandApiUrl;
  MEMBER_API_URL = memberApiUrl;
};
