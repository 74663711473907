var ReplacePreWithDiv = (function () {
    function ReplacePreWithDiv() {
    }
    ReplacePreWithDiv.prototype.processElement = function (element) {
        if (element instanceof HTMLElement) {
            var containsPre = element.querySelector('pre');
            if (containsPre) {
                containsPre.outerHTML = containsPre.outerHTML.replace('pre', 'div');
            }
        }
        return element;
    };
    return ReplacePreWithDiv;
}());
export default ReplacePreWithDiv;
