export class FeatureToggleService {
    constructor(mediaservice) {
        this.mediaservice = mediaservice;
    }
    get shouldNotificationListBeLoaded() {
        return !this.isVersionedMobileAppLogin();
    }
    get shouldShowLogoutButton() {
        return !this.isMobileAppLogin();
    }
    get shouldShowNavigationMenu() {
        return !this.isVersionedMobileAppLogin();
    }
    get shouldShowHeaderImage() {
        return !this.isVersionedMobileAppLogin();
    }
    get shouldShowFooter() {
        return !this.isVersionedMobileAppLogin();
    }
    get shouldTrackInAppInsites() {
        return !this.isMobileAppLogin();
    }
    get shouldShowConversationAttachment() {
        return true;
    }
    isMobileAppLogin() {
        return this.mediaservice.isMobileApp;
    }
    isVersionedMobileAppLogin() {
        return this.mediaservice.isMobileApp && this.mediaservice.hasAppVersion();
    }
}
FeatureToggleService.$inject = ['mediaservice'];
