var AddFocusOnReplyTextboxOnClick = (function () {
    function AddFocusOnReplyTextboxOnClick($timeout) {
        this.$timeout = $timeout;
    }
    AddFocusOnReplyTextboxOnClick.prototype.link = function (scope, element, attr, ngModel) {
        var _this = this;
        element.on('mouseup', function () {
            _this.$timeout(function () { return applyWorkaroundForFocuseEditableContent(attr); }, 100);
        });
    };
    AddFocusOnReplyTextboxOnClick.$inject = ['$timeout'];
    return AddFocusOnReplyTextboxOnClick;
}());
export { AddFocusOnReplyTextboxOnClick };
function applyWorkaroundForFocuseEditableContent(attr) {
    if (document.querySelector('#conversationEditor_' + attr.conversationEditorId + ' [contenteditable]')) {
        var editableElement = document.querySelector('#conversationEditor_' + attr.conversationEditorId + ' [contenteditable]');
        editableElement.focus();
    }
}
