import { __awaiter } from "tslib";
import { Utils } from '../../core/utils';
import { DateTime } from 'luxon';
export class UserNotificationService {
    constructor(_, httpService, notifications, serverConstants, authService, forumService, $state, conversationService, trackingService, iscPages, constants, featureToggleService, $stateParams, featureService, incentiveService) {
        this._ = _;
        this.httpService = httpService;
        this.notifications = notifications;
        this.serverConstants = serverConstants;
        this.authService = authService;
        this.forumService = forumService;
        this.$state = $state;
        this.conversationService = conversationService;
        this.trackingService = trackingService;
        this.iscPages = iscPages;
        this.constants = constants;
        this.featureToggleService = featureToggleService;
        this.$stateParams = $stateParams;
        this.featureService = featureService;
        this.incentiveService = incentiveService;
        this._userNotifications = [];
        this.suspendedNotification = this.notifications.suspendedNotification;
        this.suspendedNotification.subscribe(() => {
            this.incomingSuspendedNotificationsHandler();
        });
        this._cancelScheduledExpirededNotificationRemoval = this._.noop;
        this.authService.on('logout', () => {
            this._userNotifications = [];
        });
        this.notificationTypes = this.serverConstants.notificationTypeConstants;
        this.notificationCategories = this.serverConstants.notificationCategoryConstants;
        this.channelTypes = this.serverConstants.channelTypeConstants;
        this.initData();
    }
    initData() {
        return __awaiter(this, void 0, void 0, function* () {
            const isAuthorized = yield this.authService.isAuthorized();
            if (this.featureToggleService.shouldNotificationListBeLoaded && isAuthorized) {
                yield this.getNotifications();
                this.notifications.userNotification
                    .subscribe(() => __awaiter(this, void 0, void 0, function* () {
                    yield this.getNotifications();
                }));
                this.notifications.connected.subscribe((connected) => __awaiter(this, void 0, void 0, function* () {
                    if (connected) {
                        yield this.getNotifications();
                    }
                }));
            }
        });
    }
    getNotifications() {
        return __awaiter(this, void 0, void 0, function* () {
            const notificationData = yield this.getUserNotifications();
            yield this.incomingNotificationsHandler(notificationData);
        });
    }
    get userNotifications() {
        return this._userNotifications;
    }
    markNotificationsAsReadByType(notificationType) {
        return __awaiter(this, void 0, void 0, function* () {
            this._userNotifications.map((notification) => __awaiter(this, void 0, void 0, function* () {
                if (notification.NotificationType === notificationType) {
                    yield this.markNotificationRead(notification.Guid, notification.RelatedNotificationGuids);
                }
                return;
            }));
        });
    }
    markNotificationRead(guid, relatedNotificationGuids) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: '/NotificationService/MarkNotificationRead',
                data: {
                    notificationGuid: guid,
                    relatedNotificationGuids: relatedNotificationGuids || [],
                },
            });
        });
    }
    markTopicNotificationsRead(topicGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: '/NotificationService/MarkTopicNotificationsRead',
                params: {
                    topicGuid,
                },
            });
        });
    }
    markActivityNotificationsRead(activityGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpService.post({
                url: '/NotificationService/MarkActivityNotificationsRead',
                params: {
                    activityGuid,
                },
            });
        });
    }
    clearAllNotifications() {
        return __awaiter(this, void 0, void 0, function* () {
            this._userNotifications = [];
            return this.httpService.post({
                url: '/NotificationService/ClearAllNotifications',
            });
        });
    }
    activeNotificationForTarget(targetGuid, notificationType) {
        const activeNotification = this._.find(this._userNotifications, (notification) => (notification.TargetGuid === targetGuid && notification.NotificationType === notificationType));
        return activeNotification;
    }
    resolveNotificationTargetGuid(notification) {
        return __awaiter(this, void 0, void 0, function* () {
            let targetGuid = notification.TargetGuid;
            if (notification.NotificationType === this.serverConstants.notificationTypeConstants.discussionReply ||
                notification.NotificationType === this.serverConstants.notificationTypeConstants.newReplyWithAnswerRequired ||
                notification.NotificationType === this.serverConstants.notificationTypeConstants.replyAccepted ||
                notification.NotificationType === this.serverConstants.notificationTypeConstants.discussionLike ||
                notification.NotificationType === this.serverConstants.notificationTypeConstants.discussionMention) {
                targetGuid = yield this.forumService.getQualSampleGuidFromReply(notification.TargetGuid);
            }
            return targetGuid;
        });
    }
    goToNotification(notificationGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            let notification = this._.find(this._userNotifications, (e) => e.Guid === notificationGuid);
            if (!notification) {
                notification = yield this.getUserNotification(notificationGuid);
            }
            if (!notification) {
                this.$state.go('container.main.home');
                return;
            }
            yield this.notificationClicked(notification);
        });
    }
    notificationClicked(notification) {
        return __awaiter(this, void 0, void 0, function* () {
            let channelType = null;
            if (this.$stateParams.channelType) {
                channelType = parseInt(this.$stateParams.channelType, 10);
            }
            yield this.markNotificationRead(notification.Guid, notification.RelatedNotificationGuids);
            const notificationTargetGuid = yield this.resolveNotificationTargetGuid(notification);
            let trackingGuid = notificationTargetGuid;
            switch (notification.NotificationType) {
                case this.notificationTypes.platformNotification: {
                    this.trackingService.platformNotificationClick(notification.TargetGuid, channelType);
                    window.open(notification.RedirectToUrl, '_self');
                    break;
                }
                case this.notificationTypes.profileActivityAvailable: {
                    trackingGuid = null;
                    this.$state.go('container.main.profile.activity', {
                        activityGuid: notificationTargetGuid,
                    });
                    break;
                }
                case this.notificationTypes.quantResearchActivityAvailable:
                case this.notificationTypes.quantResearchAlmostClosed:
                case this.notificationTypes.qualResearchActivityAvailable:
                case this.notificationTypes.qualResearchAlmostClosed:
                case this.notificationTypes.failedStimuliUpload:
                case this.notificationTypes.nextActivityInSequenceLaunched: {
                    if (notification.ActivityType === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey) {
                        const url = this.$state.href('container.activity', {
                            sampleGuid: notificationTargetGuid,
                            channel: this.channelTypes.notification,
                        });
                        window.open(url, '_blank');
                    }
                    else {
                        this.$state.go('container.activity', {
                            sampleGuid: notificationTargetGuid,
                            channel: this.channelTypes.notification,
                        });
                    }
                    break;
                }
                case this.notificationTypes.discussionMention:
                case this.notificationTypes.discussionReply:
                case this.notificationTypes.discussionLike:
                case this.notificationTypes.discussionMentions:
                case this.notificationTypes.discussionReplies:
                case this.notificationTypes.discussionLikes:
                case this.notificationTypes.discussionMentionReplyLike:
                case this.notificationTypes.discussionMentionRepliesLike:
                case this.notificationTypes.discussionMentionReplyLikes:
                case this.notificationTypes.discussionMentionRepliesLikes:
                case this.notificationTypes.discussionMentionsReplyLike:
                case this.notificationTypes.discussionMentionsRepliesLike:
                case this.notificationTypes.discussionMentionsReplyLikes:
                case this.notificationTypes.discussionMentionsRepliesLikes:
                case this.notificationTypes.discussionMentionReply:
                case this.notificationTypes.discussionMentionReplies:
                case this.notificationTypes.discussionMentionsReply:
                case this.notificationTypes.discussionMentionsReplies:
                case this.notificationTypes.discussionMentionLike:
                case this.notificationTypes.discussionMentionLikes:
                case this.notificationTypes.discussionMentionsLike:
                case this.notificationTypes.discussionMentionsLikes:
                case this.notificationTypes.discussionReplyLike:
                case this.notificationTypes.discussionRepliesLike:
                case this.notificationTypes.discussionReplyLikes:
                case this.notificationTypes.discussionRepliesLikes:
                case this.notificationTypes.newProbingQuestion:
                case this.notificationTypes.newReplyWithAnswerRequired:
                case this.notificationTypes.replyAccepted:
                case this.notificationTypes.newOptionalProbingQuestion:
                case this.notificationTypes.qualResearchActivityUpdate: {
                    yield this.conversationService.jumpToReplyQual(notification.TargetGuid, this.channelTypes.notification);
                    break;
                }
                case this.notificationTypes.forumMention:
                case this.notificationTypes.forumReply:
                case this.notificationTypes.forumLike:
                case this.notificationTypes.forumMentions:
                case this.notificationTypes.forumReplies:
                case this.notificationTypes.forumLikes:
                case this.notificationTypes.forumMentionReplyLike:
                case this.notificationTypes.forumMentionRepliesLike:
                case this.notificationTypes.forumMentionReplyLikes:
                case this.notificationTypes.forumMentionRepliesLikes:
                case this.notificationTypes.forumMentionsReplyLike:
                case this.notificationTypes.forumMentionsRepliesLike:
                case this.notificationTypes.forumMentionsReplyLikes:
                case this.notificationTypes.forumMentionsRepliesLikes:
                case this.notificationTypes.forumMentionReply:
                case this.notificationTypes.forumMentionReplies:
                case this.notificationTypes.forumMentionsReply:
                case this.notificationTypes.forumMentionsReplies:
                case this.notificationTypes.forumMentionLike:
                case this.notificationTypes.forumMentionLikes:
                case this.notificationTypes.forumMentionsLike:
                case this.notificationTypes.forumMentionsLikes:
                case this.notificationTypes.forumReplyLike:
                case this.notificationTypes.forumRepliesLike:
                case this.notificationTypes.forumReplyLikes:
                case this.notificationTypes.forumRepliesLikes:
                case this.notificationTypes.newLoungeTopic: {
                    yield this.conversationService.jumpToReplyForum(notification.TargetGuid);
                    break;
                }
                case this.notificationTypes.savingUpRewardsEarned:
                case this.notificationTypes.savingUpRewardsCorrectionApplied:
                case this.notificationTypes.rewardsAvailableForRedemption:
                case this.notificationTypes.moreSavingUpRewardsEarned: {
                    this.$state.go('container.main.incentives.mysavinguprewards');
                    break;
                }
            }
            if (trackingGuid && notification.Category === this.notificationCategories.activity) {
                this.trackingService.researchActivityNotificationClick(trackingGuid, channelType);
            }
        });
    }
    getUserNotifications() {
        return this.httpService.get({
            url: '/NotificationService/GetUserNotificationsForConsumerIf',
        }).then((response) => response.data.map((item) => (Object.assign(Object.assign({}, item), { ExpiryDate: DateTime.fromISO(item.ExpiryDate) }))));
    }
    getUserNotification(guid) {
        return this.httpService.get({
            url: '/NotificationService/GetUserNotification',
            params: {
                guid,
            },
        }).then((response) => response.data);
    }
    incomingNotificationsHandler(newNotifications) {
        return __awaiter(this, void 0, void 0, function* () {
            this._userNotifications = newNotifications;
            yield this.resolveSavingUpRewardNotificationsCount();
            this.scheduleExpiredNotificationRemoval();
        });
    }
    resolveSavingUpRewardNotificationsCount() {
        return __awaiter(this, void 0, void 0, function* () {
            const isSavingUpEnabled = yield this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards);
            if (!isSavingUpEnabled) {
                return;
            }
            let creditsCount = 0;
            const savingUpRewardNotification = this._.findLast(this._userNotifications, (item) => item.NotificationType === this.serverConstants.notificationTypeConstants.savingUpRewardsEarned);
            if (savingUpRewardNotification) {
                creditsCount = 1;
            }
            else {
                const moreSavingUpRewardNotification = this._.findLast(this._userNotifications, (item) => item.NotificationType === this.serverConstants.notificationTypeConstants.moreSavingUpRewardsEarned);
                if (moreSavingUpRewardNotification) {
                    creditsCount = moreSavingUpRewardNotification.RelatedNotificationGuids.length;
                }
            }
            this.incentiveService.totalCredits = creditsCount;
        });
    }
    scheduleExpiredNotificationRemoval() {
        this._cancelScheduledExpirededNotificationRemoval();
        if (this._userNotifications.length === 0) {
            this._cancelScheduledExpirededNotificationRemoval = this._.noop;
        }
        else {
            let minExpiryDate = this._userNotifications[0].ExpiryDate;
            this._userNotifications.forEach((n) => {
                if (n.ExpiryDate < minExpiryDate) {
                    minExpiryDate = n.ExpiryDate;
                }
            });
            this._cancelScheduledExpirededNotificationRemoval = Utils.runAtDate(minExpiryDate, () => {
                this.removeExpiredNotifications();
                this.scheduleExpiredNotificationRemoval();
            });
        }
    }
    removeExpiredNotifications() {
        const currentDate = DateTime.utc();
        this._userNotifications = this._.filter(this._userNotifications, (notification) => notification.ExpiryDate > currentDate);
    }
    incomingSuspendedNotificationsHandler() {
        this.authService.signOut(true);
    }
}
UserNotificationService.$inject = ['_', 'httpService', 'notifications', 'serverConstants', 'authService', 'forumService', '$state',
    'conversationService', 'trackingService', 'iscPages', 'constants', 'featureToggleService', '$stateParams',
    'featureService', 'incentiveService'];
