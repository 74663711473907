import { UiModule } from '../../../index';
import { IscModalPresetController } from './isc-modal-preset.controller';
var template = "<isc-modal-dialog class=\"buttons-right transparent-actions\" ng-class=\"{ 'hide-toolbar': !vm.titleText, 'grey-footer': vm.question != '' }\">\n  <isc-modal-toolbar>\n    <h1 class=\"h2\">{{vm.titleText}}</h1>\n  </isc-modal-toolbar>\n  <isc-modal-content>\n    <p class=\"text\" ng-bind-html=\"vm.text\"></p>\n    <isc-input ng-if=\"vm.placeholder\" class=\"md-block\" model=\"vm.title\" is-required=\"true\" maxlength=\"vm.toInt(vm.maxLength)\" placeholder=\"{{vm.placeholder}}\">\n      <isc-input-errors>\n        <div ng-message=\"required\">{{vm.requiredText}}</div>\n        <div ng-message=\"md-maxlength\">{{vm.maxLengthMessage}}</div>\n      </isc-input-errors>\n    </isc-input>\n    <p ng-if=\"vm.question\" class=\"question\">{{vm.question}}</p>\n  </isc-modal-content>\n  <isc-modal-actions ng-if=\"!vm.question\">\n    <isc-button ng-if=\"vm.stayText\" class=\"flat primary\" label=\"{{vm.stayText}}\" ng-click=\"vm.stay()\"></isc-button>\n    <isc-button ng-if=\"vm.cancelText\" class=\"flat primary\" label=\"{{vm.cancelText}}\" ng-click=\"vm.cancel()\"></isc-button>\n    <isc-button ng-if=\"vm.okText && !vm.cancelText\" class=\"flat primary\" label=\"{{vm.okText}}\" ng-click=\"vm.close()\"></isc-button>\n    <isc-button ng-if=\"vm.okText && vm.cancelText\" class=\"primary\" label=\"{{vm.okText}}\" ng-click=\"vm.close()\"></isc-button>\n  </isc-modal-actions>\n  <isc-modal-actions ng-if=\"vm.question\">\n    <isc-button ng-if=\"vm.okText && !vm.cancelText\" class=\"flat primary\" label=\"{{vm.okText}}\" ng-click=\"vm.close()\"></isc-button>\n    <isc-button ng-if=\"vm.okText && vm.cancelText\" class=\"primary\" label=\"{{vm.okText}}\" ng-click=\"vm.close()\"></isc-button>\n    <isc-button ng-if=\"vm.cancelText\" class=\"flat primary\" label=\"{{vm.cancelText}}\" ng-click=\"vm.cancel()\"></isc-button>\n  </isc-modal-actions>\n</isc-modal-dialog>";
UiModule
    .component('iscModalPreset', {
    template: template,
    controllerAs: 'vm',
    controller: IscModalPresetController,
    bindings: {
        text: '@',
        titleText: '@',
        okText: '@',
        question: '@',
        cancelText: '@',
        stayText: '@',
        placeholder: '@',
        requiredText: '@',
        maxLength: '@',
        maxLengthMessage: '@',
        title: '@',
    },
});
