import { PlatformNoticeController } from './platform-notice.controller';
const template = "<div class=\"platform-notice\" id=\"platform-notice\" ng-if=\"platform.showPlatformNotice\">\n  <span ng-bind-html=\"platform.platformNotice.customMessage\"></span>\n  <md-icon class=\"fa fa-xmark\" ng-click=\"platform.closePlatformNotice()\"></md-icon>\n</div>";
export const PlatformNoticeComponent = {
    controller: PlatformNoticeController,
    template,
    controllerAs: 'platform',
    bindings: {
        padding: '=',
    },
};
