import { PaginationController } from './pagination.controller';
const template = "﻿<md-button isc-translate=\"[aria-label](LabelPaginationPreviousPage)\" class=\"pull-left md-primary\" ng-class=\"{'hide-arrow' : vm.currentPage === 1}\"\n  ng-click=\"vm.navigate(vm.currentPage - 1)\">\n  <i class=\"typcn typcn-chevron-left\"></i>\n</md-button>\n<md-button isc-translate=\"[aria-label](LabelPaginationNextPage)\" class=\"pull-right md-primary\" ng-class=\"{'hide-arrow' : vm.currentPage === vm.totalPages}\"\n  ng-click=\"vm.navigate(vm.currentPage + 1)\">\n  <i class=\"typcn typcn-chevron-right\"></i>\n</md-button>\n<div>\n  <md-button class=\"md-primary\" ng-class=\"{ 'md-raised': 1 === vm.currentPage }\" ng-click=\"vm.navigate(1)\">1</md-button>\n  <span class=\"dots\" ng-show=\"vm.showDotsAfterFirstPage\">\n        <i class=\"typcn typcn-media-record\"></i>\n        <i class=\"typcn typcn-media-record\"></i>\n        <i class=\"typcn typcn-media-record\"></i>\n    </span>\n  <md-button class=\"md-primary\" ng-class=\"{ 'md-raised': pageNumber === vm.currentPage }\" ng-repeat=\"pageNumber in vm.pages\"\n    ng-click=\"vm.navigate(pageNumber)\">{{pageNumber}}</md-button>\n  <span class=\"dots\" ng-show=\"vm.showDotsBeforeLastPage\">\n        <i class=\"typcn typcn-media-record\"></i>\n        <i class=\"typcn typcn-media-record\"></i>\n        <i class=\"typcn typcn-media-record\"></i>\n    </span>\n  <md-button class=\"md-primary\" ng-hide=\"vm.totalPages === 1\" ng-class=\"{ 'md-raised': vm.totalPages === vm.currentPage }\"\n    ng-click=\"vm.navigate(vm.totalPages)\">{{vm.totalPages}}</md-button>\n</div>";
export const PaginationComponent = {
    controller: PaginationController,
    template,
    controllerAs: 'vm',
    bindings: {
        currentPage: '<',
        totalPages: '<',
        onPageClick: '&',
    },
};
