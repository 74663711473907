<template>
  <div
    v-if="showImages"
    class="header-images"
  >
    <div
      v-if="images.headerDesktop || images.headerMobile"
      class="header-background"
    >
      <q-img
        v-if="images.headerDesktop"
        class="header-background__desktop gt-sm"
        :src="images.headerDesktop"
        alt=""
      />
      <q-img
        v-if="images.headerMobile"
        class="header-background__mobile lt-md"
        :src="images.headerMobile"
        alt=""
      />
    </div>
    <div
      v-if="images.headerLogo"
      class="header-logo"
    >
      <q-img
        :src="images.headerLogo"
        :class="headerLogoClass"
        alt=""
        fit="none"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { emptyImage, useImagesStore } from '@/store/images-store';
import { computed } from 'vue';

const imagesStore = useImagesStore();
const images = computed(() => imagesStore.$state);
const showImages = computed(() => images.value.headerDesktop
  || images.value.headerMobile
  || (images.value.headerLogo && images.value.headerLogo !== emptyImage));
const headerLogoClass = computed(() =>
  (images.value.headerDesktop && images.value.headerDesktop !== emptyImage)
    ? 'header-logo__img absolute-top absolute-bottom'
    : 'header-logo__img');
</script>

<style lang="scss">
.header-images {
  position: relative;
}

.header-background {
  img {
    width: 100%;
  }
}
</style>
