import { BaseDataService } from './base.data.service';
import * as _ from 'lodash';
export class ConfigurationService extends BaseDataService {
    constructor(httpService, serverConstants) {
        super(httpService);
        this.serverConstants = serverConstants;
    }
    getFileConfiguration() {
        return this.getOrCached({
            url: '/ConfigurationService/GetFileConfiguration',
        }, BaseDataService.SECONDS_ONE_DAY).then((response) => response.data);
    }
    getFileTypes() {
        return this.serverConstants.fileTypeConstants;
    }
    getAcceptedMimeTypesAndExtensions(fileConfiguration, fileTypes) {
        let mimeTypes = [];
        let extensions = [];
        let patterns = [];
        if (fileTypes && fileTypes.length) {
            _.forEach(fileTypes, (fileType) => {
                const fileTypeConfig = _.find(fileConfiguration, (config) => config.FileType === fileType);
                if (fileTypeConfig && fileTypeConfig.Extensions) {
                    _.forEach(fileTypeConfig.Extensions, (ext) => {
                        mimeTypes = mimeTypes.concat(ext.MimeType);
                        extensions = extensions.concat(ext.Extension);
                        patterns = patterns.concat(`.${ext.Extension}`);
                    });
                }
            });
        }
        return { mimeTypes, extensions, patterns };
    }
}
ConfigurationService.$inject = ['httpService', 'serverConstants'];
