import { IscVideoController } from './isc-video.controller';
var videoTemplate = "<isc-button-icon ng-attr-id=\"{{vm.videoId}}\" class=\"play-button\" ng-click=\"vm.startOnClick()\">\n  <md-icon md-svg-src=\"/images/ico-play.svg\" aria-label=\"\"></md-icon>\n</isc-button-icon>\n<video class=\"video-clickable azuremediaplayer amp-default-skin amp-big-play-centered\" width=\"100%\" height=\"100%\">\n</video>";
export var IscVideoComponent = {
    controller: IscVideoController,
    controllerAs: 'vm',
    template: videoTemplate,
    bindings: {
        videoId: '<',
        videoThumbnail: '<',
        playerVars: '<?',
        videoType: '<?',
    },
};
