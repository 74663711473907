var Utils = (function () {
    function Utils() {
    }
    Utils.stringEndsWith = function (text, end) {
        var result = false;
        var index = text.indexOf(end);
        if (index !== -1) {
            result = index === (text.length - end.length);
        }
        return result;
    };
    Utils.isMobileView = function () {
        return window.innerWidth <= 959.98;
    };
    return Utils;
}());
export { Utils };
