export default (function (html) {
    html = html.replace(/<o:p>\s*<\/o:p>/g, '');
    html = html.replace(/<o:p>.*?<\/o:p>/g, ' ');
    html = html.replace(/\s*mso-[^:]+:[^;"']+;?/gi, '');
    html = html.replace(/\s*MARGIN:[^\s;]+;?;/gi, '');
    html = html.replace(/\s*MARGIN:[^\s;]+;?"/gi, '\"');
    html = html.replace(/\s*MARGIN:[^\s;]+;?'/gi, '\'');
    html = html.replace(/\s*TEXT-INDENT:[^\s;]+;?;/gi, '');
    html = html.replace(/\s*TEXT-INDENT:[^\s;]+;?"/gi, '\"');
    html = html.replace(/\s*TEXT-INDENT:[^\s;]+;?'/gi, '\'');
    html = html.replace(/\s*TEXT-ALIGN:[^\s;]+;?;/gi, '');
    html = html.replace(/\s*TEXT-ALIGN:[^\s;]+;?"/gi, '\"');
    html = html.replace(/\s*TEXT-ALIGN:[^\s;]+;?'/gi, '\'');
    html = html.replace(/\s*PAGE-BREAK-BEFORE:[^\s;]+;?;/gi, '');
    html = html.replace(/\s*PAGE-BREAK-BEFORE:[^\s;]+;?"/gi, '\"');
    html = html.replace(/\s*PAGE-BREAK-BEFORE:[^\s;]+;?'/gi, '\'');
    html = html.replace(/\s*FONT-VARIANT:[^\s;]+;?;/gi, '');
    html = html.replace(/\s*FONT-VARIANT:[^\s;]+;?"/gi, '\"');
    html = html.replace(/\s*FONT-VARIANT:[^\s;]+;?'/gi, '\'');
    html = html.replace(/\s*tab-stops:[^;"']*;?/gi, '');
    html = html.replace(/\s*tab-stops:[^"']*/gi, '');
    html = html.replace(/\s*face="[^"]*"/gi, '');
    html = html.replace(/\s*face='[^']*'/gi, '');
    html = html.replace(/\s*face=[^ >]*/gi, '');
    html = html.replace(/\s*lang="[^"]*"/gi, '');
    html = html.replace(/\s*lang='[^']*'/gi, '');
    html = html.replace(/\s*lang=[^ >]*/gi, '');
    html = html.replace(/\s*style=["']\s*["']/gi, '');
    html = html.replace(/<SPAN\s*[^>]*>\s* \s*<\/SPAN>/gi, ' ');
    html = html.replace(/<SPAN\s*[^>]*><\/SPAN>/gi, '');
    html = html.replace(/<SPAN\s*>(.*?)<\/SPAN>/gi, '$1');
    html = html.replace(/<\\?\?xml[^>]*>/gi, '');
    html = html.replace(/<\/?\w+:[^>]*>/gi, '');
    html = html.replace(/<\!--.*?-->/g, '');
    html = html.replace(/<(U|I|STRIKE)> <\/\1>/g, ' ');
    html = html.replace(/<H\d>\s*<\/H\d>/gi, '');
    html = html.replace(/<(\w+)[^>]*\sstyle="[^"]*DISPLAY\s?:\s?none(.*?)<\/\1>/ig, '');
    html = html.replace(/<(\w[^>]*) language=([^ |>]*)([^>]*)/gi, '<$1$3');
    html = html.replace(/<(\w[^>]*) onmouseover="([^\"]*)"([^>]*)/gi, '<$1$3');
    html = html.replace(/<(\w[^>]*) onmouseover='([^\']*)'([^>]*)/gi, '<$1$3');
    html = html.replace(/<(\w[^>]*) onmouseout="([^\"]*)"([^>]*)/gi, '<$1$3');
    html = html.replace(/<(\w[^>]*) onmouseout='([^\']*)'([^>]*)/gi, '<$1$3');
    html = html.replace(/<H1([^>]*)>/gi, '<div$1>');
    html = html.replace(/<H2([^>]*)>/gi, '<div$1><b>');
    html = html.replace(/<H3([^>]*)>/gi, '<div$1><b>');
    html = html.replace(/<H4([^>]*)>/gi, '<div$1><b>');
    html = html.replace(/<H5([^>]*)>/gi, '<div$1><b>');
    html = html.replace(/<H6([^>]*)>/gi, '<div$1><b>');
    html = html.replace(/<\/H\d>/gi, '<\/font><\/b><\/div>');
    html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, ' ');
    html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, ' ');
    html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, ' ');
    html = html.replace(/\n\s*\n/g, '\n');
    html = html.replace(/\n/g, ' ');
    return html;
});
