import * as angular from 'angular';
import * as _ from 'lodash';
var TimeLineController = (function () {
    function TimeLineController(timelineService, $timeout, $q) {
        var _this = this;
        this.timelineService = timelineService;
        this.$timeout = $timeout;
        this.$q = $q;
        this.registeredCards = {};
        this.debouncedHandleScroll = _.debounce(function () { return _this.handleScroll(); }, 100);
        this.drawing = false;
        this.hasMoreCards = true;
        this.updating = false;
    }
    Object.defineProperty(TimeLineController.prototype, "cards", {
        get: function () {
            return this._cards;
        },
        set: function (value) {
            this._cards = value;
            this.hasMoreCards = true;
            this.updating = false;
        },
        enumerable: false,
        configurable: true
    });
    TimeLineController.prototype.$onInit = function () {
        var _this = this;
        if (this.infinite && !this.infiniteCallback) {
            throw Error('Infinite argument specified but no callback present');
        }
        if (this.infinite) {
            this.prevScrollPosition = window.pageYOffset;
            document.addEventListener('scroll', this.debouncedHandleScroll);
        }
        angular.element(document).ready(function () {
            if (_this.hideEnding) {
                _this.hideEndingLine();
            }
        });
    };
    TimeLineController.prototype.$onDestroy = function () {
        var _this = this;
        document.removeEventListener('scroll', this.debouncedHandleScroll);
        if (this.cardPromises) {
            _.forEach(this.cardPromises, function (promise) {
                if (promise !== undefined) {
                    _this.$timeout.cancel(promise);
                }
            });
        }
    };
    TimeLineController.prototype.registerCard = function (guid, $element) {
        var _this = this;
        this.registeredCards[guid] = $element;
        this.checkIfCardsRegistered();
        return function () { return delete _this.registeredCards[guid]; };
    };
    TimeLineController.prototype.checkIfCardsRegistered = function () {
        var _this = this;
        var allCardsRegistered = this.cards && _.every(this.cards, function (card) { return !!_this.registeredCards[card.Guid]; });
        if (!allCardsRegistered) {
            return;
        }
        this.drawing = true;
        var firstIndex = null;
        var isNewCardOnPageAdded = this.isNewCardOnPage();
        this.cardPromises = _.map(this.cards, function (card, index) {
            var element = _this.registeredCards[card.Guid];
            if (element.hasClass('hide')) {
                if (firstIndex === null) {
                    firstIndex = index;
                }
                return _this.$timeout(function () {
                    element.removeClass('hide');
                    angular.element(window).triggerHandler('checkInView');
                    _this.timelineService.calculateCard(element, isNewCardOnPageAdded);
                    isNewCardOnPageAdded = false;
                }, (index - firstIndex) * 100);
            }
        });
        this.$q.all(this.cardPromises).finally(function () {
            _this.drawing = false;
        });
    };
    TimeLineController.prototype.isNewCardOnPage = function () {
        var _this = this;
        if (this.cards[0] == null || this.cards[1] == null) {
            return false;
        }
        var element1 = this.registeredCards[this.cards[0].Guid];
        var element2 = this.registeredCards[this.cards[1].Guid];
        if (element1.hasClass('hide') && !element2.hasClass('hide')) {
            this.cards.forEach(function (card) {
                var element = _this.registeredCards[card.Guid];
                if (!element.hasClass('hide')) {
                    element.addClass('hide');
                }
            });
            return true;
        }
        return false;
    };
    TimeLineController.prototype.handleScroll = function () {
        if (this.updating || this.drawing || this.hasMoreCards === false) {
            return;
        }
        var scrollPos = window.pageYOffset;
        if (scrollPos === this.prevScrollPosition || Math.abs(scrollPos - this.prevScrollPosition) < 50) {
            return;
        }
        var pageHeight = document.documentElement.scrollHeight;
        var clientHeight = document.documentElement.clientHeight;
        if ((pageHeight - (scrollPos + clientHeight)) < 50) {
            this.loadMoreCards();
        }
        this.prevScrollPosition = scrollPos;
    };
    TimeLineController.prototype.loadMoreCards = function () {
        var _this = this;
        if (this.hasMoreCards === false) {
            return;
        }
        this.updating = true;
        var promise = this.infiniteCallback();
        if (!promise || !promise.finally) {
            throw new Error('Callback function should return a promise');
        }
        promise.then(function (data) {
            if (!_this.updating) {
                return;
            }
            if (typeof (data.HasMoreCards) !== 'boolean') {
                throw new Error('Please let your callback function return a boolean to indicate if the end of the list has been reached');
            }
            var hasMoreCards = data.HasMoreCards;
            if (!hasMoreCards && data.List.length === 0) {
                _this.hasMoreCards = hasMoreCards;
                _this.updating = hasMoreCards;
            }
        });
        promise.finally(function () {
            _this.updating = false;
        });
    };
    TimeLineController.prototype.loadMoreClick = function () {
        if (this.infinite && this.hasMoreCards !== false && !this.updating) {
            this.loadMoreCards();
        }
    };
    TimeLineController.prototype.showTooltipLoadMore = function () {
        return this.infinite && this.hasMoreCards !== false && !this.updating;
    };
    TimeLineController.prototype.noMoreCards = function () {
        return this.infinite && this.hasMoreCards === false;
    };
    TimeLineController.prototype.showTooltipLoading = function () {
        return this.infinite && this.updating;
    };
    TimeLineController.prototype.ctaClick = function (card) {
        switch (card.ChannelType) {
            case this.channelTypes.communicationCard:
                this.onEngagementCardOnHomePageCtaClick({ card: card });
                break;
            case this.channelTypes.researchActivityChallengeHome:
                this.onResearchActivityCardOnHomePageCtaClick({ card: card });
                break;
            case this.channelTypes.communicationNews:
                this.onNewsCardOnHomePageCtaClick({ card: card });
                break;
        }
    };
    TimeLineController.prototype.trackViewed = function (card) {
        switch (card.ChannelType) {
            case this.channelTypes.communicationCard:
                this.onEngagementCardOnHomePageTrackViewed({ card: card });
                break;
            case this.channelTypes.researchActivityChallengeHome:
                this.onResearchActivityCardOnHomePageTrackViewed({ card: card });
                break;
            case this.channelTypes.communicationNews:
                this.onNewsCardOnHomePageTrackViewed({ card: card });
                break;
        }
    };
    TimeLineController.prototype.newsCardLikeClick = function (card) {
        this.onNewsCardOnHomePageLikeClick({ card: card });
    };
    TimeLineController.prototype.createDateLabelUpdater = function (card) {
        return this.createCardDateLabelUpdater({ card: card });
    };
    TimeLineController.prototype.hideEndingLine = function () {
        var timelines = document.querySelectorAll('isc-timeline');
        _.forEach(timelines, function (t) {
            var timelineBadges = t.querySelectorAll('.timeline-badge');
            var last = timelineBadges[timelineBadges.length - 1];
            last.classList.add('hide-ending');
        });
    };
    TimeLineController.prototype.toggleCard = function (card, $event) {
        var timelineBadges = $event.target.closest('isc-timeline').querySelectorAll('.timeline-badge');
        var first = timelineBadges[0];
        var last = timelineBadges[timelineBadges.length - 1];
        if (card.isOpen) {
            first.classList.remove('hide-ending');
            last.classList.add('hide-ending');
        }
        else {
            first.classList.add('hide-ending');
            last.classList.remove('hide-ending');
        }
    };
    TimeLineController.$inject = ['timelineService', '$timeout', '$q'];
    return TimeLineController;
}());
export { TimeLineController };
