export class HelpcenterController {
    constructor($stateParams, _, $timeout, iscPages, $state) {
        this.$stateParams = $stateParams;
        this._ = _;
        this.$timeout = $timeout;
        this.iscPages = iscPages;
        this.$state = $state;
        this.contactmodel = {};
        this.selectedIndex = 0;
    }
    $onInit() {
        this.iscPages.init().then(() => {
            this.tabs = this.iscPages.helpCenterPages;
            if (this.$stateParams.helpcenterPage) {
                this.$timeout(() => {
                    this.selectedIndex = this._.findIndex(this.tabs, ['Guid', this.$stateParams.helpcenterPage]);
                });
            }
            else if (this.tabs !== undefined && this.tabs.length > 0) {
                this.selectedIndex = 0;
                this.goToTab(this.tabs[0]);
            }
        });
    }
    goToTab(item) {
        this.iscPages.goToPage(item);
        this.selectTab(item);
    }
    selectTab(tab) {
        this.selectedIndex = this._.findIndex(this.tabs, ['Guid', tab.Guid]);
    }
    get currentRoute() {
        return this.$state.current.name;
    }
}
HelpcenterController.$inject = ['$stateParams', '_', '$timeout', 'iscPages', '$state'];
