import { IscHashtagController } from './hashtag.controller';
var IscHashtagDirective = (function () {
    function IscHashtagDirective(iscHashtagUtil, $document, $compile, $log, $timeout, $parse) {
        this.iscHashtagUtil = iscHashtagUtil;
        this.$document = $document;
        this.$compile = $compile;
        this.$log = $log;
        this.$timeout = $timeout;
        this.$parse = $parse;
        this.restrict = 'A';
        this.require = 'ngModel';
        this.controller = IscHashtagController;
    }
    IscHashtagDirective.prototype.link = function (scope, element, attrs, ctrl) {
        var _this = this;
        if (!scope.hashtag.disabled) {
            scope.hashtag.triggerCharMap = {};
            scope.hashtag.targetElement = element;
            attrs.$set('autocomplete', 'off');
            scope.hashtag.defaultTriggerChar = '#';
            var html = "<isc-hashtag-menu\n          isc-hashtag-search=\"hashtag.bridgeSearch(term)\"\n          isc-hashtag-select=\"hashtag.bridgeSelect(item)\"\n          isc-hashtag-items=\"hashtag.items\"\n          isc-hashtag-trigger-char=\"'".concat(scope.hashtag.defaultTriggerChar, "'\"\n          isc-hashtag-replace-text=\"hashtag.replaceText(text)\"\n          isc-hashtag-add-menu=\"hashtag.addMenu(menuScope)\"/>");
            var linkFn = this.$compile(html);
            var el_1 = linkFn(scope);
            element.parent().append(el_1);
            scope.$on('$destroy', function () {
                el_1.remove();
            });
            ctrl.$viewChangeListeners.push(function () {
                var newValue = ctrl.$viewValue;
                if ((!newValue || newValue === '') && !scope.hashtag.isActive()) {
                    return;
                }
                if (scope.hashtag.triggerCharSet === undefined) {
                    _this.$log.error('Error, no isc-mention-items attribute was provided, ' +
                        'and no separate isc-mention-menus were specified.  Nothing to do.');
                    return;
                }
                if (scope.hashtag.contentEditableMenuPasted) {
                    scope.hashtag.contentEditableMenuPasted = false;
                    return;
                }
                var isActive = scope.hashtag.isActive();
                var hashtagInfo = _this.iscHashtagUtil.getTriggerInfo(scope.hashtag.triggerCharSet, isActive);
                if (hashtagInfo !== undefined &&
                    (!isActive ||
                        (isActive &&
                            hashtagInfo.taggingTriggerChar === scope.hashtag.currentMentionTriggerChar))) {
                    if (hashtagInfo.taggingSelectedElement) {
                        scope.hashtag.targetElement = hashtagInfo.taggingSelectedElement;
                        scope.hashtag.targetElementPath = hashtagInfo.taggingSelectedPath;
                        scope.hashtag.targetElementSelectedOffset = hashtagInfo.taggingSelectedOffset;
                    }
                    scope.hashtag.setTriggerText(hashtagInfo.taggingText);
                    scope.hashtag.currentMentionPosition = hashtagInfo.taggingPosition;
                    scope.hashtag.currentMentionTriggerChar = hashtagInfo.taggingTriggerChar;
                    scope.hashtag.query(hashtagInfo.taggingTriggerChar, hashtagInfo.taggingText);
                }
                else {
                    scope.hashtag.setTriggerText('');
                    scope.hashtag.hideAll();
                }
            });
        }
    };
    IscHashtagDirective.factory = function () {
        var directive = function (iscHashtagUtil, $document, $compile, $log, $timeout, $parse) {
            return new IscHashtagDirective(iscHashtagUtil, $document, $compile, $log, $timeout, $parse);
        };
        directive.$inject = ['iscHashtagUtil', '$document', '$compile', '$log', '$timeout', '$parse'];
        return directive;
    };
    IscHashtagDirective.$inject = ['iscHashtagUtil', '$document', '$compile', '$log', '$timeout', '$parse'];
    return IscHashtagDirective;
}());
export { IscHashtagDirective };
