import { ConfirmitEmbedController } from './confirmit-embed.controller';
const template = "<iframe class=\"confirmit-embed\" scrolling=\"no\"></iframe>";
export const ConfirmitEmbedComponent = {
    controller: ConfirmitEmbedController,
    template,
    controllerAs: 'vm',
    bindings: {
        surveyUrl: '<',
        onSurveyComplete: '&',
    },
};
