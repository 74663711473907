import * as angular from 'angular';
var IscFallbackSrc = (function () {
    function IscFallbackSrc() {
    }
    IscFallbackSrc.prototype.link = function (scope, element, attr) {
        element.bind('error', function () {
            if (attr.src !== attr.iscFallbackSrc && attr.iscFallbackSrc !== '') {
                angular.element(this).attr('src', attr.iscFallbackSrc);
            }
        });
    };
    IscFallbackSrc.factory = function () {
        var directive = function () { return new IscFallbackSrc(); };
        return directive;
    };
    return IscFallbackSrc;
}());
export { IscFallbackSrc };
