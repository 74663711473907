import { UiModule } from '../../../index';
import { IscButtonController } from './isc-button.controller';
var template = "<md-button ng-attr-type=\"{{vm.type || 'button'}}\" ng-disabled=\"vm.disabled\" ng-class=\"[ vm.themeClass, vm.label ? 'has-label': '']\"\n  aria-label=\"{{::vm.ariaLabel}}\">\n  <isc-button-icon ng-show=\"::vm.hasIcon\" ng-transclude=\"icon\" class=\"icon\"></isc-button-icon>\n  <span class=\"label\">{{vm.label}}</span>\n  <span ng-if=\"::vm.hasAlternativeLabel\" class=\"label\" ng-transclude=\"alternativeLabel\"></span>\n</md-button>\n";
UiModule
    .component('iscButton', {
    template: template,
    controllerAs: 'vm',
    controller: IscButtonController,
    transclude: {
        icon: '?iscButtonIcon',
        alternativeLabel: '?iscButtonLabel',
    },
    bindings: {
        type: '@',
        label: '@',
        ariaLabel: '@',
        theme: '@',
    },
});
