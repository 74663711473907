import { UiModule } from '../../../index';
UiModule.directive('richInputValidator', richInputValidatorDirective);
richInputValidatorDirective.$inject = [];
function richInputValidatorDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            var thisAttrs = attrs;
            ngModel.$validators.descriptionRequired = function () {
                if (thisAttrs.richInputValidator !== 'true') {
                    return true;
                }
                var currentValue = ngModel.$viewValue;
                if (currentValue) {
                    var div = document.createElement('div');
                    div.innerHTML = currentValue;
                    if (doesElementContainText(div) || doesElementContainNonEmojiImages(div)) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            };
        },
    };
}
function doesElementContainText(element) {
    var text = element.textContent;
    return text && text.trim();
}
function doesElementContainNonEmojiImages(element) {
    var images = element.querySelectorAll('img');
    var emojiClass = 'emoji';
    var containsNonEmojiImages = false;
    for (var _i = 0, images_1 = images; _i < images_1.length; _i++) {
        var image = images_1[_i];
        if (!image.classList.contains(emojiClass)) {
            containsNonEmojiImages = true;
            break;
        }
    }
    return containsNonEmojiImages;
}
