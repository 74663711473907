import { __awaiter } from "tslib";
import { activityStartedKey } from './../core/dataservices/activity.service';
import { CacheService } from '../core/services/cache.service';
export class ActivityController {
    constructor(activityService, $window, $state, $location, _, authService, iscPages, serverConstants, mediaservice, $stateParams, activitySequenceDataService) {
        this.activityService = activityService;
        this.$window = $window;
        this.$state = $state;
        this.$location = $location;
        this._ = _;
        this.authService = authService;
        this.iscPages = iscPages;
        this.serverConstants = serverConstants;
        this.mediaservice = mediaservice;
        this.$stateParams = $stateParams;
        this.activitySequenceDataService = activitySequenceDataService;
    }
    $onInit() {
        this.setParamValues();
        let redirectPromise;
        switch (this.type) {
            case 'intake':
                redirectPromise = this.redirectToIntakeSurvey();
                break;
            case 'test':
                redirectPromise = this.redirectToTestSurvey();
                break;
            case 'researchactivity':
                redirectPromise = this.redirectToSurveyUsingActivityGuid();
                break;
            default:
                redirectPromise = this.redirectToSurvey();
                break;
        }
        redirectPromise.catch((error) => __awaiter(this, void 0, void 0, function* () { return yield this.handleInvalidActivity(error); }));
    }
    setParamValues() {
        this.setType();
        this.sampleGuid = this.$stateParams.sampleGuid;
        this.activityGuid = this.$stateParams.activityGuid;
        this.channel = this.$stateParams.channel;
        this.bId = this.$stateParams.bid;
        this.subsource = this.$stateParams.subsource;
        this.partnerId = this.$stateParams.partnerId || this.$stateParams.partnerid;
    }
    setType() {
        switch (this.$state.current.name) {
            case 'container.activity':
                this.type = 'activity';
                break;
            case 'container.researchactivity':
                this.type = 'researchactivity';
                break;
            case 'container.testactivity':
                this.type = 'test';
                break;
            case 'container.intakeactivity':
                this.type = 'intake';
                break;
        }
    }
    redirectToIntakeSurvey() {
        const backgroundVarRegex = new RegExp(`bvar${this.partnerId}_\\d+`, 'i');
        const backgroundVariables = [];
        let Country;
        this._.forOwn(this.$location.search(), (value, key) => {
            if (key.match(backgroundVarRegex)) {
                backgroundVariables.push({
                    Variable: key,
                    Value: value,
                });
            }
            if (key === 'qtc') {
                Country = value;
            }
        });
        return this.activityService.getScreenerSurveyLink({
            ActivityGuid: this.activityGuid,
            PartnerId: this.partnerId,
            BId: this.bId,
            Subsource: this.subsource,
            BackgroundVariables: backgroundVariables,
            Country,
        })
            .then((link) => this.redirectToLink(link))
            .catch((e) => __awaiter(this, void 0, void 0, function* () {
            yield this.handleInvalidActivity(e);
        }));
    }
    redirectToTestSurvey() {
        return this.activityService.getTestSurveyLink(this.activityGuid)
            .then((response) => {
            if (!response.IsProfileActivity) {
                this.redirectToLink(response.Link);
            }
            else {
                this.redirectToTestProfileActivity(response.Link);
            }
        });
    }
    redirectToTestProfileActivity(link) {
        this.$state.go('container.test-profile-activity', {
            link,
            activityGuid: this.$stateParams.activityGuid,
        });
    }
    redirectToSurvey() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.sampleGuid && this.sampleGuid !== this.serverConstants.validationConstants.emptyGuid) {
                CacheService.setCache({ key: activityStartedKey, value: this.sampleGuid });
                return this.activityService.getResearchSurveyLink(this.sampleGuid, this.channel)
                    .then((link) => this.redirectToLink(link));
            }
            else if (this.activityGuid) {
                return this.redirectToSurveyUsingActivityGuid();
            }
        });
    }
    redirectToSurveyUsingActivityGuid() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.activityService.getResearchSurveyLinkForActivity(this.activityGuid, this.channel)
                .then((responseData) => {
                this.sampleGuid = responseData.SampleGuid;
                CacheService.setCache({ key: activityStartedKey, value: this.sampleGuid });
                this.redirectToLink(responseData.Link);
            });
        });
    }
    redirectToLink(link) {
        const redirectUrl = this.mediaservice.appendMobileAppParam(link);
        if (link.indexOf('http') === 0) {
            this.$window.location.replace(redirectUrl);
        }
        else {
            this.$location.url(redirectUrl).replace();
        }
    }
    handleInvalidActivity(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const isAuthorized = yield this.authService.isAuthorized();
            const redirectState = isAuthorized ? 'container.main.home' : 'container.login';
            let redirectToMobileAppHome = false;
            if (this.mediaservice.isMobileApp && !this.mediaservice.hasAppVersion()) {
                redirectToMobileAppHome = true;
            }
            switch (response.data.Reason) {
                case 'activity_not_live':
                    this.$state.go('container.landingpage', {
                        landingTitle: '(LabelLandingPageTitleActivities)',
                        landingMessage: isAuthorized ? '(LabelLandingPageMessageActivityUnavailable)' : '(LabelLandingPageMessageDefault)',
                        redirectState,
                        redirectToMobileAppHome,
                        timeout: 5000,
                    });
                    break;
                case 'activity_participant_not_active':
                    this.$state.go('container.landingpage', {
                        landingTitle: '(LabelLandingPageTitleActivities)',
                        landingMessage: '(LabelLandingPageMessageParticipantNonActive)',
                        redirectToMobileAppHome,
                        timeout: -1,
                    });
                    break;
                case 'activity_finished':
                    this.$state.go('container.landingpage', {
                        landingTitle: '(LabelLandingPageTitleActivities)',
                        landingMessage: isAuthorized ? '(LabelLandingPageMessageActivityFinished)' : '(LabelLandingPageMessageDefault)',
                        redirectToMobileAppHome,
                        redirectState,
                        timeout: 5000,
                    });
                    break;
                case 'participant_not_targeted_for_activity':
                    this.$state.go('container.landingpage', {
                        landingTitle: '(LabelLandingPageTitleActivities)',
                        landingMessage: isAuthorized ? '(LabelLandingPageMessageParticipantNotTargetedForActivity)' : '(LabelLandingPageMessageDefault)',
                        redirectState: isAuthorized ? 'container.main.home' : 'container.login',
                        timeout: 5000,
                    });
                    break;
                case 'activity_not_yet_live':
                    this.$state.go('container.landingpage', {
                        landingTitle: '(LabelLandingPageTitleActivities)',
                        landingMessage: isAuthorized ? '(LabelLandingPageMessageActivityNotYetLive)' : '(LabelLandingPageMessageDefault)',
                        redirectState: isAuthorized ? 'container.main.home' : 'container.login',
                        timeout: 5000,
                    });
                    break;
                case 'wrong_participant':
                    this.$state.go('container.login', {
                        redirectState: this.$state.current.name,
                        redirectParams: {
                            sampleGuid: this.sampleGuid,
                            channel: this.channel,
                        },
                    });
                    break;
                case 'activity_locked':
                    const firstUnlockedActivityGuid = yield this.activitySequenceDataService.getFirstIncompleteActivityGuidInSequence(null, this.sampleGuid);
                    this.$state.go('container.landingpage', {
                        landingTitle: '(LabelLandingPageTitleActivityLocked)',
                        landingMessage: '(LabelLandingPageMessageActivityLocked)',
                        redirectState: 'container.researchactivity',
                        activityGuid: firstUnlockedActivityGuid,
                        channelType: this.serverConstants.channelTypeConstants.researchActivityChallenge,
                        timeout: 5000,
                    });
                    break;
                default:
                    this.$state.go('container.landingpage', {
                        redirectState,
                        redirectToMobileAppHome,
                        timeout: 5000,
                    });
                    break;
            }
        });
    }
}
ActivityController.$inject = ['activityService', '$window', '$state', '$location', '_',
    'authService', 'iscPages', 'serverConstants', 'mediaservice', '$stateParams', 'activitySequenceDataService'];
