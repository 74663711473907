export class SpinnerController {
    constructor($scope, spinnerservice) {
        this.$scope = $scope;
        this.spinnerservice = spinnerservice;
    }
    $onInit() {
        if (!this.register) {
            this.register = true;
        }
        else {
            this.register = this.register.toLowerCase() === 'true';
        }
        const api = {
            name: this.name,
            group: this.group,
            show: () => {
                this.show = true;
            },
            hide: () => {
                this.show = false;
            },
            toggle: () => {
                this.show = !this.show;
            },
        };
        if (this.register === true) {
            this.spinnerservice._register(api);
        }
        if (this.onShow || this.onHide) {
            this.$scope.$watch('show', function (show) {
                if (show && this.onShow) {
                    this.onShow({ spinnerservice: this.spinnerservice, spinnerApi: api });
                }
                else if (!show && this.onHide) {
                    this.onHide({ spinnerservice: this.spinnerservice, spinnerApi: api });
                }
            });
        }
        if (this.onLoaded) {
            this.onLoaded({ spinnerservice: this.spinnerservice, spinnerApi: api });
        }
    }
}
SpinnerController.$inject = ['$scope', 'spinnerservice'];
angular
    .module('insitesApp.core')
    .component('iscSpinnerComponent', {
    template: '<div class="spinnerContent" ng-if="vm.show">' +
        '<img ng-if="vm.imgSrc" ng-src="{{vm.imgSrc}}" />' +
        '<ng-transclude></ng-transclude>' +
        '</div>',
    transclude: true,
    controller: SpinnerController,
    controllerAs: 'vm',
    bindings: {
        name: '@?',
        group: '@?',
        show: '=?',
        imgSrc: '@?',
        register: '@?',
        onLoaded: '&?',
        onShow: '&?',
        onHide: '&?',
    },
});
