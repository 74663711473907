import { __awaiter } from "tslib";
export class IncentivesController {
    constructor(_, $timeout, iscPages, $state) {
        this._ = _;
        this.$timeout = $timeout;
        this.iscPages = iscPages;
        this.$state = $state;
        this._selectedIndex = 0;
    }
    get selectedIndex() {
        return this._selectedIndex;
    }
    set selectedIndex(value) {
        this._selectedIndex = value;
        this.iscPages.goToPage(this.tabs[value]);
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.iscPages.init();
            if (this.iscPages.incentives.length === 0) {
                this.$state.go('container.main.home');
            }
            this.tabs = this._.clone(this.iscPages.incentives);
            do {
                yield this.$timeout();
                this._selectedIndex = this.tabs.findIndex((t) => t.Route === this.$state.current.name);
            } while (this._selectedIndex === -1);
        });
    }
    onLinkClicked(event, page) {
        if (!page.CustomUrl) {
            event.preventDefault();
        }
        else {
            event.stopPropagation();
        }
    }
    get currentRoute() {
        return this.$state.current.name;
    }
}
IncentivesController.$inject = ['_', '$timeout', 'iscPages', '$state'];
