import * as _ from 'lodash';
import * as tinycolor from 'tinycolor2';
import * as angular from 'angular';
import * as emojione from 'emojione';
const isInIframe = () => {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
};
const isInternetExplorer = () => {
    const userAgent = window.navigator.userAgent;
    return !!userAgent.match(/Trident|MSIE/g);
};
const isIOS = () => {
    const userAgent = window.navigator.userAgent;
    return !!userAgent.match(/iPad|iPhone|iPod/g);
};
angular
    .module('insitesApp.core')
    .constant('_', _)
    .constant('tinycolor', tinycolor)
    .constant('amp', window.amp)
    .constant('emojione', emojione)
    .constant('constants', {
    isInIframe: isInIframe(),
    itemsPerPage: isInternetExplorer() ? 10 : 50,
    isIOS: isIOS(),
});
