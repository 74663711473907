import { __awaiter } from "tslib";
export class DiscussionLoungeService {
    constructor(httpService) {
        this.httpService = httpService;
    }
    createOrUpdateTopic(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/DiscussionLoungeService/CreateOrUpdateTopic',
                data: Object.assign({}, request),
            });
            return response.data;
        });
    }
    removeTopic(request) {
        this.httpService.post({
            url: '/DiscussionLoungeService/RemoveTopic',
            data: request,
        }).then((response) => response.data);
    }
}
DiscussionLoungeService.$inject = ['httpService'];
