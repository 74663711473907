import { __awaiter } from "tslib";
import { CacheService } from './services/cache.service';
export class ConstantsFactory {
    constructor(projectService, __env, authService) {
        this.projectService = projectService;
        this.__env = __env;
        this.authService = authService;
        this.labelsCache = { key: `Labels-${this.__env.buildVersion}`, durationDays: 1 };
        this.authService.on('logout', () => CacheService.removeCacheValue(this.labelsCache.key));
    }
    getLabelValue(name) {
        return this.getLabels().then((dict) => dict[name] || '');
    }
    getLabelValueReplaceUrl(name, url) {
        const matchTextUrlPlaceholder = /\{(.+)\|(url)\}/g;
        return this.getLabelValue(name).then((value) => value.replace(matchTextUrlPlaceholder, (match, text) => `<a href="${url}">${text}</a>`));
    }
    getLabels() {
        return __awaiter(this, arguments, void 0, function* (reset = false) {
            if (reset) {
                CacheService.removeCacheValue(this.labelsCache.key);
            }
            if (!this.labels || reset) {
                const labelsList = CacheService.getCacheValue('Language');
                if (labelsList) {
                    this.labels = yield CacheService.getOrSet(() => this.projectService.getListLabel(labelsList), this.labelsCache.key, this.labelsCache.durationDays);
                }
            }
            return this.labels;
        });
    }
}
ConstantsFactory.$inject = ['projectService', '__env', 'authService'];
