import {
  ListJobItemResponse,
  ListLabelResponse,
  ListLanguageResponse,
  ListCultureResponse,
  SquareInfoResponse,
  ListCurrencyResponse,
  ListCurrencyIdResponse,
  ConfigListResponse,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const selectionJob = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListJobItemResponse>('/ProjectService/SelectionJob', undefined, config);

export const listLabel = (language?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListLabelResponse>('/ProjectService/ListLabel', { language }, config);

export const listLabelInLanguage = (language?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListLabelResponse>('/ProjectService/ListLabelInLanguage', { language }, config);

export const listEmailLabelInLanguage = (language?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListLabelResponse>('/ProjectService/ListEmailLabelInLanguage', { language }, config);

export const listEmailDefaultLabelInLanguage = (language?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListLabelResponse>('/ProjectService/ListEmailDefaultLabelInLanguage', { language }, config);

export const listLanguage = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListLanguageResponse>('/ProjectService/ListLanguage', undefined, config);

export const listCulture = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListCultureResponse>('/ProjectService/ListCulture', undefined, config);

export const squareInfo = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<SquareInfoResponse>('/ProjectService/SquareInfo', undefined, config);

export const listCurrency = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListCurrencyResponse>('/ProjectService/ListCurrency', undefined, config);

export const listNoRwardPartnerCurrency = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ListCurrencyIdResponse>('/ProjectService/ListNoRwardPartnerCurrency', undefined, config);

export const getTermsAndConditionsUrl = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/ProjectService/GetTermsAndConditionsUrl', undefined, config);

export const validateDatabaseState = (isClientDb: boolean, interfaceName?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<void>('/ProjectService/ValidateDatabaseState', { isClientDb, interfaceName }, config);

export const configList = (keys?: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<ConfigListResponse>('/ProjectService/ConfigList', { keys }, config);
