export class IsExpanded {
    constructor() {
        this.restrict = 'A';
    }
    link(scope, element) {
        scope.$watch(() => element.hasClass('is-expanded'), (newValue) => {
            if (newValue) {
                scope.question.isExpanded = true;
            }
            else {
                scope.question.isExpanded = false;
            }
        });
    }
}
