import {
  PaginatedResultOfCommandConversationElementDetailItem,
  ForumConversationElementsListRequest,
  MentionUserListItem,
  GetMentionUsersForConversationRequest,
  PaginatedResultOfConversationListItem,
  ForumConversationListRequest,
  TopicNotificationCountResponse,
  ListRoomItemResponse,
  ListRoomItemRequest,
  RoomDetailItemResponse,
  RoomDetailItemRequest,
  ProbequestionsResponse,
  GetActivityBaseRequest,
  ForumConversationResponse,
  ForumConversationRequest,
  ConversationLocationResponse,
  ConversationLocationRequest,
  ListForumRoomsResponse,
  ListForumRoomsRequest,
  PaginatedResultOfConversationSearchResultListItem,
  ForumRoomSearchRequest,
  QualConversationElementsConsumerResponse,
  QualConversationElementsListRequest,
  QualConversationElementResponse,
  QualConversationElementRequest,
  GetReplyBaseRequest,
  QualConversationElementsModerationRequest,
  QualConversationElementsAdminResponse,
  MemberQualConversationElementsAdminResponse,
  ParticipantConversationsRequest,
  HashtagSquareParticipantListItem,
  GetHashtagsForConversationRequest,
  GetMentionUsersForConversationAdminRequest,
  GetMentionUsersForConversationForumRequest,
  MemberDetailsResponse,
  MemberDetailsRequest,
  TagListItem,
  GetTagsForModerationRequest,
  ConversationTagDetail,
  GetConversationTagListRequest,
  ConversationHashtagDetail,
  GetConversationHashtagListRequest,
} from '../../../models/command';
import{ httpService } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const getConversationElements = (request: ForumConversationElementsListRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<PaginatedResultOfCommandConversationElementDetailItem>(
    '/ForumService/GetConversationElements',
    request,
    undefined,
    config);

export const getMentionUsersForConversation = (request: GetMentionUsersForConversationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<MentionUserListItem[]>(
    '/ForumService/GetMentionUsersForConversation',
    request,
    undefined,
    config);

export const getConversationsInRoom = (request: ForumConversationListRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<PaginatedResultOfConversationListItem>(
    '/ForumService/GetConversationsInRoom',
    request,
    undefined,
    config);

export const getTopicNotificationCounts = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<TopicNotificationCountResponse[]>(
    '/ForumService/GetTopicNotificationCounts',
    undefined,
    undefined,
    config);

export const getForumRooms = (request: ListRoomItemRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ListRoomItemResponse>(
    '/ForumService/GetForumRooms',
    request,
    undefined,
    config);

export const getRoomDetails = (request: RoomDetailItemRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<RoomDetailItemResponse>(
    '/ForumService/GetRoomDetails',
    request,
    undefined,
    config);

export const getProbequestionsForFiltering = (request: GetActivityBaseRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ProbequestionsResponse[]>(
    '/ForumService/GetProbequestionsForFiltering',
    request,
    undefined,
    config);

export const getForumConversation = (request: ForumConversationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ForumConversationResponse>(
    '/ForumService/GetForumConversation',
    request,
    undefined,
    config);

export const getConversationLocation = (request: ConversationLocationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ConversationLocationResponse>(
    '/ForumService/GetConversationLocation',
    request,
    undefined,
    config);

export const listForumRooms = (request: ListForumRoomsRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ListForumRoomsResponse>(
    '/ForumService/ListForumRooms',
    request,
    undefined,
    config);

export const searchRoom = (request: ForumRoomSearchRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<PaginatedResultOfConversationSearchResultListItem>(
    '/ForumService/SearchRoom',
    request,
    undefined,
    config);

export const getQualConversationElements = (request: QualConversationElementsListRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<QualConversationElementsConsumerResponse>(
    '/ForumService/GetQualConversationElements',
    request,
    undefined,
    config);

export const getQualConversationElementOrDefault = (request: QualConversationElementRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<QualConversationElementResponse>(
    '/ForumService/GetQualConversationElementOrDefault',
    request,
    undefined,
    config);

export const getQualSampleGuidFromReply = (request: GetReplyBaseRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ForumService/GetQualSampleGuidFromReply',
    request,
    undefined,
    config);

export const getFirstUnReadConversation = (request: QualConversationElementsModerationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<QualConversationElementResponse>(
    '/ForumService/GetFirstUnReadConversation',
    request,
    undefined,
    config);

export const getQualConversationElementsForModeration = (request: QualConversationElementsModerationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<QualConversationElementsAdminResponse>(
    '/ForumService/GetQualConversationElementsForModeration',
    request,
    undefined,
    config);

export const getParticipantConversations = (request: ParticipantConversationsRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<MemberQualConversationElementsAdminResponse>(
    '/ForumService/GetParticipantConversations',
    request,
    undefined,
    config);

export const getHashtagsForConversation = (request: GetHashtagsForConversationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<HashtagSquareParticipantListItem[]>(
    '/ForumService/GetHashtagsForConversation',
    request,
    undefined,
    config);

export const getMentionUsersForConversationAdmin = (request: GetMentionUsersForConversationAdminRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<MentionUserListItem[]>(
    '/ForumService/GetMentionUsersForConversationAdmin',
    request,
    undefined,
    config);

export const getMentionUsersForConversationForForum = (request: GetMentionUsersForConversationForumRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<MentionUserListItem[]>(
    '/ForumService/GetMentionUsersForConversationForForum',
    request,
    undefined,
    config);

export const getForumRoomsAdmin = (request: ListRoomItemRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ListRoomItemResponse>(
    '/ForumService/GetForumRoomsAdmin',
    request,
    undefined,
    config);

export const getMemberDetails = (request: MemberDetailsRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<MemberDetailsResponse>(
    '/ForumService/GetMemberDetails',
    request,
    undefined,
    config);

export const getTagsForModeration = (request: GetTagsForModerationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<TagListItem[]>(
    '/ForumService/GetTagsForModeration',
    request,
    undefined,
    config);

export const getConversationTagList = (request: GetConversationTagListRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ConversationTagDetail[]>(
    '/ForumService/GetConversationTagList',
    request,
    undefined,
    config);

export const getConversationHashtagList = (request: GetConversationHashtagListRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ConversationHashtagDetail[]>(
    '/ForumService/GetConversationHashtagList',
    request,
    undefined,
    config);
