import 'angular-square/dist/app/layout';
import 'angular-square/dist/app/core';
import 'angular-square/dist/app/membership';
import 'angular-square/dist/app/home';
import 'angular-square/dist/app/maintenance';
import 'angular-square/dist/app/externalLink';
import 'angular-square/dist/app/gotonotification';
import 'angular-square/dist/app/more';
import 'angular-square/dist/app/challenges';
import 'angular-square/dist/app/forum';
import 'angular-square/dist/app/news';
import 'angular-square/dist/app/reusables';
import 'angular-square/dist/app/mainPageCustom';
import 'angular-square/dist/app/helpcenter';
import 'angular-square/dist/app/profile';
import 'angular-square/dist/app/public-profile';
import 'angular-square/dist/app/incentives';
import 'angular-square/dist/app/footerpage';
import 'angular-square/dist/app/landingpages';
import 'angular-square/dist/app/landingpages/force-mobile-app';
import 'angular-square/dist/app/activity';
import 'angular-square/dist/app/intakefinished';
import 'angular-square/dist/app/splashPage';
import 'angular-square/dist/app/splashPage/content';
import 'angular-square/dist/app/loginerror';
import 'angular-square/dist/app/404';

import 'angular-square/node_modules/angular-material/angular-material.css';
import 'angular-square/node_modules/ng-img-crop/compile/unminified/ng-img-crop.css';
import 'angular-square/node_modules/ng-wig/dist/css/ng-wig.min.css';
import 'angular-square/node_modules/v-accordion/dist/v-accordion.css';
import 'typicons.font/src/font/typicons.css';

import * as angular from 'angular';
import {
  AUTHENTICATION_BASE,
  AZURE_BASE_URL,
  BASE_URL,
  BUILD_VERSION,
  CAPTCHA_METHOD,
  RECAPTCHA_FALLBACKMODE,
  RECAPTCHAV3_SITE_ID,
  RECAPTCHAV2_SITE_ID,
  COMMAND_API_URL,
  DECIPHER_URL,
  HEALTHDASHBOARD_URL,
  ENABLE_DEBUG,
  ENVIRONMENT,
  ICP_REDIRECT_URL,
  INSITES_URL,
  INSTRUMENTATION_KEY,
  IS_ENVIRONMENT_AS_PREFIX,
  QUERY_API_URL,
  MEMBER_API_URL,
  SHOW_ICP_LICENSE,
  SIGNALR_URL,
  SQUARE_URL_BASE,
} from '@/common/env.config';
import { noop } from 'lodash-es';

const env = {
  signalrUrl: SIGNALR_URL,
  queryApiUrl: QUERY_API_URL,
  commandApiUrl: COMMAND_API_URL,
  memberApiUrl: MEMBER_API_URL,

  instrumentationKey: INSTRUMENTATION_KEY,
  environment: ENVIRONMENT,

  authenticationBase: AUTHENTICATION_BASE,
  insitesUrl: INSITES_URL,
  baseUrl: BASE_URL,
  azureBaseUrl: AZURE_BASE_URL,
  squareUrlBase: SQUARE_URL_BASE,
  isEnvironmentAsPrefix: IS_ENVIRONMENT_AS_PREFIX,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output in some cases
  enableDebug: ENABLE_DEBUG,
  buildVersion: BUILD_VERSION,

  decipherUrl: DECIPHER_URL,
  healthDashboardUrl: HEALTHDASHBOARD_URL,

  showICPLicense: SHOW_ICP_LICENSE,
  ICPRedirectUrl: ICP_REDIRECT_URL,

  CaptchaMethod: CAPTCHA_METHOD,
  ReCaptchaFallbackMode: RECAPTCHA_FALLBACKMODE,
  ReCaptchaV3SiteId: RECAPTCHAV3_SITE_ID,
  ReCaptchaV2SiteId: RECAPTCHAV2_SITE_ID,
};

angular.module('env', [])
  .constant('__env', env)
  .constant('authenticationUrl', `${env.authenticationBase}?return_uri=`);

// Overwrite router modules, since we don't want them in Vue
angular.module('blocks.router', [])
  .provider('routerHelper', class RouterHelperProvider {
    configure = noop;
    $get = () => ({ configureStates: noop });
  });
angular.module('ui.router', []);

angular.module('insitesApp', [
  'env',
  'insitesApp.core',
  'insitesApp.layout',
  'insitesApp.home',
  'insitesApp.maintenance',
  'insitesApp.externalLink',
  'insitesApp.gotonotification',
  'insitesApp.more',
  'insitesApp.challenges',
  'insitesApp.forum',
  'insitesApp.news',
  'insitesApp.mainPageCustom',
  'insitesApp.helpcenter',
  'insitesApp.profile',
  'insitesApp.incentives',
  'insitesApp.footer',
  'insitesApp.registration',
  'insitesApp.login',
  'insitesApp.sso',
  'insitesApp.autologin',
  'insitesApp.confirmEmail',
  'insitesApp.unsubscribe',
  'insitesApp.reactivate',
  'insitesApp.landingpage',
  'insitesApp.activity',
  'insitesApp.intakefinished',
  'insitesApp.incentiveLandingpage',
  'insitesApp.splashPage',
  'insitesApp.forceMobileApp',
  'insitesApp.testProfileActivity',
  'angularXRegExp',
  'insitesApp.loginerror',
  'insitesApp.consumer404Page',
]);
