import {
  UserNotificationResponse,
  EmailType,
  UserNotificationMobile,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const getUserNotificationsForConsumerIf = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<UserNotificationResponse[]>('/NotificationService/GetUserNotificationsForConsumerIf', undefined, config);

export const getUserNotificationsForAdminIf = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<UserNotificationResponse[]>('/NotificationService/GetUserNotificationsForAdminIf', undefined, config);

export const getUserNotifications = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<UserNotificationResponse[]>('/NotificationService/GetUserNotifications', undefined, config);

export const getUserNotification = (guid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<UserNotificationResponse>('/NotificationService/GetUserNotification', { guid }, config);

export const getEmailTemplate = (emailType: EmailType, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<string>('/NotificationService/GetEmailTemplate', { emailType }, config);

export const getUserNotificationsMobile = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<UserNotificationMobile[]>('/NotificationService/GetUserNotificationsMobile', undefined, config);
