import { __awaiter } from "tslib";
import { IscPagesService } from '../core/isc-pages.service';
export class MoreController {
    constructor(iscPages, _, participantFactory, squareFactory, authService, projectService, userService, __env, serverConstants) {
        this.iscPages = iscPages;
        this._ = _;
        this.participantFactory = participantFactory;
        this.squareFactory = squareFactory;
        this.authService = authService;
        this.projectService = projectService;
        this.userService = userService;
        this.__env = __env;
        this.serverConstants = serverConstants;
        this.showMyCookieSettings = this.__env.cookieYesSiteId !== '';
        this.showIcpLicense = this.__env.showICPLicense;
        this.ICPRedirectUrl = this.__env.ICPRedirectUrl;
        this.participant = this.participantFactory;
        this.pageTypeConstants = serverConstants.pageTypeConstants;
    }
    get onBehalfOfUsername() {
        if (this.authService.isImpersonating) {
            return this.authService.impersonate.username;
        }
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.iscPages.init();
            this.mainPages = this._.filter(this.iscPages.mainPages, (item) => item.Route !== 'container.main.profile');
            this.profilePage = IscPagesService.profilePage;
            this.incentivePage = this.iscPages.incentives[0];
            this.footerPages = this.iscPages.footerPages.filter((page) => {
                if (page.PageType === this.pageTypeConstants.myCookieSettings && !this.showMyCookieSettings) {
                    return false;
                }
                return true;
            });
        });
    }
    switchInterface() {
        let activityUrl = '';
        if (this.squareFactory.IsCompleted) {
            activityUrl = '/activities/';
        }
        const squareAdminUrl = this.projectService.getSquareAdminUrl() + activityUrl;
        if (this.userService.hasModeratorRole()) {
            this.authService.goToAdminAndLogIn();
        }
        else {
            window.location.href = squareAdminUrl;
        }
    }
}
MoreController.$inject = ['iscPages', '_', 'participantFactory', 'squareFactory', 'authService', 'projectService', 'userService',
    '__env', 'serverConstants'];
