<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { useConstantsStore } from '@/store/constants-store';
import { usePagesStore } from '@/store/pages-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { useAuthenticationStore } from '@/store/authentication-store';
import {
  getScreenerSurveyLink,
  getTestSurveyLink,
  getResearchSurveyLink,
  getResearchSurveyLinkForActivity,
} from '@api/services/command/default/ActivityService';
import { BackgroundVariableValuePair } from '@api/models/query';
import { appendMobileAppParam } from '@/common/utils/media';
import { setCache } from '@ui/common/utils/cache';
import { NIL } from 'uuid';
import { IscWrapperPage } from '@ui/components';

const route = useRoute();
const router = useRouter();
const auth = useAuthenticationStore();
const getLabelValue = useConstantsStore().getLabelValue;

const activityGuid = route.params.activityGuid || route.query.activityGuid;

const activityStartedKey = 'activityStartedKey';

const redirectToLink = (link: string) => {
  const redirectUrl = appendMobileAppParam(link);
  if (link.indexOf('http') === 0) {
    // external url
    window.location.href = redirectUrl;
  } else {
    // local url
    router.push(redirectUrl);
  }
};

const redirectToIntakeSurvey = () => {
  const partnerId = route.query.partnerId || route.query.partnerid;
  const backgroundVarRegex = new RegExp(`bvar${partnerId}_\\d+`, 'i');
  const backgroundVariables: BackgroundVariableValuePair[] = [];
  Object.entries(route.query)
    .filter(([key]) => key.match(backgroundVarRegex))
    .forEach(([key, value]) => {
      backgroundVariables.push({
        variable: key,
        value: value as string,
      });
    });

  const request = {
    activityGuid: activityGuid as string,
    partnerId: parseInt(partnerId as string),
    bId: route.query.bid as string,
    subsource: route.query.subsource as string,
    country: route.query.qtc as string,
    backgroundVariables,
  };

  return getScreenerSurveyLink(request)
    .then((response) => redirectToLink(response.link as string))
    .catch(async (e) => {
      handleInvalidActivity(e);
    });
};

const redirectToTestSurvey = () => getTestSurveyLink(activityGuid as string)
  .then((response) => redirectToLink(response.link as string));

const redirectToSurvey = () => {
  if (route.params.sampleGuid && route.params.sampleGuid !== NIL) {
    const sampleGuid = route.params.sampleGuid as string;
    setCache({ key: activityStartedKey, value: sampleGuid });
    return getResearchSurveyLink(sampleGuid, false, parseInt((route.params.channel) as string, 10))
      .then((response) => redirectToLink(response.link as string));
  }
  return redirectToSurveyUsingActivityGuid();
};

const redirectToSurveyUsingActivityGuid = () =>
  getResearchSurveyLinkForActivity(activityGuid as string, false, parseInt((route.params.channel) as string, 10))
    .then((response) => {
      setCache({ key: activityStartedKey, value: response.sampleGuid });
      redirectToLink(response.link as string);
    });

const handleInvalidActivity = (response: { data: { reason: string }}) => {
  const isAuthorized = auth.isAuthorized;

  const params: {
    landingTitle: string;
    landingMessage?: string;
    redirectState: string;
    timeout?: number;
    redirectParams?: string;
  } = {
    landingTitle: 'LabelLandingPageTitleActivities',
    redirectState: isAuthorized ? RouteNames.Home : RouteNames.Login,
    timeout: 5000,
  };

  const to = {
    name: RouteNames.LandingPage,
    query: params,
  };

  switch (response.data.reason) {
    case 'activity_not_live':
      to.query.landingMessage = isAuthorized ? 'LabelLandingPageMessageActivityUnavailable' : 'LabelLandingPageMessageDefault';
      break;
    case 'activity_participant_not_active':
      to.query.landingMessage = 'LabelLandingPageMessageParticipantNonActive';
      to.query.timeout = -1;
      break;
    case 'activity_finished':
      to.query.landingMessage = isAuthorized ? 'LabelLandingPageMessageActivityFinished' : 'LabelLandingPageMessageDefault';
      break;
    case 'participant_not_targeted_for_activity':
      to.query.landingMessage = isAuthorized ? 'LabelLandingPageMessageParticipantNotTargetedForActivity' : 'LabelLandingPageMessageDefault';
      break;
    case 'activity_not_yet_live':
      to.query.landingMessage = isAuthorized ? 'LabelLandingPageMessageActivityNotYetLive' : 'LabelLandingPageMessageDefault';
      break;
    case 'wrong_participant':
      to.name = RouteNames.Login;
      to.query.redirectState = route.name as string;
      to.query.redirectParams= JSON.stringify({
        sampleGuid: route.params.sampleGuid,
        channel: route.params.channel,
        activityGuid,
      });
      break;
    case 'activity_locked':
      to.query.landingTitle = 'LabelLandingPageTitleActivityLocked';
      to.query.landingMessage = 'LabelLandingPageMessageActivityLocked';
      to.query.redirectState = RouteNames.ChallengesTodo as string;
      to.query.redirectParams = JSON.stringify({
        pageGuid: usePagesStore().mainPages.find((p) => p.route === RouteNames.Challenges)?.guid,
      });
      break;
  }
  router.push(to);
};

const redirectPromise = route.name &&
{
  [RouteNames.ActivityResearch.toString()]: redirectToSurveyUsingActivityGuid,
  [RouteNames.ActivityTest.toString()]: redirectToTestSurvey,
  [RouteNames.ActivityIntake.toString()]: redirectToIntakeSurvey,
}[(route.name as string).replace('vue','')] || redirectToSurvey; // Todo remove replace after switch to vue

redirectPromise().catch((error) => {
  handleInvalidActivity(error);
});

</script>

<template>
  <isc-wrapper-page
    :top-margin="false"
    :x-padding="false"
    :y-padding="false"
    class="q-pa-xl"
  >
    <div
      class="text-insites-blue text-center q-mt-xl"
      :class="$q.screen.gt.xs ? 'text-h1 q-mb-xl q-mx-xl q-px-xl' : 'text-h4 q-mb-lg'"
    >
      {{ getLabelValue('ActivityPleaseWaitRedirectTitle') }}
    </div>
    <div class="text-subtitle1 text-weight-medium text-center q-pb-xl">
      {{ getLabelValue('ActivityPleaseWaitRedirectMessage') }}
    </div>
  </isc-wrapper-page>
</template>
