import { UiModule } from './../../../../index';
var EmojiService = (function () {
    function EmojiService(emojis, emojione, _, envInfoService) {
        var _this = this;
        this.emojis = emojis;
        this.emojione = emojione;
        this._ = _;
        this.envInfoService = envInfoService;
        this.getUniqueEmojis = this._.memoize(function () {
            var emojisWithTextAndImage = _this._.filter(_this.emojis, function (emoji) {
                return emoji.text !== null && emoji.has_img_emojione;
            });
            return _this._.uniqBy(emojisWithTextAndImage, function (emoji) { return emoji.text; });
        });
    }
    EmojiService.prototype.getEmojiHtml = function (emoji) {
        return '<img class="emoji ' + emoji.short_name + '" src="/images/emojis/' + emoji.image
            + '" style="height: 20px;" alt="' + emoji.name + '"></img > ';
    };
    EmojiService.prototype.imgToShortcode = function (html) {
        var _this = this;
        var output = html;
        var el = document.createElement('div');
        el.innerHTML = html;
        var images = el.getElementsByTagName('img');
        this._.forEach(images, function (image) {
            var shortcode = image.classList[1];
            var emoji = _this._.find(_this.emojis, { short_name: shortcode });
            if (emoji) {
                output = output.replace(image.outerHTML, ':' + emoji.short_name + ':');
            }
        });
        return output;
    };
    EmojiService.prototype.htmlToShort = function (html) {
        if (!html) {
            return '';
        }
        html = this.imgToShortcode(html);
        html = this.emojione.toShort(html);
        return html;
    };
    EmojiService.$inject = ['emojis', 'emojione', '_', 'iscEnvInfoService'];
    return EmojiService;
}());
export { EmojiService };
UiModule
    .service('emojiservice', EmojiService);
