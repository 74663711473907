import { IscHashtagMenuItemController } from './hashtag-menu-item.controller';
export var IscHashtagMenuItemComponent = {
    template: "\n    <li ng-class=\"{'active': vm.active()}\" ng-mouseenter=\"vm.mouseEnter()\" ng-click=\"vm.click()\">\n      <div ng-bind-html=\"vm.item.text | iscHashtagHighlight:vm.typedTerm:'menu-highlighted'\"></div>\n    </li>\n  ",
    controllerAs: 'vm',
    controller: IscHashtagMenuItemController,
    bindings: {
        item: '<',
        isActive: '&',
        typedTerm: '<',
        activate: '&',
        selectItem: '&',
    },
    require: {
        iscHashtagMenu: '^iscHashtagMenu',
    },
};
