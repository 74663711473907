import { __awaiter } from "tslib";
export class NewsDataService {
    constructor(httpService) {
        this.httpService = httpService;
    }
    getNewsPageCards(pageGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.get({
                url: '/NewsService/ListSquareNews',
                params: {
                    pageGuid,
                },
            });
            return response.data;
        });
    }
    likeNews(cardGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/NewsService/LikeNews',
                data: {
                    CommunicationChannelGuid: cardGuid,
                },
            });
            return response.data;
        });
    }
    unlikeNews(cardGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.httpService.post({
                url: '/NewsService/UnlikeNews',
                data: {
                    CommunicationChannelGuid: cardGuid,
                },
            });
            return response.data;
        });
    }
}
NewsDataService.$inject = ['httpService'];
