import { __awaiter } from "tslib";
export class SplashCheckService {
    constructor($state, participantService) {
        this.$state = $state;
        this.participantService = participantService;
    }
    resolveSplashPageFlow(redirectToUrlAfterAgreed) {
        return __awaiter(this, void 0, void 0, function* () {
            const shouldRedirect = yield this.participantService.shouldRedirectToSplashPage();
            if (shouldRedirect) {
                yield this.$state.go('container.splashPage', { redirectUrl: redirectToUrlAfterAgreed }, { notify: false });
                return false;
            }
            return true;
        });
    }
}
SplashCheckService.$inject = ['$state', 'participantService'];
