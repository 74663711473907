import { PageConsumer, usePagesStore } from '@/store/pages-store';
import { computed, ref, Ref, ComputedRef } from 'vue';

const listPageOverflow = ref<PageConsumer[]>([]);

export const usePriorityNavigationHelper = (): { listPageOverflow: Ref<PageConsumer[]>; mainPagesToShow: ComputedRef<PageConsumer[]> } => {
  const pagesStore = usePagesStore();
  const mainPagesToShow = computed(() => {
    const overflowGuids = listPageOverflow.value.map((po) => po.guid);
    return pagesStore.mainPages.filter((p) => !overflowGuids.includes(p.guid));
  });

  return { listPageOverflow, mainPagesToShow };
};
