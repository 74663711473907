export class SquareNotActiveController {
    constructor(serverConstants) {
        this.serverConstants = serverConstants;
        this.message = '';
    }
    $onInit() {
        if (this.squareStatus === this.serverConstants.squareStatusConstants.scheduled) {
            this.message = 'This square is not active yet';
        }
        else if (this.squareStatus === this.serverConstants.squareStatusConstants.closed) {
            this.message = 'This square is currently closed';
        }
        else if (this.squareStatus === this.serverConstants.squareStatusConstants.archived) {
            this.message = 'This square is currently archived';
        }
    }
}
SquareNotActiveController.$inject = ['serverConstants'];
