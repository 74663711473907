import { __awaiter } from "tslib";
import * as _ from 'lodash';
export class NewsService {
    constructor($stateParams, newsDataService, $rootScope, $state, iscPages, serverConstants, constantsFactory, $q) {
        this.$stateParams = $stateParams;
        this.newsDataService = newsDataService;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.iscPages = iscPages;
        this.serverConstants = serverConstants;
        this.constantsFactory = constantsFactory;
        this.$q = $q;
        this.notifications = this.$rootScope.$new(true);
        this.on = this.notifications.$on.bind(this.notifications);
    }
    getNewsPageCards() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.newsPageCards) {
                return this.$q.resolve(this.newsPageCards);
            }
            if (this.promise) {
                this.newsPageCards = yield this.promise;
                return this.newsPageCards;
            }
            this.promise = this.newsDataService.getNewsPageCards(this.$stateParams.pageGuid);
            this.newsPageCards = (yield this.promise) || [];
            yield this.applyPagesInfoToCards();
            return this.newsPageCards;
        });
    }
    getNewsCardDetail(cardGuid) {
        return __awaiter(this, void 0, void 0, function* () {
            const newsPageCards = yield this.getNewsPageCards();
            return newsPageCards.filter((c) => c.Guid === cardGuid)[0];
        });
    }
    getPrevCard(cardGuid) {
        const index = this.getIndex(cardGuid);
        if (index - 1 >= 0) {
            return this.newsPageCards[index - 1];
        }
        return undefined;
    }
    getNextCard(cardGuid) {
        const index = this.getIndex(cardGuid);
        if (index + 1 < this.newsPageCards.length) {
            return this.newsPageCards[index + 1];
        }
        return undefined;
    }
    likeClick(card) {
        return __awaiter(this, void 0, void 0, function* () {
            let promise;
            if (card.IsLiked) {
                promise = this.newsDataService.unlikeNews(card.Guid);
            }
            else {
                promise = this.newsDataService.likeNews(card.Guid);
            }
            yield promise;
            if (card.IsLiked) {
                card.LikesCount--;
            }
            else {
                card.LikesCount++;
            }
            card.IsLiked = !card.IsLiked;
            this.notifications.$emit('likeClick', card);
        });
    }
    clear() {
        this.newsPageCards = undefined;
        this.promise = undefined;
    }
    getIndex(cardGuid) {
        return _.findIndex(this.newsPageCards, (c) => c.Guid === cardGuid);
    }
    applyPagesInfoToCards() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.iscPages.init();
            const policyRewardLabel = yield this.constantsFactory.getLabelValue('jAryXn6meLCustomFooter4');
            const savingUpPolicyRewardLabel = yield this.constantsFactory.getLabelValue('jAryXn6meLCustomFooter2');
            for (const card of this.newsPageCards) {
                card.Message = this.replaceMessageParameters(card.Message, policyRewardLabel, savingUpPolicyRewardLabel);
            }
        });
    }
    replaceMessageParameters(message, policyRewardLabel, savingUpPolicyRewardLabel) {
        const activitiesPage = _.find(this.iscPages.mainPages, (item) => item.PageType === this.serverConstants.pageTypeConstants.activities);
        const loungePage = _.find(this.iscPages.mainPages, (item) => item.PageType === this.serverConstants.pageTypeConstants.forum);
        const faqPage = _.find(this.iscPages.allPages, (item) => item.PageType === this.serverConstants.pageTypeConstants.faq);
        const rewardsPolicyPage = _.find(this.iscPages.allPages, (item) => (item.PageType === this.serverConstants.pageTypeConstants.rewardPolicy
            || item.PageType === this.serverConstants.pageTypeConstants.savingUpRewardPolicy)
            || item.PageType === this.serverConstants.pageTypeConstants.footerCustom
                && (item.Name === policyRewardLabel || item.Name === savingUpPolicyRewardLabel));
        const contactPage = _.find(this.iscPages.allPages, (item) => item.PageType === this.serverConstants.pageTypeConstants.contact);
        const profileUrl = this.$state.href('container.main.profile', {}, { absolute: true });
        message = message.replaceAll('{profilelink}', profileUrl);
        if (activitiesPage) {
            const activitiesTodoUrl = this.$state.href('container.main.challenges.todo', { pageGuid: activitiesPage.Guid }, { absolute: true });
            message = message.replaceAll('{activitiespage}', activitiesTodoUrl);
        }
        if (loungePage) {
            const loungeUrl = this.$state.href('container.main.forum', { pageGuid: loungePage.Guid }, { absolute: true });
            message = message.replaceAll('{lounge}', loungeUrl);
        }
        const myRewardsUrl = this.$state.href('container.main.incentives.myincentive', {}, { absolute: true });
        message = message.replaceAll('{myrewardspage}', myRewardsUrl);
        if (faqPage) {
            const faqUrl = this.$state.href('container.main.helpcenter.faq', { helpcenterPage: faqPage.Guid }, { absolute: true });
            message = message.replaceAll('{faqpage}', faqUrl);
        }
        if (contactPage) {
            const contactUrl = this.$state.href('container.main.helpcenter.contact', { helpcenterPage: contactPage.Guid }, { absolute: true });
            message = message.replaceAll('{supportpage}', contactUrl);
        }
        message = message.replaceAll('{googleplaystore}', 'https://play.google.com/store/apps/details?id=com.squareapp&hl=en');
        message = message.replaceAll('{appstore}', 'https://itunes.apple.com/app/insites-square/id1232226979?mt=8');
        if (rewardsPolicyPage) {
            const rewardsPolicyUrl = this.$state.href('container.main.footer', { pageGuid: rewardsPolicyPage ? rewardsPolicyPage.Guid : null }, { absolute: true });
            message = message.replaceAll('{rewardspolicy}', rewardsPolicyUrl);
        }
        return message;
    }
}
NewsService.$inject = ['$stateParams', 'newsDataService', '$rootScope', '$state', 'iscPages', 'serverConstants', 'constantsfactory',
    '$q'];
