import { IscUserNotificationController } from './notifications.controller';
var notificationTemplate = "<div ng-class=\"{'mobile': vm.isMobile}\" class=\"notifications\">\n  <md-menu md-position-mode=\"target-right target\" ng-attr-md-offset=\"{{vm.menuOffset}}\">\n    <a ng-disabled=\"vm.notifications.length === 0\" class=\"bell\" md-menu-origin ng-click=\"$mdMenu.open($event); vm.notificationTrack();\"\n    ng-keydown=\"$event.keyCode === 13 && $mdMenu.open($event)\" tabindex=\"0\"\n      md-no-ink>\n      <md-icon class=\"menu-icon fa fa-bell\" aria-label=\"\"></md-icon>\n      <notification-counter ng-class=\"{'hidden-counter': vm.notifications.length === 0}\">\n        {{vm.notifications.length}}\n      </notification-counter>\n    </a>\n    <md-menu-content ng-class=\"{'mobile': vm.isMobile}\" class=\"notification-menu-content\" width=\"5\">\n      <md-menu-item  ng-repeat=\"notification in vm.notifications\" class=\"notif\">\n        <md-button ng-click=\"vm.notificationClick({notification : notification})\" ng-class=\"{'last-element': $last && !vm.clearAll}\">\n          <span class=\"notification-label\" ng-bind-html=\"notification.Label\"></span>\n          <md-icon ng-if=\"vm.clearIndividualNotification\" class=\"fa fa-xmark close\" aria-label=\"\"\n            ng-click=\"vm.notificationMarkAsReadClick({notification : notification, event: $event})\"></md-icon>\n        </md-button>\n      </md-menu-item>\n      <div ng-if=\"vm.clearAll\" class=\"clear-container\" ng-click=\"vm.clearAllNotifications()\">\n        <span>Clear all notifications</span>\n      </div>\n    </md-menu-content>\n  </md-menu>\n</div>";
export var IscUserNotificationComponent = {
    controller: IscUserNotificationController,
    template: notificationTemplate,
    controllerAs: 'vm',
    bindings: {
        isMobile: '<',
        borderedIcon: '<',
        notificationClick: '&',
        notificationMarkAsReadClick: '&?',
        clearIndividualNotification: '=',
        notificationTrack: '&?',
        notifications: '=',
        menuOffset: '<',
        notificationTypes: '<',
        clearAll: '=',
        clearAllNotifications: '&?',
    },
};
