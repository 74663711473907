import { __awaiter, __generator } from "tslib";
import { AddWebcamMediaDialogController } from './add-webcam-media-dialog.controller';
import * as _ from 'lodash';
var addWebcamMediaTemplate = "<isc-modal-dialog close-callback=\"vm.cancel()\" show-close-button=\"true\">\n  <isc-modal-close>\n    <isc-button class=\"icon-button\" ng-click=\"vm.cancel()\" aria-label=\"Close\">\n      <isc-button-icon>\n        <md-icon md-svg-src=\"/images/button-icons/Remove.svg\" aria-label=\"\"></md-icon>\n      </isc-button-icon>\n    </isc-button>\n  </isc-modal-close>\n  <isc-modal-content>\n    <isc-media-recorder media-capture=\"vm.videoData\" submit-capture=\"vm.submitCapture()\" cancel=\"vm.cancel()\"\n      show-image=\"vm.showImage\" show-video=\"vm.showVideo\">\n    </isc-media-recorder>\n  </isc-modal-content>\n</isc-modal-dialog>";
var IscUploadConversationController = (function () {
    function IscUploadConversationController(iscSocialStimuli, iscLabelService, iscConfigurationService, $q, $mdDialog, iscStimuliTypeService, serverConstants) {
        this.iscSocialStimuli = iscSocialStimuli;
        this.iscLabelService = iscLabelService;
        this.iscConfigurationService = iscConfigurationService;
        this.$q = $q;
        this.$mdDialog = $mdDialog;
        this.iscStimuliTypeService = iscStimuliTypeService;
        this.serverConstants = serverConstants;
        this.showStimuliWarning = false;
        this.invalidFiles = [];
        this.isMobileBrowser = false;
        this.fileConfiguration = [];
        this.replyContentWhileSavingDisabled = false;
    }
    IscUploadConversationController.prototype.$onInit = function () {
        var _this = this;
        this.stimuliTypes = this.iscStimuliTypeService.getTypes();
        this.isMobileBrowser = this.checkIfMobile();
        this.hasMediaRecorderApi = navigator.mediaDevices && navigator.mediaDevices.getUserMedia && !!window.MediaRecorder;
        this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
        this.iscConfigurationService.getFileConfiguration()
            .then(function (response) {
            _this.fileConfiguration = response;
            _this.initFileMaxSizes();
        });
    };
    IscUploadConversationController.prototype.initFileMaxSizes = function () {
        var _this = this;
        this.imageStimuliMaxSizeBytes = _.find(this.fileConfiguration, function (config) { return config.FileType === _this.fileTypeConstants.image; }).MaxFileSizeMb * Math.pow(1024, 2);
        this.videoStimuliMaxSizeBytes = _.find(this.fileConfiguration, function (config) { return config.FileType === _this.fileTypeConstants.video; }).MaxFileSizeMb * Math.pow(1024, 2);
        this.attachmentMaxSizeBytes = _.find(this.fileConfiguration, function (config) { return config.FileType === _this.fileTypeConstants.attachment; }).MaxFileSizeMb * Math.pow(1024, 2);
    };
    IscUploadConversationController.prototype.parseNewStimulus = function (stimulus) {
        var _this = this;
        if (stimulus.file) {
            var savedStimuli = this.stimuli.filter(function (s) { return s.status === _this.serverConstants.conversationStimuliStatusConstants.saved; });
            stimulus.id = (this.newStimulusFiles.length + (this.isQuestion ? savedStimuli.length || 0 : 0)).toString();
            this.newStimulusFiles.push(stimulus.file);
        }
        else {
            stimulus.id = stimulus.value;
        }
        this.stimuli.push(stimulus);
    };
    IscUploadConversationController.prototype.addStimulus = function (stimulus) {
        this.parseNewStimulus(stimulus);
        if (this.onFileAdded) {
            var data = {
                uploadedItems: [stimulus],
                isStimulus: true,
            };
            this.onFileAdded({ data: data });
        }
    };
    IscUploadConversationController.prototype.addStimuli = function (stimuli) {
        var _this = this;
        if (stimuli && stimuli.length > 0) {
            _.forEach(stimuli, function (stimulus) {
                _this.parseNewStimulus(stimulus);
            });
            if (this.onFileAdded) {
                var data = {
                    uploadedItems: stimuli,
                    isStimulus: true,
                };
                this.onFileAdded({ data: data });
            }
        }
    };
    IscUploadConversationController.prototype.parseNewAttachment = function (attachment) {
        if (attachment.file) {
            attachment.id = this.newAttachmentFiles.length.toString();
            this.newAttachmentFiles.push(attachment.file);
        }
        this.attachments.push(attachment);
    };
    IscUploadConversationController.prototype.addAttachment = function (attachment) {
        if (attachment === undefined
            || !attachment
            || this.attachmentsCount >= this.config.maxAttachments
            || !this.config.showAttachment) {
            return;
        }
        this.parseNewAttachment(attachment);
        if (this.onFileAdded) {
            var data = {
                uploadedItems: [attachment],
                isStimulus: false,
            };
            this.onFileAdded({ data: data });
        }
    };
    IscUploadConversationController.prototype.addAttachments = function (attachments) {
        var _this = this;
        if (attachments && attachments.length) {
            _.forEach(attachments, function (attachment) {
                _this.parseNewAttachment(attachment);
            });
            if (this.onFileAdded) {
                var data = {
                    uploadedItems: attachments,
                    isStimulus: false,
                };
                this.onFileAdded({ data: data });
            }
        }
    };
    IscUploadConversationController.prototype.filesChanged = function ($files, $newFiles, $invalidFiles) {
        var validStimuli = [];
        var newFilesCounter = 0;
        for (var _i = 0, $newFiles_1 = $newFiles; _i < $newFiles_1.length; _i++) {
            var file = $newFiles_1[_i];
            newFilesCounter++;
            if (this.validateUploadingStimuli(file, $invalidFiles, newFilesCounter) === true) {
                validStimuli.push(this.createStimulus(file));
            }
        }
        if (validStimuli.length > 0) {
            this.addStimuli(validStimuli);
        }
        $invalidFiles.splice(0);
        $files.splice(0);
    };
    IscUploadConversationController.prototype.createStimulus = function (file) {
        var stimulus = {
            file: file,
            type: this.getStimulusType(file),
            temporaryGuid: this.createUniqueTemporaryGuid(),
            isPreview: false,
            status: this.serverConstants.conversationStimuliStatusConstants.new,
        };
        return stimulus;
    };
    IscUploadConversationController.prototype.createUniqueTemporaryGuid = function () {
        function _p8(s) {
            var p = (Math.random().toString(16) + '000000000').substr(2, 8);
            return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    };
    IscUploadConversationController.prototype.attachmentsChanged = function ($attach, $newAttach, $invalidAttachments) {
        this.invalidAttachments = [];
        var validAttachments = [];
        var newAttachmentsCounter = 0;
        for (var _i = 0, $newAttach_1 = $newAttach; _i < $newAttach_1.length; _i++) {
            var file = $newAttach_1[_i];
            newAttachmentsCounter++;
            if (this.validateUploadingAttachment(file, $invalidAttachments, newAttachmentsCounter) === true) {
                validAttachments.push(this.createAttachment(file));
            }
        }
        this.addAttachments(validAttachments);
        $invalidAttachments.splice(0);
        $attach.splice(0);
    };
    IscUploadConversationController.prototype.createAttachment = function (file) {
        var newAttachment = {
            file: file,
            type: this.serverConstants.stimulusTypeConstants.attachment,
            isPreview: false,
            name: file.name,
            status: this.serverConstants.conversationStimuliStatusConstants.new,
        };
        return newAttachment;
    };
    IscUploadConversationController.prototype.addSocialStimulus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var labels, link, parsedUrl, type, _a, stimulus;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.stimuliCount >= this.config.maxStimuli || !this.config.showVideoLink) {
                            return [2];
                        }
                        return [4, this.$q.all([
                                this.iscLabelService.getLabelValue('LabelLinkPrompt'),
                                this.iscLabelService.getLabelValue('LabelLinkAlert'),
                            ])];
                    case 1:
                        labels = _b.sent();
                        link = prompt(labels[0]);
                        if (!link) {
                            return [2];
                        }
                        parsedUrl = this.iscSocialStimuli.parseUrl(link);
                        if (!!parsedUrl) return [3, 2];
                        alert(labels[1]);
                        return [3, 6];
                    case 2:
                        type = 'vimeo' === parsedUrl.type ? this.stimuliTypes.vimeo : this.stimuliTypes.youtube;
                        if (!(type === this.stimuliTypes.vimeo)) return [3, 4];
                        _a = parsedUrl;
                        return [4, this.iscSocialStimuli.getVimeoThumbnail(parsedUrl.value)];
                    case 3:
                        _a.thumbnailUrl = _b.sent();
                        return [3, 5];
                    case 4:
                        parsedUrl.thumbnailUrl = this.iscSocialStimuli.getYoutubeThumbnail(parsedUrl.value);
                        _b.label = 5;
                    case 5:
                        stimulus = {
                            file: null,
                            type: type,
                            isPreview: false,
                            thumbnailUrl: parsedUrl.thumbnailUrl,
                            url: link,
                            value: parsedUrl.value,
                            status: this.serverConstants.conversationStimuliStatusConstants.new,
                        };
                        this.addStimulus(stimulus);
                        _b.label = 6;
                    case 6: return [2];
                }
            });
        });
    };
    IscUploadConversationController.prototype.addWebcamMedia = function () {
        return __awaiter(this, void 0, void 0, function () {
            var message, okButton;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.config.showVideoUpload && !this.config.showImageUpload) {
                            return [2];
                        }
                        if (!!this.hasMediaRecorderApi) return [3, 3];
                        return [4, this.iscLabelService.getLabelValue('LabelMediaRecorderNotSupportedMessage')];
                    case 1:
                        message = _a.sent();
                        return [4, this.iscLabelService.getLabelValue('LabelMediaRecorderNotSupportedOkButton')];
                    case 2:
                        okButton = _a.sent();
                        this.$mdDialog.show(this.$mdDialog.iscAlert()
                            .text(message)
                            .ok(okButton));
                        return [3, 4];
                    case 3:
                        this.$mdDialog.show({
                            controller: AddWebcamMediaDialogController,
                            controllerAs: 'vm',
                            template: addWebcamMediaTemplate,
                            locals: { showVideo: this.config.showVideoUpload, showImage: this.config.showImageUpload },
                            bindToController: true,
                        }).then(function (capture) {
                            if (_this.validateUploadMedia(capture)) {
                                var stimulus = {
                                    type: _this.getStimulusType(capture),
                                    file: capture,
                                    isPreview: false,
                                    status: _this.serverConstants.conversationStimuliStatusConstants.new,
                                    temporaryGuid: _this.createUniqueTemporaryGuid(),
                                };
                                _this.addStimulus(stimulus);
                            }
                        });
                        _a.label = 4;
                    case 4: return [2];
                }
            });
        });
    };
    IscUploadConversationController.prototype.checkIfMobile = function () {
        var check = false;
        var userAgent = navigator.userAgent || navigator.vendor;
        var detectMobileRegexPart1 = new RegExp("(android|bbd+|meego).+mobile|avantgo|bada/|\n    blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|\n    lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|\n    p(ixi|re)/|plucker|pocket|psp|series(4|6)0|symbian|treo|up.(browser|link)|vodafone|\n    wap|windows ce|xda|xiino", 'i');
        var detectMobileRegexPart2 = new RegExp("1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|\n    abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|\n    attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55/|capi|\n    ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|\n    ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|\n    gene|gf-5|g-mo|go(.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|\n    ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|/)|ibro|idea|ig01|ikom|im1k|\n    inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|\n    lg( g|/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|\n    mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|\n    n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|\n    oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|\n    prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55/|\n    sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|\n    sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|\n    ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(.b|g1|si)|utst|\n    v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)\n    |w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-", 'i');
        if (detectMobileRegexPart1.test(userAgent) ||
            detectMobileRegexPart2.test(userAgent.substr(0, 4))) {
            check = true;
        }
        return check;
    };
    IscUploadConversationController.prototype.validateUploadMedia = function (media) {
        var validationResult = false;
        var _a = this.iscConfigurationService.getAcceptedMimeTypesAndExtensions(this.fileConfiguration, [this.fileTypeConstants.image, this.fileTypeConstants.video]), acceptedMimeTypes = _a.mimeTypes, acceptedExtensions = _a.extensions;
        if (media) {
            var invalidMedia = media;
            if (this.stimuliCount + 1 > this.config.maxStimuli) {
                invalidMedia.$errorMessages = invalidMedia.$errorMessages || {};
                invalidMedia.$errorMessages.maxFiles = true;
            }
            if (media.size
                && media.size > this.videoStimuliMaxSizeBytes) {
                invalidMedia.$errorMessages = invalidMedia.$errorMessages || {};
                invalidMedia.$errorMessages.maxSize = true;
            }
            if ((media.name && acceptedExtensions.indexOf(this.getExtensionFromFilename(media.name)) < 0)
                || (media.type && acceptedMimeTypes.indexOf(media.type) < 0)) {
                invalidMedia.$errorMessages = invalidMedia.$errorMessages || {};
                invalidMedia.$errorMessages.pattern = true;
            }
            if (!invalidMedia.$errorMessages) {
                validationResult = true;
            }
            else {
                this.invalidFiles = this.invalidFiles || [];
                this.invalidFiles.push(invalidMedia);
            }
        }
        return validationResult;
    };
    IscUploadConversationController.prototype.validateUploadingAttachment = function (file, invalidAttachments, newAttachmentsCounter) {
        var validationResult = false;
        if (this.attachmentsCount >= this.config.maxAttachments ||
            !this.config.showAttachment) {
            return validationResult;
        }
        if (file) {
            if (this.attachmentsCount + newAttachmentsCounter > this.config.maxAttachments) {
                file.$errorMessages = file.$errorMessages || {};
                file.$errorMessages.maxFiles = true;
                file.maxFiles = this.config.maxAttachments;
                this.invalidAttachments.push(file);
            }
            if (file.size > this.attachmentMaxSizeBytes) {
                file.$errorMessages = file.$errorMessages || {};
                file.$errorMessages.maxSize = true;
                file.maxSize = Math.floor(this.attachmentMaxSizeBytes / Math.pow(1024, 2));
                this.invalidAttachments.push(file);
            }
        }
        if (!file.$errorMessages) {
            validationResult = true;
        }
        else {
            if (invalidAttachments.indexOf(file) >= 0) {
                this.invalidAttachments.push(file);
            }
        }
        return validationResult;
    };
    IscUploadConversationController.prototype.validateUploadingStimuli = function (file, invalidFiles, newFileCounter) {
        var validationResult = false;
        if (this.stimuliCount >= this.config.maxStimuli || (!this.config.showImageUpload && !this.config.showVideoUpload)) {
            return validationResult;
        }
        if (file) {
            if (this.stimuliCount + newFileCounter > this.config.maxStimuli) {
                file.$errorMessages = file.$errorMessages || {};
                file.$errorMessages.maxFiles = true;
                file.maxFiles = this.config.maxStimuli;
                this.invalidFiles.push(file);
            }
            if ((file.type.indexOf('image') === 0 && file.size > this.imageStimuliMaxSizeBytes) ||
                (file.type.indexOf('video') === 0 && file.size > this.videoStimuliMaxSizeBytes)) {
                file.$errorMessages = file.$errorMessages || {};
                file.$errorMessages.maxSize = true;
                file.maxSize = Math.floor((file.type.indexOf('image') === 0 ? this.imageStimuliMaxSizeBytes : this.videoStimuliMaxSizeBytes) / Math.pow(1024, 2));
                this.invalidFiles.push(file);
            }
            if (!file.$errorMessages) {
                validationResult = true;
            }
            else {
                if (invalidFiles.indexOf(file) >= 0) {
                    this.invalidFiles.push(file);
                }
            }
        }
        return validationResult;
    };
    IscUploadConversationController.prototype.getStimulusType = function (file) {
        if (file.type.indexOf('image') === 0) {
            return this.stimuliTypes.image;
        }
        else if (file.type.indexOf('video') === 0) {
            return this.stimuliTypes.video;
        }
        else if (file.type === '') {
            return this.stimuliTypes.video;
        }
        else {
            return -1;
        }
    };
    IscUploadConversationController.prototype.getExtensionFromFilename = function (filename) {
        var result = '';
        if (filename) {
            result = filename.split('.').pop();
        }
        return result;
    };
    IscUploadConversationController.prototype.showWebcam = function () {
        return !this.isMobileBrowser && (this.config.showImageUpload || this.config.showVideoUpload);
    };
    IscUploadConversationController.prototype.showUploadStimuli = function () {
        return this.config.showImageUpload || this.config.showVideoUpload;
    };
    IscUploadConversationController.prototype.parseFileTypesArrayForCurrentConfig = function (initalFileTypes) {
        var applyConfig = function (fileTypes, configValue, fileType) {
            if (configValue === false) {
                var imageIndex = fileTypes.indexOf(fileType);
                if (imageIndex !== -1) {
                    initalFileTypes.splice(imageIndex, 1);
                }
            }
        };
        applyConfig(initalFileTypes, this.config.showImageUpload, this.fileTypeConstants.image);
        applyConfig(initalFileTypes, this.config.showVideoUpload, this.fileTypeConstants.video);
        applyConfig(initalFileTypes, this.config.showAttachment, this.fileTypeConstants.attachment);
    };
    IscUploadConversationController.prototype.getPatternForFileType = function (fileTypes) {
        this.parseFileTypesArrayForCurrentConfig(fileTypes);
        var patterns = this.iscConfigurationService.getAcceptedMimeTypesAndExtensions(this.fileConfiguration, fileTypes).patterns;
        return "'".concat(patterns.toString(), "'");
    };
    IscUploadConversationController.prototype.getAcceptedMimeTypesForFileType = function (fileTypes) {
        this.parseFileTypesArrayForCurrentConfig(fileTypes);
        var mimeTypes = this.iscConfigurationService.getAcceptedMimeTypesAndExtensions(this.fileConfiguration, fileTypes).mimeTypes;
        return mimeTypes.toString();
    };
    IscUploadConversationController.prototype.getWebcamLabel = function () {
        if (this.config.showImageUpload && this.config.showVideoUpload) {
            return '(LabelForumConversationEditorAddWebcamMedia)';
        }
        if (this.config.showImageUpload) {
            return '(LabelForumConversationEditorAddWebcamMediaImageOnly)';
        }
        if (this.config.showVideoUpload) {
            return '(LabelForumConversationEditorAddWebcamMediaVideoOnly)';
        }
    };
    IscUploadConversationController.prototype.getStimuliUploadLabel = function () {
        if (this.config.showImageUpload && this.config.showVideoUpload) {
            return '(LabelForumConversationEditorUploadStimuli)';
        }
        if (this.config.showImageUpload) {
            return '(LabelForumConversationEditorUploadStimuliImageOnly)';
        }
        if (this.config.showVideoUpload) {
            return '(LabelForumConversationEditorUploadStimuliVideoOnly)';
        }
    };
    IscUploadConversationController.prototype.getUploadingLimitsInfoTextLabel = function () {
        if (this.config.showImageUpload && this.config.showVideoUpload &&
            this.config.showVideoLink && this.config.showAttachment) {
            return '(LabelForumConversationEditorUploadingLimitsInfoText)';
        }
        if (this.config.showImageUpload && this.config.showVideoUpload && this.config.showVideoLink) {
            return '(LabelForumConversationEditorImageVideoVideoLinkUploadingLimitsInfoText)';
        }
        if (this.config.showImageUpload && this.config.showVideoUpload && this.config.showAttachment) {
            return '(LabelForumConversationEditorImageVideoAttachmentUploadingLimitsInfoText)';
        }
        if (this.config.showImageUpload && this.config.showVideoLink && this.config.showAttachment) {
            return '(LabelForumConversationEditorImageVideoLinkAttachmentUploadingLimitsInfoText)';
        }
        if (this.config.showVideoUpload && this.config.showVideoLink && this.config.showAttachment) {
            return '(LabelForumConversationEditorVideoVideoLinkAttachmentUploadingLimitsInfoText)';
        }
        if (this.config.showImageUpload && this.config.showVideoUpload) {
            return '(LabelForumConversationEditorImageVideoUploadingLimitsInfoText)';
        }
        if (this.config.showImageUpload && this.config.showVideoLink) {
            return '(LabelForumConversationEditorImageVideoLinkUploadingLimitsInfoText)';
        }
        if (this.config.showVideoUpload && this.config.showVideoLink) {
            return '(LabelForumConversationEditorVideoVideoLinkUploadingLimitsInfoText)';
        }
        if (this.config.showImageUpload && this.config.showAttachment) {
            return '(LabelForumConversationEditorImageAttachmentUploadingLimitsInfoText)';
        }
        if (this.config.showVideoUpload && this.config.showAttachment) {
            return '(LabelForumConversationEditorVideoAttachmentUploadingLimitsInfoText)';
        }
        if (this.config.showVideoLink && this.config.showAttachment) {
            return '(LabelForumConversationEditorVideoLinkAttachmentUploadingLimitsInfoText)';
        }
        if (this.config.showImageUpload) {
            return '(LabelForumConversationEditorImageUploadingLimitsInfoText)';
        }
        if (this.config.showVideoUpload) {
            return '(LabelForumConversationEditorVideoUploadingLimitsInfoText)';
        }
        if (this.config.showVideoLink) {
            return '(LabelForumConversationEditorVideoLinkUploadingLimitsInfoText)';
        }
        if (this.config.showAttachment) {
            return '(LabelForumConversationEditorAttachmentUploadingLimitsInfoText)';
        }
        return '';
    };
    IscUploadConversationController.prototype.isreplyContentWhileSavingDisabled = function () {
        return this.isButtonDisabled(this.stimuli, this.config.maxStimuli);
    };
    IscUploadConversationController.prototype.isUploadAttachmentsButtonDisabled = function () {
        return this.isButtonDisabled(this.attachments, this.config.maxAttachments);
    };
    IscUploadConversationController.prototype.isButtonDisabled = function (items, maxItems) {
        var currentFilesCount = this.getUploadedItemsCount(items);
        return currentFilesCount >= maxItems || this.config.isDisabled || this.replyContentWhileSavingDisabled;
    };
    Object.defineProperty(IscUploadConversationController.prototype, "stimuliCount", {
        get: function () {
            return this.getUploadedItemsCount(this.stimuli);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IscUploadConversationController.prototype, "attachmentsCount", {
        get: function () {
            return this.getUploadedItemsCount(this.attachments);
        },
        enumerable: false,
        configurable: true
    });
    IscUploadConversationController.prototype.getUploadedItemsCount = function (items) {
        var _this = this;
        var currentFilesCount = _.countBy(items, function (item) {
            return item.status !== _this.serverConstants.conversationStimuliStatusConstants.removed;
        });
        return currentFilesCount.true || 0;
    };
    IscUploadConversationController.$inject = [
        'iscSocialStimuli',
        'iscLabelService',
        'iscConfigurationService',
        '$q',
        '$mdDialog',
        'iscStimuliTypeService',
        'serverConstants'
    ];
    return IscUploadConversationController;
}());
export { IscUploadConversationController };
