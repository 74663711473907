export class IdeaStreamSsoController {
    constructor($window, authService, userService, $state, notifications, ideastreamssoService) {
        this.$window = $window;
        this.authService = authService;
        this.userService = userService;
        this.$state = $state;
        this.notifications = notifications;
        this.ideastreamssoService = ideastreamssoService;
    }
    $onInit() {
        if (this.projectId === '0') {
            this.ideastreamssoService.stopListening();
            this.notifications.connected.subscribe((connected) => {
                if (connected) {
                    this.notifications.ideaStreamSsoLoginFinished(this.connectionId, this.redirect)
                        .finally(() => this.$window.close());
                }
            });
            return;
        }
        if (!this.userService.getToken() && this.token) {
            this.authService.setToken(this.token);
        }
        this.authService.squareToIdeaStreamSsoLogin({
            projectId: this.projectId,
            sourceGuid: this.sourceGuid,
            source: this.source,
        }).then((response) => {
            let url = response.data.SsoRedirectUrl;
            if (this.redirect && !this.connectionId) {
                url += `/redirect:${this.bin2hex(this.redirect)}`;
            }
            if (this.connectionId) {
                const redirectUrl = this.$state.href('container.ideastreamsso', {
                    projectId: 0,
                    connectionId: this.connectionId,
                    redirect: response.data.CommunityUrl,
                }, {
                    absolute: true,
                });
                url += `/redirect:${this.bin2hex(redirectUrl)}`;
            }
            this.$window.open(url, '_self');
        }).catch((response) => {
            this.$state.go('container.landingpage', {
                landingMessage: response.data.Reason && `(LabelIdeaStreamSsoError_${response.data.Reason})`,
                redirectState: 'container.main.home',
            });
        });
    }
    bin2hex(s) {
        let result = '';
        for (let i = 0; i < s.length; i++) {
            const n = s.charCodeAt(i).toString(16);
            result += n.length < 2 ? `0${n}` : n;
        }
        return result;
    }
}
IdeaStreamSsoController.$inject = ['$window', 'authService', 'userService', '$state', 'notifications', 'ideastreamssoService'];
