import { RejectType } from '@uirouter/angularjs';
export class RouterHelper {
    constructor(config, $urlRouterProvider, $stateProvider, $injector, $location, $rootScope, $transitions, $state, logger, $uiRouter) {
        this.config = config;
        this.$urlRouterProvider = $urlRouterProvider;
        this.$stateProvider = $stateProvider;
        this.$injector = $injector;
        this.$location = $location;
        this.$rootScope = $rootScope;
        this.$transitions = $transitions;
        this.$state = $state;
        this.logger = logger;
        this.$uiRouter = $uiRouter;
        this.init = () => {
            this.handlingStateChangeError = false;
            this.stateCounts = {
                errors: 0,
                changes: 0,
            };
            this.handleRoutingErrors();
            this.$transitions.onSuccess({}, () => {
                this.updateDocTitle();
            });
        };
        this.updateDocTitle = () => {
            if (!this.constantsfactory) {
                this.constantsfactory = this.$injector.get('constantsfactoryProvider').$get();
            }
            this.stateCounts.changes++;
            this.handlingStateChangeError = false;
            if (this.constantsfactory) {
                const stateTitle = this.$state.current.data ? this.$state.current.data.title : '';
                const match = /\((\w+)\)/g.exec(stateTitle);
                if (match && match[1]) {
                    this.constantsfactory.getLabelValue(match[1]).then((label) => {
                        const title = this.config.docTitle + (label || '');
                        this.$rootScope.title = title;
                    });
                }
            }
            else {
                this.$rootScope.title = this.config.docTitle;
            }
            this.$rootScope.state = this.$state.current.name;
        };
        this.configureStates = (states) => {
            states.forEach((state) => {
                state.config.resolve =
                    angular.extend(state.config.resolve || {}, this.config.resolveAlways);
                this.$stateProvider.state(state.state, state.config);
            });
            this.$urlRouterProvider.otherwise(() => {
                this.$state.go('container.main.404', {}, { location: false });
            });
        };
        this.handleRoutingErrors = () => {
            this.$transitions.onError({}, (transition) => {
                const error = transition.error();
                if (error.redirected || this.handlingStateChangeError || error.type === RejectType.IGNORED) {
                    return;
                }
                this.stateCounts.errors++;
                this.handlingStateChangeError = true;
                const transitionTo = transition.to();
                const destination = (transitionTo &&
                    (transitionTo.data.title || transitionTo.name || transitionTo.data.loadedTemplateUrl)) ||
                    'unknown target';
                const msg = `Error routing to ${destination}. ${error.message || ''}`;
                this.logger.warning(msg, [transitionTo]);
                if (error.type === RejectType.ERROR) {
                    this.$location.path('/');
                }
            });
            this.$uiRouter.stateService.defaultErrorHandler(() => null);
        };
        this.getStates = () => this.$state.get();
        this.init();
    }
    static factory(config, $urlRouterProvider, $stateProvider, $injector) {
        const helper = ($location, $rootScope, $transitions, $state, logger, $uiRouter) => new RouterHelper(config, $urlRouterProvider, $stateProvider, $injector, $location, $rootScope, $transitions, $state, logger, $uiRouter);
        helper.$inject = ['$location', '$rootScope', '$transitions', '$state', 'logger', '$uiRouter'];
        return helper;
    }
}
RouterHelper.insitesUserInvalid = false;
