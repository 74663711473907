import {
  MarkNotificationReadRequest,
  PushNotificationResponse,
  SendPushNotificationRequest,
  DiscussionPostChangedRequest,
  RemoveLikeNotificationForDiscussionRequest,
} from '../../../models/command';
import{ httpService } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const markNotificationRead = (request: MarkNotificationReadRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/NotificationService/MarkNotificationRead',
    request,
    undefined,
    config);

export const markTopicNotificationsRead = (topicGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/NotificationService/MarkTopicNotificationsRead',
    undefined,
    { topicGuid },
    config);

export const markActivityNotificationsRead = (activityGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/NotificationService/MarkActivityNotificationsRead',
    undefined,
    { activityGuid },
    config);

export const clearAllNotifications = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/NotificationService/ClearAllNotifications',
    undefined,
    undefined,
    config);

export const clearAllNotificationsMobile = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/NotificationService/ClearAllNotificationsMobile',
    undefined,
    undefined,
    config);

export const sendPushNotification = (request: SendPushNotificationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<PushNotificationResponse>(
    '/NotificationService/SendPushNotification',
    request,
    undefined,
    config);

export const createNotificationForDiscussion = (request: DiscussionPostChangedRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/NotificationService/CreateNotificationForDiscussion',
    request,
    undefined,
    config);

export const removeLikeNotificationForDiscussion = (request: RemoveLikeNotificationForDiscussionRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/NotificationService/RemoveLikeNotificationForDiscussion',
    request,
    undefined,
    config);
