import * as angular from 'angular';
import { UiModule } from './../../../../../index';
import { Utils } from '../../../../../utils/utils';
var nwEmojiPickerTemplate = "<md-menu>\n  <button class=\"nw-button emoji-picker-button\" title=\"Pick emoji\" ng-click=\"$mdMenu.open($event)\" ng-disabled=\"vm.editMode || vm.disabled\">'Emoji picker</button>\n  <md-menu-content>\n    <md-menu-item ng-repeat=\"emoji in vm.uniqueEmojis;\" class=\"emoji-picker\">\n      <md-button ng-click=\"vm.pickEmoji($event, emoji)\" aria-label=\"{{emoji.short_name}}\">\n        <img class=\"emoji {{emoji.short_name}}\" ng-src=\"/images/emojis/{{emoji.image}}\" alt=\"\"/>\n      </md-button>\n    </md-menu-item>\n  </md-menu-content>\n</md-menu>";
UiModule.component('iscNwEmojiPicker', {
    template: nwEmojiPickerTemplate,
    controller: EmojiPickerController,
    controllerAs: 'vm',
    bindings: {
        execCommand: '=',
        editMode: '=',
        disabled: '=',
    },
});
EmojiPickerController.$inject = ['emojiservice', '$scope', '$timeout', '$element', '_', '$mdMenu', 'iscDomhelperservice'];
function EmojiPickerController(emojiservice, $scope, $timeout, $element, _, $mdMenu, iscDomhelperservice) {
    var ngWig;
    var ngWigEditor;
    var vm = this;
    vm.uniqueEmojis = emojiservice.getUniqueEmojis();
    vm.$postLink = function () {
        $timeout(function () {
            ngWig = iscDomhelperservice.findAncestor($element[0], 'ng-wig');
            ngWigEditor = ngWig.querySelector('#ng-wig-editable');
            angular.element(ngWigEditor).on('keyup', vm.onKeyUp);
            if (document.queryCommandSupported('enableObjectResizing')) {
                vm.execCommand('enableObjectResizing', false);
            }
            else {
                angular.element(ngWigEditor).on('mousedown mouseup click', vm.onMouseDown);
            }
        });
    };
    vm.onMouseDown = function (event) {
        if (iscDomhelperservice.elementMatches(event.target, 'img.emoji')) {
            event.preventDefault();
        }
    };
    vm.onKeyUp = function () {
        replaceLastTypedEmoji();
        $mdMenu.hide();
    };
    function replaceLastTypedEmoji() {
        var selection = window.getSelection();
        var isTextNode = selection.anchorNode.nodeType === Node.TEXT_NODE;
        if (selection.type === 'Caret' && isTextNode) {
            var node = selection.anchorNode;
            var allText = node.nodeValue.substring(0, selection.anchorOffset);
            var text_1 = allText.substring(0, selection.anchorOffset);
            var emoji = _.find(vm.uniqueEmojis, function (e) {
                return Utils.stringEndsWith(text_1, e.text);
            });
            if (emoji) {
                var newNode = node.splitText(selection.anchorOffset);
                node.textContent = text_1.substring(0, text_1.length - emoji.text.length);
                var range = document.createRange();
                if (newNode.textContent) {
                    range.setStart(newNode, 0);
                    range.setEnd(newNode, 0);
                }
                else {
                    range.setStart(node, node.length);
                    range.setEnd(node, node.length);
                }
                selection.removeAllRanges();
                selection.addRange(range);
                iscDomhelperservice.insertHtmlInNgWig(emojiservice.getEmojiHtml(emoji), ngWigEditor);
            }
        }
    }
    vm.pickEmoji = function (event, emoji) {
        var emojiHtml = emojiservice.getEmojiHtml(emoji);
        iscDomhelperservice.insertHtmlInNgWig(emojiHtml, ngWigEditor);
    };
}
