import * as angular from 'angular';
import { Subject } from 'rxjs';
export class ParticipantService {
    constructor(httpService, _, userService, serverConstants) {
        this.httpService = httpService;
        this._ = _;
        this.userService = userService;
        this.serverConstants = serverConstants;
        this.profileEditorClosed = new Subject();
    }
    SquareParticipantDetails() {
        return this.httpService.get({
            url: '/ParticipantService/SquareParticipantDetails',
            params: {
                squareParticipantGuid: this.userService.userGuid(),
            },
        }).then((response) => response.data);
    }
    SquareParticipantPublicDetails(username) {
        return this.httpService.get({
            url: '/ParticipantService/SquareParticipantPublicDetails',
            params: {
                Username: username,
            },
        }).then((response) => response.data);
    }
    registerParticipant(participant) {
        return this.httpService.post({
            url: '/ParticipantService/RegisterSquareParticipant',
            data: participant,
        });
    }
    isUserUnique(user) {
        return this.httpService.get({
            url: '/ParticipantService/IsUserUnique',
            params: {
                username: user.name,
                squareParticipantGuid: user.squareParticipantGuid,
            },
        });
    }
    prepareParticipantForActivation(squareParticipantGuid) {
        return this.httpService.post({
            url: '/ParticipantService/PrepareParticipantForActivation',
            data: {
                squareParticipantGuid,
            },
        });
    }
    updateSquareParticipantProfile(participantProfile) {
        return this.httpService.post({
            url: '/ParticipantService/UpdateSquareParticipantDetails',
            data: participantProfile,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
    resetPassword(resetPasswordRequest) {
        if (!resetPasswordRequest.captchaResponse) {
            delete resetPasswordRequest.captchaResponse;
        }
        return this.httpService.post({
            url: '/ParticipantService/ResetPassword',
            data: angular.fromJson(resetPasswordRequest),
            transformRequest: (obj) => {
                const str = [];
                this._.forEach(Object.keys(obj), (p) => {
                    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
                });
                return str.join('&');
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
    checkForgotPassUrlValidity(forgotPassGuid) {
        return this.httpService.get({
            url: '/ParticipantService/SquareParticipantChangeRequestUrlValidity',
            params: {
                ChangeRequestGuid: forgotPassGuid,
                Type: this.serverConstants.squareParticipantChangeRequestTypeConstants.forgotPassword,
            },
        });
    }
    checkChangeEmailUrlValidity(changeEmailGuid) {
        return this.httpService.get({
            url: '/ParticipantService/SquareParticipantChangeRequestUrlValidity',
            params: {
                ChangeRequestGuid: changeEmailGuid,
                Type: this.serverConstants.squareParticipantChangeRequestTypeConstants.changeEmail,
            },
        });
    }
    checkSubscribeUrlValidity(subscribeGuid) {
        return this.httpService.get({
            url: '/ParticipantService/SquareParticipantChangeRequestUrlValidity',
            params: {
                ChangeRequestGuid: subscribeGuid,
                Type: this.serverConstants.squareParticipantChangeRequestTypeConstants.subscription,
            },
        }).then((response) => response.data.IsUrlValid);
    }
    changeParticipantEmail(squareParticipantChangeRequestGuid) {
        return this.httpService.post({
            url: '/ParticipantService/ChangeParticipantEmail',
            data: { squareParticipantChangeRequestGuid },
        });
    }
    squareParticipantRequestedEmailChange(squareParticipantGuid) {
        return this.httpService.get({
            url: `/ParticipantService/SquareParticipantRequestedEmailChange?squareParticipantGuid=${squareParticipantGuid}`,
        });
    }
    changePasswordForSquareParticipant(changeRequest) {
        return this.httpService.post({
            url: '/ParticipantService/ChangePassword',
            data: changeRequest,
        });
    }
    saveParticipantAvatar(image, metadata, squareParticipantGuid) {
        return this.httpService.upload({
            url: '/ParticipantService/SaveParticipantAvatar',
            data: {
                file: image,
                metadata: angular.toJson(metadata),
                squareParticipantGuid,
            },
        });
    }
    unsubscribeParticipant(participant) {
        return this.httpService.post({
            url: '/ParticipantService/UnsubscribeParticipant',
            data: participant,
        }).then((response) => response.data);
    }
    reactivateParticipant(changeRequestGuid) {
        return this.httpService.post({
            url: '/ParticipantService/ReactivateParticipant',
            data: { SquareParticipantChangeRequestGuid: changeRequestGuid },
        }).then((response) => response.data.IsUrlValid);
    }
    shouldDisplayCaptcha() {
        return this.httpService.get({
            url: '/ParticipantService/ShouldShowCaptcha',
        }).then((response) => response.data.ShowCaptcha);
    }
    validateCurrentToken() {
        return this.httpService.get({
            url: '/ParticipantService/ValidateCurrentToken',
        });
    }
    shouldRedirectToSplashPage() {
        return this.httpService.get({
            url: '/ParticipantService/ShouldRedirectToSplashPage',
        }).then((response) => response.data);
    }
    hasUserAlreadyDownloadedTheMobileApp() {
        return this.httpService.get({
            url: '/ParticipantService/HasUserAlreadyDownloadedTheMobileApp',
        }).then((response) => response.data);
    }
}
ParticipantService.$inject = ['httpService', '_', 'userService', 'serverConstants'];
