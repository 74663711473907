var YouTubeStimuliDialogController = (function () {
    function YouTubeStimuliDialogController($mdDialog, videoId) {
        this.$mdDialog = $mdDialog;
        this.videoId = videoId;
        this.playerVars = {
            autoplay: 1,
        };
    }
    YouTubeStimuliDialogController.prototype.close = function () {
        this.$mdDialog.cancel();
    };
    YouTubeStimuliDialogController.$inject = ['$mdDialog', 'videoId'];
    return YouTubeStimuliDialogController;
}());
export { YouTubeStimuliDialogController };
