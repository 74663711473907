<script setup lang="ts">

import { useConstantsStore } from '@/store/constants-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { usePagesStore } from '@/store/pages-store';
import NavigationTab from './navigation-tab.vue';
import { useSelectedTab } from './composables/navigation-bar-shared';
import { RouteRecordName, useRoute, useRouter } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { useTour } from '@/composables/use-tour';
import { useParticipantStore } from '@/store/participant-store';
import { QTab } from 'quasar';

const constantsStore = useConstantsStore();
const mainPages = computed(() => usePagesStore().mainPages);
const getLabelValue = constantsStore.getLabelValue;
const { selectedTab } = useSelectedTab();
const route = useRoute();
const router = useRouter();
const tabs = ref<QTab[]>([]);

const getLink = (name: RouteRecordName | undefined | null) => {
  if (name !== RouteNames.More) {
    return { name: RouteNames.More };
  }

  const backState = router.options?.history?.state?.back;
  if (backState) {
    return String(backState);
  }

  return { name: RouteNames.Home };
};

// Delay the start of the tour until
// - the tabs have been rendered correctly
// - the user is routed to the homepage
// This is done by watching the tabs and route themselves.
// Since this should only be done once, we immediately call the WatchStopHandle
const stopTabWatch = watch(([tabs.value, route]), async () => {
  // If we're still on the splashpage, we don't need to start the tour yet nor stop the watch for it
  if(route.name?.toString() !== RouteNames.Home){
    return;
  }
  const participantStore = useParticipantStore();
  // On first login or startTour=true, show tour
  // Checking startTour here as well instead of using immediate on the watch below to make sure startTour() won't be triggered twice.
  if (participantStore.shouldStartTour || route.query.startTour === 'true'){
    participantStore.shouldStartTour = false;
    useTour().startTour();
  }
  // Show tour when startTour parameter is added
  watch(() => route.query.startTour, (newValue) => {
    if(newValue === 'true') {
      useTour().startTour();
    }
  });
  stopTabWatch();
});

</script>

<template>
  <q-footer class="bg-white shadow-10 text-grey q-pb-lg navigation-bar-mobile">
    <q-tabs
      v-model="selectedTab"
      data-v-step="welcome"
      content-class="justify-around"
      class="row no-wrap navigation-bar-mobile__inner q-px-sm"
    >
      <!-- Home -->
      <navigation-tab
        v-if="mainPages[0]"
        :key="mainPages[0].guid"
        :page="mainPages[0]"
        :index="0"
        @set-tabs="(index, el) => tabs[index] = el"
      />

      <!-- main pages -->
      <div class="row level2 wrap justify-evenly fit overflow-hidden">
        <navigation-tab
          v-for="(page, index) in mainPages.slice(1)"
          :key="page.guid"
          :page="page"
          :index="index"
        />
      </div>

      <router-link :to="getLink(route.name)">
        <q-tab
          ref="moreTabMobile"
          :name="RouteNames.More"
          class="q-px-sm"
        >
          <q-icon
            size="sm"
            :name="route.name !== RouteNames.More ? 'svguse:/images/page-icons/menu.svg#menu' : 'fa-solid fa-times'"
          />
          <span class="navigation-bar-tabs__page-name text-caption text-capitalize">
            {{ getLabelValue('LabelNavigationMore') }}
          </span>
        </q-tab>
      </router-link>
    </q-tabs>
  </q-footer>
</template>

<style lang="scss">
.navigation-bar-mobile {
  &__inner {
    height: 52px;
  }
  a {
    text-decoration: none;
    color: $dark;
  }
  .q-tabs__content {
    align-items: flex-start;
  }
}
</style>
