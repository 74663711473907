const squareFactory = (serverConstants) => {
    const square = {
        Guid: '00000000-0000-0000-0000-000000000001',
        Id: -1,
        SquareName: '',
        Subdomain: '',
        Language: '',
        Status: 0,
        ClientGuid: '',
        ClientName: '',
        ProgramGuid: '',
        SsoEnabled: false,
        IsCompleted: false,
        SsoExternalLoginUrl: '',
        DateLastImageChange: '',
        Anonymous: false,
        IsPublicProfileEnabled: false,
        VideoStorage: 1,
    };
    square.IsActive = () => square.Status === serverConstants.squareStatusConstants.active;
    return square;
};
squareFactory.$inject = ['serverConstants'];
angular
    .module('insitesApp.core')
    .factory('squareFactory', squareFactory);
export {};
