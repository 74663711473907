export class PriorityNavigationHelper {
    static emptyThePageList() {
        this._listPageOverflow = [];
        this._listMainPage = [];
    }
    static get listPageOverflow() {
        return this._listPageOverflow;
    }
    static set listPageOverflow(list) {
        this._listPageOverflow = list;
    }
    static get listMainPage() {
        return this._listMainPage;
    }
    static set listMainPage(list) {
        this._listMainPage = list;
    }
}
PriorityNavigationHelper._listPageOverflow = [];
PriorityNavigationHelper._listMainPage = [];
