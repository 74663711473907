import MainContainer from '@/components/main/main-container.vue';
import { RouteRecordRaw } from 'vue-router';
import { landingPageRoute } from './landing-page.route';
import { RouteNames } from './route-names.enum';
import { splashPageRoute } from './splash-page.route';
import { loginRoute } from './login.route';
import { activityRoutes } from './activities.route';
import { reactivateRoute } from './reactivate.route';
import { registrationRoute } from './registration.route';
import { resetPasswordRoute } from './reset-password.route';
import { changePasswordRoute } from './change-password.route';
import { unsubscribeRoute } from './unsubscribe.route';
import { confirmEmailRoute } from './confirm-email.route';

export const nonMainRoute: RouteRecordRaw = {
  name: RouteNames.NonMain,
  path: '',
  component: MainContainer,
  meta: {
    title: 'Login',
    requireLogin: false,
  },
  redirect: () => ({ name: RouteNames.Home }),
  children: [
    splashPageRoute,
    reactivateRoute,
    landingPageRoute,
    loginRoute,
    registrationRoute,
    resetPasswordRoute,
    changePasswordRoute,
    unsubscribeRoute,
    ...activityRoutes,
    confirmEmailRoute,
  ],
};
