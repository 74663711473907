var RemoveBadStyles = (function () {
    function RemoveBadStyles() {
    }
    RemoveBadStyles.prototype.processElement = function (element) {
        if (!(element instanceof HTMLElement)) {
            return element;
        }
        element.style.background = '';
        element.style.backgroundColor = '';
        element.style.backgroundImage = '';
        return element;
    };
    return RemoveBadStyles;
}());
export default RemoveBadStyles;
