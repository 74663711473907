import { __extends } from "tslib";
import { ReplayEventPubSub } from './replayeventpubsub';
var EventPubSub = (function (_super) {
    __extends(EventPubSub, _super);
    function EventPubSub(scope, eventName, $q, _) {
        var _this = _super.call(this, scope, eventName, $q, _) || this;
        _this.shouldResolveInitialValueOnSubscribe = false;
        return _this;
    }
    return EventPubSub;
}(ReplayEventPubSub));
export { EventPubSub };
