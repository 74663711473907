export class TaskListController {
    constructor(taskList) {
        this.taskList = taskList;
        this.tasks = this.taskList.tasks;
    }
    onCloseClick(task, $event) {
        $event.stopPropagation();
        task.hide();
    }
}
TaskListController.$inject = ['taskList'];
