import { __awaiter } from "tslib";
export class MobileTourController {
    constructor($mdDialog, $state) {
        this.$mdDialog = $mdDialog;
        this.$state = $state;
    }
    goToProfile() {
        return __awaiter(this, void 0, void 0, function* () {
            this.$state.go('container.main.profile');
            this.$mdDialog.hide();
        });
    }
}
MobileTourController.$inject = ['$mdDialog', '$state'];
