export class UsernameValidator {
    constructor($q, participantService, userService, $stateParams, _, serverConstants) {
        this.$q = $q;
        this.participantService = participantService;
        this.userService = userService;
        this.$stateParams = $stateParams;
        this._ = _;
        this.serverConstants = serverConstants;
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {
            participant: '=participant',
        };
    }
    link($scope, element, attrs, ngModel) {
        ngModel.$asyncValidators.uniqueUsername = (modelValue, viewValue) => {
            let squarePartGuid = this.$stateParams.sqPartId;
            if (typeof this.$stateParams.sqPartId === 'undefined' || this.$stateParams.sqPartId === null) {
                squarePartGuid = this.userService.userGuid();
            }
            const user = {
                name: encodeURIComponent(viewValue),
                squareParticipantGuid: squarePartGuid,
            };
            return this.participantService.isUserUnique(user).then((response) => {
                if (response.data === false) {
                    return this.$q.reject();
                }
                return response.data;
            });
        };
        ngModel.$validators.anonymousUsername = (modelValue, viewValue) => {
            const participant = $scope.participant;
            if (participant && participant.Role === this.serverConstants.roleConstants.participant
                && participant.AnonymousSquare && (participant.FirstName || participant.LastName)) {
                const firstNameSplit = participant.FirstName ? participant.FirstName.toLowerCase().split(' ') : '';
                const lastNameSplit = participant.LastName ? participant.LastName.toLowerCase().split(' ') : '';
                const names = this._.unionWith(firstNameSplit, lastNameSplit, this._.isEqual);
                const found = this._.find(names, (name) => viewValue.toLowerCase().includes(name));
                if (found) {
                    return false;
                }
            }
            return true;
        };
    }
    static factory() {
        const directive = ($q, participantService, userService, $stateParams, _, serverConstants) => new UsernameValidator($q, participantService, userService, $stateParams, _, serverConstants);
        directive.$inject = ['$q', 'participantService', 'userService', '$stateParams', '_', 'serverConstants'];
        return directive;
    }
}
UsernameValidator.$inject = ['$q', 'participantService', 'userService', '$stateParams', '_'];
