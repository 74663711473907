export class TrackingService {
    constructor(serverConstants, notifications, mediaservice) {
        this.serverConstants = serverConstants;
        this.notifications = notifications;
        this.mediaservice = mediaservice;
        this.eventType = {
            view: 1,
            click: 2,
        };
    }
    researchActivityCardOnHomePageClick(card) {
        this.trackActivityChannelEvent(card.CommunicationSampleGuid, this.serverConstants.channelTypeConstants.researchActivityChallengeHome, this.eventType.click);
    }
    researchActivityCardOnChallengesPageClick(card) {
        this.trackActivityChannelEvent(card.CommunicationSampleGuid, this.serverConstants.channelTypeConstants.researchActivityChallenge, this.eventType.click);
    }
    researchActivityNotificationClick(sampleGuid, channelType = null) {
        this.trackActivityChannelEvent(sampleGuid, channelType || this.serverConstants.channelTypeConstants.notification, this.eventType.click);
    }
    platformNotificationClick(sampleGuid, channelType = null) {
        this.trackPlatformNotificationChannelEvent(sampleGuid, channelType || this.serverConstants.channelTypeConstants.platformNotification, this.eventType.click);
    }
    researchActivityNotificationView(sampleGuid) {
        this.trackActivityChannelEvent(sampleGuid, this.serverConstants.channelTypeConstants.notification, this.eventType.view);
    }
    researchActivityCardOnHomePageView(card) {
        this.trackActivityChannelEvent(card.CommunicationSampleGuid, this.serverConstants.channelTypeConstants.researchActivityChallengeHome, this.eventType.view);
    }
    researchActivityCardOnChallengesPageView(card) {
        this.trackActivityChannelEvent(card.CommunicationSampleGuid, this.serverConstants.channelTypeConstants.researchActivityChallenge, this.eventType.view);
    }
    engagementCardOnHomePageClick(targetGuid) {
        const event = {
            Identifier: 'engagement-card-channel-',
            ChannelType: this.serverConstants.channelTypeConstants.communicationCard,
            TargetGuid: targetGuid,
        };
        this.trackEvent(event, this.eventType.click);
    }
    engagementCardOnHomePageView(targetGuid) {
        const event = {
            Identifier: 'engagement-card-channel-',
            ChannelType: this.serverConstants.channelTypeConstants.communicationCard,
            TargetGuid: targetGuid,
        };
        this.trackEvent(event, this.eventType.view);
    }
    newsCardOnHomePageClick(targetGuid) {
        this.newsCardEvent(targetGuid, this.serverConstants.channelTypeConstants.communicationNewsHome, this.eventType.click);
    }
    newsCardOnHomePageView(targetGuid) {
        this.newsCardEvent(targetGuid, this.serverConstants.channelTypeConstants.communicationNewsHome, this.eventType.view);
    }
    newsCardOnNewsPageClick(targetGuid) {
        this.newsCardEvent(targetGuid, this.serverConstants.channelTypeConstants.communicationNews, this.eventType.click);
    }
    newsCardOnNewsPageView(targetGuid) {
        this.newsCardEvent(targetGuid, this.serverConstants.channelTypeConstants.communicationNews, this.eventType.view);
    }
    newsCardEvent(targetGuid, channel, eventType) {
        const event = {
            Identifier: 'news-card-channel-',
            ChannelType: channel,
            TargetGuid: targetGuid,
        };
        this.trackEvent(event, eventType);
    }
    trackActivityChannelEvent(targetGuid, channel, eventType) {
        const event = {
            Identifier: 'research-activity-channel-',
            ChannelType: channel,
            TargetGuid: targetGuid,
        };
        if (this.mediaservice.isMobileApp) {
            event.ChannelType = this.serverConstants.channelTypeConstants.researchActivityMobileApp;
        }
        this.trackEvent(event, eventType);
    }
    trackPlatformNotificationChannelEvent(targetGuid, channel, eventType) {
        const event = {
            Identifier: 'platform-notification-channel-',
            ChannelType: channel,
            TargetGuid: targetGuid,
        };
        if (this.mediaservice.isMobileApp) {
            event.ChannelType = this.serverConstants.channelTypeConstants.researchActivityMobileApp;
        }
        this.trackEvent(event, eventType);
    }
    trackEvent(event, eventType) {
        if (eventType === this.eventType.click) {
            event.Identifier += 'clicked';
            this.notifications.elementClicked(event);
        }
        if (eventType === this.eventType.view) {
            event.Identifier += 'viewed';
            this.notifications.elementViewed(event);
        }
    }
}
TrackingService.$inject = ['serverConstants', 'notifications', 'mediaservice'];
