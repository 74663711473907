var IscButtonController = (function () {
    function IscButtonController($attrs, $transclude, $element, _) {
        this.$attrs = $attrs;
        this.$transclude = $transclude;
        this.$element = $element;
        this._ = _;
        this.disabled = false;
        this.hasIcon = false;
        this.hasAlternativeLabel = false;
    }
    IscButtonController.prototype.$onInit = function () {
        var _this = this;
        this.themeClass = this.theme ? ('md-' + this.theme) : '';
        this.$attrs.$observe('disabled', function (disabled) {
            _this.disabled = disabled;
        });
        this.hasIcon = this.$transclude.isSlotFilled('icon');
        this.hasAlternativeLabel = this.$transclude.isSlotFilled('alternativeLabel');
        this.$element.on('click', function (event) {
            if (_this.disabled) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }
        });
        var mdButton = this.$element[0].querySelector('.md-button');
        var classes = [];
        if (this.$element.hasClass('primary')) {
            classes.push('md-primary');
        }
        else if (this.$element.hasClass('accent')) {
            classes.push('md-accent');
        }
        else if (this.$element.hasClass('warn')) {
            classes.push('md-warn');
        }
        else if (this.$element.hasClass('{{stepExtraButton.class}}')) {
            classes.push('{{stepExtraButton.class}}');
        }
        if (!this.theme && classes.length === 0) {
            classes.push('default');
        }
        if (!this.$element.hasClass('flat') && !this.$element.hasClass('outline') && !this.$element.hasClass('icon-button')) {
            classes.push('md-raised');
        }
        this._.each(classes, function (item) {
            mdButton.classList.add(item);
        });
    };
    IscButtonController.$inject = ['$attrs', '$transclude', '$element', '_'];
    return IscButtonController;
}());
export { IscButtonController };
