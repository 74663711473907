export class ChallengesGroupController {
    constructor($state, serverConstants, $mdMedia, $scope, trackingService, _, mediaservice, languageService, challengesService) {
        this.$state = $state;
        this.serverConstants = serverConstants;
        this.trackingService = trackingService;
        this._ = _;
        this.mediaservice = mediaservice;
        this.languageService = languageService;
        this.challengesService = challengesService;
        this._mobileWidth = 80;
        this._isMobile = false;
        this._viewedCards = [];
        this.startingCard = 0;
        $scope.$watch(() => $mdMedia('xs'), (set) => {
            if (set) {
                this.cardsInView = 1;
            }
            this._isMobile = set;
        });
        $scope.$watch(() => $mdMedia('sm'), (set) => {
            if (set) {
                this.cardsInView = 2;
            }
        });
        $scope.$watch(() => $mdMedia('gt-sm'), (set) => {
            if (set) {
                this.cardsInView = 3;
            }
        });
    }
    $onInit() {
        this.countEndingInViewCard();
    }
    get cardsInView() {
        return this._cardsInView;
    }
    set cardsInView(value) {
        this._cardsInView = value;
        if (this.startingCard > this.cardGroup.sequences.length - this._cardsInView) {
            this.startingCard = Math.max(0, this.cardGroup.sequences.length - this._cardsInView);
        }
    }
    get showNavigationButtons() {
        return this.cardGroup.sequences.length > this.cardsInView && this.cardsInView > 1;
    }
    get previousButtonDisabled() {
        return this.startingCard <= 0;
    }
    get nextButtonDisabled() {
        return this.startingCard + this.cardsInView >= this.cardGroup.sequences.length;
    }
    get wrapCards() {
        return this._wrapCards;
    }
    set wrapCards(value) {
        this._wrapCards = value;
    }
    cardActionClick(card) {
        this.challengesService.listPromise = undefined;
        this.trackingService.researchActivityCardOnChallengesPageClick(card);
        if (card.ActivityType === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey && !this.mediaservice.isMobileApp) {
            const url = this.$state.href(card.route, {
                sampleGuid: card.CommunicationSampleGuid,
                activityGuid: card.ActivityGuid,
                channel: this.serverConstants.channelTypeConstants.researchActivityChallenge,
            });
            window.open(url, '_blank');
        }
        else {
            let nextRouterState = card.route;
            if (card.ActivityType === this.serverConstants.squareActivityTypeConstants.discussion20Research) {
                nextRouterState = 'container.main.challenges.discussionNew';
            }
            this.$state.go(nextRouterState, {
                sampleGuid: card.CommunicationSampleGuid,
                activityGuid: card.ActivityGuid,
                channel: this.serverConstants.channelTypeConstants.researchActivityChallenge,
                activityType: card.ActivityType,
            });
        }
    }
    trackViewedCard(inview, sequence) {
        if (inview) {
            sequence.forEach((card) => {
                if (!this._.some(this._viewedCards, card)) {
                    this.trackingService.researchActivityCardOnChallengesPageView(card);
                    this._viewedCards.push(card);
                }
            });
        }
    }
    countEndingInViewCard() {
        this.endingCard = Math.min(this.cardGroup.sequences.length, 3);
    }
    nextClick() {
        if (!this.nextButtonDisabled) {
            this.startingCard++;
            this.endingCard++;
        }
    }
    previousClick() {
        if (!this.previousButtonDisabled) {
            this.startingCard--;
            this.endingCard--;
        }
    }
    swipeLeft() {
        this.nextClick();
    }
    swipeRight() {
        this.previousClick();
    }
    loadCard(index) {
        return this._wrapCards || index >= this.startingCard - 1 && index <= this.startingCard + this._cardsInView + 1;
    }
    getCardStyle(index) {
        let cardWidth;
        let opacity = 0;
        let offset = 0;
        if (this._isMobile) {
            if (index === this.startingCard) {
                opacity = 1;
            }
            else if (index === this.startingCard + 1 || index === this.cardGroup.sequences.length - 2) {
                opacity = 0.5;
            }
            if (this.cardGroup.sequences.length > 1) {
                if (this.startingCard === this.cardGroup.sequences.length - 1) {
                    offset = 100 - this._mobileWidth;
                }
            }
            cardWidth = this._mobileWidth;
        }
        else {
            cardWidth = 100 / this.cardsInView;
            if (index >= this.startingCard && index < this.startingCard + this.cardsInView) {
                opacity = 1;
            }
            else {
                opacity = 0;
            }
        }
        const start = this.languageService.isRtl() ? 'right' : 'left';
        return {
            width: `${cardWidth}%`,
            [start]: `${cardWidth * (index - this.startingCard) + offset}%`,
            opacity,
        };
    }
    isDesktop() {
        return this.mediaservice.isDesktop();
    }
    isToDoCard(challenge) {
        return !challenge.Completed;
    }
}
ChallengesGroupController.$inject = ['$state', 'serverConstants', '$mdMedia', '$scope', 'trackingService', '_', 'mediaservice', 'languageService', 'challengesService'];
