<script setup lang="ts">
import { PageType } from '@/common/constants/enums';
import { RouteNames } from '@/router/routes/route-names.enum';
import { computed } from 'vue';
import { useNotificationsStore } from '@/store/notifications-store';
import { QTab } from 'quasar';
import { useSquareStore } from '@/store/square-store';
import { PageConsumer, usePagesStore } from '@/store/pages-store';
import { useRoute } from 'vue-router';
import { IscBadgeWrapper } from '@ui/components';

defineProps<{
  page: PageConsumer;
  index: number;
}>();

defineEmits<(eventName: 'set-tabs', index: number, el: QTab) => void>();

const todoActivitiesCount = computed(() => useNotificationsStore().todoActivities || 0);
const squareStore = useSquareStore();
const homePage = computed(() => usePagesStore().mainPages.find((p) => p.pageType === PageType.Home));
const route = useRoute();

const primarycolor = computed(() => {
  const design = squareStore.design;
  const baseColor = design.squareDesign?.baseColor1;

  if (route.params && route.params.pageGuid) {
    return squareStore.design.pageDesign?.find((page) => page.pageGuid === route.params.pageGuid)?.color || baseColor;
  }

  if (route.name === RouteNames.Home) {
    return design.pageDesign?.find((page) => page.pageGuid === homePage.value?.guid)?.color || baseColor;
  }

  return baseColor;
});

const getTabName = (page: PageConsumer) => {
  if (page.route && ([RouteNames.Forum, RouteNames.News, RouteNames.MainpageCustom] as string[]).includes(page.route)) {
    return `${page.route}-${page.routeParams?.pageGuid}`;
  }

  return page.route;
};

</script>

<template>
  <router-link
    :to="{ name: page.route, params: page.routeParams }"
    :data-v-step="page.id"
    class="navigation-bar-tab"
    :aria-label="page.name"
  >
    <q-tab
      :ref="(el: any) => { if (el) { $emit('set-tabs', index, el) } }"
      :name="getTabName(page)"
      content-class="text-uppercase text-weight-medium"
      no-caps
      :ripple="false"
      tabindex="-1"
    >
      <isc-badge-wrapper
        v-if="todoActivitiesCount > 0 && page.pageType === PageType.Activities"
        :notifications="todoActivitiesCount"
        class="gt-sm"
      />
      <q-icon
        v-if="page.icon"
        :size="$q.screen.gt.sm ? 'xs' : 'sm'"
        :name="`svguse:/images/page-icons/${page.icon}.svg#${page.icon}`"
        :class="{ 'q-mr-xs': $q.screen.gt.sm }"
      >
        <isc-badge-wrapper
          v-if="todoActivitiesCount > 0 && page.pageType === PageType.Activities"
          :notifications="todoActivitiesCount"
          class="navigation-bar-tab__badge-mobile-nav lt-md"
        />
      </q-icon>
      <span
        class="navigation-bar-tab__page-name"
        :class="$q.screen.lt.md ? 'text-caption text-capitalize' : 'text-subtitle2'"
      >
        {{ page.name }}
      </span>
    </q-tab>
  </router-link>
</template>


<style lang="scss">
.navigation-bar-tab {
  /* We need to manually overwrite the min-width because Quasar adds some extra unwanted padding on bigger screens */
  .q-tab .q-tab__content {
    min-width: fit-content;
  }

  .q-tab--active {
    color: v-bind(primarycolor);
  }
  @media (max-width: $breakpoint-sm-max) {
    &__page-name {
      display: block;
    }
    .q-tab {
      padding: 0 8px;
    }
  }

  // overrule quasar default 0.85 opacity on inactive items.
  .q-tab--inactive {
    opacity: unset;
  }
}
</style>
