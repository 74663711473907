import { UiModule } from '../../index';
UiModule.directive('iscInlineImageStimuli', ['iscStimuliDialogService', 'iscDomhelperservice', function (iscStimuliDialogService, iscDomhelperservice) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.addClass('isc-inline-image-stimuli');
                element[0].addEventListener('click', function (ev) {
                    if (iscDomhelperservice.elementMatches(ev.target, 'img:not(.emoji):not(.emojione)')) {
                        var img = ev.target;
                        iscStimuliDialogService.openInlineImageStimuli(img.src, ev.target);
                    }
                });
            },
        };
    }]);
