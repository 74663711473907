import { __awaiter } from "tslib";
export class ExternalLinkController {
    constructor($stateParams, squareService, $window, $state) {
        this.$stateParams = $stateParams;
        this.squareService = squareService;
        this.$window = $window;
        this.$state = $state;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const externalLink = yield this.squareService.getExternalLink(this.$stateParams.externallinkguid);
            if (externalLink) {
                this.$window.location.replace(externalLink);
            }
            else {
                this.$state.go('container.main.home');
            }
        });
    }
}
ExternalLinkController.$inject = ['$stateParams', 'squareService', '$window', '$state'];
