const resolveTab = (transition) => {
    const toState = transition.to();
    switch (toState.name) {
        case 'container.main.challenges':
        case 'container.main.challenges.default':
            return redirect(transition);
        default:
            return true;
    }
};
const redirect = (transition) => {
    const params = transition.params();
    return transition.router.stateService.target('container.main.challenges.all', params);
};
const func = ($transitions) => {
    $transitions.onBefore({ to: 'container.main.challenges.**' }, (transition) => resolveTab(transition));
};
func.$inject = ['$transitions'];
export const resolveActivityTab = func;
