import { UiModule } from './../../index';
var AccessibleIscButton = (function () {
    function AccessibleIscButton() {
        this.restrict = 'E';
    }
    AccessibleIscButton.prototype.link = function (_scope, element) {
        element.attr('tabindex', '-1');
        element.removeAttr('role');
    };
    return AccessibleIscButton;
}());
UiModule
    .directive('iscButton', function () { return new AccessibleIscButton(); })
    .directive('iscSaveButton', function () { return new AccessibleIscButton(); });
