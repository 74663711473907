import { shouldRedirectToSplashPage } from '@api/services/query/default/ParticipantService';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { watch } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import router from '..';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from '../routes/route-names.enum';
import { SquareStatus } from '@/common/constants/enums';
import { usePagesStore } from '@/store/pages-store';

let hasAgreedAlready = false;

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
  const toSplashPage = to.name === RouteNames.SplashPage;
  const toLogout = to.name === RouteNames.Logout;
  const noLoginRequired = !to.meta.requireLogin;

  if (hasAgreedAlready || toSplashPage || toLogout || noLoginRequired || !useAuthenticationStore().isAuthorized) {
    return;
  }

  const shouldRedirect = await shouldRedirectToSplashPage();
  if (shouldRedirect) {
    const allowedPagesInClosedSquare = [
      RouteNames.Incentives, RouteNames.IncentivesMySavingUpRewards, RouteNames.IncentivesMyIncentives, RouteNames.IncentiveLandingpageForm,
      RouteNames.Helpcenter, RouteNames.HelpcenterFaq, RouteNames.HelpcenterContact, RouteNames.HelpcenterCustom,
      RouteNames.Profile, RouteNames.ProfileEdit, RouteNames.ProfileActivity,
      RouteNames.More];

    const allowedPagesInArchivedSquare = [
      RouteNames.Incentives, RouteNames.IncentivesMySavingUpRewards, RouteNames.IncentivesMyIncentives, RouteNames.IncentiveLandingpageForm,
    ];

    const pageDetails = usePagesStore().pageByGuid(to.params.pageGuid as string);
    const customUsesExternal = pageDetails && pageDetails.route === RouteNames.MainpageCustom && pageDetails.openInNewTab;

    // Allow custom external urls to launch
    if (customUsesExternal) {
      window.open(pageDetails.customUrl, '_blank');
    }

    if (useSquareStore().info.status === SquareStatus.Closed
      && allowedPagesInClosedSquare.includes(to.name as RouteNames)
      && customUsesExternal == null) {
      return;
    }

    if (useSquareStore().info.status === SquareStatus.Archived
      && allowedPagesInArchivedSquare.includes(to.name as RouteNames)) {
      return;
    }

    return await router.replace({
      name: RouteNames.SplashPage,
      query: { redirectUrl: to.path },
    });
  }

  hasAgreedAlready = true;
  createSquareStatusWatcher();
};

// Watch the status of the square, if it changes the splashpage needs to be checked again
const createSquareStatusWatcher = () => {
  watch(() => useSquareStore().info.status, () => {
    hasAgreedAlready = false;
  });
};
