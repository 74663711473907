import * as angular from 'angular';
import { HttpService } from './http.service';
import { SquareService } from './square.service';
import { SsoService } from './sso.service';
import { AuthService } from './auth.service';
import { ActivityService } from './activity.service';
import { ActivitySequenceDataService } from './activitySequence.service';
import { ForumService } from './forum.service';
import { IncentiveService } from './incentive.service';
import { ParticipantService } from './participant.service';
import { ProjectService } from './project.service';
import { UserNotificationService } from './usernotification.service';
import { NewsDataService } from './news.service';
import { ContactService } from './contact.service';
import { PlatformNoticeService } from './platformnotice.service';
import { FeatureService } from './feature.service';
import { BaseDataService } from './base.data.service';
import { ConfigurationService } from './configuration.service';
import { MuxService } from './mux.service';
import { FileStorageService } from './fileStorage.service';
import { BeaconService } from './beacon.service';
import { PageService } from './page.service';
import { DiscussionLoungeService } from './discussionLounge.service';
import { DiscussionService } from './discussion.service';
angular
    .module('insitesApp.core')
    .service('httpService', HttpService)
    .service('basedataservice', BaseDataService)
    .service('authService', AuthService)
    .service('ssoService', SsoService)
    .service('activityService', ActivityService)
    .service('activitySequenceDataService', ActivitySequenceDataService)
    .service('forumService', ForumService)
    .service('incentiveService', IncentiveService)
    .service('participantService', ParticipantService)
    .service('projectService', ProjectService)
    .service('userNotificationService', UserNotificationService)
    .service('squareService', SquareService)
    .service('pageService', PageService)
    .service('newsDataService', NewsDataService)
    .service('contactService', ContactService)
    .service('platformnoticeService', PlatformNoticeService)
    .service('featureService', FeatureService)
    .service('configurationService', ConfigurationService)
    .service('muxService', MuxService)
    .service('beaconService', BeaconService)
    .service('fileStorageService', FileStorageService)
    .service('discussionService', DiscussionService)
    .service('discussionLoungeService', DiscussionLoungeService);
