import { __awaiter } from "tslib";
export class ContainerController {
    constructor(imagesFactory, iscPages, $state, squareFactory, participantService, mediaservice, featureToggleService, tourService, authService, _, $stateParams) {
        this.imagesFactory = imagesFactory;
        this.iscPages = iscPages;
        this.$state = $state;
        this.squareFactory = squareFactory;
        this.participantService = participantService;
        this.mediaservice = mediaservice;
        this.featureToggleService = featureToggleService;
        this.tourService = tourService;
        this.authService = authService;
        this._ = _;
        this.$stateParams = $stateParams;
        this.state = this.$state;
        this.isAuthorized = false;
        this.isMobileApp = this.mediaservice.isMobileApp;
        this.shouldShowFooter = this.featureToggleService.shouldShowFooter;
        this.shouldShowHeaderImage = this.featureToggleService.shouldShowHeaderImage;
        this.shouldShowNavigationMenu = this.featureToggleService.shouldShowNavigationMenu;
        this.padding = {
            top: 0,
        };
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isAuthorized = yield this.authService.isAuthorized();
            this.imagesFactory.applyImages();
            this.iscPages.init();
            if (this.isAuthorized) {
                yield this.authService.getSquareParticipantDetailsAndUpdate();
            }
            this.mediaservice.loadVideoScripts();
            if (this.$state.current.name.indexOf('container.main.profile') !== -1 && this.squareFactory.SsoEnabled) {
                this.$state.go('container.main.home');
            }
        });
    }
    get squareStatus() {
        return this.squareFactory.Status;
    }
    get isSquareActive() {
        return this.squareFactory.IsActive();
    }
    isPlatformTourStarted() {
        return this.tourService.isTourStarted;
    }
}
ContainerController.$inject = [
    'imagesFactory', 'iscPages', '$state', 'squareFactory',
    'participantService', 'mediaservice', 'featureToggleService',
    'tourService', 'authService', '_',
    '$stateParams',
];
