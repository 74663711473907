<template>
  <q-header class="mobile-header shadow-10">
    <square-not-active />
    <platform-notice />
    <skip-to-content />
    <q-toolbar class="mobile-header__main row justify-between overflow-hidden no-wrap">
      <div
        v-if="isHomePageNavigation"
        class="q-pr-md"
      >
        <header-square-logo v-if="false" />
      </div>
      <div v-else>
        <q-btn
          flat
          class="q-px-sm mobile-header__back-button"
          :icon="$q.lang.rtl ? 'fa fa-chevron-right' : 'fa fa-chevron-left'"
          @click="router.push({ name: RouteNames.More })"
        />
      </div>
      <header-consumer-logo
        :show-logo-top-bar="scrollUp"
        class="full-width"
      />
      <div
        v-show="mobileNavigationPageTitle && scrollUp === false"
        class="mobile-header__navigation-title overflow-hidden flex justify-start"
      >
        <div class="ellipsis text-bold text-subtitle1">
          {{ mobileNavigationPageTitle }}
        </div>
      </div>
      <div class="mobile-header__icons row no-wrap">
        <user-notification
          class="mobile-header__notifications"
          :is-mobile="true"
        />
        <profile-image />
      </div>
    </q-toolbar>
  </q-header>
  <q-scroll-observer
    axis="vertical"
    @scroll="(info) => scrollUp = info.direction === 'up'"
  />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useMobileNavigationInfo } from './composables/use-mobile-navigation';
import { ref } from 'vue';
import UserNotification from './user-notification.vue';
import PlatformNotice from '../platform-notice/platform-notice.vue';
import squareNotActive from '../square-not-active/square-not-active.vue';
import ProfileImage from './profile-logo.vue';
import HeaderConsumerLogo from './header-consumer-logo.vue';
import HeaderSquareLogo from './header-square-logo.vue';
import { RouteNames } from '@/router/routes/route-names.enum';
import SkipToContent from '../skip-to-content/skip-to-content.vue';

// Mobile navigation info
const { mobileNavigationPageTitle, isHomePageNavigation } = useMobileNavigationInfo();

const router = useRouter();
const scrollUp = ref(true);

</script>

<style lang="scss">
.mobile-header.q-header {
  z-index: 69; // Set page-header z-index lower than modal, higher than timeline
}
.mobile-header {
  background: linear-gradient(to right, var(--navigation-bar-start, $light) 0%, var(--navigation-bar-end, $light) 100%);

  &__main {
    color: var(--navigation-bar-text, $dark);
    padding: 12px;
    height: 65px;
  }

  &__square-logo {
    height: 35px;
  }

  &__home-link {
    padding: 0 12px 0 4px;
  }
}
</style>
