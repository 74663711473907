import * as angular from 'angular';
var ChallengeCardFooterController = (function () {
    function ChallengeCardFooterController() {
    }
    ChallengeCardFooterController.prototype.showAvailableCredits = function () {
        return !this.card.Completed && this.card.Credits > 0;
    };
    ChallengeCardFooterController.prototype.showEarnedCredits = function () {
        return this.card.Completed && this.card.Credits > 0;
    };
    ChallengeCardFooterController.prototype.showRewards = function () {
        return !this.card.Completed
            && this.card.RaffleRewards
            && angular.isArray(this.card.RaffleRewards)
            && this.card.RaffleRewards.length > 0;
    };
    ChallengeCardFooterController.prototype.showEndDate = function () {
        return !this.card.Completed || this.card.isDiscussionActivity;
    };
    ChallengeCardFooterController.prototype.showCompletionPoints = function () {
        return this.card.CompletionPoints > 0;
    };
    ChallengeCardFooterController.prototype.showSpotsLeft = function () {
        return this.card.CompletionTarget != null;
    };
    ChallengeCardFooterController.prototype.showMultiplePointsLabel = function () {
        if (this.card.CompletionPoints > 1 || this.card.Credits > 1) {
            return true;
        }
        return false;
    };
    ChallengeCardFooterController.prototype.spotsLeft = function () {
        return Math.max(this.card.CompletionTarget - this.card.ParticipantsCompleted, 0);
    };
    return ChallengeCardFooterController;
}());
export { ChallengeCardFooterController };
