var DownloadStimuliController = (function () {
    function DownloadStimuliController(iscStimuliService, iscSpinnerService) {
        this.iscStimuliService = iscStimuliService;
        this.iscSpinnerService = iscSpinnerService;
    }
    DownloadStimuliController.prototype.downloadStimuli = function () {
        var _this = this;
        this.iscSpinnerService.show('loading');
        this.iscStimuliService.downloadStimuli(this.stimulusId)
            .finally(function () {
            _this.iscSpinnerService.hide('loading');
        });
    };
    DownloadStimuliController.$inject = ['iscStimuliService', 'iscSpinnerService'];
    return DownloadStimuliController;
}());
export { DownloadStimuliController };
