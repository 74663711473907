import { __awaiter } from "tslib";
import { Utils } from '../../core/utils';
import { DateTime } from 'luxon';
import * as emojione from 'emojione';
import { IscDraftService } from 'isc-ui/dist/services/activity/draft.service';
import * as _ from 'lodash';
export class ConversationPostController {
    constructor($state, $stateParams, forumService, conversationService, serverConstants, conversationEventsService, languageService, featureToggleService, participantFactory, constantsfactory, squareFactory, mappingService, muxService, fileStorageService, $q, $scope) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.forumService = forumService;
        this.conversationService = conversationService;
        this.serverConstants = serverConstants;
        this.conversationEventsService = conversationEventsService;
        this.languageService = languageService;
        this.featureToggleService = featureToggleService;
        this.participantFactory = participantFactory;
        this.constantsfactory = constantsfactory;
        this.squareFactory = squareFactory;
        this.mappingService = mappingService;
        this.muxService = muxService;
        this.fileStorageService = fileStorageService;
        this.$q = $q;
        this.$scope = $scope;
        this.replyActive = false;
        this.stimuli = [];
        this.attachments = [];
        this.isComponentDestroyed = false;
        this.shouldShowConversationAttachment = this.featureToggleService.shouldShowConversationAttachment;
        this.buttonClicked = false;
        this.requiresNewSave = false;
        this.saveButtonClicked = false;
        this.isEditMode = false;
        this.addedStimulusFiles = [];
        this.addedAttachmentFiles = [];
        this.removedStimulusFiles = [];
        this.removedAttachmentFiles = [];
        this.lastBlurTimestamp = (new Date()).getTime();
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isComponentDestroyed = false;
            this.conversationEditorConfig = {
                showTitle: false,
                showTextEditor: true,
                showCaptionEditor: false,
                saveLabel: this.saveButtonText,
                actionButtonsDisabled: this.buttonClicked,
                uploadConfig: {
                    showImageUpload: true,
                    showVideoUpload: true,
                    showVideoLink: true,
                    showAttachment: true,
                    maxStimuli: this.serverConstants.validationConstants.conversationStimuliMaxNo,
                    maxAttachments: this.serverConstants.validationConstants.conversationAttachmentMaxNo,
                },
            };
            if (this.item != null && this.item.InDraftReply != null) {
                this.replyActive = true;
                this.message = emojione.toImage(this.item.InDraftReply.Message);
                this.stimuli = this.mappingService.mapConversationStimuliForConversationEditor(this.item.InDraftReply.Stimuli);
                this.attachments = this.mappingService.mapConversationAttachmentsForConversationEditor(this.item.InDraftReply.Attachments);
                this.previousSavedReply = this.message;
                this.previousSavedCaption = this.caption;
                this.isEditMode = false;
            }
            this.newStimulusFiles = [];
            this.newAttachmentFiles = [];
            yield this.setSaveLabel();
            IscDraftService.subscribeToDraftFileChanges(this.getDraftIdentifier(), this.onDraftFileChanges);
            this.checkPictureStimuli();
            this.author = {
                username: this.item.Username,
                picture: this.getAvatar(),
                link: this.getAuthorPublicProfileUrl(),
            };
            this.watchItemChange();
        });
    }
    watchItemChange() {
        if (!this.stopIdWatch) {
            this.$scope.$watch(() => this.item.Id, (oldVal, newVal) => __awaiter(this, void 0, void 0, function* () {
                if (oldVal === newVal) {
                    return;
                }
                yield this.$onInit();
            }));
        }
    }
    checkPictureStimuli() {
        const dt = DateTime.fromISO(this.item.DateCreated).plus({ minutes: 5 });
        if (dt < DateTime.utc()) {
            return;
        }
        if (this.item.Stimuli && this.item.Stimuli.length !== 0) {
            this.item.Stimuli.forEach((stimulus) => {
                if (stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image
                    && stimulus.IsBroken !== true) {
                    this.fileStorageService.checkStimulusUrl(stimulus.ThumbnailUrl, (broken) => {
                        stimulus.IsBroken = broken;
                    });
                }
            });
        }
        if (this.stimuli && this.stimuli.length !== 0) {
            this.stimuli.forEach((stimulus) => {
                if (stimulus.type === this.serverConstants.conversationStimuliTypeConstants.image
                    && stimulus.IsBroken !== true) {
                    this.fileStorageService.checkStimulusUrl(stimulus.thumbnailUrl, (broken) => {
                        stimulus.IsBroken = broken;
                    });
                }
            });
        }
    }
    onDraftFileChanges(param) {
        if (param && param.uid) {
            if (param.added) {
            }
            if (param.removed) {
            }
        }
        return true;
    }
    getSaveButtonText() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isEditMode) {
                return yield this.constantsfactory.getLabelValue('LabelForumPostSave');
            }
            return this.isTopMost(this.item) ?
                yield this.constantsfactory.getLabelValue('LabelForumReply') : yield this.constantsfactory.getLabelValue('LabelForumSendReply');
        });
    }
    setSaveLabel() {
        return __awaiter(this, void 0, void 0, function* () {
            this.saveButtonText = yield this.getSaveButtonText();
            this.conversationEditorConfig.saveLabel = this.saveButtonText;
        });
    }
    $onDestroy() {
        var _a;
        (_a = this.stopIdWatch) === null || _a === void 0 ? void 0 : _a.call(this);
        this.isComponentDestroyed = true;
        IscDraftService.storeDraft(this.getDraftIdentifier(), this.getDraftValue());
    }
    getRoleLabel(roleType) {
        return Utils.getRoleLabel(this.serverConstants.roleConstants, roleType);
    }
    getStatusLabel(statusType, suspended) {
        return Utils.getStatusLabel(this.serverConstants.registrationStatusConstants, statusType, suspended);
    }
    enterReply(item) {
        if (item.Level === this.serverConstants.squareConstants.maxReplyLevel) {
            this.message = `<span class="mention" data-username="${item.Username}">@${item.Username}</span>&nbsp;`;
        }
        this.saveButtonClicked = false;
        this.isEditMode = false;
        this.replyActive = true;
        this.conversationEventsService.conversationChangeStarted(this.$stateParams.conversationGuid);
    }
    cancelReply() {
        IscDraftService.broadcastDraftRemove(undefined, this.getDraftIdentifier());
        this.replyActive = false;
        this.message = '';
        this.stimuli.splice(0);
        this.attachments.splice(0);
        this.conversationEventsService.conversationChangeEnded(this.$stateParams.conversationGuid);
        this.isEditMode = false;
    }
    getAvatar() {
        return this.conversationService.getAvatar(this.item);
    }
    reportAsInappropriate() {
        this.conversationService.reportAsInappropriate();
    }
    sendReply(reply) {
        return __awaiter(this, void 0, void 0, function* () {
            this.saveButtonClicked = true;
            this.replyActive = false;
            const replyStartDateTime = DateTime.utc();
            const guid = reply.Guid;
            let parentMessage = reply.ParentMessage;
            if (!this.isEditMode) {
                parentMessage = reply.Guid;
            }
            const clonedItem = angular.copy(this.item);
            clonedItem.Guid = guid;
            clonedItem.ParentMessage = parentMessage;
            const replyGuid = yield this.conversationService.sendForumReply(clonedItem, this.message, this.stimuli, this.attachments, this.newAttachmentFiles, this.serverConstants.conversationElementTypeConstants.reply);
            if (this.stimuli &&
                this.stimuli.length &&
                this.stimuli.find((s) => s.type === this.serverConstants.conversationStimuliTypeConstants.video && !s.thumbnailUrl)) {
                const videos = this.stimuli.filter((s) => s.type === this.serverConstants.conversationStimuliTypeConstants.video);
                const stimulusProgressList = this.mappingService.mapConversationStimuliForStimulusProgressList(videos);
                this.conversationService.createAndShowVideoUploadTask(replyGuid, stimulusProgressList);
            }
            this.cancelReply();
            if (!this.isComponentDestroyed && replyGuid) {
                this.conversationService.jumpToReplyForum(replyGuid, replyStartDateTime);
            }
            this.conversationEventsService.conversationChangeEnded(this.$stateParams.conversationGuid);
            this.isEditMode = false;
            if (replyGuid !== undefined && replyGuid !== '') {
                IscDraftService.broadcastDraftRemove(undefined, this.getDraftIdentifier());
            }
        });
    }
    onHeartClick() {
        return __awaiter(this, void 0, void 0, function* () {
            this.buttonClicked = true;
            yield this.conversationService.onForumPostHeartClick(this.item);
            this.buttonClicked = false;
        });
    }
    mentionSelect(item) {
        if (item) {
            return `<span data-username="${item.label}">@${item.label}</span>&nbsp;`;
        }
        return '';
    }
    ownPost() {
        return this.item.SquareParticipantGuid === this.participantFactory.Guid;
    }
    mentionSearch(term) {
        return __awaiter(this, void 0, void 0, function* () {
            const pageGuid = this.$stateParams.pageGuid;
            if (term) {
                return yield this.forumService.getMentionUsersForConversationForForum(pageGuid, term, this.item.Guid);
            }
            return this.$q(() => undefined);
        });
    }
    isTopMost(item) {
        return item
            && item.Level === 0;
    }
    hasEmptyMessage(item) {
        return item
            && item.Message === '';
    }
    hasAttachments(item) {
        return item
            && item.Attachments.length > 0;
    }
    hasStimuli(item) {
        return item
            && item.Stimuli.length > 0;
    }
    isLtr() {
        return this.languageService.isLtr();
    }
    isRtl() {
        return this.languageService.isRtl();
    }
    isStimuliDownloadSupported(stimuli) {
        return stimuli.Type === this.serverConstants.conversationStimuliTypeConstants.image ||
            stimuli.Type === this.serverConstants.conversationStimuliTypeConstants.video;
    }
    isEditable() {
        return this.item && this.item.IsEditable;
    }
    editPostClick() {
        return __awaiter(this, void 0, void 0, function* () {
            this.message = this.item.Message;
            this.stimuli = this.mappingService.mapConversationStimuliForConversationEditor(this.item.Stimuli);
            this.attachments = this.mappingService.mapConversationAttachmentsForConversationEditor(this.item.Attachments);
            this.isEditMode = true;
            yield this.setSaveLabel();
        });
    }
    getUploadProgress(stimulus) {
        var _a;
        let stimulusUploadProgress;
        if (stimulus.Value &&
            stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.video &&
            !stimulus.ThumbnailUrl) {
            let stimulusValue = stimulus.Value;
            if (this.squareFactory.VideoStorage === this.serverConstants.clientVideoStorageConstants.human8MediaService) {
                stimulusValue = (_a = stimulus.Guid) !== null && _a !== void 0 ? _a : stimulus.Value;
            }
            stimulusUploadProgress = {
                stimulusValue,
                progress: this.squareFactory.VideoStorage === this.serverConstants.clientVideoStorageConstants.mux
                    ? this.muxService.getStimulusUploadProgress(stimulus.Value)
                    : this.fileStorageService.getStimulusUploadProgress(stimulus.Guid),
            };
            this.conversationService.updateStimuliUploadTaskProgress(this.item.Guid, stimulusUploadProgress);
        }
        if (stimulus.Guid &&
            stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image &&
            !stimulus.ThumbnailUrl) {
            stimulusUploadProgress = {
                stimulusValue: stimulus.Guid,
                progress: this.fileStorageService.getStimulusUploadProgress(stimulus.Guid),
            };
            this.conversationService.updateStimuliUploadTaskProgress(this.item.Guid, stimulusUploadProgress);
        }
        return stimulusUploadProgress ? stimulusUploadProgress.progress : null;
    }
    getAuthorPublicProfileUrl() {
        let result = null;
        if (this.squareFactory.IsPublicProfileEnabled
            && this.item.SquareParticipantRole === this.serverConstants.roleConstants.participant
            && this.item.SquareParticipantStatus !== this.serverConstants.registrationStatusConstants.unsubscribed
            && this.item.SquareParticipantStatus !== this.serverConstants.registrationStatusConstants.anonymized
            && this.item.Suspended !== true) {
            result = this.$state.href('container.main.publicProfile', { username: this.item.Username });
        }
        return result;
    }
    getDraftValue() {
        return this.replyActive
            ? {
                message: this.message,
                stimuli: this.stimuli,
                attachments: this.attachments,
            }
            : undefined;
    }
    setDraftValue(draft) {
        if (draft && draft.message && draft.message !== '') {
            this.replyActive = true;
            this.message = draft.message;
        }
    }
    getDraftIdentifier() {
        return `${this.item.Guid}_${this.participantFactory.Guid}`;
    }
    autosave() {
        IscDraftService.broadcastDraftUpdate(undefined, this.getDraftIdentifier(), this.getDraftValue());
    }
    retryStimulus(newStimulus) {
        if (newStimulus && newStimulus.guid) {
            let foundStimulus = null;
            if (this.item.Stimuli && this.item.Stimuli.length) {
                _.forEach(this.item.Stimuli, (stimulus) => {
                    if (stimulus.Guid === newStimulus.guid) {
                        foundStimulus = stimulus;
                        return false;
                    }
                });
            }
            if (foundStimulus && foundStimulus.IsBroken === true && foundStimulus.IsBrokenAndBelongsToCurrentUser === true) {
                const stimuli = [];
                stimuli.push(newStimulus);
                this.conversationService.prepareStimuliAndNewStimuli(stimuli)
                    .then((result) => {
                    if (result && result.stimuli && result.stimuli.length > 0) {
                        result.stimuli.forEach((stimulus) => {
                            if (stimulus.fileContentToBeRemoved) {
                                stimulus.file = undefined;
                                this.conversationService.updateStimulusAfterNewUpload(stimulus);
                            }
                        });
                        foundStimulus.IsBroken = undefined;
                        if (foundStimulus.Type === this.serverConstants.conversationStimuliTypeConstants.video) {
                            foundStimulus.Value = result.stimuli[0].id;
                        }
                        else if (foundStimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image) {
                            foundStimulus.Url = result.stimuli[0].url;
                            foundStimulus.ThumbnailUrl = result.stimuli[0].thumbnailUrl;
                        }
                    }
                });
            }
        }
    }
}
ConversationPostController.$inject = ['$state', '$stateParams',
    'forumService', 'conversationService', 'serverConstants',
    'conversationEventsService', 'languageService', 'featureToggleService',
    'participantFactory', 'constantsfactory', 'squareFactory',
    'mappingService', 'muxService', 'fileStorageService', '$q', '$scope'];
