import { __awaiter } from "tslib";
import * as angular from 'angular';
import * as _ from 'lodash';
import { ProfileStateEnum } from './profile-state.enum';
const avatarCropTemplate = "<md-dialog isc-translate=\"[aria-label](LabelAriaCropDialog)\" class=\"insites-dialog avatar-crop\">\n  <md-toolbar hide-gt-sm>\n    <div class=\"md-toolbar-tools\">\n      <md-button class=\"md-icon-button\" ng-click=\"vm.cancel()\" isc-translate=\"[aria-label](LabelAriaCancel)\">\n        <i class=\"typcn typcn-times\"></i>\n      </md-button>\n    </div>\n  </md-toolbar>\n  <md-button hide-lt-md md-no-ink class=\"md-icon-button close-button\" ng-click=\"vm.cancel()\" isc-translate=\"[aria-label](LabelAriaCancel)\">\n    <i class=\"typcn typcn-times\"></i>\n  </md-button>\n  <md-dialog-content>\n    <div class=\"cropper\">\n      <img-crop image=\"vm.file | ngfDataUrl\" crop-area=\"vm.cropArea\"></img-crop>\n    </div>\n    <div layout=\"row\" layout-align=\"center start\" class=\"save-button\">\n      <md-button ng-click=\"vm.save()\" ng-disabled=\"vm.saving\" class=\"md-primary md-raised isc-button\" isc-translate=\"(LabelAvatarCropSave)\"></md-button>\n    </div>\n  </md-dialog-content>\n</md-dialog>";
export class ProfileController {
    constructor($mdDialog, participantFactory, $state, activityService, mediaservice, serverConstants, authService, featureToggleService, squareFactory, userService, iscConfigurationService, participantService) {
        this.$mdDialog = $mdDialog;
        this.participantFactory = participantFactory;
        this.$state = $state;
        this.activityService = activityService;
        this.mediaservice = mediaservice;
        this.serverConstants = serverConstants;
        this.authService = authService;
        this.featureToggleService = featureToggleService;
        this.squareFactory = squareFactory;
        this.userService = userService;
        this.iscConfigurationService = iscConfigurationService;
        this.participantService = participantService;
        this.cleanupFunctions = [];
        this.participant = this.participantFactory;
        this.progressPercentage = 0;
        this.profileTip = '';
        this.profileActivities = [];
        this.invalidSize = false;
        this.invalidType = false;
        this.profileState = ProfileStateEnum.Unknown;
        this.ProfileStateEnum = ProfileStateEnum;
        this.shouldShowLogoutButton = this.featureToggleService.shouldShowLogoutButton;
        this.sampleStatusCompleted = this.serverConstants.sampleStatusConstants.completed;
        this.squareInfo = this.squareFactory;
        this.fileConfiguration = [];
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
            this.iscConfigurationService.getFileConfiguration()
                .then((response) => {
                this.fileConfiguration = response;
                this.imageStimuliMaxSizeBytes = _.find(this.fileConfiguration, (config) => config.FileType === this.fileTypeConstants.image).MaxFileSizeMb * Math.pow(1024, 2);
                const { mimeTypes, patterns, extensions } = this.iscConfigurationService.getAcceptedMimeTypesAndExtensions(this.fileConfiguration, [this.fileTypeConstants.image]);
                this.acceptedMimeTypes = mimeTypes.toString();
                this.acceptedPatterns = `'${patterns.toString()}'`;
                this.acceptedExtensions = extensions.toString().toLowerCase();
            });
            const setAndHandleProfileActivities = (activities) => {
                this.profileActivities = activities;
                this.handleProfileActivities();
            };
            const setAndUpdateProgressPercentage = (progress) => {
                const progressPercentage = Math.round(progress);
                this.progressPercentage = isNaN(progressPercentage) ? 0 : progressPercentage;
                this.updateProfileState();
                this.updateTip();
                this.goToFirstIncompleteActivity();
            };
            const activitiesSubscription = this.activityService.profileActivities
                .subscribe(setAndHandleProfileActivities);
            const progressSubscription = this.activityService.progress
                .subscribe(setAndUpdateProgressPercentage);
            const profileClosedSubscription = this.participantService.profileEditorClosed.subscribe((value) => {
                if (value) {
                    this.goToFirstIncompleteActivity();
                }
            });
            this.cleanupFunctions.push(activitiesSubscription, progressSubscription, profileClosedSubscription);
            if (!this.progressPercentage || this.progressPercentage === 0) {
                this.activityService.progress.toPromise()
                    .then(setAndUpdateProgressPercentage);
            }
            if (!this.profileActivities || this.profileActivities.length === 0) {
                this.activityService.profileActivities.toPromise()
                    .then(setAndHandleProfileActivities);
            }
        });
    }
    handleProfileActivities() {
        this.onActivitiesLoaded();
        this.updateProfileState();
        this.updateTip();
    }
    onActivitiesLoaded() {
        if (!this.isSubPageOpen()) {
            this.goToFirstIncompleteActivity();
        }
    }
    $onDestroy() {
        _.each(this.cleanupFunctions, (cleanup) => {
            cleanup();
        });
    }
    goToFirstIncompleteActivity() {
        if (this.mediaservice.isDesktop() || this.mediaservice.isTablet) {
            const firstIncompleteActivity = _.find(this.profileActivities, (activity) => activity.CompletedPercentage < 100);
            if (firstIncompleteActivity) {
                this.goToActivity(firstIncompleteActivity);
            }
        }
    }
    getExtensionFromFilename(filename) {
        let result = '';
        if (filename) {
            result = filename.split('.').pop().toLowerCase();
        }
        return result;
    }
    selectFile($files) {
        this.invalidSize = false;
        this.invalidType = false;
        if ($files && $files.length > 0) {
            const file = $files.pop();
            this.invalidType = this.acceptedExtensions.indexOf(this.getExtensionFromFilename(file.name)) === -1
                || (file.type && this.acceptedMimeTypes.indexOf(file.type) === -1);
            this.invalidSize = file.size > this.imageStimuliMaxSizeBytes;
            if (!this.invalidType && !this.invalidSize) {
                this.$mdDialog.show({
                    controller: 'AvatarCropController',
                    controllerAs: 'vm',
                    template: avatarCropTemplate,
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: true,
                    locals: {
                        file,
                        user: this.participant,
                    },
                }).then((response) => {
                    if (response) {
                        this.participantFactory.Avatar = `${response.Uri}?t=${new Date(response.DateModified).getTime()}`;
                        this.participantFactory.HasProfileImage = true;
                        this.updateProfileState();
                        this.updateTip();
                    }
                });
            }
        }
    }
    updateProfileState() {
        if (this.progressPercentage === 100) {
            this.profileState = ProfileStateEnum.Complete;
        }
        else {
            const allActivitiesComplete = !_.some(this.profileActivities, (act) => act.SampleStatus !== this.sampleStatusCompleted);
            if (this.isParticipantOnAnonymousSquare()) {
                if (allActivitiesComplete) {
                    this.profileState = ProfileStateEnum.Complete;
                }
                else {
                    this.profileState = ProfileStateEnum.IncompleteActivities;
                }
            }
            else {
                if (allActivitiesComplete) {
                    this.profileState = ProfileStateEnum.IncompleteAvatar;
                }
                else if (this.participant.HasProfileImage) {
                    this.profileState = ProfileStateEnum.IncompleteActivities;
                }
                else {
                    this.profileState = ProfileStateEnum.IncompleteAvatarAndActivities;
                }
            }
        }
    }
    updateTip() {
        switch (this.profileState) {
            case ProfileStateEnum.Complete:
                this.profileTip = '(LabelProfileTipComplete)';
                break;
            case ProfileStateEnum.IncompleteAvatar:
                this.profileTip = '(LabelProfileTipUploadAvatar)';
                break;
            case ProfileStateEnum.IncompleteActivities:
                this.profileTip = '(LabelProfileTipCompleteActivities)';
                break;
            case ProfileStateEnum.IncompleteAvatarAndActivities:
                this.profileTip = '(LabelProfileTipUploadAvatarAndCompleteActivities)';
                break;
        }
    }
    toggleActivities() {
        if (this.profileActivities && this.profileActivities.length > 4) {
            this.showActivities = true;
        }
        else {
            this.showActivities = false;
        }
    }
    getLimit() {
        let limit = 4;
        if (this.showActivities) {
            limit = Infinity;
        }
        return limit;
    }
    logout() {
        this.authService.signOut(true);
    }
    isSubPageOpen() {
        const currentState = this.$state.current.name;
        return currentState !== 'container.main.profile'
            && currentState.indexOf('container.main.profile') === 0;
    }
    goToActivity(activity) {
        if (this.lastProfileActivityGuid !== activity.Guid) {
            this.lastProfileActivityGuid = activity.Guid;
            this.activityService.getProfileSurveyLink(activity.Guid)
                .then((url) => {
                this.$state.go('container.main.profile.activity', {
                    activityGuid: activity.Guid,
                    surveyUrl: url,
                });
            });
        }
    }
    goToEditPage() {
        this.$state.go('container.main.profile.edit');
    }
    isParticipantOnAnonymousSquare() {
        return this.userService.hasParticipantRole() && this.squareFactory.Anonymous;
    }
    get currentRoute() {
        return this.$state.current.name;
    }
}
ProfileController.$inject = ['$mdDialog', 'participantFactory', '$state', 'activityService',
    'mediaservice', 'serverConstants', 'authService', 'featureToggleService',
    'squareFactory', 'userService', 'iscConfigurationService', 'participantService'];
