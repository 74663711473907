export class DoesNotContainDirective {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {
            otherModelValue: '=doesNotContain',
        };
    }
    link(scope, element, attrs, ngModel) {
        ngModel.$validators.doesNotContain = (modelValue) => {
            if (modelValue && scope.otherModelValue) {
                return modelValue.indexOf(scope.otherModelValue) === -1;
            }
            return true;
        };
        scope.$watch('otherModelValue', () => {
            ngModel.$validate();
        });
    }
}
