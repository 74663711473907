var IscModalDialogController = (function () {
    function IscModalDialogController($transclude) {
        this.$transclude = $transclude;
    }
    IscModalDialogController.prototype.$onInit = function () {
        this.hasToolbar = this.$transclude.isSlotFilled('toolbar');
        this.hasActions = this.$transclude.isSlotFilled('actions');
    };
    IscModalDialogController.$inject = ['$transclude'];
    return IscModalDialogController;
}());
export { IscModalDialogController };
