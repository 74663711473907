var FocusedNodeWorkaround = (function () {
    function FocusedNodeWorkaround($timeout) {
        this.$timeout = $timeout;
    }
    FocusedNodeWorkaround.prototype.init = function (richInput) {
        this.richInput = richInput;
    };
    FocusedNodeWorkaround.prototype.postLink = function () {
        var _this = this;
        this.$timeout(function () { return applyWorkaroundForWrongFocusedNode(_this.richInput.getElement()); }, 100);
    };
    FocusedNodeWorkaround.$inject = ['$timeout'];
    return FocusedNodeWorkaround;
}());
export default FocusedNodeWorkaround;
function applyWorkaroundForWrongFocusedNode($element) {
    var editableElement = $element[0].querySelector('[contenteditable]');
    var editableParent = editableElement.parentElement;
    editableParent.addEventListener('click', function (e) {
        var sel = document.getSelection();
        if (sel.focusNode === editableParent) {
            var newRange = document.createRange();
            var firstChildOfEditableNode = editableElement.firstChild;
            newRange.selectNodeContents(firstChildOfEditableNode || editableElement);
            newRange.collapse(false);
            sel.removeAllRanges();
            sel.addRange(newRange);
        }
    });
}
