import { __awaiter } from "tslib";
export class PublicProfileController {
    constructor(participantService, _, participantFactory, squareFactory, $state, $stateParams) {
        this.participantService = participantService;
        this._ = _;
        this.participantFactory = participantFactory;
        this.squareFactory = squareFactory;
        this.$state = $state;
        this.$stateParams = $stateParams;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.squareFactory.IsPublicProfileEnabled) {
                this.$state.go('container.main.home');
            }
            this.participantFactory.loadingPromise.then(() => {
                this.fetchParticipantDetails();
            });
        });
    }
    fetchParticipantDetails() {
        this.participantService.SquareParticipantPublicDetails(this.$stateParams.username).then((response) => {
            if (!response) {
                this.$state.go('container.main.home');
            }
            else {
                this.participant = response;
                if (response.HasProfileImage) {
                    this.avatar = this.participantFactory.getParticipantImageUrl(response.SquareParticipantGuid, new Date().getTime());
                }
                else {
                    this.avatar = this.participantFactory.DefaultAvatar;
                }
            }
        }).catch(() => {
            this.$state.go('container.main.home');
        });
    }
    get showTotalActivitiesCount() {
        return this.participant && this.participant.ActivitiesCompleted;
    }
    get showTotalContributionsCount() {
        return this.participant && this.participant.ActivitiesSubmissions;
    }
    get showAcceptedContributionsCount() {
        return this.participant && this.participant.ActivitiesAcceptedSubmissions;
    }
}
PublicProfileController.$inject = ['participantService', '_', 'participantFactory', 'squareFactory', '$state', '$stateParams'];
