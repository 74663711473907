import { getWrappedAngularComponent } from '@/angular/vue-angular-helpers/wrapper';
import { PageType } from '@/common/constants/enums';
import { usePagesStore } from '@/store/pages-store';
import { isEqual } from 'lodash-es';
import { RouteParams, RouteRecordRaw } from 'vue-router';
import { RouteNames } from '../route-names.enum';
import { guidRegex } from '@/router/router.constants';

export const incentivesRoute: RouteRecordRaw = {
  name: RouteNames.Incentives,
  path: '/incentives',
  component: () => import('@/pages/incentives/incentives-page.vue'),
  meta: {
    requireLogin: true,
    title: '(LabelWindowTitleIncentives)',
  },
  beforeEnter: async (to) => {
    const pagesStore = usePagesStore();
    const incentivePages = pagesStore.incentivePages;
    if (incentivePages.length <= 0) {
      return false;
    }

    if (incentivePages[0].pageType === PageType.IncentivesCustom) {
      if (to.name !== incentivePages[0].route || !isEqual(to.params, incentivePages[0].routeParams)) {
        return { name: incentivePages[0].route, params: incentivePages[0].routeParams };
      }
    }
    if (to.name !== incentivePages[0].route) {
      return { name: incentivePages[0].route };
    }
  },
  children: [
    {
      name: RouteNames.IncentivesCustom,
      path: `custom/:pageGuid(${guidRegex})`,
      component: () => import('@/pages/custom-page.vue'),
      meta: {
        requireLogin: true,
        title: (routeParams: RouteParams) => {
          const pagesStore = usePagesStore();
          return pagesStore.pageByGuid(routeParams.pageGuid as string)?.name || '(LabelWindowTitleCustom)';
        },
      },
    },
    {
      name: RouteNames.IncentivesMyIncentives,
      path: 'myincentive',
      component: getWrappedAngularComponent('myIncentiveComponent'),
      meta: {
        requireLogin: true,
        title: '(LabelWindowTitleMyIncentive)',
        mobileTitle: '(LabelMyIncentive)',
      },
    },
    {
      name: RouteNames.IncentivesMySavingUpRewards,
      path: 'mysavinguprewards',
      component: () => import('@/pages/incentives/incentives-saving-up-page.vue'),
      meta: {
        requireLogin: true,
        title: '(LabelWindowTitleMyIncentive)',
        mobileTitle: '(LabelMyIncentive)',
      },
    },
  ],
};
