import { __awaiter } from "tslib";
import { CacheService } from '../../core/services/cache.service';
import { PlatformNoticeOption } from '../../core/models/platformNoticeOption';
export class PlatformNoticeController {
    constructor($state, mediaservice, platformnoticeService, userService) {
        this.$state = $state;
        this.mediaservice = mediaservice;
        this.platformnoticeService = platformnoticeService;
        this.userService = userService;
        this.state = this.$state;
        this.isMobileApp = this.mediaservice.isMobileApp;
        this.showPlatformNotice = false;
        this.platformNoticeOption = null;
        this.canUpdatedPadding = !this.mediaservice.isMobileApp;
        this.updatePadding = () => {
            if (this.canUpdatedPadding) {
                this.canUpdatedPadding = false;
                if (!this.mediaservice.isDesktop() && this.$state.current.name === 'container.main.home') {
                    this.padding.top = 0;
                    this.canUpdatedPadding = true;
                }
                else {
                    this.padding.top = 56;
                    if (this.mediaservice.isDesktop()) {
                        window.setTimeout(() => {
                            const platformNotice = document.querySelector('#platform-notice');
                            const height = platformNotice ? platformNotice.offsetHeight : 0;
                            this.padding.top = this.padding.top + height;
                            this.canUpdatedPadding = true;
                        });
                    }
                    else {
                        this.canUpdatedPadding = true;
                    }
                }
            }
        };
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.platformNoticeOption =
                CacheService.getCacheValue(`${PlatformNoticeOption.KEY}_${this.userService.email()}`);
            this.platformNotice = yield this.platformnoticeService.displayNotice();
            if (this.platformNotice) {
                this.showPlatformNotice =
                    !this.platformNoticeOption ||
                        !this.platformNoticeOption.value ||
                        this.platformNoticeOption.value !== this.platformNotice.customMessage;
            }
            this.addListeners();
            this.updatePadding();
        });
    }
    addListeners() {
        window.addEventListener('resize', this.updatePadding);
    }
    closePlatformNotice() {
        this.platformNoticeOption = new PlatformNoticeOption();
        this.platformNoticeOption.key = `${PlatformNoticeOption.KEY}_${this.userService.email()}`;
        this.platformNoticeOption.value = this.platformNotice.customMessage;
        CacheService.setCache(this.platformNoticeOption);
        this.showPlatformNotice = false;
        this.updatePadding();
    }
    $onDestroy() {
        window.removeEventListener('resize', this.updatePadding);
    }
}
PlatformNoticeController.$inject = [
    '$state', 'mediaservice', 'platformnoticeService', 'userService',
];
