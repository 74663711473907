import { UiModule } from './../../../../../index';
var nwOutdent = "<button class=\"nw-button fa-outdent\" title=\"Outdent\" ng-click=\"vm.outdent()\" ng-disabled=\"vm.editMode || vm.disabled\">'Outdent\n</button>";
UiModule
    .component('iscNwOutdent', {
    template: nwOutdent,
    controller: OutdentController,
    controllerAs: 'vm',
    bindings: {
        execCommand: '=',
        editMode: '=',
        disabled: '=',
        content: '=',
    },
});
OutdentController.$inject = ['iscDomhelperservice', '$element'];
function OutdentController(iscDomhelperservice, $element) {
    var vm = this;
    vm.outdent = function () {
        iscDomhelperservice.outdentInNgWig();
    };
}
