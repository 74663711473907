import { BaseDataService } from './base.data.service';
export class PageService extends BaseDataService {
    constructor(httpService) {
        super(httpService);
    }
    listPageConsumer() {
        return this.httpService.get({
            url: '/PageService/ListPageConsumer',
        });
    }
    getPageDetails(pageGuid) {
        return this.httpService.get({
            url: '/PageService/SelectPage',
            params: {
                Guid: pageGuid,
            },
        }).then((response) => response.data.Detail);
    }
    getFaqPageDetails(pageGuid) {
        return this.httpService.get({
            url: '/PageService/GetFaqPageDetails',
            params: {
                Guid: pageGuid,
                OnlyFiltered: true,
            },
        });
    }
    allowedToNavigateToPage(guid) {
        return this.getOrCached({
            url: '/PageService/AllowedToNavigateToPage',
            params: { pageGuid: guid },
        }, BaseDataService.SECONDS_ONE_HOUR).then((response) => response.data);
    }
}
PageService.$inject = ['httpService'];
