var IscUIUtils = (function () {
    function IscUIUtils() {
    }
    IscUIUtils.isActivityScoutDiscussionType = function (activityType, serverConstants) {
        var result = activityType
            && serverConstants
            && serverConstants.squareActivityTypeConstants
            && (Number(activityType) === serverConstants.squareActivityTypeConstants.publicScoutResearch ||
                Number(activityType) === serverConstants.squareActivityTypeConstants.privateScoutResearch);
        return result;
    };
    IscUIUtils.isActivityDiscussionNewType = function (activityType, serverConstants) {
        var result = activityType
            && serverConstants
            && serverConstants.squareActivityTypeConstants
            && (Number(activityType) === serverConstants.squareActivityTypeConstants.observation ||
                Number(activityType) === serverConstants.squareActivityTypeConstants.discussion20Research);
        return result;
    };
    return IscUIUtils;
}());
export { IscUIUtils };
