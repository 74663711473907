import {
  PlatformNoticeResponse,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const getCurrentPlatformNotice = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<PlatformNoticeResponse>('/PlatformNoticeService/GetCurrentPlatformNotice', undefined, config);

export const getCurrentPlatformNoticeDisplay = (square: boolean, language?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<PlatformNoticeResponse>('/PlatformNoticeService/GetCurrentPlatformNoticeDisplay', { square, language }, config);
