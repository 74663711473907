<template>
  <q-input
    :model-value="props.searchInput"
    :dense="dense"
    :outlined="outlined"
    :clearable="clearable"
    borderless
    :placeholder="placeholder"
    input-class="text-subtitle1"
    for="search"
    class="isc-search-filter"
    @update:model-value="(inputValue: string | number | null) => emit('search-input', inputValue?.toString())"
  >
    <template #prepend>
      <q-icon
        size="xs"
        name="fas fa-magnifying-glass"
        color="grey-6"
        class="isc-search-filter q-pa-sm"
      />
    </template>
  </q-input>
</template>

<script lang="ts" setup>
export interface Props {
  searchInput?: string;
  outlined?: boolean;
  dense?: boolean;
  placeholder?: string;
  clearable?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  searchInput: undefined,
  outlined: false,
  dense: true,
  placeholder: 'Search',
  clearable: false,
});

const emit = defineEmits<(e: 'search-input', payload: string | undefined) => void>();
</script>
