import { __extends } from "tslib";
import { HotkeySubscription } from '../HotKeySubscription';
var OutdentHotkey = (function (_super) {
    __extends(OutdentHotkey, _super);
    function OutdentHotkey($document) {
        var _this = _super.call(this) || this;
        _this.$document = $document;
        _this.keyCode = 9;
        _this.shiftKey = true;
        return _this;
    }
    OutdentHotkey.prototype.handler = function (event) {
        this.outdent(event);
    };
    OutdentHotkey.prototype.outdent = function (event) {
        this.$document[0].execCommand('outdent', false);
        event.preventDefault();
    };
    OutdentHotkey.$inject = ['$document'];
    return OutdentHotkey;
}(HotkeySubscription));
export { OutdentHotkey };
