import FocusedNodeWorkaround from './FocusedNodeWorkaround';
import ValidateMaxLength from './validateMaxLength/ValidateMaxLength';
import UndoRedoHotkeys from './undoRedo/UndoRedoHotkeys';
import IndentOutdentHotkeys from './indentOutdent/IndentOutdentHotkeys';
import PastedContentHandler from './PastedContentHandler';
import OutdentOnBackspace from './outdentOnBackSpace/OutdentOnBackspace';
export default [
    FocusedNodeWorkaround,
    ValidateMaxLength,
    UndoRedoHotkeys,
    IndentOutdentHotkeys,
    PastedContentHandler,
    OutdentOnBackspace,
];
