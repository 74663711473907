import { __awaiter, __generator } from "tslib";
import Hls from 'hls.js';
var VideoStimuliDialogController = (function () {
    function VideoStimuliDialogController(videoUrl, stimulusGuid, $mdDialog, $window, iscStimuliService, $scope, $element, allowDownload) {
        this.videoUrl = videoUrl;
        this.stimulusGuid = stimulusGuid;
        this.$mdDialog = $mdDialog;
        this.$window = $window;
        this.iscStimuliService = iscStimuliService;
        this.$scope = $scope;
        this.$element = $element;
        this.allowDownload = allowDownload;
        this.mediaPlayer = null;
        this.mediaPlayerOptions = {
            logo: {
                enabled: false,
            },
            controls: true,
            autoplay: true,
            heuristicProfile: 'QuickStart',
        };
        this.init();
    }
    VideoStimuliDialogController.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var videoElement;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        videoElement = this.$element[0].querySelector('video');
                        videoElement.id = VideoStimuliDialogController.getUniqueIdForVideoElement();
                        return [4, this.initMediaPlayer(videoElement)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    VideoStimuliDialogController.prototype.initMediaPlayer = function (videoElement) {
        return __awaiter(this, void 0, void 0, function () {
            var theUrl, _a, videoType, hls;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.videoUrl;
                        if (_a) return [3, 2];
                        return [4, this.iscStimuliService.getStimuliStreamingUrl(this.stimulusGuid)];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        theUrl = _a;
                        videoType = VideoStimuliDialogController.getMediaPlayerSourceType(theUrl);
                        if (videoType !== VideoStimuliDialogController.defaultMediaServicesType
                            && !videoElement.canPlayType(videoType)) {
                            if (Hls.isSupported()) {
                                videoElement.controls = true;
                                hls = new Hls();
                                hls.loadSource(theUrl);
                                hls.attachMedia(videoElement);
                                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                                    videoElement.play();
                                });
                            }
                        }
                        else {
                            this.mediaPlayer = this.$window.amp(videoElement, this.mediaPlayerOptions);
                            this.mediaPlayer.src([
                                {
                                    src: theUrl,
                                    type: videoType,
                                },
                            ]);
                            this.$scope.$on('$destroy', function () {
                                _this.mediaPlayer.dispose();
                            });
                        }
                        return [2];
                }
            });
        });
    };
    VideoStimuliDialogController.prototype.close = function () {
        this.$mdDialog.cancel();
    };
    VideoStimuliDialogController.getUniqueIdForVideoElement = function () {
        VideoStimuliDialogController.videoId++;
        var id = 'amp-video-' + VideoStimuliDialogController.videoId;
        return id;
    };
    VideoStimuliDialogController.getMediaPlayerSourceType = function (videoUrl) {
        var result = VideoStimuliDialogController.defaultMediaServicesType;
        if (videoUrl && videoUrl.indexOf('.mp4') !== -1) {
            result = 'video/mp4';
        }
        if (videoUrl.indexOf('.m3u8') !== -1) {
            result = 'application/x-mpegURL';
        }
        return result;
    };
    VideoStimuliDialogController.$inject = ['videoUrl', 'stimulusGuid', '$mdDialog', '$window', 'iscStimuliService', '$scope', '$element', 'allowDownload'];
    VideoStimuliDialogController.videoId = 0;
    VideoStimuliDialogController.defaultMediaServicesType = 'application/vnd.ms-sstr+xml';
    return VideoStimuliDialogController;
}());
export { VideoStimuliDialogController };
