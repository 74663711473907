import { __awaiter } from "tslib";
import { ForumNewConversationControllerBase } from '../../common/forum-new-conversation-controller-base';
export class ForumNewConversationController extends ForumNewConversationControllerBase {
    constructor($stateParams, $state, forumService, taskList, pageThemes, emojiservice, serverConstants, mappingService, conversationService, $timeout) {
        super($stateParams, $state, forumService, taskList, pageThemes, emojiservice, serverConstants, mappingService, conversationService, $timeout);
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.forumService = forumService;
        this.taskList = taskList;
        this.pageThemes = pageThemes;
        this.emojiservice = emojiservice;
        this.serverConstants = serverConstants;
        this.mappingService = mappingService;
        this.conversationService = conversationService;
        this.$timeout = $timeout;
    }
    $onInit() {
        super.$onInit();
    }
    saveConversation() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isSaving) {
                return;
            }
            this.isSaving = true;
            const request = {
                Room: this.$stateParams.roomGuid,
                Title: this.title,
                Message: this.message,
                Type: this.serverConstants.conversationElementTypeConstants.topic,
                Stimuli: this.stimuli,
                Attachments: this.attachments,
            };
            const pageGuid = this.$stateParams.pageGuid;
            const roomGuid = this.$stateParams.roomGuid;
            const theme = this.pageThemes.getPageTheme(pageGuid);
            const publishingTask = this.taskList.createTask({
                title: '(LabelPublishingForumConversationTitle)',
                text: '(LabelPublishingConversationText)',
                theme,
            });
            const publishedTask = this.taskList.createTask({
                title: '(LabelPublishedConversationTitle)',
                text: '(LabelPublishedConversationText)',
                showCloseButton: true,
                theme,
                onClick: () => {
                    this.$state.go('container.main.forum.room', { pageGuid, roomGuid });
                    publishedTask.hide();
                },
            });
            publishingTask.show();
            try {
                request.Message = this.emojiservice.htmlToShort(request.Message);
                const preparedStimuli = yield this.conversationService.prepareStimuliAndNewStimuli(request.Stimuli);
                preparedStimuli.stimuli.forEach((stimulus) => {
                    if (stimulus.fileContentToBeRemoved) {
                        stimulus.file = undefined;
                    }
                });
                request.Stimuli = preparedStimuli.stimuli;
                this.newStimulusFiles = preparedStimuli.files;
                const attachmentsAndFiles = yield this.conversationService.prepareAttachmentsAndNewAttachments(request.Attachments);
                request.Attachments = attachmentsAndFiles.attachments;
                this.newAttachmentFiles = attachmentsAndFiles.files;
                yield this.forumService.createForumConversation(request, this.newStimulusFiles, this.newAttachmentFiles);
                publishingTask.hide();
                publishedTask.show();
                publishedTask.hide();
                this.goToRoom();
            }
            catch (error) {
                publishedTask.title = '(LabelPublishConversationError)';
                publishedTask.text = '(LabelPublishConversationErrorText)';
                publishingTask.hide();
                publishedTask.show();
                this.isSaving = false;
            }
        });
    }
}
ForumNewConversationController.$inject = [
    '$stateParams',
    '$state',
    'forumService',
    'taskList',
    'pageThemes',
    'emojiservice',
    'serverConstants',
    'mappingService',
    'conversationService',
    '$timeout',
];
