import { fromJson, toJson } from 'angular';
import { UiModule } from './../../index';
var IscDraftService = (function () {
    function IscDraftService($rootScope) {
        this.$rootScope = $rootScope;
        this.TIMEOUT_SECONDS = 86400 * 7;
        this.onDraftSaved = this.$rootScope.$on(IscDraftService.EVENT_DRAFT_SAVED, this.onDraftSavedCallback);
        this.onDraftSaving = this.$rootScope.$on(IscDraftService.EVENT_DRAFT_SAVING, this.onDraftSavingCallback);
        this.onDraftRemove = this.$rootScope.$on(IscDraftService.EVENT_DRAFT_REMOVE, this.onDraftRemoveCallback);
        this.onDraftStart = this.$rootScope.$on(IscDraftService.EVENT_DRAFT_START, this.onDraftStartCallback);
        this.onDraftUpdate = this.$rootScope.$on(IscDraftService.EVENT_DRAFT_UPDATE, this.onDraftUpdateCallback);
        IscDraftService._this = this;
    }
    IscDraftService.prototype.$onDestroy = function () {
        this.onDraftSaved();
        this.onDraftSaving();
        this.onDraftRemove();
        this.onDraftStart();
        this.onDraftUpdate();
    };
    IscDraftService.prototype.onDraftRemoveCallback = function (event, draftUid) {
        if (event) {
            event.preventDefault();
        }
        if (IscDraftService._this) {
            IscDraftService._this.remDraft(draftUid);
        }
        IscDraftService.storeDraft(draftUid, undefined);
    };
    IscDraftService.prototype.onDraftStartCallback = function (event, draftUid, draftValue) {
        if (event) {
            event.preventDefault();
        }
        if (IscDraftService.draftStartDelegates && IscDraftService.draftStartDelegates.length > 0) {
            IscDraftService.draftStartDelegates.forEach(function (delegate) {
                if ((delegate.uid === draftUid || !delegate.uid) && delegate.callback) {
                    try {
                        delegate.callback({
                            uid: draftUid,
                            value: draftValue,
                        });
                    }
                    catch (_a) {
                    }
                }
            });
        }
    };
    IscDraftService.prototype.onDraftUpdateCallback = function (event, draftUid, draftValue) {
        if (event) {
            event.preventDefault();
        }
        if (draftUid) {
            if (IscDraftService.draftUpdateDelegates && IscDraftService.draftUpdateDelegates.length > 0) {
                IscDraftService.draftUpdateDelegates.some(function (updateDelegate) {
                    if (updateDelegate.uid === draftUid) {
                        try {
                            if (updateDelegate.callback({
                                uid: draftUid,
                                value: draftValue,
                            })) {
                                return true;
                            }
                        }
                        catch (_a) {
                        }
                    }
                    return false;
                });
            }
        }
    };
    IscDraftService.onDraftSavingOrSaved = function (uniqueIdentifier, draftData, delegates) {
        delegates.forEach(function (delegate) {
            if (delegate.uid === (draftData ? draftData.uid : uniqueIdentifier) && delegate.callback) {
                try {
                    delegate.callback({
                        uid: draftData ? draftData.uid : uniqueIdentifier,
                        value: draftData ? draftData.draftValue : undefined,
                    });
                }
                catch (error) {
                }
            }
        });
    };
    IscDraftService.prototype.onDraftSavedCallback = function (event, draftData) {
        if (event) {
            event.preventDefault();
        }
        if (draftData && draftData.uid
            && IscDraftService.draftSavedDelegates
            && IscDraftService.draftSavedDelegates.length > 0) {
            IscDraftService.onDraftSavingOrSaved(undefined, draftData, IscDraftService.draftSavedDelegates);
        }
    };
    IscDraftService.prototype.onDraftSavingCallback = function (event, uniqueIdentifier) {
        if (event) {
            event.preventDefault();
        }
        if (uniqueIdentifier
            && IscDraftService.draftSavingDelegates
            && IscDraftService.draftSavingDelegates.length > 0) {
            IscDraftService.onDraftSavingOrSaved(uniqueIdentifier, undefined, IscDraftService.draftSavingDelegates);
        }
    };
    IscDraftService.prototype.getOrClearDraftData = function (draftData) {
        var result;
        var dateIntervalSeconds = (Date.now() - draftData.timestamp) / 1000;
        if (dateIntervalSeconds < this.TIMEOUT_SECONDS) {
            result = draftData.draftValue;
        }
        else {
            this.remDraftFiles(draftData);
        }
        return result;
    };
    IscDraftService.prototype.getDraftData = function (uniqueIdentifier) {
        var storageData = localStorage.getItem(uniqueIdentifier);
        if (storageData) {
            var draftData = fromJson(storageData);
            return draftData;
        }
        return undefined;
    };
    IscDraftService.prototype.getDraft = function (uniqueIdentifier) {
        var result;
        var draftData = this.getDraftData(uniqueIdentifier);
        if (draftData && draftData.draftValue) {
            result = this.getOrClearDraftData(draftData);
            if (result) {
                if (IscDraftService.draftSavedDelegates && IscDraftService.draftSavedDelegates.length > 0) {
                    this.$rootScope.$emit(IscDraftService.EVENT_DRAFT_SAVED, result);
                }
            }
        }
        return result;
    };
    IscDraftService.prototype.remDraft = function (uniqueIdentifier) {
        var draftData = this.getDraftData(uniqueIdentifier);
        if (draftData) {
            localStorage.removeItem(uniqueIdentifier);
            this.remDraftFiles(draftData);
        }
    };
    IscDraftService.prototype.remDraftFiles = function (draftData) {
        if (IscDraftService.draftFileChangeDelegates && IscDraftService.draftFileChangeDelegates.length > 0) {
            IscDraftService.draftFileChangeDelegates.forEach(function (delegate) {
                if (delegate.uid === draftData.uid) {
                    draftData.draftFiles.forEach(function (draftFile) {
                        try {
                            delegate.callback({
                                file: draftFile,
                                uid: draftData.uid,
                                removed: true,
                            });
                        }
                        catch (_a) {
                        }
                    });
                }
            });
        }
    };
    IscDraftService.prototype.getExistingFile = function (draftUid, currentName, property, draftFiles) {
        var result = draftFiles.find(function (file) {
            return file.uid === "".concat(currentName).concat(property);
        });
        if (result && result.cloneof && result.cloneof !== '') {
            var cloneid_1 = result.cloneof;
            result = draftFiles.find(function (file) {
                return file.uid === cloneid_1;
            });
        }
        return result;
    };
    IscDraftService.prototype.setDraft = function (uniqueIdentifier, draft, check) {
        if (uniqueIdentifier) {
            if (IscDraftService.draftSavingDelegates && IscDraftService.draftSavingDelegates.length > 0) {
                this.$rootScope.$emit(IscDraftService.EVENT_DRAFT_SAVING, uniqueIdentifier);
            }
            if (check === true) {
                var existing = this.getDraftData(uniqueIdentifier);
                if (existing) {
                    var draftFiles = [];
                    if (IscDraftService.deepEquals(existing.draftValue, draft, false, IscDraftService.excludedProperties)) {
                        return;
                    }
                }
            }
            var draftData = {
                draftValue: draft,
                timestamp: Date.now(),
                uid: uniqueIdentifier,
                draftFiles: [],
            };
            localStorage.setItem(uniqueIdentifier, toJson(draftData));
            if (IscDraftService.draftSavedDelegates && IscDraftService.draftSavedDelegates.length > 0) {
                this.$rootScope.$emit(IscDraftService.EVENT_DRAFT_SAVED, draftData);
            }
        }
    };
    IscDraftService.prototype.updateDraft = function (uniqueIdentifier, callback) {
        if (callback) {
            var cache = this.getDraft(uniqueIdentifier);
            if (callback({
                uid: uniqueIdentifier,
                value: cache,
            })) {
                this.setDraft(uniqueIdentifier, cache);
            }
        }
    };
    IscDraftService.prototype.setDraftPeriodically = function (callback, interval) {
        var _this_1 = this;
        if (callback && interval && interval > 0) {
            var intervalId = setInterval(function () {
                var response = callback();
                if (response && response.uid && response.value) {
                    _this_1.setDraft(response.uid, response.value, true);
                }
            }, interval * 1000);
            return intervalId;
        }
        return -1;
    };
    IscDraftService.prototype.setOnDraftSaved = function (uniqueIdentifier, onSaved) {
        if (uniqueIdentifier && onSaved) {
            IscDraftService.draftSavedDelegates.push({
                uid: uniqueIdentifier,
                callback: onSaved,
            });
        }
    };
    IscDraftService.prototype.clearOnDraftSaved = function (uniqueIdentifier) {
        if (IscDraftService.draftSavedDelegates && IscDraftService.draftSavedDelegates.length > 0) {
            var newDelegates_1 = [];
            IscDraftService.draftSavedDelegates.forEach(function (delegate) {
                if (delegate.uid !== uniqueIdentifier) {
                    newDelegates_1.push(delegate);
                }
            });
            IscDraftService.draftSavedDelegates = newDelegates_1;
        }
    };
    IscDraftService.prototype.setOnDraftSaving = function (uniqueIdentifier, onSaving) {
        if (uniqueIdentifier && onSaving) {
            IscDraftService.draftSavingDelegates.push({
                uid: uniqueIdentifier,
                callback: onSaving,
            });
        }
    };
    IscDraftService.prototype.clearOnDraftSaving = function (uniqueIdentifier) {
        if (IscDraftService.draftSavingDelegates && IscDraftService.draftSavingDelegates.length > 0) {
            var newDelegates_2 = [];
            IscDraftService.draftSavingDelegates.forEach(function (delegate) {
                if (delegate.uid !== uniqueIdentifier) {
                    newDelegates_2.push(delegate);
                }
            });
            IscDraftService.draftSavingDelegates = newDelegates_2;
        }
    };
    IscDraftService.prototype.setOnDraftUpdate = function (uniqueIdentifier, onUpdate, dontCheck) {
        if (uniqueIdentifier && onUpdate) {
            var existing = dontCheck ? undefined : IscDraftService.draftUpdateDelegates.find(function (delegate) { return delegate.uid === uniqueIdentifier; });
            if (!existing) {
                IscDraftService.draftUpdateDelegates.push({
                    uid: uniqueIdentifier,
                    callback: onUpdate,
                });
            }
        }
    };
    IscDraftService.prototype.clearOnDraftUpdate = function (uniqueIdentifier) {
        if (IscDraftService.draftUpdateDelegates && IscDraftService.draftUpdateDelegates.length > 0) {
            var newDelegates_3 = [];
            IscDraftService.draftUpdateDelegates.forEach(function (delegate) {
                if (delegate.uid !== uniqueIdentifier) {
                    newDelegates_3.push(delegate);
                }
            });
            IscDraftService.draftUpdateDelegates = newDelegates_3;
        }
    };
    IscDraftService.prototype.setOnDraftStart = function (uniqueIdentifier, onStart) {
        if (onStart) {
            IscDraftService.draftStartDelegates.push({
                callback: onStart,
                uid: uniqueIdentifier,
            });
        }
    };
    IscDraftService.prototype.clearOnDraftStart = function (uniqueIdentifier) {
        if (IscDraftService.draftStartDelegates && IscDraftService.draftStartDelegates.length > 0) {
            var newDelegates_4 = [];
            IscDraftService.draftStartDelegates.forEach(function (delegate) {
                if (delegate.uid !== uniqueIdentifier) {
                    newDelegates_4.push(delegate);
                }
            });
            IscDraftService.draftStartDelegates = newDelegates_4;
        }
    };
    IscDraftService.deepEquals = function (first, second, onlySentenceCase, excludedProperties) {
        var result = true;
        if (first !== second) {
            if (first === undefined || second === undefined) {
                result = false;
            }
            else {
                if ((typeof first === 'function' && typeof second === 'function')
                    || (first instanceof Date && second instanceof Date)
                    || (first instanceof RegExp && second instanceof RegExp)
                    || (first instanceof String && second instanceof String)
                    || (first instanceof Number && second instanceof Number)) {
                    result = first.toString() === second.toString();
                    if (!result) {
                        result = false;
                    }
                }
                else {
                    var _loop_1 = function (prop) {
                        if (prop.startsWith('$')) {
                            return "continue";
                        }
                        if (excludedProperties && excludedProperties.find(function (exc) { return exc === prop; })) {
                            return "continue";
                        }
                        if (onlySentenceCase === true
                            && prop.charAt(0) !== prop.charAt(0).toUpperCase()) {
                            return "continue";
                        }
                        if (second.hasOwnProperty(prop) !== first.hasOwnProperty(prop)) {
                            result = false;
                            return "break";
                        }
                        else if (typeof second[prop] !== typeof first[prop]) {
                            result = false;
                            return "break";
                        }
                    };
                    for (var prop in second) {
                        var state_1 = _loop_1(prop);
                        if (state_1 === "break")
                            break;
                    }
                    var _loop_2 = function (prop) {
                        if (prop.startsWith('$')) {
                            return "continue";
                        }
                        if (excludedProperties && excludedProperties.find(function (exc) { return exc === prop; })) {
                            return "continue";
                        }
                        if (onlySentenceCase === true
                            && prop.charAt(0) !== prop.charAt(0).toUpperCase()) {
                            return "continue";
                        }
                        if (first.hasOwnProperty(prop) !== second.hasOwnProperty(prop)) {
                            result = false;
                            return "break";
                        }
                        else if (typeof first[prop] !== typeof second[prop]) {
                            result = false;
                            return "break";
                        }
                        if (!result) {
                            return "break";
                        }
                        switch (typeof first[prop]) {
                            case 'object':
                            case 'function':
                                result = this_1.deepEquals(first[prop], second[prop], onlySentenceCase, excludedProperties);
                                if (!result) {
                                    result = false;
                                }
                                break;
                            default:
                                result = first[prop] === second[prop];
                                if (!result) {
                                    result = false;
                                }
                                break;
                        }
                        if (!result) {
                            return "break";
                        }
                    };
                    var this_1 = this;
                    for (var prop in first) {
                        var state_2 = _loop_2(prop);
                        if (state_2 === "break")
                            break;
                    }
                }
            }
        }
        return result;
    };
    IscDraftService.fileEquals = function (currentList, cacheList) {
        var result = true;
        if (currentList && cacheList) {
            if (currentList.length !== cacheList.length) {
                result = false;
            }
            else {
                currentList.forEach(function (file) {
                    if (!cacheList.find(function (cache) { return cache.name === file.name; })) {
                        result = false;
                        return;
                    }
                });
            }
        }
        return result;
    };
    IscDraftService.rootScope = function ($rootScope) {
        if ($rootScope) {
            return $rootScope;
        }
        else if (IscDraftService._this) {
            return IscDraftService._this.$rootScope;
        }
        return undefined;
    };
    IscDraftService.broadcastDraftRemove = function ($rootScope, uniqueIdentifier) {
        var result = uniqueIdentifier !== undefined && uniqueIdentifier !== null && uniqueIdentifier !== '';
        if (result) {
            try {
                if (IscDraftService._this) {
                    IscDraftService._this.onDraftRemoveCallback(undefined, uniqueIdentifier);
                }
                else {
                    IscDraftService.rootScope($rootScope)
                        .$broadcast(IscDraftService.EVENT_DRAFT_REMOVE, uniqueIdentifier);
                }
            }
            catch (_a) {
                result = false;
            }
        }
        return result;
    };
    IscDraftService.broadcastDraftStart = function ($rootScope, uniqueIdentifier, draft) {
        var result = uniqueIdentifier !== undefined && uniqueIdentifier !== null && uniqueIdentifier !== '';
        if (result) {
            try {
                if (IscDraftService._this) {
                    IscDraftService._this.onDraftStartCallback(undefined, uniqueIdentifier, draft);
                }
                else {
                    IscDraftService.rootScope($rootScope)
                        .$broadcast(IscDraftService.EVENT_DRAFT_START, uniqueIdentifier, draft);
                }
            }
            catch (_a) {
                result = false;
            }
        }
        return result;
    };
    IscDraftService.broadcastDraftUpdate = function ($rootScope, uniqueIdentifier, draftValue) {
        var result = uniqueIdentifier !== undefined && uniqueIdentifier !== null && uniqueIdentifier !== '';
        if (result) {
            try {
                if (IscDraftService._this) {
                    IscDraftService._this.onDraftUpdateCallback(undefined, uniqueIdentifier, draftValue);
                }
                else {
                    IscDraftService.rootScope($rootScope)
                        .$broadcast(IscDraftService.EVENT_DRAFT_UPDATE, uniqueIdentifier, draftValue);
                }
            }
            catch (_a) {
                result = false;
            }
        }
        return result;
    };
    IscDraftService.storeDraft = function (uniqueIdentifier, draftValue, dontRemoveDelegates) {
        if (uniqueIdentifier && IscDraftService._this) {
            if (!dontRemoveDelegates) {
                IscDraftService._this.clearOnDraftSaved(uniqueIdentifier);
                IscDraftService._this.clearOnDraftSaving(uniqueIdentifier);
                IscDraftService._this.clearOnDraftStart(uniqueIdentifier);
                IscDraftService._this.clearOnDraftUpdate(uniqueIdentifier);
                IscDraftService.clearDraftFileChanges(uniqueIdentifier);
            }
            if (draftValue) {
                IscDraftService._this.setDraft(uniqueIdentifier, draftValue, true);
            }
        }
    };
    IscDraftService.clearDraftFileChanges = function (uniqueIdentifier) {
        var newDelegates = [];
        if (IscDraftService.draftFileChangeDelegates && IscDraftService.draftFileChangeDelegates.length > 0) {
            IscDraftService.draftFileChangeDelegates.forEach(function (delegate) {
                if (delegate.uid !== uniqueIdentifier) {
                    newDelegates.push(delegate);
                }
            });
        }
        IscDraftService.draftFileChangeDelegates = newDelegates;
    };
    IscDraftService.subscribeToDraftFileChanges = function (uniqueIdentifier, delegate) {
        var result = false;
        if (uniqueIdentifier && uniqueIdentifier !== '' && delegate) {
            IscDraftService.draftFileChangeDelegates.push({
                uid: uniqueIdentifier,
                callback: delegate,
            });
            result = true;
        }
        return result;
    };
    IscDraftService.numberWithDigits = function (no, digits) {
        return no.toLocaleString('en-US', { minimumIntegerDigits: digits });
    };
    IscDraftService.formatSavedDate = function (date) {
        var result = "".concat(this.numberWithDigits(date.getHours(), 2), ":").concat(this.numberWithDigits(date.getMinutes(), 2));
        return result;
    };
    IscDraftService.EVENT_DRAFT_SAVED = 'draft_saved';
    IscDraftService.EVENT_DRAFT_SAVING = 'draft_saving';
    IscDraftService.EVENT_DRAFT_REMOVE = 'draft_remove';
    IscDraftService.EVENT_DRAFT_START = 'draft_start';
    IscDraftService.EVENT_DRAFT_UPDATE = 'draft_update';
    IscDraftService.excludedProperties = ['file', 'url', 'newStimulusFiles', 'newAttachmentFiles', 'Guid', 'isSlideIn', 'thumbnailUrl', 'stimuli', 'attachments'];
    IscDraftService.draftSavedDelegates = [];
    IscDraftService.draftSavingDelegates = [];
    IscDraftService.draftStartDelegates = [];
    IscDraftService.draftUpdateDelegates = [];
    IscDraftService.draftFileChangeDelegates = [];
    IscDraftService.$inject = ['$rootScope'];
    return IscDraftService;
}());
export { IscDraftService };
UiModule.service('iscDraftService', IscDraftService);
