import * as _ from 'lodash';
import { DateTime } from 'luxon';
export class BaseDataService {
    constructor(httpService) {
        this.httpService = httpService;
        this.cache = [];
        this.checkAllCache();
    }
    keyOf(getData) {
        return `${getData.url}_${JSON.stringify(getData.params)}`;
    }
    checkAllCache() {
        if (this.cache.length > 0) {
            const clone = Object.assign([], this.cache);
            for (const item of clone) {
                this.verifyCache(item);
            }
        }
        setTimeout(() => this.checkAllCache(), BaseDataService.DEFAULT_TIMEOUT * 1000);
    }
    verifyCache(cached) {
        let result = cached;
        if (result && result.timeout && result.timeout !== 0 && result.from) {
            if ((result.timeout * 1000) + result.from < DateTime.utc().toMillis()) {
                _.remove(this.cache, cached);
                result = undefined;
            }
        }
        return result;
    }
    getOrCached(getData, timeoutS, noCache) {
        const theKey = this.keyOf(getData);
        const theNow = DateTime.utc().toMillis();
        let cached = noCache ? undefined : _.find(this.cache, (item) => item.key === theKey);
        cached = this.verifyCache(cached);
        if (cached === undefined) {
            cached = {
                key: theKey,
                value: this.httpService.get(getData),
                timeout: timeoutS || BaseDataService.DEFAULT_TIMEOUT,
                from: theNow,
            };
            if (!noCache) {
                this.cache.push(cached);
            }
        }
        return cached.value;
    }
    invalidateCache(key) {
        if (key && key !== '') {
            const toRemove = _.find(this.cache, (item) => item.key === key);
            if (toRemove) {
                _.remove(this.cache, toRemove);
            }
        }
        else {
            this.cache = [];
        }
    }
}
BaseDataService.DEFAULT_TIMEOUT = 300;
BaseDataService.SECONDS_ONE_DAY = 86400;
BaseDataService.SECONDS_ONE_HOUR = 3600;
