import { UiModule } from './../../index';
UiModule
    .directive('iscTranslateArgument', IscTranslateArgumentDirective);
function IscTranslateArgumentDirective() {
    return {
        restrict: 'E',
        require: '^iscTranslate',
        link: function (scope, element, attr, controller) {
            var argumentName = attr.name;
            controller.setLabelArgumentValue(argumentName, element.html());
            element.remove();
        },
    };
}
