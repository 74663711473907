import * as angular from 'angular';
import { DoesNotContainDirective } from './doesnt-contain.directive';
import { EqualsToDirective } from './equals-to.directive';
import { SsoDomainValidator } from './ssodomain-validator.directive';
import { UsernameValidator } from './username-validator.directive';
import { IscHideMobileDirective } from './isc-hide-mobile.directive';
import { IscTrackClickDirective } from './isc-track-click.directive';
import { PasswordValidator } from './password-validator.directive';
import { RemoveFocusOnClick } from './remove-focus-on-click.directive';
import { SpecialCharactersValidator } from './special-characters.directive';
angular
    .module('insitesApp.core')
    .directive('doesNotContain', () => new DoesNotContainDirective())
    .directive('equalsTo', () => new EqualsToDirective())
    .directive('ssoDomainValidator', SsoDomainValidator.factory())
    .directive('usernameValidator', UsernameValidator.factory())
    .directive('iscHideMobile', IscHideMobileDirective.factory())
    .directive('iscTrackClick', IscTrackClickDirective.factory())
    .directive('passwordValidator', ['serverConstants', (serverConstants) => new PasswordValidator(serverConstants)])
    .directive('removeFocusOnClick', () => new RemoveFocusOnClick())
    .directive('specialCharactersValidator', () => new SpecialCharactersValidator());
