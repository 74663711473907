import * as angular from 'angular';
import { Settings } from 'luxon';
import '../../FontAwesome/css/all.css';
export { IncentiveCardType } from './components/timeline/cards/incentive-card/incentive-card-type.enum';
export { IscUIUtils } from './utils/sharedutils';
export var UiModule = angular.module('isc-ui', ['ngMaterial', 'angular-inview']);
export var ConfigureLuxon = function (language, zone) {
    Settings.defaultLocale = language;
    Settings.defaultZone = zone;
};
