import { DateTime } from 'luxon';
var ConversationListController = (function () {
    function ConversationListController() {
    }
    ConversationListController.prototype.getTimeStringFromDate = function (date) {
        return DateTime.fromISO(date).toRelative({ base: DateTime.utc() });
    };
    return ConversationListController;
}());
export { ConversationListController };
