import { __awaiter } from "tslib";
export class ForumController {
    constructor($state, $stateParams, pageService, forumService, _, $scope, $q, $timeout, constantsfactory, $mdMedia) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.pageService = pageService;
        this.forumService = forumService;
        this._ = _;
        this.$scope = $scope;
        this.$q = $q;
        this.$timeout = $timeout;
        this.constantsfactory = constantsfactory;
        this.$mdMedia = $mdMedia;
        this.unsubscribeFromTransition = this._.noop;
        this.forumRooms = [];
        this.Conversations = [];
        this.selectedRoomIndex = -1;
        this.selectedRoomGuid = null;
        this.loading = true;
        this.labelReadMore = 'read more';
        this.labelReadLess = 'read less';
        this.labelRecentTopics = 'Recent topics';
        this.readMoreExpanded = false;
        this.readMoreEnabled = false;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.unsubscribeRoom = this.$scope.$watch(() => this.$stateParams.roomGuid, () => {
                this.updateSelectedRoomFromRoute();
            });
            yield this.loadLabels();
            this.$q.all([
                this.loadPageDetails(),
                this.loadRooms(),
            ]).then(() => {
                this.updateUrl = this.$scope.$watch(() => this.$stateParams.roomGuid, (newVal) => {
                    if (!newVal) {
                        this.navigateToRecentTopics();
                    }
                });
            }).finally(() => {
                this.loading = false;
            });
            this.unsubscribeForum = this.forumService.onConversationChanged.subscribe(() => this.loadRooms());
        });
    }
    $onDestroy() {
        this.updateUrl();
        this.unsubscribeFromTransition();
        this.unsubscribeForum();
        this.unsubscribeRoom();
    }
    goToSelectedRoom() {
        if (this.selectedRoomGuid !== undefined) {
            this.$state.go('container.main.forum.room', { roomGuid: this.selectedRoomGuid, page: 1 });
        }
    }
    loadPageDetails() {
        return __awaiter(this, void 0, void 0, function* () {
            this.pageDetails = yield this.pageService.getPageDetails(this.$stateParams.pageGuid);
            this.pageIntroMobile = String(this.pageDetails.Intro).replace(/<((?!\/?br\b|\/?a\b)[^>]+)>/gmi, '');
            this.$timeout(() => {
                const divElement = document.getElementById('intro-read-more-div');
                this.$scope.$watch(() => divElement.scrollHeight, () => {
                    if (divElement.scrollHeight > divElement.clientHeight) {
                        this.readMoreEnabled = true;
                    }
                    else {
                        this.readMoreEnabled = false;
                    }
                });
            });
        });
    }
    loadLabels() {
        return __awaiter(this, void 0, void 0, function* () {
            this.labelReadMore = yield this.constantsfactory.getLabelValue('LabelForumReadMore');
            this.labelReadLess = yield this.constantsfactory.getLabelValue('LabelForumReadLess');
            this.labelRecentTopics = yield this.constantsfactory.getLabelValue('LabelForumRecentTopics');
        });
    }
    loadRooms() {
        this.recentTopics = { Name: this.labelRecentTopics, IsVisible: true, Guid: this.forumService.EMPTYGUID, pageGuid: this.$stateParams.pageGuid };
        return this.forumService.getSquareForumRooms(this.$stateParams.pageGuid)
            .then((forumRooms) => {
            this.forumRooms = this._.concat([this.recentTopics], forumRooms);
        })
            .then(() => this.$timeout())
            .then(() => this.updateSelectedRoomFromRoute());
    }
    navigateToRecentTopics() {
        if (!this.$stateParams.roomGuid && this.forumRooms.length > 1) {
            this.$state.go('container.main.forum.room', { roomGuid: this.forumRooms[0].Guid }, { location: 'replace' });
        }
    }
    updateSelectedRoomFromRoute() {
        const roomIndex = this._.findIndex(this.forumRooms, {
            Guid: this.$stateParams.roomGuid,
        });
        const room = this.forumRooms[roomIndex];
        this.selectedRoomIndex = roomIndex;
        this.selectedRoomGuid = room && room.Guid;
    }
    get currentRoute() {
        return this.$state.current.name;
    }
}
ForumController.$inject = ['$state', '$stateParams', 'pageService', 'forumService', '_', '$scope', '$q', '$timeout', 'constantsfactory', '$mdMedia'];
