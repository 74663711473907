import { IdeaStreamSsoController } from './ideastreamsso.controller';
const template = "<landing-page landing-title=\"::'(IdeaStreamLoginTitle)'\" landing-message=\"::'(IdeaStreamLoginMessage)'\">\n</landing-page>";
export const IdeastreamSsoComponent = {
    controller: IdeaStreamSsoController,
    template,
    controllerAs: 'vm',
    bindings: {
        projectId: '<',
        redirect: '@',
        connectionId: '@',
        token: '@',
        sourceGuid: '@',
        source: '@',
    },
};
