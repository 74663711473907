import { OutdentOnBackSpaceHotkey } from './OutdentOnBackSpaceHotkey';
var OutdentOnBackspace = (function () {
    function OutdentOnBackspace(iscDomhelperservice) {
        this.iscDomhelperservice = iscDomhelperservice;
    }
    OutdentOnBackspace.prototype.init = function (richInput) {
        richInput.subscribeToKeyDown(new OutdentOnBackSpaceHotkey(this.iscDomhelperservice));
    };
    OutdentOnBackspace.$inject = ['iscDomhelperservice'];
    return OutdentOnBackspace;
}());
export default OutdentOnBackspace;
