import { __extends } from "tslib";
import { KeyEventSubscription } from '../IKeyEventSubscription';
var OutdentOnBackSpaceHotkey = (function (_super) {
    __extends(OutdentOnBackSpaceHotkey, _super);
    function OutdentOnBackSpaceHotkey(iscDomhelperservice) {
        var _this = _super.call(this) || this;
        _this.iscDomhelperservice = iscDomhelperservice;
        _this.keyCode = 8;
        return _this;
    }
    OutdentOnBackSpaceHotkey.prototype.handler = function (event) {
        this.onBackspacePressed(event);
    };
    OutdentOnBackSpaceHotkey.prototype.onBackspacePressed = function (event) {
        var selection = window.getSelection();
        if (this.isCursorAtTheStartOfAnIndentation(selection)) {
            this.iscDomhelperservice.outdentInNgWig();
            event.preventDefault();
        }
    };
    OutdentOnBackSpaceHotkey.prototype.isCursorAtTheStartOfAnIndentation = function (selection) {
        if (selection.type !== 'Caret' || selection.anchorOffset !== 0) {
            return false;
        }
        var indentedAncestor = this.findIndentedAncestor(selection.anchorNode);
        if (!indentedAncestor) {
            return false;
        }
        return indentedAncestor === selection.anchorNode
            || this.isAtTheStartOf(selection.anchorNode, indentedAncestor);
    };
    OutdentOnBackSpaceHotkey.prototype.findIndentedAncestor = function (node) {
        var indentedElementsSelector = 'blockquote, li';
        return this.iscDomhelperservice.findAncestor(node, indentedElementsSelector);
    };
    OutdentOnBackSpaceHotkey.prototype.isAtTheStartOf = function (node, ancestor) {
        var isAtTheStart = true;
        this.walkUpToAncestor(node, ancestor, function (n) {
            isAtTheStart = isAtTheStart && !n.previousSibling;
        });
        return isAtTheStart;
    };
    OutdentOnBackSpaceHotkey.prototype.walkUpToAncestor = function (startingNode, ancestor, callback) {
        var node = startingNode.parentNode;
        while (node && node !== ancestor) {
            callback(node);
            node = node.parentNode;
        }
    };
    return OutdentOnBackSpaceHotkey;
}(KeyEventSubscription));
export { OutdentOnBackSpaceHotkey };
