export class IscTrackClickDirective {
    constructor(notifications, serverConstants) {
        this.notifications = notifications;
        this.serverConstants = serverConstants;
        this.restrict = 'A';
    }
    link(scope, element, attrs) {
        element.on('mousedown', () => {
            const event = JSON.parse(attrs.iscTrackClick);
            this.notifications.elementClicked(event);
        });
    }
    static factory() {
        const directive = (notifications, serverConstants) => new IscTrackClickDirective(notifications, serverConstants);
        directive.$inject = ['notifications', 'serverConstants'];
        return directive;
    }
}
IscTrackClickDirective.$inject = ['notifications', 'serverConstants'];
