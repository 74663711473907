import { UiModule } from '../../../index';
import { ShowRepliesButtonController } from './show-replies-button.controller';
var showRepliesButtonTemplate = "<div class=\"show-replies-button-content\" ng-if=\"vm.conversation.ReplyCount\" ng-class=\"{'scout-conversation': vm.isScoutDiscussionType}\">\n  <button ng-click=\"vm.toggleReplies()\" class=\"md-button isc-button\" type=\"button\">\n   <span ng-if=\"!vm.conversation.IsExpanded\" isc-translate=\"(LabelShowReplies)\" isc-translate-replycount=\"{{vm.conversation.ReplyCount}}\"></span>\n   <span ng-if=\"vm.conversation.IsExpanded\" isc-translate=\"(LabelHideReplies)\" class=\"label\"></span>\n  </button>\n</div>";
UiModule.component('showRepliesButton', {
    template: showRepliesButtonTemplate,
    controller: ShowRepliesButtonController,
    controllerAs: 'vm',
    transclude: true,
    bindings: {
        conversation: '<',
        toggleReplies: '&',
        activityType: '<',
    },
});
