import { __awaiter } from "tslib";
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { Tab } from '../../challenges/challenges.controller';
import { IncentiveCardType } from 'isc-ui';
export class MySavingUpRewardsController {
    constructor(incentiveService, serverConstants, constantsfactory, $state, $stateParams, pageThemes, taskList, userNotificationService, pageService, iscPages, $timeout, $q) {
        this.incentiveService = incentiveService;
        this.serverConstants = serverConstants;
        this.constantsfactory = constantsfactory;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.pageThemes = pageThemes;
        this.taskList = taskList;
        this.userNotificationService = userNotificationService;
        this.pageService = pageService;
        this.iscPages = iscPages;
        this.$timeout = $timeout;
        this.$q = $q;
        this.rewardLabels = [];
        this.savingUpRewardsEarnedNotifications = [];
        this.isRedeeming = false;
        this.questionsInfoText = '';
        this.SavingUpCardTypes = IncentiveCardType;
        this.isLoaded = false;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.reset();
            yield this.checkIncentivesFaqCategoryExists();
            this.isLoaded = true;
        });
    }
    reset() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.incentiveService.getSavingUpRedeemOverview();
            this.pointsSavedUp = data.PointsSavedUp;
            this.minimumPointsToRedeem = data.MinimumPointsToRedeem;
            this.totalValue = data.TotalValue;
            this.remainderForMinimum = data.RemainderForMinimum;
            this.currency = data.Currency;
            this.unitLabel = yield this.constantsfactory.getLabelValue(`Label${data.UnitLabel}`);
            this.unitLabelCapital = this.unitLabel.charAt(0).toUpperCase() + this.unitLabel.slice(1);
            this.unitLabelSingle = yield this.constantsfactory.getLabelValue('LabelPoint');
            this.isAutomaticHandlingEnabled = data.IsAutomaticHandlingEnabled;
            this.rulesContentLabel = this.isAutomaticHandlingEnabled ? '(RewardRulesContent)' : '(RewardRulesContentAutomaticHandlingDisabled)';
            this.channelTypes = this.serverConstants.channelTypeConstants;
            this.savingUpRewardsEarnedNotifications = this.userNotificationService.userNotifications
                .filter((n) => n.NotificationType === this.serverConstants.notificationTypeConstants.savingUpRewardsEarned ||
                n.NotificationType === this.serverConstants.notificationTypeConstants.moreSavingUpRewardsEarned);
            yield this.fetchAllRewardLabels();
            this.isRedeeming = false;
            yield this.getRewardDate();
            yield this.arrangeRewards();
            yield this.userNotificationService.markNotificationsAsReadByType(this.serverConstants.notificationTypeConstants.savingUpRewardsEarned);
            yield this.userNotificationService.markNotificationsAsReadByType(this.serverConstants.notificationTypeConstants.moreSavingUpRewardsEarned);
            yield this.userNotificationService.markNotificationsAsReadByType(this.serverConstants.notificationTypeConstants.rewardsAvailableForRedemption);
            yield this.userNotificationService.markNotificationsAsReadByType(this.serverConstants.notificationTypeConstants.savingUpRewardsCorrectionApplied);
        });
    }
    redeemReward() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isRedeeming) {
                return;
            }
            this.isRedeeming = true;
            const { publishingTask, errorTask, succeededTask } = this.makeTasks();
            publishingTask.show();
            this.incentiveService.redeemSavingUpReward().then(() => __awaiter(this, void 0, void 0, function* () {
                publishingTask.hide();
                succeededTask.show();
                this.$timeout(() => succeededTask.hide(), 5000);
                yield this.reset();
            }), () => {
                publishingTask.hide();
                errorTask.show();
                this.$timeout(() => errorTask.hide(), 5000);
            });
        });
    }
    fetchAllRewardLabels() {
        return __awaiter(this, void 0, void 0, function* () {
            for (const prop in this.serverConstants.rewardPartnerTypeConstants) {
                if (Object.prototype.hasOwnProperty.call(this.serverConstants.rewardPartnerTypeConstants, prop)) {
                    const value = this.serverConstants.rewardPartnerTypeConstants[prop];
                    const capitalized = prop.charAt(0).toUpperCase() + prop.slice(1);
                    const label = yield this.constantsfactory.getLabelValue(`LabelRewardPartner${capitalized}`);
                    if (this.serverConstants.rewardPartnerTypeConstants[prop] !== this.serverConstants.rewardPartnerTypeConstants.none) {
                        this.rewardLabels[value] = label || prop;
                    }
                }
            }
        });
    }
    arrangeRewards() {
        return __awaiter(this, void 0, void 0, function* () {
            const tempCards = _.concat(yield this.$q.all(_.map(this.savingUpRedemptions, (r) => __awaiter(this, void 0, void 0, function* () {
                return ({
                    DateTime: r.DateCreated,
                    Guid: r.Guid,
                    Type: this.SavingUpCardTypes.redemptionHistory,
                    ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                    SavingUpUnits: r.SavingUpUnits,
                    SavingUpUnitValue: r.SavingUpUnitValue,
                    SavingUpTotalValue: r.SavingUpTotalValue,
                    CurrencyName: r.CurrencyName,
                    RewardPartnerType: r.RewardPartner,
                    RewardPartner: this.rewardLabels[r.RewardPartner],
                    Children: [],
                    RedeemedRewardResponse: r.RedeemedRewardResponse,
                    Leftover: r.Leftover,
                });
            }))), _.map(this.rewards, (r) => {
                var _a;
                const route = {
                    Ref: undefined,
                    IsExternal: false,
                    Params: {
                        channel: this.serverConstants.channelTypeConstants.incentiveCard,
                        sampleGuid: r.Guid,
                        activityGuid: r.ActivityGuid,
                        pageGuid: r.PageGuid,
                        selectedIndex: Tab.ToDo,
                    },
                };
                switch (r.ActivityType) {
                    case this.serverConstants.squareActivityTypeConstants.checkMarketSurvey:
                        route.Ref = 'container.activity';
                        route.IsExternal = true;
                        break;
                    case this.serverConstants.squareActivityTypeConstants.confirmitSurvey:
                    case this.serverConstants.squareActivityTypeConstants.decipherSurvey:
                        if (r.ActivityFormat === this.serverConstants.squareActivityFormatConstants.research) {
                            route.Ref = 'container.activity';
                            route.IsExternal = true;
                            break;
                        }
                        route.Ref = 'container.main.profile.activity';
                        break;
                    case this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch:
                    case this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch:
                    case this.serverConstants.squareActivityTypeConstants.publicScoutResearch:
                    case this.serverConstants.squareActivityTypeConstants.privateScoutResearch:
                    case this.serverConstants.squareActivityTypeConstants.qualitativeResearch:
                        route.Ref = 'container.main.challenges.qualactivity';
                        break;
                }
                const type = r.ReceivedPoints
                    || r.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.quotaFull
                    || r.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.screened
                    ? this.SavingUpCardTypes.rewardEarned
                    : this.SavingUpCardTypes.rewardNotEarned;
                return {
                    DateTime: r.RewardDateCreated || (r.IsRewardActivityCompleted ? r.DateRewardActivityCompleted : r.ActivityEndDate),
                    Guid: r.Guid,
                    Type: type,
                    ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                    IsSampleCompleted: r.IsSampleCompleted,
                    IsActivityFinished: r.Removed === this.serverConstants.sampleRemovedReasonsConstants.notInTarget
                        ? (r.IsSampleCompleted ? r.IsActivityFinished : true) : r.IsActivityFinished,
                    Removed: r.Removed,
                    SavingUpUnits: r.ReceivedPoints || r.CompletionPoints,
                    IsRewardActivityCompleted: r.IsRewardActivityCompleted,
                    ActivityName: r.ActivityName,
                    ActivityTitle: r.ActivityTitle,
                    Route: route,
                    HasNotification: (_a = r.HasNotification) !== null && _a !== void 0 ? _a : this.savingUpRewardsEarnedNotifications.some((n) => n.TargetGuid === r.Guid),
                    IsLocked: r.IsLocked,
                    SampleStatus: r.SampleStatus,
                    OutcomeCode: r.OutcomeCode,
                };
            }), _.map(this.manualInterventionRedemptions, (r) => ({
                DateTime: r.DateCreated,
                Guid: r.Guid,
                Type: r.ActivityGuid
                    ? this.SavingUpCardTypes.manualRedemptionHistory
                    : r.ManualIntervention
                        ? this.SavingUpCardTypes.manualIntervention
                        : this.SavingUpCardTypes.manualRedemptionHistory,
                ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                RewardValue: r.RewardValue,
                RewardPartnerType: r.RewardPartner,
                RewardPartner: this.rewardLabels[r.RewardPartner],
                CurrencyName: r.CurrencyName,
                ManualIntervention: r.ManualIntervention,
                RedeemedRewardResponse: r.RedeemedRewardResponse,
                Leftover: null,
            })), this.isAutomaticHandlingEnabled && this.pointsSavedUp >= this.minimumPointsToRedeem && this.totalValue >= 1 ?
                {
                    DateTime: DateTime.utc().toISO(),
                    Guid: 'redeemCard',
                    Type: this.SavingUpCardTypes.redemptionAction,
                    ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                    SavingUpUnits: 0,
                    SavingUpUnitValue: 0,
                    RewardPartner: '',
                    Children: [],
                } : [], _.map(this.invalidations, (i) => ({
                DateTime: i.DateCreated,
                Guid: i.Guid,
                Credits: i.Credits,
                Type: this.SavingUpCardTypes.invalidation,
                ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                Children: [],
            }))).sort((a, b) => DateTime.fromISO(b.DateTime).toMillis() - DateTime.fromISO(a.DateTime).toMillis());
            let parent;
            this.cards = [];
            _.forEach(tempCards, (card) => {
                if (this.IsReward(card)) {
                    if (!parent) {
                        parent = {
                            DateTime: DateTime.utc().toISO(),
                            Guid: 'emptyContainer',
                            Type: this.SavingUpCardTypes.emptyContainer,
                            ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                            Children: [],
                            Leftover: null,
                        };
                        this.cards.push(parent);
                    }
                    parent.Children.push(card);
                    return;
                }
                if (card.Type === this.SavingUpCardTypes.info) {
                    this.cards.push(card);
                    return;
                }
                parent = card;
                this.cards.push(card);
            });
            if (this.isAlmostInactive && this.pointsSavedUp > 0) {
                this.cards.push({
                    DateTime: DateTime.utc().plus({ seconds: 1 }).toISO(),
                    Guid: 'AlmostInactive',
                    Type: this.SavingUpCardTypes.info,
                    ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                    Leftover: null,
                    Title: 'Almost ',
                    Body: 'Inactive',
                });
            }
            this.cards = _.concat(this.cards, yield this.$q.all(_.map(this.manualRewardsRedemptions, (r) => __awaiter(this, void 0, void 0, function* () {
                return ({
                    DateTime: r.DateCreated,
                    Guid: r.Guid,
                    Type: r.ActivityGuid
                        ? this.SavingUpCardTypes.manualRedemptionHistory
                        : r.ManualIntervention
                            ? this.SavingUpCardTypes.manualIntervention
                            : this.SavingUpCardTypes.manualRedemptionHistory,
                    ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                    RewardValue: r.RewardValue,
                    RewardPartnerType: r.RewardPartner,
                    RewardPartner: this.rewardLabels[r.RewardPartner],
                    CurrencyName: r.CurrencyName,
                    ManualIntervention: r.ManualIntervention,
                    RedeemedRewardResponse: r.RedeemedRewardResponse,
                    IsRedeemable: r.IsRedeemable,
                    Leftover: null,
                });
            }))))
                .sort((a, b) => DateTime.fromISO(b.DateTime).toMillis() - DateTime.fromISO(a.DateTime).toMillis());
            const cardsWithoutManualRedemption = this.cards.filter((c) => c.Type !== this.SavingUpCardTypes.manualRedemptionHistory);
            if (cardsWithoutManualRedemption.length > 0) {
                for (let i = 0; i < cardsWithoutManualRedemption.length; i++) {
                    const currentCard = cardsWithoutManualRedemption[i];
                    if (currentCard.Leftover && currentCard.Leftover !== 0 && currentCard.Type === this.SavingUpCardTypes.redemptionHistory) {
                        if (i === 0) {
                            const emptyContainer = {
                                DateTime: DateTime.utc().toISO(),
                                Guid: 'emptyContainer_leftover',
                                Type: this.SavingUpCardTypes.emptyContainer,
                                ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                                Children: [],
                                Leftover: null,
                            };
                            this.cards.unshift(emptyContainer);
                            cardsWithoutManualRedemption.unshift(emptyContainer);
                            i++;
                        }
                        const nextCard = cardsWithoutManualRedemption[i - 1];
                        nextCard.Children.push({
                            DateTime: currentCard.DateTime,
                            Guid: `leftover_${currentCard.Guid}`,
                            ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
                            Type: this.SavingUpCardTypes.leftover,
                            Leftover: currentCard.Leftover,
                        });
                    }
                }
            }
        });
    }
    IsReward(card) {
        return [this.SavingUpCardTypes.rewardEarned, this.SavingUpCardTypes.rewardNotEarned, this.SavingUpCardTypes.manualIntervention].indexOf(card.Type) > -1;
    }
    getRewardDate() {
        return __awaiter(this, void 0, void 0, function* () {
            this.savingUpRedemptions = yield this.incentiveService.getSavingUpRewardRedemptionList();
            this.rewards = yield this.incentiveService.getSavingUpRewardItemList();
            this.invalidations = yield this.incentiveService.getSavingUpInvalidationList();
            this.isAlmostInactive = yield this.incentiveService.isParticipantAlmostInactive();
            const manualRewardRedemptions = yield this.incentiveService.getManualRewardsEarnedList();
            this.manualInterventionRedemptions = manualRewardRedemptions.filter((reward) => reward.ManualIntervention !== null);
            this.manualRewardsRedemptions = manualRewardRedemptions.filter((reward) => reward.ManualIntervention === null);
        });
    }
    goToRoute(route) {
        if (route.IsExternal) {
            const url = this.$state.href(route.Ref, route.Params);
            window.open(url, '_blank');
        }
        else {
            this.$state.go(route.Ref, route.Params);
        }
    }
    setQuestionsInfoTextValue() {
        const faqPage = this.iscPages.helpCenterPages.filter((page) => page.PageType === this.iscPages.PageType.faq);
        const faqIncentivesUrl = this.$state.href('container.main.helpcenter.faq', { helpcenterPage: faqPage[0].Guid }, { absolute: true });
        this.constantsfactory.getLabelValueReplaceUrl('LabelQuestionsSavingUpRewards', faqIncentivesUrl)
            .then((value) => {
            this.questionsInfoText = value;
        });
    }
    checkIncentivesFaqCategoryExists() {
        this.iscPages.init().then(() => {
            const page = _.find(this.iscPages.helpCenterPages, (item) => item.PageType === this.iscPages.PageType.faq);
            if (!page) {
                return;
            }
            this.pageService.getFaqPageDetails(page.Guid).then((result) => {
                if (_.some(result.data.Categories, { IsLinkedToIncentives: true, IsVisible: true })) {
                    this.setQuestionsInfoTextValue();
                }
            });
        });
    }
    makeTasks() {
        const pageGuid = this.$stateParams.pageGuid;
        const theme = this.pageThemes.getPageTheme(pageGuid);
        const publishingTask = this.taskList.createTask({
            title: '(SavingUpRedeemingInitiatedTitle)',
            text: '(SavingUpRedeemingInitiatedText)',
            theme,
        });
        const errorTask = this.taskList.createTask({
            title: '(SavingUpRedeemingErrorTitle)',
            text: '(SavingUpRedeemingErrorText)',
            showCloseButton: true,
            theme,
            onClick: () => {
                errorTask.hide();
            },
        });
        const succeededTask = this.taskList.createTask({
            title: '(SavingUpRedeemingSucceededTitle)',
            text: '(SavingUpRedeemingSucceededText)',
            showCloseButton: true,
            theme,
            onClick: () => {
                succeededTask.hide();
            },
        });
        return { publishingTask, errorTask, succeededTask };
    }
}
MySavingUpRewardsController.$inject = ['incentiveService', 'serverConstants', 'constantsfactory', '$state',
    '$stateParams', 'pageThemes', 'taskList', 'userNotificationService',
    'pageService', 'iscPages', '$timeout', '$q'];
