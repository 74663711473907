var VimeoStimuliDialogController = (function () {
    function VimeoStimuliDialogController($mdDialog, videoId) {
        this.$mdDialog = $mdDialog;
        this.videoId = videoId;
        this.playerVars = {
            autoplay: 1,
        };
        this.id = '';
        this.init();
    }
    VimeoStimuliDialogController.prototype.init = function () {
        VimeoStimuliDialogController.videoId++;
        this.id = 'vimeo-' + VimeoStimuliDialogController.videoId;
    };
    VimeoStimuliDialogController.prototype.close = function () {
        this.$mdDialog.cancel();
    };
    VimeoStimuliDialogController.$inject = ['$mdDialog', 'videoId'];
    VimeoStimuliDialogController.videoId = 0;
    return VimeoStimuliDialogController;
}());
export { VimeoStimuliDialogController };
