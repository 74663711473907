<script setup lang="ts">
import { RouteNames } from '@/router/routes/route-names.enum';
import { useConstantsStore } from '@/store/constants-store';
import { each, takeRight, throttle } from 'lodash-es';
import { QBtn, QItem, QMenu, QTab, QTabs, useQuasar } from 'quasar';
import { computed, ref, watch, nextTick, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useTour } from '@/composables/use-tour';
import { usePriorityNavigationHelper } from './composables/use-priority-navigation-helper';
import { useParticipantStore } from '@/store/participant-store';
import { usePagesStore } from '@/store/pages-store';
import { useSelectedTab } from './composables/navigation-bar-shared';
import navigationTab from './navigation-tab.vue';

const $q = useQuasar();

const tabs = ref<QTab[]>([]);
const moreButtonDesktop = ref<QBtn>();
const moreTabMobile = ref<QTab>();
const container = ref<HTMLDivElement>();
const menu = ref<QMenu>();
const moreMenuItems = ref<QItem[]>();

// Tour
const constantsStore = useConstantsStore();
const getLabelValue = constantsStore.getLabelValue;
// Is used to make the 'more' menu persistent while the tour is being shown
// This is needed so that the menu doesn't close when we click somewhere during the tour
const isTourActive = computed(() => useTour().isActive.value);

const router = useRouter();
const route = useRoute();

const { selectedTab } = useSelectedTab();

// Delay the start of the tour until
// - the tabs have been rendered correctly
// - the user is routed to the homepage
// This is done by watching the tabs and route themselves.
// Since this should only be done once, we immediately call the WatchStopHandle
const stopTabWatch = watch(([tabs.value, route]), async () => {
  // If we're still on the splashpage, we don't need to start the tour yet nor stop the watch for it
  if(route.name?.toString() !== RouteNames.Home){
    return;
  }
  const participantStore = useParticipantStore();
  // On first login or startTour=true, show tour
  // Checking startTour here as well instead of using immediate on the watch below to make sure startTour() won't be triggered twice.
  if (participantStore.shouldStartTour || route.query.startTour === 'true'){
    participantStore.shouldStartTour = false;
    useTour().startTour();
  }
  // Show tour when startTour parameter is added
  watch(() => route.query.startTour, (newValue) => {
    if(newValue === 'true') {
      useTour().startTour();
    }
  });
  stopTabWatch();
});

// Menu click
const menuClick = (name: string | undefined, params: Record<string, string> = {}) => {
  router.push({ name, params });
};

// Pages
const mainPages = computed(() => usePagesStore().mainPages);
const { listPageOverflow, mainPagesToShow } = usePriorityNavigationHelper();

let moreTabWidth = 0;
let moreButtonWidth = 0;
let pageTabWidths: number[] = [];

const fetchElementWidths = async () => {
  listPageOverflow.value = mainPagesToShow.value;
  await nextTick();
  moreTabWidth = moreTabMobile.value?.$el.clientWidth || 0;
  moreButtonWidth = moreButtonDesktop.value?.$el.clientWidth || 0;

  listPageOverflow.value = [];
  await nextTick();

  pageTabWidths = tabs.value.map((t) => t.$el.clientWidth);
};

const calculateItemsToShow = async () => {
  const menuWidth: number = (container.value?.clientWidth || 0);
  // Since the more tab should always be shown on mobile, we subtract from the available width that we have.
  // On desktop view, the width of these will be 0 so no harm there.
  const mainPageTabsContainerWidth = menuWidth - moreTabWidth;
  let menuItemsWidth = 0;
  let firstNotFitMenuItemIndex = -1;

  each(pageTabWidths, (width, index) => {
    menuItemsWidth += width;
    // if we're on the desktop view and if it's not the last item,
    // we have to take into account that the more button will also take up space and accomodate for this.
    // This isn't necessary for mobile since we already took that into account earlier
    const isLastItem = index === tabs.value.length - 1;
    const correctedMenuItemsWidth = !isLastItem ? menuItemsWidth + moreButtonWidth : menuItemsWidth;
    if (correctedMenuItemsWidth > mainPageTabsContainerWidth) {
      firstNotFitMenuItemIndex = index;
      return false; // return false to break the loop
    }
  });

  listPageOverflow.value = firstNotFitMenuItemIndex !== -1
    ? takeRight(mainPages.value, mainPages.value.length - firstNotFitMenuItemIndex)
    : [];
  await nextTick();
};

// We use a throttled version of the calculation for performance reasons and to make sure there is only 1 calculation running at a time.
const throttledCalculateItemsToShow = throttle(calculateItemsToShow, 100, { leading: true, trailing: true });
const resizeObserver = new ResizeObserver(throttledCalculateItemsToShow);

onMounted(async () => {
  await fetchElementWidths();
  await throttledCalculateItemsToShow();
  if (container.value) {
    resizeObserver.observe(container.value);
  }
});

// Recalculate the tabs-width whenever the pages in the store change
// This is for the first login
watch(mainPages, async () => {
  await fetchElementWidths();
  await throttledCalculateItemsToShow();
});


onUnmounted(() => {
  resizeObserver.disconnect();
});

const onMoreMenuTabPressed = (e: KeyboardEvent) => {
  // Last item and tab is pressed
  if (e.target === moreMenuItems.value?.[moreMenuItems.value.length - 1].$el && !e.shiftKey) {
    // close menu. since we don't prevent the default behavior the next item in the navigation menu will be selected (eg. saving up icon)
    menu.value?.hide();
    return;
  }

  // First item and shift+tab is pressed
  if (e.target === moreMenuItems.value?.[0].$el && e.shiftKey) {
    // Focus the entire menu again
    if (menu.value?.contentEl && 'focus' in menu.value.contentEl && typeof menu.value?.contentEl.focus === 'function') {
      menu.value.contentEl.focus();
      e.preventDefault();
    }
    return;
  }

  // Menu and shift+tab is pressed
  if (e.target === menu.value?.contentEl && e.shiftKey) {
    // close menu. since we prevent the default behavior this should focus the 'more' button again
    menu.value?.hide();
    e.preventDefault();
    return;
  }
};

</script>

<template>
  <div
    ref="container"
    class="navigation-bar-tabs col justify-center"
    :class="{ row: $q.screen.gt.sm }"
  >
    <q-tabs
      v-model="selectedTab"
      data-v-step="welcome"
      :inline-label="$q.screen.gt.sm"
      content-class="justify-around"
    >
      <!-- main pages -->
      <navigation-tab
        v-for="(page, index) in mainPagesToShow"
        :key="page.guid"
        :page="page"
        :index="index"
        @set-tabs="(index, el) => tabs[index] = el"
      />

      <!-- More Button (desktop) -->
      <q-btn
        v-if="$q.screen.gt.sm && listPageOverflow.length > 0"
        ref="moreButtonDesktop"
        class="navigation-bar-tabs__more-button-desktop"
        :ripple="false"
        flat
        no-caps
        :aria-label="getLabelValue('LabelAriaMorePages')"
        aria-haspopup="true"
      >
        <q-icon
          :size="$q.screen.gt.sm ? 'xs' : 'sm'"
          name="svguse:/images/page-icons/menu.svg#menu"
          class="navigation-bar-tabs__page-icon q-mr-xs"
        />
        <span class="text-weight-medium text-uppercase">
          {{ getLabelValue('LabelNavigationMore') }}
        </span>
        <q-menu
          ref="menu"
          :persistent="isTourActive"
          class="navigation-bar-tabs__more-button-desktop-menu"
          @keydown.tab="onMoreMenuTabPressed"
        >
          <q-list>
            <q-item
              v-for="(page) in listPageOverflow"
              ref="moreMenuItems"
              :key="page.guid"
              v-close-popup
              :aria-label="page.name"
              clickable
              :data-v-step="page.id"
              class="navigation-bar-tabs__more-item q-py-md"
              @click="menuClick(page.route, page.routeParams)"
            >
              <q-item-section avatar>
                <q-icon
                  :name="`svguse:/images/page-icons/${page.icon}.svg#${page.icon}`"
                  size="xs"
                />
              </q-item-section>
              <q-item-section>
                <span class="navigation-bar-tabs__more-page-name text-dark text-capitalize text-weight-medium">
                  {{ page.name }}
                </span>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-tabs>
  </div>
</template>


<style lang="scss">
.navigation-bar-tabs {
  min-height: 52px;
  max-width: 1080px !important;
  .q-tabs i.q-tabs__arrow {
    display: none;
  }

  &__more-button-desktop span {
    flex-wrap: nowrap;
  }

  &__more-item {
    border-bottom: $border-color 1px solid;
  }

  /* Doesn't seem possible to change min-width on avatar */
  &__more-page-name {
    margin-left: -25px;
  }
  .q-focus-helper {
    display: none;
  }
  .q-tabs__content {
    overflow: visible;
  }
}
</style>
