import { UiModule } from './../../index';
var ConfigurationService = (function () {
    function ConfigurationService() {
    }
    ConfigurationService.prototype.getFileConfiguration = function () {
        throw new Error('Please override this function with a function that gets configuration for the file types');
    };
    ConfigurationService.prototype.getFileTypes = function () {
        throw new Error('Please override this function with a function that gets the file types from the backend');
    };
    ConfigurationService.prototype.getAcceptedMimeTypesAndExtensions = function (fileConfiguration, fileTypes) {
        throw new Error('Please override this function with a function that gets the file types from the backend');
    };
    ConfigurationService.prototype.getConfigList = function () {
        throw new Error('Please override this function with a function that gets the configuration backend');
    };
    return ConfigurationService;
}());
export { ConfigurationService };
UiModule.service('iscConfigurationService', ConfigurationService);
