import { __awaiter } from "tslib";
export class ForceMobileAppController {
    constructor(imagesFactory, squareService, $window) {
        this.imagesFactory = imagesFactory;
        this.squareService = squareService;
        this.$window = $window;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.imagesFactory.applyImages();
            this.images = this.imagesFactory.images;
            const appCustomUriDetails = yield this.squareService.getAppCustomUriDetails();
            this.appName = appCustomUriDetails.AppName;
            if (appCustomUriDetails.CustomUriScheme) {
                this.deepLink = this.$window.location.href
                    .replace(/^https?/, appCustomUriDetails.CustomUriScheme)
                    .replace('login?from=/', '');
            }
        });
    }
    redirectToApp() {
        this.$window.location = this.deepLink;
    }
}
ForceMobileAppController.$inject = ['imagesFactory', 'squareService', '$window'];
