export class ContactService {
    constructor(httpService) {
        this.httpService = httpService;
    }
    sendTicket(ticketDetails) {
        return this.httpService.post({
            url: '/ContactService/SendTicket',
            data: ticketDetails,
        }).then((response) => response.data);
    }
}
ContactService.$inject = ['httpService'];
