import { __awaiter, __generator } from "tslib";
var IscRedemptionThresholdController = (function () {
    function IscRedemptionThresholdController(_, $scope) {
        this._ = _;
        this.$scope = $scope;
    }
    IscRedemptionThresholdController.prototype.$onInit = function () {
        var _this = this;
        this.initLabels();
        this.$scope.$watch('vm.pointsSavedUp', function () { return _this.calculateCanRedeem(); });
        this.$scope.$watch('vm.minimumPointsToRedeem', function () { return _this.calculateCanRedeem(); });
    };
    IscRedemptionThresholdController.prototype.initLabels = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.labelPointsSavedUp = 'IscRedemptionThresholdPointsSavedUp';
                this.labelPointsRequiredToRedeem = 'IscRedemptionThresholdPointsRequiredToRedeem';
                this.labelMinimumPointsToRedeem = 'IscRedemptionThresholdMinimumPointsToRedeem';
                this.labelGetReward = 'IscRedemptionThresholdGetReward';
                return [2];
            });
        });
    };
    IscRedemptionThresholdController.prototype.calculateCanRedeem = function () {
        this.canRedeem = (this.pointsSavedUp - this.minimumPointsToRedeem >= 0) && (this.totalValue >= 1);
    };
    IscRedemptionThresholdController.prototype.redeem = function () {
        if (this.canRedeem) {
            this.onRedeem();
        }
        else {
            return false;
        }
    };
    IscRedemptionThresholdController.$inject = ['_', '$scope'];
    return IscRedemptionThresholdController;
}());
export { IscRedemptionThresholdController };
