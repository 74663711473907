import { __awaiter } from "tslib";
import { DateTime } from 'luxon';
import * as _ from 'lodash';
export class CacheService {
    constructor() {
        this.clearExpiredItems();
    }
    static getOrSet(getCallback, cacheKey, durationDays) {
        return __awaiter(this, void 0, void 0, function* () {
            let cacheValue = CacheService.getCacheValue(cacheKey);
            if (!cacheValue) {
                cacheValue = yield getCallback();
                CacheService.setCache({ key: cacheKey, value: cacheValue, duration: durationDays });
            }
            return cacheValue;
        });
    }
    static removeCacheValue(key) {
        if (localStorage) {
            localStorage.removeItem(key);
        }
        else {
            document.cookie = `${key}="";max-age=1`;
        }
    }
    static isSet(key) {
        return this.getCacheValue(key) != null;
    }
    static setCache(cacheable) {
        if (cacheable.value == null) {
            this.removeCacheValue(cacheable.key);
        }
        else {
            if (localStorage) {
                const cache = {
                    Expiry: cacheable.duration ? DateTime.utc().plus({ days: cacheable.duration }) : undefined,
                    Value: cacheable.value,
                };
                localStorage.setItem(cacheable.key, JSON.stringify(cache));
            }
            else {
                const maxAge = 60 * 60 * 24 * (cacheable.duration ? cacheable.duration : 365);
                document.cookie = `${cacheable.key}=${JSON.stringify(cacheable.value)};max-age=${maxAge};samesite=lax`;
            }
        }
    }
    static getCacheValue(keyName) {
        const cache = this.getCache(keyName);
        if (!cache) {
            return null;
        }
        if (cache.expiry < DateTime.utc()) {
            this.removeCacheValue(keyName);
            return null;
        }
        return cache.value;
    }
    static getCache(keyName) {
        return localStorage ? this.getFromLocalStorage(keyName) : this.getFromCookie(keyName);
    }
    static getFromCookie(keyName) {
        let result;
        const value = this.findCookie(keyName);
        if (value) {
            try {
                const parsedValue = JSON.parse(value);
                if (parsedValue) {
                    result = {
                        key: keyName,
                        expiry: DateTime.utc().plus({ days: 99999 }),
                        value: parsedValue,
                    };
                }
            }
            catch (error) {
                if (typeof value === 'string') {
                    result = {
                        key: keyName,
                        expiry: DateTime.utc().plus({ days: 99999 }),
                        value,
                    };
                }
                else {
                    throw error;
                }
            }
        }
        return result;
    }
    static getFromLocalStorage(keyName) {
        let result;
        const value = localStorage.getItem(keyName);
        if (value) {
            try {
                const json = JSON.parse(value);
                if (json && json.Value) {
                    result = {
                        key: keyName,
                        expiry: json.Expiry ? DateTime.fromISO(json.Expiry) : DateTime.utc().plus({ days: 99999 }),
                        value: json.Value,
                    };
                }
            }
            catch (err) {
                if (typeof value === 'string') {
                    result = {
                        key: keyName,
                        expiry: DateTime.utc().plus({ days: 99999 }),
                        value,
                    };
                }
                else {
                    throw err;
                }
            }
        }
        return result;
    }
    static findCookie(key) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${key}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }
    clearExpiredItems() {
        if (localStorage) {
            const keys = Object.keys(localStorage);
            _.each(keys, (key) => {
                const cached = localStorage.getItem(key);
                if (cached) {
                    try {
                        const cacheValue = JSON.parse(cached);
                        if (cacheValue && cacheValue.Expiry && DateTime.utc() > DateTime.fromISO(cacheValue.Expiry)) {
                            localStorage.removeItem(key);
                        }
                    }
                    catch (_a) {
                    }
                }
            });
        }
    }
}
CacheService.$inject = [];
