import { __awaiter, __generator } from "tslib";
import { UiModule } from './../../index';
var StimuliService = (function () {
    function StimuliService($q) {
        this.$q = $q;
    }
    StimuliService.prototype.getStimuliStreamingUrl = function (guid) {
        throw new Error('Please override this function with a function that gets the stimuli streaming URL from the backend');
    };
    StimuliService.prototype.downloadStimuli = function (guid) {
        throw new Error('Please override this function with a function that downloads a stimuli');
    };
    StimuliService.prototype.uploadEditorImage = function (file) {
        throw new Error('Please override this function with a function that uploads an image');
    };
    StimuliService.prototype.getVimeoThumbnailImagePath = function (videoId) {
        throw new Error('Please override this function with a function that return a thumbnail for a Vimeo video');
    };
    StimuliService.prototype.getImageOrFallback = function (imageUrl, fallbackUrl) {
        return __awaiter(this, void 0, void 0, function () {
            var result, deferred, image;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = imageUrl;
                        deferred = this.$q.defer();
                        image = new Image();
                        image.onerror = function () {
                            deferred.resolve(false);
                        };
                        image.onload = function () {
                            deferred.resolve(true);
                        };
                        image.src = result;
                        return [4, deferred.promise.then(function (isImage) {
                                result = isImage ? result : fallbackUrl;
                            })];
                    case 1:
                        _a.sent();
                        return [2, result];
                }
            });
        });
    };
    StimuliService.prototype.isVideoMimeType = function (type) {
        if (type && type.indexOf('video/') !== -1) {
            return true;
        }
        return false;
    };
    StimuliService.$inject = ['$q'];
    return StimuliService;
}());
export { StimuliService };
UiModule.service('iscStimuliService', StimuliService);
